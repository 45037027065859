import React, { Component } from "react";
import { View, KeyboardAvoidingView, ActivityIndicator } from "react-native";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SendChatComponent from "./SendChatComponent";
import ChatHeaderComponent from "./ChatHeaderComponent";
import ChatItemsComponent from "./ChatItemsComponent";
import "./styles/chat.scss";
import { chatOperations } from "./state";
import { API_ERROR_STATUS, WEBSOCKET_MESSAGES, EMPTY_STRING, THUMBNAIL_SMALL, PROFILE_THUMBNAIL_BUCKET_URL, IMAGE_MAX_UPLOAD, WEBSOCKET_API_ERROR_STATUS, DELETED_USER_HANDLENAME, API_NETWORK_ERROR, S3_STATUS_SUCCESS } from "../../../utils/constants";
import { MAX_IMAGE_EXCEEDED, SYSTEM_ERROR, MSG_SYSTEM_ERROR } from "../../../utils/messages";
import styles from "./styles/chatconversation.styles.native";
import { isNullOrUndefined } from "util";
import { selectors } from './state';
import { authOperations } from "../Authentication/state";
import ChatImageComponent from "./ChatImageComponent";
import moment from "moment"
import ErrorModal from "../../components/ErrorModal";
import { uploadToS3, isFumiyaUserCheck, onApiSuccess, isFFStaffUserCheck, isFumiyaOrFFStaffUserCheck, getObjKeyIdString, isStringNullEmptyOrUndefined } from "../../../utils/common"
import debounce from 'lodash/debounce'
import { BROWSER_ID } from "../../../config/settings";
import { Media } from 'react-breakpoints'
import { isMobile } from "react-device-detect";
import User404 from "../Errors/User404";
import { Detector } from "react-detect-offline";

class ChatConversation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ownerId: props.userId,
      ownerHandleName: props.handleName,
      recipientId: parseInt(props.match.params.id),
      recipientHandleName: isNullOrUndefined(props.location.state) ? EMPTY_STRING : props.location.state.handleName,
      threadId: null,
      isMessageDelete: false,
      selectedMessage: null,
      message: EMPTY_STRING,
      imageFiles: [],
      isImageUpload: false,
      currentPagination: 1,
      threadMessages: [],
      errors: {},
      initialLoad: true,
      isErrorModalShow: false,
      errorMessage: null,
      isMessageSender: false,
      imageURL: PROFILE_THUMBNAIL_BUCKET_URL + props.match.params.id + "/" + props.match.params.id + THUMBNAIL_SMALL,
      scrollHeight: 0,
      fetchingData: false,
      lastImageLoaded: false,
      isToggle: false,
      isMessageSending: false,
      isUserBlocked: false,
      isBlockChecking : true,
      hash: Date.now(),
      isWhiteSpace : false,
      userExist: true,
      isScrollable: false,
      blockedByUsers:[],
      isTextSending: false
    };
    this.storeMessage = debounce(props.storeMessage, 1000)
    this.handleSendMessage = debounce(this.handleSendMessage, 300)
  }

  initWSHandlers() {
    this.props.websocket.onmessage = (data) => {

      let serverData = JSON.parse(data.data);
      if (!isNullOrUndefined(serverData.message) && WEBSOCKET_API_ERROR_STATUS === serverData.message) {
        this.setState({
          isErrorModalShow: true,
          errorMessage: SYSTEM_ERROR
        })
      }

      let responseData = JSON.parse(serverData.data)

      if (WEBSOCKET_MESSAGES.onNotifications === responseData.action) {
        this.props.incrementNotifCount(responseData.referenceId)
      }else if (WEBSOCKET_MESSAGES.onChatNotification === responseData.action) {
        if(getObjKeyIdString(this.state.threadId) === getObjKeyIdString(responseData.threadId)){
          return
        }else{
          this.props.incrementChatThreadCount(responseData.senderId, responseData.threadId)
        }
      } else if (WEBSOCKET_MESSAGES.onMessage === responseData.action) {
        //Remove message in redux if received message from server is equal to owner id
        if (responseData.senderId === this.state.ownerId) {
          this.props.clearMessage(this.state.recipientId, EMPTY_STRING)
        }
        
        //Check if the message coming from the server involves the recipient
        if (responseData.senderId === this.state.recipientId || responseData.receiverId === this.state.recipientId) {
          this.setState({
            message: responseData.receiverId === this.state.recipientId ? EMPTY_STRING : this.state.message,
            isTextSending: false,
            isMessageSending: false,
            threadId: responseData.threadId,
            isMessageSender: responseData.senderId === this.state.ownerId,
            threadMessages: [...this.state.threadMessages, responseData]
          }, () => {
            this.checkInput()
            this.handleScrollToBottom()
            this.setState({
              isMessageSender: false
            })
          })
        }

        this.props.updateChangeSeenStatus(this.state.ownerId, this.state.recipientId, this.props.websocket)

      } else if (WEBSOCKET_MESSAGES.onImageUpload === responseData.action) {
        if (0 < responseData.url.length) {
          responseData.url.map((item) => {
            if(this.state.isImageUpload){
              uploadToS3(item, this.state.imageFiles[0]).then(response=>{
                if(API_NETWORK_ERROR === response || (isNullOrUndefined(response.status) && S3_STATUS_SUCCESS !== response.status)){
                  this.setState({
                    isErrorModalShow: true,
                    errorMessage: MSG_SYSTEM_ERROR,
                    isMessageSending : false
                  })
                }
              })
            }
            return null
          })
          let remainingImageFiles = Object.assign([], this.state.imageFiles); 
          let imageFiles = remainingImageFiles.slice(1)
          this.setState({
            imageFiles,
            isMessageSending : false
          }, ()=>{
            this.checkInput()
          })
          if (0 === this.state.imageFiles.length) {
            this.setState({
              isImageUpload: false
            })
          }
        }
      } else if (WEBSOCKET_MESSAGES.onImageRender === responseData.action) {
        //Check if the message coming from the server involves the recipient
        if (responseData.senderId === this.state.recipientId || responseData.receiverId === this.state.recipientId) {
          this.setState({
            threadId: responseData.threadId,
            isMessageSender: responseData.senderId === this.state.ownerId,
            threadMessages: [...this.state.threadMessages, responseData]
          }, () => {
            this.checkInput()
            this.handleScrollToBottom()
            this.setState({
              isMessageSender: false,
              isMessageSending : false,
            })
          })
          this.props.updateChangeSeenStatus(this.state.ownerId, this.state.recipientId, this.props.websocket)
        }
      } else if (WEBSOCKET_MESSAGES.onMessageDelete === responseData.action) {
        this.setState(prevState => {
          //Update from the chat messages
          const threadMessages = prevState.threadMessages.map(item => {
            if (item.chatId.$oid === responseData.chatId) {
              return {
                ...item,
                isDeleted: true
              };
            }
            return item;
          })
          return {
            threadMessages
          }
        });
        this.setState({
          selectedMessage: null,
          isMessageDelete: false,
        });
      } else if (WEBSOCKET_MESSAGES.onBlockUser === responseData.action) {
          this.setState({
            blockedByUsers : [...this.state.blockedByUsers, responseData.blockerId]
          })
      } else if (WEBSOCKET_MESSAGES.onUnblockUser === responseData.action) {
        let blockedByUsers = Object.assign([], this.state.blockedByUsers); 
        let updatedBlockedByUsers = blockedByUsers.filter(userId =>  userId !== responseData.blockerId);

        this.setState({
          blockedByUsers: updatedBlockedByUsers
        })
      } 
      else if (WEBSOCKET_MESSAGES.onError === responseData.action || WEBSOCKET_MESSAGES.onAttachmentError === responseData.action
        || WEBSOCKET_MESSAGES.onLimitError === responseData.action || API_ERROR_STATUS === serverData.status) {
        this.setState({
          isErrorModalShow: true,
          errorMessage: serverData.message,
          isMessageSending : false,
          isTextSending: false
        })
      }
    }

    let isExistError = false; //added 20220614 White Out fix (caused by reconnection loop)
    this.props.websocket.onerror = () => { isExistError = true } //mod 20220617 White Out fix (caused by reconnection loop) Start.
    this.props.websocket.onclose = () => { if(!isExistError) this.socketReconnect();}
    //mod 20220617 White Out fix (caused by reconnection loop) End
  }

  socketReconnect = () => {
    this.props.websocket.close()
    this.props.connect();
    setTimeout(() => {
        this.initWSHandlers()
    }, 1000);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.isAtTop, false);
    document.removeEventListener('scroll', this.handleScrollToBottom, false);
  }


  componentDidMount() {
    if (!isNullOrUndefined(this.props.message) && 0 < this.props.message.length) {
      if (!isNullOrUndefined(this.state.recipientId)) {
        this.props.message.map(item => {
          if (item.recipientId === this.state.recipientId) {
            this.setState({
              message: item.message
            },()=>{
              this.checkInput()
            })
          }
          return null
        })
      }
    }

    if (!isNullOrUndefined(this.props.websocket) && this.props.websocket.readyState === WebSocket.OPEN) {
      if (EMPTY_STRING !== this.state.recipientId) {
        this.props.updateChangeSeenStatus(this.state.ownerId, this.state.recipientId, this.props.websocket)
      }
    }

    this.props.getSpecificChatThread(
      this.state.threadId,
      this.state.currentPagination,
      this.state.ownerId,
      this.state.recipientId
    ).then(response => {
      if (onApiSuccess(response)) {
        let threadMessages = JSON.parse(response.data.data.data);
        let BROWSER_UNIQUE_ID = sessionStorage.getItem(BROWSER_ID)
        if(0 < threadMessages.length && BROWSER_UNIQUE_ID === response.data.data.browserId) {
            this.setState({
              threadMessages: threadMessages,
              threadId: threadMessages[0].threadId
            })
            const scope = this
            let images = document.getElementsByClassName("chat-image")
            if (images && 0 < images.length) {
              let lastImage = images[images.length - 1]
              lastImage.onload = function () {
                const interval = setInterval(() => {
                  if (0 < lastImage.naturalWidth && 0 < lastImage.naturalHeight) {
                      scope.setState({
                        lastImageLoaded: true,
                        initialLoad: false
                      })
                      clearInterval(interval);
                  }
                }, 50);
              }
            } else {
              this.setState({
                lastImageLoaded: true,
                initialLoad: false
              })
            }
            this.handleScrollToBottom();
        }
      } else {
        this.setState({
          isErrorModalShow: true,
          errorMessage: SYSTEM_ERROR
        })
      }
    });
    this.props.getUnseenThreads(this.state.ownerId)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isNullOrUndefined(this.props.websocket) && this.props.websocket.readyState === WebSocket.OPEN) {
      this.initWSHandlers()
    }

    //Skip scroll if the isImageUpload was updated
    if(this.state.isImageUpload === prevState.isImageUpload){
      if(!this.state.isBlockChecking && !this.state.isUserBlocked && this.state.userExist){
        if (this.state.isMessageSender) {
          this.handleScrollToBottom();
        }
        else if (this.state.fetchingData && 0 === this.chatMessages.scrollTop) {
          this.chatMessages.scrollTo(0, this.chatMessages.scrollHeight - this.state.scrollHeight);
        } else if ((!this.state.fetchingData || 0 !== this.chatMessages.scrollTop) && !this.state.isToggle) {
          this.chatMessages.scrollTo(0, (this.chatMessages.scrollHeight - this.state.scrollHeight) + this.chatMessages.scrollTop)
        }
      }
    }
  }

  handleScrollToBottom = () => {
    if (this.state.lastImageLoaded && (!this.state.isBlockChecking && !this.state.isUserBlocked && this.state.userExist)) {
        this.chatMessages.scrollTo(0, this.chatMessages.scrollHeight)
    }
  };

  handleAttachScrollListener = () => {
    this.chatMessages.addEventListener("scroll", this.isAtTop.bind(this));
    if(this.chatMessages.scrollHeight > this.chatMessages.clientHeight){
      this.setState({
        isScrollable: true
      })
    }
  };

  isAtTop = (event) => {
    var node = event.target;
    var height = node.scrollHeight;
    const top = node.scrollTop === 0;
    if (top) {
      this.setState({
        scrollHeight: height,
        fetchingData: true
      });
      this.handlePagination();
    }
  };


  componentWillMount() {
    this.props.checkIfUserExist(this.state.ownerId, this.state.recipientId).then(response=>{
      if(onApiSuccess(response)){
        let result = JSON.parse(response.data.data);
        if(result.user_exist){
            this.setState({
                userExist: true
            })
        }else{
          this.setState({
            userExist: false
          })
        }
      }else{
        this.setState({
          isErrorModalShow: true,
          errorMessage: SYSTEM_ERROR,
          isLoading: true
        })
      }
    })
    this.props.isRecipientBlocked(this.props.userId, this.state.recipientId).then(response=>{
      if(onApiSuccess(response)){
          let result = response.data.data;
          if(result.is_blocked){
              this.setState({
                  isUserBlocked: true,
                  isBlockChecking: false
              })
          }
          else{
            this.setState({
              isBlockChecking: false
            },()=>{
                if(this.state.userExist && !this.state.isUserBlocked){
                  this.handleAttachScrollListener();  
                }
            })
          }
      }else{
          this.setState({
              isErrorModalShow: true,
              errorMessage: SYSTEM_ERROR,
              isLoading: true
          })
      }
  })

    //Get handlename
    this.props.getFriendHandleName(this.state.ownerId,this.state.recipientId).then(response => {
      if (onApiSuccess(response)) {
        let result = JSON.parse(response.data.data);
        let handleName = 0 === result.length ? EMPTY_STRING : isNullOrUndefined(result[0].recipientHandleName) ? result[0].handleName : result[0].recipientHandleName
        this.setState({
          recipientHandleName : handleName
        })
      } else {
        this.setState({
          isErrorModalShow: true,
          errorMessage: SYSTEM_ERROR
        })
      }
    })
  }

  handleToggleDelete = id => {
    this.setState({
      selectedMessage: id,
      isMessageDelete: !this.state.isMessageDelete,
      isToggle: true
    });
  };

  handleDeleteMessage = (item) => {
    if (!isNullOrUndefined(this.props.websocket) && this.props.websocket.readyState === WebSocket.OPEN) {
      let fileExtension = 1 === item.type ? "." + item.chatBody.split('.').pop() : null;
      const messageModel = {
        chatId: item.chatId.$oid,
        recipientId: this.state.recipientId,
        ownerId: this.state.ownerId,
        type: item.type,
        fileExtension: fileExtension
      }
      this.props.deleteMessage(messageModel, this.props.websocket)
      this.setState({
        isMessageDelete: false
      })
    } else {
      this.setState({
        isErrorModalShow: true,
        errorMessage: SYSTEM_ERROR
      })
    }
  };

  handleInputChange = (e) => {
    this.setState({
      message: e,
      fetchingData: false,
      isToggle: true
    }, ()=>{
      this.checkInput()
    })

    this.storeMessage(this.state.recipientId, e)
  }

  handleSendPress = (online) => {
    if (!this.state.isMessageSending && !this.state.isTextSending) {
      if(online){
        // this.setState({
        //   isMessageSending: false
        // }, ()=>{
          if(this.props.websocket.readyState !== WebSocket.OPEN){
            this.socketReconnect()
            setTimeout(()=>{
              this.handleSendMessage()
            }, 1500)
            
          }else{
            this.handleSendMessage()
          }
        // })
      }else{
        this.setState({
          isErrorModalShow: true,
          errorMessage: SYSTEM_ERROR,
          isMessageSending: false
        })
      }
    }
  }
  
  handleSendMessage = () => {
    if (this.state.isMessageSending || this.state.isTextSending) {
      return;
    }

    let message = this.state.isWhiteSpace ? null : this.state.message.trim();

    this.setState({isMessageSending: true, isTextSending: !isStringNullEmptyOrUndefined(message)}, ()=>{
      //Validate image files and text
      let imageErrors = selectors.validateChatImage(this.state.imageFiles)
      let textErrors = selectors.validateChatMessage(this.state.message)
      let errors = { ...imageErrors, ...textErrors }
      this.setState({
        errors
      })

      let data = [message, ...this.state.imageFiles]
      if (0 >= Object.keys(errors).length) {
        if (!isNullOrUndefined(this.props.websocket) && !this.state.blockedByUsers.includes(this.state.recipientId)) {
          //model for chatbody and object for message and image files
          const messageModel = {
            ownerId: this.state.ownerId,
            recipientId: this.state.recipientId,
            recipientHandleName: this.state.recipientHandleName,
            ownerHandleName: this.state.ownerHandleName,
            chatBody: message,
            senderId: this.state.ownerId,
          };
          this.props.sendMessage(messageModel, data, this.props.websocket).then(response=>{
            if(!response){
              this.setState({
                isErrorModalShow: true,
                errorMessage: SYSTEM_ERROR,
                isMessageSending: false
              })
            }
          });
        } else {
          this.setState({
            isErrorModalShow: true,
            errorMessage: SYSTEM_ERROR,
            isMessageSending: false
          })
        }
      }else{
        this.setState({isMessageSending: false});
      }
    });
  }

  checkInput = () => {
    if (!this.state.message.replace(/\s/g, '').length) {
      this.setState({
        isWhiteSpace : true
      })
    }else{
      this.setState({
        isWhiteSpace : false
      })
    }
  }

  handleUpdateAddImage = () => {
    this.setState({
      fetchingData: false,
      isImageUpload: !this.state.isImageUpload
    })
  }

  handleAddImage = (e) => {
    if (IMAGE_MAX_UPLOAD >= e.target.files.length && IMAGE_MAX_UPLOAD > this.state.imageFiles.length) {
        let addedImages = e.target.files
        let files = []
        // loop through files
        addedImages.forEach(function (item, index) {
          files.push(item)
        })
        this.setState({
          imageFiles: [...this.state.imageFiles, ...files]
        })
    } else {
      this.setState({
        isErrorModalShow: true,
        errorMessage: MAX_IMAGE_EXCEEDED
      })
    }
    e.target.value = EMPTY_STRING
  }

  handleCloseErrorModal = () => {
    this.setState({
      isErrorModalShow: false
    })
  }

  handleRemoveImage = (event, imageIndex) => {
    event.preventDefault()
    this.state.imageFiles.map((item, index) => {
      if (index === imageIndex) {
        let imageFiles = this.state.imageFiles.filter(function (item, index) {
          return index !== imageIndex
        })
        this.setState({
          imageFiles
        })
      }
      return null
    })
  }

  handlePagination = () => {
    let currentPagination = this.state.currentPagination + 1
    this.setState(prevState => {
      return {
        ...prevState,
        currentPagination: prevState.currentPagination + 1,
        initialLoad: false,
        isMessageSender: false,
        isMessageDelete: false,
      }
    })

    this.props.getSpecificChatThread(this.state.threadId, currentPagination, this.state.ownerId,
      this.state.recipientId).then((response) => {
        if (onApiSuccess(response)) {
          let threadMessages = JSON.parse(response.data.data.data);
          let BROWSER_UNIQUE_ID = sessionStorage.getItem(BROWSER_ID)
          if (0 < threadMessages.length && BROWSER_UNIQUE_ID === response.data.data.browserId) {
            this.setState({
              threadMessages : [...threadMessages, ...this.state.threadMessages]
            })
          }
        } else {
          this.setState({
            isErrorModalShow: true,
            errorMessage: SYSTEM_ERROR
          })
        }
      })
  }


  render() {
    if(this.state.isBlockChecking){
      return (
        <center>
            <ActivityIndicator
                style={{
                    marginBottom: 20,
                    height: 50,
                    display: "block"
                }}
            />
        </center>
      )
    }
    else if(this.state.isUserBlocked || !this.state.userExist){
      return(
          <User404/>
      )
    }else if(!this.state.isUserBlocked && this.state.userExist && (!this.state.initialLoad || !this.state.isUserBlocked || this.state.userExist)){
      return(
        <Media>
        {({ breakpoints, currentBreakpoint }) => {
          let mobileView = (breakpoints[currentBreakpoint] < breakpoints.mobileLandscape) || isMobile;
          return(
          <div>
          <div className={isFumiyaOrFFStaffUserCheck(this.state.recipientId) || DELETED_USER_HANDLENAME === this.state.recipientHandleName ? "chat-conversation list-container ff-list-container" : "chat-conversation list-container"}>
            <ChatHeaderComponent {...this.state} />
            <div className={isFumiyaOrFFStaffUserCheck(this.state.recipientId) || DELETED_USER_HANDLENAME === this.state.recipientHandleName ? "ff-message-container" : this.state.isImageUpload ? "image-upload-container" : "message-container"}>
              <div
                ref={(el) => {
                  this.chatMessages = el;
                }}
                className={this.state.isImageUpload ? this.state.isScrollable ? "chat-items-image" : "chat-items-image2" : "chat-items-container"}
              >
                {this.state.threadMessages.map((item, index) => {
                  let currentDate = moment(item.createDt.$date).format("YYYY/MM/DD");
                  let prevDate = 0 !== index ? moment(this.state.threadMessages[index - 1].createDt.$date).format("YYYY/MM/DD") : null;

                  return (
                      <View key={item.createDt.$date + index} style={item.chatId === this.state.selectedMessage ? styles.topContainer : styles.bottomContainer}>
                        <ChatItemsComponent
                          {...this.state}

                          hash={this.state.hash}
                          item={item}
                          key={item.chatId}
                          date={0 === index || (currentDate !== prevDate)
                            ? currentDate
                            : null
                          }
                          handleToggleDelete={this.handleToggleDelete}
                          handleDeleteMessage={this.handleDeleteMessage}
                        />
                      </View>
                    );
                })}
              </div>
              {this.state.isImageUpload && !mobileView ?
                <div className="chat-image-upload-container">
                  <ChatImageComponent {...this.state} handleAddImage={this.handleAddImage} handleRemoveImage={this.handleRemoveImage} />
                </div>
              : null}
            </div>
          </div>


          <ErrorModal
            {...this.state}
            handleCloseModal={this.handleCloseErrorModal}
            customContent={null}
            errorMessage={this.state.errorMessage}
          />


          <Detector
            onChange={() => this.setState({isMessageSending: false})}
            render={({ online }) => (
              <KeyboardAvoidingView style={styles.keyBoardAvoidingView}>
                <div ref={(el) => { this.chatBottomContainer = el }} className={isFumiyaUserCheck(this.state.recipientId) ? "ff-send-container" : "send-container"}>
                {this.state.isImageUpload && mobileView ?
                    <div className="chat-image-upload-container-mobile">
                      <ChatImageComponent {...this.state} handleAddImage={this.handleAddImage} handleRemoveImage={this.handleRemoveImage} />
                    </div>
                : null}
                  {DELETED_USER_HANDLENAME === this.state.recipientHandleName || isFumiyaUserCheck(this.state.recipientId) || isFFStaffUserCheck(this.state.recipientId) ?
                    null :
                    <SendChatComponent
                      errors={this.state.errors}
                      message={this.state.message}
                      isWhiteSpace={this.state.isWhiteSpace}
                      isTextSending={this.state.isTextSending}
                      imageFiles={this.state.imageFiles}
                      isMessageSending={this.state.isMessageSending}
                      handleUpdateAddImage={this.handleUpdateAddImage}
                      handleInputChange={this.handleInputChange}
                      handleSendMessage={this.handleSendMessage}
                      handleSendPress={()=>this.handleSendPress(online)}
                      isOnline={online}
                    />
                  }
                </div>
              </KeyboardAvoidingView>
            )}
          />
        </div>
          )
        }}
        </Media>
      );
    }
  }
}

//Bind dispatch to action creator
//Add Websocket instance
const mapStateToProps = state => {
  return {
    websocket: state.auth.websocket,
    userId: state.auth.credentials.userId,
    handleName: state.editprofile.profileDetails.handleName,
    message: state.chat.message
  };
};
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSpecificChatThread: chatOperations.getSpecificChatThread,
      sendMessage: chatOperations.sendMessage,
      deleteMessage: chatOperations.deleteMessage,
      updateChangeSeenStatus: chatOperations.updateChangeSeenStatus,
      connect: authOperations.connectWS,
      storeMessage: chatOperations.storeMessage,
      getFriendHandleName: chatOperations.getFriendHandleName,
      isRecipientBlocked: chatOperations.isRecipientBlocked,
      incrementNotifCount: authOperations.incrementNotifCount,
      incrementChatThreadCount: authOperations.incrementChatThreadCount,
      getUnseenThreads : authOperations.getUnseenThreads,
      checkIfUserExist : chatOperations.checkIfUserExist,
      connectWS: authOperations.connectWS,
      clearMessage: chatOperations.storeMessage
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ChatConversation);

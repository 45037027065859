import React from 'react';
import UploadingPlaceholder from '../../../includes/img/placeholders/uploading.png';
import FailedPlaceholder from '../../../includes/img/placeholders/failed-grey.png';
import { VIMEO_PLAYER_URL } from '../../../config/settings';
import { UPLOAD_STATUS } from '../../../utils/constants';
import './styles.scss';


const RenderVideo = ({ videoUrl, videoStatus, userId, author }) => {
    let isOwner = userId === author.userId;
    switch (videoStatus) {
        case UPLOAD_STATUS.uploading: return <img alt="uploading" className="post-view-placeholder-video" src={UploadingPlaceholder} />;
        case UPLOAD_STATUS.failed: return isOwner ? <img alt="failed attachment" className="post-view-placeholder-video" src={FailedPlaceholder} /> : '';
        case UPLOAD_STATUS.success:
        default:
            return (
                <div className="post-view-video-container">
                    <iframe
                        title={videoUrl}
                        src={`${VIMEO_PLAYER_URL + videoUrl}?title=0&byline=0&portrait=0&loop=1&autopause=1`}
                        frameBorder="0"
                        allow="autoplay; fullscreen"
                        allowFullScreen
                        className="post-view-video-content"
                    >
                    </iframe>
                </div>
            );
    }
}

export default RenderVideo;
import * as Path from "./apiRoutes";
import ApiService from "../../../../utils/apiService";

const sendEmail = (contactForm) => () =>{
    return ApiService.post(Path.SEND_EMAIL, contactForm)
    .then(response => {
      return response;
    })
    .catch(error => {
      console.error(error);
    });
}
export{
  sendEmail
}
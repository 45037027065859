//Dummy json data
export const REST_SERVICE_URL_ROOT = process.env.REACT_APP_API_URL;

//Persisted key 
export const PERSISTED_STATE_KEY = process.env.REACT_APP_PERSISTED_KEY;

// S3 URL
export const S3_URL_BUCKET = process.env.REACT_APP_S3_URL_BUCKET

// Cloudfront URL
export const CLOUDFRONT_URL = process.env.REACT_APP_CLOUDFRONT_URL

// Websocket URL
export const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL

// USER API URL
export const USER_ROOT_API_URL = process.env.REACT_APP_USER_ROOT_API_URL

// ADMIN API URL
export const ADMIN_ROOT_API_URL = process.env.REACT_APP_ADMIN_ROOT_API_URL

//Auth Check
export const NEW_COMU_AUTH_CHECK = process.env.REACT_APP_AUTH_CHECK

//VIMEO URL
export const VIMEO_PLAYER_URL = "https://player.vimeo.com";
export const VIMEO_KEY = process.env.REACT_APP_VIMEO_KEY; 

//Max Attachment Size
export const MAX_USER_ATTACHMENT = 5000000000;

//Root
export const ROOT = '/';

//Fumiya ID
export const FUMIYA_USER_ID = 3644;

//Fumiya SNS Accounts
export const SNS_TWITTER_URL = "https://www.twitter.com/ff238_official"
export const SNS_INSTAGRAM_URL = "https://instagram.com/fumiya_fujii_ff"
export const SNS_FACEBOOK_URL = "http://www.facebook.com/fumiyafujiiofficial"

//Facebook Page ID
export const FACEBOOK_PAGE_ID = 169915886394405
                                   
//Max Image File Size (10MB)
export const MAX_IMAGE_FILE_SIZE = 10000000;

//User Roles
export const ROLE_ADMIN = "ROLE_ADMIN";
export const ROLE_STAFF = "ROLE_STAFF";
export const ROLE_USER = "ROLE_USER";

//FF-Staff ID
export const FF_STAFF_USER_ID = 5526;

//Browser ID
export const BROWSER_ID = "BROWSER_ID";

//FFMWEB
export const FFMWEB_ROOT = process.env.REACT_APP_FFM_SITE
export const FFMWEB_LOGIN_URL = process.env.REACT_APP_FFM_SITE
export const FFMWEB_LOGOUT_URL = process.env.REACT_APP_FFM_SITE + "/logout"
export const FFMWEB_PRIVACY_POLICY_URL = FFMWEB_ROOT + "/privacypolicy"

//Cookies Settings
export const COMUX2_COOKIE_CONSENT = "comux2-cookie-consent"
export const COMU2X_COOKIE_EXPIRATION = 7500

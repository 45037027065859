import reducer from "./reducers";
import { routes } from "../routes";
import * as mypageOperations from "./operations"
import * as selectors from "./selectors"

export { mypageOperations, selectors }

export default {
    reducer,
    routes
};

import React from "react";
import Modal from "react-modal";
import { View, Text, Image, TouchableOpacity } from "react-native";
import { Button } from "react-native-elements";
import "./styles.scss";
import CloseIcon from "../../../includes/img/icons/close.svg";
import styles from "./styles.native.js";
import { Media } from 'react-breakpoints'
import { isMobile } from "react-device-detect";

const ConfirmationModal = props => {
  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        let tabletView = (breakpoints[currentBreakpoint] < breakpoints.tabletLandscape) || isMobile;
        return (
          <View>
            <Modal
              ariaHideApp={false}
              isOpen={props.isModalShow}
              className="delete-modal"
              overlayClassName="delete-modal-overlay"
              id={props.id || "confirmation"}
            >
              <TouchableOpacity
                style={styles.modalClose}
                onPress={() => props.handleCloseModal()}
              >
                <Image source={CloseIcon} alt="close-icon" style={styles.closeIcon} />
              </TouchableOpacity>

              <View>
                <Text style={styles.modalText}>{props.confirmationText}</Text>
                {props.customContent}
              </View>
              <div className="btn-container">
                <Button
                  onClick={() => props.handleConfirmAction()}
                  buttonStyle={tabletView ? [styles.confirmBtn, { marginRight: 0 }] : styles.confirmBtn}
                  title={props.confirmTitle || "はい"}
                  titleStyle={styles.titleConfirm}
                />
                <Button
                  onClick={() => props.handleCloseModal()}
                  buttonStyle={props.closeBtnStyle || styles.closeBtn}
                  title={props.closeTitle || "戻る"}
                  titleStyle={styles.titleClose}
                />
              </div>
            </Modal>
          </View>
        )
      }}
    </Media>
  );
};

export default ConfirmationModal;

import * as Path from "./apiRoutes";
import ApiService from "../../../../utils/apiService";
import axios from 'axios';

const getInstagramFeed = (accessToken) => () => {
    var instance = axios.create({
        baseURL: 'https://graph.instagram.com/'
    });

  let params = {
    fields : "id,media_url",
    access_token : accessToken
  }
  return instance.get(Path.GET_INSTAGRAM_FEED + `?fields=${params.fields}&access_token=${params.access_token}`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response.data
    });
};

const getInstagramAccessToken = () => () => {
  return ApiService.get(Path.GET_INSTAGRAM_ACCESS_TOKEN)
    .then((response) => {
        return response
    })
    .catch((error) => {
        console.log(error);
    });
};

const nextFeedImages = (url) => () => {
    var instance = axios.create({
            baseURL: 'https://graph.instagram.com/'
    });
    return instance.get(url)
      .then((response) => {
          return response
      })
      .catch((error) => {
        return error.response.data
      });
  };

const getFacebookAccessToken = () => () => {
  return ApiService.get(Path.GET_FACEBOOK_ACCESS_TOKEN)
    .then((response) => {
        return response
    })
    .catch((error) => {
        console.log(error);
    });
};


const getFacebookPage = (accessToken) => () =>{
  var instance = axios.create({
    baseURL: 'https://graph.facebook.com/v7.0'
  });

  let params = {
    fields : "name,picture",
    access_token : accessToken
  }

  return instance.get(Path.GET_FACEBOOK_PAGE + `?fields=${params.fields}&access_token=${params.access_token}`)
  .then((response) => {
      return response
  })
  .catch((error) => {
      return error.response.data
  });
};

const getFacebookPosts = (accessToken) => () => {
  var instance = axios.create({
      baseURL: 'https://graph.facebook.com/v7.0'
  });

  let params = {
    fields : "created_time,message,attachments{title,type,description,media,media_type,subattachments},updated_time",
    access_token : accessToken
  }
  return instance.get(Path.GET_FACEBOOK_FEED + `?fields=${params.fields}&limit=10&access_token=${params.access_token}`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response.data
    });
};

const getPaginatedFacebookPosts = (url) => () => {
  var instance = axios.create({
      baseURL: 'https://graph.facebook.com/v7.0'
  });

  return instance.get(url)
  .then((response) => {
        return response
  })
  .catch((error) => {
    return error.response.data
  });
};


export {
  getFacebookAccessToken,
  getFacebookPage,
  getFacebookPosts,
  getInstagramAccessToken,
  getInstagramFeed,
  nextFeedImages,
  getPaginatedFacebookPosts,
};

import { PASSWORD_CHANGE_MAX_LENGTH } from '../../../../utils/constants';

export const passwordChangeForm = {
    model: "PasswordChangeModel",
    form: [
        {
            type: "top-error-message",
            label: "",
            name: "topErrorMessage",
            default: ''
        },
        {
            label: "新しいパスワード",
            name: "newPassword",
            type: "password",
            maxLength: PASSWORD_CHANGE_MAX_LENGTH,
            default: ''
        },
        {
            label: "新しいパスワード確認",
            name: "confirmPassword",
            type: "password",
            maxLength: PASSWORD_CHANGE_MAX_LENGTH,
            default: ''
        }
    ]
}

export const passwordChangeUpdateForm = {
    model: "PasswordChangeUpdateModel",
    form: [
        {
            type: "top-error-message",
            label: "",
            name: "topErrorMessage",
            default: ''
        },
        {
            type: "values",
            label: "新しいパスワード",
            name: "newPassword",
            default: '',
            divideCenter: true
        }
    ]
}
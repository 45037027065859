import React from "react";
import { Switch, Route } from "react-router-dom";
import { userRoutes, fumiyaRoutes } from "../../../app.modules";
import PrivateUserRoute from "../../components/PrivateUserRoute";
import "./styles/containers.scss";
import {
  appendUserRoute
} from "../../../utils/common";

import { isFumiyaUserCheck, isFFStaffUserCheck } from "../../../utils/common";
import User404 from "../Errors/User404";
import EditProfile from "../EditProfile/EditProfile";
import ContactUs from "../ContactUs/ContactUs";
import { APP_ROOT_ROUTE } from "./routes";
import HamburgerMenu from "../../components/HamburgerMenu";
import Help from "../Help/Help";

export const ModuleContent = React.memo(({ userId, location }) => {
  return (
    <Switch>
      {/** Always allow screens */}
      <Route
        path={appendUserRoute("/edit-profile")}
        component={EditProfile}
        exact={true}
      />
      <Route
        path={appendUserRoute("/contact-us")}
        component={ContactUs}
        exact={true}
      />
      <Route
        path={appendUserRoute("/menu")}
        component={HamburgerMenu}
        exact={true}
      />
      <Route
        path={appendUserRoute("/help")}
        component={Help}
        exact={true}
      />
      {isFumiyaUserCheck(userId) || isFFStaffUserCheck(userId)
        ? fumiyaRoutes.map((route, i) => {
            return (
              <PrivateUserRoute
                {...location}
                key={"route" + route.path}
                path={APP_ROOT_ROUTE + route.path}
                component={route.component}
                exact={route.exact}
              />
            );
          })
        : userRoutes.map((route, i) => {
            return (
              <PrivateUserRoute
                {...location}
                key={"route" + route.path}
                path={APP_ROOT_ROUTE + route.path}
                component={route.component}
                exact={route.exact}
              />
            );
          })}
      )}
      {/**Not Found Screen**/}
      <Route path="*" component={User404} />
    </Switch>
  );
});

import React from 'react';
import ContentLoader from 'react-content-loader'

const RowSkeleton = props => (
    <ContentLoader
        speed={2}
        width={props.isHeader ? "104%" : "98%"}
        height={props.isHeader ? 90 : 120}
        
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        {props.isHeader ?
        <>
            <rect x="0" y="36" width="750" height="8" rx="3" />
            <rect x="0" y="50" width="650" height="8" rx="3" />
            <rect x="0" y="64" width="550" height="8" rx="3" />
        </>
        :
        <>
            <rect x="20" y="36" width="740" height="8" rx="3" />
            <rect x="20" y="50" width="640" height="8" rx="3" />
            <rect x="20" y="64" width="540" height="8" rx="3" />
        </>
        }

    </ContentLoader>
);

const MyPageSkeleton = props => {
    let x = props
    return (
        <div className="pt-3 pr-3 pl-1">
            {RowSkeleton(x)}
        </div>
    );
};

export default MyPageSkeleton;
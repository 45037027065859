export const RANDOM_MESSAGE_DELETE_CONFIRM = '以下メッセージを削除しますか？';
export const RANDOM_MESSAGE_DELETE_SUCCESS= '以下メッセージを削除しました。';
export const RANDOM_MESSAGE_UPDATE_SUCCESS= '以下メッセージの作成・編集が完了しました。';
export const ADMIN_NOTICE_CREATE_UPDATE_SUCCESS = '以下のお知らせの作成・編集が完了しました。';
export const ADMIN_NOTICE_DELETE_CONFIRM = '以下のお知らせを削除しますか？';
export const ADMIN_NOTICE_DELETE_SUCCESS = '以下のお知らせを削除しました。';
export const PASSWORD_CHANGE_SUCCESS = 'パスワードを変更しました。';
export const ADMIN_MAINTENANCE_NORMAL = '只今システムのメンテナンス中です。お客様には大変ご迷惑をお掛け致しますが、ご理解とご協力をお願い申し上げます。お問い合わせはこちらよりお願いします。';
export const ADMIN_MAINTENANCE_EMERGENCY = '只今システムの緊急メンテナンス中です。お客様には大変ご迷惑をお掛け致しますが、ご理解とご協力をお願い申し上げます。お問い合わせはこちらよりお願いします。';
export const ADMIN_MAINTENANCE_TEMPORARY = '只今システムの臨時メンテナンス中です。お客様には大変ご迷惑をお掛け致しますが、ご理解とご協力をお願い申し上げます。お問い合わせはこちらよりお願いします。';

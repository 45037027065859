import React, { useState, useImperativeHandle, useRef, forwardRef } from 'react';
import { Image, Container } from 'react-bootstrap';
import DefaultIcon from '../../../includes/img/placeholders/user.svg';
import SendIcon from '../../../includes/img/icons/send.svg';
import SendWhiteIcon from '../../../includes/img/icons/send-white.svg';
import { Media } from 'react-breakpoints'
import { isMobile } from "react-device-detect";
import { useSelector } from 'react-redux';
import TextareaAutosize from 'react-autosize-textarea';
import './styles.scss';
import PCOnlyButton from '../PCOnlyButton';
import { emptyTextValue, isNullOrUndefined, addDefaultSrc } from '../../../utils/common';
import MobileOnlyButton from '../MobileOnlyButton';
import { postSelectors } from '../../containers/Post/state';
import { CLOUDFRONT_THUMBNAIL_PREFIX_URL, COMMENTS_MAX_CHAR, FUMIYA_COMMENTS_MAX_CHAR } from '../../../utils/constants';
import { Beforeunload } from 'react-beforeunload';
import DiscardChangesModal from '../DiscardChangesModal';
import { FUMIYA_USER_ID } from '../../../config/settings';

const RenderCreateComment = (props, ref) => {
    let { createComment } = props;

    const [txtComment, setTxtComment] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const [hasUpdates, setHasUpdates] = useState(false); //For page redirect check

    //Redux store data
    const loggedUser = useSelector(state => state.auth.credentials);

    const handleInputChange = (e) => {
        let newError = postSelectors.validateTextField(e, formErrors);
        setTxtComment(e.target.value);
        setFormErrors(newError);
        if ("" === e.target.value) {
            setHasUpdates(false);
        } else {
            setHasUpdates(true);
        }

    }

    const handleCreateComment = () => {
        createComment(txtComment);
    }

    /**REFERENCES FOR PARENT CALLS*/
    const createCommentRef = useRef();
    useImperativeHandle(ref, () => ({
        onSuccess: () => {
            setTxtComment("")
            setHasUpdates(false)
        }
    }));


    const isSaveDisabled = emptyTextValue(txtComment) || 0 < Object.keys(formErrors).length;
    let isProfileImageUrlValid = loggedUser.profileImageUrl && 0 <= loggedUser.profileImageUrl.indexOf("Profile/");

    return (
        <div ref={createCommentRef}>
            {/** On before page leave*/}
            <Beforeunload onBeforeunload={(event) => hasUpdates ? event.preventDefault() : {}} >

                {/** On before route leave*/}
                {hasUpdates && <DiscardChangesModal active={true} />}
                <Media>
                    {({ breakpoints, currentBreakpoint }) => {
                        let mobileView = (breakpoints[currentBreakpoint] < breakpoints.mobileLandscape) || isMobile;
                        return (
                            <Container className={`post-view-comment-create-${mobileView ? "mobile" : "pc"}`}>
                                <div className="post-view-comment-body">
                                    <Image
                                        onError={addDefaultSrc}
                                        src={isProfileImageUrlValid ? `${CLOUDFRONT_THUMBNAIL_PREFIX_URL + loggedUser.profileImageUrl}-small?${props.hash}` : DefaultIcon}
                                        className="post-view-avatar"
                                        roundedCircle
                                    />
                                    <div className="w-100 mr-3">
                                        <TextareaAutosize
                                            name="commentInput"
                                            value={txtComment}
                                            onChange={handleInputChange}
                                            rows={3}
                                            placeholder={"コメントを投稿する"}
                                            className={`form-control post-view-comment-textarea-${mobileView ? "mobile" : "pc"}`}
                                            maxLength={FUMIYA_USER_ID === props.authorId ? FUMIYA_COMMENTS_MAX_CHAR : COMMENTS_MAX_CHAR}
                                        />
                                        {Object.values(formErrors).map((err, i) => {
                                            return <p key={"err" + i} className="ml-3 mt-1 formik-error flex-column">{err}</p>
                                        })}
                                    </div>
                                </div>
                                <div className={`d-flex justify-content-end ml-2 ${!mobileView && 'mt-3'}`}>
                                    <PCOnlyButton
                                        disabled={isSaveDisabled}
                                        label={'投稿する'}
                                        icon={SendWhiteIcon}
                                        onClick={() => isNullOrUndefined(createComment) ? {} : handleCreateComment(txtComment)}
                                    />

                                    <MobileOnlyButton
                                        disabled={isSaveDisabled}
                                        onPress={() => isNullOrUndefined(createComment) ? {} : handleCreateComment(txtComment)}
                                        buttonStyle={{ alignItems: 'center' }}
                                        labelStyle={{ fontSize: 14 }}
                                        icon={SendIcon}
                                        label={'投稿'}
                                    />
                                </div>
                            </Container>
                        );
                    }}
                </Media>
            </Beforeunload>
        </div>
    )
}

export default forwardRef(RenderCreateComment);

import React from "react"
import {TouchableOpacity, Text, Image} from "react-native"
import styles from "./styles/chatconversation.styles.native"
import DeleteIcon from "../../../includes/img/icons/delete-white.svg";
import { isMobile } from "react-device-detect";
import { Media } from 'react-breakpoints'

const RemoveModalWindow = (props) => {
    return(
        <Media>
        {({ breakpoints, currentBreakpoint }) => {
            let isDeviceMobile = (breakpoints[currentBreakpoint] < breakpoints.mobileLandscape) || isMobile
            return(
                <TouchableOpacity
                    style={isDeviceMobile ?  styles.touchableDelete : styles.touchableDeletePC}
                    onPress={(e) => {e.preventDefault(); props.handleDeleteMessage(props.item)}}
                >
                    <Image
                        source={DeleteIcon}
                        alt="delete-icon"
                        style={isDeviceMobile ? styles.deleteIcon : styles.deleteIconPC}
                    />
                    <Text style={isDeviceMobile ? styles.deleteText : styles.deleteTextPC}>削除</Text>
                </TouchableOpacity>
            )
         }}
    </Media>
    )
}

export default RemoveModalWindow;
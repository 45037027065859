import React from 'react';
import ContentLoader from 'react-content-loader'

const RowSkeleton = (
    <ContentLoader
        speed={2}
        width={'100%'}
        height={100}
        
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="120" y="40" width="200" height="15" rx="3" />
        <circle cx="50" cy="48" r="35" />
    </ContentLoader>
);

const CommunityHeaderSkeleton = () => {
    return (
        <div className="pr-3 pl-1">
            {RowSkeleton}
            {RowSkeleton}
            {RowSkeleton}
            {RowSkeleton}
            {RowSkeleton}
            {RowSkeleton}
        </div>
    );
};

export default CommunityHeaderSkeleton;
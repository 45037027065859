import React from 'react';
import { ActivityIndicator } from 'react-native';

const WithLoaderIndicator = (props) => {
    if(props.isLoading){
        return props.hideLoader ? "" : <ActivityIndicator/>
    }

    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    );
};

export default WithLoaderIndicator;
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { profileOperations, profileSelectors } from "./state";
import { connect } from "react-redux";
import { Card, Container } from "react-bootstrap";
import {
  appendUserRoute,
  onApiSuccess,
  initialAccountUpdated,
  defaultTopScroll,
  isNullOrUndefined,
} from "../../../utils/common";
import { SYSTEM_ERROR } from "../../../utils/messages";
import ProfileFormTemplate from "./ProfileFormTemplate";
import {
  profileForm,
  radioInput,
  hobbyOptions,
  prefectureOptions,
} from "./state/templates";
import { EMPTY_STRING } from "../../../utils/constants";
import ErrorModal from "../../components/ErrorModal";
import DiscardChangesModal from "../../components/DiscardChangesModal";
import ProcessLoader from "../../components/ProcessLoader";

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileModel: null,
      ownerId: props.userId,
      username: props.username,
      hobby1: hobbyOptions,
      hobby2: hobbyOptions,
      hobby3: hobbyOptions,
      hobbySelected: [],
      isErrorModalShow: false,
      errorMessage: EMPTY_STRING,
      hasUpdates: false,
      isProcessing: false,
      isInitialUpdated: false,
      isFFMRedirect: false
    };
  }

  componentDidMount() {
    defaultTopScroll(); 

    this.props.getUserProfile(this.state.ownerId).then((response) => {
      if (onApiSuccess(response)) {
        let isUserUpdated = initialAccountUpdated();
        this.setState({
          isInitialUpdated: isUserUpdated
        })
      }else{
        this.setState({
          isErrorModalShow: true,
          errorMessage: SYSTEM_ERROR
        });
      }
    });
  }

  handleSubmitValidation = (profileModel) => {
    let errors = profileSelectors.checkEditProfileForm(profileModel);
    return errors;
  };

  handleSubmit = (profileModel) => {
    this.setState({ isProcessing: true }, () => {
      this.props
        .updateProfile(
          profileModel.ProfileModel,
          this.state.ownerId,
          this.state.username
        )
        .then((response) => {
          if (onApiSuccess(response)) {
            this.setState({ hasUpdates: false, isProcessing: false, isInitialUpdated: true }, () => {
              this.props.history.push({
                pathname: appendUserRoute("/mypage"),
              });
            });
          } else {
            this.setState({
              isProcessing: false,
              isErrorModalShow: true,
              errorMessage: isNullOrUndefined(response.data.message) ? SYSTEM_ERROR : response.data.message,
            });
          }
        }).catch(()=>{
          this.setState({
            isProcessing: false,
            isErrorModalShow: true,
            errorMessage: SYSTEM_ERROR,
          });
        });
    });
  };

  handleHobbySelectChange = (selected, name, setFieldValue, modelField) => {
    let tempFiltered = this.state.hobbySelected.filter(
      (data, i) => data.name !== name
    );
    let updateListSelected = [...tempFiltered, { name: name, value: selected }];

    this.setState({
      hobbySelected: updateListSelected,
    });

    let hobby1List = hobbyOptions;
    let hobby2List = hobbyOptions;
    let hobby3List = hobbyOptions;

    updateListSelected.map((option) => {
      if ("hobby1" === option.name) {
        return hobby1List;
      }
      hobby1List = hobby1List.filter((data, i) => data.value !== option.value);
      return "";
    });
    updateListSelected.map((option) => {
      if ("hobby2" === option.name) {
        return hobby2List;
      }
      hobby2List = hobby2List.filter((data, i) => data.value !== option.value);
      return "";
    });
    updateListSelected.map((option) => {
      if ("hobby3" === option.name) {
        return hobby3List;
      }
      hobby3List = hobby3List.filter((data, i) => data.value !== option.value);
      return "";
    });
    this.setState({
      hobby1: hobby1List,
      hobby2: hobby2List,
      hobby3: hobby3List,
    });

    setFieldValue(modelField, selected);
  };

  handleCloseErrorModal = () => {
    this.setState({
      isErrorModalShow: false,
    });
  };

  handleHasUpdates = () => {
    this.setState({
      hasUpdates: true,
    });
  };

  render() {
    const hobbyOptionsList = {
      hobby1: this.state.hobby1,
      hobby2: this.state.hobby2,
      hobby3: this.state.hobby3,
    };

    return (
      <React.Fragment>
        <ProcessLoader isShown={this.state.isProcessing} />
        <React.Fragment>
          <DiscardChangesModal
            active={this.state.hasUpdates}
            disableRedirect={!this.state.isInitialUpdated}
          />
          <Card className="editProfile-container mb-5 pb-5">
            <Card.Header className="page-title">{"プロフィール編集"}</Card.Header>
            <Container className="pt-4">
              <ProfileFormTemplate
                formInputs={profileForm}
                radioInput={radioInput}
                hobbyOptionsList={hobbyOptionsList}
                prefectureOptions={prefectureOptions}
                initialValues={{ ProfileModel: this.props.profileDetails }}
                validate={profileSelectors.checkEditProfileForm}
                handleHasUpdates={this.handleHasUpdates}
                handleSubmit={this.handleSubmit}
                handleHobbySelectChange={this.handleHobbySelectChange}
                formButtons={[
                  { type: "primary", label: "保存", submit: true },
                ]}
              />
            </Container>
          </Card>
          
          <ErrorModal
            {...this.state}
            handleCloseModal={this.handleCloseErrorModal}
            errorMessage={this.state.errorMessage}
          />

        </React.Fragment>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.auth.credentials.userId,
    username: state.auth.credentials.username,
    handleName: state.editprofile.profileDetails.handleName,
    profileDetails: state.editprofile.profileDetails,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserProfile: profileOperations.getUserProfile,
      updateProfile: profileOperations.updateProfile,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);

export const MSG000_SYSTEM_ERROR = "システムエラーが発生しました。" //MESSAGE 0
export const MSG001_EMPTY_EMAIL = 'Emailを入力してください。'; //MESSAGE 1
export const MSG002_INVALID_EMAIL = '無効なEmailが入力されています。'; //MESSAGE 2
export const MSG003_EMPTY_PASSWORD = 'パスワードを入力してください。'; //MESSAGE 3
export const MSG004_NON_APLHANUM_PASSWORD = 'パスワードは英数半角で入力してください。'; //MESSAGE 4
export const MSG005_EMAIL_PASSWORD_INCORRECT = 'Email、もしくはパスワードに誤りがあります。'; //MESSAGE 5
export const MSG006_EMAIL_NOT_EXIST = '指定されたEmailは登録されていません。'; //MESSAGE 6
export const MSG007_INVALID_ID = 'IDに利用できない文字が含まれています。'; //MESSAGE 7
export const MSG008_NO_USER_RESULTS_FOUND = '指定されたユーザは存在しません。'; //MESSAGE 8
export const MSG009_SYS_ERR_SAVING_RECORD = 'レコード登録時にシステムエラーが発生しました。管理者へお問い合わせください。'; //MESSAGE 9

export const MSG010_EMPTY_CREATED_YEAR = '日付[年]を入力してください。'; //MESSAGE 10
export const MSG011_EMPTY_CREATED_MONTH = '日付[月]を入力してください。 '; //MESSAGE 11
export const MSG012_EMPTY_CREATED_DAY = '日付[日]を入力してください。'; //MESSAGE 12
export const MSG013_EMPTY_ADMIN_NOTICE_TITLE = 'タイトルを入力してください。'; //MESSAGE 13
export const MSG014_EMPTY_ADMIN_NOTICE_BODY = '本文を入力してください。'; //MESSAGE 14
export const MSG015_MISSING_DISPLAY_TYPE = '表示/非表示を選択してください。'; //MESSAGE 15
export const MSG016_EMPTY_DISPLAY_START_YEAR = '公開開始日[年]を入力してください。'; //MESSAGE 16
export const MSG017_EMPTY_DISPLAY_START_MONTH = '公開開始日[月]を入力してください。'; //MESSAGE 17
export const MSG018_EMPTY_DISPLAY_START_DAY = '公開開始日[日]を入力してください。'; //MESSAGE 18
export const MSG019_EMPTY_DISPLAY_END_YEAR = '公開終了日[年]を入力してください。'; //MESSAGE 19
export const MSG020_EMPTY_DISPLAY_END_MONTH = '公開終了日[月]を入力してください。'; //MESSAGE 20
export const MSG021_EMPTY_DISPLAY_END_DAY = '公開終了日[日]を入力してください。'; //MESSAGE 21

export const MSG022_CREATED_YEAR_HAS_DOUBLE_BYTE = '日付[年]は半角で入力してください。'; //MESSAGE 22
export const MSG023_CREATED_MONTH_HAS_DOUBLE_BYTE = '日付[月]は半角で入力してください。'; //MESSAGE 23
export const MSG024_CREATED_DAY_HAS_DOUBLE_BYTE = '日付[日]は半角で入力してください。'; //MESSAGE 24

export const MSG025_INVALID_CREATED_YEAR = '日付[年]に利用できない文字が含まれています。'; //MESSAGE 25
export const MSG026_INVALID_CREATED_MONTH = '日付[月]に利用できない文字が含まれています。'; //MESSAGE 26
export const MSG027_INVALID_CREATED_DAY = '日付[日]に利用できない文字が含まれています。'; //MESSAGE 27
export const MSG028_INVALID_CREATED_DATE = '日付が無効です。'; //MESSAGE 28

export const MSG029_DISPLAY_START_YEAR_HAS_DOUBLE_BYTE = '公開開始日[年]は半角で入力してください。'; //MESSAGE 29
export const MSG030_DISPLAY_START_MONTH_HAS_DOUBLE_BYTE = '公開開始日[月]は半角で入力してください。'; //MESSAGE 30
export const MSG031_DISPLAY_START_DAY_HAS_DOUBLE_BYTE = '公開開始日[日]は半角で入力してください。'; //MESSAGE 31

export const MSG032_INVALID_DISPLAY_START_YEAR = '公開開始日[年]に利用できない文字が含まれています。'; //MESSAGE 32
export const MSG033_INVALID_DISPLAY_START_MONTH = '公開開始日[月]に利用できない文字が含まれています。'; //MESSAGE 33
export const MSG034_INVALID_DISPLAY_START_DAY = '公開開始日[日]に利用できない文字が含まれています。'; //MESSAGE 34
export const MSG035_INVALID_DISPLAY_START_DATE = '公開開始日が無効です。'; //MESSAGE 35

export const MSG036_DISPLAY_END_YEAR_HAS_DOUBLE_BYTE = '公開終了日[年]は半角で入力してください。'; //MESSAGE 36
export const MSG037_DISPLAY_END_MONTH_HAS_DOUBLE_BYTE = '公開終了日[月]は半角で入力してください。'; //MESSAGE 37
export const MSG038_DISPLAY_END_DAY_HAS_DOUBLE_BYTE = '公開終了日[日]は半角で入力してください。'; //MESSAGE 38

export const MSG039_INVALID_DISPLAY_END_YEAR = '公開終了日[年]に利用できない文字が含まれています。'; //MESSAGE 39
export const MSG040_INVALID_DISPLAY_END_MONTH = '公開終了日[月]に利用できない文字が含まれています。'; //MESSAGE 40
export const MSG041_INVALID_DISPLAY_END_DAY = '公開終了日[日]に利用できない文字が含まれています。'; //MESSAGE 41
export const MSG042_INVALID_DISPLAY_END_DATE = '公開終了日が無効です。'; //MESSAGE 42

export const MSG043_INVALID_DISPLAY_DATE_DURATION = '終了日は、開始日以降の日付を指定してください。'; //MESSAGE 43

export const MSG044_NO_DELIVERY_TYPE = '種類を選択してください。'; //MESSAGE 44
export const MSG045_DELIVERY_DATE_AND_TIME_REQUIRED = '種類が[全員]の場合は、送信日時を指定してください。'; //MESSAGE 45
export const MSG046_DELIVERY_YEAR_REQUIRED = '種類が[ランダム]の場合は、送信日時[年]もしくは[年][月]を指定してください。'; //MESSAGE 46
export const MSG047_DELIVERY_DATE_SHOULD_BE_FUTURE = '種類が[全員]の場合は、送信日時を未来の日時で指定してください。'; //MESSAGE 47
export const MSG048_DELIVERY_YEAR_SHOULD_BE_CURRENT_OR_FUTURE = '種類が[ランダム]の場合は、送信日時[年]は今年以降の年で指定してください。'; //MESSAGE 48
export const MSG049_DELIVERY_YEAR_AND_MONTH_SHOULD_BE_CURRENT_OR_FUTURE = '種類が[ランダム]の場合は、送信日時[年][月]は今月以降の年月で指定してください。'; //MESSAGE 49
export const MSG050_EMPTY_BODY_OR_IMAGE = '本文、写真のいずれかが必須です。'; //MESSAGE 50
export const MSG051_EMPTY_DELIVERY_STATUS = '配信を選択してください。'; //MESSAGE 51
export const MSG052_DELIVERY_YEAR_HAS_DOUBLE_BYTE = '送信日時[年]は半角で入力してください。'; //MESSAGE 52
export const MSG053_DELIVERY_MONTH_HAS_DOUBLE_BYTE = '送信日時[月]は半角で入力してください。'; //MESSAGE 53
export const MSG054_DELIVERY_DAY_HAS_DOUBLE_BYTE = '送信日時[日]は半角で入力してください。'; //MESSAGE 54
export const MSG055_DELIVERY_HOUR_HAS_DOUBLE_BYTE = '送信日時[時]は半角で入力してください。'; //MESSAGE 55
export const MSG056_DELIVERY_MINUTE_HAS_DOUBLE_BYTE = '送信日時[分]は半角で入力してください。'; //MESSAGE 56
export const MSG057_INVALID_DELIVERY_YEAR = '送信日時[年]に利用出来ない文字が含まれています。'; //MESSAGE 57
export const MSG058_INVALID_DELIVERY_MONTH = '送信日時[月]に利用出来ない文字が含まれています。'; //MESSAGE 58
export const MSG059_INVALID_DELIVERY_DAY = '送信日時[日]に利用出来ない文字が含まれています。'; //MESSAGE 59
export const MSG060_INVALID_DELIVERY_HOUR = '送信日時[時]に利用出来ない文字が含まれています。'; //MESSAGE 60
export const MSG061_INVALID_DELIVERY_MINUTE = '送信日時[分]に利用出来ない文字が含まれています。'; //MESSAGE 61
export const MSG062_INVALID_DELIVERY_DATE = '送信日時が無効です。'; //MESSAGE 62
export const MSG063_INVALID_IMAGE = '無効な写真ファイルです。'; //MESSAGE 63

export const MSG064_EMPTY_NEW_PASSWORD = '新しいパスワードを入力してください。'; //MESSAGE 64
export const MSG065_EMPTY_CONFIRM_PASSWORD = '新しいパスワード(確認用)を入力してください。'; //MESSAGE 65
export const MSG066_PASSWORD_HAS_DOUBLE_BYTE = '新しいパスワードは半角で入力してください。'; //MESSAGE 66
export const MSG067_PASSWORD_HAS_MACHINE_DEPENDENT = '新しいパスワードに誤りがあります。機種依存文字は使用できません。'; //MESSAGE 67
export const MSG068_INVALID_PASSWORD = '新しいパスワードに利用できない文字が含まれています。'; //MESSAGE 68
export const MSG069_INVALID_PASSWORD_LEN = '新しいパスワードは8～16文字以内で入力してください。'; //MESSAGE 69
export const MSG070_PASSWORD_NOT_MATCH = '新しいパスワードと確認用パスワードが一致しません。'; //MESSAGE 70
export const MSG071_COGNITO_ERROR = '登録時にエラー（AWS Cognito）が発生しました。管理者へお問い合わせください。'; //MESSAGE 71

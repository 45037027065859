import { POST_MAX_CHAR, COMMENTS_MAX_CHAR, REPLIES_MAX_CHAR, ACCEPTED_IMAGE_TYPES, ACCEPTED_VIDEO_TYPES, DATETIME_FORMAT } from "../../../../utils/constants";
import { MSG_POST_CHAR_EXCEEDED, MSG_COMMENT_CHAR_EXCEEDED, MSG_REPLY_CHAR_EXCEEDED, MSG_INVALID_IMAGE_SIZE, MSG_INVALID_VIDEO, MSG_POST_REQUIRED, MSG_INVALID_VIMEO_ID, MSG_INVALID_DATE, MSG_INVALID_DURATION, MSG_FUTURE_DATE } from "../../../../utils/messages";
import * as CommonSelectors from '../../../components/Validations/CommonSelectors';
import { objKeyExist, isStringNullEmptyOrUndefined, formatEndDateTime, formatStartDateTime, formatPrevEndDateTime, formatPrevStartDateTime } from "../../../../utils/common";
import { MAX_IMAGE_FILE_SIZE } from "../../../../config/settings";
import { isNullOrUndefined } from "../../../../../node_modules/util";
import moment from 'moment';

export const validateTextField = (e, errors) => {
    let newErrors = Object.assign({}, errors);
    let key = e.target.name;
    let value = e.target.value;

    if ("postBody" === key) {
        if (POST_MAX_CHAR < (value.trim()).length) {
            if (!objKeyExist(newErrors, key)) {
                newErrors[key] = MSG_POST_CHAR_EXCEEDED;
            }
        } else {
            delete newErrors[key];
        }
        if(0 < (value.trim()).length){
            delete newErrors["postRequired"];
        }
    }else if ("commentInput" === key) {
        if (COMMENTS_MAX_CHAR < (value.trim()).length) {
            if (!objKeyExist(newErrors, key)) {
                newErrors[key] = MSG_COMMENT_CHAR_EXCEEDED;
            }
        } else {
            delete newErrors[key];
        }
    }else if ("replyInput" === key) {
        if (REPLIES_MAX_CHAR < (value.trim()).length) {
            if (!objKeyExist(newErrors, key)) {
                newErrors[key] = MSG_REPLY_CHAR_EXCEEDED;
            }
        } else {
            delete newErrors[key];
        }
    }else if("vimeoLivestreamId" === key){
        if(isStringNullEmptyOrUndefined(value) || !CommonSelectors.isAlphanumeric(value)){
            newErrors["vimeoId"] = MSG_INVALID_VIMEO_ID;
        }else{
            delete newErrors["vimeoId"]
        }
    }

    return newErrors;
}

export const validateNormalForm = (data, errors) => {
    let newErrors = Object.assign({}, errors);

    if(isStringNullEmptyOrUndefined(data.postBody)
            && 0 >= data.photoUpload.length
            && 0 >= data.photos.length
            && isNullOrUndefined(data.videoUpload)
            && isStringNullEmptyOrUndefined(data.vimeoUrl)
            && isStringNullEmptyOrUndefined(data.videoUrl)
    ){
        newErrors["postRequired"] = MSG_POST_REQUIRED;
        delete newErrors["photoUpload"];
        delete newErrors["videoUpload"];
    }else{
        delete newErrors["postRequired"]

        if (POST_MAX_CHAR < (data["postBody"].trim()).length) {
            if (!objKeyExist(newErrors, "postBody")) {
                newErrors["postBody"] = MSG_POST_CHAR_EXCEEDED;
            }
        } else {
            delete newErrors["postBody"];
        }
    
        if(0 < data["photoUpload"].length){
            let hasInvalidImageSize = false;
            data["photoUpload"].map(item => {
                if (MAX_IMAGE_FILE_SIZE < item.size || 0 > ACCEPTED_IMAGE_TYPES.indexOf(item.type)) {
                    hasInvalidImageSize = true;
                }
                return '';
            });
            if (hasInvalidImageSize) {
                newErrors["photoUpload"] = MSG_INVALID_IMAGE_SIZE;
                delete newErrors["videoUpload"];
            }else{
                delete newErrors["photoUpload"];
            }
        }else{
            delete newErrors["photoUpload"];
        }
    
        if(data["videoUpload"]){
            if (0 > ACCEPTED_VIDEO_TYPES.indexOf(data["videoUpload"].type)) {
                newErrors["videoUpload"] = MSG_INVALID_VIDEO;
                delete newErrors["photoUpload"];
            }else{
                delete newErrors["videoUpload"];
            }
        }else{
            delete newErrors["videoUpload"];
        }
    }

    return newErrors;
}

export const validateLivestreamForm = (data, errors) => {
    let newErrors = Object.assign({}, errors);

    //Format start Date (Set to UTC)
    let startDate = moment.utc(formatStartDateTime(data));

    //Format end Date (Set to UTC)
    let endDate = moment.utc(formatEndDateTime(data));

    //Format prev start Date (Set to UTC)
    let startDatePrev = moment.utc(formatPrevStartDateTime(data));

    //Format prev end Date (Set to UTC)
    let endDatePrev = moment.utc(formatPrevEndDateTime(data));

    if(isStringNullEmptyOrUndefined(data.vimeoLivestreamId) || !CommonSelectors.isAlphanumeric(data.vimeoLivestreamId)){
        newErrors["vimeoId"] = MSG_INVALID_VIMEO_ID;
    }else{
        delete newErrors["vimeoId"]
    }

    if(isStringNullEmptyOrUndefined(data.startYear) 
        || isStringNullEmptyOrUndefined(data.startMonth)
        || isStringNullEmptyOrUndefined(data.startDay)
        || isStringNullEmptyOrUndefined(data.startHour)
        || isStringNullEmptyOrUndefined(data.startMinute)
        || isStringNullEmptyOrUndefined(data.endYear) 
        || isStringNullEmptyOrUndefined(data.endMonth)
        || isStringNullEmptyOrUndefined(data.endDay)
        || isStringNullEmptyOrUndefined(data.endHour)
        || isStringNullEmptyOrUndefined(data.endMinute)
        || 1000 > parseInt(data.startYear)
        || 1000 > parseInt(data.endYear)
    ){
        newErrors["invalidDate"] = MSG_INVALID_DATE;
    }else{
        delete newErrors["invalidDate"]
        if(!startDate.isValid() || !endDate.isValid()){
            newErrors["invalidDate"] = MSG_INVALID_DATE;
        }else if (startDate.isAfter(endDate)){
            newErrors["invalidDate"] = MSG_INVALID_DURATION;
            //Formatted to follow same timezone by default
        }else if(startDate.isSame(startDatePrev) && endDate.isSame(endDatePrev)){
            //Skip and allow
        }else if (moment(moment().format(DATETIME_FORMAT)).isAfter(startDate.format(DATETIME_FORMAT))){
            newErrors["invalidDate"] = MSG_FUTURE_DATE;
        }
    }

    return newErrors;
}
import React from "react";
import "./styles/radio.scss";
import {isFumiyaOrFFStaffUserCheck} from "../../../utils/common"

const RadioButton = props => {
  if (props.isThreadDelete && !isFumiyaOrFFStaffUserCheck(props.recipientId)) {
    return (
      <label className="label-container">
        <input
          className="radio-btn"
          id={props.id}
          type="radio"
          onChange={() => props.handleSelectThread(props.id)}
          checked={props.isThreadDelete && props.id === props.selectedThread}
        />
        <span className="checkmark"></span>
      </label>
    );
  } else {
    return null;
  }
};

export default RadioButton;

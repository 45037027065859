import * as types from "./types";
import { getObjKeyIdString } from "../../../../utils/common";

const defaultState = {
    //Module state
    newsfeed: [],
    adminNotice: [],
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.UPDATE_NEWSFEED_LIST:{
            return {
                ...state,
                newsfeed: action.payload
            };
        }
        case types.APPEND_NEWSFEED_LIST:{
            return {
                ...state,
                newsfeed: [...state.newsfeed, ...action.payload]
            };
        }
        case types.REMOVE_NEWSFEED_POST:{
            let filteredPosts = state.newsfeed.filter(i=> getObjKeyIdString(i._id) !== action.payload);
            return {
                ...state,
                newsfeed: filteredPosts
            };
        }
            
        case types.UPDATE_ADMIN_NOTICE:
            return {
                ...state,
                adminNotice: action.payload
            };
        case types.UPDATE_POST_IMAGE_SOCKET:{
            let updatedNewsfeed = Object.assign([], state.newsfeed); 
            let postSocket = updatedNewsfeed.filter(i => getObjKeyIdString(i._id) === getObjKeyIdString(action.payload.postId));
            if(0 < postSocket.length){
                let selectedPost = postSocket[0];
                let updatePhoto = selectedPost.photos.filter(i => i.imageUrl === action.payload.imageUrl)
                if (0 < updatePhoto.length) {
                    updatePhoto[0].imageStatus = action.payload.imageStatus
                }
            }
            return{
                ...state,
                newsfeed: updatedNewsfeed
            } 
        }

        case types.UPDATE_POST_VIDEO_SOCKET:{
            let updatedNewsfeed = Object.assign([], state.newsfeed); 
            let postSocket = updatedNewsfeed.filter(i => getObjKeyIdString(i._id) === getObjKeyIdString(action.payload.postId));
            if(0 < postSocket.length){
                let selectedPost = postSocket[0];
                selectedPost.videoUrl = action.payload.videoUrl;
                selectedPost.videoStatus = action.payload.videoStatus;
            }
            return{
                ...state,
                newsfeed: updatedNewsfeed
            } 
        }

        default: return state;
    }
};
import React from 'react';
import ContentLoader from 'react-content-loader'

const RowSkeleton = (
    <ContentLoader
        speed={2}
        width={'100%'}
        height={70}
        
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >

        <rect x="110" y="8" width="200" height="8" rx="3" />
        <rect x="110" y="23" width="200" height="8" rx="3" />
        <rect x="110" y="38" width="200" height="8" rx="3" />
        <rect x="110" y="53" width="200" height="8" rx="3" />
        <circle cx="46" cy="35" r="35" />
    </ContentLoader>
);

const CommunityHeaderSkeleton = () => {
    return (
        <div className="pr-3 pl-1">
            {RowSkeleton}
        </div>
    );
};

export default CommunityHeaderSkeleton;
import * as types from "./types";

export const setAdminAuthenticated = ( data, authenticate ) => ({
    type: types.SET_USER_AUTH,
    payload: data,
    isAuthenticated: authenticate
});

export const setMemberAuthenticated = ( data, authenticate ) => ({
    type: types.SET_MEMBER_AUTHENTICATED,
    payload: data,
    userAuthenticated: authenticate
});

export const setWSConnect = () => ({
    type: types.SET_WS_CONNECTION
});

export const resetUser = (id) => ({
    type: types.RESET_USER,
    payload: id
})

export const updateNotifCount = (count, referenceIds) => ({
    type: types.UPDATE_NOTIF_COUNT,
    count: count,
    referenceIds: referenceIds
});
export const updateChatThreadCount = (count, referenceIds) => ({
    type: types.UPDATE_CHAT_THREAD_COUNT,
    count: count,
    referenceIds: referenceIds
});

export const incrementNotifCount = (referenceId) => ({
    type: types.INCREMENT_NOTIF_COUNT,
    payload: referenceId
});

export const incrementChatThreadCount = (senderId, threadId) => ({
    type: types.INCREMENT_CHAT_THREAD_COUNT,
    senderId: senderId,
    threadId: threadId
});

export const clearNewNotifReferences = () => ({
    type: types.CLEAR_NEW_NOTIF_REFERENCES
});

export const setMaintenance = (maintenanceType) => ({
    type: types.SET_MAINTENANCE,
    payload: maintenanceType
});
export const refreshTokens = (data) => ({
    type: types.REFRESH_TOKENS,
    payload: data,
});

export const setProfileImage = (data) => ({
    type: types.SET_PROFILE_IMAGE,
    payload: data,
});

export const setHandleName = (handleName) => ({
    type: types.SET_HANDLE_NAME,
    payload: handleName,
})

export const setAdminBackdoorCheck = (params) => ({
    type: types.SET_ADMIN_BACKDOOR_CHECK,
    payload: params
});
import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { Switch, withRouter, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { LOGIN_ROUTE } from '../Authentication/routes';
import { adminRoutes } from '../../../app.modules';
import { ADMIN_ROOT_ROUTE } from './routes';
import PrivateAdminRoute from '../../components/PrivateAdminRoute';
import AdminHeader from '../../components/AdminHeader';
import AdminSideNav from '../../components/AdminSideNav';
import PasswordChangeModal from '../PasswordChange/PasswordChangeModal';
import { passwordChangeOperations } from '../PasswordChange/state';
import { onApiSuccess, refreshTokens } from '../../../utils/common';
import ProcessLoader from '../../components/ProcessLoader';
import { MSG009_SYS_ERR_SAVING_RECORD, MSG071_COGNITO_ERROR } from '../../../utils/messages/admin/errorMessages';
import { maintenanceOperations } from '../Maintenance/state'; 
import './styles/container.scss';
import { authOperations } from '../Authentication/state';
import { USER_TYPE } from '../../../utils/constants';
import ErrorModal from '../../components/ErrorModal';

const AdminContainer = (props) => {
  //Hooks state
  const [isModalShow, setIsModalShow] = useState(false);
  const [currPassword, setCurrPassword] = useState(null);
  const [modalType, setModalType] = useState('update');
  const [prevModal, setPrevModal] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [dataValidationError, setDataValidationError] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);

  //Redux store data
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const credentials = useSelector(state => state.auth.credentials);
  const [maintenanceInit, setMaintenanceInit] = useState(false);

  const dispatch = useDispatch();
  const operations = bindActionCreators(
    {
      updatePassword: passwordChangeOperations.updatePassword,
      getMaintenanceSettings: maintenanceOperations.getMaintenanceSettings,
      refreshTokens: authOperations.refreshTokens
    },
    dispatch
  )

  const handleConfirmClick = (values) => {
    setCurrPassword(values.PasswordChangeModel);
    setModalType('confirm');
    setPrevModal('update');
  }

  const handleShowEditModal = () => {
    setModalType('update');
    setPrevModal('confirm');
  }

  const handleShowSuccessModal = () => {
    setModalType('success');
    setIsProcessing(false)
  }

  const handleCloseModal = () => {
    setPrevModal('');
    setModalType('update');
    setIsModalShow(false)
  }

  const setModalProps = () => {
    switch (modalType) {
        case 'update': {
            return {
                prevModal: prevModal,
                handleCloseModal: handleCloseModal,
                handleConfirm: handleConfirmClick,
                handleSubmit: handleConfirmClick,
            }
        }
        case 'confirm': {
            return {
                handleConfirm: handleConfirmClick,
                handleSubmit: handleConfirmClick,
                handleCloseModal: handleCloseModal,
                handleShowEditModal: handleShowEditModal,
                handleUpdate: handleUpdateSuccess,
                dataValidationError: dataValidationError,
            }
        }
        case 'success': {
            return {
                handleCloseModal: handleCloseModal,
            }
        }
        default: return {};
    }
  }

  const handleUpdateSuccess = () => {
    setIsProcessing(true);
    currPassword.username = credentials.username;
    currPassword.userId = credentials.attributes.preferred_username;
    operations.updatePassword(currPassword)
      .then(res => {
        if (onApiSuccess(res)) {
            handleShowSuccessModal(true);
        } else {
            setDataValidationError(MSG071_COGNITO_ERROR);
            setShowErrorModal(true)
            setIsProcessing(false)
        }
    }).catch(error => {
        console.log(error)
        setDataValidationError(MSG009_SYS_ERR_SAVING_RECORD);
        setShowErrorModal(true)
        setIsProcessing(false)
    });
  }

  const handleTokenExpire = (operations) => {
    if(credentials.hasOwnProperty('signInUserSession')) {
      if(credentials.signInUserSession.hasOwnProperty('accessToken')) {
        const currTimeStamp = Math.floor(Date.now()/ 1000);
        const expTimestamp = credentials.signInUserSession.accessToken.payload.exp;

        let diff = Math.floor((expTimestamp - currTimeStamp) / 60);

        //Refresh 20 minutes before expiry
        if (diff < 20 || expTimestamp <= currTimeStamp) {
          /**
           * if current timestamp reaches token expiration
           * perform refresh access token
           * **/
          refreshTokens(operations, USER_TYPE.admin);
        }
      }
    }
  }

  useEffect(()=>{
    if(!maintenanceInit) {
      operations.getMaintenanceSettings();
      setMaintenanceInit(true);
    }
  }, [operations, maintenanceInit]);

  useEffect(() => {
    handleTokenExpire(operations);
  })

  if (!isAuthenticated || (credentials && "ROLE_ADMIN" !== credentials.access)) {
    return <Redirect to={{
      pathname: LOGIN_ROUTE,
    }} />
  }

  return (
    <div className="admin-container">
      <AdminSideNav/>
      <div className="admin-content">
        <AdminHeader handleShowModal={()=>setIsModalShow(true)} />
        <ProcessLoader isShown={isProcessing} />
        <PasswordChangeModal
          isModalShow={isModalShow}
          currPassword={currPassword}
          type={modalType}
          {...setModalProps()}
        />
        <div className="admin-body">
          <Switch>
            {adminRoutes.map((route, i) => {
              return (
                <PrivateAdminRoute
                  {...props.location}
                  key={"adminroute" + i}
                  path={ADMIN_ROOT_ROUTE + route.path}
                  component={route.component}
                  exact={route.exact}
                />
              )
            }
            )}
          </Switch>
        </div>

        <ErrorModal
          errorMessage={dataValidationError}
          isErrorModalShow={showErrorModal}
          handleCloseModal={()=>setShowErrorModal(false)}
        />
      </div>
    </div>
  );
}

export default withRouter(AdminContainer);

import React, { Component } from 'react';
import { defaultTopScroll, isStringNullEmptyOrUndefined, getObjKeyIdString, appendUserRoute, isScrollRefetchReached, onApiSuccess } from '../../../utils/common';
import { Media } from 'react-breakpoints'
import { isMobile } from "react-device-detect";
import PCOnlyView from '../../components/PCOnlyView';
import { Tab, Card } from 'react-bootstrap';
import { ActivityIndicator } from 'react-native';
import { Button, Icon } from 'react-native-elements';
import ErrorModal from '../../components/ErrorModal';
import SearchPostItemTemplate from './SearchPostItemTemplate';
import { searchOperations } from './state';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NO_RESULTS_FOUND, SYSTEM_ERROR } from '../../../utils/messages';
import format from "date-fns/format";
import './styles/search.scss';
import styles from './styles/styles.native';
import CalendarIconFilters from './CalendarIconFilters';
import PostDetailModal from '../../components/PostDetailModal'; //task#363

/**For scroll direction check */
var scrollPos = 0;

class PostByDate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dateSelected: null,
            activeTab: "posts",
            searchInitiated: false,
            isSearching: false,
            isFetching: false,
            msgErrorModal: "",
            showErrorModal: false,
            postsList: [],
            currentPaginationPosts: 1,//task#363
            postDetailPostId: "" //task#363
        }
    }

    componentDidMount(){
        defaultTopScroll();
        
        const query = new URLSearchParams(this.props.location.search);
        let dateSearchField = query.get('date');

        if(!isStringNullEmptyOrUndefined(dateSearchField)){
            this.setState({
                dateSelected: dateSearchField
            }, ()=>{
                this.getFilteredPost("", true);
            })
        }

        document.addEventListener('scroll', this.onScrollCheck);
    }

    componentDidUpdate(prevProps) {
        //detect URL query params update
        if (this.props.location.search !== prevProps.location.search) {
            const query = new URLSearchParams(this.props.location.search);
            let dateSearchField = query.get('date');

            if(!isStringNullEmptyOrUndefined(dateSearchField)){
                this.setState({
                    dateSelected: dateSearchField
                }, ()=>{
                    this.getFilteredPost("", true);
                })
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.onScrollCheck);
    }

    onScrollCheck = () => {
        let isScrollDown = (document.body.getBoundingClientRect()).top < scrollPos;
        let isEndReached = this.state.postEndReached;

        if (isScrollRefetchReached() && !this.state.isFetching && !this.state.isSearching && isScrollDown && !isEndReached && this.state.searchInitiated) {
            this.handlePagination();
        }

        scrollPos = (document.body.getBoundingClientRect()).top;
    }

    handlePagination = () => {
        if(!this.state.isFetching){
            let currentPagination = this.state.currentPaginationPosts;

            let params = {
                userId: this.props.user.userId,
                dateSelected: this.state.dateSelected,
                currentPagination: currentPagination,
            }

            this.setState({ isFetching: true }, () => {
                this.props.executeSearchByDate(params)
                    .then(res => {
                        if (onApiSuccess(res)) {
                            let resultData = JSON.parse(res.data.data);
                            let hasPosts = 0 < resultData.length;

                            this.setState(prevState => ({
                                postsList: [...prevState.postsList, ...resultData],
                                currentPaginationPosts: hasPosts ? prevState.currentPaginationPosts + 1 : prevState.currentPaginationPosts,
                                isFetching: false,
                                postEndReached: !hasPosts
                            }));
                        } else {
                            this.showSystemError();
                        }
                    }).catch(() => this.showSystemError())
            })
        }
    }

    showSystemError = () => {
        this.setState({
            isFetching: false,
            isSearching: false,
            msgErrorModal: SYSTEM_ERROR,
            showErrorModal: true,
            postsList: [],
        })
    }

    searchEmptyCheck = () =>{
        return 0 === (this.state.dateSelected.trim()).length;
    }

    getFilteredPost = (event, backSearch) =>{
        if (event) {
            event.preventDefault()
        }

        if (!this.searchEmptyCheck()) {
            defaultTopScroll();

            let params = {
                userId: this.props.user.userId,
                dateSelected: this.state.dateSelected,
                currentPagination: 1,
            }

            if(!backSearch){
                let dateSelected = encodeURIComponent(this.state.dateSelected.trim())

                this.props.history.push(
                    appendUserRoute(
                        `/search/post/filter?date=${dateSelected}`
                    )
                );
            }

            this.setState({ isSearching: true }, () => {
                this.props.executeSearchByDate(params)
                    .then(res => {
                        if (onApiSuccess(res)) {
                            let resultData = JSON.parse(res.data.data);
                            let hasPosts = 0 < resultData.length;
                            
                            this.setState(prevState => ({
                                postsList: resultData,
                                currentPaginationPosts: hasPosts ? 2 : 1,
                                searchInitiated: true,
                                isSearching: false,
                                searchString: prevState.dateSelected.trim(),
                                allEndReached: false,
                                userEndReached: false,
                                postEndReached: false
                            }))

                        } else {
                            this.showSystemError();
                        }
                    }).catch(() => this.showSystemError())
            })
        }
    }

    toggleErrorModal = () => {
        this.setState(prevState => ({ showErrorModal: !prevState.showErrorModal }))
    }

    handlePostOnly = (mobileView) => {
        if (this.state.isSearching) {
            return <ActivityIndicator style={{ paddingTop: 20 }} />
        }

        if (0 === this.state.postsList.length) {
            return <p className="no-result" style={{ marginTop: 0 }}>{NO_RESULTS_FOUND}</p>
        }

        return (
            this.state.postsList.map(item => {
                return <SearchPostItemTemplate hash={this.state.hash} key={getObjKeyIdString(item._id)} {...item} mobileView={mobileView} 
                setPostDetailModal={this.setPostDetailModal}/**task#363**//>
            })
        )
    }

    setPostDetailModal = (postId) =>{ //task#363 start
        this.setState({showPostDetailModal: true, postDetailPostId: postId})
    }

    closePostDetailModal = () =>{
        this.setState({showPostDetailModal: false})
    } //task#363 end

    render() {
        return (
            <Media>
                {({ breakpoints, currentBreakpoint }) => {
                    let mobileView = (breakpoints[currentBreakpoint] < breakpoints.mobileLandscape) || isMobile;
                    return (
                        <div className={mobileView ? "tab-content-mobile" : "tab-content-web"}>
                            <Tab.Container
                                defaultActiveKey="posts"
                                activeKey={this.state.activeTab}
                                style={{ backgroundColor: "red" }}
                            >
                                <div className={mobileView ? "search-bar-mobile col-sm-12 p-0" : "search-bar-web"}>
                                    <div className={mobileView ? "search-mobile" : "search-web"}>
                                        <PCOnlyView>
                                            <Card.Title className="search-title">
                                                <span>日記を日付で検索</span>
                                                <Button
                                                    key={"back"}
                                                    type="clear"
                                                    icon={
                                                        <Icon
                                                        name="chevron-left"
                                                        size={24}
                                                        color="black"
                                                        type={"material"}
                                                        />
                                                    }
                                                    title={"戻る"}
                                                    titleStyle={{fontSize: 16, color: "#000"}}
                                                    buttonStyle={{padding: 0, margin: 0}}
                                                    onPress={() => this.props.history.push(appendUserRoute("/search"))}
                                                />

                                            </Card.Title>
                                        </PCOnlyView>
                                        <div className={`search-container-${mobileView ? "mobile" : "web"} pt-3 pl-1 pr-1`}>
                                            <CalendarIconFilters/>
                                            <p className="text-center mb-0 mt-1">{this.state.dateSelected}</p>
                                        </div>
                                    </div>
                                </div>
                                
                                {this.state.searchInitiated ? (
                                    <Tab.Content style={{ paddingTop: mobileView ? 115 : 0 }}>
                                        <Tab.Pane eventKey="posts" className={"tab-pane"}>
                                            {this.handlePostOnly(mobileView)}
                                        </Tab.Pane>
                                    </Tab.Content>
                                ) : (
                                        <center>
                                            <ActivityIndicator
                                                style={{
                                                    paddingTop: 115,
                                                    display: this.state.isSearching ? "block" : "none"
                                                }}
                                            />
                                        </center>
                                    )}

                                <center>
                                    <ActivityIndicator
                                        style={{
                                            paddingTop: 10,
                                            paddingBottom: 24,
                                            display: this.state.isFetching ? "block" : "none"
                                        }}
                                    />
                                </center>
                            </Tab.Container>

                            <ErrorModal
                                errorMessage={this.state.msgErrorModal}
                                isErrorModalShow={this.state.showErrorModal}
                                handleCloseModal={this.toggleErrorModal}
                            />

                            <PostDetailModal /**task#363 start**/
                                isModalShow={this.state.showPostDetailModal}
                                confirmationText={this.state.postDetailPostId}
                                confirmTitle={"SearchByDate"}
                                handleConfirmAction={()=>this.redirectViewPost(this.state.postDetailPostId)}
                                handleCloseModal={this.closePostDetailModal}
                            /**task#363**/ /> 

                        </div>
                    )
                }}
            </Media>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.credentials
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        executeSearchByDate: searchOperations.executeSearchByDate,
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(PostByDate);
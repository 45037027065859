import React from 'react';
import { useSelector } from 'react-redux';
import { isNullOrUndefined } from 'util';
import Badge from 'react-bootstrap/Badge'

const ChatNotificationBadge = ({ mobile }) => {
    //Redux store data
    const unseenChatThreadCount = useSelector(state => state.auth.unseenChatThreadCount);

    if(isNullOrUndefined(unseenChatThreadCount) || 0 === unseenChatThreadCount){
        return ""
    }
    
    return (
        <Badge 
            pill 
            variant="danger"
            style={mobile ? { position: 'absolute', marginLeft: 20, marginTop: -24 }
            : { position: 'absolute', marginLeft: 100, marginTop: -21 }}
        >
            {unseenChatThreadCount}
        </Badge>
    );
};

export default ChatNotificationBadge;
import * as Path from "./apiRoutes";
import ApiService from "../../../../utils/apiService";

const executeSearch = (params) => () => {
    return ApiService.post(Path.EXECUTE_SEARCH, params)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
};

const executeSearchByDate = (params) => () => {
    return ApiService.post(Path.EXECUTE_SEARCH_BY_DATE, params)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
};

export {
    executeSearch,
    executeSearchByDate
}
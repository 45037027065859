import React from 'react';
import { Pagination, Button } from 'react-bootstrap';
import '../../components/PaginationTemplate/styles.scss';
import { isStringNullEmptyOrUndefined } from '../../../utils/common';

const UserPaginationTemplate = ({currentPage, totalPage, handlePageChange, handleNextPage, paginationToken}) => {

    let firstPrev = 1 === currentPage
    let items = [];

    for (let number = 1; number <= totalPage; number++) {
        items.push(
            <Pagination.Item key={number} active={number === currentPage} onClick={() => {handlePageChange(number)}}>
                {number}
            </Pagination.Item>
        );
    }

    return (
        
        <div>
            <Pagination style={{ float: 'right' }}>
                <Button className="pagination-caret" variant="link" disabled={firstPrev} onClick={() => { handlePageChange(currentPage-1) }}>{"<"}</Button>
                {items}
                <Button className="pagination-caret" variant="link" disabled={(1 === totalPage || currentPage === totalPage)} onClick={() => { handlePageChange(currentPage+1) }}>{">"}</Button>
                {!isStringNullEmptyOrUndefined(paginationToken) && (<Button className="pagination-caret" variant="link" onClick={() => { handleNextPage(currentPage+1) }}>{"次ページへ"}</Button>)}

            </Pagination>
        </div>
    )
}

export default UserPaginationTemplate;
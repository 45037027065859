import * as types from "./types";

export const updateNewsfeedList = data => ({
    type: types.UPDATE_NEWSFEED_LIST,
    payload: data
  });

export const appendNewsfeedList = data => ({
  type: types.APPEND_NEWSFEED_LIST,
  payload: data
});

export const removeNewsfeedPost = data => ({
  type: types.REMOVE_NEWSFEED_POST,
  payload: data
})

export const updatePostImageSocket = data => ({
  type: types.UPDATE_POST_IMAGE_SOCKET,
  payload: data
})

export const updatePostVideoSocket = data => ({
  type: types.UPDATE_POST_VIDEO_SOCKET,
  payload: data
})

export const updateAdminNotice = data => ({
  type: types.UPDATE_ADMIN_NOTICE,
  payload: data
})

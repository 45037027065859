import * as types from "./types";

const defaultState = {
    //Module state
    notificationList:[]
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.SET_NOTIF_LIST:
            return {
                ...state,
                notificationList: action.payload
            };
            case types.INSERT_NOTIF:
                return { 
                    ...state,
                    notificationList: [ ...state.notificationList, action.payload ]
                                        .sort((a,b) => { return new Date(b.createViewDt) - new Date(a.createViewDt); })
                };
            case types.UPDATE_NOTIF: 
                return {
                    ...state,
                    notificationList: [ ...state.notificationList.map((notif) => {
                        if(notif._id === action.payload._id) {
                            Object.keys(action.payload).forEach((key) => {
                                notif[key] = action.payload[key];
                            });
                        }
                        return notif;
                    }) ]
                };
            case types.DELETE_NOTIF:
                return {
                    ...state,
                    notificationList: state.notificationList.filter(notif => notif._id !== action.payload)
                };
        default: return state;
    }
};
import React from 'react';
import './styles.scss';
import RenderPollResult from './RenderPollResult';
import { isNullOrUndefined, customHTMLParse } from '../../../utils/common';
import { COMMUNITY_POST_TYPES } from '../../../utils/constants';
import LinkTransform from '../LinkTransform';

const PostContent = ({ title, postBody, poll , postType}) => {
    let pollContent = null;

    if(!isNullOrUndefined(poll) && 0 < Object.keys(poll).length && COMMUNITY_POST_TYPES.poll === postType){
        pollContent = <RenderPollResult poll={poll} />;
    }

    return (
        <React.Fragment>
            <p className="post-title">{title}</p>
            <p>
                <LinkTransform>
                    {customHTMLParse(postBody)}
                </LinkTransform>
            </p>
            {pollContent}
        </React.Fragment>
    )
}


export default PostContent;
import * as types from "./types";

export const setNotifList = ( data ) => ({
    type: types.SET_NOTIF_LIST,
    payload: data,
});

export const insertNotif = ( data ) => ({
    type: types.INSERT_NOTIF,
    payload: data,
});

export const updateNotif = ( data ) => ({
    type: types.UPDATE_NOTIF,
    payload: data,
});

export const deleteNotif = ( index ) => ({
    type: types.DELETE_NOTIF,
    payload: index,
});
import React from 'react';
import ContentLoader from 'react-content-loader'
import PCOnlyView from '../PCOnlyView';
import MobileOnlyView from '../MobileOnlyView';

const RowSkeleton = (
    <ContentLoader
        speed={2}
        width={'30%'}
        height={180}
        viewBox=" 0 800 80"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        className="svg-content-loader"
    >
        <PCOnlyView>
            <rect x="10" y="20" width="100%" height="150" rx="3" />
            <rect x="30%" y="175" width="50%" height="8" rx="3s" />
        </PCOnlyView>
        <MobileOnlyView>
            <rect x="10" y="20" width="100%" height="120" rx="3" />
            <rect x="30%" y="145" width="50%" height="8" rx="3s" />
        </MobileOnlyView>

    </ContentLoader>
);

const MyFriendsSkeleton = () => {
    return (
        <div className="friends-skeleton">
            {RowSkeleton}
            {RowSkeleton}
            {RowSkeleton}
        </div>
    );
};

export default MyFriendsSkeleton;
import reducer from "./reducers";
import { routes } from "../routes";
import * as pastpostOperations from "./operations"

export {pastpostOperations}

export default {
    reducer,
    routes
};

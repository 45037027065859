import newsfeed from "../../../includes/img/user-menu/newsfeed-dark.svg";
import mypage from "../../../includes/img/user-menu/mypage-dark.svg";
import chat from "../../../includes/img/user-menu/chat-dark.svg";
import search from "../../../includes/img/user-menu/search.svg";
import notification from "../../../includes/img/user-menu/notification-dark.svg";
import friends from "../../../includes/img/user-menu/friends-dark.svg";
import block from "../../../includes/img/user-menu/block-dark.svg";
import help from "../../../includes/img/user-menu/help-dark.svg";
import contact from "../../../includes/img/user-menu/contact-dark.svg";
import community from "../../../includes/img/user-menu/community-dark.svg";
import document from "../../../includes/img/user-menu/document-dark.svg";
import dotMenu from '../../../includes/img/icons/dot-menu.svg';
import camera from '../../../includes/img/icons/camera.svg';
import addFriend from "../../../includes/img/user-menu/friends.svg"
import menuBox from "../../../includes/img/icons/menu-box.svg"
import hamburger from "../../../includes/img/user-menu/burger.svg";
import newChat from "../../../includes/img/icons/edit.svg";
import send from "../../../includes/img/icons/send.svg";
import gallery from '../../../includes/img/icons/gallery.svg';
import deleteIcon from "../../../includes/img/icons/delete.svg";

import {FFMWEB_ROOT} from "../../../config/settings";

const helpData = [
  {
    itemNo: 1,
    category: "アイコン",
    isCategoryOpen: false,
    questions: [
      {
        questionNo: 1,
        question: "各アイコンが示すものは？",
        isOpen: false,
        answerClassName: "help-icons-line-spacing",
        answer: 
        `<p class="mb-0"><img class='help-icons-medium' src="${mypage}"/>：マイページ・・・ご自身のプロフィール編集や日記投稿ができます</p>\n`+
        `<p class="mb-0"><img class='help-icons-medium' src="${notification}"/>：新着情報・・・新しい友達申請や新着コメントなどのお知らせが表示されます</p>\n`+
        `<p class="mb-0"><img class='help-icons-medium' src="${contact}"/>：お問い合わせ・・・入力フォームから管理者へ問い合わせができます</p>\n`+
        `<p class="mb-0"><img class='help-icons-medium' src="${newsfeed}"/>：最新の更新・・・友達の最新日記が表示されます</p>\n`+
        `<p class="mb-0"><img class='help-icons-medium' src="${friends}"/>：友達リスト・・・友達申請により追加したメンバーが表示されます</p>\n`+
        `<p class="mb-0"><img class='help-icons-medium' src="${community}"/>：過去のオフィシャルコミュニティ・・・過去のライブ密着レポートなどのアーカイブが表示されます</p>\n`+
        `<p class="mb-0"><img class='help-icons-medium' src="${chat}"/>：メッセージ・・・メンバーと直接メッセージをやりとりできます</p>\n`+
        `<p class="mb-0"><img class='help-icons-medium' src="${block}"/>：アクセスブロック・・・ブロックした人を確認できます</p>\n`+
        `<p class="mb-0"><img class='help-icons-medium' src="${document}"/>：プライバシーポリシー、または利用規約・・・規約等の確認ができます</p>\n` +
        `<p class="mb-0"><img class='help-icons-medium' src="${search}"/>：検索・・・キーワードや日付から公開中の日記やメンバーが検索できます</p>\n`+
        `<p class="mb-0"><img class='help-icons-medium' src="${help}"/>：Q&A／ヘルプ・・・comu comuの利用方法などが確認できます</p>`
      }
    ]
  },
  {
    itemNo: 2,
    category: "アーティストとスタッフ",
    isCategoryOpen: false,
    questions: [
      {
        questionNo: 1,
        question: "「友達リスト」内のフミヤ、FF-Staffとは？",
        isQuestionOpen: false,
        answer: "comu comuでは、初回ログイン時からあなたのお友達として、フミヤとFFスタッフを「友達リスト」に登録しています。\n"+
        "これにより、フミヤやFFスタッフの日記が更新されると「最新の更新」に反映され、友達リストからダイレクトにそれぞれのページへアクセスすることができます。\n" +
        "なお、フミヤおよびFFスタッフは通常のメンバーとは設定が異なるため、友達リストから外すことやメッセージを送信することはできません。ご了承ください。"
      }
    ]
  },
  {
   itemNo: 3,
   category: "最新の更新",
   isCategoryOpen: false,
   questions: [
     {
       questionNo: 1,
       question: "最新の更新とは？",
       isQuestionOpen: false,
       answer: "comu comuにログインしたときに最初に表示される画面のことです。\n"+
       "フミヤやFFスタッフをはじめ、友達の最新日記をいつでも確認することができます。"
     }
   ]
 },
 {
   itemNo: 4,
   category: "日記",
   isCategoryOpen: false,
   questions: [
    {
      questionNo: 1,
      question: "日記の全文を読みたい。",
      isQuestionOpen: false,
      answer: "1.長文の日記など、全文を読みたい日記の本文もしくは左下の[コメントする]をタップ(クリック)してください。\n"+
      "2.タップ(クリック)した日記の全文(詳細画面)がポップアップで開きます。\n"+
      `コメントを投稿する場合は、「コメントを投稿する」欄にコメントを入力し、<img class='help-icons' src="${send}"/>[投稿]をタップ(クリック)します。\n`+
      "*フミヤの日記には1日記につき、お一人あたり1コメントのみの投稿となります。また、投稿できるコメントは1,000文字までとなります。\n"+
      "*日記詳細(ポップアップ)画面上でユーザのアイコンをタップ(クリック)すると、そのユーザのマイページが別タブで開きます。\n"+
      "3.日記詳細(ポップアップ)画面右上の[×]ボタンをタップ(クリック)すると、日記詳細(ポップアップ)画面が閉じます。"
    },
    {
      questionNo: 2,
      question: "日記を投稿したい。",
      isQuestionOpen: false,
      answer: `1.【スマートフォン】画面上部の<img class='help-icons' src="${mypage}"/>アイコン(マイページ)をタップ＞友達リスト下の「日記を投稿しよう」欄をタップしてください。\n`+
      `【PC】「最新の更新」または画面右側の<img class='help-icons' src="${mypage}"/>アイコン(マイページ)をクリック＞「投稿を作成する」下の「日記を投稿しよう」欄をクリックしてください。\n`+
      "2.日記投稿画面に本文を入力します。\n"+
      `3.下部の公開レベルを選択します。写真を添付したい場合は<img class='help-icons' src="${gallery}"/>(写真)アイコンをクリックし、写真ファイルをアップロードします。(写真のみを投稿することも可能)\n`+
      "4.[日記を投稿する]ボタンをタップ(クリック)します。\n"+
      "5.投稿された日記がマイページに表示され、友達の「最新の更新」ページにも反映されます。"
     },
     {
      questionNo: 3,
      question: "日記に投稿できる写真ファイルの形式は？",
      isQuestionOpen: false,
      answer: "1ファイルにつき10MBまでのJPEG、およびPNG形式に対応しています。日記1件に添付できる写真は30点まで可能です。動画はアップロードできません。"
     },
     {
      questionNo: 4,
      question: "投稿した日記を編集したい。",
      isQuestionOpen: false,
      answer: `1.編集したい日記右上の<img class='help-icons' src="${dotMenu}"/>(ミートボール)メニュー -> [日記を編集]をタップ(クリック)してください。\n`+
      "2.日記編集画面が表示されます。\n"+
      "3.編集終了後、[日記を投稿する]ボタンをタップ(クリック)します。編集した日記には「編集済」と表示されます。"
     },
     {
      questionNo: 5,
      question: "日記に投稿した写真を削除したい。",
      isQuestionOpen: false,
      answer: `1.編集したい日記右上の<img class='help-icons' src="${dotMenu}"/>(ミートボール)メニュー -> [日記を編集]をタップ(クリック)してください。\n`+
      "2.日記編集画面が表示されます。\n"+
      `3.削除したい写真右上の<img class='help-icons' src="${dotMenu}"/>(ミートボール)メニュー -> [写真を削除]をタップ(クリック)してください。\n`+
      "4.写真が削除されたら、[日記を投稿する]ボタンをタップ(クリック)します。"
     },
     {
      questionNo: 6,
      question: "投稿した日記を削除したい。",
      isQuestionOpen: false,
      answer: `1.編集したい日記右上の<img class='help-icons' src="${dotMenu}"/>(ミートボール)メニュー -> [日記を削除]をタップ(クリック)してください。\n`+
      "2.確認メッセージがポップアップで表示されたら、[削除]ボタンをタップ(クリック)します。"
     },
     {
      questionNo: 7,
      question: "日記へコメントを投稿したい。",
      isQuestionOpen: false,
      answer: "1.コメントを投稿したい日記の本文もしくは左下の[コメントする]をタップ(クリック)してください。\n"+
      "2.タップ(クリック)した日記の全文(詳細画面)がポップアップで開きます。\n"+
      `コメントを投稿する場合は、「コメントを投稿する」欄にコメントを入力し、<img class='help-icons' src="${send}"/>[投稿]をタップ(クリック)します。\n`+
      "*フミヤの日記には1日記につき、お一人あたり1コメントのみの投稿となります。また、投稿できるコメントは1,000文字までとなります。\n"+
      "*日記詳細(ポップアップ)画面上でユーザのアイコンをタップ(クリック)すると、そのユーザのマイページが別タブで開きます。\n"+
      "3.日記詳細(ポップアップ)画面右上の[×]ボタンをタップ(クリック)すると、日記詳細(ポップアップ)画面が閉じます。\n"+
      "*日記詳細(ポップアップ)画面を閉じると元の日記のコメント数は増えていませんが、日記詳細(ポップアップ)画面で投稿が完了している内容は次に表示した際に投稿数に反映されます。"
     },
     {
      questionNo: 8,
      question: "日記へ投稿したコメントを編集したい。",
      isQuestionOpen: false,
      answer: "1.編集したいコメント下部の[編集]をタップ(クリック)してください。\n"+
      "2.コメントを編集し、[保存]ボタンをタップ(クリック)します。編集したコメントには「編集済」と表示されます。"
     },
     {
      questionNo: 9,
      question: "日記へ投稿したコメントを削除したい。",
      isQuestionOpen: false,
      answer: "1.編集したいコメント下部の[削除]をタップ(クリック)してください。\n"+
      "2.確認メッセージがポップアップで表示されたら、[はい]をタップ(クリック)します。\n"+
      "*フミヤの日記に対するコメントを削除した場合、同じ日記に新たにコメントを投稿することはできません。内容を変更したい場合は[編集]をタップ(クリック)して修正してください。\n"+
      "*削除したいコメントに他ユーザーの返信コメントがある場合は、合わせて削除されます。"
     },
     {
      questionNo: 10,
      question: "友達のコメントもしくは自分のコメントに返信したい。",
      isQuestionOpen: false,
      answer: "1.返信したいコメント下部の[返信]をタップ(クリック)してください。\n"+
      "2.返信コメントを入力し、[返信](または保存)をタップ(クリック)してください。"
     },
     {
      questionNo: 11,
      question: "フミヤの過去の日記を見たい。",
      isQuestionOpen: false,
      answer: "1.2020年10月以降の日記：最新のフミヤの日記から一番下までスクロールしてご覧ください。\n"+
      "2.2006年12月〜2020年9月までの日記：フミヤのページ＞「その他」＞「過去の投稿を見る」からスクロールしてください。\n"+
      "また「過去の投稿」画面上部の「月別に見る」では、【2006年12月〜2011年3月】【2011年3月〜2020年9月】のカレンダーから、青で表示された見たい月をタップ(クリック)してください。"
     },
     {
      questionNo: 12,
      question: "自分の過去の日記を見たい。",
      isQuestionOpen: false,
      answer: "自身のマイページトップ＞プロフィール画像横のミートボール(・・・)をタップ(クリック)＞「過去の投稿を見る」からスクロールしてご覧ください。\n"+
      "また「過去の投稿」画面上部の「月別に見る」では、【2006年12月〜2011年3月】【2011年3月〜2020年9月】のカレンダーから、青で表示された見たい月をタップ(クリック)してください。"
     },
     {
      questionNo: 13,
      question: "日記の投稿には容量制限はある？",
      isQuestionOpen: false,
      answer: "お一人が日記で投稿できる画像の容量は合計5GBまでとなり、5GBを超えてアップロードしようとするとエラーメッセージが表示されます。\n"+
      "空き容量を増やすには、過去にアップロードした写真ファイルの削除を行ってください。文章のみの投稿の場合は制限はありません。"
     },
     {
      questionNo: 14,
      question: "HTMLタグは使える？",
      isQuestionOpen: false,
      answerInitial: `本文欄にて、URLリンクタグが利用可能です。以下のように記述した場合、表示は【<a href="${FFMWEB_ROOT}">FFM</a>】となり、" "内のURLにリンクが張られます。\n`,
      answer:
      `記述方法（例）<a href="https://www.ffm.co.jp">FFM</a>\n`+
      "また、タグを使わずにURLを記載すると、そのままURLの文字からリンクが張られます。なお、それ以外のタグは無効となり、ご利用いただけません。",
      disableParse: true
     },
   ]
 },
 {
   itemNo: 5,
   category: "マイページ",
   isCategoryOpen: false,
   questions: [
     {
       questionNo: 1,
       question: "サムネイル（プロフィール写真）、背景写真を設定・更新したい。",
       isQuestionOpen: false,
       answer: `1.<img class='help-icons' src="${mypage}"/>アイコン(マイページ)をタップ(クリック)してください。\n`+
       `2.サムネイル（プロフィール写真）、背景写真欄にある<img class='help-icons' src="${camera}"/>(カメラ)アイコンをタップ(クリック)し、写真をアップロードします。`
     },
     {
      questionNo: 2,
      question: "プロフィールを更新したい。",
      isQuestionOpen: false,
      answer: `1. <img class='help-icons' src="${mypage}"/> アイコン(マイページ)をタップ(クリック)してください。\n`+
      "2.[プロフィール編集]をタップ(クリック)します。\n"+
      "3.内容を更新して、[保存]をタップ(クリック)します。"
    },
    {
      questionNo: 3,
      question: "非公開に設定したプロフィールがマイページに表示されているが？",
      isQuestionOpen: false,
      answer: "ご自身のマイページには、公開範囲に関わらず、すべての項目が表示されます。プレビューにて(※)実際の表示状況をご確認ください。\n"+
      `※プロフィール写真横の<img class='help-icons' src="${dotMenu}"/>(ミートボール)メニュー -> [プレビュー]をタップ(クリック)`
    },
   ]
 },
 {
  itemNo: 6,
  category: "友達の追加・削除・ブロック",
  isCategoryOpen: false,
  questions: [
    {
      questionNo: 1,
      question: "友達を追加したい。",
      isQuestionOpen: false,
      answer: `1.友達になりたいユーザーのページにアクセスし、<img class='help-icons' src="${addFriend}"/>(友達になる)アイコンをタップ(クリック)してください。\n`+
      "2.送信確認画面で「追加(送る)」または「戻る(送らない)」を選択します。「追加」を選択すると、友達リクエストが送信されます。\n"+
      "3.相手の方がリクエストを承認すると「新着情報」にお知らせが届き、友達リストに追加されます。\n"+
      "*原則として、交流のない方へは、唐突な友達リクエストは送らず、日記へのコメントで交流をしてから友達申請をするようにしましょう。"
    },
    {
      questionNo: 2,
      question: "アクセスブロックしたい。",
      isQuestionOpen: false,
      answer: "「アクセスブロック」とは、あなたのページを閲覧してほしくないユーザーからのアクセスを拒否する機能です。アクセスブロックした相手に対し通知されることはありません。\n"+
      `1.アクセスブロックしたいユーザーのページにアクセスし、<img class='help-icons' src="${menuBox}"/>(その他)アイコン -> [ブロックする]をタップ(クリック)してください。\n`+
      "2.確認メッセージがポップアップで表示されたら、[ブロック]をタップ(クリック)します。\n"+
      "3.アクセスブロックしたユーザーのページが再読み込みされ、ブロック完了です。"
    },
    {
      questionNo: 3,
      question: "アクセスブロック済のユーザーを確認したい。",
      isQuestionOpen: false,
      answer: `画面右上の<img class='help-icons-small' src="${hamburger}"/>(ハンバーガー)メニュー -> 「アクセスブロック」をタップ(クリック)してください。アクセスブロックしたユーザーが一覧表示されます。`
    },
    {
      questionNo: 4,
      question: "アクセスブロックを解除したい。",
      isQuestionOpen: false,
      answer: `1.画面右上の<img class='help-icons-small' src="${hamburger}"/>(ハンバーガー)メニュー -> 「アクセスブロック」をタップ(クリック)してください。\n`+
      "2.ブロックを解除したいユーザーをタップ(クリック)し、ユーザーのページにアクセスします。\n"+
      `3.<img class='help-icons' src="${menuBox}"/>(その他)アイコン-> [ブロックを解除する]をタップ(クリック)してください。\n`+
      "4.確認メッセージがポップアップで表示されたら、[解除]をタップ(クリック)します。\n"+
      "5.ブロック解除したユーザーのページが再読み込みされ、ブロック解除完了です。\n"
    },
  ]
  },
  {
    itemNo: 7,
    category: "メッセージ",
    isCategoryOpen: false,
    questions: [
      {
        questionNo: 1,
        question: "メッセージとは？",
        isQuestionOpen: false,
        answer: "comu comuメンバー同士でメッセージを交換するための機能です。\n"+
        "メッセージの保存期間は3ヶ月となり、それを過ぎると自動的に消去されます。\n"+
        "なお、フミヤやFFスタッフへメッセージを送信することはできません。"
      },
      {
        questionNo: 2,
        question: "友達へメッセージや写真を送りたい。",
        isQuestionOpen: false,
        answer: "以下の2つの方法があります。\n\n"+
        "＜メッセージメニューから＞\n"+
        `1.画面右上の<img class='help-icons-small' src="${hamburger}"/>(ハンバーガー)メニュー -> 「メッセージ」をタップ(クリック)します。\n`+
        `2.「メッセージ」画面右上の<img class='help-icons' src="${newChat}"/>(ペン)アイコンをタップ(クリック)します。\n`+
        "3.友達リストが表示され、メッセージを送信したい友達をタップ(クリック)すると、メッセージ詳細・送信画面が表示されます。\n\n"+
        "＜友達ページから＞\n"+
        `1.友達のページにアクセスし、プロフィール下の<img class='help-icons-medium' src="${chat}"/>(メッセージ)アイコンをタップ(クリック)すると、メッセージ詳細・送信画面が表示されます。\n\n`+
        `メッセージ欄にテキストを入力し、<img class='help-icons' src="${send}"/>[送信]をタップ(クリック)してください。\n`+
        `*写真を送りたい場合は、メッセージ欄左の<img class='help-icons' src="${gallery}"/>(写真)アイコンをタップ(クリック)し、写真ファイルをアップロードします。`
      },
      {
        questionNo: 3,
        question: "送信済のメッセージ・写真を削除したい。",
        isQuestionOpen: false,
        answer: "削除したいメッセージもしくは写真をタップ(クリック)し、右下に表示される[削除]ボタンをタップ(クリック)します。\n"+
        "選択したメッセージは削除され、送信先の友達のメッセージ履歴からも削除されると同時に「●●さんがメッセージを削除しました」と表示されます。\n"+
        "なお、削除したメッセージや写真を元に戻すことはできません。"
      },
      {
        questionNo: 4,
        question: "友達とのメッセージ履歴を削除したい。",
        isQuestionOpen: false,
        answer: `1.画面右上の<img class='help-icons-small' src="${hamburger}"/>(ハンバーガー)メニュー -> 「メッセージ」をタップ(クリック)してください。\n`+
        `2.メッセージ画面右上の<img class='help-icons' src="${dotMenu}"/>(ミートボール)メニュー -> [メッセージを選択]をタップ(クリック)してください。\n`+
        `3.右側のラジオボタンで削除したいメッセージ履歴を選択の上、<img class='help-icons' src="${deleteIcon}"/>(ゴミ箱)アイコンをタップ(クリック)します。\n`+
        "4.確認メッセージがポップアップで表示されたら、[削除]ボタンをタップ(クリック)します。"
      },
    ]
  },
  {
    itemNo: 8,
    category: "新着情報",
    isCategoryOpen: false,
    questions: [
      {
        questionNo: 1,
        question: "新着情報とは？",
        isQuestionOpen: false,
        answer: "以下の4つの場合に「新着情報」が届きます。なお、#3以外の新着情報は、未読であっても1ヶ月を過ぎると自動的に消去されます。\n"+
        "1.あなたの日記に新着コメントがあるとき\n"+
        "2.友達の日記に書いたあなたのコメントに新着の返信コメントがあるとき\n"+
        "3.他のユーザーがあなたに友達リクエストを送信したとき\n"+
        "4.あなたが送信した友達リクエストが承認されたとき"
      }
    ]
  },
  {
    itemNo: 9,
    category: "利用料・支払方法変更",
    isCategoryOpen: false,
    questions: [
      {
        questionNo: 1,
        question: "利用料はいくらですか？",
        isQuestionOpen: false,
        answer: `月額330円(税込)です。`
      },
      {
        questionNo: 2,
        question: "支払い方法は？",
        isQuestionOpen: false,
        answer: `クレジットカード(取扱ブランド：JCB・VISA・MASTER・アメリカンエクスプレス)、およびスマートフォンキャリア決済(d払い、auかんたん決済、ソフトバンクまとめて支払い、ワイモバイルまとめて支払い)のいずれかとなります。特にお申し出(comu comuの退会手続き)がなければ毎月自動継続となります。`
      },
      {
        questionNo: 3,
        question: "利用料の決済日はいつですか？",
        isQuestionOpen: false,
        answer: `毎月の決済日はお支払い方法により異なります。 \n` +
        "後日、カード会社もしくはスマートフォンキャリアから送付される利用明細書には、以下の決済日の日付が明記されます。\n" +
        "1.d払い：末日 \n" +
        "2.クレジットカード決済・auかんたん決済：初月のみ登録手続き日、次月以降１日 \n" +
        "3.ソフトバンクまとめて支払い・ワイモバイルまとめて支払い：初月のみ登録手続き日、次月以降末日"
      },
      {
        questionNo: 4,
        question: "月末に登録したのですが、利用料はどうなりますか？",
        isQuestionOpen: false,
        answer: `月の途中からのご登録・利用開始であっても、その月の月額利用料は全額お支払いいただきます。`
      },
      {
        questionNo: 5,
        question: "1ヶ月ずっとログインしていないのですが、利用料はどうなりますか？",
        isQuestionOpen: false,
        answer: `1ヶ月で1度もログインされていなくても、登録されている限り利用料が発生します。`
      },
      {
        questionNo: 6,
        question: "利用料決済に登録したクレジットカードを変更したい。",
        isQuestionOpen: false,
        answer: `会員サイトにログインの上、「SNS comu comu」 - 「クレジットカード情報の更新・変更」よりお手続きください。`
      },
      {
        questionNo: 7,
        question: "登録しているクレジットカードの有効期限が変わるが、手続きは必要？",
        isQuestionOpen: false,
        answer: `新しいクレジットカードが届いたら、すぐに会員サイトにログインの上、「SNS comu comu」 - 「クレジットカード情報の更新・変更」よりカード情報を再登録してください。\n`+
        `利用料決済時にカード情報の有効期限が切れている場合、即ご利用停止となることがあります。`
      },
      {
        questionNo: 8,
        question: "支払い方法を変更したい。",
        isQuestionOpen: false,
        answer: `クレジットカード決済からキャリア決済へ、もしくは、キャリア決済からクレジットカード決済へ変更する場合、会員サイトにログインの上、「SNS comu comu」 -` +
        "「退会」より一旦退会手続き(※)をしていただき、翌月1日以降に別の決済方法にて新たにご登録ください。\n" +
        "退会手続きをしても、その月の月末までは「comu comu」は利用可能です。また、退会手続き後２ヶ月間はデータが保持されるため、その期間内に再登録いただければ、前のデータを引き続き利用することが可能です。\n" +
        "ただし、同月内に再登録された場合、変更前・変更後それぞれの決済方法で月額利用料金が発生しますのでご注意ください。\n" +
        "※＜docomo「d払い」ご利用の方＞\n" +
        "退会手続きは、月末日20:00までに行ってください。(月末日20:00〜次月1日12:00まで解約不可)"
      },
      {
        questionNo: 9,
        question: "キャリア決済しているスマートフォンを機種変更する場合の必要な手続きは？",
        isQuestionOpen: false,
        answer: `携帯会社(docomo／au／SoftBank・Y!mobile)が同じ場合：手続きの必要はありません。\n` +
        "携帯会社(docomo／au／SoftBank・Y!mobile)を変更して機種変更をする場合：会員サイトにログインの上、「SNS comu comu」 -" +
        "「退会」より一旦退会手続き(※)をしていただき、新たにご登録ください。\n" +
        "ただし、同月内に再登録された場合、変更前・変更後それぞれのキャリアで月額利用料金が発生しますのでご注意ください。\n" +
        "※＜docomo「d払い」ご利用の方＞\n" +
        "退会手続きは、月末日20:00までに行ってください。(月末日20:00〜次月1日12:00まで解約不可)"
      },
    ]
  },
  {
    itemNo: 10,
    category: "退会",
    isCategoryOpen: false,
    questions: [
      {
        questionNo: 1,
        question: "comu comuを退会したい。",
        isQuestionOpen: false,
        answer: `会員サイトにログインの上、「SNS comu comu」 - 「退会」よりお手続きください。退会手続きを行うと、翌月1日よりcomu comuが利用不可となります。\n`+
        "comu comu退会後もファンクラブを退会しない限り、２ヶ月間はデータが保持されます。その期間内に再登録を行うと、引き続き前のデータを利用することが可能です。\n" +
        "※＜docomo「d払い」ご利用の方＞\n" +
        "退会手続きは、月末日20:00までに行ってください。(月末日20:00〜次月1日12:00まで解約不可)"
      },
      {
        questionNo: 2,
        question: "comu comuの退会手続きを行わずに藤井フミヤオフィシャルクラブ「FF」の会員期限を過ぎた(退会となった)場合、comu comuは自動的に退会となりますか？",
        isQuestionOpen: false,
        answer: `ファンクラブ会員期限満了の翌月末日をもってcomu comuは自動退会となりますが、会員期限翌月分まではひと月分の利用料が発生します。\n` +
        "それより前にcomu comuを退会したい場合は、会員サイトにログインの上、「SNS comu comu」 - 「退会」よりお手続きください。\n" +
        "退会手続きを行うと、翌月1日よりcomu comuが利用不可となります。"
      },
      {
        questionNo: 3,
        question: "退会していないが、「新規登録」と表示されているのは？",
        isQuestionOpen: false,
        answer: `利用料決済時、ご登録の決済方法にてエラーとなった場合はご利用停止となり、ログインできなくなります。\n` +
        "ご自身の決済方法をご確認の上、クレジットカード決済の方は有効なカード情報を「新規登録」から再登録を、キャリア決済の方はお支払い状況をご確認ください。\n" +
        "ファンクラブを退会しない限り、２ヶ月間はデータが保持されます。その期間内に再登録を行うと、引き続き前のデータを利用することが可能です。" 
      },
    ]
  },
  {
    itemNo: 11,
    category: "その他",
    isCategoryOpen: false,
    questions: [
      {
        questionNo: 1,
        question: "推奨環境は？",
        isQuestionOpen: false,
        answer: `推奨環境については<u><a href="${FFMWEB_ROOT}/environment" target="_blank">こちら</a></u>をご覧ください。`,
        hasLink: true
      },
      {
        questionNo: 2,
        question: "絵文字、機種依存文字は利用できる？",
        isQuestionOpen: false,
        answer: "絵文字・機種依存文字を入力した場合、正しく表示されないものがあります。\n"+
        "ご利用環境により正しく表示されないことがあるため、なるべく表示可能な文字に置き換えてご利用ください。"
      },
    ]
  },
];

export default helpData;

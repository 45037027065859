import reducer from "./reducers";
import { routes } from "../routes";
import * as snsOperations from "./operations"

export {snsOperations}

export default {
  reducer,
  routes,
};

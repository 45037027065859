const styles = {
    notifItem: {
        borderColor: 'rgba(0, 0, 0, 0.12)',
        borderBottomWidth: 1
    },
    whiteLabel: {
        fontSize: 14,
        color: "#fff"
    },
    blackLabel: {
        fontSize: 14,
        color: "#000"
    },
    confirmBtn: {
        backgroundColor: "#0a182c",
        width: 160,
        marginRight: 20,
        marginBottom: 10,
        borderRadius: 10,
        height: 40
    },
    closeBtn: {
        backgroundColor: "#dcdcdc",
        width: 160,
        borderRadius: 10,
        marginBottom: 10,
        height: 40
    },
    notifSeen:{
        backgroundColor: "#FFFFFF"
    },
    notifUnseen:{
        backgroundColor: "#F5F5F5"
    },
    notifTitle:{ marginLeft: 16, fontSize: 14 },
    notifSubtitle: { fontWeight: 'normal', marginLeft: 16, fontSize: 12 }
}

export default styles;
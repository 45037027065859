import React, {useState, useRef } from 'react';
import { Navbar, Dropdown } from 'react-bootstrap';
import ProfileIcon from '../../../includes/img/admin-menu/profile.svg';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { authOperations } from '../../containers/Authentication/state';
import './styles.scss';

const CustomToggle = React.forwardRef(({onClick, ref, dropdownToggled}) => {
    //Redux store data
    const username = useSelector(state => state.auth.credentials.attributes.email);

    return (
        <a
            href="_blank"
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            className="admin-header-dropdown"
        >
            <img alt="profile-icon" src={ProfileIcon} className="admin-header-name" />
            {username}
            <span className={dropdownToggled ? "arrow-up" : "arrow-down"}>&#x25b2;</span>
        </a>
    )}
)

const AdminHeader = (props) => {

    const [dropdownToggled, setDropdownToggled] = useState(false);

    //Bind dispatch to action creators
    const dispatch = useDispatch();
    const operations = bindActionCreators(
        {
            logoutUser: authOperations.logoutUser
        },
        dispatch
    )

    const toggleRef = useRef();
    
    return (
        <Navbar className="admin-header">
            <Navbar.Brand/>
            <Dropdown onToggle={()=>setDropdownToggled(!dropdownToggled)}>
                <Dropdown.Toggle 
                    ref={toggleRef} 
                    as={(field) => (
                        <CustomToggle 
                            onClick={field.onClick} 
                            ref={toggleRef} 
                            dropdownToggled={dropdownToggled}
                        />
                    )} 
                    id="dropdown-custom-components" />

                <Dropdown.Menu alignRight>
                    <Dropdown.Item eventKey="1" onClick={()=>props.handleShowModal()}>パスワード変更&nbsp;&nbsp;&nbsp;&nbsp;
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="2" onClick={()=>operations.logoutUser(true)}>ログアウト</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Navbar>
    );
};

export default AdminHeader;
import React,  { useEffect }  from 'react';
import { Route, Redirect } from 'react-router-dom';
import { initialAccountUpdated, appendUserRoute } from '../../utils/common';
import ReactGA from 'react-ga';
import GA4React from "ga-4-react";

const PrivateUserRoute = (props) => {

    useEffect(() => {
        // Google analytics
        ReactGA.initialize('UA-1005737-3');
        ReactGA.pageview(window.location.pathname + window.location.search);

        const ga4react = new GA4React("G-J6HL91GKMP");
        ga4react.initialize().then((ga4) => {
            ga4.pageview(window.location.pathname + window.location.search)
            ga4.gtag('event','pageview','path')
          },(err) => {
            console.error(err)
          })
          
    });
    

    if (initialAccountUpdated()) {
        return (
            <div>
                <Route path={props.path} component={props.component} exact={props.exact} />
            </div>
        )
    } else {
        return (
            <Redirect to={appendUserRoute("/edit-profile")} />
        );
    }
};

export default PrivateUserRoute;
import React, { Component } from 'react';
import { View, TouchableOpacity } from "react-native";
import { ListItem, Text } from "react-native-elements";
import ErrorModal from "../../components/ErrorModal";
import "./styles/styles.scss"
import { Card, Image } from 'react-bootstrap';
import { ActivityIndicator } from 'react-native';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { addDefaultSrc, appendUserRoute, onApiSuccess, isNullOrUndefined, defaultTopScroll, isScrollRefetchReached } from "../../../utils/common";
import {fetchEmptyScroll} from "../../../utils/common"
import RightArrow from "../../../includes/img/icons/right-arrow.svg"
import { THUMBNAIL_SMALL, PROFILE_THUMBNAIL_BUCKET_URL, SOURCE } from "../../../utils/constants";
import styles from "./styles/styles.native"
import CalendarFilterComponent from './CalendarFilterComponent';
import { pastpostOperations } from './state';
import { SYSTEM_ERROR, MSG_NO_POSTS } from '../../../utils/messages';
import PostItemTemplate from '../../components/PostItemTemplate';
import User404 from '../Errors/User404';
import PostDetailModal from '../../components/PostDetailModal'; //task#363

class PastPost extends Component {
    constructor(props){
        super(props);
        this.state = {
            userId: props.userId,
            userHandleName:props.handleName,
            userImg: props.profileImageUrl,
            isShowFilter: false,
            source: 1,
            genOneYears: ["2020", "2019", "2018", "2017", "2016", "2015", "2014", "2013", "2012", "2011"],
            genTwoYears: ["2011", "2010", "2009", "2008", "2007", "2006"],
            // filterDates: ["2020-01", "2018-10", "2011-03"],
            filterDates: [],
            filterYear: null,
            filterMonth: null,
            pastPostOwnerId: parseInt(props.match.params.userId),
            pastPostOwnerHandleName: null,
            currentPagination: 1,
            pastPosts: [],
            isPostFetching: true,
            isPostFiltering: false,
            isLastPage: false,
            isAccessInvalid: false,
            isBlockFetching: true,
            isLoading: true,
            hash: Date.now()
        }
    }

    componentWillMount() {
        const friendId = this.props.match.params.userId
        const source = this.props.match.params.generation

        if(![1, 2].includes(parseInt(source))) {
            this.props.history.push(appendUserRoute(`/past-posts/${friendId}/1`))
        }
    
        if(parseInt(friendId) !== this.state.userId) {
            this.handleCheckIfBlocked(friendId)
        }
        else {
            this.setState({
                isBlockFetching: false
            })
        }
    }

    handleCheckIfBlocked(friendId) {
        this.props.checkIfBlocked(this.state.userId, friendId).then(response => {
            if(onApiSuccess(response)) {
                const data = JSON.parse(response.data.data)
                const isAccessInvalid = data.is_blocked
                this.setState({
                    isAccessInvalid: isAccessInvalid,
                    isBlockFetching: false
                })
            }
            else {
                this.setState({
                    isErrorModalShow : true,
                    errorMessage: SYSTEM_ERROR
                })
            }
        }) 
    }

    componentDidMount(){
        defaultTopScroll();
        this.setState({
            pastPostOwnerId: parseInt(this.props.match.params.userId),
            source: parseInt(this.props.match.params.generation),
        }, () => {
            this.props.getPostDates(this.state.userId, this.state.pastPostOwnerId, this.state.source).then(response => {
                if(onApiSuccess(response)){
                    let data = JSON.parse(response.data.data)
                    const author = JSON.parse(data.author)
                    const tempPastDates = JSON.parse(data.data)
                    const pastDates = tempPastDates.map(item => item._id);
                    const handleName = undefined === author || 0 === author.length ? null : author[0]["handleName"]

                    this.setState({
                        filterDates: pastDates,
                        pastPostOwnerHandleName: handleName,
                        isLoading: false
                    })

                    this.props.getAllPastPosts(this.state.userId, this.state.pastPostOwnerId, this.state.filterYear, this.state.filterMonth, this.state.source, this.state.currentPagination)
                    .then(response => {
                        if(onApiSuccess(response)){
                            let data = JSON.parse(response.data.data)
                            this.setState({
                                pastPosts : data,
                                isPostFetching: false,
                                isLastPage: data.length === 0 ? true : false,
                                currentPagination: 2
                            })
                        }else{
                            this.setState({
                                isErrorModalShow : true,
                                errorMessage: SYSTEM_ERROR
                            })
                        }
                    })
                }else{
                    this.setState({
                        isErrorModalShow : true,
                        errorMessage: SYSTEM_ERROR
                    })
                }
            })
        })

        document.addEventListener('scroll', this.onScrollCheck);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.userId !== this.props.match.params.userId
            || prevProps.match.params.generation !== this.props.match.params.generation) {
            this.setState({
                pastPostOwnerId: parseInt(this.props.match.params.userId),
                source: parseInt(this.props.match.params.generation),
                isShowFilter: false,
                genOneYears: ["2020", "2019", "2018", "2017", "2016", "2015", "2014", "2013", "2012", "2011"],
                genTwoYears: ["2011", "2010", "2009", "2008", "2007", "2006"],
                // filterDates: ["2020-01", "2018-10", "2011-03"],
                filterDates: [],
                filterYear: null,
                filterMonth: null,
                currentPagination: 1,
                pastPosts: [],
                isPostFetching: true,
                isPostFiltering: false,
                isLastPage: false
            }, () => {
                this.props.getPostDates(this.state.userId, this.state.pastPostOwnerId, this.state.source).then(response => {
                    if (onApiSuccess(response)) {
                        let data = JSON.parse(response.data.data)
                        const author = JSON.parse(data.author)
                        const tempPastDates = JSON.parse(data.data)
                        const pastDates = tempPastDates.map(item => item._id);
                        const handleName = undefined === author || 0 === author.length ? null : author[0]["handleName"]
    
                        this.setState({
                            filterDates: pastDates,
                            pastPostOwnerHandleName: handleName,
                            isLoading: false,
                        })

                        this.props.getAllPastPosts(this.state.userId, this.state.pastPostOwnerId, this.state.filterYear, this.state.filterMonth, this.state.source, this.state.currentPagination)
                            .then(response => {
                                if (onApiSuccess(response)) {
                                    let data = JSON.parse(response.data.data)
                                    this.setState({
                                        pastPosts: data,
                                        isPostFetching: false,
                                        isLastPage: data.length === 0 ? true : false,
                                        currentPagination: 2
                                    })
                                } else {
                                    this.setState({
                                        isErrorModalShow: true,
                                        errorMessage: SYSTEM_ERROR
                                    })
                                }
                            })
                    } else {
                        this.setState({
                            isErrorModalShow: true,
                            errorMessage: SYSTEM_ERROR
                        })
                    }
                })
            })
        }
    }

    handleDisplayOneGen = () =>{
        this.setState({
            isShowFilter: false,
            source: SOURCE.oneGeneration,
            currentPagination: 1
        }, ()=>{
            let oneGenerationRoute = appendUserRoute(`/past-posts/${this.state.pastPostOwnerId}/${this.state.source}`)
            this.props.history.push(oneGenerationRoute)
        })
    }

    handleDisplayTwoGen = () =>{
        this.setState({
            isShowFilter: false,
            source: SOURCE.twoGeneration,
            currentPagination: 1
        }, ()=>{
            let twoGenerationsRoute = appendUserRoute(`/past-posts/${this.state.pastPostOwnerId}/${this.state.source}`)
            this.props.history.push(twoGenerationsRoute)
        })
    }

    handleOpenFilter = () =>{
        this.setState({
            isShowFilter: true
        })
    }

    handleCloseFilter = (e) =>{
        e.preventDefault()
        this.setState({
            isShowFilter: false
        })
    }

    handleCloseErrorModal = () => {
        this.setState({
            isErrorModalShow: false
        })
    }

    handleFilterPost = (event, year, month) =>{
        event.preventDefault()

        if (!this.state.isPostFetching) {
            const filterYear = parseInt(year)
            const filterMonth = parseInt(month)
            this.setState({
                filterYear: filterYear,
                filterMonth: filterMonth,
                isPostFetching: true,
                isShowFilter: false,
                isPostFiltering: true,
                currentPagination: 1,
                isLastPage: false
            }, () => {
                this.props.getAllPastPosts(this.state.userId, this.state.pastPostOwnerId, this.state.filterYear, this.state.filterMonth, this.state.source, this.state.currentPagination)
                    .then(response => {
                        if (onApiSuccess(response)) {
                            let data = JSON.parse(response.data.data)
                            this.setState({
                                pastPosts: data,
                                isPostFetching: false,
                                isPostFiltering: false,
                                isLastPage: data.length === 0 ? true : false,
                                currentPagination: 2
                            })
                        } else {
                            this.setState({
                                isErrorModalShow: true,
                                errorMessage: SYSTEM_ERROR
                            })
                        }
                    })
            })
        }
    }

    onScrollCheck = () =>{
        if(!this.state.isLoading && !this.state.isLastPage && isScrollRefetchReached()){
            this.handlePaginatedPost();
        }
    }

    componentWillUnmount(){
        document.removeEventListener('scroll', this.onScrollCheck);
    }

    handlePaginatedPost = () => {
        if (!this.state.isPostFetching) {
            this.setState({ isPostFetching: true }, () => {
                this.props.getAllPastPosts(this.state.userId, this.state.pastPostOwnerId, this.state.filterYear, this.state.filterMonth, this.state.source, this.state.currentPagination)
                .then(res => {
                    if (onApiSuccess(res)) {
                        let pastPosts = JSON.parse(res.data.data) 
                        let updateFetch = () => {
                            this.setState(prevState => ({
                               pastPosts: [
                                   ...prevState.pastPosts,
                                   ...pastPosts
                               ],
                               isPostFetching: false,
                               isLastPage: pastPosts.length === 0 ? true : false,
                               currentPagination : this.state.currentPagination + 1 
                           }))
                       }
                       if (0 >= pastPosts.length) {
                           fetchEmptyScroll();
                           setTimeout(() => { updateFetch() }, 500);
                       } else {
                           updateFetch();
                       }
                    } else {
                        this.setState({
                            isErrorModalShow : true,
                            errorMessage: SYSTEM_ERROR,
                            isPostFetching: false
                        })                   
                    }
                })
            });
        }
    }

    setPostDetailModal = (postId) =>{ //task#363 start
        this.setState({showPostDetailModal: true, postDetailPostId: postId})
    }

    closePostDetailModal = () =>{
        this.setState({showPostDetailModal: false})
    }//task#363 end

    render() {
        if(this.state.isBlockFetching) {
            return (
                <React.Fragment>
                    <center>
                        <ActivityIndicator
                            style={{
                                marginBottom: 20,
                                height: 50,
                                display: "block"
                            }}
                        />
                    </center>
                </React.Fragment>
            )
        }
        else if(this.state.isAccessInvalid || (isNullOrUndefined(this.state.pastPostOwnerHandleName) && !this.state.isLoading)) {
            return (
                <User404/>
            )
        }
        else {
            let appendedRoute = this.state.pastPostOwnerId ? appendUserRoute(`/user/${this.state.pastPostOwnerId}`) : "#";

            if (this.props.userId === this.state.pastPostOwnerId) {
                appendedRoute = appendUserRoute("/mypage");
            }

            return (
                <React.Fragment>
                    <Card className="past-post-header-container">
                        <View style={styles.listItemContainer}>
                            
                                <ListItem
                                    containerStyle={[styles.listItem]}
                                    title={
                                        <div className="header-title" onClick={()=>this.props.history.push(appendedRoute)}>
                                            {this.state.pastPostOwnerHandleName}
                                        </div>}
                                    leftElement={
                                        <img onClick={()=>this.props.history.push(appendedRoute)} className="header-avatar" alt="user-avatar" src={PROFILE_THUMBNAIL_BUCKET_URL + this.state.pastPostOwnerId + "/" + this.state.pastPostOwnerId + THUMBNAIL_SMALL + `?${this.state.hash}`} onError={addDefaultSrc} />
                                    }
                                />
                            
                            <View style={styles.horizontalBorder} />
                            <View style={styles.pastPostDetailsContainer}>
                                <Text>過去の投稿</Text>
                                <TouchableOpacity onPress={() => this.handleOpenFilter()} style={styles.filterButton}><Text style={{width: '150px', textAlign: "right"}}>月別に見る</Text><Image src={RightArrow} className="past-post-button" /></TouchableOpacity>
                            </View>
                        </View>
                    </Card>
    
    
                    {!this.state.isPostFiltering ?
                        0 < this.state.pastPosts.length ?
                            (
                                <div className="feed-main">
                                    {
                                        this.state.pastPosts.map((details, i) => 
                                            <PostItemTemplate 
                                                key={"post" + i} 
                                                {...details} 
                                                deletePost={this.handleDeletePost} 
                                                isPastPost={true}
                                                profileHash={this.state.hash}
                                                setPostDetailModal={this.setPostDetailModal/**task#363**/}
                                            />
                                        )
                                    }
                                </div>
                            )
                            :
                            (
                                !this.state.isPostFetching ? 
                                <div className="feed-main">
                                    <center><p>{MSG_NO_POSTS}</p></center>  
                                </div> 
                                : null
                            )
                        : null
                    }
    
                    <center>
                        <ActivityIndicator
                            style={{
                                marginBottom: 20,
                                height: 50,
                                display: this.state.isPostFetching ? "block" : "none"
                            }}
                        />
                    </center>
                    <ErrorModal
                        {...this.state}
                        handleCloseModal={this.handleCloseErrorModal}
                        customContent={null}
                        errorMessage={this.state.errorMessage}
                    />
                    <CalendarFilterComponent
                        filterDates={this.state.filterDates}
                        source={this.state.source}
                        genOneYears={this.state.genOneYears}
                        genTwoYears={this.state.genTwoYears}
                        isShowFilter={this.state.isShowFilter}
                        handleCloseFilter={this.handleCloseFilter}
                        handleDisplayOneGen={this.handleDisplayOneGen}
                        handleDisplayTwoGen={this.handleDisplayTwoGen}
                        handleFilterPost={this.handleFilterPost}
                    />
                    <PostDetailModal /**task#363 start**/
                        isModalShow={this.state.showPostDetailModal}
                        confirmationText={this.state.postDetailPostId}
                        confirmTitle={"PastPost"}
                        handleConfirmAction={()=>this.redirectViewPost(this.state.postDetailPostId)}
                        handleCloseModal={this.closePostDetailModal}
                    /**task#363**/ /> 
                </React.Fragment>
            );
        }

    }
}

//Bind dispatch to action creators
const mapStateToProps = state => {
    return {
        userId: state.auth.credentials.userId,
        handleName: state.editprofile.profileDetails.handleName,
        profileImageUrl: state.auth.credentials.profileImageUrl
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            checkIfBlocked: pastpostOperations.checkIfBlocked,
            getPostDates: pastpostOperations.getPostDates,
            getAllPastPosts: pastpostOperations.getAllPastPosts
        },
          dispatch
    );
      
export default connect(mapStateToProps, mapDispatchToProps)(PastPost);

import React from 'react';
import { Button } from 'react-native-elements';
import { Card } from 'react-bootstrap';
import styles from "./styles/mypage.styles.native"
import { appendUserRoute, getPrefecture, getHobby, isStringNullEmptyOrUndefined } from '../../../utils/common';
import { Link } from 'react-router-dom';
import PCOnlyView from '../../components/PCOnlyView';
import MobileOnlyView from '../../components/MobileOnlyView';
import { PRIVACY, POST_PRIVACY } from '../../../utils/constants';

const appendedRoute = appendUserRoute("/edit-profile");

const MyPageDetailComponent = (props) => {
    let userInfo = props.userModel
    if(!props.isPreview){
        return renderMyPage(props, userInfo)
    }else{
        if(POST_PRIVACY.friends === props.privacyType){
            return renderMyPageFriendPreview(props, userInfo)
        }else{
            return renderMyPagePublicPreview(props, userInfo)
        }
    }
}

const renderMyPage = (props, userInfo) =>{
    let hobbies = [userInfo.hobby1, userInfo.hobby2, userInfo.hobby3]
    return(
        <>
            <Card.Body className="mypage-info-container">
                <div className="category-container">
                    <table>
                        <tbody>
                                <tr>
                                    <td className="profile-category">名前 </td>
                                    <td className="profile-category-detail">{userInfo.lastname + " " + userInfo.firstname}</td>
                                </tr>
                                <tr>
                                    <td className="profile-category">性別</td>
                                    <td className="profile-category-detail">{userInfo.gender}</td>
                                </tr>
                                <tr>
                                    <td className="profile-category">血液型</td>
                                    <td className="profile-category-detail">{userInfo.bloodtype}</td>
                                </tr>
                                {/* Year Privacy (only me) but month day are empty */}
                                {PRIVACY.private === parseInt(userInfo.birthYearPrivacy) && 
                                     (isStringNullEmptyOrUndefined(userInfo.birthMonth) || isStringNullEmptyOrUndefined(userInfo.birthDay))
                                    ? "" : (
                                        <tr>
                                            <td className="profile-category">誕生日</td>
                                            <td className="profile-category-detail">{userInfo.birthYear}{userInfo.birthMonth ? `/${userInfo.birthMonth}` : ""}{userInfo.birthMonth && userInfo.birthDay ? `/${userInfo.birthDay}` : ""}</td>
                                        </tr>
                                )}
                                {((!isStringNullEmptyOrUndefined(userInfo.address1) && 0 !== parseInt(userInfo.address1)) || !isStringNullEmptyOrUndefined(userInfo.address2)) ?
                                <tr>
                                    <td className="profile-category"> 現住所</td>
                                    <td className="profile-category-detail">{getPrefecture(parseInt(userInfo.address1)) + "  " + userInfo.address2}</td>
                                </tr>
                                : null}
                                {!isStringNullEmptyOrUndefined(userInfo.birthplace) ?
                                <tr>
                                    <td className="profile-category">出身地</td>
                                    <td className="profile-category-detail">{userInfo.birthplace}</td>
                                </tr>
                                : null}
                                {!isStringNullEmptyOrUndefined(hobbies) && !isHobbyNone(hobbies) ?
                                <tr>
                                    <td className="profile-category">趣味</td>
                                    <td className="profile-category-detail">{renderHobbies(hobbies)}</td>
                                </tr>
                                : null}
                                {!isStringNullEmptyOrUndefined(userInfo.occupation) ?
                                <tr>
                                <td className="profile-category">職業</td>
                                    <td className="profile-category-detail">{userInfo.occupation}</td>
                                </tr> 
                                : null}
                        </tbody>
                    </table>
                </div>
             </Card.Body>
            <PCOnlyView>
                <Link key={appendedRoute} href={appendedRoute} to={appendedRoute} className="link-router">
                    <Button title="プロフィール編集" titleStyle={styles.btnTitleStyle} buttonStyle={styles.editProfileBtnPC}/>
                </Link>
            </PCOnlyView>
            <MobileOnlyView>
                <Link key={appendedRoute} href={appendedRoute} to={appendedRoute} className="link-router">
                    <Button title="プロフィール編集" titleStyle={styles.btnTitleStyle} buttonStyle={styles.editProfileBtn}/>
                </Link>
            </MobileOnlyView>
        </>
    )
}

const renderMyPageFriendPreview = (props,userInfo) =>{
    let hobbies = [userInfo.hobby1, userInfo.hobby2, userInfo.hobby3]
    return(
        <>
            <Card.Body className="mypage-info-container">
                <div className="category-container">
                    <table>
                        <tbody>
                                <tr className={PRIVACY.private === parseInt(userInfo.namePrivacy) ? "detail-hidden" : ""}>
                                    <td className="profile-category">名前 </td>
                                    <td className="profile-category-detail">{userInfo.lastname + " " + userInfo.firstname}</td>
                                </tr>
                                <tr className={PRIVACY.private === parseInt(userInfo.genderPrivacy) ? "detail-hidden" : ""}>
                                    <td className="profile-category">性別</td>
                                    <td className="profile-category-detail">{userInfo.gender}</td>
                                </tr>
                                <tr className={PRIVACY.private === parseInt(userInfo.bloodtypePrivacy) ? "detail-hidden" : ""}>
                                    <td className="profile-category">血液型</td>
                                    <td className="profile-category-detail">{userInfo.bloodtype}</td>
                                </tr>

                                {/* Year Privacy (only me) but month day are empty */}
                                {PRIVACY.private === parseInt(userInfo.birthYearPrivacy) && 
                                     (isStringNullEmptyOrUndefined(userInfo.birthMonth) || isStringNullEmptyOrUndefined(userInfo.birthDay))
                                    ? "" : (
                                        <tr className={PRIVACY.private === parseInt(userInfo.birthYearPrivacy) && PRIVACY.private === parseInt(userInfo.birthMonthDayPrivacy) ? "detail-hidden" : ""}>
                                            <td className="profile-category">誕生日</td>
                                            <td className="profile-category-detail">
                                                {PRIVACY.private === parseInt(userInfo.birthYearPrivacy) ? "" : userInfo.birthYear}
                                                {PRIVACY.private !== parseInt(userInfo.birthYearPrivacy)
                                                    && PRIVACY.private !== parseInt(userInfo.birthMonthDayPrivacy) && userInfo.birthMonth ?
                                                    "/" : ""}
                                                {PRIVACY.private === parseInt(userInfo.birthMonthDayPrivacy) ? "" : userInfo.birthMonth}
                                                {PRIVACY.private !== parseInt(userInfo.birthMonthDayPrivacy)
                                                    && PRIVACY.private !== parseInt(userInfo.birthMonthDayPrivacy) && userInfo.birthDay ?
                                                    "/" : ""}
                                                {PRIVACY.private === parseInt(userInfo.birthMonthDayPrivacy) ? "" : userInfo.birthDay}
                                            </td>
                                        </tr>
                                    )}
                                {(((!isStringNullEmptyOrUndefined(userInfo.address1) && 0 !== parseInt(userInfo.address1)) || !isStringNullEmptyOrUndefined(userInfo.address2)))?
                                <tr className={PRIVACY.private === parseInt(userInfo.addressPrivacy) ? "detail-hidden" : ""}>
                                    <td className="profile-category"> 現住所</td>
                                    <td className="profile-category-detail">{getPrefecture(parseInt(userInfo.address1)) + "  " + userInfo.address2 }</td>
                                </tr>
                                : null}
                                {!isStringNullEmptyOrUndefined(userInfo.birthplace) ?
                                <tr className={PRIVACY.private === parseInt(userInfo.birthplacePrivacy) ? "detail-hidden" : ""}>
                                    <td className="profile-category">出身地</td>
                                    <td className="profile-category-detail">{userInfo.birthplace}</td>
                                </tr>
                                : null}
                                {!isStringNullEmptyOrUndefined(hobbies) && !isHobbyNone(hobbies) ?
                                <tr className={PRIVACY.private === parseInt(userInfo.hobbyPrivacy) ? "detail-hidden" : ""}>
                                    <td className="profile-category">趣味</td>
                                    <td className="profile-category-detail">{renderHobbies(hobbies)}</td>
                                </tr>
                                : null}
                                {!isStringNullEmptyOrUndefined(userInfo.occupation) ?
                                <tr className={PRIVACY.private === parseInt(userInfo.occupationPrivacy) ? "detail-hidden" : ""}>
                                <td className="profile-category">職業</td>
                                    <td className="profile-category-detail">{userInfo.occupation}</td>
                                </tr> 
                                : null}
                        </tbody>
                    </table>
                </div>
             </Card.Body>
        </>
    )
}

const renderMyPagePublicPreview = (props,userInfo) =>{
    let hobbies = [userInfo.hobby1, userInfo.hobby2, userInfo.hobby3]
    return(
        <>
            <Card.Body className="mypage-info-container">
                <div className="category-container">
                    <table>
                        <tbody>
                                <tr className={PRIVACY.public !== parseInt(userInfo.namePrivacy) ? "detail-hidden" : ""}>
                                    <td className="profile-category">名前 </td>
                                    <td className="profile-category-detail">{userInfo.lastname + " " + userInfo.firstname}</td>
                                </tr>
                                <tr className={PRIVACY.public !== parseInt(userInfo.genderPrivacy) ? "detail-hidden" : ""}>
                                    <td className="profile-category">性別</td>
                                    <td className="profile-category-detail">{userInfo.gender}</td>
                                </tr>
                                <tr className={PRIVACY.public !== parseInt(userInfo.bloodtypePrivacy) ? "detail-hidden" : ""}>
                                    <td className="profile-category">血液型</td>
                                    <td className="profile-category-detail">{userInfo.bloodtype}</td>
                                </tr>

                                {/* Year Privacy (only me) but month day are empty */}
                                {PRIVACY.private === parseInt(userInfo.birthYearPrivacy) && 
                                     (isStringNullEmptyOrUndefined(userInfo.birthMonth) || isStringNullEmptyOrUndefined(userInfo.birthDay))
                                    ? "" : (
                                    <tr className={PRIVACY.public !== parseInt(userInfo.birthYearPrivacy) ? "detail-hidden" : ""}>
                                        
                                        <td className="profile-category">誕生日</td>
                                        <td className="profile-category-detail">
                                            {PRIVACY.public !== parseInt(userInfo.birthYearPrivacy) ? "" : userInfo.birthYear}
                                            {PRIVACY.public === parseInt(userInfo.birthYearPrivacy)
                                                && PRIVACY.public === parseInt(userInfo.birthMonthDayPrivacy) && userInfo.birthMonth ?
                                                "/" : ""}
                                            {PRIVACY.public !== parseInt(userInfo.birthMonthDayPrivacy) ? "" : userInfo.birthMonth}
                                            {PRIVACY.public === parseInt(userInfo.birthMonthDayPrivacy)
                                                && PRIVACY.public === parseInt(userInfo.birthMonthDayPrivacy) && userInfo.birthDay ?
                                                "/" : ""}
                                            {PRIVACY.public !== parseInt(userInfo.birthMonthDayPrivacy) ? "" : userInfo.birthDay}
                                        </td>
                                    </tr>
                                )}
                                {(((!isStringNullEmptyOrUndefined(userInfo.address1) && 0 !== parseInt(userInfo.address1)) || !isStringNullEmptyOrUndefined(userInfo.address2)))?
                                <tr className={PRIVACY.public !== parseInt(userInfo.addressPrivacy) ? "detail-hidden" : ""}>
                                    <td className="profile-category"> 現住所</td>
                                    <td className="profile-category-detail">{getPrefecture(parseInt(userInfo.address1)) + "  " + userInfo.address2}</td>
                                </tr>
                                : null}
                                {!isStringNullEmptyOrUndefined(userInfo.birthplace) ?
                                <tr className={PRIVACY.public !== parseInt(userInfo.birthplacePrivacy) ? "detail-hidden" : ""}>
                                    <td className="profile-category">出身地</td>
                                    <td className="profile-category-detail">{userInfo.birthplace}</td>
                                </tr>
                                : null}
                               {!isStringNullEmptyOrUndefined(hobbies) && !isHobbyNone(hobbies) ?
                                <tr className={PRIVACY.public !== parseInt(userInfo.hobbyPrivacy) ? "detail-hidden" : ""}>
                                    <td className="profile-category">趣味</td>
                                    <td className="profile-category-detail">{renderHobbies(hobbies)}</td>
                                </tr>
                                : null}
                                {!isStringNullEmptyOrUndefined(userInfo.occupation) ?
                                <tr className={PRIVACY.public !== parseInt(userInfo.occupationPrivacy) ? "detail-hidden" : ""}>
                                <td className="profile-category">職業</td>
                                    <td className="profile-category-detail">{userInfo.occupation}</td>
                                </tr> 
                                : null}
                        </tbody>
                    </table>
                </div>
             </Card.Body>
        </>
    )
}


const isHobbyNone = (hobbies) =>{
    //Check if all hobbies is equal to 1
    return hobbies.every( item => parseInt(item) === 1 )
}

const renderHobbies = (hobbies) =>{
    //Render values of hobbies
    var hobbyList = []
    hobbies.map(item=>{
        if(1 < parseInt(item)){
            let name = getHobby(parseInt(item))
            hobbyList.push(name)
        }
        return null
    })
    return hobbyList.join(",")
}




export default MyPageDetailComponent

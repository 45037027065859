import React from 'react';
import ContentLoader from 'react-content-loader'

const RowSkeleton = (
    <ContentLoader
        speed={2}
        width={'100%'}
        height={80}
        
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >

        <rect x="48" y="8" width="100" height="6" rx="3" />
        <rect x="48" y="26" width="1000" height="6" rx="3" />
        <rect x="48" y="40" width="1000" height="6" rx="3" />
        <circle cx="20" cy="20" r="20" />
    </ContentLoader>
);

const CommentSkeleton = () => {

    return (
        <div className="pl-1 pr-1 pb-1 pt-3">
            {RowSkeleton}
            {RowSkeleton}
            {RowSkeleton}
        </div>
    );
};

export default CommentSkeleton;
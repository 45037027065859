import React from 'react';
import { Image } from 'react-bootstrap';
import { appendUserRoute, addDefaultSrc, isFumiyaUserCheck } from '../../../utils/common';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DefaultIcon from '../../../includes/img/placeholders/user.svg';
import moment from 'moment';
import './styles.scss';
import { DATETIME_FORMAT, S3_THUMBNAIL_PREFIX_URL } from '../../../utils/constants';
import { isNullOrUndefined } from 'util';
import { TouchableOpacity } from 'react-native';
import styles from "./styles.native"

const HandleName = (props) => {
    let { appendedRoute, isUserDeleted, isFriendPage, handleName, isPreview } = props;

    if (isUserDeleted) {
        return (
            <span className="author-name">{"退会ユーザ"}</span>
        )
    
    } else if (isFriendPage || isPreview) {
        return (
            <span className="author-name">{handleName}</span>
        )
    } else {
        if(window == window.parent) { //task#363
            return (
                <Link href={appendedRoute} to={appendedRoute}>
                    <span className="author-name">{handleName}</span>
                </Link>
            )
        }else{ //task#363 start
            return (
                <Link href={appendedRoute} to={appendedRoute} 
                 target = "_blank" rel="noopener noreferrer"> {/**task#363 **/}
                    <span className="author-name">{handleName}</span>
                </Link>
            )
        } //task#363 end;
    }
}

const ProfileImage = props => {
    let { appendedRoute, isUserDeleted, invalidUserId, isFriendPage, isProfileImageUrlValid, profileImageUrl, hash, disableImageLink } = props;
    if (isUserDeleted || isFriendPage || disableImageLink || invalidUserId) {
        return (
            <Image onError={addDefaultSrc} src={isProfileImageUrlValid ? `${S3_THUMBNAIL_PREFIX_URL + profileImageUrl}-small?` + hash  : DefaultIcon} style={{ width: 36, height: 36, objectFit: 'cover' }} roundedCircle alt="author-profile"/>
        )
    } else {
        if(window == window.parent) { //task#363
            return (
                <Link href={appendedRoute} to={appendedRoute}>
                    <Image onError={addDefaultSrc} src={isProfileImageUrlValid ? `${S3_THUMBNAIL_PREFIX_URL + profileImageUrl}-small?` + hash  : DefaultIcon} style={{ width: 36, height: 36, objectFit: 'cover' }} roundedCircle alt="author-profile"/>
                </Link>
            )
        }else{ //task#363 start
            return (
                <Link href={appendedRoute} to={appendedRoute}
                 target = "_blank" rel="noopener noreferrer"> {/**task#363 **/}
                    <Image onError={addDefaultSrc} src={isProfileImageUrlValid ? `${S3_THUMBNAIL_PREFIX_URL + profileImageUrl}-small?` + hash  : DefaultIcon} style={{ width: 36, height: 36, objectFit: 'cover' }} roundedCircle alt="author-profile"/>
                </Link>
            )
        } //task#363 end;
    }
}

const AuthorHeader = (props) => {
    //Redux store data
    const loggedUserId = useSelector(state => state.auth.credentials.userId);

    //User Route
    let appendedRoute = props.userId ? appendUserRoute(`/user/${props.userId}`) : "#";

    if (loggedUserId === props.userId) {
        appendedRoute = appendUserRoute("/mypage");
    }

    let isValidCreateDt = moment(new Date(props.createDt)).isValid();
    let isValidUpdateDt = moment(new Date(props.updateDt)).isValid();
    let isProfileImageUrlValid = props.profileImageUrl && 0 <= props.profileImageUrl.indexOf("Profile/");

    let isPastPost = props.source !== 0

    return (
        <div className="author-container">
            <React.Fragment>
                {(!isNullOrUndefined(props.isPreview) || props.isPreview) && props.handleOpenPostModal ?
                    <TouchableOpacity onPress={()=>props.handleOpenPostModal(props.userId, "header")} style={styles.authorTouchableHeader}>
                        {!props.isAdminNotice ? 
                        <Image onError={addDefaultSrc} src={isProfileImageUrlValid ? `${S3_THUMBNAIL_PREFIX_URL + props.profileImageUrl}-small?` + props.hash  : DefaultIcon} style={{ width: 36, height: 36, objectFit: 'cover' }} roundedCircle alt="author-profile"/>
                        : ""}
                        <div className={!props.isAdminNotice ? "d-flex flex-column ml-2" : "d-flex flex-column ml-0"}>
                            <HandleName isUserDeleted={props.isUserDeleted} isPreview={props.isPreview} handleName={props.handleName}/>
                            <span className="author-datetime">
                                {isValidCreateDt && moment(new Date(props.createDt)).format(DATETIME_FORMAT)}
                                {isValidUpdateDt && !isPastPost && !isFumiyaUserCheck(parseInt(props.userId)) && " | 編集済"}
                            </span>
                        </div>
                    </TouchableOpacity> 
                :
                    <>
                        {!props.isAdminNotice ? 
                        <ProfileImage appendedRoute={appendedRoute} invalidUserId={isNullOrUndefined(props.userId)} disableImageLink={props.disableImageLink} isUserDeleted={props.isUserDeleted} isFriendPage={props.isFriendPage} isProfileImageUrlValid={isProfileImageUrlValid} profileImageUrl={props.profileImageUrl} hash={props.hash} />
                        : "" }
                        <div className={!props.isAdminNotice ? "d-flex flex-column ml-2" : "d-flex flex-column ml-0"}>
                            <HandleName appendedRoute={appendedRoute} isUserDeleted={props.isUserDeleted} isPreview={props.isPreview} isFriendPage={props.isFriendPage} handleName={props.handleName}/>
                            <span className="author-datetime">
                                {props.stringCreateDt ? props.stringCreateDt : (isValidCreateDt && moment(new Date(props.createDt)).format(DATETIME_FORMAT))}
                                {isValidUpdateDt && !isPastPost && !isFumiyaUserCheck(parseInt(props.userId)) && " | 編集済"} 
                            </span>
                        </div> 
                    </>
                }
            </React.Fragment>
        </div>
    );


};

export default AuthorHeader;
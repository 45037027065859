import React, { PureComponent } from 'react';
import { onApiSuccess, defaultTopScroll } from '../../../utils/common';
import PostItemTemplate from '../../components/PostItemTemplate';
import PostSkeleton from '../../components/Skeletons/PostSkeleton';
import './styles/create.scss';
import { isScrollRefetchReached } from '../../../utils/common';
import { ActivityIndicator } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { topOperations } from './state';
import { MSG_NO_POST_YET, SYSTEM_ERROR } from '../../../utils/messages';
import { postOperations } from '../Post/state';
import ErrorModal from '../../components/ErrorModal';
import ProcessLoader from '../../components/ProcessLoader';
import ProcessLoaderForLoading from '../../components/ProcessLoaderForLoading'; // Whiete Out fix
import CreatePostArea from './CreatePostArea';
import AdminNotice from './AdminNotice';
import { WEBSOCKET_MESSAGES } from '../../../utils/constants';
import { isNullOrUndefined } from 'util';
import { authOperations } from "../Authentication/state";
import ConfirmationModal from '../../components/ConfirmationModal';
import $ from 'jquery'; 
import { isSessionExpired } from "../../../utils/common"; // Whiete Out fix
import PostDetailModal from '../../components/PostDetailModal'; //task#363


/**For scroll direction check */
var scrollPos = 0;

class Newsfeed extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            posts: [],
            newsfeedPage: 1,
            isPostFetching: false,
            newsfeedEndReached: false,
            initialLoaded: false,
            isProcessing: false,
            isProcessingForLoading: false, //White Out fix
            showErrorModal: false,
            msgErrorModal: '',
            profileHash: Date.now(),
            showDeleteModal: false,
            deletePostId: "",//task#363
            showPostDetailModal: false,//task#363
            postDetailPostId: "" //task#363
        }
    }

    componentDidMount() {
        defaultTopScroll();

        this.getPaginatedNewsfeed();
        this.props.getAdminNotice();
        document.addEventListener('scroll', this.onScrollCheck);
        document.addEventListener("visibilitychange", this.backGroundCare); //White Out fix

        /**Sockets */
        if (!isNullOrUndefined(this.props.websocket) && this.props.websocket.readyState === WebSocket.OPEN) {
            this.initWSHandlers()
        }
        /***/

        //Custom JQuery to remove link propagantion
        //on common.js customParseLink
        $(".parsedLink" ).on( "click", function(event) {
            event.stopPropagation();
        });
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.onScrollCheck);
        document.removeEventListener("visibilitychange", this.backGroundCare); //White Out fix
    }

    componentDidUpdate(){
        this.initWSHandlers()
    }

    onScrollCheck = () => {
        let isScrollDown = (document.body.getBoundingClientRect()).top < scrollPos;

        //Check if within scroll refetch, no concurrent post fetch, and limit not reached
        if (isScrollRefetchReached() && !this.state.isPostFetching && isScrollDown && !this.state.newsfeedEndReached && this.state.initialLoaded) {
            this.getPaginatedNewsfeed();
        }

        scrollPos = (document.body.getBoundingClientRect()).top;
    }

    /** SOCKETS for thumbnails*/
    initWSHandlers() {
        this.props.websocket.onmessage = (data) => {
            let serverData = JSON.parse(data.data);
            let responseData = JSON.parse(serverData.data);

            // check if required fields is included on the message
            if (WEBSOCKET_MESSAGES.onNotifications === responseData.action) {
                this.props.incrementNotifCount(responseData.referenceId)
            }else if (WEBSOCKET_MESSAGES.onChatNotification === responseData.action) {
                this.props.incrementChatThreadCount(responseData.senderId, responseData.threadId)
            }else if (WEBSOCKET_MESSAGES.onPostImageRender === responseData.action) {
                this.props.updatePostImageSocket(responseData.postId, responseData.imageUrl, responseData.imageStatus)
            }else if(WEBSOCKET_MESSAGES.onPostVideoRender === responseData.action){
                this.props.updatePostVideoSocket(responseData.postId, responseData.videoUrl, responseData.videoStatus)
            }
        }

        let isExistError = false; //added 20220720 White Out fix (caused by reconnection loop)
        this.props.websocket.onerror = () => { isExistError = true } //mod 20220617 White Out fix (caused by reconnection loop) Start.
        this.props.websocket.onclose = () => {
            let isScreenBackGround = localStorage.getItem("isScreenBackGround");
            if(isScreenBackGround == null){
                if(!isExistError) this.socketReconnect();
            } else { //If screen in smartPhone run to background, do not reconnect here as reconnection will be done with the exclusive method
                localStorage.setItem("isScreenBackGround", null);
            }
        } 
    }
     
    backGroundCare = () =>{
        if (document.visibilityState === "hidden"){ //When screen run to background
            localStorage.setItem("isScreenBackGround", 1);
            this.props.websocket.close(); //Forcibly close websocket
            localStorage.setItem("isWebsocktOpen", 0);
            this.setState({ isProcessingForLoading: true }); // Prepare to show Processing modal when screen returned from background
        }
        if (document.visibilityState === "visible"){ //When screen returned from background
            if (isSessionExpired()) this.props.logoutUser(); 
                this.props.connect();
                const interval = setInterval(() => {
                    if (localStorage.getItem("isWebsocktOpen") == 1) {
                        this.setState({ isProcessingForLoading: false }); // Stop Processing modal
                        clearInterval(interval);
                    }
                  }, 50);
        } 
    }//added 20220720 White Out fix End

    socketReconnect = () => {
        this.props.websocket.close()
        this.props.connect();
        setTimeout(() => {
            this.initWSHandlers()
        }, 1000);
    }
    /**END OF SOCKETS */

    getPaginatedNewsfeed = () => {
        if (!this.state.isPostFetching) {
            this.setState({ isPostFetching: true }, () => {
                this.props.getPaginatedNewsfeed(this.props.user.userId, this.state.newsfeedPage)
                    .then(res => {
                        if (onApiSuccess(res)) {
                            if (0 < JSON.parse(res.data.data).length) {
                                this.setState(prevState => ({
                                    newsfeedPage: prevState.newsfeedPage + 1,
                                    isPostFetching: false,
                                    initialLoaded: true
                                }));
                            } else {
                                //No more post to retrieve, disable scroll pagination
                                this.setState({
                                    newsfeedEndReached: true,
                                    isPostFetching: false,
                                    initialLoaded: true
                                })
                            }

                        } else {
                            this.showSystemError();
                        }
                    }).catch(()=>this.showSystemError())
            });
        }
    }

    deletePost = (postId) => {
        return new Promise(resolve => {
            this.setState({ isProcessing: true },
                async () => {
                    this.props.deletePost(postId)
                        .then(res => {
                            if (onApiSuccess(res)) {
                                this.setState({ isProcessing: false, showDeleteModal: false })
                                this.props.removeNewsfeedPost(postId);
                                resolve(true);
                            } else {
                                this.showSystemError();
                                resolve(false);
                            }
                        })
                        .catch(() => this.showSystemError())
                })
        });
    }

    showSystemError = () => {
        this.setState({
            isPostFetching: false,
            isProcessing: false,
            msgErrorModal: SYSTEM_ERROR,
            showErrorModal: true,
            showDeleteModal: false, //task#363
            showPostDetailModal: false //task#363
        })
    }

    toggleErrorModal = () => {
        this.setState(prevState => ({ showErrorModal: !prevState.showErrorModal }));
    }

    setDeleteModal = (postId) =>{
        this.setState({showDeleteModal: true, deletePostId: postId})
    }

    closeDeleteModal = () =>{
        this.setState({showDeleteModal: false})
    }

    setPostDetailModal = (postId) =>{ //task#363 start
        this.setState({showPostDetailModal: true, postDetailPostId: postId})
    }

    closePostDetailModal = () =>{
        this.setState({showPostDetailModal: false})
    }//task#363 end

    render() {
        let postList = this.props.newsfeed;
        let newsfeedContent = <PostSkeleton />;

        if (this.state.initialLoaded || 0 < postList.length) {
            newsfeedContent = (
                <div className="feed-main">
                    {0 < postList.length ? (
                        postList.map((details, i) => 
                            <PostItemTemplate 
                                key={"post" + i} 
                                ownerId={details.author.userId} 
                                {...details} 
                                profileHash={this.state.profileHash} 
                                setDeleteModal={this.setDeleteModal}
                                setPostDetailModal={this.setPostDetailModal/**task#363**/}
                            />)
                    ) : (
                            <center><p>{MSG_NO_POST_YET}</p></center>
                        )}
                </div>
            )
        }

        return (
            <React.Fragment>
                {/**Process Loading indicator*/}
                <ProcessLoader
                    isShown={this.state.isProcessing}
                />

                <ProcessLoaderForLoading
                    isShown={this.state.isProcessingForLoading}
                />

                {this.props.adminNotice && this.props.adminNotice.map((adminProp, index) => {
                    return(
                        <AdminNotice key={"admin" + index} {...adminProp}/>
                    )
                })}

                <CreatePostArea user={this.props.user} profileHash={this.state.profileHash} history={this.props.history}/>
                
                {newsfeedContent}
                
                <center>
                    <ActivityIndicator
                        style={{
                            height: 50,
                            display: this.state.isPostFetching ? "block" : "none"
                        }}
                    />
                </center>

                <ErrorModal
                    errorMessage={this.state.msgErrorModal}
                    isErrorModalShow={this.state.showErrorModal}
                    handleCloseModal={this.toggleErrorModal}
                />

                <ConfirmationModal
                    isModalShow={this.state.showDeleteModal}
                    confirmationText={"日記を削除しますか？"}
                    confirmTitle={"削除"}
                    handleConfirmAction={()=>this.deletePost(this.state.deletePostId)}
                    handleCloseModal={this.closeDeleteModal}
                />
                
                <PostDetailModal /**task#363 start**/
                    isModalShow={this.state.showPostDetailModal}
                    confirmationText={this.state.postDetailPostId}
                    confirmTitle={"Newsfeed"}
                    handleConfirmAction={()=>this.redirectViewPost(this.state.postDetailPostId)}
                    handleCloseModal={this.closePostDetailModal}
                /**task#363**/ /> 
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.credentials,
        newsfeed: state.top.newsfeed,
        adminNotice: state.top.adminNotice,
        websocket: state.auth.websocket,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getPaginatedNewsfeed: topOperations.getPaginatedNewsfeed,
        deletePost: postOperations.deletePost,
        removeNewsfeedPost: topOperations.removeNewsfeedPost,
        getAdminNotice: topOperations.getAdminNotice,
        updatePostImageSocket: topOperations.updatePostImageSocket,
        updatePostVideoSocket: topOperations.updatePostVideoSocket,
        connect: authOperations.connectWS,
        incrementNotifCount: authOperations.incrementNotifCount,
        incrementChatThreadCount: authOperations.incrementChatThreadCount,
        logoutUser: authOperations.logoutUser //White out fix
    },
    dispatch
);


export default connect(mapStateToProps, mapDispatchToProps)(Newsfeed);
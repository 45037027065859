import React, { useImperativeHandle, useRef, forwardRef } from 'react';
import { Image, Media as BootstrapMedia } from 'react-bootstrap';
import DefaultIcon from '../../../includes/img/placeholders/user.svg';
import ResponseArea from './ResponseArea';
import RenderReply from './RenderReply';
import { appendUserRoute, getObjKeyIdString, addDefaultSrc } from '../../../utils/common';
import { Link } from 'react-router-dom';
import './styles.scss';
import { CLOUDFRONT_THUMBNAIL_PREFIX_URL } from '../../../utils/constants';

const RenderCommentItem = (props, ref) => {
    let isOwner = props.userId === props.author.userId;

    //User Route
    let appendedRoute = appendUserRoute(`/user/${props.author.userId}`);
    
    let isProfileImageUrlValid = props.author.profileImageUrl && 0 <= props.author.profileImageUrl.indexOf("Profile/");

    if (isOwner) {
        appendedRoute = appendUserRoute("/mypage");
    }

    /**REFERENCES FOR PARENT CALLS*/
    const createReplySubsubChildRef = useRef();
    useImperativeHandle(ref, () => ({
        onSuccess: () => {
            createReplySubsubChildRef.current.onSuccess() //Pass down to child
        }
      })); 

    let isParent = (window == window.parent); //task#363

    return (
        <BootstrapMedia>
            {props.author.isUserDeleted ? (
                <Image
                    onError={addDefaultSrc}
                    src={isProfileImageUrlValid ? `${CLOUDFRONT_THUMBNAIL_PREFIX_URL + props.author.profileImageUrl}-small?${props.hash}` : DefaultIcon}
                    className="post-view-avatar"
                    roundedCircle
                    alt={"user-img"}
                />
            ): (
                <Link /** task#363 start */
                    href={appendedRoute} 
                    to={appendedRoute}
                    target = {isParent ? "_self" : "_blank"} 
                    rel="noopener noreferrer"
                > {/** task#363 end*/}
                    <Image
                        onError={addDefaultSrc}
                        src={isProfileImageUrlValid ? `${CLOUDFRONT_THUMBNAIL_PREFIX_URL + props.author.profileImageUrl}-small?${props.hash}` : DefaultIcon}
                        className="post-view-avatar"
                        roundedCircle
                        alt={"user-img"}
                    />
                </Link>
            )}
            
            <BootstrapMedia.Body>
                <ResponseArea
                    {...props}
                    ref={createReplySubsubChildRef}
                    commentId={props.commentId}
                    type={"comment"}
                    replyCount={props.replies ? props.replies.length : 0}
                    value={props.commentBody}
                    isOwner={isOwner}
                    hash={props.hash}
                />
                {props.replies && props.replies.map((reply, i) => {
                    return (
                        <RenderReply
                            {...reply}
                            {...props}
                            hash={props.hash}
                            commentId={props.commentId}
                            replyId={reply._id}
                            author={reply.author}
                            key={getObjKeyIdString(reply._id)}
                            createDt={reply.createDt}
                            updateDt={reply.updateDt}
                        />);
                })}
            </BootstrapMedia.Body>
        </BootstrapMedia>
    )
}

export default forwardRef(RenderCommentItem);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, Redirect } from 'react-router-dom';
import { authOperations } from '../containers/Authentication/state';
import { LOGIN_ROUTE } from '../containers/Authentication/routes';
import * as Settings from "../../config/settings";

class PrivateAdminRoute extends Component {
    render() {
        return this.props.isAuthenticated && (Settings.ROLE_ADMIN || Settings.ROLE_STAFF === this.props.user.signInUserSession.idToken.payload) ? (
            <div>
                <Route path={this.props.path} component={this.props.component} exact={this.props.exact} />
            </div>
        ) : (
            //Redirect to Login
            <Redirect to={{
                pathname: LOGIN_ROUTE,
                state: {
                    from: this.props.location
                }
            }} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.credentials
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        logoutUser: authOperations.logoutUser
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(PrivateAdminRoute);
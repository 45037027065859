import React from "react";
import styles from "./styles/chatconversation.styles.native";
import { View } from "react-native";
import { ListItem } from "react-native-elements";
import { THUMBNAIL_MEDIUM, PROFILE_THUMBNAIL_BUCKET_URL } from "../../../utils/constants";
import { addDefaultSrc } from "../../../utils/common.js";
import { isMobile } from "react-device-detect";
import { Media } from 'react-breakpoints'
import { useHistory } from "react-router";

const ChatHeaderComponent = props => {
  const appendedRoute = `/app/user/${props.recipientId}`
  const history = useHistory();

  return(
    <Media>
    {({ breakpoints, currentBreakpoint }) => {
      return (
        <View style={styles.chatListItemContainer}>
          <ListItem
            containerStyle={styles.listItem}
            title={
              <div className="header-title" onClick={()=>history.push(appendedRoute)}>
                {props.recipientHandleName}
              </div>
            }
            titleStyle={breakpoints[currentBreakpoint] < breakpoints.mobileLandscape || isMobile ? styles.titleMobile : styles.titlePC}
            leftElement={
              <img onClick={()=>history.push(appendedRoute)} className="header-avatar" alt="user-avatar" src={PROFILE_THUMBNAIL_BUCKET_URL + props.recipientId + "/" + props.recipientId + THUMBNAIL_MEDIUM} onError={addDefaultSrc} />
            }
          />
          <View style={styles.horizontalBorder} />
        </View>
      );
    }}
  </Media>
  )
};

export default ChatHeaderComponent;

import React from 'react';
import ConfirmationModal from './ConfirmationModal';
import ErrorModal from './ErrorModal';
import NavigationPrompt from 'react-router-navigation-prompt';
import { isNullOrUndefined } from '../../utils/common';

const DiscardChangesModal = ({active, disableRedirect, discardCallback, customDiscardCancel}) => {
    return (
        <NavigationPrompt 
            when={(currentLocation, nextLocation) => {
                if("/app/edit-profile" === currentLocation.pathname && disableRedirect){
                    return (active || disableRedirect);
                }else{
                    return active;
                }
            }}
        >
            {({onConfirm, onCancel}) => {
                /**First time user update */
                if(disableRedirect){
                    return (
                        <ErrorModal
                            isErrorModalShow={true}
                            errorMessage={"先にプロフィールを保存してください。"}
                            handleCloseModal={onCancel}
                        />
                    )
                }else if (active){
                    return (
                        <div id="discardModal">
                            <ConfirmationModal
                                isModalShow={true}
                                confirmationText={"更新された内容が破棄されます。\nよろしいですか？"}
                                confirmTitle={"はい"}
                                handleConfirmAction={()=>{
                                    if(discardCallback){
                                        discardCallback().then(()=>onConfirm())
                                    }else{
                                        onConfirm()
                                    }
                                    
                                }}
                                handleCloseModal={()=>{
                                    if(isNullOrUndefined(customDiscardCancel)){
                                        onCancel()
                                    }else{
                                        customDiscardCancel();
                                    }
                                }}
                            />
                        </div>
                    )
                }else{
                    onConfirm()
                }
            }}
        </NavigationPrompt>
    );
};

export default DiscardChangesModal;
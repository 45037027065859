import React from "react";
import styles from "./styles.native.js";
import { FlatList } from "react-native";
import { ListItem } from "react-native-elements";
import { addDefaultSrc, isFumiyaOrFFStaffUserCheck, getObjKeyIdString,isNullOrUndefined  } from "../../../utils/common.js";
import { CLOUDFRONT_THUMBNAIL_PREFIX_URL, THUMBNAIL_MEDIUM, S3_DEFAULT_URL} from "../../../utils/constants.js";
import "./styles.scss";
import { isMobile } from "react-device-detect";
import { Media } from 'react-breakpoints'

const EmptyComponent = (props) => {
  if((!isNullOrUndefined(props.isUserList) && props.isUserList)){
    return null
  }else{
    return(
        <Media>
        {({ breakpoints, currentBreakpoint }) => {
            let isDeviceMobile = (breakpoints[currentBreakpoint] < breakpoints.mobileLandscape) || isMobile
            return (
              <ListItem title="指定された条件に一致する友達は存在しません。" titleStyle={isDeviceMobile ? styles.emptyListMobile : styles.emptyList} />
            );
        }}
      </Media>
    )
  }

};

const renderItem = (item, props) => {
  let profileImageUrl = `Profile/${item.userId}/${item.userId}`;

  if(!isFumiyaOrFFStaffUserCheck(item.userId) || (!isNullOrUndefined(props.isUserList) && props.isUserList)){
    return (
      <Media>
      {({ breakpoints, currentBreakpoint }) => {
        return(
          <div onClick={() => props.handleSelectItem(item)}>
            <ListItem
              activeOpacity={0.7}
              delayPressIn={5}
              title={item.handleName}
              titleStyle={(breakpoints[currentBreakpoint] < breakpoints.mobileLandscape) || isMobile ? styles.titleMobile : styles.title}
              leftElement={
                <img className="avatar" alt="user-avatar" src={CLOUDFRONT_THUMBNAIL_PREFIX_URL + profileImageUrl + THUMBNAIL_MEDIUM + `?${props.hash}`} onError={addDefaultSrc}/>
              }
            />
          </div>
        )
      }}
  </Media>
   );
  }else{
    return null
  }
 };

const renderCommunityItem = (item, props) => {
  return (
    <Media>
    {({ breakpoints, currentBreakpoint }) => {
      return(
        <div onClick={() => props.handleSelectItem(item)}>
          <ListItem
            title={item.name}
            titleStyle={(breakpoints[currentBreakpoint] < breakpoints.mobileLandscape) || isMobile ? styles.communityTitleMobile :styles.communityTitle}
            leftElement={
              <img className="avatar" alt="user-avatar" src={S3_DEFAULT_URL + item.communityImageUrl} onError={addDefaultSrc}/>
            }
          />
        </div>
      )
  }}
  </Media>
   );
 };

const UserListItemComponent = props => {
  return(
    <Media>
    {({ breakpoints, currentBreakpoint }) => {
      let isDeviceMobile = (breakpoints[currentBreakpoint] < breakpoints.mobileLandscape) || isMobile
      if (!props.isCommunity) {
        return (
          <FlatList
            style={isDeviceMobile ? props.isUserList ? styles.listContainerMobile : styles.listContainerMobileChat : styles.listContainerPC}
            showsVerticalScrollIndicator={!isDeviceMobile}
            data={props.list}
            keyExtractor={item => item.userId.toString()}
            renderItem={({ item }) => renderItem(item, props)}
            extraData={props.list}
            ListEmptyComponent={EmptyComponent(props)}
            bounces={false}
            refreshing={false}
            onEndReachedThreshold={0.2}
            onEndReached={() => {
            props.handlePagination()
            }}
        />
        );
      } else {
        //Render Community List
        return (
          <FlatList
            style={isDeviceMobile ? styles.communityListContainerMobile : styles.communityListContainerPC}
            showsVerticalScrollIndicator={!isDeviceMobile}
            data={props.list}
            keyExtractor={item => getObjKeyIdString(item._id)}
            renderItem={({ item }) => renderCommunityItem(item, props)}
            extraData={props.list}
            ListEmptyComponent={EmptyComponent}
            bounces={false}
            refreshing={false}
            onEndReachedThreshold={0.2}
            onEndReached={() => {
            props.handlePagination()
            }}
        />
        );
      }
    }}
  </Media>
  )
};

export default UserListItemComponent;
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './styles.scss';
import { appendAdminRoute } from '../../../utils/common';
import UserIcon from '../../../includes/img/admin-menu/user.svg';
import NoticeIcon from '../../../includes/img/admin-menu/notification.svg';
import MessageIcon from '../../../includes/img/admin-menu/message.svg';
import MaintenanceIcon from '../../../includes/img/admin-menu/maintenance.svg';
import { ADMIN_LEVEL_TWO } from '../../../utils/constants';

const AdminSideNav = () => {
    
    let navigation = [
        { navigate: "/user-search", label: 'ユーザ検索', icon: UserIcon },
        { navigate: "/admin-notice", label: 'お知らせ', icon: NoticeIcon },
        { navigate: "/random-message", label: 'フミヤさんからのランダムメッセージ', icon: MessageIcon },
        { navigate: "/maintenance", label: 'System Maintenance Setting', icon: MaintenanceIcon }
    ]

    //Redux store data
    const credentials = useSelector(state => state.auth.credentials);
    let adminLevel = null;

    if(credentials.hasOwnProperty('attributes')) {
        if(credentials.attributes.hasOwnProperty('custom:level')) {
            adminLevel = credentials.attributes['custom:level']
        }
    }

    if (ADMIN_LEVEL_TWO !== adminLevel) {
        navigation = navigation.filter(item => "/maintenance" !== item.navigate);
    }

    return (
        <div className="admin-sidenav">
            <div>{"comu comu"}</div>
            <div>{"管理画面"}</div>
            <div className="admin-sidenav-menu">
                {navigation.map((item, i) =>{
                    let appendedRoute = appendAdminRoute(item.navigate);
                    return(
                        <Link key={item.navigate} href={appendedRoute} to={appendedRoute}>
                            <span key={"sidenav" + i} style={{display:"flex", alignItems: 'flex-start'}}>
                                <img alt="sidenav-icon" src={item.icon} className="admin-sidenav-icon"/>
                                <span>{item.label}</span>
                            </span>
                        </Link>
                    )
                })}
            </div>
        </div>
    );
};

export default AdminSideNav;
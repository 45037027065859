import React, { Component } from 'react';
import { TextInput, TouchableOpacity } from 'react-native';
import PCOnlyView from '../../components/PCOnlyView';
import { Card } from 'react-bootstrap';
import './styles/search.scss';
import styles from './styles/styles.native';
import SearchIcon from "../../../includes/img/icons/search.svg";
import { Media } from 'react-breakpoints'
import { isMobile } from "react-device-detect";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { searchOperations } from './state';
import { defaultTopScroll, appendUserRoute } from '../../../utils/common';
import debounce from 'lodash/debounce'
import CalendarIconFilters from './CalendarIconFilters';

class MainSearch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchString: ""
        }

        debounce(this.handleInputChange, 250)
    }

    componentDidMount() {
        defaultTopScroll();
    }

    handleSearchSubmit = (event) => {
        if (event) {
            event.preventDefault()
        }

        if (!this.searchEmptyCheck()) {
            let encoded_string = encodeURIComponent(this.state.searchString.trim())
            let searchRoute = appendUserRoute(`/search/results?keyword=${encoded_string}`)
            this.props.history.push(searchRoute)
        }
    }


    handleInputChange = (value) => {
        this.setState({ searchString: value });
    }

    searchEmptyCheck = () =>{
        return 0 === (this.state.searchString.trim()).length;
    }

    render() {
        return (
            <Media>
                {({ breakpoints, currentBreakpoint }) => {
                    let mobileView = (breakpoints[currentBreakpoint] < breakpoints.mobileLandscape) || isMobile;
                    return (
                        <div className={mobileView ? "tab-content-mobile" : "tab-content-web"}>
                            <div className={mobileView ? "search-bar-mobile col-sm-12 p-0" : "search-bar-web"}>
                                <div className={mobileView ? "search-mobile" : "search-web"}>
                                    {/**Mobile MainSearch Component */}
                                    <PCOnlyView>
                                        <Card.Title className="search-title">メンバー・日記検索</Card.Title>
                                    </PCOnlyView>
                                    <div className={`search-container-${mobileView ? "mobile" : "web"}`}>
                                        <p className="text-center mb-2">キーワードで検索</p>
                                        <div className="search-input-area" style={{ borderColor: mobileView ? "#606770" : "#DCDCDC" }}>
                                            <form autoComplete="off" className="w-100" onSubmit={this.handleSearchSubmit}>
                                                <TextInput
                                                    autoFocus={true}
                                                    autoComplete="off"
                                                    placeholder="comu comuを検索"
                                                    value={this.state.searchString}
                                                    onChangeText={this.handleInputChange}
                                                    onKeyPress={this.handleOnKeyPress}
                                                    style={mobileView ? styles.inputMobile : styles.inputWeb}
                                                    returnKeyType="search"
                                                    autoCorrect={false}
                                                    spellCheck={false}
                                                    autofill={"off"}
                                                />
                                            </form>

                                            <TouchableOpacity disabled={this.searchEmptyCheck()} activeOpacity={0.5} onPress={this.handleSearchSubmit}>
                                                <img
                                                    alt="search-icon"
                                                    src={SearchIcon}
                                                    className={this.searchEmptyCheck() ? "search-icon half-opacity" : "search-icon"}
                                                />
                                            </TouchableOpacity>
                                        </div>
                                    </div>

                                    <div className="flex-center flex-column pb-5 bg-white">
                                        <p className="text-center mb-3 mt-3">日記を日付で検索</p>
                                        <CalendarIconFilters/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    )

                }}
            </Media>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.credentials
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        executeSearch: searchOperations.executeSearch,
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(MainSearch);
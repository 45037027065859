import React from 'react';
import './styles.scss';
import moment from "moment"
import { getObjDateValue } from '../../../utils/common';
import { DATE_FORMAT } from '../../../utils/constants';

const RenderPollResult = ({poll}) => {
    return(
        <div className="post-view-poll-container">
            <p className="post-view-poll-deadline">回答締切⽇: {moment(getObjDateValue(poll.deadline)).format(DATE_FORMAT)}</p>
            <span className="post-view-poll-deadline-text">このアンケートは既に締め切りました。</span>
            <table className="post-view-poll-table">
                <tbody>
                  {
                        poll.choices.map((choice, index) => {
                           return (
                            <tr>
                                <td className="post-view-poll-table-td">{choice.choiceBody}</td>
                                <td>{choice.votes}</td>
                            </tr>
                           )
                        })
                  }
                </tbody>
            </table>
        </div>
    )
}

export default RenderPollResult
import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Image } from 'react-bootstrap';
import { addDefaultSrc } from "../../../utils/common.js";
import { S3_DEFAULT_URL, DATE_FORMAT, EMPTY_STRING} from "../../../utils/constants.js";
import { appendUserRoute } from '../../../utils/common';
import { Link } from 'react-router-dom';
import moment from 'moment';
import "./styles/styles.scss"
import CommunityDetailsSkeleton from "../../components/Skeletons/CommunityDetailsSkeleton.js";
import CommunityHeaderSkeleton from "../../components/Skeletons/CommunityHeaderSkeleton.js";

const PastCommunityHeader = props => {
    const userId = useSelector(state => state.auth.credentials.userId);
    let profileRoute =  !props.isLoading ? userId === props.communityDetails[0].administrator.userId ? appendUserRoute("/mypage") :appendUserRoute(`/user/${props.communityDetails[0].administrator.userId}`) : null;
    return(
        <Card.Body>
            {!props.isLoading ?
               <div className="community-details-container">
                <Image className="community-avatar" alt="user-avatar" src={S3_DEFAULT_URL + props.communityDetails[0].communityImageUrl} onError={addDefaultSrc}/>
                <div className="community-info">
                        <table>
                            <tbody>
                                <tr>
                                    <td>カテゴリ {EMPTY_STRING === props.communityDetails[0].category ? null : "／" + props.communityDetails[0].category}</td>
                                </tr>
                                <tr>
                                    <td>開設⽇／{moment(props.communityDetails[0].dateEstablished.$date).format(DATE_FORMAT)}</td>
                                </tr>
                                <tr>
                                    <td>管理者／
                                        <Link key={profileRoute} href={profileRoute} to={profileRoute}>
                                            {props.communityDetails[0].administrator.handleName}
                                        </Link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                </div>
             </div>
            : <CommunityHeaderSkeleton/>}
            <div className="community-horizontal-border"></div>
            <div className="community-description-container">
                {!props.isLoading ? <span className="community-details">{props.communityDetails[0].description}</span>: <CommunityDetailsSkeleton/>}
            </div>
        </Card.Body>
    )
}

export default PastCommunityHeader
import React from 'react';
import Modal from "react-modal";
import { View, Image, TouchableOpacity } from "react-native";
import { notificationCreateForm, notificationUpdateForm, radioInput } from './state/templates';
import { isNullOrUndefined } from "../../../utils/common";
import FormTemplate from '../../components/FormTemplate';
import CloseIcon from "../../../includes/img/icons/close.svg";
import "./styles/notificationManagement.scss"
import styles from "../../components/ConfirmationModal/styles.native"
import { selectors } from './state';

const NotificationModal = (props) => {

    /**
     *  Supply default values to form inputs
     */
    const setFormValues = (createForm) => {
        let currNotif = props.currNotif
        createForm.form.map(input => {
            if('createdDate' === input.name && "multiple-text" === input.type) {
                input.children.map(child => {
                    if('createdYear' === child.name) {
                        child.default = currNotif.createdYear;
                    }
    
                    else if('createdMonth' === child.name) {
                        if(!isNullOrUndefined(currNotif)) {
                            child.default = currNotif.createdMonth  || ""
                        }
                    }
    
                    else if('createdDay' === child.name) {
                        child.default = currNotif.createdDay || "";
                    }
                    return child;
                })
                input.default = `${currNotif.createdYear}/${currNotif.createdMonth}/${currNotif.createdDay}`;
            }

            else if('title' === input.name) {
                input.default = (currNotif.title.length ? currNotif.title : '');
            }

            else if('body' === input.name) {
                input.default = (currNotif.body.length ? currNotif.body : '');
            }
            
            else if('isDisplayed' === input.name) {
                input.default = ('表示' === currNotif.isDisplayed || '1' === currNotif.isDisplayed) ? '1' : '0';
            }

            else if('startDate' === input.name && "multiple-text" === input.type) {
                input.children.map(child => {
                    if('startYear' === child.name) {
                        child.default = currNotif.startYear || "";
                    }
    
                    else if('startMonth' === child.name) {
                        if(!isNullOrUndefined(currNotif)) {
                            child.default = currNotif.startMonth  || ""
                        }
                    }
    
                    else if('startDay' === child.name) {
                        child.default = currNotif.startDay || "";
                    }
                    return child;
                })
                input.default = `${currNotif.startYear}/${currNotif.startMonth}/${currNotif.startDay}`;
            }

            else if('endDate' === input.name && "multiple-text" === input.type) {
                input.children.map(child => {
                    if('endYear' === child.name) {
                        child.default = currNotif.endYear || "";
                    }
    
                    else if('endMonth' === child.name) {
                        if(!isNullOrUndefined(currNotif)) {
                            child.default = currNotif.endMonth  || ""
                        }
                    }
    
                    else if('endDay' === child.name) {
                        child.default = currNotif.endDay || "";
                    }
                    return child;
                })
                input.default = `${currNotif.endYear}/${currNotif.endMonth}/${currNotif.endDay}`;
            }
            return input;
        });
        return createForm;
    }

    const resetFormValues = (form) => {
        form.form.map(input => {
            if('radio' !== input.type) {
                input.default = '';
                if(input.hasOwnProperty('children')) {
                    input.children.map(child => {
                        child.default = '';
                        return child;
                    });
                }
            }
            return form;
        });
        return form;
    }

    const handleShowEditForm = () => {
        props.handleShowEditModal();
    }

    const handleHideDate = (customNotificationForm) => {
        // Hide startDate and endDate if isDisplayed is equal to 非表示
        if('0' === props.currNotif.isDisplayed || '非表示' === props.currNotif.isDisplayed) {
            customNotificationForm.form = customNotificationForm.form.filter(item => 
                'startDate' !== item.name && 'endDate' !== item.name);
        }
        return customNotificationForm;
    }

    const handleEmptyDate = (customNotificationForm) => {
        // Show "-" value if 表示 is checked and no date is set.
        if('1' === props.currNotif.isDisplayed || '表示' === props.currNotif.isDisplayed) {
            customNotificationForm.form = customNotificationForm.form.map(item => {
                if('startDate' === item.name || 'endDate' === item.name) {
                    item.value = '-';
                }
                return item;
            })
        }
        return customNotificationForm;
    }

    const setFormProps = () => {
        switch(props.type) {
            case 'create': {
                return {
                    title: 'お知らせ作成・編集',
                    validate: selectors.validateNotification,
                    formInputs: 'confirm' === props.prevModal ? setFormValues(notificationCreateForm) : resetFormValues(notificationCreateForm),
                    radioInput: radioInput,
                    handleSubmit: (row) => props.handleSubmit(row),
                    formButtons: [
                        { type: "primary", label: "確認する", submit: props.handleConfirm },
                        { type: "secondary", label: "戻る", onClick: props.handleCloseModal }
                    ]
                }
            }
            case 'update': {
                return{
                    title: 'お知らせ作成・編集',
                    validate: selectors.validateNotification,
                    handleUpdateIsDisplayed: props.handleUpdateIsDisplayed,
                    formInputs: setFormValues(notificationCreateForm),
                    radioInput: radioInput,
                    updatePreview: true,
                    handleSubmit: (row) => props.handleSubmit(row),
                    formButtons: [
                        { type: "primary", label: "確認する", submit: props.handleConfirm }, 
                        { type: "secondary", label: "戻る", onClick: props.handleCloseModal }
                    ]
                }
            }
            case 'confirmUpdate': {
                //Update form definition
                let customNotificationForm = Object.assign({}, notificationUpdateForm);
                customNotificationForm.form[0].name="";
                customNotificationForm = handleHideDate(customNotificationForm);
                customNotificationForm = handleEmptyDate(customNotificationForm);

                return{
                    title: 'お知らせ作成・編集',
                    preview: true,
                    validate: selectors.validateNotification,
                    formInputs: customNotificationForm,
                    radioInput: [],
                    formButtons: [
                        { type: "primary", label: "確定する", onClick: props.handleUpdate },
                        { type: "secondary", label: "戻る", onClick: handleShowEditForm }
                    ]
                }
            }
            case 'confirmDelete': {
                //Update form definition
                let customNotificationForm = Object.assign({}, notificationUpdateForm);
                customNotificationForm.form[0].name="adminNoticeConfirmDeleteMsg";
                customNotificationForm = handleHideDate(customNotificationForm);

                return{
                    title: 'お知らせ【削除】',
                    preview: true,
                    validate: selectors.validateNotification,
                    formInputs: customNotificationForm,
                    radioInput: [],
                    formButtons: [
                        { type: "primary", label: "削除する", onClick: (index) => { props.handleDbDelete(index) }},
                        { type: "secondary", label: "戻る", onClick: props.handleCloseModal }
                    ]
                }
            }
            case 'successUpdate': {
                //Update form definition
                let customNotificationForm = Object.assign({}, notificationUpdateForm);
                customNotificationForm.form[0].name="adminNoticeConfirmCreateUpdateMsg";
                customNotificationForm = handleHideDate(customNotificationForm);

                return{
                    title: 'お知らせ作成・編集',
                    preview: true,
                    formInputs: customNotificationForm,
                    radioInput: [],
                    currNotif: props.currNotif,
                    formButtons: [
                        { type: "secondary", label: "閉じる", onClick: props.handleCloseModal}
                    ]
                }
            }
            case 'successDelete': {
                //Update form definition
                let customNotificationForm = Object.assign({}, notificationUpdateForm);
                customNotificationForm.form[0].name="adminNoticeSuccessDeleteMsg";
                customNotificationForm = handleHideDate(customNotificationForm);

                return{
                    title: 'お知らせ【削除】',
                    preview: true,
                    formInputs: customNotificationForm,
                    formButtons: [
                        { type: "secondary", label: "閉じる", onClick: props.handleCloseModal}
                    ]
                }
            }
            default: return{
                formInputs: notificationUpdateForm,
                radioInput: radioInput
            };
        }
    }

    return (
        <View>
            <Modal
                ariaHideApp={false}
                isOpen={props.isModalShow}
                className="notificationManagement-modal"
                overlayClassName="notificationManagement-modal-overlay"
            >
                <TouchableOpacity
                    style={styles.modalClose}
                    onPress={props.handleCloseModal}
                >
                    <Image source={CloseIcon} alt="close-icon" style={styles.closeIcon} />
                </TouchableOpacity>
                <View>

                {/**Render Form */}
                <FormTemplate
                    key={props.type}
                    validate={selectors.validateNotification}
                    currNotif={props.currNotif}
                    {...setFormProps()}
                />
                </View>
            </Modal>
        </View>    
    );
};

export default NotificationModal;
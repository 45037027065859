import * as Path from "./apiRoutes";
import ApiService from "../../../../utils/apiService";
import * as Actions from "./actions";
import { onApiSuccess } from "../../../../utils/common";

const getAdminNotice = () => dispatch =>{
    return ApiService.get(Path.GET_ADMIN_NOTICE)
    .then(response => {
        if(onApiSuccess(response)){
            let data = JSON.parse(response.data.data);
            dispatch(Actions.updateAdminNotice(data));
        }
      return response;
    })
    .catch(error => {
      console.error(error);
    });
}

const getPaginatedNewsfeed = (userId, currentPagination) => dispatch => {
  return ApiService.get(Path.GET_PAGINATED_NEWSFEED + `/${userId}/${currentPagination}`)
    .then(response => {
        if(onApiSuccess(response)){
            let data = JSON.parse(response.data.data);
            if(1 === currentPagination){
                dispatch(Actions.updateNewsfeedList(data));
            }else{
                dispatch(Actions.appendNewsfeedList(data));
            }
            
        }
      return response;
    })
    .catch(error => {
      console.error(error);
    });
};

const removeNewsfeedPost = (postId) => dispatch =>{
    dispatch(Actions.removeNewsfeedPost(postId))
}

const updatePostImageSocket = (postId, imageUrl, imageStatus) => dispatch =>{
    dispatch(Actions.updatePostImageSocket({postId, imageUrl, imageStatus}))
}

const updatePostVideoSocket = (postId, videoUrl, videoStatus) => dispatch => {
    dispatch(Actions.updatePostVideoSocket({postId, videoUrl, videoStatus}))
}

export{
    getAdminNotice,
    getPaginatedNewsfeed,
    removeNewsfeedPost,
    updatePostImageSocket,
    updatePostVideoSocket
}
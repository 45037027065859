import LoginScreen from './LoginScreen'
import Logout from './Logout'
import MainContainer from "../UserMain/MainContainer";
import UserAuthentication from './UserAuthentication';

/**Dummy auth prior to data migration */
import DummyUserAuthTest from './dummy/UserAuthTest'
import DummyUserAuthentication from './dummy/UserAuthentication';
export const DUMMY_USER_AUTH_REDIRECT = "/user/auth/:id";
export const DUMMY_USER_AUTH_ROUTE = "/user/authenticate";


export const LOGIN_ROUTE = "/admin/login";
export const LOGOUT = "/logout";
export const USER_AUTH_ROUTE = "/action/login";
export const APP_ROOT_ROUTE = "/app";

export const routes = [
    {
        path: LOGIN_ROUTE,
        component: LoginScreen,
        exact: true,
        public: true
    },
    {
        path: LOGOUT,
        component: Logout,
        exact: true,
        public: true
    },
    {
        path: USER_AUTH_ROUTE,
        component: UserAuthentication,
        exact: true,
        public: true
    },
    {
        path: APP_ROOT_ROUTE,
        component: MainContainer,
        exact: true,
        public: true
    },


    {
        path: DUMMY_USER_AUTH_REDIRECT,
        component: DummyUserAuthTest,
        exact: true,
        public: true
    },
    {
        path: DUMMY_USER_AUTH_ROUTE,
        component: DummyUserAuthentication,
        exact: true,
        public: true
    },
];
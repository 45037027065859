import React, { Component } from "react";
import { FFMWEB_PRIVACY_POLICY_URL } from "../../../config/settings";
import ProcessLoader from '../../components/ProcessLoader';


export class PrivacyPolicy extends Component {
  constructor( props ){
    super();
    this.state = { ...props };
  }
  componentWillMount(){
    window.location = FFMWEB_PRIVACY_POLICY_URL;
  }
  render(){
    return (<ProcessLoader isShown={true} />);
  }
}

export default PrivacyPolicy;
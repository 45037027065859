import React from "react";
import { View, Text } from "react-native";
import styles from "./styles/chatconversation.styles.native";
import "./styles/chat.scss";
import moment from "moment";
import RemoveModalWindow from "./RemoveModalWindow"
import { RANDOM_MSG_THUMBNAIL_BUCKET_URL, CHAT_TYPE , CHAT_THUMBNAIL_BUCKET_URL, THUMBNAIL_SMALL, THUMBNAIL_MEDIUM} from "../../../utils/constants"
import { USER_DELETED_MESSAGE, FRIEND_DELETED_MESSAGE } from "../../../utils/messages";
import { isNullOrUndefined } from "util";
import { isMobile } from "react-device-detect";
import { Media } from 'react-breakpoints'
import ProfileIcon from '../../../includes/img/placeholders/user.svg';
import { addDefaultSrc } from "../../../utils/common";
import LinkTransform from "../../components/LinkTransform";

const ChatItemsComponent = props => {
  return(
    <Media>
    {({ breakpoints, currentBreakpoint }) => {
      return (
        <View>
          {!isNullOrUndefined(props.date) ?      
          <Text style={breakpoints[currentBreakpoint] < breakpoints.mobileLandscape || isMobile ? styles.dateTextMobile : styles.dateTextPC}>
            {moment(props.date).format("YYYY/MM/DD")}
          </Text>  
          : null}
          {props.ownerId === props.item.senderId ?
              <UserItemContainer key={props.item.chatId.$oid} {...props} item={props.item} />
            : <FriendItemContainer key={props.item.chatId.$oid} {...props} item={props.item} />
          }
        </View>
      );
    }}
    </Media>
  )
};

const Timestamp = props => {
  return (
    (moment(props.item.createDt.$date).get("hours")< 10?'0': '') + moment(props.item.createDt.$date).get("hours") +
    ":" + (moment(props.item.createDt.$date).get("minutes")< 10?'0': '') + moment(props.item.createDt.$date).get("minutes")
  );
};


const UserItemContainer = props => {
  return (
      <Media>
      {({ breakpoints, currentBreakpoint }) => {
        let isDeviceMobile = (breakpoints[currentBreakpoint] < breakpoints.mobileLandscape) || isMobile
        return(
          <View style={ props.item.chatId === props.selectedMessage ? null : styles.belowContainer}>
          {/* Check if chat type is text*/}
          {CHAT_TYPE.text === props.item.type ? (
            <View>
              {props.item.isDeleted ? 
               (
                <Text style={breakpoints[currentBreakpoint] < breakpoints.mobileLandscape || isMobile ? styles.deletedMessage : styles.deletedMessagePC}>{USER_DELETED_MESSAGE}</Text>
               ) : 
                  (
                   <View style={styles.userItemContainer}>
                          <Text style={isDeviceMobile ? styles.userTimeStamp :styles.userTimeStampPC}>
                            {props.item.isDeleted ? null : <Timestamp {...props} />}
                          </Text>
                          <Text
                            style={isDeviceMobile ? styles.userChatItem : styles.userChatItemPC}
                            onPress={() => props.handleToggleDelete(props.item.chatId)}
                          >
                            <LinkTransform>
                              {props.item.chatBody}
                            </LinkTransform>
                          </Text>
                          {props.isMessageDelete &&  props.item.chatId === props.selectedMessage ? (
                            <View style={isDeviceMobile ? styles.chatDeleteContainer : styles.chatDeleteContainerPC}>
                              <RemoveModalWindow {...props}/>
                            </View>
                          ) : null}
                  </View>
                )}
            </View>
          ) : (
            <View>
            {(props.item.isSent ? (!props.item.isDeleted) ? (
              <View style={styles.userItemContainer}>
                  <Text style={isDeviceMobile ? styles.userTimeStampImage : styles.userTimeStampImagePC}>
                      {props.item.isDeleted ? null : <Timestamp {...props} />}
                  </Text>

                  <div className={isDeviceMobile ? "chat-image-container" : "chat-image-pc-container"}>
                    <img
                      className={isDeviceMobile ? "chat-image chat-image-user-mobile" : "chat-image chat-image-user-pc" }
                      style={{maxWidth: isDeviceMobile ? 180 : 300}}
                      loading="lazy"
                      alt={"user-sent-img"}
                      src={isDeviceMobile ? CHAT_THUMBNAIL_BUCKET_URL + props.ownerId +'/'+ props.threadId.$oid +'/'+ props.item.chatId.$oid + THUMBNAIL_SMALL :
                      CHAT_THUMBNAIL_BUCKET_URL + props.ownerId +'/'+ props.threadId.$oid +'/'+ props.item.chatId.$oid + THUMBNAIL_MEDIUM
                      }
                      onClick={() => props.handleToggleDelete(props.item.chatId)}
                    />
                  </div>
                  {/* Display modal window */}
                  {props.isMessageDelete && props.item.chatId === props.selectedMessage ? (
                    <View style={isDeviceMobile ? styles.chatDeleteContainerImage : styles.chatDeleteContainerImagePC}>
                      <RemoveModalWindow {...props}/>
                    </View>
                  ) : null}
            </View>
            ) : (
              <Text style={isDeviceMobile ? styles.deletedMessage : styles.deletedMessagePC}>{USER_DELETED_MESSAGE}</Text>
            ) : null)}
          </View>
        )}
      </View>
        )
      }}
      </Media>  
  )
};


const FriendItemContainer = props => {
  let isProfileImageUrlValid = props.imageURL && 0 <= props.imageURL.indexOf("Profile/");
  return (
    <Media>
    {({ breakpoints, currentBreakpoint }) => {
      let isDeviceMobile = (breakpoints[currentBreakpoint] < breakpoints.mobileLandscape) || isMobile
      return (
        <View style={styles.belowContainer}>
          {CHAT_TYPE.text === props.item.type ? (
            <View>
              {props.item.isDeleted ?
               (
                <Text style={isDeviceMobile ? styles.deletedMessage : styles.deletedMessagePC}>
                  {props.recipientHandleName + FRIEND_DELETED_MESSAGE}
                </Text>
               ) :
              (
                <View style={styles.friendItemContainer}>
                    <img 
                      className="chat-avatar" 
                      alt={"friend-avatar"}
                      src={isProfileImageUrlValid ? (props.imageURL + `?${props.hash}`) : ProfileIcon} onError={addDefaultSrc}
                    />
                      <Text style={isDeviceMobile ? styles.friendChatItem : styles.friendChatItemPC}>
                        <LinkTransform>{props.item.chatBody}</LinkTransform>
                      </Text>

                  {/* Check if item is deleted */}
                  {props.item.isDeleted ? null : (
                    <View style={styles.friendTimeStampContainer}>
                        <Text style={isDeviceMobile ?  styles.friendTimeStamp : styles.friendTimeStampPC}>
                          <Timestamp {...props} />
                        </Text>
                    </View>
                  )}
                </View>
              )}
            </View>
          ) : (
            <View>
              {props.item.isSent ? (!props.item.isDeleted) ? 
              (
                <View style={styles.friendItemContainer}>
                    <img 
                      className="chat-avatar" 
                      alt={"friend-avatar"}
                      src={isProfileImageUrlValid ? (props.imageURL + `?${props.hash}`) : ProfileIcon} onError={addDefaultSrc}
                    />
                    <div className={isDeviceMobile ? "chat-image-container" : "chat-image-pc-container"}>
                        {!isNullOrUndefined(props.item.randomMsgId) ? 
                            <img
                              className={isDeviceMobile ? "chat-image chat-image-friend-mobile" : "chat-image chat-image-friend-pc" }
                              style={{maxWidth: isDeviceMobile ? 180 : 300}}
                              alt={"friend-sent-img"}
                              src={isDeviceMobile ? RANDOM_MSG_THUMBNAIL_BUCKET_URL + props.item.randomMsgId.$oid  +'/'+ props.item.randomMsgId.$oid  + THUMBNAIL_SMALL
                              : RANDOM_MSG_THUMBNAIL_BUCKET_URL + props.item.randomMsgId.$oid  +'/'+ props.item.randomMsgId.$oid  + THUMBNAIL_MEDIUM}
                              /> :
                            <img
                              className={isDeviceMobile ? "chat-image chat-image-friend-mobile" : "chat-image chat-image-friend-pc" }
                              style={{maxWidth: isDeviceMobile ? 180 : 300}}
                              alt={"friend-sent-img"}
                              src={isDeviceMobile ? CHAT_THUMBNAIL_BUCKET_URL + props.ownerId +'/'+ props.threadId.$oid +'/'+ props.item.chatId.$oid + THUMBNAIL_SMALL 
                             : CHAT_THUMBNAIL_BUCKET_URL + props.ownerId +'/'+ props.threadId.$oid +'/'+ props.item.chatId.$oid + THUMBNAIL_MEDIUM }
                            />
                        }
                        <Text style={isDeviceMobile ? styles.friendTimeStampImage : styles.friendTimeStampImagePC}>
                          <Timestamp {...props} />
                        </Text>
                    </div>
               </View>
              ) : (
                <Text style={isDeviceMobile ? styles.deletedMessage : styles.deletedMessagePC}>
                   {props.recipientHandleName + FRIEND_DELETED_MESSAGE}
                </Text>
              ) : null}
            </View>
          )}
        </View>
      );
    }}
    </Media>
  )
};

export default ChatItemsComponent;

import React from "react";
import Modal from "react-modal";
import { View, Text,  Image, TouchableOpacity} from "react-native";
import { Button } from "react-native-elements";
import "./styles.scss";
import CloseIcon from "../../../includes/img/icons/close.svg";
import styles from "./styles.native.js";

const ErrorModal = props => {
  return (
    <View>
      <Modal
        ariaHideApp={false}
        isOpen={props.isErrorModalShow}
        className="error-modal"
        overlayClassName="error-modal-overlay"
      >
        <TouchableOpacity
          style={styles.modalClose}
          onPress={() => props.handleCloseModal()}
        >
          <Image source={CloseIcon} alt="close-icon" style={styles.closeIcon} />
        </TouchableOpacity>
        <View>
          <Text style={styles.modalText}>{props.errorMessage}</Text>
        </View>
        <div className="btn-container">
          <Button
            onClick={() => props.handleCloseModal()}
            buttonStyle={styles.closeBtn}
            title={"閉じる"}
            titleStyle={styles.titleClose}
          />
        </div>
      </Modal>
    </View>
  );
};

export default ErrorModal;

import React from 'react';
import ContentLoader from 'react-content-loader'

const RowSkeleton = (
    <ContentLoader
        speed={2}
        width={'100%'}
        height={70}
        
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >

        <rect x="65" y="12" width="1000" height="8" rx="3" />
        <rect x="65" y="30" width="150" height="8" rx="3" />
        <circle cx="26" cy="26" r="26" />
    </ContentLoader>
);

const NotificationSkeleton = () => {
    return (
        <div className="pt-3 pr-3 pl-3">
            {RowSkeleton}
            {RowSkeleton}
            {RowSkeleton}
        </div>
    );
};

export default NotificationSkeleton;
import React from 'react';
import { Media } from 'react-breakpoints'
import { isMobile } from "react-device-detect";

const PCOnlyView = (props) => {
    return (
        <Media>
            {({ breakpoints, currentBreakpoint }) => {
                if ((breakpoints[currentBreakpoint] < breakpoints.mobileLandscape) || isMobile) {
                    return "";
                } else {
                    return props.children;
                }
            }}
        </Media>
    );
};

export default PCOnlyView;
import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  header: {
    backgroundColor: "#F5F5F5",
    borderBottomColor: "#dcdcdc",
    borderBottomWidth: 1,
    height: 60
  },
  listItemContainer: {
    
  },
  listItem: {
    paddingLeft: 12,
    paddingRight: 12, 
    margin: 0,
    height: 52
  },
  title: {
    marginLeft: 10,
    color: "#0A182C",
    maxWidth: 350
  },
  horizontalBorder: {
    borderBottomColor: "#EAEDED",
    borderBottomWidth: 1,
    width: "100%",
    alignSelf: "center"
  },
  pastPostDetailsContainer: {
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      marginVertical: 12,
      marginHorizontal: 16
  },
  filterButton: {
    flexDirection: "row"
  },
//Calendar Modal
  modalClose: {
    position: "absolute",
    top: "-5px",
    right: "-5px",
    zIndex: 2
  },
  closeIcon: {
    width: 22,
    height: 22
  },
  yearContainer: {
    flex: 1,
    alignItems: "flex-start", 
    justifyContent: 'center',
    width : 270, 
    maxWidth: 270,
    margin: 0, 
    marginLeft: "auto", 
    marginRight: "auto"
  },
  yearText : {
    width: 100
  }
});


export default styles;

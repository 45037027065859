import React from 'react';
import PCOnlyView from '../PCOnlyView';
import { Image } from 'react-bootstrap';
import './styles.scss';

const PCOnlyButton = ({disabled, label, icon, buttonStyle, onClick}) => {
    return (
        <PCOnlyView>
            <button 
                disabled={disabled}
                className="pc-action-button" 
                style={buttonStyle || {}}
                onClick={onClick}
            >
                <span>{label}</span>
                <span className="pc-action-icon">
                    <Image
                        alt="action-icon"
                        src={icon}
                    />
                </span>
            </button>
        </PCOnlyView>
    );
};

export default PCOnlyButton;
import AdminContainer from "./AdminContainer";

export const ADMIN_ROOT_ROUTE = "/admin";

//Parent User Route
export const routes = [
    {
        path: ADMIN_ROOT_ROUTE,
        component: AdminContainer,
        exact: false,
        showNav: true
    }
]
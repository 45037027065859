import * as types from "./types";
import { getObjKeyIdString } from "../../../../utils/common";

const defaultState = {
    //Module state
    notificationsList: []
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.UPDATE_NOTIFICATIONS_LIST:{
            return {
                ...state,
                notificationsList: action.payload
            };
        }
        case types.APPEND_NOTIFICATIONS_LIST:{
            return {
                ...state,
                notificationsList: [...state.notificationsList, ...action.payload]
            };
        }
        case types.REMOVE_REQUEST_NOTIFICATION:{
            let filteredNotifications = state.notificationsList.filter(i => getObjKeyIdString(i._id) !== action.payload);
            return {
                ...state,
                notificationsList: filteredNotifications
            };
        }
        case types.ADD_RECEIVED_NOTIFICATION:{
            let filteredNotifications = state.notificationsList.filter(i => getObjKeyIdString(i._id) !== getObjKeyIdString(action.payload._id));
            filteredNotifications.unshift(action.payload)
            return {
                ...state,
                notificationsList: filteredNotifications
            };
        }
        case types.SET_SEEN_NOTIFICATION:{
            let notifList = Object.assign([], state.notificationsList);
            let updateNotif = notifList.filter(i => getObjKeyIdString(i._id) === action.payload);
            if(0 < updateNotif.length){
                updateNotif[0].status = 1;
            }
            
            return {
                ...state,
                notificationsList: notifList
            };
        }
        
        default: return state;
    }
};
import { routes } from "../routes";
import * as postOperations from './operations';
import * as postSelectors from './selectors';

export {
    postOperations,
    postSelectors
}

export default {
    routes
};

import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  header: {
    backgroundColor: "#F5F5F5",
    borderBottomColor: "#dcdcdc",
    borderBottomWidth: 1,
    height: 60
  },
  listItemContainer: {
    paddingBottom: 8,
  },
  listItemContainerMobile: {
    
  },
  listItem: {
    paddingLeft: 12,
    paddingRight: 12, 
    margin: 0,
    height: 52
  },
  title: {
    marginLeft: 10,
    color: "#0A182C",
    maxWidth: 350,
    fontFamily: "kozuka-gothic-pro, sans-serif"
  },
  titleMobile: {
    marginLeft: 10,
    color: "#0A182C",
    maxWidth: 350,
    fontFamily: "kozuka-gothic-pro, sans-serif",
    fontSize: 14
  },
  horizontalBorder: {
    borderBottomColor: "#EAEDED",
    borderBottomWidth: 1,
    width: "100%",
    alignSelf: "center",
  },
  flatlistContainer : {
    flex: 1,
    paddingBottom: 3
  }
});

export default styles;

import React from 'react';
import MobileOnlyView from '../MobileOnlyView';
import { TouchableOpacity } from 'react-native';
import { Image } from 'react-bootstrap';
import './styles.scss';

const MobileOnlyButton = ({ disabled, buttonStyle, labelStyle, onPress, icon, label }) => {
    return (
        <MobileOnlyView>
            <TouchableOpacity 
                disabled={disabled}
                onPress={onPress} 
                style={buttonStyle || {}}
            >
                <Image
                    alt="action-icon"
                    src={icon}
                    style={label ? { marginBottom: 4 } : {}}
                />
                {label && <p style={{...labelStyle, color: disabled ? "#cacaca" : "#000"}}>{label}</p>}
            </TouchableOpacity>
        </MobileOnlyView>
    );
};

export default MobileOnlyButton;
import React from "react";
import { TouchableOpacity, Image } from "react-native";
import "./styles/chat.scss";
import { IMAGE_MAX_UPLOAD } from "../../../utils/constants";
import styles from "./styles/chatconversation.styles.native";
import AddImageIcon from "../../../includes/img/buttons/add-image.png";
import CloseIcon from "../../../includes/img/icons/close.svg";

const ChatImageComponent = (props) => {
  let data = [...props.imageFiles, { addImage: true }];
  return (
    <div className="add-image-container">
      {data.map((item, index) => {
        if (item.addImage) {
          return (
            <div key={index} className="file-upload-container ">
              <label className="chat-custom-file-upload">
                <img
                  src={AddImageIcon}
                  alt="icon-upload"
                  className="add-image mr-2"
                />
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => props.handleAddImage(e)}
                  multiple
                />
              </label>
            </div>
          );
        } else if (item instanceof File && IMAGE_MAX_UPLOAD !== index) {
          return (
            <div  key={index} className="image-preview-container">
              <TouchableOpacity
               onPress={(e) =>  props.handleRemoveImage(e,index)}>
                <Image
                  source={CloseIcon}
                  alt="close-icon"
                  style={styles.removeIcon}
                />
              </TouchableOpacity>
              <img
                className="image-preview"
                src={URL.createObjectURL(item)}
                alt={item.name}
              />
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default ChatImageComponent;
import {USER_ROOT_API_URL } from "../../../../config/settings.js"

export const GET_USER_FRIENDS = USER_ROOT_API_URL + "/get_user_friends";
export const SEARCH_FRIEND = USER_ROOT_API_URL + "/search_friend";
export const GET_ALL_CHAT_THREAD = USER_ROOT_API_URL + "/get_all_chat_thread";
export const DELETE_CHAT_THREAD = USER_ROOT_API_URL + "/delete_chat_thread";
export const GET_SPECIFIC_CHAT_THREAD = USER_ROOT_API_URL + "/get_specific_chat_thread";
export const GET_FRIEND_HANDLENAME = USER_ROOT_API_URL + "/get_friend_handlename";
export const IS_RECIPIENT_BLOCKED = USER_ROOT_API_URL + "/is_recipient_blocked";
export const GET_UNSEEN_THREADS = USER_ROOT_API_URL + "/get_unseen_threads";
export const VALIDATE_USER = USER_ROOT_API_URL + "/validate_user";
import React from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { authOperations } from './state';
import ProcessLoader from '../../components/ProcessLoader';

const Logout = () => {
  const dispatch = useDispatch();
  let operations = bindActionCreators(
      {
        logoutUser: authOperations.logoutUser
      },
      dispatch
  )

  /**Logout and close current tab */
  let isAdmin = false;
  let comuOnly = true;
  operations.logoutUser(isAdmin, comuOnly);

  return <ProcessLoader isShown={true} />;
}

export default Logout;
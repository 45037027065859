import React from 'react';
import LoadingSvg from '../../../includes/img/svg/loading.svg';
import LoadingTextSvg from '../../../includes/img/svg/loading-text.svg';
import Modal from "react-modal";
import './styles.scss';

const ProcessLoader = ({ isShown }) => {
    if (isShown) {
        return (
            <Modal
                ariaHideApp={false}
                isOpen={isShown}
                className="loader-modal"
                overlayClassName="loader-modal-overlay"
            >
                <img src={LoadingSvg} alt="" className="loader-icon" />
                <img src={LoadingTextSvg} alt="GE" className="loader-text" />
            </Modal>
        );
    } else {
        return "";
    }
};

export default ProcessLoader;

import React, { useState } from 'react';
import { Image, Media as BootstrapMedia, Col } from 'react-bootstrap';
import DefaultIcon from '../../../includes/img/placeholders/user.svg';
import { useSelector } from 'react-redux';
import TextareaAutosize from '../../../../node_modules/react-autosize-textarea/lib';
import SaveIcon from '../../../includes/img/icons/save.svg';
import SendWhiteIcon from '../../../includes/img/icons/send-white.svg';
import './styles.scss';
import PCOnlyButton from '../PCOnlyButton';
import { emptyTextValue, addDefaultSrc } from '../../../utils/common';
import MobileOnlyButton from '../MobileOnlyButton';
import { postSelectors } from '../../containers/Post/state';
import { CLOUDFRONT_THUMBNAIL_PREFIX_URL, REPLIES_MAX_CHAR } from '../../../utils/constants';
import { Beforeunload } from 'react-beforeunload';
import DiscardChangesModal from '../DiscardChangesModal';

const RenderCreateReply = ({ commentId, mobileView, callbackReply, hash }) => {
    //Redux store data
    const user = useSelector(state => state.auth.credentials);

    //Hooks state
    const [reply, setReply] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const [hasUpdates, setHasUpdates] = useState(false); //For page redirect check

    const handleInputChange = (e) => {
        let newError = postSelectors.validateTextField(e, formErrors);
        setReply(e.target.value);
        setFormErrors(newError);
        if("" === e.target.value){
            setHasUpdates(false)
        }else{
            setHasUpdates(true)
        }
    }

    const handleCallbackReply = () => {
        callbackReply(commentId, reply);
    }

    const isSaveDisabled = emptyTextValue(reply) || 0 < Object.keys(formErrors).length;
    let isProfileImageUrlValid = user.profileImageUrl && 0 <= user.profileImageUrl.indexOf("Profile/");

    return (
        /** On before page leave*/
        <Beforeunload onBeforeunload={(event) => hasUpdates ? event.preventDefault() : {}} >
            <BootstrapMedia style={{ marginLeft: 12 }}>

                {/** On before route leave*/}
                {hasUpdates && <DiscardChangesModal active={true} />}

                <Image
                    onError={addDefaultSrc}
                    src={isProfileImageUrlValid ? `${CLOUDFRONT_THUMBNAIL_PREFIX_URL + user.profileImageUrl}-small?${hash}` : DefaultIcon}
                    className="post-view-avatar"
                    roundedCircle
                />
                <BootstrapMedia.Body>
                    <Col style={{ marginBottom: 14, paddingRight: 0 }}>
                        <div className="d-flex flex-direction-row">
                            <div className="w-100">
                                <TextareaAutosize
                                    name="replyInput"
                                    value={reply}
                                    onChange={handleInputChange}
                                    placeholder={"返信する"}
                                    className={`form-control post-view-comment-textarea-${mobileView ? "mobile" : "pc"}`}
                                    style={{ marginLeft: 0 }}
                                    maxLength={REPLIES_MAX_CHAR}
                                />

                                {Object.values(formErrors).map((err, i) => {
                                    return <p key={"err" + i} className="mt-1 formik-error flex-column">{err}</p>
                                })}
                            </div>

                            <MobileOnlyButton
                                disabled={isSaveDisabled}
                                onPress={() => handleCallbackReply()}
                                buttonStyle={{ alignItems: 'center', marginLeft: 12 }}
                                labelStyle={{ fontSize: 14 }}
                                icon={SaveIcon}
                                label={'保存'}
                            />
                        </div>

                        <span style={{ paddingRight: mobileView ? 32 : 0 }} className={`d-flex justify-content-end mt-2 mb-4`}>
                            <PCOnlyButton
                                disabled={emptyTextValue(reply)}
                                label={'返信する'}
                                icon={SendWhiteIcon}
                                onClick={() => handleCallbackReply()}
                            />
                        </span>
                    </Col>
                </BootstrapMedia.Body>
            </BootstrapMedia>
        </Beforeunload>
    )
}

export default RenderCreateReply;
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { mypageOperations } from './state';
import { connect } from 'react-redux';
import { Card } from 'react-bootstrap';
import { onApiSuccess, appendUserRoute, defaultTopScroll } from '../../../utils/common';
import PCOnlyView from '../../components/PCOnlyView';
import MobileOnlyView from '../../components/MobileOnlyView';
import PostItemTemplate from '../../components/PostItemTemplate';
import './styles/create.scss';
import './styles/info.scss';
import './styles/feed.scss';
import './styles/preview.scss';
import MyPageHeaderComponent from "./MyPageHeaderComponent"
import MyPageDetailComponent from "./MyPageDetailComponent"
import ErrorModal from '../../components/ErrorModal';
import { EMPTY_STRING, PREVIEW_TYPE, POST_PRIVACY } from '../../../utils/constants';
import { SYSTEM_ERROR, MSG_NO_POST_YET } from '../../../utils/messages';
import {isBottomScrollReached, fetchEmptyScroll} from "../../../utils/common"
import { ActivityIndicator } from 'react-native';
import Select from 'react-select';
import ConfirmationModal from "../../components/ConfirmationModal"
import { isNullOrUndefined } from 'util';
import { profileOperations } from '../EditProfile/state';

const preview_types = [
    { value: "friends", label: '友達' },
    { value: "public", label: '一般ユーザ' }
];

class MyPagePreview extends Component {
    constructor(props){
        super(props)
        this.state = {
            ownerId: props.userId,
            handleName: props.handleName,
            userModel: props.userInformation,
            randomFriends: [],
            currentPagination : 1,
            privacyType: POST_PRIVACY.friends,
            isPreview: true,
            postList: [],
            isLoading: true,
            errorMessage: EMPTY_STRING,
            isErrorModalShow: false,
            isPostFetching: true,
            postId: null,
            initialLoad: true,
            isFriendModalShow: false,
            isPostModalShow: false,
            coverHash: Date.now(),
            profileHash : Date.now(),
            userId: null,
            isPostItemsHidden: true,
            isLastPage: false
        }
    }

    reloadPage(){
        defaultTopScroll();
        if(this.state.initialLoad){
            this.props.getUserProfile(this.state.ownerId).then(response => {
                if(!onApiSuccess(response)) {
                    this.setState({
                        isErrorModalShow: true,
                        errorMessage: SYSTEM_ERROR
                    })
                }
            })
    
            this.props.getRandomFriends(this.state.ownerId).then(response => {
                if(onApiSuccess(response)){
                    let data = JSON.parse(response.data.data)
                    let friendsList= data.friends
                    let friendsData = {
                        "count" : data.count,
                        "friends" : friendsList
                    }
                    this.setState({
                        randomFriends : friendsData
                    })
                }else{
                    this.setState({
                        isErrorModalShow : true,
                        errorMessage: SYSTEM_ERROR
                    })
                }
            })

            this.props.getUserPosts(this.state.ownerId, this.state.privacyType, this.state.currentPagination)
            .then(response => {
                if(onApiSuccess(response)){
                    let postListData = JSON.parse(response.data.data)
                    this.setState({
                        postList : postListData,
                        isLoading: false,
                        initialLoad: false,
                        isPostItemsHidden: false,
                        isPostFetching: false,
                        currentPagination : this.state.currentPagination + 1
                    })
                }else{
                    this.setState({
                        isPostFetching: true,
                        isLoading: true,
                        isErrorModalShow : true,
                        errorMessage: SYSTEM_ERROR
                    })
                }
            })
        }else{
            this.props.getUserPosts(this.state.ownerId, this.state.privacyType, this.state.currentPagination)
                .then(response => {
                    if(onApiSuccess(response)){
                        let postListData = JSON.parse(response.data.data)
                        this.setState({
                            postList : postListData,
                            isLoading: false,
                            isPostItemsHidden: false,
                            isPostFetching: false,
                            currentPagination : this.state.currentPagination + 1
                        })
                    }else{
                        this.setState({
                            isPostFetching: true,
                            isLoading: true,
                            isChangePreview: true,
                            isErrorModalShow : true,
                            errorMessage: SYSTEM_ERROR
                        })
                    }
                })
        }   
    }

    componentDidMount(){
        this.reloadPage()
        document.addEventListener('scroll', this.onScrollCheck);
        defaultTopScroll();
    }

    componentWillMount(){
        defaultTopScroll();
    }

    componentWillUnmount(){
        document.removeEventListener('scroll', this.onScrollCheck);
    }

    onScrollCheck = () =>{
        if(!this.state.isLoading && isBottomScrollReached()){
            this.handlePagination();
        }
    }

    handleChangePreviewType = (type) => {
        if(PREVIEW_TYPE.public === type.value){
            this.setState({
                privacyType: POST_PRIVACY.public,
                isLastPage: false,
                currentPagination: 1
            }, () =>  this.reloadPage())
        }else{
            this.setState({
                privacyType: POST_PRIVACY.friends,
                isLastPage: false,
                currentPagination: 1
            }, () =>  this.reloadPage())
        }
    }


    handlePagination = () => {
        if (!this.state.isPostFetching && !this.state.isLastPage) {
            this.setState({isPostFetching: true }, () => {
            this.props.getUserPosts(this.state.ownerId, this.state.privacyType, this.state.currentPagination)
                .then(res => {
                    if (onApiSuccess(res)) {
                        let postListData = JSON.parse(res.data.data)
                        let updateFetch = () => {
                            this.setState(prevState => ({
                               postList: [
                                   ...prevState.postList,
                                   ...postListData
                               ],
                               currentPagination : prevState.currentPagination + 1,
                               isPostFetching: false
                           }))
                       }
                       if (0 >= postListData) {
                           this.setState({
                                isLastPage: true
                           })
                           fetchEmptyScroll();
                           setTimeout(() => { updateFetch() }, 500);
                       } else {
                           updateFetch();
                       }
                    } else {
                        this.setState({
                            isErrorModalShow : true,
                            errorMessage: SYSTEM_ERROR,
                            isPostFetching: false
                        })                   
                    }
                })
            });
        }
    }

    handleCloseErrorModal = () =>{
        this.setState({
            isErrorModalShow: false
        })
    }

    handleRedirectProfile = (userId) =>{
        if(this.state.isFriendModalShow){
            let profileRoute = appendUserRoute(`/user/${userId}`);
            this.handleCloseModal()
            this.props.history.push(profileRoute)
        }
    }


    handleOpenModal = () =>{
        this.setState({
            isFriendModalShow: true
        })
    }
    
    handleCloseModal = () =>{
        this.setState({
            isPostModalShow: false,
            isFriendModalShow: false
        })
    }

    handleOpenPostModal = (id, action) =>{
        if("header" === action){
            this.setState({
                isPostModalShow: true,
                userId: id,
                postId: null
            })
        }else{
            this.setState({
                isPostModalShow: true,
                postId: id,
                userId: null
            })
        }
    
    }

    handleRedirectPost = () =>{
        if(this.state.isPostModalShow){
            if(isNullOrUndefined(this.state.postId)){
                let mypageRoute = appendUserRoute(`/mypage`);
                this.handleCloseModal()
                this.props.history.push(mypageRoute)
    
            }else{
                let postRoute = appendUserRoute(`/post/view/${this.state.postId}`);
                this.handleCloseModal()
                this.props.history.push(postRoute)
            }
        }
    }

    render() {
            let type = this.state.privacyType
            return (
                    <React.Fragment>
                        <>
                            <Card className="privacy-main-container">
                            <Card.Body className="privacy">
                                <PCOnlyView>
                                    <Select
                                            classNamePrefix="react-select"
                                            defaultValue={type ? preview_types[0] : preview_types[1]}
                                            placeholder={null}
                                            onChange={this.handleChangePreviewType}
                                            isSearchable={false}
                                            options={preview_types}
                                            className={type ? "friend-privacy" : "public-privacy"}
                                    />
                                        <span className="select-text-pc">にはこのように表示されます。</span>
                                </PCOnlyView>
                                <MobileOnlyView>
                                    <Select
                                            classNamePrefix="react-select"
                                            defaultValue={type ? preview_types[0] : preview_types[1]}
                                            placeholder={null}
                                            onChange={this.handleChangePreviewType}
                                            isSearchable={false}
                                            options={preview_types}
                                            className={type ? "friend-privacy-mobile" : "public-privacy-mobile"}
                                    />
                                        <span className="select-text-mobile">にはこのように表示されます。</span>
                                </MobileOnlyView>
                            </Card.Body>
                            </Card>    
                            <Card className="mypage-info-container">
                                <MyPageHeaderComponent 
                                    {...this.state}
                                    handleToggleMenu={this.handleToggleMenu}
                                    handleProfileImageChange={this.handleProfileImageChange}
                                    handleCoverImageChange={this.handleCoverImageChange}
                                />
                                <div className="horizontal-border"></div>
                                
                                <MyPageDetailComponent {...this.state}/>
                                                             
                            </Card>

                            <div className="feed-main preview">
                                {!this.state.isLoading && !this.state.isPostItemsHidden ? 
                                    0 < this.state.postList.length ?
                                    ( 
                                        this.state.postList.map((details, i) =>
                                            <PostItemTemplate key={"post" + i} 
                                                {...details} 
                                                ownerId={this.state.ownerId}
                                                handleName={this.state.handleName}
                                                userModel={this.state.userModel}
                                                privacyType={this.state.privacyType}
                                                isPreview={this.state.isPreview}
                                                coverHash={this.state.coverHash}
                                                profileHash={this.state.profileHash}
                                                userId={this.state.userId}
                                                isPostItemsHidden={this.state.isPostItemsHidden}
                                                handleOpenPostModal={this.handleOpenPostModal}
                                                handleRedirectMyPage={this.handleRedirectMyPage} 
                                            />
                                        )
                                    ) 
                                 : !this.state.isPostFetching ? <center><p>{MSG_NO_POST_YET}</p></center> : null : null }
                               
                                <ConfirmationModal
                                    isModalShow={this.state.isPostModalShow}
                                    confirmationText={"プレビューモードを終了しますか？"}
                                    handleConfirmAction={()=>this.handleRedirectPost()}
                                    handleCloseModal={this.handleCloseModal}
                                />
                            </div>
                            <center>
                                <ActivityIndicator
                                    style={{
                                        marginBottom: 20,
                                        height: 30,
                                        display: this.state.isPostFetching ? "block" : "none"
                                    }}
                                />
                            </center>
                            <ErrorModal
                                {...this.state}
                                handleCloseModal={this.handleCloseErrorModal}
                                errorMessage={this.state.errorMessage}
                            />
                        </>
                </React.Fragment>
        )
     }
}


const mapStateToProps = state => {
    return{
        userId: state.auth.credentials.userId,
        handleName: state.editprofile.profileDetails.handleName,
        userInformation: state.editprofile.profileDetails,
    }
}

const mapDispatchToProps = dispatch => 
    bindActionCreators(
        {
            getUserBasicInformation: mypageOperations.getUserBasicInformation,
            getRandomFriends: mypageOperations.getRandomFriends,
            getUserPosts: mypageOperations.getUserPosts,
            uploadImageChange: mypageOperations.uploadImageChange,
            getUserProfile: profileOperations.getUserProfile
        },
        dispatch
    )

export default connect(mapStateToProps, mapDispatchToProps)(MyPagePreview);
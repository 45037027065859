import React, { Component } from 'react';
import { Image, Card, Row, Form, Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DefaultIcon from '../../../includes/img/placeholders/user.svg';
import AddImageIcon from '../../../includes/img/buttons/add-image.png';
import AddVideoIcon from '../../../includes/img/buttons/add-video.png';
import AddVimeoIcon from '../../../includes/img/buttons/add-vimeo.svg';
import TextareaAutosize from 'react-autosize-textarea';
import { TouchableOpacity } from 'react-native';
import './styles/details.scss';
import styles from './styles/details.native.js';
import { POST_TYPES, MODE, POST_MAX_IMAGE, ATTACHMENT_TYPE, UPLOAD_STATUS, CLOUDFRONT_THUMBNAIL_PREFIX_URL, ACCEPTED_IMAGE_TYPES, ACCEPTED_VIDEO_TYPES, WEBSOCKET_MESSAGES } from '../../../utils/constants';
import Select from 'react-select';
import { privacySelect } from './state/templates';
import { isStringNullEmptyOrUndefined, isFumiyaOrFFStaffUserCheck, singleFilter, onApiSuccess, appendUserRoute, isObjectEmpty, isArrayNullOrEmpty, uploadToS3, getObjKeyIdString, formatStartDateTime, formatEndDateTime, getObjDateValue, addDefaultSrc, objKeyExist, defaultTopScroll  } from '../../../utils/common';
import SendWhiteIcon from '../../../includes/img/icons/send-white.svg';
import { Media } from 'react-breakpoints'
import { isMobile } from "react-device-detect";
import { isNullOrUndefined } from '../../../../node_modules/util';
import RemoveIcon from "../../../includes/img/icons/close.svg";
import { VIMEO_PLAYER_URL, MAX_USER_ATTACHMENT, S3_URL_BUCKET, MAX_IMAGE_FILE_SIZE } from '../../../config/settings';
import ConfirmationModal from '../../components/ConfirmationModal';
import UploadingPlaceholder from '../../../includes/img/placeholders/uploading.png';
import FailedPlaceholder from '../../../includes/img/placeholders/failed-grey.png';
import MenuIcon from '../../../includes/img/icons/dot-menu.svg';
import { MenuItem } from '../../components/MenuItem';
import { postSelectors, postOperations } from './state';
import ErrorModal from '../../components/ErrorModal';
import { MSG_MAX_POST_PHOTO_EXCEEDED, MSG_TOTAL_SIZE_REACHED, MSG_INVALID_IMAGE_SIZE, SYSTEM_ERROR, MSG_INVALID_VIDEO, MSG_INVALID_VIMEO_ID } from '../../../utils/messages';
import WithLoaderIndicator from '../../components/WithLoaderIndicator';
import User404 from '../Errors/User404';
import ProcessLoader from '../../components/ProcessLoader';
import { Beforeunload } from 'react-beforeunload';
import DiscardChangesModal from '../../components/DiscardChangesModal';
import { authOperations } from "../Authentication/state";
import moment from 'moment';
import debounce from 'lodash/debounce';
import uuid from 'react-uuid'
import { isNumeric } from '../../components/Validations/CommonSelectors';
import ProcessLoaderForLoading from '../../components/ProcessLoaderForLoading'; // Whiete Out fix
import { isSessionExpired} from "../../../utils/common"; // Whiete Out fix

const CustomToggle = React.forwardRef(({ onClick }, ref) => (
    <a
        href="_blank"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        <img alt="menu-icon" src={MenuIcon} className="custom-toggle-icon" />
    </a>
));

const DateInput = ({ value, name, year, label, min, max, onChange, mobileView }) => {
    let inputStyle = {
        maxWidth: year ? (mobileView ? 60 : 70) : 42, 
        fontSize: 14
    }

    return (
        <React.Fragment>
            <Form.Control
                className="user-form-control"
                style={inputStyle}
                type="text"
                min={min}
                max={max}
                name={name}
                value={value}
                onChange={onChange}
                step={1}
            />
            <Form.Label className={mobileView ? "livestream-date-mobile" : "livestream-date"}>{label}</Form.Label>
        </React.Fragment>
    )
}

class ViewPost extends Component {
    constructor(props) {
        super(props)
        this.state = {
            type: 0,
            postId: null,
            postBody: "",
            privacy: privacySelect[0],
            photoUpload: [],
            videoUpload: null,
            vimeoId: "",
            vimeoUrl: "",
            videoUrl: "",
            videoStatus: false,
            userAttachmentSize: 0,
            mode: MODE.add,
            title: "",
            source: null,
            attachmentType: 0,
            photos: [],
            removedUpload: [],
            vimeoLivestreamId: null,
            isProcessingForLoading: false, //White Out fix
            startYear: "",
            startMonth: "",
            startDay: "",
            startHour: "",
            startMinute: "",
            endYear: "",
            endMonth: "",
            endDay: "",
            endHour: "",
            endMinute: "",

            startYearPrev: "",
            startMonthPrev: "",
            startDayPrev: "",
            startHourPrev: "",
            startMinutePrev: "",
            endYearPrev: "",
            endMonthPrev: "",
            endDayPrev: "",
            endHourPrev: "",
            endMinutePrev: "",

            errors: [],
            showVimeoModal: false,
            msgErrorModal: "",
            showErrorModal: false,
            showReuploadModal: false,
            isPostLoading: true,
            isAccessInvalid: false,
            isProcessing: false,
            reuploadUrl: "",
            reuploadFile: null,
            reuploadAttachmentType: "",
            hasUpdates: false,
            confirmDiscard: false,
            showDiscardModal: false,
            previousPath: "",
            profileHash: Date.now(),
            isInvalidVimeoId: false,
            customDiscardCancel: null,
            refId: uuid()
        }

        this.imageUploadRef = React.createRef();
        this.videoUploadRef = React.createRef();

        this.imageReuploadRef = React.createRef();
        this.videoReuploadRef = React.createRef();

        debounce(this.handleInputChange, 250)

    }

    componentDidMount() {
        let id = this.props.match.params.postId;
        let locationState = this.props.location.state;
        let livestreamType = locationState && POST_TYPES.livestream === locationState.type;
        let previousPath = (locationState && objKeyExist(locationState, "previousPath")) ? locationState.previousPath : "";
        let redirectImages = (locationState && objKeyExist(locationState, "preloadImages")) ? Array.from(locationState.preloadImages) : [];
        let hasUpdates = 0 < redirectImages.length;
        let preloadedImages = redirectImages.filter(i=> MAX_IMAGE_FILE_SIZE >= i.size && 0 <= ACCEPTED_IMAGE_TYPES.indexOf(i.type));
        let preErrors = {};
        
        if(preloadedImages.length !== redirectImages.length){
            preErrors["photoUpload"] = MSG_INVALID_IMAGE_SIZE;
        }

        if (0 <= this.props.location.pathname.indexOf("livestream")) {
            //Create Livestream Form
            if(isFumiyaOrFFStaffUserCheck(this.props.user.userId)){
                this.setState({ previousPath, isPostLoading: false, mode: MODE.add, type: POST_TYPES.livestream })
            }else{
                //Invalid access for non Fumiya/FFStaff
                this.setState({isAccessInvalid: true})
            }
        } else if (livestreamType) {
            //Update Livestream Form
            this.setState({ previousPath, mode: MODE.edit, type: POST_TYPES.livestream })
            this.getPost(id);
        } else if (undefined === id) {
            this.setState({ previousPath, isPostLoading: false, photoUpload: preloadedImages, hasUpdates, errors: preErrors });
        } else {
            this.setState({ previousPath, mode: MODE.edit, photoUpload: preloadedImages, hasUpdates })
            this.getPost(id);
        }

        /**Sockets */
        if (!isNullOrUndefined(this.props.websocket) && this.props.websocket.readyState === WebSocket.OPEN) {
            this.initWSHandlers()
        }
        /***/

        document.addEventListener("visibilitychange", this.backGroundCare); //White Out fix

        defaultTopScroll();
    }

    componentWillUnmount() {
        document.removeEventListener("visibilitychange", this.backGroundCare); //White Out fix
    }

    componentDidUpdate(){
        this.initWSHandlers()
    }

    /** SOCKETS for thumbnails*/
    initWSHandlers() {
        this.props.websocket.onmessage = (data) => {
            let serverData = JSON.parse(data.data);
            let responseData = JSON.parse(serverData.data)

            if (WEBSOCKET_MESSAGES.onNotifications === responseData.action) {
                this.props.incrementNotifCount(responseData.referenceId)
            }else if (WEBSOCKET_MESSAGES.onChatNotification === responseData.action) {
                this.props.incrementChatThreadCount(responseData.senderId, responseData.threadId)
            }else if (WEBSOCKET_MESSAGES.onPostImageRender === responseData.action) {
                if (getObjKeyIdString(responseData.postId) === this.state.postId) {
                    let filteredPhotos = Object.assign([], this.state.photos);
                    let updatePhoto = filteredPhotos.filter(i => i.imageUrl === responseData.imageUrl)

                    //Existing photo on current view
                    if (0 < updatePhoto.length) {
                        updatePhoto[0].imageStatus = responseData.imageStatus
                    }

                    //Update state
                    this.setState({photos: filteredPhotos})
                }
            }else if(WEBSOCKET_MESSAGES.onPostVideoRender === responseData.action){
                if (getObjKeyIdString(responseData.postId) === this.state.postId) {
                    //Update state
                    this.setState({videoStatus: responseData.videoStatus, videoUrl: responseData.videoUrl})
                }
            }
        }

        let isExistError = false; //added 20220720 White Out fix (caused by reconnection loop)
        this.props.websocket.onerror = () => { isExistError = true } //mod 20220617 White Out fix (caused by reconnection loop) Start.
        this.props.websocket.onclose = () => {
            let isScreenBackGround = localStorage.getItem("isScreenBackGround");
            if(isScreenBackGround == null){
                if(!isExistError) this.socketReconnect();
            } else { //If screen in smartPhone run to background, do not reconnect here as reconnection will be done with the exclusive method
                localStorage.setItem("isScreenBackGround", null);
            }
        } 
    }
     
    backGroundCare = () =>{
        if (document.visibilityState === "hidden"){ //When screen run to background
            localStorage.setItem("isScreenBackGround", 1);
            this.props.websocket.close(); //Forcibly close websocket
            localStorage.setItem("isWebsocktOpen", 0);
            this.setState({ isProcessingForLoading: true }); // Prepare to show Processing modal when screen returned from background
        }
        if (document.visibilityState === "visible"){ //When screen returned from background
            if (isSessionExpired()) this.props.logoutUser(); 
                this.props.connect();
                const interval = setInterval(() => {
                    if (localStorage.getItem("isWebsocktOpen") == 1) {
                        this.setState({ isProcessingForLoading: false }); // Stop Processing modal
                        clearInterval(interval);
                    }
                  }, 50);
        } 
    }//added 20220720 White Out fix End

    socketReconnect = () => {
        this.props.websocket.close()
        this.props.connect();
        setTimeout(() => {
            this.initWSHandlers()
        }, 1000);
    }
    /**END OF SOCKETS */


    getPost = (postId) => {
        this.props.getPost(this.props.user.userId, postId)
            .then((res) => {
                if (onApiSuccess(res)) {
                    let postData = JSON.parse(res.data.data);
                    postData.privacy = privacySelect.filter(i => i.value === postData.privacy)[0];

                    
                    if(POST_TYPES.livestream === postData.type){
                        //Format date strings
                        const startDate = moment(getObjDateValue(postData.startLivestreamDt));
                        postData.startYear = startDate.isValid() ? startDate.format("YYYY") : '';
                        postData.startMonth = startDate.isValid() ? startDate.format("MM") : '';
                        postData.startDay = startDate.isValid() ? startDate.format("DD") : '';
                        postData.startHour = startDate.isValid() ? startDate.format("HH") : '';
                        postData.startMinute = startDate.isValid() ? startDate.format("mm") : '';

                        postData.startYearPrev = startDate.isValid() ? startDate.format("YYYY") : '';
                        postData.startMonthPrev = startDate.isValid() ? startDate.format("MM") : '';
                        postData.startDayPrev = startDate.isValid() ? startDate.format("DD") : '';
                        postData.startHourPrev = startDate.isValid() ? startDate.format("HH") : '';
                        postData.startMinutePrev = startDate.isValid() ? startDate.format("mm") : '';

                        const endDate = moment(getObjDateValue(postData.endLivestreamDt));
                        postData.endYear = endDate.isValid() ? endDate.format("YYYY") : '';
                        postData.endMonth = endDate.isValid() ? endDate.format("MM") : '';
                        postData.endDay = endDate.isValid() ? endDate.format("DD") : '';
                        postData.endHour = endDate.isValid() ? endDate.format("HH") : '';
                        postData.endMinute = endDate.isValid() ? endDate.format("mm") : '';

                        postData.endYearPrev = endDate.isValid() ? endDate.format("YYYY") : '';
                        postData.endMonthPrev = endDate.isValid() ? endDate.format("MM") : '';
                        postData.endDayPrev = endDate.isValid() ? endDate.format("DD") : '';
                        postData.endHourPrev = endDate.isValid() ? endDate.format("HH") : '';
                        postData.endMinutePrev = endDate.isValid() ? endDate.format("mm") : '';
                    }

                    this.setState({
                        postId,
                        ...postData,
                        isPostLoading: false
                    });
                } else {
                    this.setState({ isAccessInvalid: true })
                }
            })
            .catch(() => this.showSystemError())
    }

    toggleVimeoModal = () => {
        this.setState(prevState => ({ showVimeoModal: !prevState.showVimeoModal, vimeoId: "" }));
    }

    toggleErrorModal = () => {
        this.setState(prevState => ({ showErrorModal: !prevState.showErrorModal }));
    }

    validateLivestreamId = () =>{
        return this.props.checkVimeoId(this.state.vimeoLivestreamId)
        .then(res => {
            let vimeoData = res.data;
            if(objKeyExist(vimeoData, "type") && "live" === vimeoData["type"]){
                return true;
            }else{
                return false;
            }
        })
        .catch(()=>{
            return false;
        })
    }

    handleSubmit = () => {
        if (POST_TYPES.livestream === this.state.type) {
            let formErrors = postSelectors.validateLivestreamForm(this.state, this.state.errors);
            
            if (isObjectEmpty(formErrors)) {
                this.setState({ isProcessing: true }, () => {
                    this.validateLivestreamId()
                    .then((isValid)=>{
                        if(isValid){
                            if (MODE.edit === this.state.mode) {
                                this.handleEditLivestream();
                            } else {
                                this.handleCreateLivestream()
                            }
                        }else{
                            this.setState({ errors: {"vimeoId": MSG_INVALID_VIMEO_ID}, isProcessing: false })
                            defaultTopScroll()
                        }
                    })
                })
            } else {
                this.setState({ errors: formErrors })
                defaultTopScroll()
            }
        }else{
            let formErrors = postSelectors.validateNormalForm(this.state, this.state.errors);
            if (isObjectEmpty(formErrors) && !this.isFormEmpty()) {
                if (MODE.edit === this.state.mode) {
                    this.handleEditPost();
                } else {
                    this.handleCreatePost()
                }
            } else {
                this.setState({ errors: formErrors })
                defaultTopScroll()
            }
        }

    }

    setNormalPostParams = () => {

        let attachmentTypeUpdate = this.state.attachmentType;
        let attachmentCount = 0;
        let attachmentSize = 0;
        let imageFilenames = [];
        let newVideo = false;

        if (!isStringNullEmptyOrUndefined(this.state.vimeoUrl)) {
            attachmentTypeUpdate = ATTACHMENT_TYPE.video;
        } else if (!isNullOrUndefined(this.state.videoUpload)) {
            newVideo = true
            attachmentTypeUpdate = ATTACHMENT_TYPE.video;
            attachmentCount = 1;
            attachmentSize = this.state.videoUpload.size;
        } else if (!isArrayNullOrEmpty(this.state.photoUpload)) {
            attachmentTypeUpdate = ATTACHMENT_TYPE.image
            attachmentCount = this.state.photoUpload.length;
            imageFilenames = this.state.photoUpload.map(i => {
                attachmentSize += i.size;
                return i.name;
            })
        }

        if (MODE.edit === this.state.mode) {
            //Check old attachment type if update type none on current flow
            if (ATTACHMENT_TYPE.none === attachmentTypeUpdate) {
                if (!isArrayNullOrEmpty(this.state.photos)) {
                    attachmentTypeUpdate = ATTACHMENT_TYPE.image
                } else if (!isStringNullEmptyOrUndefined(this.state.videoUrl)) {
                    attachmentTypeUpdate = ATTACHMENT_TYPE.video
                }
            }
        }

        return {
            attachmentCount,
            imageFilenames,
            newVideo,
            attachmentSize,
            attachmentType: attachmentTypeUpdate,
            type: this.state.type,
            userId: this.props.user.userId,
            postId: this.state.postId,
            postBody: this.state.postBody,
            privacy: this.state.privacy.value,
            photoUpload: this.state.photoUpload,
            videoUpload: this.state.photoUpload,
            vimeoId: this.state.vimeoId,
            vimeoUrl: this.state.vimeoUrl,
            videoUrl: this.state.videoUrl,
            refId: this.state.refId,
            source: 0,
            removedUpload: this.state.removedUpload,
            author: {
                userId: this.props.user.userId,
                handleName: this.props.userDetails.handleName,
                profileImageUrl: this.props.user.profileImageUrl,
                isUserDeleted: this.props.user.isUserDeleted
            }
        }
    }

    handleCreatePost = () => {
        this.setState({ isProcessing: true }, () => {
            let params = this.setNormalPostParams();
            this.props.createPost(params)
                .then(res => {
                    if (onApiSuccess(res)) {
                        //Check for presigned urls if attachmentType 1 or 2
                        let responseData = JSON.parse(res.data.data);
                        let promiseGroup = [];

                        //Set to state for discard scenarios
                        this.setState({postId: getObjKeyIdString(responseData.post_id)})

                        if (ATTACHMENT_TYPE.video === params.attachmentType) {
                            if (0 < responseData.presignedUrls.length) {
                                promiseGroup.push(
                                    uploadToS3(responseData.presignedUrls[0], this.state.videoUpload)
                                )
                            }
                        } else if (ATTACHMENT_TYPE.image === params.attachmentType) {
                            responseData.presignedUrls.map((item, index) => {
                                promiseGroup.push(
                                    uploadToS3(item, this.state.photoUpload[index])
                                )
                                return null;
                            })
                        }

                        Promise.all(promiseGroup).then((pRes) => {
                            this.setState({hasUpdates: false}, ()=>{
                                let redirectMyPage = () =>{
                                    this.props.history.push({
                                        pathname: appendUserRoute("/mypage"),
                                        state: {
                                            newPost:{
                                                _id: responseData.post_id,
                                                ...params
                                            }
                                        }
                                    });
                                }
                                let checkDiscardModal = () =>{
                                    let discardElement = document.getElementById("discardModal");
                                    
                                    if(discardElement){
                                        //Set the Cancel button of discard to redirect
                                        this.setState({customDiscardCancel: ()=> redirectMyPage()})
                                    }else{
                                        redirectMyPage()
                                    }
                                }
                                checkDiscardModal();
                                
                                
                            })
                        })
                    } else {
                        this.setState({
                            isProcessing: false,
                            msgErrorModal: res.data.message,
                            showErrorModal: true
                        })
                    }
                })
                .catch(() => this.showSystemError())
        })
    }

    showSystemError = () => {
        this.setState({
            isProcessing: false,
            msgErrorModal: SYSTEM_ERROR,
            showErrorModal: true
        })
    }

    handleEditPost = () => {
        this.setState({ isProcessing: true }, () => {
            let params = this.setNormalPostParams();
            this.props.editPost(params)
                .then(res => {
                    if (onApiSuccess(res)) {
                        let responseData = JSON.parse(res.data.data);
                        let promiseGroup = [];

                        if (ATTACHMENT_TYPE.video === params.attachmentType) {
                            if (0 < responseData.presignedUrls.length) {
                                promiseGroup.push(
                                    uploadToS3(responseData.presignedUrls[0], this.state.videoUpload)
                                )
                            }
                        } else if (ATTACHMENT_TYPE.image === params.attachmentType) {
                            responseData.presignedUrls.map((item, index) => {
                                promiseGroup.push(
                                    uploadToS3(item, this.state.photoUpload[index])
                                )
                                return null;
                            })
                        }

                        Promise.all(promiseGroup).then((pRes) => {
                            this.setState({hasUpdates: false}, ()=>{
                                //Redirect based on initiator page access (Default to post/view)
                                if(isStringNullEmptyOrUndefined(this.state.previousPath)){
                                    this.props.history.push(appendUserRoute(`/post/view/${this.state.postId}`))
                                }else{
                                    this.props.history.push(this.state.previousPath)
                                }
                            })
                        })
                    } else {
                        //Error response side effect
                        this.setState({
                            isProcessing: false,
                            msgErrorModal: res.data.message,
                            showErrorModal: true
                        })
                    }
                })
                .catch(() => this.showSystemError())
        })
    }

    setLivestreamParams = () =>{
        let startLivestreamDt = formatStartDateTime(this.state);
        let endLivestreamDt = formatEndDateTime(this.state);
        return {
            author: {
                userId: this.props.user.userId,
                handleName: this.props.userDetails.handleName,
                profileImageUrl: this.props.user.profileImageUrl,
                isUserDeleted: this.props.user.isUserDeleted
            },
            userId: this.props.user.userId,
            postId: this.state.postId,
            postBody: this.state.postBody,
            vimeoLivestreamId: this.state.vimeoLivestreamId,
            startLivestreamDt,
            endLivestreamDt,
        }
    }

    handleCreateLivestream = () => {
        let params = this.setLivestreamParams();
        this.props.createLivestream(params)
            .then(res => {
                if (onApiSuccess(res)) {
                    //Check for presigned urls if attachmentType 1 or 2
                    this.setState({hasUpdates: false}, ()=>{
                        this.props.history.push({
                            pathname: appendUserRoute("/mypage")
                        });
                    })
                } else {
                    this.setState({
                        isProcessing: false,
                        msgErrorModal: res.data.message,
                        showErrorModal: true
                    })
                }
            })
            .catch(() => this.showSystemError())
    }

    handleEditLivestream = () => {
        let params = this.setLivestreamParams();
        this.props.editLivestream(params)
            .then(res => {
                if (onApiSuccess(res)) {
                    this.setState({hasUpdates: false}, ()=>{
                        //Redirect based on initiator page access (Default to post/view)
                        if(isStringNullEmptyOrUndefined(this.state.previousPath)){
                            this.props.history.push(appendUserRoute(`/post/view/${this.state.postId}`))
                        }else{
                            this.props.history.push(this.state.previousPath)
                        }
                    })
                } else {
                    //Error response side effect
                    this.setState({
                        isProcessing: false,
                        msgErrorModal: res.data.message,
                        showErrorModal: true
                    })
                }
            })
            .catch(() => this.showSystemError())
    }

    handleAddPhoto = (e) => {
        let newPhotos = e.target.files;
        let newTotalAttachmentSize = this.state.userAttachmentSize;
        let hasInvalidImageSize = false;
        let newErrors = Object.assign({}, this.state.errors);

        [...newPhotos].map(item => {
            newTotalAttachmentSize += item.size
            if (MAX_IMAGE_FILE_SIZE < item.size || 0 > ACCEPTED_IMAGE_TYPES.indexOf(item.type)) {
                hasInvalidImageSize = true;
            }

            return item;
        });

        if (hasInvalidImageSize) {
            newErrors["photoUpload"] = MSG_INVALID_IMAGE_SIZE;
            delete newErrors["postRequired"]
            delete newErrors["videoUpload"]
            this.setState({ errors: newErrors })
            defaultTopScroll();
        } else if (MAX_USER_ATTACHMENT < newTotalAttachmentSize) {
            newErrors["photoUpload"] = MSG_TOTAL_SIZE_REACHED;
            delete newErrors["postRequired"]
            delete newErrors["videoUpload"]
            this.setState({ errors: newErrors })
            defaultTopScroll();
        } else if (POST_MAX_IMAGE < newPhotos.length + this.state.photoUpload.length + this.state.photos.length) {
            this.setState({
                msgErrorModal: MSG_MAX_POST_PHOTO_EXCEEDED,
                showErrorModal: true,
                errors: newErrors
            })
            defaultTopScroll();
        } else {
            delete newErrors["photoUpload"]
            delete newErrors["postRequired"]
            delete newErrors["videoUpload"]
            this.setState(prevState => ({
                photoUpload: [...prevState.photoUpload, ...newPhotos],
                userAttachmentSize: newTotalAttachmentSize,
                errors: newErrors,
                hasUpdates: true
            }))
        }
    }

    handleRemovePhoto = (img) => {
        let filteredPhoto = this.state.photoUpload.filter(item => item !== img);
        this.setState(prevState => ({ photoUpload: filteredPhoto, hasUpdates: true }));
    }

    handleAddVideo = (e) => {
        let newVideo = e.target.files[0];
        let newErrors = Object.assign({}, this.state.errors);

        if(newVideo){
            if (0 > ACCEPTED_VIDEO_TYPES.indexOf(newVideo.type)) {
                newErrors["videoUpload"] = MSG_INVALID_VIDEO;
                delete newErrors["photoUpload"]
                delete newErrors["postRequired"]
                this.setState({ errors: newErrors })
            } else {
                delete newErrors["photoUpload"]
                delete newErrors["postRequired"]
                delete newErrors["videoUpload"]
                this.setState({ videoUpload: newVideo, hasUpdates: true, errors: newErrors })
            }
        }
    }

    handleAddVimeo = () => {
        this.setState({isProcessing: true}, ()=>{
            this.props.checkVimeoId(this.state.vimeoId)
            .then(res => {
                let vimeoData = res.data;
                if(objKeyExist(vimeoData, "status") && "available" === vimeoData["status"]){
                    this.setState(prevState => ({
                        vimeoUrl: `/video/${prevState.vimeoId}`,
                        vimeoId: "",
                        showVimeoModal: false,
                        hasUpdates: true,
                        isInvalidVimeoId: false,
                        isProcessing: false
                    }))
                }else{
                    this.setState({isInvalidVimeoId: true, isProcessing: false})
                }
            })
            .catch(()=>this.setState({isInvalidVimeoId: true, isProcessing: false}))
        })
    }

    handleRemoveVideo = () => {
        this.setState({
            videoUpload: null,
            vimeoUrl: "",
            hasUpdates: true
        })
    }

    handleRemoveAttached = (url) => {
        if (ATTACHMENT_TYPE.video === this.state.attachmentType) {
            this.setState(prevState => ({
                removedUpload: [...prevState.removedUpload, { attachmentType: prevState.attachmentType, url: url }],
                videoUrl: "",
                videoStatus: null,
                attachmentType: ATTACHMENT_TYPE.none,
                hasUpdates: true
            }))
        } else if (ATTACHMENT_TYPE.image === this.state.attachmentType) {
            let filteredPhoto = this.state.photos.filter(item => item.imageUrl.toString() !== url.toString());
            this.setState(prevState => ({
                removedUpload: [...prevState.removedUpload, { attachmentType: prevState.attachmentType, url: url }],
                photos: filteredPhoto,
                hasUpdates: true
            }))
        }
    }

    handleInputChange = (e) => {
        let newError = postSelectors.validateTextField(e, this.state.errors);
        this.setState({ 
            [e.target.name]: e.target.value, 
            errors: newError, 
            hasUpdates: true, 
            isInvalidVimeoId: "vimeoId" === e.target.name ? false : this.state.isInvalidVimeoId
        })
    }

    handleNumberChange = (e) => {
        let parsedValue = parseInt(e.target.value);
        if ("" === e.target.value || (isNumeric(e.target.value) && parsedValue >= parseInt(e.target.min) && parsedValue <= parseInt(e.target.max))) {
            this.setState({ [e.target.name]: e.target.value })
        }
    }

    handleSelectChange = (selected) => {
        this.setState({ privacy: selected, hasUpdates: true })
    }

    handleReuploadImage = (e) => {
        let newPhoto = e.target.files[0];
        //Check file limit (Display on modal since process/checking is different than main edit form)
        if(MAX_IMAGE_FILE_SIZE < newPhoto.size || 0 > ACCEPTED_IMAGE_TYPES.indexOf(newPhoto.type)) {
            this.setState({
                msgErrorModal: MSG_INVALID_IMAGE_SIZE,
                showErrorModal: true
            })
        }else{
            this.setState({
                reuploadFile: e.target.files[0],
                reuploadAttachmentType: ATTACHMENT_TYPE.image,
                showReuploadModal: true,
                hasUpdates: true
            })
        }
    }

    handleReuploadVideo = (e, videoUrl) => {
        this.setState({
            reuploadUrl: videoUrl,
            reuploadFile: e.target.files[0],
            reuploadAttachmentType: ATTACHMENT_TYPE.video,
            showReuploadModal: true,
            hasUpdates: true
        })
    }

    handleReuploadSubmit = () => {
        let params = {
            post_id: this.state.postId,
            source_url: this.state.reuploadUrl,
            attachment_type: this.state.reuploadAttachmentType,
            user_id: this.props.user.userId
        }
        this.setState({ isProcessing: true }, () => {
            this.props.requestReupload(params)
                .then(res => {
                    if (onApiSuccess(res)) {
                        let responseData = JSON.parse(res.data.data);
                        if (responseData.presignedUrl) {
                            Promise.resolve(uploadToS3(responseData.presignedUrl, this.state.reuploadFile))
                                .then(pRes => {
                                    let filteredPhotos = Object.assign([], this.state.photos);
                                    if(ATTACHMENT_TYPE.image === this.state.reuploadAttachmentType){
                                        let updatePhoto = filteredPhotos.filter(i => i.imageUrl === this.state.reuploadUrl)

                                        if (0 < updatePhoto.length) {
                                            updatePhoto[0].imageStatus = UPLOAD_STATUS.uploading;
                                        }
                                    }

                                    this.setState({
                                        isProcessing: false,
                                        reuploadFile: null,
                                        reuploadAttachmentType: null,
                                        showReuploadModal: false,
                                        videoStatus: UPLOAD_STATUS.uploading,
                                        photos: filteredPhotos
                                    })
                                })
                                .catch(() => this.showSystemError())
                        } else {
                            this.showSystemError()
                        }
                    } else {
                        this.showSystemError()
                    }
                })
                .catch(() => this.showSystemError())
        });
    }

    toggleReuploadModal = () => {
        this.setState(prevState => ({ showReuploadModal: !prevState.showReuploadModal }))
    }

    renderAttachments = () => {
        if (ATTACHMENT_TYPE.video === this.state.attachmentType) {
            let attachMenu = [
                { item: { label: "写真を削除", icon: require('../../../includes/img/icons/delete-white.svg') }, onPress: () => this.handleRemoveAttached(this.state.videoUrl) },
            ]
            if (UPLOAD_STATUS.failed === this.state.videoStatus) {
                attachMenu.unshift(
                    { item: { label: "アップロード", icon: require('../../../includes/img/icons/upload-white.svg') }, onPress: () => this.videoReuploadRef.current.click() },
                )
            }
            return (
                <div className="post-attachment-video">
                    {UPLOAD_STATUS.failed === this.state.videoStatus ? (
                        <Image key={"failed-video"} src={FailedPlaceholder} className="post-attachment" alt="post-attachment" />
                    ) : (
                            <iframe
                                title={this.state.videoUrl}
                                src={`${VIMEO_PLAYER_URL + this.state.videoUrl}?title=0&byline=0&portrait=0&loop=1&autopause=1`}
                                frameBorder="0"
                                allow="autoplay; fullscreen"
                                allowFullScreen
                                className="video-content"
                            >
                            </iframe>
                        )}

                    {/**Hidden input for video reupload*/}
                    <input
                        accept={ACCEPTED_VIDEO_TYPES.join(",")}
                        ref={this.videoReuploadRef}
                        type="file"
                        id="videoReuploadFile"
                        onChange={(e) => this.handleReuploadVideo(e, this.state.videoUrl)}
                        style={{ display: "none" }}
                    />

                    <Dropdown className="attachment-menu">
                        <Dropdown.Toggle as={CustomToggle} id="attachment-dropdown-menu" />
                        <Dropdown.Menu alignRight className="attachment-dropdown">
                            {attachMenu.map((menu, i) => (
                                <MenuItem
                                    key={"attach-vid-menu" + i}
                                    action={true}
                                    item={menu.item}
                                    onPress={menu.onPress}
                                    titleStyle={{ color: "#fff", marginLeft: 12 }}
                                />
                            )
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            )
        } else if (ATTACHMENT_TYPE.image === this.state.attachmentType) {
            return this.state.photos.map((item, i) => {
                switch (item.imageStatus) {
                    case UPLOAD_STATUS.uploading: return this.renderPhoto(UploadingPlaceholder, item, i);
                    case UPLOAD_STATUS.success: return this.renderPhoto(CLOUDFRONT_THUMBNAIL_PREFIX_URL + item.imageUrl + "-large", item, i);
                    case UPLOAD_STATUS.failed: return this.renderPhoto(FailedPlaceholder, item, i);
                    default: return this.renderPhoto(item.imageUrl);
                }
            });
        }
    }

    renderPhoto = (uri, item, index) => {
        let attachMenu = [
            { item: { label: "写真を削除", icon: require('../../../includes/img/icons/delete-white.svg') }, onPress: () => this.handleRemoveAttached(item.imageUrl) },
        ]
        if (UPLOAD_STATUS.failed === item.imageStatus) {
            attachMenu.unshift(
                { item: { label: "アップロード", icon: require('../../../includes/img/icons/upload-white.svg') }, onPress: () => {this.setState({reuploadUrl: item.imageUrl}, ()=>this.imageReuploadRef.current.click())}},
            )
        }

        return (
            <div key={"attach-image-" + index} className="position-relative">
                <Image key={"i" + index} src={uri} className="post-attachment" alt="post-image" />

                {/**Hidden input for image reupload*/}
                <input
                    key={item.imageUrl + index}
                    accept={ACCEPTED_IMAGE_TYPES.join(",")}
                    ref={this.imageReuploadRef}
                    type="file"
                    id="imageReuploadFile"
                    onChange={(e) => this.handleReuploadImage(e)}
                    style={{ display: "none" }}
                />

                <Dropdown className="attachment-menu">
                    <Dropdown.Toggle as={CustomToggle} id="attachment-dropdown-menu" />
                    <Dropdown.Menu alignRight className="attachment-dropdown">
                        {attachMenu.map((menu, i) => (
                            <MenuItem
                                key={"attach-menu" + i}
                                action={true}
                                item={menu.item}
                                onPress={menu.onPress}
                                titleStyle={{ color: "#fff", marginLeft: 12 }}
                            />
                        )
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        )
    }

    renderLivestreamForm = (mobileView) => {
        return (
            <React.Fragment>
                <div className="livestream-area">
                    <Form.Label className="livestream-label">Vimeo動画ID</Form.Label>
                    <Form.Control value={this.state.vimeoLivestreamId} className="user-form-control" type="text" maxLength={50} name="vimeoLivestreamId" onChange={this.handleInputChange} />

                    <Form.Label className="livestream-label">ログイン後トップ画面最上部表示時間</Form.Label>
                    <Form.Group as={Row} className="align-items-center ml-0 mb-3">
                        <DateInput mobileView={mobileView} value={this.state.startYear} label="年" name="startYear" min={1} max={9999} year={true} onChange={this.handleNumberChange} />
                        <DateInput mobileView={mobileView} value={this.state.startMonth} label="月" name="startMonth" min={0} max={12} onChange={this.handleNumberChange} />
                        <DateInput mobileView={mobileView} value={this.state.startDay} label="日" name="startDay" min={0} max={31} onChange={this.handleNumberChange} />
                        <DateInput mobileView={mobileView} value={this.state.startHour} label="時" name="startHour" min={0} max={23} onChange={this.handleNumberChange} />
                        <DateInput mobileView={mobileView} value={this.state.startMinute} label="分 から" name="startMinute" min={0} max={59} onChange={this.handleNumberChange} />
                    </Form.Group>

                    <Form.Group as={Row} className="align-items-center ml-0">
                        <DateInput mobileView={mobileView} value={this.state.endYear} label="年" name="endYear" min={1} max={9999} year={true} onChange={this.handleNumberChange} />
                        <DateInput mobileView={mobileView} value={this.state.endMonth} label="月" name="endMonth" min={0} max={12} onChange={this.handleNumberChange} />
                        <DateInput mobileView={mobileView} value={this.state.endDay} label="日" name="endDay" min={0} max={31} onChange={this.handleNumberChange} />
                        <DateInput mobileView={mobileView} value={this.state.endHour} label="時" name="endHour" min={0} max={23} onChange={this.handleNumberChange} />
                        <DateInput mobileView={mobileView} value={this.state.endMinute} label="分 まで" name="endMinute" min={0} max={59} onChange={this.handleNumberChange} />
                    </Form.Group>

                    <p className="warning-text">*この指定時間内は、ライブ配信中か否かにかかわらず、常にログイン後トップ画⾯の最上部に表⽰されます。</p>
                </div>
            </React.Fragment>
        )
    }

    renderNormalForm = (mobileView) => {
        return (
            <React.Fragment>
                {this.renderAttachmentActions()}
                <Row className={mobileView ? "mobile-privacy-select ml-0" : "pc-privacy-select"}>
                    <span>公開レベル</span>
                    <Select
                        value={this.state.privacy}
                        onChange={this.handleSelectChange}
                        options={privacySelect}
                        className={mobileView ? "mobile-privacy-input" : "pc-privacy-input"}
                        defaultValue={singleFilter(privacySelect, true)}
                    />
                </Row>

                {/* Vimeo ID Modal */}
                <ConfirmationModal
                    isModalShow={this.state.showVimeoModal}
                    confirmationText={"Vimeo動画ID"}
                    confirmTitle={"追加"}
                    customContent={
                        <React.Fragment>
                            <p className="error align-self-center">
                                {this.state.isInvalidVimeoId && MSG_INVALID_VIMEO_ID}
                            </p>
                            <Form.Control
                                type="text"
                                name="vimeoId"
                                autoComplete="off"
                                value={this.state.vimeoId}
                                className="vimeo-modal-input"
                                onChange={this.handleInputChange}
                                maxLength={50}
                            />
                        </React.Fragment>
                    }
                    handleConfirmAction={this.handleAddVimeo}
                    handleCloseModal={this.toggleVimeoModal}
                />
            </React.Fragment>
        )
    }

    isVideoEmpty() {
        return isStringNullEmptyOrUndefined(this.state.videoUrl)
            && isNullOrUndefined(this.state.videoUpload)
            && isStringNullEmptyOrUndefined(this.state.vimeoUrl);
    }

    isNewVideoEmpty() {
        return isNullOrUndefined(this.state.videoUpload)
            && isStringNullEmptyOrUndefined(this.state.vimeoUrl);
    }

    renderAttachmentActions = () => {
        return (
            <Row className="post-actions-row">
                {(this.isVideoEmpty()) && (
                    <React.Fragment>
                        {this.state.photoUpload.map((item, i) => {
                            return (
                                //Image upload preview
                                <span key={"img-upload-" + i} className="position-relative">
                                    <Image src={URL.createObjectURL(item)} className="post-add-image" />
                                    <TouchableOpacity
                                        style={styles.removeIcon}
                                        onPress={() => this.handleRemovePhoto(item)}
                                    >
                                        <Image src={RemoveIcon} alt="remove-icon" className={"remove-icon"} />
                                    </TouchableOpacity>
                                </span>
                            )
                        })}

                        <input
                            multiple
                            accept={ACCEPTED_IMAGE_TYPES.join(",")}
                            ref={this.imageUploadRef}
                            type="file"
                            id="imageUploadFile"
                            onChange={this.handleAddPhoto}
                            style={{ display: "none" }}
                        />

                        <TouchableOpacity activeOpacity={0.7} onPress={() => this.imageUploadRef.current.click()}>
                            <Image src={AddImageIcon} className="post-add-buttons" />
                        </TouchableOpacity>
                    </React.Fragment>
                )}

                {!this.isVideoEmpty() ? (
                    !this.isNewVideoEmpty() && (
                        <span className="position-relative">
                            {isStringNullEmptyOrUndefined(this.state.videoUpload) ? (
                                //Vimeo link preview
                                <iframe
                                    title={this.state.vimeoUrl}
                                    src={`${VIMEO_PLAYER_URL + this.state.vimeoUrl}?controls=0`}
                                    className="post-add-image"
                                />
                            ) : (
                                    //Video upload preview
                                    <video className="post-add-image" autoPlay loop muted playsInline onLoadedData={(event)=>{
                                        event.target.pause();
                                    }} >
                                        <source src={URL.createObjectURL(this.state.videoUpload)} />
                                    </video>
                                )}
                            <TouchableOpacity
                                style={styles.removeIcon}
                                onPress={() => this.handleRemoveVideo()}
                            >
                                <Image src={RemoveIcon} alt="remove-icon" className={"remove-icon"} />
                            </TouchableOpacity>
                        </span>
                    )
                ) : (isFumiyaOrFFStaffUserCheck(this.props.user.userId) && 0 === this.state.photoUpload.length && 0 === this.state.photos.length) && (
                    <React.Fragment>
                        <input
                            accept={ACCEPTED_VIDEO_TYPES.join(",")}
                            ref={this.videoUploadRef}
                            type="file"
                            id="videoUploadFile"
                            onChange={this.handleAddVideo}
                            style={{ display: "none" }}
                        />
                        <TouchableOpacity activeOpacity={0.7} onPress={() => this.videoUploadRef.current.click()}>
                            <Image src={AddVideoIcon} className="post-add-buttons" />
                        </TouchableOpacity>

                        <TouchableOpacity activeOpacity={0.7} onPress={() => this.toggleVimeoModal()}>
                            <Image src={AddVimeoIcon} className="post-add-buttons" />
                        </TouchableOpacity>
                    </React.Fragment>
                )}

            </Row>
        )
    }

    isFormEmpty = () => {
        return isStringNullEmptyOrUndefined(this.state.postBody)
            && 0 >= this.state.photoUpload.length
            && 0 >= this.state.photos.length
            && isNullOrUndefined(this.state.videoUpload)
            && isStringNullEmptyOrUndefined(this.state.vimeoUrl)
            && isStringNullEmptyOrUndefined(this.state.videoUrl);
    }

    renderErrors = () => {
        return (
            <div className="mt-2 ml-0 mr-0 mb-0 p-0">
                {Object.values(this.state.errors).map((err, i) => {
                    return <p key={"err" + i} className="error mb-0 flex-column">{err}</p>
                })}
            </div>
        )
    }

    deleteOnDiscard = () =>{
        //Delete newly created post if discarded
        if(MODE.add === this.state.mode){
            if(isStringNullEmptyOrUndefined(this.state.postId)){
                this.setState({discarded: true})
                // Delete by postBody
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        resolve(this.props.deletePost("onCreate", this.state.refId));
                    }, 1000)
                  })
            }else{
                //Delete by ID
                return this.props.deletePost(this.state.postId)
            }
        }else{
            return Promise.resolve();
        }
    }

    render() {
        let { user, userDetails } = this.props;
        let isLivestream = POST_TYPES.livestream === this.state.type;
        let isProfileImageUrlValid = (user.profileImageUrl && 0 <= user.profileImageUrl.indexOf("Profile/"));

        if (this.state.isAccessInvalid) {
            return <User404 />
        }

        return (
            /** On before page leave*/
            <Beforeunload onBeforeunload={(event) => this.state.hasUpdates ? event.preventDefault() : {}} >
                <Media>
                    {({ breakpoints, currentBreakpoint }) => {
                        let mobileView = (breakpoints[currentBreakpoint] < breakpoints.mobileLandscape) || isMobile;
                        return (
                            <React.Fragment>
                                {/**Process Loading indicator*/}
                                <ProcessLoader
                                    isShown={this.state.isProcessing}
                                />

                                <ProcessLoaderForLoading
                                    isShown={this.state.isProcessingForLoading}
                                />

                                {/** On before route leave*/}
                                <DiscardChangesModal 
                                    id={"discardModal"}
                                    active={this.state.hasUpdates}
                                    discardCallback={this.deleteOnDiscard}
                                    customDiscardCancel={this.state.customDiscardCancel}
                                />

                                <Card className="mb-4" style={{ minHeight: mobileView ? "89.5vh" : "auto" }}>
                                    <Card.Body className="p-0">
                                        <div className="pad-container">
                                            <Row className="d-flex align-items-center m-0 p-0">
                                                <Image onError={addDefaultSrc} src={isProfileImageUrlValid ? `${S3_URL_BUCKET}/Thumbnail-${user.profileImageUrl}-small?${this.state.profileHash}` : DefaultIcon} className="post-profile-icon" roundedCircle />
                                                <span className="post-author-name">{userDetails.handleName}</span>
                                            </Row>

                                            {this.renderErrors()}

                                            <WithLoaderIndicator isLoading={this.state.isPostLoading}>
                                                <Row className="m-0 p-0">
                                                    <TextareaAutosize
                                                        name="postBody"
                                                        autoFocus={true}
                                                        className="post-body"
                                                        rows={5}
                                                        value={this.state.postBody}
                                                        onChange={this.handleInputChange}
                                                        placeholder={this.state.type === POST_TYPES.livestream ? "ライブ動画の説明を追加" : "日記を投稿しよう"}
                                                    />
                                                </Row>
                                            </WithLoaderIndicator>
                                        </div>

                                        {this.renderAttachments()}

                                        <WithLoaderIndicator
                                            isLoading={this.state.isPostLoading}
                                            hideLoader={true}
                                        >
                                            <div className="pad-container">
                                                <Row className="m-0 p-0">
                                                    {isLivestream ? this.renderLivestreamForm(mobileView) : this.renderNormalForm(mobileView)}
                                                </Row>

                                                <Row className="d-flex justify-content-center m-0 p-0">
                                                    <button
                                                        className={mobileView ? "mobile-save-button" : "pc-save-button"}
                                                        onClick={this.handleSubmit}
                                                    >
                                                        {isLivestream ? (
                                                            <span>{"投稿"}</span>
                                                        ) : (
                                                                <React.Fragment>
                                                                    <span>{"日記を投稿する"}</span>
                                                                    <span className="save-icon">
                                                                        <Image
                                                                            alt="action-icon"
                                                                            src={SendWhiteIcon}
                                                                        />
                                                                    </span>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    </button>
                                                </Row>
                                            </div>
                                        </WithLoaderIndicator>
                                    </Card.Body>
                                </Card>

                                {/* Attachment Reupload Modal */}
                                {this.state.showReuploadModal && (
                                    <ConfirmationModal
                                        isModalShow={true}
                                        confirmationText={"写真を再アップロードすると、\n既存の写真は上書きされます。\nよろしいですか？"}
                                        confirmTitle={"はい"}
                                        handleConfirmAction={this.handleReuploadSubmit}
                                        handleCloseModal={this.toggleReuploadModal}
                                    />
                                )}

                                {this.state.showErrorModal && (
                                    <ErrorModal
                                        errorMessage={this.state.msgErrorModal}
                                        isErrorModalShow={true}
                                        handleCloseModal={this.toggleErrorModal}
                                    />
                                )}

                            </React.Fragment>
                        );
                    }}
                </Media>
            </Beforeunload>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.auth.credentials,
        websocket: state.auth.websocket,
        userDetails: state.editprofile.profileDetails
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getPost: postOperations.getPost,
        createPost: postOperations.createPost,
        editPost: postOperations.editPost,
        deletePost: postOperations.deletePost,
        requestReupload: postOperations.requestReupload,
        connect: authOperations.connectWS,
        createLivestream: postOperations.createLivestream,
        editLivestream: postOperations.editLivestream,
        incrementNotifCount: authOperations.incrementNotifCount,
        incrementChatThreadCount: authOperations.incrementChatThreadCount,
        checkVimeoId: postOperations.checkVimeoId,
        logoutUser: authOperations.logoutUser //White out fix
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(ViewPost);
import React from "react";
import { View, KeyboardAvoidingView, Text, TouchableOpacity, Image } from "react-native";
import SendIcon from "../../../includes/img/icons/send.svg";
import GalleryIcon from "../../../includes/img/icons/image.svg";
import styles from "./styles/chatconversation.styles.native";
import { isNullOrUndefined } from "util";
import { EMPTY_STRING, CHAT_MAX_CHAR } from "../../../utils/constants";
import './styles/chat.scss'
import TextareaAutosize from 'react-autosize-textarea';
import { Media } from 'react-breakpoints'
import { isMobile } from "react-device-detect";

const SendChatComponent = (props) => {
  function CustomError(column) {
    return !isNullOrUndefined(props.errors[column]) ? <span className="chat-form-error">{props.errors[column]}</span> : EMPTY_STRING;
  }
  let isDisabled = ((( EMPTY_STRING === props.message || props.isWhiteSpace || props.isTextSending) && (0 === props.imageFiles.length || props.isMessageSending)));
  return (
    <Media>
        {({ breakpoints, currentBreakpoint }) => {
          let mobileView = breakpoints[currentBreakpoint] < breakpoints.mobileLandscape || isMobile;
          
          return(
            <View style={styles.sendChatContainer}>
            <KeyboardAvoidingView style={styles.inputContainer}>
              <TouchableOpacity onPress={() => props.handleUpdateAddImage()}>
                <Image source={GalleryIcon} style={styles.icon} />
              </TouchableOpacity>
              <div className="chat-textarea-container"> 
                  <TextareaAutosize
                    value={props.message}
                    className={`form-control chat-textarea`}
                    maxLength={CHAT_MAX_CHAR}
                    onChange={(e)=>props.handleInputChange(e.target.value)}
                    placeholder={"メッセージ"}
                    maxRows={mobileView ? 3 : 5}
                 />
                 <Text style={mobileView ? styles.errorMessage : styles.errorMessagePC }>{CustomError("image") || CustomError("message")}</Text>
              </div>
              <TouchableOpacity 
                onPress={() => props.handleSendPress()}
                disabled={isDisabled}>
                <Image source={SendIcon} style={isDisabled ? styles.disabledIcon : styles.icon} />
              </TouchableOpacity>
            </KeyboardAvoidingView>
            </View>
          )
        }}
    </Media>
  );
};

export default SendChatComponent;

import * as errMsg from "../../../../utils/messages"
import { isThereDoubleByte, isCorrectEmail, isAlphanumeric } from '../../../components/Validations/CommonSelectors';
import { isStringNullEmptyOrUndefined } from '../../../../utils/common';

export const checkContactForm = (contactForm) => {
    let errArray = {};

    /**FFID */
    if('undefined' === typeof(contactForm.ffid) || isStringNullEmptyOrUndefined(contactForm.ffid) || isThereDoubleByte(contactForm.ffid)) {
        errArray["ffid"] = errMsg.MSG_INVALID_FFID;
    }

    /**EMAIL */
    if('undefined' === typeof(contactForm.email) || isStringNullEmptyOrUndefined(contactForm.email)){
        errArray["email"] = errMsg.MSG_EMPTY_EMAIL;
    }else if(isThereDoubleByte(contactForm.email)){
        errArray["email"] = errMsg.MSG_DOUBLE_BYTE_EMAIL;
    }else if(!isCorrectEmail(contactForm.email)){
        errArray["email"] = errMsg.MSG_INVALID_EMAIL;
    }

    /**PASSWORD */
    if('undefined' === typeof(contactForm.password) || isStringNullEmptyOrUndefined(contactForm.password)){
        errArray["password"] = errMsg.MSG_EMPTY_PASSWORD;
    }else if(!isAlphanumeric(contactForm.password)){
        errArray["password"] = errMsg.MSG_INVALID_PASSWORD;
    }

    /**NAME */
    if('undefined' === typeof(contactForm.name) || isStringNullEmptyOrUndefined(contactForm.name)){
        errArray["name"] = errMsg.MSG_EMPTY_NAME;
    }

    /**CONTENT */
    if('undefined' === typeof(contactForm.content) || isStringNullEmptyOrUndefined(contactForm.content)){
        errArray["content"] = errMsg.MSG_EMPTY_CONTENT;
    }

    return errArray
}
import reducer from "./reducers";
import { routes } from "../routes";
import * as chatOperations from "./operations";
import * as selectors from "./selectors";

export { chatOperations, selectors };

export default {
  reducer,
  routes
};

import {StyleSheet} from "react-native"

const styles = StyleSheet.create({
    editProfileBtn: {
        backgroundColor: "#DCDCDC",
        margin: "auto",
        width: "90%",
        borderRadius: 10,
        marginBottom: 20,
        height: 35
    },
    editProfileBtnPC: {
        backgroundColor: "#DCDCDC",
        margin: "auto",
        width: "60%",
        borderRadius: 5,
        marginBottom: 20,
        height: 40
    },
    btnTitleStylePC: {
        color: "#666666",
        fontSize: "16px",
        fontFamily: "kozuka-gothic-pro, sans-serif"
    },
    btnTitleStyle: {
        color: "#666666",
        fontSize: "14px",
        fontFamily: "kozuka-gothic-pro, sans-serif"
    },
    friendsCount: {
        fontSize: 16
    },
    displayFriendsBtn: {
        backgroundColor: "#0A182C",
        margin: "auto",
        width: "90%",
        borderRadius: 10,
        marginBottom: 20,
        height: 35
    },
    displayFriendsBtnPC: {
        backgroundColor: "#0A182C",
        margin: "auto",
        width: "60%",
        borderRadius: 5,
        marginBottom: 20,
        height: 40
    },
    friendsBtnTitlePC: {
        textDecorationStyle: "none",
        color: "white",
        fontSize: "16px",
        fontFamily: "kozuka-gothic-pro, sans-serif"
    },
    friendsBtnTitle: {
        textDecorationStyle: "none",
        color: "white",
        fontSize: "14px",
        fontFamily: "kozuka-gothic-pro, sans-serif"
    },
    disabled:{
        opacity: 0.6
    }
})

export default styles;
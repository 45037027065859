import * as Path from './apiRoutes'
import ApiService from "../../../../utils/apiService"
import { WEBSOCKET_ACTIONS } from "../../../../utils/constants";


const checkIfBlocked = (userId, friendId) => (dispatch) => {
    return ApiService.get(Path.CHECK_IF_BLOCKED + `/${userId}/${friendId}`)
    .then((response)=>{
        return response
    })
    .catch((error)=>{
        console.log(error)
    })
}

const getUserBasicInformation = (userId, friendId) => (dispatch) => {
    return ApiService.get(Path.GET_FRIENDPAGE_INFORMATION + `/${userId}/${friendId}`)
    .then((response)=>{
        return response
    })
    .catch((error)=>{
        console.log(error)
    })
}

const getRandomFriends = (userId) => (dispatch) => {
    return ApiService.get(Path.GET_FRIENDPAGE_FRIENDS + `/${userId}`)
    .then((response)=>{
        return response
    })
}

const getUserPosts = (userId, friendId, currentPagination) => (dispatch) => {
    return ApiService.get(Path.GET_FRIENDPAGE_POSTS + `/${userId}/${friendId}/${currentPagination}`)
    .then((response)=>{
        return response
    })
}

const sendFriendRequest = (params, ws) => () => {
    ws.send(
        JSON.stringify({
            action: WEBSOCKET_ACTIONS.onSendRequest,
            type: WEBSOCKET_ACTIONS.onSendRequest,
            data: {
                ...params
            },
        })
    );
}

const acceptFriendRequest = (params, ws) => () => {
    ws.send(
        JSON.stringify({
            action: WEBSOCKET_ACTIONS.onAcceptRequest,
            type: WEBSOCKET_ACTIONS.onAcceptRequest,
            data: {
                ...params
            },
        })
    );
}

const blockUser = (userId, blockId) => () => {
    const params = {
        userId: userId,
        blockId: blockId
    }
    return ApiService.post(Path.DO_BLOCK_USER, params)
    .then((response)=>{
        return response
    })
}

const unblockUser = (userId, blockId) => () => {
    const params = {
        userId: userId,
        blockId: blockId
    }
    return ApiService.post(Path.DO_UNBLOCK_USER, params)
    .then((response)=>{
        return response
    })
}

const removeFriend = (userId, blockId) => () => {
    const params = {
        userId: userId,
        blockId: blockId
    }
    return ApiService.post(Path.DO_REMOVE_FRIEND, params)
    .then((response)=>{
        return response
    })
}

export {
    checkIfBlocked,
    getUserBasicInformation,
    getRandomFriends,
    getUserPosts,
    blockUser,
    unblockUser,
    removeFriend,
    sendFriendRequest,
    acceptFriendRequest
}
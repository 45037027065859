import { StyleSheet } from "react-native";
import { Dimensions } from 'react-native';

const windowHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
  headerContainer: {
    height: 55,
    width: "100%",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    borderBottomColor: "#dcdcdc",
    borderBottomWidth: 1,
    backgroundColor: "#f4f4f4"
  },
  headerContainerPC: {
    height: 55,
    width: "100%",
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: 10,
    borderBottomColor: "#dcdcdc",
    borderBottomWidth: 1,
    backgroundColor: "#f4f4f4",
    borderRadius: 'inherit'
  },
  icon: {
    height: 30,
    width: 30,
    marginTop: 1,
    marginLeft: 5
  },
  mainContainer: {
    justifyContent: "center",
    flex: 1,
  },
  imageContainer: {
    flex: 1,
    flexDirection: "column",
    margin: 1
  },
  instagramContainerPC: {
    height: "80vh"
  },
  instagramContainerMobile: {
    height: windowHeight - 148,
  },
  flatlistContainer : {
    flex: 1,
    paddingBottom: 3
  }
});
export default styles;

import { NOTIFICATION_TITLE_MAX_LENGTH } from '../../../../utils/constants';


export const notificationsTable = {
    tableHeaders: [
        "日付",
        "タイトル",
        "表示/非表示",
        "公開開始日",
        "公開終了日",
        " "
    ],
    tableColumns: [
        { key: "createViewDt", center: true },
        { key: "title", colored: true, link: true },
        { key: "isDisplayed", colored: true, center: true },
        { key: "startDate", center: true },
        { key: "endDate", center: true },
    ]
}


export const notificationCreateForm = {
    model: "NotificationModel",
    form: [
        {
            type: "top-error-message",
            label: "",
            name: "topErrorMessage",
            default: ''
        },
        {
            type: "multiple-text",
            label: "日付",
            name: "createdDate",
            children: [
                {
                    type: "custom-text-date",
                    label: "年",
                    name: "createdYear",
                    width: 115,
                    min: 1,
                    max: 9999,
                    default: ''
                },
                {
                    type: "custom-text-date",
                    label: "月",
                    name: "createdMonth",
                    width: 95,
                    min: 1,
                    max: 12,
                    default: ''
                },
                {
                    type: "custom-text-date",
                    label: "日",
                    name: "createdDay",
                    width: 95,
                    min: 1,
                    max: 31,
                    default: ''
                },
            ]
        },
        {
            type: "text",
            label: "タイトル",
            name: "title",
            maxLength: NOTIFICATION_TITLE_MAX_LENGTH, 
            default: ''
        },
        {
            type: "textarea",
            label: "本文",
            name: "body",
            rows: "10",
            default: '',
            startLabel: true,
        },
        {
            type: "radio",
            label: "表示/非表示",
            name: "isDisplayed",
            inline: true,
            default: ''
        },
        {
            type: "multiple-text",
            label: "公開開始日",
            name: "startDate",
            children: [
                {
                    type: "custom-text-date",
                    label: "年",
                    name: "startYear",
                    width: 115,
                    min: 1,
                    max: 9999,
                    default: '',
                    notifDisplayDependent: true
                },
                {
                    type: "custom-text-date",
                    label: "月",
                    name: "startMonth",
                    width: 95,
                    min: 1,
                    max: 12,
                    default: '',
                    notifDisplayDependent: true
                },
                {
                    type: "custom-text-date",
                    label: "日",
                    name: "startDay",
                    width: 95,
                    min: 1,
                    max: 31,
                    default: '',
                    notifDisplayDependent: true
                },
            ]
        },
        {
            type: "multiple-text",
            label: "公開終了日",
            name: "endDate",
            children: [
                {
                    type: "custom-text-date",
                    label: "年",
                    name: "endYear",
                    width: 115,
                    min: 1,
                    max: 9999,
                    default: '',
                    notifDisplayDependent: true
                },
                {
                    type: "custom-text-date",
                    label: "月",
                    name: "endMonth",
                    width: 95,
                    min: 1,
                    max: 12,
                    default: '',
                    notifDisplayDependent: true
                },
                {
                    type: "custom-text-date",
                    label: "日",
                    name: "endDay",
                    width: 95,
                    min: 1,
                    max: 31,
                    default: '',
                    notifDisplayDependent: true
                },
            ]
        },
    ]
}

export const notificationUpdateForm = {
    model: "NotificationUpdateModel",
    form: [
        {
            type: "values",
            label: "",
            name: "confirmDeleteMsg",
            default: ''
        },
        {
            type: "values",
            label: "日付",
            name: "createdDate",
            default: ''
        },
        {
            type: "values",
            label: "タイトル",
            name: "title",
            value: '',
            default: ''
        },
        {
            type: "values",
            label: "本文",
            name: "body",
            default: '',
            startLabel: true,
        },
        {
            type: "values",
            label: "表示/非表示",
            name: "isDisplayed",
            inline: true,
            default: ''
        },
        {
            type: "values",
            label: "公開開始日",
            name: "startDate",
            value: '',
            default: ''
        },
        {
            type: "values",
            label: "公開終了日",
            name: "endDate",
            default: '',
            startLabel: true,
        },
    ]
}

export const radioInput = [
    { name: "isDisplayed", value: 1, label: "表示" },
    { name: "isDisplayed", value: 0, label: "非表示" }
];
import React from 'react';
import ContentLoader from 'react-content-loader'

const RowSkeleton = (
    <ContentLoader
        speed={2}
        width={'100%'}
        height={100}
        
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >

        <rect x="20" y="8" width="1000" height="8" rx="3" />
        <rect x="20" y="23" width="1000" height="8" rx="3" />
        <rect x="20" y="38" width="1000" height="8" rx="3" />
        <rect x="20" y="53" width="1000" height="8" rx="3" />
    </ContentLoader>
);

const CommunityDetailsSkeleton = () => {
    return (
        <div className="pt-3 pr-3 pl-1">
            {RowSkeleton}
        </div>
    );
};

export default CommunityDetailsSkeleton;
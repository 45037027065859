import React, { Component } from 'react';
import { communityOperations } from './state';
import { View } from "react-native";
import { bindActionCreators } from "redux";
import { EMPTY_STRING } from "../../../utils/constants";
import UserListItemComponent from "../../components/UserListItemComponent";
import ErrorModal from "../../components/ErrorModal";
import { MSG_SYSTEM_ERROR } from "../../../utils/messages";
import { onApiSuccess, appendUserRoute, getObjKeyIdString, defaultTopScroll } from '../../../utils/common';
import { connect } from "react-redux";
import "./styles/styles.scss"
import CommunityListSkeleton from "../../components/Skeletons/CommunityListSkeleton.js";
import { Media } from 'react-breakpoints'
import styles from "./styles/community.styles.native"

class PastCommunities extends Component {
    constructor(props) {
        super(props);
        this.state = {
          currentPagination: 1,
          communityList: [],
          isErrorModalShow: false,
          errorMessage: EMPTY_STRING,
          isLoading: true
        };
    }

    componentDidMount(){
        defaultTopScroll();
        this.props.getAllPastCommunities(this.state.currentPagination).then(response=>{
            if(onApiSuccess(response)){
                let data = JSON.parse(response.data.data)
                this.setState({
                    communityList : data,
                    isLoading: false
                })
            }else{
                this.setState({
                    isErrorModalShow: true,
                    errorMessage: MSG_SYSTEM_ERROR
                })
            }
        })
    }


    handleSelectCommunity = (item) => {
        let communityId = getObjKeyIdString(item._id)
        let communityRoute = appendUserRoute(`/communities/${communityId}`)
        this.props.history.push({
            pathname: communityRoute,
            state: {
              communityId: communityId,
              name: item.name,
              communityImageUrl: item.communityImageUrl
            }
        });
    }

    handlePagination = () => {
        this.setState({
            currentPagination: this.state.currentPagination + 1
        }, ()=>{
            this.props.getAllPastCommunities(this.state.currentPagination).then(response=>{
                if(onApiSuccess(response)){
                    let data = JSON.parse(response.data.data)
                    this.setState(prevState => ({
                        communityList : [...prevState.communityList, ...data]
                    }))
                }else{
                    this.setState({
                        isErrorModalShow: true,
                        errorMessage: MSG_SYSTEM_ERROR
                    })
                }
            })
        })
    }

    handleCloseErrorModal = () => {
        this.setState({
          isErrorModalShow : false
        })
    }
    

    render() {
        return (
            <Media>
            {({ breakpoints, currentBreakpoint }) => {
                return(
                    <div className="community-container">
                    <div className="community-header">
                      <p className="community-header-text">{"過去のオフィシャルコミュニティ"}</p>
                    </div>
                    <View>
                        {
                            !this.state.isLoading ? 
                                <View style={styles.flatlistContainer}>
                                    <UserListItemComponent
                                    {...this.state}
                                    list={this.state.communityList}
                                    isCommunity={true}
                                    handlePagination={this.handlePagination}
                                    handleSelectItem={this.handleSelectCommunity}
                                    /> 
                                </View>
                            : <CommunityListSkeleton/>
                        }
                    </View>
            
                    <ErrorModal
                      {...this.state}
                      handleCloseModal={this.handleCloseErrorModal}
                      customContent={null}
                      errorMessage={this.state.errorMessage}
                    />
                  </div>
                )
            }}
            </Media>
    );
}
}


const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getAllPastCommunities: communityOperations.getAllPastCommunities        
        },
          dispatch
    );
      
export default connect(null, mapDispatchToProps)(PastCommunities);

export const SET_MEMBER_AUTHENTICATED = "user/SET_MEMBER_AUTHENTICATED";
export const SET_USER_AUTH = "user/SET_USER_AUTH";
export const SET_WS_CONNECTION = "user/SET_WS_CONNECTION";
export const RESET_USER = "user/RESET_USER";
export const UPDATE_NOTIF_COUNT = "user/UPDATE_NOTIF_COUNT";
export const UPDATE_CHAT_THREAD_COUNT = "user/UPDATE_CHAT_THREAD_COUNT";
export const INCREMENT_NOTIF_COUNT = "user/INCREMENT_NOTIF_COUNT";
export const INCREMENT_CHAT_THREAD_COUNT = "user/INCREMENT_CHAT_THREAD_COUNT";
export const DECREMENT_NOTIF_COUNT = "user/DECREMENT_NOTIF_COUNT";
export const CLEAR_NEW_NOTIF_REFERENCES = "user/CLEAR_NEW_NOTIF_REFERENCES";
export const SET_MAINTENANCE = "user/SET_MAINTENANCE";
export const REFRESH_TOKENS = "user/REFRESH_TOKENS";
export const SET_PROFILE_IMAGE = "user/SET_PROFILE_IMAGE";
export const SET_HANDLE_NAME = "user/SET_HANDLE_NAME";
export const SET_ADMIN_BACKDOOR_CHECK = "user/SET_ADMIN_BACKDOOR_CHECK";
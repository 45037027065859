import reducer from "./reducers";
import { routes } from "../routes";

import * as notificationOperations from './operations';

export {
    notificationOperations
}

export default {
    reducer,
    routes
};

import { REGEX_NUMERIC } from "../../../../utils/constants";

export const contactFormInputs = [
    {
        type: "text",
        label: "会員番号6桁*",
        name: "ffid",
        placeholder: "半角入力",
        default: "",
        maxLength: 6,
        regex: REGEX_NUMERIC
    },
    {
        type: "text",
        label: "会員サイト登録メールアドレス*",
        name: "email",
        placeholder: "半角入力",
        default: "",
        maxLength: 272
    },
    {
        type: "password",
        label: "会員サイトパスワード*",
        name: "password",
        placeholder: "半角入力",
        default: "",
        maxLength: 80
    },
    {
        type: "text",
        label: "氏名*",
        name: "name",
        placeholder: "全角入力",
        default: "",
        maxLength: 272
    },
    {
        type: "textarea",
        label: "お問い合わせ内容*",
        name: "content",
        rows: 8,
        placeholder: "どのような状態になってしまうのか等、詳しくお書き添えください。",
        default: "",
    },
]
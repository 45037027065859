import React from 'react';
import { APP_ROOT_ROUTE } from "../views/containers/UserMain/routes";
import { ADMIN_ROOT_ROUTE } from "../views/containers/AdminMain/routes";
import { FUMIYA_USER_ID, FF_STAFF_USER_ID } from "../config/settings";
import ApiService from "./apiService";
import DefaultIcon from '../includes/img/placeholders/user.svg';
import CoverIcon from '../includes/img/placeholders/cover.svg';
import { API_SUCCESS_STATUS, MAX_TEXT_LINES } from "./constants";
import  axios from "axios"
import configureStore from '../app.modules';
import Auth from '@aws-amplify/auth';
import awsSettings from "../config/awsSettings";
import HTMLParse from 'html-react-parser';
import Truncate from 'react-truncate';

export const initialAccountUpdated = () =>{
    const profile = configureStore().getState().editprofile.profileDetails;
    
    let updated = false;

    /**Check if handlename has value on data */
    if(objKeyExist(profile, "handleName")){
        if(!isStringNullEmptyOrUndefined(profile.handleName)){
            updated = true;
        }
    }
    return updated;
}

export const verifiedUserAuthentication = () =>{
    const userAuthenticated = configureStore().getState().auth.userAuthenticated;
    const credentials = configureStore().getState().auth.credentials;
    
    return userAuthenticated && objKeyExist(credentials, "signInUserSession") && objKeyExist(credentials, "username") && objKeyExist(credentials, "userDataKey")
}

export const appendUserRoute = (path) => {
    return APP_ROOT_ROUTE + path;
}

export const appendAdminRoute = (path) => {
    return ADMIN_ROOT_ROUTE + path;
}

export const isStringNullEmptyOrUndefined = (value) => {
    return undefined === value || null === value || '' === value.toString().trim() || null === value.toString().trim();
}

export const isEmptyDateString = (year, month, day) => {
    return (isStringNullEmptyOrUndefined(year) && isStringNullEmptyOrUndefined(month) 
            && isStringNullEmptyOrUndefined(day)) ? true : false;
}

export const isNullOrUndefined = (value) => {
    return undefined === value || null === value;
}

export const parseISOString = (s) => {
    const b = s.split(/\D+/);
    return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
}

export const sortDateByKey = (list, key, order) => {
    switch (order) {
        case 'desc': return list.sort((a, b) => new Date(b[key]) - new Date(a[key]));
        case 'asc':
        default: return list.sort((a, b) => new Date(b[key]) - new Date(a[key]));
    }
}

export const emptyTextValue = (value) => {
    if ('string' === typeof value) {
        return 0 >= value.trim().length;
    } else {
        return false;
    }
}

export const singleFilter = (list, value, key="value") =>{
    return list.filter(item=>value === item[key])[0];
}

export const isFumiyaUserCheck = (userId) => {
    return FUMIYA_USER_ID === userId;
}

export const objKeyExist = (currObj, key) => {
    try{
        return currObj.hasOwnProperty(key);
    }catch (e){
        console.log("Object error", e)
        return false;
    }
    
}

export const isObjectEmpty = (currObj) => {
    return 0 === Object.keys(currObj).length;
}


export const isBottomScrollReached = () => {
    let rootElement = document.getElementById('root');
    return rootElement.getBoundingClientRect().bottom - 0.5 <= window.innerHeight;
}

export const isScrollRefetchReached = () => {
    //Refetch data if 1500 offset from bottom is reached
    let scrollRefetchOffset = 1500;
    let rootElement = document.getElementById('root');
    return rootElement.getBoundingClientRect().bottom - scrollRefetchOffset <= window.innerHeight;
}

export const getObjKeyIdString = (objectId) =>{
    return objectId ? objectId["$oid"].toString() : "";
}

export const getObjDateValue = (dateObject) =>{
    return dateObject ? dateObject["$date"] : "";
}

export const isFumiyaOrFFStaffUserCheck = (userId) => {
    return FUMIYA_USER_ID === userId || FF_STAFF_USER_ID === userId;
}

export const isArrayNullOrEmpty = (arr) =>{
    return isNullOrUndefined(arr) || 0 >= arr.length;
}

export const fetchEmptyScroll = () =>{
    return window.scrollBy({
        top: -100,
        left: 0,
        behavior: 'smooth'
    })
}

export const onApiSuccess = (response) => {
    try{
        if(response.data){
            return !isNullOrUndefined(response.data.status) && API_SUCCESS_STATUS.toLowerCase() === response.data.status.toLowerCase()
        }else{
            return false
        }
    }catch{
        return false
    }
}

export const prependZero = (value) => {
    if (null === value) {
        return value;
    }
    return value < 10 && value.toString().length < 2 && 0 !== strToInt(value) ? `0${value}` : `${value}`;
}

export const strToInt = (value) => {
    return !isNaN(parseInt(value)) && !isStringNullEmptyOrUndefined(value.toString) ? parseInt(value) : null;
}

export const formatDeliveryDate = (value) => {
    return ('全員' === value.type || strToInt(value.type)) ? (`${value.deliveryYear}/${prependZero(value.deliveryMonth)}/${prependZero(value.deliveryDay)} ${prependZero(value.deliveryHour)}:${prependZero(value.deliveryMinute)}`) : (strToInt(value.deliveryMonth) ? `${prependZero(value.deliveryYear)}/${prependZero(value.deliveryMonth)}` : `${prependZero(value.deliveryYear)}`);
}

export const formatYYYMMDD = (year, month, day) => {
    return `${year}/${prependZero(month)}/${prependZero(day)}`;
}

export const formatJpDate = (value) => {
    return '全員' === value.type || strToInt(value.type) ? (`${value.deliveryYear}年${prependZero(value.deliveryMonth)}月${prependZero(value.deliveryDay)}日 ${prependZero(value.deliveryHour)}時${prependZero(value.deliveryMinute)}分`) : (strToInt(value.deliveryMonth) ? `${prependZero(value.deliveryYear)}年${prependZero(value.deliveryMonth)}月` : `${prependZero(value.deliveryYear)}年`);
}

export const formatStartDateTime = (value) => {
    return (`${value.startYear}/${prependZero(value.startMonth)}/${prependZero(value.startDay)} ${prependZero(value.startHour)}:${prependZero(value.startMinute)}`);
}

export const formatEndDateTime = (value) => {
    return (`${value.endYear}/${prependZero(value.endMonth)}/${prependZero(value.endDay)} ${prependZero(value.endHour)}:${prependZero(value.endMinute)}`);
}

export const formatPrevStartDateTime = (value) => {
    return (`${value.startYearPrev}/${prependZero(value.startMonthPrev)}/${prependZero(value.startDayPrev)} ${prependZero(value.startHourPrev)}:${prependZero(value.startMinutePrev)}`);
}

export const formatPrevEndDateTime = (value) => {
    return (`${value.endYearPrev}/${prependZero(value.endMonthPrev)}/${prependZero(value.endDayPrev)} ${prependZero(value.endHourPrev)}:${prependZero(value.endMinutePrev)}`);
}

export const formatDeliveryType = (value) => {
    return strToInt(value.type) ? '全員' : 'ランダム';
}

export const formatDeliveryStatus = (value) => {
    return value.deliveryStatus ? '配信する' : '配信しない';
}

export const timestampToYYYMMDD = (value) => {
    let date = new Date(value);
    const ddmmyyyy = date.toLocaleDateString();
    date = ddmmyyyy.replace(/(\d+)\/(\d+)\/(\d+)/g, "$3/$1/$2");
    const updatedDate = new Date(date);
    const year = prependZero(updatedDate.getFullYear());
    const month = prependZero(updatedDate.getMonth() + 1);
    const day = prependZero(updatedDate.getDate());
    return `${year}/${month}/${day}`;
}

export const isFFStaffUserCheck = (userId) => {
    return FF_STAFF_USER_ID === userId;
}

export const uploadToS3 = (preSignedURL, imageFile) =>{
    let params = new FormData()
    Object.keys(preSignedURL.fields).forEach((key)=> {
        params.append(key, preSignedURL.fields[key])
    });
    params.append("file", imageFile)
    params.append("Content-Type", imageFile.type)
    return axios.post(preSignedURL.url, params)
    .then(response => {
       return response;
    })
    .catch(error => {
        return error.message
    });
};

export const adminUploadToS3 = (preSignedURL, imageFile) => {
    let params = new FormData();
    Object.keys(preSignedURL.fields).forEach((key) => {
        params.append(key, preSignedURL.fields[key]);
    });
    params.append("file", imageFile);
    params.append("Content-Type", imageFile.type);

    return axios.post(preSignedURL.url,  params, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }
    ).then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
};

export const checkImageURL = (url) => {
    return ApiService.get(url)
    .then(response => {
      if(response.statusError === "404"){
          return false
      }else{
          return true
      }
    })
    .catch(error => {
      console.error(error);
    });
}

export const addDefaultSrc = (ev) =>{
    return ev.target.src = DefaultIcon
}

export const addDefaultCoverSrc = (ev) =>{
    return ev.target.src = CoverIcon
}

export const removeFileExtension = (path) =>{
    return path.replace(/\.[^/.]+$/, "");
}

export const setCookie = (cname, cvalue) => {
    var d = new Date();
    d.setTime(d.getTime() + (7500*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();

    document.cookie = cname + "=" + cvalue + "; expires = " + expires + "; path=/";
}
  
export const getCookie = (cname) =>{
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  
export const checkCookieExist = (cname) => {
    var check = getCookie(cname);
    return !isStringNullEmptyOrUndefined(check);
  }


export const getHobby= (value) => {
    switch(value){
        case 2: 
            return "映画鑑賞"
        case 3: 
            return "音楽鑑賞"  
        case 4: 
            return "スポーツ"    
        case 5: 
            return "スポーツ観戦"    
        case 6: 
            return "カラオケ"    
        case 7: 
            return "バンド"  
        case 8: 
            return "料理"     
        case 9: 
            return "グルメ"
        case 10: 
            return "お酒"  
        case 11: 
            return "ショッピング"    
        case 12: 
            return "ファッション"    
        case 13: 
            return "アウトドア"    
        case 14: 
            return "ドライブ"  
        case 15: 
            return "旅行"  
        case 16: 
            return "アート"  
        case 17: 
            return "習いごと"     
        case 18: 
            return "語学"
        case 19: 
            return "読書"  
        case 20: 
            return "マンガ"    
        case 21: 
            return "テレビ"    
        case 22: 
            return "ゲーム"    
        case 23: 
            return "ギャンブル"  
        case 24: 
            return "ペット" 
        case 25: 
            return "美容"  
        case 26: 
            return "ダイエッ"         
        default:
            return "なし"
    }
}

export const getPrefecture= (value) => {
    switch(value){
        case 1: 
             return "北海道"
        case 2: 
            return "青森県"
        case 3: 
            return "岩手県"  
        case 4: 
            return "宮城県"    
        case 5: 
            return "秋田県"    
        case 6: 
            return "山形県"    
        case 7: 
            return "福島県"  
        case 8: 
            return "茨城県"     
        case 9: 
            return "栃木県"
        case 10: 
            return "群馬県"  
        case 11: 
            return "埼玉県"    
        case 12: 
            return "千葉県"    
        case 13: 
            return "東京都"    
        case 14: 
            return "神奈川県"  
        case 15: 
            return "新潟県"  
        case 16: 
            return "富山県"  
        case 17: 
            return "石川県"     
        case 18: 
            return "福井県"
        case 19: 
            return "山梨県"  
        case 20: 
            return "長野県"    
        case 21: 
            return "岐阜県"    
        case 22: 
            return "静岡県"    
        case 23: 
            return "愛知県"  
        case 24: 
            return "三重県" 
        case 25: 
            return "滋賀県"  
        case 26: 
            return "京都府" 
        case 27: 
            return "大阪府" 
        case 28: 
            return "兵庫県"
        case 29: 
            return "奈良県" 
        case 30: 
            return "和歌山県" 
        case 31: 
            return "鳥取県" 
        case 32: 
            return "島根県"  
        case 33: 
            return "岡山県"     
        case 34: 
            return "広島県" 
        case 35: 
            return "山口県" 
        case 36: 
            return "徳島県"
        case 37: 
            return "香川県"  
        case 38: 
            return "愛媛県"  
        case 39: 
            return "高知県"  
        case 40: 
            return "福岡県"  
        case 41: 
            return "佐賀県"  
        case 42: 
            return "長崎県"       
        case 43: 
            return "熊本県"  
        case 44: 
            return "大分県"  
        case 45: 
            return "宮崎県"  
        case 46: 
            return "鹿児島県"  
        case 47: 
            return "沖縄県"  
        default:
            return ""
    }
}


export const refreshTokens = async(operations, userType) => {
    Auth.configure(awsSettings.cognito);
    let cognitoUser, currentSession;
    
    Auth.currentAuthenticatedUser()
      .then((user) => {
        cognitoUser = user;
        Auth.currentSession()
          .then((session) => {
            currentSession = session;
            cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
              const { idToken, refreshToken, accessToken } = session;
              const payload = {
                    'accessToken': accessToken,
                    'idToken': idToken,
                    'refreshToken': refreshToken,
                    'userType' : userType 
              }
              operations.refreshTokens(payload);
          });
      });
    });
    return;
  }

  export const refreshTokensFromApi = async(operations, userType) => {
    Auth.configure(awsSettings.cognito);
    let cognitoUser, currentSession;
    
    return Auth.currentAuthenticatedUser()
      .then((user) => {
        cognitoUser = user;
        return Auth.currentSession()
          .then((session) => {
            currentSession = session;
            cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
              const { idToken, refreshToken, accessToken } = session;
              const payload = {
                    'accessToken': accessToken,
                    'idToken': idToken,
                    'refreshToken': refreshToken,
                    'userType' : userType 
              }
              operations.refreshTokens(payload);
            });
            return session.idToken.jwtToken
      });
    });
  }

export const defaultTopScroll = () =>{
    return window.scrollTo(0, 0);
}

export const setCognitoUserDetails = (cognitoUser) =>{
    return {
        access: cognitoUser.access,
        attributes: cognitoUser.attributes,
        client: cognitoUser.client,
        keyPrefix: cognitoUser.keyPrefix,
        signInUserSession: cognitoUser.signInUserSession,
        userDataKey: cognitoUser.userDataKey,
        username: cognitoUser.username
    }
}

export const customHTMLParse = (customValue) =>{
    let value = customValue || "";
    //Limit Parsing if "<a href" tag is present only
    if(0 <= (value).indexOf("<a href")){
        //Listener for parsedLink class is found on MainContainer.js
        return HTMLParse(value.replace("<a href", `<a target='blank' class='parsedLink' href`))
    }else{
        return value;
    }
}

export const boldHandlenameRequest = (fullText, splitRef) =>{
    let splitIndex = fullText.indexOf(splitRef);
    return `<span class="bold">${fullText.substring(0, splitIndex)}</span>` + fullText.substring(splitIndex);
}

export const isSessionExpired = () => {
    let sessionExpiry = localStorage.getItem("session_expiry")
    let currTime = (new Date()).getTime();
    return sessionExpiry && (sessionExpiry <= currTime);
}

const splitLineRawText = (text) =>{
    if(typeof text === 'string') {
        return text.split('\n').map((line, i, arr) => {
            line = <span key={i}>{line}</span>;
        
            if (i === arr.length - 1) {
                return line;
            } else {
                return [line, <br key={i + 'br'} />];
            }
        })
    } 
    return text;
}
export const maxTextLinePreviewTrim = (rawText, exceedChar, hideSeeMore) =>{

    let text = splitLineRawText(rawText)

    let trimmedContent = (
        <Truncate lines={MAX_TEXT_LINES} ellipsis={hideSeeMore ? "" : <span className="see-more-button"> . . .もっと見る</span>}>
            {text}
        </Truncate>
    )

    let maxLineExceed = false;
    let preview;
    if(trimmedContent.props.children.length){
        preview = trimmedContent.props.children.map((row, index) =>{
            if(!maxLineExceed){
                if(MAX_TEXT_LINES > index){
                    if(!hideSeeMore && exceedChar && (index + 1) === trimmedContent.props.children.length){
                        if(row instanceof Object){
                            return(
                                HTMLParse(row.props.children + `<span class="see-more-button"> . . .もっと見る</span>`)
                            )
                        } else {
                            return(
                                HTMLParse(row + `<span class="see-more-button"> . . .もっと見る</span>`)
                            )
                        }
                    }
                    return row;
                }else{
                    console.log(trimmedContent.props)
                    maxLineExceed = true
                    return trimmedContent.props.ellipsis;
                }
            }
        })
    } else {
        preview = trimmedContent.props.children;
    }
    return preview;
}

export const isMaxLineExceed = (rawText) =>{
    let text = splitLineRawText(rawText)

    let trimmedContent = (
        <Truncate lines={MAX_TEXT_LINES}>
            {text}
        </Truncate>
    )

    return MAX_TEXT_LINES < trimmedContent.props.children.length;
}
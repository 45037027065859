import React, { useEffect, useState } from "react";
import TwitterIcon from "../../../includes/img/user-menu/twitter.svg";
import SNSHeader from "./SNSHeader";
import "./styles/styles.scss";
import { SNS_TWITTER_URL } from "../../../config/settings";
import $ from 'jquery'; 
import { defaultTopScroll } from "../../../utils/common";
import { ActivityIndicator } from 'react-native';

const customizeTweetMedia = () => {
    // Added onclick Events
    $('.twitter-embed').find('.twitter-timeline').contents().find('.timeline-LoadMore-prompt').bind( "click", function(e) {
      reloadScript()
    });
    $('.twitter-embed').find('.twitter-timeline').contents().find('.timeline-TweetList').unbind("click").click(function () {
      redirect()
    });

    //Customize css position
    $('.twitter-embed').find('.twitter-timeline').contents().find('.timeline-TweetList').css(
      {
        'z-index' : '2',
        'position' : 'relative',
        'cursor' : "pointer"
     }
    );
    $('.twitter-embed').find('.twitter-timeline').contents().find('.timeline-TweetList-tweet').css(
      {
        'z-index' : '-1',
        'position' : 'relative',
       "pointer-events":"none"
     }
    );
    
    $('.twitter-embed').find('.twitter-timeline').contents().find('.timeline-TweetList-tweet').attr("disabled", "disabled")
}

const redirect = () =>{
  window.open(SNS_TWITTER_URL, '_blank');
}

const reloadScript = () =>{
    setTimeout(function(){customizeTweetMedia() }, 1500);
}

const Twitter = () => {
  const [isLoading, setLoading] = useState(true);
  const [snsUrl] = useState(SNS_TWITTER_URL);
  const [snsName] = useState("X");
  const [snsAccount] = useState("ff238_official");

  useEffect(() => {
    defaultTopScroll();
    
    var loadScript = function(src) {
      const script = document.createElement("script");
      script.src = src;
      script.async = false
      document.getElementsByClassName("twitter-embed")[0].appendChild(script);
      

      //Check if script has loaded
      script.onload = function(){
        const interval = setInterval(() => {
          customizeTweetMedia()
          clearInterval(interval);
        }, 1500);
        setTimeout(function(){customizeTweetMedia() }, 3000);
        setLoading(false) 
      }
    }
    loadScript('https://platform.twitter.com/widgets.js')
  });

  return (
        <div className="sns-container">
        <SNSHeader
          snsUrl={snsUrl}
          snsName={snsName}
          snsIcon={TwitterIcon}
          snsAccount={snsAccount}
        />
        <div className="sns-twitter-container" >
          <section className="twitter-container">
            <div className="twitter-embed">
            { isLoading ?  (
              <ActivityIndicator style={{height: 50}}/>
            ) :  
              <a
                className="twitter-timeline"
                data-theme="light"
                data-chrome="noheader nofooter noborders"
                href="https://twitter.com/ff238_official"
                target="_blank" 
                rel="noopener noreferrer"
              >{null}</a>}
            </div>
          </section>
        </div>
      </div>
  )
};

export default Twitter;
import React, { useState, useImperativeHandle, useRef, forwardRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { TouchableOpacity, Text } from 'react-native';
import { DATETIME_FORMAT, SOURCE, WRAP_TEXT_CHARS, CLOUDFRONT_THUMBNAIL_PREFIX_URL, THUMBNAIL_SMALL, THUMBNAIL_MEDIUM, COMMENT_TYPE,  COMMENTS_MAX_CHAR, FUMIYA_COMMENTS_MAX_CHAR } from '../../../utils/constants';
import { Media } from 'react-breakpoints'
import { isMobile } from "react-device-detect";
import moment from 'moment';
import TextareaAutosize from 'react-autosize-textarea';
import SaveIcon from '../../../includes/img/icons/save.svg';
import SaveWhiteIcon from '../../../includes/img/icons/save-white.svg';
import './styles.scss';
import ConfirmationModal from '../ConfirmationModal';
import { isFumiyaOrFFStaffUserCheck, emptyTextValue, appendUserRoute, getObjDateValue, customHTMLParse, isFumiyaUserCheck, maxTextLinePreviewTrim, isMaxLineExceed } from '../../../utils/common';
import RenderCreateReply from './RenderCreateReply';
import PCOnlyButton from '../PCOnlyButton';
import MobileOnlyButton from '../MobileOnlyButton';
import { Link } from 'react-router-dom';
import { postSelectors } from '../../containers/Post/state';
import { Beforeunload } from 'react-beforeunload';
import DiscardChangesModal from '../DiscardChangesModal';
import LinkTransform from '../LinkTransform';
import { FUMIYA_USER_ID } from '../../../config/settings';

let isParent = (window == window.parent); //task#363

const HandleName = (props) => {
    let { appendedRoute, isUserDeleted, handleName } = props;
    if (isUserDeleted) {
        return (
            <span className="post-view-handlename">{"退会ユーザ"}</span>
        )
    } else {
        return (
            <Link  /** task#363 start */
                href={appendedRoute} 
                to={appendedRoute} 
                className="post-view-handlename"
                target = {isParent ? "_self" : "_blank"} 
                rel="noopener noreferrer"
            > {/** task#363 end*/}
                <span className="post-view-comment-handleName">{handleName}</span>
            </Link>
        )
    }
}

const ResponseButtons = (props) => {
    let buttonStyle = { marginLeft: 20, paddingVertical: props.editMode ? 0 : 4, paddingHorizontal: 4 };

    if (props.isComment) {
        return (
            <div className="post-view-comment-buttons">
                {props.isOwner && (
                    <React.Fragment>
                        {!props.editMode && <TouchableOpacity style={buttonStyle} onPress={() => props.setEditMode(true)}><Text>{'編集'}</Text></TouchableOpacity>}
                        <TouchableOpacity style={buttonStyle} onPress={() => props.setShowDelete(true)}><Text>{'削除'}</Text></TouchableOpacity>
                    </React.Fragment>
                )}
                {props.editMode ? (
                    <React.Fragment>
                        <TouchableOpacity
                            style={buttonStyle}
                            onPress={() => props.setShowDiscard(true)}
                        >
                            <Text>{'キャンセル'}</Text>
                        </TouchableOpacity>

                        <PCOnlyButton
                            disabled={props.isSaveDisabled}
                            label={'保存する'}
                            icon={SaveWhiteIcon}
                            buttonStyle={{ marginLeft: 20 }}
                            onClick={() => props.handleEditComment()}
                        />
                    </React.Fragment>
                ) : (
                        !isFumiyaOrFFStaffUserCheck(props.postAuthorId) && <TouchableOpacity style={buttonStyle} onPress={() => props.setShowCreateReply(true)}><Text>{'返信'}</Text></TouchableOpacity>
                    )}
            </div>
        )
    } else {
        if (props.isOwner) {
            return (
                <div className="post-view-comment-buttons">
                    {!props.editMode && <TouchableOpacity style={buttonStyle} onPress={() => props.setEditMode(true)}><Text>{'編集'}</Text></TouchableOpacity>}

                    <TouchableOpacity style={buttonStyle} onPress={() => props.setShowDelete(true)}><Text>{'削除'}</Text></TouchableOpacity>

                    {props.editMode && (
                        <React.Fragment>
                            <TouchableOpacity
                                style={buttonStyle}
                                onPress={() => props.setShowDiscard(true)}
                            >
                                <Text>{'キャンセル '}</Text>
                            </TouchableOpacity>

                            <PCOnlyButton
                                disabled={props.isSaveDisabled}
                                label={'保存する'}
                                icon={SaveWhiteIcon}
                                buttonStyle={{ marginLeft: 20 }}
                                onClick={() => props.handleEditReply()}
                            />
                        </React.Fragment>
                    )}
                </div>
            )
        }
    }

    return '';
}

const ResponseArea = (props, ref) => {
    let { type, value, author, source, createDt, updateDt, isOwner, postAuthorId } = props;

    let isComment = "comment" === type;
    let exceedCharPreview = WRAP_TEXT_CHARS < value.length;
    let textPreview = <LinkTransform>{maxTextLinePreviewTrim(customHTMLParse(value.substring(0, WRAP_TEXT_CHARS)), exceedCharPreview, true)}</LinkTransform>;
    let fullText = <LinkTransform>{customHTMLParse(value)}</LinkTransform>;

    const [responseValue, setResponseValue] = useState(value);
    const [editMode, setEditMode] = useState(false);
    const [showDiscard, setShowDiscard] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showCreateReply, setShowCreateReply] = useState(false);
    const [showFull, setShowFull] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [hasUpdates, setHasUpdates] = useState(false); //For page redirect check

    let isTextWrapped =  (isMaxLineExceed(value) || exceedCharPreview) && !showFull;
    let isValidCreateDt = moment(new Date(getObjDateValue(createDt))).isValid();
    let isValidUpdateDt = moment(new Date(getObjDateValue(updateDt))).isValid();
    let isCommunity = props.isCommunity

    //User Route
    let appendedRoute = appendUserRoute(`/user/${author.userId}`);
    if (isOwner) {
        appendedRoute = appendUserRoute("/mypage");
    }

    let msgDeleteComment = "このコメントを削除しますか？";

    if (isFumiyaUserCheck(postAuthorId)) {
        msgDeleteComment = 'フミヤの日記には１日記につき、お一人あたり1コメントのみの投稿となります。\nコメントを削除した場合、この日記に新たにコメントを投稿することはできません。\nこのコメントを削除しますか？';
    } else if (0 < props.replyCount) {
        msgDeleteComment = 'このコメントを削除すると、返信コメントも合わせて削除されます。\nこのコメントを削除しますか？';
    }


    const handleInputChange = (e) => {
        let newError = postSelectors.validateTextField(e, formErrors);
        setResponseValue(e.target.value);
        setFormErrors(newError);
        if("" === e.target.value){
            setHasUpdates(false)
        }else{
            setHasUpdates(true)
        }
    }

    const handleEditComment = () => {
        props.editComment(props.commentId, responseValue)
            .then(isSuccess => {
                if (isSuccess) {
                    setEditMode(false)
                    setHasUpdates(false)
                } else {
                    //Error side effect
                }
            })
    }

    const handleDeleteComment = () => {
        props.deleteComment(props.commentId)
            .then(isSuccess => {
                if (isSuccess) {
                    setEditMode(false)
                    setShowCreateReply(false)
                } else {
                    //Error side effect
                }
            })
    }

    const handleDeleteReply = () => {
        props.deleteReply(props.commentId, props.replyId)
            .then(isSuccess => {
                if (isSuccess) {
                    setEditMode(false)
                } else {
                    //Error side effect
                }
            })
    }

    const handleCreateReply = (commentId, txtReply) => {
        props.createReply(commentId, txtReply);
    }

    const handleEditReply = () => {
        props.editReply(props.commentId, props.replyId, responseValue)
            .then(isSuccess => {
                if (isSuccess) {
                    setEditMode(false)
                    setHasUpdates(false)
                } else {
                    //Error side effect
                }
            })
    }

    /** REFERENCES FOR PARENT CALLS*/
    /** Side effect check for reply socket success */
    const createReplyResponseChildRef = useRef();
    useImperativeHandle(ref, () => ({
        onSuccess: () => {
            setShowCreateReply(false)
        }
    }));

    
    const isSaveDisabled = emptyTextValue(responseValue) || 0 < Object.keys(formErrors).length;

    return (
        /** On before page leave*/
        <Beforeunload onBeforeunload={(event) => hasUpdates ? event.preventDefault() : {}} >
            <Media ref={createReplyResponseChildRef}>
                {({ breakpoints, currentBreakpoint }) => {
                    let mobileView = (breakpoints[currentBreakpoint] < breakpoints.mobileLandscape) || isMobile;
                    return (
                        <React.Fragment>
                            {/** On before route leave*/}
                            {hasUpdates && <DiscardChangesModal active={true} />}

                            <Row className="m-0 p-0">
                                <Col style={{ marginBottom: 14, paddingRight: 0 }}>
                                    {editMode ? (
                                        <React.Fragment>
                                            <p className="post-view-handlename">{author.handleName}</p>
                                            <div className="d-flex flex-direction-row">
                                                <div className="w-100">
                                                    <TextareaAutosize
                                                        key={props.commentId + props.replyId}
                                                        name={isComment ? "commentInput" : "replyInput"}
                                                        value={responseValue}
                                                        onChange={handleInputChange}
                                                        placeholder={isComment ? "コメント" : "返信する"}
                                                        className={`form-control post-view-comment-textarea-${mobileView ? "mobile" : "pc"}`}
                                                        maxLength={FUMIYA_USER_ID === props.postAuthorId ? FUMIYA_COMMENTS_MAX_CHAR : COMMENTS_MAX_CHAR}
                                                        style={{ marginLeft: 0 }}
                                                    />
                                                    {Object.values(formErrors).map((err, i) => {
                                                        return <p key={"err" + i} className="mt-1 formik-error flex-column">{err}</p>
                                                    })}
                                                </div>

                                                {editMode && (
                                                    <MobileOnlyButton
                                                        disabled={isSaveDisabled}
                                                        onPress={() => {
                                                            if (isComment) {
                                                                handleEditComment()
                                                            } else {
                                                                handleEditReply()
                                                            }
                                                        }}
                                                        buttonStyle={{ alignItems: 'center', marginLeft: 12 }}
                                                        labelStyle={{ fontSize: 14 }}
                                                        icon={SaveIcon}
                                                        label={'保存'}
                                                    />
                                                )}
                                            </div>
                                        </React.Fragment>
                                    ) : (
                                            <div className={`post-view-readonly-input-${mobileView ? "mobile" : "pc"}`}>
                                                <HandleName
                                                    appendedRoute={appendedRoute}
                                                    isUserDeleted={author.isUserDeleted}
                                                    handleName={author.handleName}
                                                />
                                                <br />

                                                { isCommunity ? COMMENT_TYPE.image === props.commentType ?
                                                    <img alt="comment-body" className="comment-image" src={mobileView ? CLOUDFRONT_THUMBNAIL_PREFIX_URL + props.commentBody + THUMBNAIL_SMALL : CLOUDFRONT_THUMBNAIL_PREFIX_URL + props.commentBody + THUMBNAIL_MEDIUM} />
                                                : isTextWrapped ?
                                                    (
                                                        <span>
                                                            <p className="m-0 comment-body-break">
                                                                {textPreview}
                                                                <span className="see-more-button" onClick={() => setShowFull(true)}>{" . . .もっと見る"}</span>
                                                            </p>
                                                        </span>
                                                    ) 
                                                : <p className="m-0">{fullText}</p> : 
                                                    isTextWrapped ? (
                                                        <span>
                                                            <p className="m-0 comment-body-break">
                                                                {textPreview}
                                                                <span className="see-more-button" onClick={() => setShowFull(true)}>{" . . .もっと見る"}</span>    
                                                            </p>
                                                        </span>
                                                    ) : <p className="m-0">{fullText}</p>}
                                            </div>
                                        )
                                    }


                                    <span style={{ paddingRight: editMode && mobileView ? 32 : 0 }} className={`post-view-comment-below${editMode ? "-edit" : ''}`}>
                                        <div className="post-view-datetime">
                                            {isValidCreateDt && moment(new Date(getObjDateValue(createDt))).format(DATETIME_FORMAT)}
                                            {isValidUpdateDt && " | 編集済"}
                                        </div>
                                        {SOURCE.new === source && (
                                            <ResponseButtons
                                                {...props}
                                                isSaveDisabled={isSaveDisabled}
                                                isComment={isComment}
                                                editMode={editMode}
                                                setEditMode={setEditMode}
                                                responseValue={responseValue}
                                                setResponseValue={setResponseValue}
                                                showDiscard={showDiscard}
                                                setShowDiscard={setShowDiscard}
                                                showDelete={showDelete}
                                                setShowDelete={setShowDelete}
                                                showCreateReply={showCreateReply}
                                                setShowCreateReply={setShowCreateReply}
                                                handleEditComment={handleEditComment}
                                                handleEditReply={handleEditReply}
                                            />
                                        )}
                                    </span>
                                </Col>
                            </Row>

                            {/* Discard Confirmation */}
                            <ConfirmationModal
                                isModalShow={showDiscard}
                                confirmTitle={"はい"}
                                confirmationText={"更新された内容が破棄されます。\nよろしいですか？"}
                                handleConfirmAction={() => {
                                    setResponseValue(props.value)
                                    setEditMode(false)
                                    setShowDiscard(false)
                                    setHasUpdates(false)
                                }}
                                handleCloseModal={() => setShowDiscard(false)}
                            />

                            {/* Delete Confirmation */}
                            <ConfirmationModal
                                isModalShow={showDelete}
                                confirmationText={msgDeleteComment}
                                handleConfirmAction={() => {
                                    if (isComment) {
                                        handleDeleteComment()
                                    } else {
                                        handleDeleteReply()
                                    }
                                    setShowDelete(false);
                                }}
                                handleCloseModal={() => setShowDelete(false)}
                            />

                            {showCreateReply && (
                                <RenderCreateReply
                                    createReply={props.createReply}
                                    commentId={props.commentId}
                                    mobileView={mobileView}
                                    callbackReply={handleCreateReply}
                                    hash={props.hash}
                                />
                            )}
                        </React.Fragment>
                    );
                }}
            </Media>
        </Beforeunload>
    )
}

export default forwardRef(ResponseArea);

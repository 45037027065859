import * as types from "./types";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { WEBSOCKET_ACTIONS, WEBSOCKET_MESSAGES } from "../../../../utils/constants"
import { WEBSOCKET_CONNECTION } from "./apiRoutes";
import { connectWS } from "./operations"
import { FUMIYA_USER_ID, FF_STAFF_USER_ID } from "../../../../config/settings";
import { getObjKeyIdString } from "../../../../utils/common";

const defaultState = {
    credentials: {},
    userAuthenticated: false,
    isAuthenticated: false,
    newNotifCount: 0,
    newNotifReferences: [],
    unseenChatThreadCount: 0,
    unseenChatThreadReferences: [],
    maintenanceType: 0
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.SET_USER_AUTH:
            let adminData = Object.assign({}, action.payload);
            adminData["access"] = "ROLE_ADMIN";
            
            return { 
                ...state, 
                credentials: adminData, 
                isAuthenticated: action.isAuthenticated,
                adminBackdoor: action.isAuthenticated
            };
        case types.SET_MEMBER_AUTHENTICATED:
            let userData = Object.assign({}, action.payload);
            
            if(action.userAuthenticated ){
                let comux2Id = parseInt(action.payload.attributes.preferred_username);
                userData["userId"] = comux2Id;
                userData["handleName"] = action.payload.attributes.name;
                /**Default Pattern */
                userData["profileImageUrl"] = `Profile/${comux2Id}/${comux2Id}`;
                userData["coverImageUrl"] = `Cover/${comux2Id}/${comux2Id}`;
                userData["access"] = "ROLE_USER";
            }

            return { 
                ...state, 
                credentials: userData, 
                userAuthenticated: action.userAuthenticated,
                adminBackdoor: false
            };
        case types.RESET_USER:
            let credentials = null;
            let userId = parseInt(action.payload);

            switch (userId) {
                case FUMIYA_USER_ID: {
                    credentials = {
                        userId: FUMIYA_USER_ID,
                        handleName: "Fumiya Account (Demo)",
                        profileImageUrl: "Profile/3644/3644",
                        coverImageUrl: "Cover/3644/3644",
                        username: "test@comux2test.com"
                    }
                    break;
                }
                case FF_STAFF_USER_ID: {
                    credentials = {
                        userId: FF_STAFF_USER_ID,
                        handleName: "FF Staff (Demo)",
                        profileImageUrl: "Profile/5526/5526",
                        coverImageUrl: "Cover/5526/5526",
                        username: "staff@comux2test.com"
                    }
                    break;
                }
                default: {
                    credentials = {
                        userId,
                        handleName: `Dummy User ${userId}`,
                        profileImageUrl: `Profile/${userId}/${userId}`,
                        coverImageUrl: `Cover/${userId}/${userId}`,
                        username: `dummyuser${userId}`,
                    }
                    break;
                }
            }
            return { ...state, credentials: credentials, isAuthenticated: true };
        case types.SET_WS_CONNECTION:
            let socket = new W3CWebSocket(WEBSOCKET_CONNECTION)
            socket.onopen = () => {
                socket.send(JSON.stringify({
                    action: WEBSOCKET_ACTIONS.onCreateConnection,
                    data: state.credentials.userId //Assuming data is fetch from current user id
                }))
                pingServer()
		        localStorage.setItem("isWebsocktOpen", 1) // White Out fix
            }
            socket.onclose = () => {
                socket.close()
                connectWS()
            }

            //Setup default listener for Notifications count
            socket.onmessage = (data) => {
                let serverData = JSON.parse(data.data);
                let responseData = JSON.parse(serverData.data)
                
                if (WEBSOCKET_MESSAGES.onNotifications === responseData.action){
                    return { 
                        ...state, 
                        websocket: socket,
                        newNotifCount: state.newNotifCount + 1
                    }
                }
            }

            function pingServer() {
                if (!socket) return;
                if (socket.readyState !== 1) return;
                socket.send(JSON.stringify({
                    action: WEBSOCKET_ACTIONS.pingServer,
                }));
                setTimeout(pingServer, 60000);
            }
            return { ...state, websocket: socket }

        case types.UPDATE_NOTIF_COUNT: {
            return {
                ...state,
                newNotifCount: action.count,
                newNotifReferences: action.referenceIds
            }
        }
        case types.UPDATE_CHAT_THREAD_COUNT: {
            return {
                ...state,
                unseenChatThreadCount: action.count,
                unseenChatThreadReferences: action.referenceIds,
            }
        }
        case types.INCREMENT_CHAT_THREAD_COUNT: {
            const isReferenceExist = state.unseenChatThreadReferences.some(el => getObjKeyIdString(el) === getObjKeyIdString(action.threadId));

            if(isReferenceExist || action.senderId === state.credentials.userId){
                return state;
            }else{
                return {
                    ...state,
                    unseenChatThreadCount: state.unseenChatThreadCount + 1,
                    unseenChatThreadReferences: [...state.unseenChatThreadReferences, action.threadId]
                }
            }            
        }
        case types.INCREMENT_NOTIF_COUNT: {
            let isReferenceExist = 0 <= state.newNotifReferences.indexOf(action.payload);

            if(isReferenceExist){
                return state;
            }else{
                return {
                    ...state,
                    newNotifCount: state.newNotifCount + 1,
                    newNotifReferences: [...state.newNotifReferences, action.payload]
                }
            }            
        }
        case types.DECREMENT_NOTIF_COUNT: {
            if (0 < state.newNotifCount){
                return {
                    ...state,
                    newNotifCount: state.newNotifCount - 1,
                } 
            }  
        }
        case types.CLEAR_NEW_NOTIF_REFERENCES: {
            return {
                ...state,
                newNotifReferences: []
            }
        }
        case types.SET_MAINTENANCE: {
            return {
                ...state,
                maintenanceType: action.payload
            }
        }
        case types.REFRESH_TOKENS:
            return {...state, credentials: {
                ...state.credentials,
                    signInUserSession: {
                        ...state.credentials.signInUserSession,
                        accessToken: action.payload.accessToken,
                        idToken: action.payload.idToken,
                        refreshToken: action.payload.refreshToken   
                    }
            }};
        case types.SET_PROFILE_IMAGE: {
            return {
                ...state,
                credentials : {
                    profileImageUrl: action.payload,
                    ...state.credentials
                }
            }
        }
        case types.SET_HANDLE_NAME: {
            return {
                ...state,
                credentials : {
                    ...state.credentials,
                    handleName: action.payload
                }
            }
        }
        case types.SET_ADMIN_BACKDOOR_CHECK: {
            let userAuth = Object.assign({}, state.credentials);
            let userId = action.payload.userId;
            userAuth["userId"] = userId;
            userAuth["handleName"] = action.payload.handleName;
            userAuth["profileImageUrl"] = `Profile/${userId}/${userId}`;
            userAuth["coverImageUrl"] = `Cover/${userId}/${userId}`;

            return {
                ...state,
                adminBackdoor: action.payload.authenticated,
                userAuthenticated: action.payload.authenticated,
                credentials: action.payload.authenticated ? userAuth : state.credentials
            }
        }
        default: return state;
    }

};
import React, { Component } from 'react';
import PostViewTemplate from '../../components/PostViewTemplate';
import { isScrollRefetchReached, onApiSuccess, getObjKeyIdString, isNullOrUndefined, appendUserRoute, defaultTopScroll, objKeyExist } from '../../../utils/common';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { postOperations } from './state';
import ProcessLoader from '../../components/ProcessLoader';
import ErrorModal from '../../components/ErrorModal';
import ViewPostSkeleton from '../../components/Skeletons/PostViewSkeleton';
import { SYSTEM_ERROR } from '../../../utils/messages';
import { WEBSOCKET_MESSAGES, WEBSOCKET_API_ERROR_STATUS, POST_TYPES } from '../../../utils/constants';
import { isSessionExpired} from "../../../utils/common"; // Whiete Out fix
import { authOperations } from '../Authentication/state'; // Whiete Out fix
import ProcessLoaderForLoading from '../../components/ProcessLoaderForLoading'; // Whiete Out fix

/**For scroll direction check */
var scrollPos = 0;

class ViewPost extends Component {
    constructor(props) {
        super(props)
        this.state = {
            postId: null,
            type: null,
            attachmentType: null,
            commentCount: null,
            title: "",
            source: null,
            author: {
                userId: 0,
                handleName: "",
                authorDatetime: "",
                profileImageUrl: null,
                isUserDeleted: false,
                isEdited: true
            },
            postBody: "",
            photos: [],
            videoUrl: "",
            videoStatus: null,
            comments: [],
            unloadedCommentIds: [],
            commentPage: 1,
            isCommentFetching: false,
            isAccessInvalid: false,
            isProcessing: false,
            isProcessingForLoading: false, //White Out fix
            msgErrorModal: "",
            showErrorModal: false,
            notFoundErrorModal: "",
            showNotFoundErrorModal: false,
            isContentLoaded: false, 
            isCommentLoaded: false,
            communityName : "",
            communityId: null,
            poll: {},
            isCommunity: false,
            commentsEndReached: false,
            isPastPost: false
        }
    }

    componentDidMount() {
        defaultTopScroll();

        let id = this.props.match.params.id;
        let communityId = this.props.match.params.communityId;
        let redirectState = this.props.location.state;

        let isPastPost = 0 <= this.props.location.pathname.indexOf("post/view/past/");

        if (undefined === id) {
            this.setState({ isAccessInvalid: true })
        } else {
            if (!isNullOrUndefined(redirectState)) {
                this.setState({
                    title: redirectState.title || "",
                    postBody: redirectState.postBody || "",
                    author: redirectState.author,
                    commentCount: redirectState.commentCount || 0,
                    source: redirectState.source || 0,
                    attachmentType: redirectState.attachmentType || 0,
                    photos: redirectState.photos || [],
                    videoUrl: redirectState.videoUrl || "",
                    videoStatus: redirectState.videoStatus || null,
                    createDt: redirectState.createDt,
                    updateDt: redirectState.updateDt,
                    isContentLoaded: true,
                    poll: redirectState.poll,
                    communityId: redirectState.communityId,
                    communityName: redirectState.communityName,
                    isCommunity: redirectState.isCommunity,
                    isPastPost: isPastPost
                });
            }
     
            if(isPastPost){
                this.setState({isPastPost: isPastPost},()=>{
                    this.getPost(id);
                    this.getMoreComments();
                })
            }else if(isNullOrUndefined(communityId)){
                this.getPost(id);
                this.getMoreComments();
            }else{
                this.getCommunityPost(communityId, id)
                this.getMoreCommunityComments();
            }
        }

        /**Sockets */
        if (!isNullOrUndefined(this.props.websocket) && this.props.websocket.readyState === WebSocket.OPEN) {
            this.initWSHandlers()
        }
        /***/

        document.addEventListener('scroll', this.onScrollCheck);
        
        document.addEventListener("visibilitychange", this.backGroundCare); //White Out fix

    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.onScrollCheck);
        document.removeEventListener("visibilitychange", this.backGroundCare); //White Out fix
    }

    componentDidUpdate(){
        this.initWSHandlers()
    }

    /** SOCKETS for thumbnails and replies/comments */
    initWSHandlers() {
        this.props.websocket.onmessage = (data) => {
            let serverData = JSON.parse(data.data);

            if (!isNullOrUndefined(serverData.message) && WEBSOCKET_API_ERROR_STATUS === serverData.message) {
                this.setState({
                    showErrorModal: true,
                    msgErrorModal: SYSTEM_ERROR,
                    isProcessing: false
                })
            }

            let responseData = JSON.parse(serverData.data)

            if (WEBSOCKET_MESSAGES.onNotifications === responseData.action) {
                this.props.incrementNotifCount(responseData.referenceId)
            }else if (WEBSOCKET_MESSAGES.onChatNotification === responseData.action) {
                this.props.incrementChatThreadCount(responseData.senderId, responseData.threadId)
            } else if (WEBSOCKET_MESSAGES.onCustomErrorMessage === responseData.action) {
                this.setState({
                    isProcessing: false,
                    msgErrorModal: responseData.errorMessage,
                    showErrorModal: true
                })
            } else if (WEBSOCKET_MESSAGES.onNotFound === responseData.action) {
                this.setState({
                    isProcessing: false,
                    notFoundErrorModal: responseData.errorMessage,
                    showNotFoundErrorModal: true
                })
            }else if (WEBSOCKET_MESSAGES.onCreateComment === responseData.action) { //Comment Sockets
                if (responseData.postId === this.state.postId) {
                    //Append comment on list
                    let newComment = {
                        _id: responseData.commentId,
                        postId: responseData.postId,
                        commentBody: responseData.commentBody,
                        createDt: responseData.createDt,
                        author: responseData.author,
                        replies: []
                    }

                    this.setState(prevState => ({
                        comments: [newComment].concat(prevState.comments),
                        isProcessing: false,
                        commentCount: prevState.commentCount + 1,
                        unloadedCommentIds: [...prevState.unloadedCommentIds, getObjKeyIdString(responseData.commentId)]
                    }))

                    //Call template success side effect (Comments)
                    if(!isNullOrUndefined(this.refs.postViewRef)){
                        this.refs.postViewRef.onWebsocketCommentSuccess()
                    }
                    
                }
            } else if (WEBSOCKET_MESSAGES.onCreateReply === responseData.action) { //Replies Sockets
                if (responseData.postId === this.state.postId) {
                    //Append reply on list
                    let newReply = {
                        _id: responseData.replyId,
                        commentId: responseData.commentId,
                        replyBody: responseData.replyBody,
                        createDt: responseData.createDt,
                        author: responseData.author
                    }

                    let newComments = Object.assign([], this.state.comments);
                    let updatedComment = newComments.filter(c => getObjKeyIdString(c._id) === responseData.commentId);

                    if(0 < updatedComment.length){
                        updatedComment[0].replies.unshift(newReply)

                        this.setState(prevState => ({
                            comments: newComments,
                            isProcessing: false,
                            commentCount: prevState.commentCount + 1
                        }));

                        //Call template success side effect (Reply)
                        if(!isNullOrUndefined(this.refs.postViewRef)){
                            this.refs.postViewRef.onWebsocketReplySuccess(responseData.commentId)
                        }
                    }
                }
            }  else if (WEBSOCKET_MESSAGES.onPostImageRender === responseData.action) {//Post Upload sockets
                if (getObjKeyIdString(responseData.postId) === this.state.postId) {
                    let filteredPhotos = Object.assign([], this.state.photos);
                    let updatePhoto = filteredPhotos.filter(i => i.imageUrl === responseData.imageUrl)

                    //Existing photo on current view
                    if (0 < updatePhoto.length) {
                        updatePhoto[0].imageStatus = responseData.imageStatus
                    }

                    //Update photos state
                    this.setState({photos: filteredPhotos})
                }
            }else if(WEBSOCKET_MESSAGES.onPostVideoRender === responseData.action){
                if (getObjKeyIdString(responseData.postId) === this.state.postId) {
                    //Update video state
                    this.setState({videoStatus: responseData.videoStatus, videoUrl: responseData.videoUrl})
                }
            }else if(WEBSOCKET_MESSAGES.onCreateCommentError === responseData.action || WEBSOCKET_MESSAGES.onCreateReplyError === responseData.action){
                this.setState({
                    showErrorModal: true,
                    msgErrorModal: serverData.message,
                    isProcessing: false
                })
            }
        }

        let isExistError = false; //added 20220720 White Out fix (caused by reconnection loop)
        this.props.websocket.onerror = () => { isExistError = true } //mod 20220617 White Out fix (caused by reconnection loop) Start.
        this.props.websocket.onclose = () => {
            let isScreenBackGround = localStorage.getItem("isScreenBackGround");
            if(isScreenBackGround == null){
                if(!isExistError) this.socketReconnect();
            } else { //If screen in smartPhone run to background, do not reconnect here as reconnection will be done with the exclusive method
                localStorage.setItem("isScreenBackGround", null);
            }
        } 
    }
     
    backGroundCare = () =>{
        if (document.visibilityState === "hidden"){ //When screen run to background
            localStorage.setItem("isScreenBackGround", 1);
            this.props.websocket.close(); //Forcibly close websocket
            localStorage.setItem("isWebsocktOpen", 0);
            this.setState({ isProcessingForLoading: true }); // Prepare to show Processing modal when screen returned from background
        }
        if (document.visibilityState === "visible"){ //When screen returned from background
            if (isSessionExpired()) this.props.logoutUser(); 
                this.props.connect();
                const interval = setInterval(() => {
                    if (localStorage.getItem("isWebsocktOpen") == 1) {
                        this.setState({ isProcessingForLoading: false }); // Stop Processing modal
                        clearInterval(interval);
                    }
                  }, 50);
        } 
    }//added 20220720 White Out fix End

    socketReconnect = () => {
        this.props.websocket.close()
        this.props.connect();
        setTimeout(() => {
            this.initWSHandlers()
        }, 1000);
    }
    /**END OF SOCKETS */


    onScrollCheck = () => {
        let isScrollDown = (document.body.getBoundingClientRect()).top < scrollPos;

        if(POST_TYPES.album !== this.state.type && !isNullOrUndefined(this.state.type)){
            if (isScrollRefetchReached() && !this.state.isCommentFetching && isScrollDown && !this.state.commentsEndReached) {
                if(isNullOrUndefined(this.state.communityId)){
                    this.getMoreComments();
                }else{
                    this.getMoreCommunityComments();
                }
            }
        }

        scrollPos = (document.body.getBoundingClientRect()).top;
    }

    //Community Posts
    getCommunityPost = (communityId, postId) => {
        this.props.getCommunityPost(communityId, postId)
            .then((res) => {
                if (onApiSuccess(res)) {
                    let postData = JSON.parse(res.data.data);
                    this.setState({
                        postId,
                        ...postData[0],
                        isAccessInvalid: false,
                    });
                    this.getMoreCommunityComments();
                } else {
                    this.setState({ isAccessInvalid: true })
                }
            })
            .catch(() => this.showSystemError())
    }

    getMoreCommunityComments = () => {
        let id = this.props.match.params.id;

        if (!this.state.isCommentFetching) {
            this.setState({ isCommentFetching: true }, () => {
                this.props.getMoreCommunityComments(id, this.state.commentPage)
                    .then((res) => {
                        if (onApiSuccess(res)) {
                            let commentData = JSON.parse(res.data.data);
                            if (0 < commentData.length) {
                                let newCommentData = []
                                commentData.map((item) => {
                                    return newCommentData.push(item.comments)
                                })
                                this.setState(prevState => ({
                                    comments: [...prevState.comments, ...newCommentData],
                                    commentPage: prevState.commentPage + 1,
                                    isCommentFetching: false,
                                    isCommentLoaded: true
                                }))
                            } else {
                                this.setState({ isCommentFetching: false, isCommentLoaded: true, commentsEndReached: true });
                            }
                        }
                    })
                    .catch(() => this.showSystemError())
            })
        }
    }

    getPost = (postId) => {
        this.props.getPost(this.props.user.userId, postId, this.state.isPastPost)
            .then((res) => {
                if (onApiSuccess(res)) {
                    let postData = JSON.parse(res.data.data);
                    this.setState({
                        postId,
                        ...postData,
                        isAccessInvalid: false,
                        isContentLoaded: true 
                    });
                } else if(objKeyExist(res, "statusError")){
                    this.showSystemError()
                }else{
                    this.setState({ isAccessInvalid: true, isContentLoaded: true }) 
                }
            })
            .catch(() => this.showSystemError())
    }

    getMoreComments = () => {
        let id = this.props.match.params.id;

        if (!this.state.isCommentFetching) {
            this.setState({ isCommentFetching: true }, () => {
                let checkPostType = 3;
                this.props.getMoreComments(this.props.user.userId, id, this.state.commentPage, this.state.isPastPost, isNullOrUndefined(this.state.type) ? checkPostType : this.state.type)
                    .then((res) => {
                        if (onApiSuccess(res)) {
                            let commentData = JSON.parse(res.data.data);

                            if (0 < commentData.length) {
                                let oldCommentsData = commentData.filter(i => 0 > this.state.unloadedCommentIds.indexOf(getObjKeyIdString(i._id)))

                                this.setState(prevState => ({
                                    comments: [...prevState.comments, ...oldCommentsData],
                                    commentPage: prevState.commentPage + 1,
                                    isCommentFetching: false,
                                    isCommentLoaded: true
                                }))
                            } else {
                                this.setState({ isCommentFetching: false, isCommentLoaded: true, commentsEndReached: true });
                            }
                        }
                    })
                    .catch(() => this.showSystemError())
            })
        }
    }

    deletePost = (postId) => {
        return new Promise (resolve=>{
            this.setState({ isProcessing: true }, 
                async () => {
                this.props.deletePost(postId)
                    .then(res => {
                        if (onApiSuccess(res)) {
                            this.props.history.push(appendUserRoute("/mypage"));
                            resolve(true);
                        } else {
                            this.setState({
                                isProcessing: false,
                                msgErrorModal: isNullOrUndefined(res.data.message) ? SYSTEM_ERROR : res.data.message,
                                showErrorModal: true
                            })
                            resolve(false);
                        }
                    })
                    .catch(() => this.showSystemError())
            })
        })
    }

    setAuthor = () => {
        return {
            userId: this.props.user.userId,
            handleName: this.props.userDetails.handleName,
            profileImageUrl: this.props.user.profileImageUrl,
            isUserDeleted: false
        }
    }

    createComment = (commentBody) => {
        let params = {
            postId: this.state.postId,
            postAuthorId: this.state.author.userId,
            commentBody: commentBody,
            author: this.setAuthor()
        }

        this.setState({ isProcessing: true }, () => { //mod 20220630 White Out fix (caused by reconnection loop) Start.
            if (!isNullOrUndefined(this.props.websocket) && this.props.websocket.readyState === WebSocket.OPEN) {
                this.props.createComment(params, this.props.websocket);
            } else {
                this.props.connect();
                this.props.websocket.onopen = () => {
                    this.props.createComment(params, this.props.websocket);
                }
            }
        });  //mod 20220630 White Out fix (caused by reconnection loop) End.
    }

    createReply = (commentId, replyBody) => {
        let params = {
            postId: this.state.postId,
            postAuthorId: this.state.author.userId,
            commentId: getObjKeyIdString(commentId),
            replyBody: replyBody,
            author: this.setAuthor()
        }

        this.setState({ isProcessing: true }, () => { //mod 20220630 White Out fix (caused by reconnection loop) Start.
            if (!isNullOrUndefined(this.props.websocket) && this.props.websocket.readyState === WebSocket.OPEN) {
                this.props.createReply(params, this.props.websocket);
            } else {
                this.props.connect();
                this.props.websocket.onopen = () => {
                    this.props.createReply(params, this.props.websocket);
                }
            }
        });  //mod 20220630 White Out fix (caused by reconnection loop) End.
    }

    editComment = (commentId, commentBody) => {
        let params = {
            commentId: getObjKeyIdString(commentId),
            commentBody: commentBody,
        }

        return new Promise(resolve => {
            this.setState({ isProcessing: true },
                async () => {
                    this.props.editComment(params)
                        .then(res => {
                            if (onApiSuccess(res)) {
                                let newComments = Object.assign([], this.state.comments);
                                let updatedComment = newComments.filter(c => c._id === commentId)[0];
                                updatedComment.updateDt = { $date: new Date() };
                                updatedComment.commentBody = commentBody;

                                this.setState({
                                    comments: newComments,
                                    isProcessing: false
                                });
                                resolve(true);
                            } else {
                                //Failed
                                this.setState({
                                    isProcessing: false,
                                    msgErrorModal: isNullOrUndefined(res.data.message) ? SYSTEM_ERROR : res.data.message,
                                    showErrorModal: true
                                })
                                resolve(false);
                            }
                        })
                        .catch(() => this.showSystemError())
                })
        })
    }

    editReply = (commentId, replyId, replyBody) => {
        let params = {
            commentId: getObjKeyIdString(commentId),
            replyId: getObjKeyIdString(replyId),
            replyBody: replyBody,
        }

        return new Promise(resolve => {
            this.setState({ isProcessing: true },
                async () => {
                    this.props.editReply(params)
                        .then(res => {
                            if (onApiSuccess(res)) {
                                let newComments = Object.assign([], this.state.comments);
                                let updatedComment = newComments.filter(c => c._id === commentId)[0];
                                let updatedReply = updatedComment.replies.filter(r => r._id === replyId)[0];
                                updatedReply.updateDt = { $date: new Date() };
                                updatedReply.replyBody = replyBody;

                                this.setState({
                                    comments: newComments,
                                    isProcessing: false
                                });
                                resolve(true);
                            } else {
                                this.setState({
                                    isProcessing: false,
                                    msgErrorModal: isNullOrUndefined(res.data.message) ? SYSTEM_ERROR : res.data.message,
                                    showErrorModal: true
                                })
                                resolve(false);
                            }
                        })
                        .catch(() => this.showSystemError())
                })
        });
    }

    deleteComment = (commentId) => {
        return new Promise(resolve => {
            this.setState({ isProcessing: true },
                async () => {
                    this.props.deleteComment(getObjKeyIdString(commentId))
                        .then(res => {
                            if (onApiSuccess(res)) {
                                let deductedCount = 1;
                                let deletedComment = this.state.comments.filter(c => c._id === commentId);
                                if(0 < deletedComment.length){
                                    deductedCount += deletedComment[0].replies.length;
                                }

                                let newComments = this.state.comments.filter(c => c._id !== commentId);

                                
                                this.setState(prevState => ({
                                    comments: newComments,
                                    isProcessing: false,
                                    commentCount: prevState.commentCount - deductedCount
                                }));
                                resolve(true);
                            } else {
                                this.setState({
                                    isProcessing: false,
                                    msgErrorModal: isNullOrUndefined(res.data.message) ? SYSTEM_ERROR : res.data.message,
                                    showErrorModal: true
                                })
                                resolve(false);
                            }
                        })
                        .catch(() => this.showSystemError())
                })
        });
    }

    deleteReply = (commentId, replyId) => {
        return new Promise(resolve => {
            this.setState({ isProcessing: true },
                async () => {
                    this.props.deleteReply(getObjKeyIdString(commentId), getObjKeyIdString(replyId))
                        .then(res => {
                            if (onApiSuccess(res)) {
                                let newComments = Object.assign([], this.state.comments);
                                let updatedComment = newComments.filter(c => c._id === commentId)[0];
                                updatedComment.replies = updatedComment.replies.filter(r => r._id !== replyId);

                                this.setState(prevState => ({
                                    comments: newComments,
                                    isProcessing: false,
                                    commentCount: prevState.commentCount - 1
                                }));
                                resolve(true);
                            } else {
                                //Failed
                                this.setState({
                                    isProcessing: false,
                                    msgErrorModal: isNullOrUndefined(res.data.message) ? SYSTEM_ERROR : res.data.message,
                                    showErrorModal: true
                                })
                                resolve(false);
                            }
                        })
                        .catch(() => this.showSystemError())
                })
        });
    }

    showSystemError = () => {
        this.setState({
            isProcessing: false,
            msgErrorModal: SYSTEM_ERROR,
            showErrorModal: true
        })
    }

    toggleErrorModal = () => {
        this.setState(prevState => ({ showErrorModal: !prevState.showErrorModal }))
    }
    
    refreshPage = () => {
        window.location.reload();
    }

    render() {
        let postContent = <ViewPostSkeleton/>;

        if (this.state.isContentLoaded) {
            postContent = (
                <PostViewTemplate
                    ref="postViewRef"
                    {...this.state}
                    history={this.props.history}
                    communityName={this.state.communityName}
                    communityId={this.state.communityId}
                    getMoreComments={this.getMoreComments}
                    deletePost={this.deletePost}
                    createComment={this.createComment}
                    createReply={this.createReply}
                    editComment={this.editComment}
                    editReply={this.editReply}
                    deleteComment={this.deleteComment}
                    deleteReply={this.deleteReply}
                    reuploadAttachment={this.reuploadAttachment}
                />
            )
        }

        return (
            <div>
                <ProcessLoader
                    isShown={this.state.isProcessing}
                />

                <ProcessLoaderForLoading
                    isShown={this.state.isProcessingForLoading}
                />

                <ErrorModal
                    errorMessage={this.state.msgErrorModal}
                    isErrorModalShow={this.state.showErrorModal}
                    handleCloseModal={this.toggleErrorModal}
                />

                <ErrorModal
                    errorMessage={this.state.notFoundErrorModal}
                    isErrorModalShow={this.state.showNotFoundErrorModal}
                    handleCloseModal={this.refreshPage}
                />

                {/**Render Post Content*/}
                {postContent}

            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.credentials,
        websocket: state.auth.websocket,
        userDetails: state.editprofile.profileDetails
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getPost: postOperations.getPost,
        getMoreComments: postOperations.getMoreComments,
        getCommunityPost: postOperations.getCommunityPost,
        getMoreCommunityComments: postOperations.getMoreCommunityComments,
        createComment: postOperations.createComment,
        editComment: postOperations.editComment,
        deleteComment: postOperations.deleteComment,
        createReply: postOperations.createReply,
        editReply: postOperations.editReply,
        deleteReply: postOperations.deleteReply,
        deletePost: postOperations.deletePost,
        connect: authOperations.connectWS,
        incrementNotifCount: authOperations.incrementNotifCount,
        incrementChatThreadCount: authOperations.incrementChatThreadCount,
        logoutUser: authOperations.logoutUser //White out fix
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(ViewPost);
import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import FacebookAuthorHeader from './FacebookAuthorHeader';
import { WRAP_TEXT_CHARS, FACEBOOK_ATTACHMENT_TYPES } from '../../../utils/constants';
import PropTypes from 'prop-types';
import { Media } from 'react-breakpoints'
import { isMobile } from "react-device-detect";
import { withRouter } from 'react-router-dom';
import './styles/post.scss';
import PhotoGrid from '../../components/PhotoGrid';
import { View } from 'react-native';
import { isNullOrUndefined } from 'util';
import { objKeyExist } from '../../../utils/common';
    

const PostContent = ({ title, postBody }) => {
    const [showFull] = useState(false);

    if (!isNullOrUndefined(postBody) && WRAP_TEXT_CHARS < postBody.length && !showFull) {
        let trimmedText = postBody.substring(0, WRAP_TEXT_CHARS);
        return (
            <span>
                <div className="post-item-body">
                    <p className="post-title">{title}</p>
                    <p>{trimmedText + " . . ."}<span className="post-body-button">もっと見る</span></p>
                </div>
            </span>
        )
    } else {
        return <div className="post-item-body"><p><b>{title}</b></p><p>{postBody}</p></div>;
    }
}

const RenderVideo = ({ videoUrl }) => {
            return (
                <div className="post-item-video-container">
                    <iframe
                        title={videoUrl}
                        src={`${videoUrl.media.source}`}
                        frameBorder="0"
                        allowFullScreen
                        className="post-item-video-content"
                    >
                    </iframe>
                </div>
            );
}

const RenderPhotos = ({ photo, onRedirect }) => {
    let album = []
    let singlePhotoObject = []
    
    let singlePhoto = (objKeyExist(photo, "media") && (FACEBOOK_ATTACHMENT_TYPES.cover_photo === photo.type || FACEBOOK_ATTACHMENT_TYPES.photo === photo.type || FACEBOOK_ATTACHMENT_TYPES.profile === photo.type || FACEBOOK_ATTACHMENT_TYPES.link === photo.media_type)) ? ((objKeyExist(photo.media, "image") ? photo.media.image.src : null)) : null;
    singlePhotoObject.push(singlePhoto)
    
    let albumPhotos = FACEBOOK_ATTACHMENT_TYPES.album === photo.type ? photo.subattachments.data.map((item)=>{
        return album.push(item.media.image.src)
    }) : null;

    
    // if(isNullOrUndefined(singlePhoto) && isNullOrUndefined(albumPhotos)){
    //     return "";
    // }

    return (
        <View style={{ flex: 1 }}>
            <PhotoGrid
                source={isNullOrUndefined(albumPhotos) ? singlePhotoObject : album}
                onPressImage={onRedirect}
            />
        </View>
    )
}

const RenderLinkType = (props, attachmentTitle, attachmentDesc) =>{
    return (props.message || "") + "\n\n" + (attachmentTitle || "")  + "\n" + (attachmentDesc || "");
}

const RenderVideoPostBody = (props, attachmentTitle) => {
    return props.message ? (props.message || "") + "\n" + (attachmentTitle || "")  :  (attachmentTitle || "") ;
}


const FacebookPostItemTemplate = (props) => {
    let media_type =  !isNullOrUndefined(props.attachments) && props.attachments.data[0].media_type 
    let attachmentTitle = !isNullOrUndefined(props.attachments) ? props.attachments.data[0].title : null 
    let attachmentDesc = !isNullOrUndefined(props.attachments) ? props.attachments.data[0].description : null 

    //Conditions
    const displayPhotos = !isNullOrUndefined(props.attachments) && (FACEBOOK_ATTACHMENT_TYPES.photo === media_type || FACEBOOK_ATTACHMENT_TYPES.album === media_type)
    const displayVideo = !isNullOrUndefined(props.attachments) &&  FACEBOOK_ATTACHMENT_TYPES.video === media_type
    const shareType =  !isNullOrUndefined(props.attachments) &&  FACEBOOK_ATTACHMENT_TYPES.share === media_type
    const linkType = !isNullOrUndefined(props.attachments) &&  FACEBOOK_ATTACHMENT_TYPES.link === media_type

    return (
        <Media>
            {({ breakpoints, currentBreakpoint }) => {
                let mobileView = (breakpoints[currentBreakpoint] < breakpoints.mobileLandscape) || isMobile;
                return (
                    <React.Fragment>
                        <Card className="feed-container-item" style={mobileView ? { border: 'none' } : {}}>
                            <Card.Body style={{ padding: 0 }}>
                                <div className="post-item-container">
                                    <FacebookAuthorHeader
                                        {...props}
                                        createDt={props.created_time}
                                        updateDt={props.updated_time}
                                    />
                                    <PostContent 
                                        title={props.title} 
                                        postBody={displayVideo || shareType ? RenderVideoPostBody(props,attachmentTitle) : displayVideo || linkType ? RenderLinkType(props, attachmentTitle, attachmentDesc) : props.message } 
                                    />
                                </div>

                                {(displayPhotos || linkType) && (
                                    <RenderPhotos
                                        photo={props.attachments.data[0]}
                                    />
                                )}

                                {displayVideo && (
                                    <RenderVideo
                                        videoUrl={props.attachments.data[0]}
                                    />
                                )}
                            </Card.Body>
                        </Card>
                    </React.Fragment>
                )
            }}
        </Media>
    );
};

export default withRouter(FacebookPostItemTemplate);

FacebookPostItemTemplate.propTypes = {
    created_time: PropTypes.string,
    updated_time: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.string,
    type: PropTypes.string,
    attachments: PropTypes.object,
    data: PropTypes.array,
    source: PropTypes.string,
    media_type: PropTypes.string,
    commentCount: PropTypes.number,
    src: PropTypes.string
};
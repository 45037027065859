import React, { useEffect } from "react";
import { withRouter, Redirect } from "react-router-dom";
import AppHeader from "../../components/AppHeader";
import { ROOT, APP_ROOT_ROUTE } from "./routes";
import { Container, Col, Row } from "react-bootstrap";
import "./styles/containers.scss";
import {
  isNullOrUndefined,
  verifiedUserAuthentication,
  refreshTokens,
  initialAccountUpdated,
  isSessionExpired
} from "../../../utils/common";
import { useSelector } from "react-redux";
import { WEBSOCKET_MESSAGES, MAINTENANCE_TYPE, USER_TYPE } from "../../../utils/constants";
import MaintenanceScreen from "../../components/MaintenanceScreen";
import { authOperations } from "../Authentication/state";
import { maintenanceOperations } from "../Maintenance/state";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import CookiesPrompt from "./CookiesPrompt";
import PCOnlyView from "../../components/PCOnlyView";
import { PCSideNav } from "./PCSideNav";
import { ModuleContent } from "./ModuleContent";
import $ from 'jquery'; 
import { isMobile } from "react-device-detect";
import SessionTimer from "../../components/SessionTimer";

const MainContainer = React.memo(({ location }) => {
  const auth = useSelector((state) => state.auth);
  const maintenance = useSelector((state) => state.maintenance.maintenanceType);
  const profile = useSelector((state) => state.editprofile.profileDetails);

  let isAdmin = ("ROLE_ADMIN" === auth.credentials.access);
  let isAdminBackdoor = auth.adminBackdoor;
  let isContactOrStaticPage = false;
  const dispatch = useDispatch();

  let user = auth.credentials;
  let userId = user.userId;
  let websocket = auth.websocket;

  if(location.pathname.includes('/contact-us') || location.pathname.includes('/help') 
    || location.pathname.includes('/terms-of-service') || location.pathname.includes('/privacy-policy')) {
      isContactOrStaticPage = true;
  }

  let isIFrame =  window != window.parent; //task#363

  let operations = bindActionCreators(
    {
      connectWS: authOperations.connectWS,
      getNewNotifCount: authOperations.getNewNotifCount,
      getUnseenThreads: authOperations.getUnseenThreads,
      incrementNotifCount: authOperations.incrementNotifCount,
      incrementChatThreadCount: authOperations.incrementChatThreadCount,
      authenticateMember: authOperations.authenticateMember,
      memberComux2IdCheck: authOperations.memberComux2IdCheck,
      getMaintenanceSettings: maintenanceOperations.getMaintenanceSettings,
      refreshTokens: authOperations.refreshTokens,
      logoutUser: authOperations.logoutUser
    },
    dispatch
  );

  const handleTokenExpire = (operations) => {
    if(user.hasOwnProperty('signInUserSession')) {
      if(user.signInUserSession.hasOwnProperty('accessToken')) {
        const currTimeStamp = Math.floor(Date.now()/ 1000);
        const expTimestamp = user.signInUserSession.accessToken.payload.exp;
        let diff = Math.floor((expTimestamp - currTimeStamp) / 60);
        
        //Refresh 20 minutes before expiry
        if (diff < 20 || expTimestamp <= currTimeStamp) {
          /**
           * if current timestamp reaches token expiration
           * perform refresh access token
           * **/
          refreshTokens(operations, USER_TYPE.user)
        }
      }
    }
  }

  //Equivalent to componentDidMount
  //Connect sockets on load
  useEffect(() => {
    //Connect websocket
    operations.connectWS();

    //Sockets check for notif badge
    if (!isNullOrUndefined(websocket)) {
      websocket.onmessage = (data) => {
        let serverData = JSON.parse(data.data);
        let responseData = JSON.parse(serverData.data);

        if (WEBSOCKET_MESSAGES.onNotifications === responseData.action) {
          operations.incrementNotifCount(responseData.referenceId);
        }else if (WEBSOCKET_MESSAGES.onChatNotification === responseData.action) {
          operations.incrementChatThreadCount(responseData.senderId, responseData.threadId)
        } 
      };
    }

    //Check Maintenance Type
    if(!isAdmin || !isAdminBackdoor){
      operations.getMaintenanceSettings();
    }

    handleTokenExpire(operations);

    //User session / credentials not exists
    if ((!verifiedUserAuthentication() && !isContactOrStaticPage) || isSessionExpired()) {
      operations.logoutUser(false);
    } else {
      if(initialAccountUpdated()){
        //Get new notif count
        operations.getNewNotifCount(userId);

        //Get new notif count
        operations.getUnseenThreads(userId);
      }
      
      //Check Comux2 ID on Cognito
      operations.memberComux2IdCheck();
    }
  }, []);

  useEffect(() => {
    //Custom JQuery to remove link propagantion
    //on common.js customParseLink
    $(".parsedLink" ).on( "click", function(event) {
      event.stopPropagation();
    });
  })

  if (ROOT === location.pathname) {
    return (
      <Redirect
        to={{
          pathname: APP_ROOT_ROUTE,
        }}
      />
    );
  }

  if (!isAdmin && !isAdminBackdoor && (MAINTENANCE_TYPE.open !== maintenance) && !isContactOrStaticPage) {
    const isNormalMaintenance = MAINTENANCE_TYPE.normal === maintenance;
    const isEmergencyMaintenance = MAINTENANCE_TYPE.emergency === maintenance;
    const isTemporaryMaintenance = MAINTENANCE_TYPE.temporary === maintenance;

    return (
      <MaintenanceScreen
        isNormalMaintenance={isNormalMaintenance}
        isEmergencyMaintenance={isEmergencyMaintenance}
        isTemporaryMaintenance={isTemporaryMaintenance}
      />
    );
  }

  return (
    <SessionTimer>
      <AppHeader />
      <div id="user-parent" style={isIFrame ?  { marginTop: 44 } :  { marginTop: 93 } }>  {/**task#363**/}
        {/**Cookies Consent **/}
        <CookiesPrompt />

        {/**Main Screen Content**/}
        <Container>
          <Row className={"user-main-container"}>
            <Col md={(isMobile || isIFrame) ? "12" : "9"} sm="12" className="user-module-container"> {/**task#363**/}
              <ModuleContent location={location} userId={userId} />
            </Col>

            <PCOnlyView>
              <Col md="3" sm="12" style={{ cursor: "default" }}>
                <PCSideNav user={user} profile={profile} />
              </Col>
            </PCOnlyView>
          </Row>
        </Container>
      </div>
    </SessionTimer>
  );
});

export default withRouter(MainContainer);

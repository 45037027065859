import React, { Component } from 'react';
import { friendsListOperations } from './state';
import { View } from "react-native";
import { bindActionCreators } from "redux";
import { EMPTY_STRING, THUMBNAIL_SMALL, PROFILE_THUMBNAIL_BUCKET_URL } from "../../../utils/constants";
import UserListItemComponent from "../../components/UserListItemComponent";
import ErrorModal from "../../components/ErrorModal";
import { MSG_SYSTEM_ERROR } from "../../../utils/messages";
import { onApiSuccess, appendUserRoute, addDefaultSrc, defaultTopScroll } from '../../../utils/common';
import { connect } from "react-redux";
import "./styles/styles.scss"
import styles from "./styles/styles.native"
import UserListSkeleton from "../../components/Skeletons/UserListSkeleton";
import { ListItem } from "react-native-elements";
import { isMobile } from "react-device-detect";
import { Media } from 'react-breakpoints'
import User404 from '../Errors/User404';
import { ActivityIndicator } from 'react-native';

class FriendsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
          userId: parseInt(props.match.params.userId),
          userHandleName: props.handleName,
          userImg: props.profileImageUrl,
          currentPagination: 1,
          friendsList: [],
          numberOfFriends: 0,
          isErrorModalShow: false,
          errorMessage: EMPTY_STRING,
          isLoading: true,
          isUserBlocked: false,
          isBlockFetching: true,
          hash: Date.now(),
          isLastPage: false
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            let pathSplit = this.props.location.pathname.split("/");
            let userId = pathSplit[pathSplit.length-1];
            
            this.setState({
                isLoading: true,
                isUserBlocked: false,
                userHandleName: EMPTY_STRING,
                currentPagination: 1,
                userId
            }, ()=>{
                //Check if user is blocked
                this.checkIsUserBlocked()
                //Get user handlename
                this.getUserHandleName()
                //Get all friends
                this.handleGetAllFriends()
            })

        }
    }

    checkIsUserBlocked = () => {
        this.props.isUserBlocked(this.props.userId, this.state.userId).then(response=>{
            if(onApiSuccess(response)){
                let result = response.data.data;
                if(result.is_blocked){
                    this.setState({
                        isUserBlocked: true,
                        isLoading: false,
                        isBlockFetching: false
                    })
                }else{
                    this.setState({
                        isBlockFetching: false
                    })
                }
            }else{
                this.setState({
                    isErrorModalShow: true,
                    errorMessage: MSG_SYSTEM_ERROR,
                    isLoading: false
                })
            }
        })
    }

    getUserHandleName = () => {
        this.props.getUserHandleName(this.state.userId).then(response=>{
            if(onApiSuccess(response)){
                let result = JSON.parse(response.data.data);
               this.setState({
                   userHandleName: result[0].handleName
               })
            }else{
                this.setState({
                    isErrorModalShow: true,
                    errorMessage: MSG_SYSTEM_ERROR
                })
            }
        })
    }

    componentWillMount(){
        //Check if user is blocked
        this.checkIsUserBlocked()
        //Get user handlename
        this.getUserHandleName()
    }

    componentDidMount(){
        defaultTopScroll();
        //Get all friends
        this.handleGetAllFriends()
    }

    handleGetAllFriends = () => {
        this.props.getAllFriends(this.state.userId, this.state.currentPagination).then(response=>{
            if(onApiSuccess(response)){
                let friendsList = JSON.parse(response.data.data)
                this.setState({
                    friendsList 
                },()=>{
                    this.props.countAllFriends(this.state.userId).then(response=>{
                        if(onApiSuccess(response)){
                            let numberOfFriends = response.data.data
                            this.setState({
                                numberOfFriends,
                                isLoading: false
                            })
                        }else{
                            this.setState({
                                isErrorModalShow: true,
                                errorMessage: MSG_SYSTEM_ERROR
                            })
                        }
                    })
                })
            }else{
                this.setState({
                    isErrorModalShow: true,
                    errorMessage: MSG_SYSTEM_ERROR
                })
            }
        })
    }

    handleSelectUser = (item) => {
        let userRoute = appendUserRoute(`/user/${item.userId}`)
        this.props.history.push({
            pathname: userRoute
        });
    }

    handlePagination = () => {
        if(!this.state.isLastPage){
            this.setState({
                currentPagination: this.state.currentPagination + 1
            }, ()=>{
                this.props.getAllFriends(this.state.userId, this.state.currentPagination).then(response=>{
                    if(onApiSuccess(response)){
                        let friendsList = JSON.parse(response.data.data)
                        if(0 < friendsList.length){
                            this.setState({
                                friendsList : [...this.state.friendsList, ...friendsList]
                            })
                        }else{
                            this.setState({
                                isLastPage: true
                            })
                        }
                    }else{
                        this.setState({
                            isErrorModalShow: true,
                            errorMessage: MSG_SYSTEM_ERROR
                        })
                    }
                })
            })
        }
    }

    handleCloseErrorModal = () => {
        this.setState({
          isErrorModalShow : false
        })
    }
    

    render() {
        if(this.state.isUserBlocked){
            return(
                <User404/>
            )
        }
        else if(this.state.isBlockFetching){
            return (
                <center>
                    <ActivityIndicator
                        style={{
                            marginBottom: 20,
                            height: 50,
                            display: "block"
                        }}
                    />
                </center>
            )
        }else{
            return (
                <Media>
                    {({ breakpoints, currentBreakpoint }) => {
                        let isMobileView = (breakpoints[currentBreakpoint] < breakpoints.mobileLandscape) || isMobile
                        return(
                            <div className="user-container">
                           <View style={isMobileView ? styles.listItemContainerMobile : styles.listItemContainer}>
                            <React.Fragment>
                            <ListItem
                                containerStyle={[styles.listItem, {height: isMobileView ? 52 : 55}]}
                                title={this.state.userHandleName}
                                titleStyle={isMobileView ? styles.titleMobile : styles.title}
                                leftElement={
                                    <img className="header-avatar" alt="user-avatar" src={PROFILE_THUMBNAIL_BUCKET_URL + this.state.userId + "/" + this.state.userId + THUMBNAIL_SMALL + `?${this.state.hash}`} onError={addDefaultSrc} />
                                }   
                            />
                                <View style={styles.horizontalBorder} />
                                {!this.state.isLoading && <p className="number-count">友達 {this.state.numberOfFriends}人</p>}
                       
                            </React.Fragment>
                            </View> 
                                {
                                    !this.state.isLoading ? 
                                    <View style={styles.flatlistContainer}>
                                        <UserListItemComponent
                                            {...this.state}
                                            list={this.state.friendsList}
                                            isCommunity={false}
                                            isUserList={true}
                                            handlePagination={this.handlePagination}
                                            handleSelectItem={this.handleSelectUser}
                                            hash={this.state.hash}
                                        /> 
                                    </View>
                                    : <UserListSkeleton/>
                                }
                  
                    
                            <ErrorModal
                              {...this.state}
                              handleCloseModal={this.handleCloseErrorModal}
                              customContent={null}
                              errorMessage={this.state.errorMessage}
                            />
                          </div>
                        )
                    }}
                </Media>
            );
        }
}
}

//Bind dispatch to action creators
const mapStateToProps = state => {
    return {
        userId: state.auth.credentials.userId,
        handleName: state.editprofile.profileDetails.handleName,
        profileImageUrl: state.auth.credentials.profileImageUrl,
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getAllFriends: friendsListOperations.getAllFriends,
            countAllFriends: friendsListOperations.countAllFriends,
            isUserBlocked: friendsListOperations.isUserBlocked,
            getUserHandleName: friendsListOperations.getUserHandleName
        },
          dispatch
    );
      
export default connect(mapStateToProps, mapDispatchToProps)(FriendsList);

import * as Actions from "./actions";
import Auth from '@aws-amplify/auth';
import awsSettings from "../../../../config/awsSettings";
import * as Settings from "../../../../config/settings";
import { GET_NEW_NOTIF_COUNT } from "../../Notifications/state/apiRoutes";
import { GET_MAINTENANCE_SETTINGS, GET_UNSEEN_THREADS } from './apiRoutes';
import apiService from "../../../../utils/apiService";
import { onApiSuccess, isStringNullEmptyOrUndefined, objKeyExist, isFumiyaOrFFStaffUserCheck, checkCookieExist, setCookie, setCognitoUserDetails } from "../../../../utils/common";
import { INT_NOT_FOUND, USER_TYPE } from "../../../../utils/constants";

/**User Verifcation */
const authenticateMember = (email, password) => (dispatch) => {
    //Cognito Login Process
    Auth.configure(awsSettings.cognito);
    return Auth.signIn(email, password)
        .then((user) => {
            //Check user roles or Fumiya and if comux2 ID exists
            if (
                (objKeyExist(user.attributes, "preferred_username") && !isStringNullEmptyOrUndefined(user.attributes.preferred_username))
                && (
                    user.signInUserSession.idToken.payload["cognito:groups"][0] === Settings.ROLE_USER
                    || isFumiyaOrFFStaffUserCheck(parseInt(user.attributes.preferred_username))
                )
            ) {
                //Save token on Cookie if cookie exist
                if(checkCookieExist(Settings.COMUX2_COOKIE_CONSENT)){
                    setCookie(Settings.COMUX2_COOKIE_CONSENT, user.signInUserSession.idToken.jwtToken);
                }

                //Get user profile from DB
                dispatch(Actions.setMemberAuthenticated(setCognitoUserDetails(user), true));

                //Set initial session expiry
                let newExpiry = new Date();
                newExpiry.setHours(newExpiry.getHours() + 2); //AWS websocket timeout
                localStorage.setItem("session_expiry", newExpiry.getTime())

                return parseInt(user.attributes.preferred_username);
            }
            else {
                dispatch(Actions.setMemberAuthenticated([], false));
                Auth.signOut();
                return INT_NOT_FOUND;
            }

        })
        .catch((error) => {
            window.location.replace("/logout");
            return error;
        });
};

/**User attributes check for Comux2 ID */
const memberComux2IdCheck = () => () =>{
    Auth.configure(awsSettings.cognito);
    Auth.currentUserInfo()
        .then(res => {
            if((objKeyExist(res.attributes, "preferred_username") && !isStringNullEmptyOrUndefined(res.attributes.preferred_username))){
                console.log("Account valid")
            }else{
                //Logout and redirect to FFMWeb Login
                Auth.signOut();
                window.location.replace(Settings.FFMWEB_LOGIN_URL);
            }
        })
        .catch(err => {
            console.error(err);
        });
}


/**Administrator Login */
const loginAdmin = (username, password) => (dispatch) => {

    //Cognito Login Process
    Auth.configure(awsSettings.cognito);
    return Auth.signIn(username, password)
        .then((user) => {
            if (user.signInUserSession.idToken.payload["cognito:groups"][0] === Settings.ROLE_ADMIN ||
                user.signInUserSession.idToken.payload["cognito:groups"][0] === Settings.ROLE_STAFF) {

                dispatch(Actions.setAdminAuthenticated(setCognitoUserDetails(user), true));
                return true;
            }
            else {
                Auth.signOut();
                return INT_NOT_FOUND;
            }
        })
        .catch((error) => {
            return error;
        });
};

const refreshTokens = (payload) =>  (dispatch) => {
    try {
        dispatch(Actions.refreshTokens(payload));
    } catch (e) {
        console.log('Unable to refresh Token', e);
        
        let isAdmin = false
        if(USER_TYPE.admin === payload.userType){
            isAdmin = true
        }
        logoutUser(isAdmin)
    }
    return;
}

/**Logout for user */
const logoutUser = (admin, comuOnly) => (dispatch) => {
    Auth.configure(awsSettings.cognito);
    Auth.signOut();
    dispatch(Actions.setAdminAuthenticated([], false));
    dispatch(Actions.setMemberAuthenticated([], false));
    if(!admin){
        window.location.replace(Settings.FFMWEB_LOGIN_URL);
    }
};

const connectWS = () => (dispatch) => {
    dispatch(Actions.setWSConnect());
}

const resetUser = (id) => (dispatch) => {
    dispatch(Actions.resetUser(id));
}

const getNewNotifCount = (userId) => dispatch => {
    apiService.get(GET_NEW_NOTIF_COUNT + `/${userId}`)
        .then(response => {
            if (onApiSuccess(response)) {
                let data = JSON.parse(response.data.data);
                dispatch(Actions.updateNotifCount(data.newNotifCount, data.newNotifReferences))
            }
        })

}

const getUnseenThreads = (userId) => dispatch => {
     apiService.get(GET_UNSEEN_THREADS + `/${userId}`)
        .then(response => {
            if (onApiSuccess(response)) {
                let data = JSON.parse(response.data.data);
                dispatch(Actions.updateChatThreadCount(data.count, data.chatThreads))
            }
        })
}

const incrementNotifCount = (referenceId) => dispatch => {
    dispatch(Actions.incrementNotifCount(referenceId))
}

const incrementChatThreadCount = (senderId, threadId) => dispatch => {
    dispatch(Actions.incrementChatThreadCount(senderId, threadId))
}

const clearNewNotifReferences = () => dispatch => {
    dispatch(Actions.clearNewNotifReferences())
}

const checkMaintenance = () => dispatch => {
    apiService.get(GET_MAINTENANCE_SETTINGS)
        .then(response => {
            if (onApiSuccess(response)) {
                let responseData = JSON.parse(response.data.data);
                let payload = parseInt(responseData[0].maintenanceType);
                dispatch(Actions.setMaintenance(payload));
            }
        })
}

const updateProfileImage = (userId) => dispatch => {
    const profile = `Profile/${userId}/${userId}`;
    dispatch(Actions.setProfileImage(profile));
}

const setAdminBackdoorCheck = (params) => dispatch =>{
    return Promise.resolve(dispatch(Actions.setAdminBackdoorCheck(params)));
}

export {
    loginAdmin,
    logoutUser,
    connectWS,
    resetUser,
    getNewNotifCount,
    incrementNotifCount,
    clearNewNotifReferences,
    authenticateMember,
    memberComux2IdCheck,
    checkMaintenance,
    refreshTokens,
    updateProfileImage,
    setAdminBackdoorCheck,
    getUnseenThreads,
    incrementChatThreadCount
};
import * as CommonSelectors from '../../../components/Validations/CommonSelectors';
import * as ErrorMessages from '../../../../utils/messages/admin/errorMessages';

const validateLogin = (values) => {
    let errors = {};
    
    if(!values.username){
        errors.email = ErrorMessages.MSG001_EMPTY_EMAIL;
    }

    else if(!CommonSelectors.isCorrectEmail(values.username)){
        errors.email = ErrorMessages.MSG002_INVALID_EMAIL;
    }

    if(!values.password){
        errors.password = ErrorMessages.MSG003_EMPTY_PASSWORD;
    }

    else if(!CommonSelectors.isAlphanumeric(values.password)){
        errors.password = ErrorMessages.MSG004_NON_APLHANUM_PASSWORD;
    }
    
    return errors;
}

export default {
    validateLogin
}
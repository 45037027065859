import {USER_ROOT_API_URL} from "../../../../config/settings.js"

export const GET_POST = USER_ROOT_API_URL + "/get_post";
export const GET_POST_PAGINATED_COMMENTS = USER_ROOT_API_URL + "/get_more_comments";

export const GET_PAST_POST = USER_ROOT_API_URL + "/get_past_post";
export const GET_POST_PAGINATED_PAST_COMMENTS = USER_ROOT_API_URL + "/get_more_past_comments";

export const GET_COMMUNITY_POST = USER_ROOT_API_URL + "/view_specific_post";
export const GET_COMMUNITY_POST_PAGINATED_COMMENTS = USER_ROOT_API_URL + "/get_more_community_comments";

export const CREATE_POST = USER_ROOT_API_URL + "/create_post";
export const EDIT_POST = USER_ROOT_API_URL + "/edit_post";
export const DELETE_POST = USER_ROOT_API_URL + "/delete_post";

export const CREATE_COMMENT = USER_ROOT_API_URL + "/create_comment";
export const EDIT_COMMENT = USER_ROOT_API_URL + "/edit_comment";
export const DELETE_COMMENT = USER_ROOT_API_URL + "/delete_comment";

export const CREATE_REPLY = USER_ROOT_API_URL + "/create_reply";
export const EDIT_REPLY = USER_ROOT_API_URL + "/edit_reply";
export const DELETE_REPLY = USER_ROOT_API_URL + "/delete_reply";

export const REQUEST_REUPLOAD = USER_ROOT_API_URL + "/request_reupload";

export const CREATE_LIVESTREAM = USER_ROOT_API_URL + "/create_livestream";
export const EDIT_LIVESTREAM = USER_ROOT_API_URL + "/edit_livestream";

export const VIMEO_CHECK = "https://api.vimeo.com/me/videos";
import React from 'react';
import { Pagination, Button } from 'react-bootstrap';
import './styles.scss';

const PaginationTemplate = ({currentPage, totalPage, handlePageChange, batch=5}) => {

    let firstPrev = 1 === currentPage || 0 === totalPage
    let nextLast = currentPage === totalPage || 0 === totalPage
    let items = [];
    let startNum = 0;
    let endNum = 0;

    for (let number = 1; number <= totalPage; number++) {
        items.push(
            <Pagination.Item key={number} active={number === currentPage} onClick={() => {handlePageChange(number)}}>
                {number}
            </Pagination.Item>
        );
    }

    if(1 === currentPage) {
        startNum = currentPage - 1;
        endNum = (currentPage + batch) - 1;
    } 
    else if(2 === currentPage) {
        startNum = currentPage - 2;
        endNum = (currentPage + batch) - 2;
    } 
    else if(3 === currentPage) {
        startNum = currentPage - 3;
        endNum = (currentPage + batch) - 3;
    } 
    else if(totalPage - 2 === currentPage) {
        startNum = (currentPage - batch) + 2;
        endNum = currentPage + 3;
    } 
    else if(totalPage - 1 === currentPage) {
        startNum = (currentPage - batch) + 1;
        endNum = currentPage + 1;
    } 
    else if(totalPage === currentPage) {
        startNum = (currentPage - batch);
        endNum = currentPage;
    } 
    else {
        startNum = currentPage - 3;
        endNum = currentPage + 2;
    }

    items = items.slice(startNum, endNum);

    return (
        
        <div>
            <Pagination style={{ float: 'right' }}>
                <Button className="pagination-caret" variant="link" disabled={firstPrev} onClick={() => { handlePageChange(1) }}>{"<<"}</Button>
                <Button className="pagination-caret" variant="link" disabled={firstPrev} onClick={() => { handlePageChange(currentPage-1) }}>{"<"}</Button>
                {items}
                <Button className="pagination-caret" variant="link" disabled={nextLast} onClick={() => { handlePageChange(currentPage+1) }}>{">"}</Button>
                <Button className="pagination-caret" variant="link" disabled={nextLast} onClick={() => { handlePageChange(totalPage) }}>{">>"}</Button>
            </Pagination>
        </div>
    )
}

export default PaginationTemplate;
import reducer from "./reducers";
import { routes } from "../routes";
import * as friendsListOperations from "./operations"

export {friendsListOperations}

export default {
    reducer,
    routes
};

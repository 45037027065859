import React from 'react';
import { FormLabel, FormGroup, Button, ButtonToolbar, Col, Row} from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize'
import { Formik, Form, Field } from 'formik';
import { isNullOrUndefined } from '../../../../node_modules/util';
import './styles/template.scss';
import '../../containers/Chat/styles/radio.scss';
import { CUSTOM_ADDRESS_NOT_ALLOWED_REGEX } from '../../../utils/constants';

const ProfileFormTemplate = (props) => {

    //Initial values
    let initialValues = {};

    let modelValues = {};
    props.formInputs.form.map((input) => {
        let propertyExist = !isNullOrUndefined(props.initialValues[props.formInputs.model][input.name]);

        if ("custom-row" === input.type) {
            input.children.map(child => {
                if("child-row" === child.type){
                    //initiate subchild value
                    child.children.map(subChild =>{
                        //Subchild
                        let subChildPropertyExist = !isNullOrUndefined(props.initialValues[props.formInputs.model][subChild.name]);
                        let value = subChildPropertyExist ? props.initialValues[props.formInputs.model][subChild.name] : subChild.default;
                        modelValues[subChild.name] = value;
                        return ""
                    })
                    
                }else{
                    //Normal child field
                    let childPropertyExist = !isNullOrUndefined(props.initialValues[props.formInputs.model][child.name]);
                    let value = childPropertyExist ? props.initialValues[props.formInputs.model][child.name] : child.default;
                    modelValues[child.name] = value;
                }
                
                return "";
            })
        }
        else {
            let value = propertyExist ? props.initialValues[props.formInputs.model][input.name] : input.default;
            modelValues[input.name] = value;
        }
        return "";
    })
    initialValues[props.formInputs.model] = modelValues;

    let defaultClass = "form-input";

    function RenderInput(values, model, input, fieldValue, setFieldValue, errors, touched) {
        let modelField = `${model}.${input.name}`;
        let modelTouch = touched[model];
        let isTouched = undefined !== touched[model] && modelTouch[input.name];
        let isError = undefined !== errors[modelField];
        let displayError = isTouched && isError;

        if('ProfileModel.birthdate' === modelField) {
            if(undefined !== touched[model] && (modelTouch["birthMonth"] || modelTouch["birthDay"])) {
                isTouched = true
                displayError = isTouched && isError;
            }
        }

        switch (input.type) {
            //renders radio button group field
            case "radio": {
                return (
                    <FormGroup key={input.name} className={defaultClass+  " mt-0 mb-0 radio-form"}>
                        <FormLabel >{input.label}</FormLabel >
                        {input.inline && <br />}

                        {props.radioInput.map((radio, index) => {
                            if (radio.name === input.name) {
                                return (
                                    
                                    <label className="label-container mt-1 mb-3">
                                        <input
                                            className="radio-btn-form"
                                            id={input.name + index}
                                            type="radio"
                                            value={radio.value}
                                            key={index}
                                            name={modelField}
                                            inline={input.inline}
                                            onChange={event => setFieldValue(modelField, event.target.value)}
                                            checked={!isNullOrUndefined(fieldValue)  && (fieldValue.toString() === radio.value.toString())}
                                        />
                                        <span className="checkmark"></span>
                                        <span className="radio-label">{radio.label}</span>
                                    </label>
                                );
                            }

                            return null;
                        })}
                        {CustomError(errors[modelField], displayError, input.hideError)}
                    </FormGroup>
                )
            }
            
            // renders select field
            case "select": {
                return (
                    <FormGroup key={input.name} className={defaultClass}>
                        <FormLabel >{input.label}</FormLabel >
                        {CustomError(errors[modelField], displayError, input.hideError)}

                        <Field
                            className={`form-control contact-field`}
                            component="select"
                            name={modelField}
                        >
                            <option disabled value="">{input.placeholder}</option>
                            {props.selectInput && props.selectInput.filter((option) => option.name === input.name).map((item, i) => {
                                return (
                                    <option key={i} value={item.value}> {item.label} </option>
                                );
                            })}
                        </Field>
                    </FormGroup>
                );
            }

            case "privacy-select": {
                return (
                    <FormGroup key={input.name} className={defaultClass}>
                        <Field
                            className={`form-control contact-field`}
                            style={{width: 150}}
                            component="select"
                            name={modelField}
                        >
                            <option value={0}>{"全体に公開"}</option>
                            <option value={1}>{"友達まで公開"}</option>
                            <option value={2}>{"非公開"}</option>
                        </Field>
                    </FormGroup>
                );
            }

            case "custom-select-year": {
                const selectOptions = []
                let currentYear = parseInt((new Date()).getFullYear());
                
                selectOptions.push(
                    <option key={modelField + "empty"} value={""}>{" "}</option>
                )
                for (var i = currentYear - 80; i <= currentYear-1; i++) {
                    selectOptions.push(
                        <option key={modelField + i} value={i}>{i}</option>
                    )
                }

                return (
                    <React.Fragment>
                        <FormGroup key={input.name} className={defaultClass + " profile-birthday-select mb-0"}>
                            <Field
                                className={`form-control contact-field`}
                                style={{width: input.width}}
                                component="select"
                                name={modelField}
                            >
                                {selectOptions}
                            </Field> {input.suffix}
                        </FormGroup>
                        {CustomError(errors[modelField], displayError, input.hideError)}
                    </React.Fragment>
                );
            }

            case "custom-select-inline": {
                const selectOptions = []
                selectOptions.push(
                    <option key={modelField + "empty"} value={""}>{" "}</option>
                )
                for (var selectIndex = input.start; selectIndex <= input.end; selectIndex++) {
                    let selectIndexDisplay = selectIndex
                    if(selectIndex.toString().length < 2)
                        selectIndexDisplay= "0" + selectIndex;
                    selectOptions.push(
                        <option key={modelField + selectIndex} value={selectIndexDisplay}>{selectIndexDisplay}</option> 
                    )
                }

                return (
                    <FormGroup key={input.name} className={defaultClass + " mr-4 profile-select-birthdate " + input.customClass}>
                        <Col md={10} className="pr-0 pl-0 mr-2">
                            <Field
                                className={`form-control contact-field`}
                                style={{ width: input.width }}
                                component="select"
                                name={modelField}
                            >
                                {selectOptions}
                            </Field>
                        </Col>
                        <FormLabel md={2}>{input.suffix}</FormLabel >
                    </FormGroup>
                );
            }

            case "custom-select-address": {
                return (
                    <FormGroup key={input.name} className={defaultClass +  `profile-birthday-select ${input.smallMargin ? "mb-1" : "mb-3"}`}>
                        <Field
                            className={`form-control contact-field`}
                            style={{width: input.width}}
                            component="select"
                            name={modelField}
                        >
                            {props.prefectureOptions.map(option => {
                                if(input.name === option.name) {
                                    return (
                                        <option key={modelField + option.value} value={option.value}>{option.label}</option>
                                    )
                                }else{
                                    return ''
                                }
                            })}
                        </Field>
                    </FormGroup>
                );
            }

            case "custom-select-hobby": {
                const hobbyOptions = props.hobbyOptionsList[input.name]

                return (
                    <FormGroup key={input.name} className={defaultClass + " profile-birthday-select mb-3"}>
                        <Field
                            className={`form-control ${input.customClass} contact-field`}
                            component="select"
                            name={modelField}
                            onChange={event => props.handleHobbySelectChange(event.target.value, input.name, setFieldValue, modelField)}
                        >
                            <option value={"1"}>{"なし"}</option>
                            {hobbyOptions.map(option => {
                                return (
                                    <option key={modelField + option.value} value={option.value}>{option.label}</option>
                                )
                            })}
                        </Field>
                    </FormGroup>
                );
            }

            case 'custom-row': {
                return (
                    <FormGroup key={modelField} className={defaultClass + " profile-input pb-0"}>
                        <FormLabel column md="3" className="row-label pl-0">{input.label}</FormLabel>
                        {CustomError(errors[modelField], displayError, input.hideError)}
                        <div md={9} className="profile-text-flex">
                            {input.children.map(child => RenderInput(values, model, child, values[model][child.name], setFieldValue, errors, touched))}
                        </div>
                    </FormGroup>
                )
            }

            case 'child-row': {
                return (
                    <div key={modelField} >
                        <Row className="mr-0 ml-0">
                            {input.children.map(child => {
                                return (RenderInput(values, model, child, values[model][child.name], setFieldValue, errors, touched))
                            })}
                        </Row>
                        {CustomError(errors[modelField], displayError, input.hideError)}
                    </div>
                )
            }

            case 'custom-text': {
                return (
                    <FormGroup key={modelField} className={`${defaultClass} ${input.customClass} profile-text-align-flex-start`}>
                        {input.label && <FormLabel md={input.labelWidth} className="mr-2 col-form-label row-label">{input.label}</FormLabel >}

                        <Col sm={input.smallWidth} md={input.fieldWidth} className="pr-1 pl-0">
                            <Field
                                className={`form-control contact-field`}
                                placeholder={input.placeholder}
                                component={input.component}
                                type={"text"}
                                name={modelField}
                                maxLength={input.maxLength}
                                minLength={input.minLength}
                                min={input.min}
                                max={input.max}
                                onChange={event => {
                                    let newValue = event.target.value;
                                    if(input.addressRegex){
                                        if(CUSTOM_ADDRESS_NOT_ALLOWED_REGEX.test(newValue) || "" === newValue){
                                            setFieldValue(modelField, newValue)
                                        }
                                    }else {
                                        setFieldValue(modelField, newValue)
                                    }
                                }}
                            />
                            {CustomError(errors[modelField], displayError, input.hideError)}
                        </Col>
                    </FormGroup>
                );
            }

            case 'custom-text-inline': {
                return (
                    <Col xs={input.groupWidth} sm={input.groupWidth} md={input.groupWidth} key={modelField} className={`${defaultClass} ${input.customClass} profile-text-align-flex-start p-0`}>
                        <FormLabel className="mr-2 col-form-label row-label">{input.label}</FormLabel >

                        <Col sm={input.fieldWidth} className="pr-1 pl-0">
                            <Field
                                className={`form-control contact-field`}
                                placeholder={input.placeholder}
                                component={input.component}
                                type={"text"}
                                name={modelField}
                                maxLength={input.maxLength}
                                minLength={input.minLength}
                                min={input.min}
                                max={input.max}
                                onChange={event => setFieldValue(modelField, event.target.value)}
                            />
                            {CustomError(errors[modelField], displayError, input.hideError)}
                        </Col>
                    </Col>
                );
            }

            case 'textarea': {
                return (
                    <FormGroup as={Row} key={modelField} className={"profile-input-last ml-0 mr-0"}>
                        <FormLabel className="pl-0" column md={3}>{input.label}</FormLabel >

                        <Col md={9} className="pl-0 pr-1">
                            <TextareaAutosize
                                name={modelField}
                                className={'form-control contact-field'}
                                minRows={parseInt(input.rows)}
                                onChange={event => setFieldValue(modelField, event.target.value)}
                                value={values["ProfileModel"]["bio"]}
                                // placeholder={"日記を投稿しよう"}
                            />
                            {CustomError(errors[modelField], displayError, input.hideError)}
                        </Col>
                    </FormGroup>
                );
            }

            //renders text, email, password, numbers, default input
            default: {
                return (
                    <FormGroup as={Row} key={modelField} className={`ml-0 mr-0 ${input.customClass}`}>
                        <FormLabel column md={3} className="pl-0">{input.label}</FormLabel >

                        <Col md={9} className="pl-0 pr-1">
                            <Field
                                className={`form-control contact-field`}
                                placeholder={input.placeholder}
                                component={input.component}
                                type={input.type}
                                name={modelField}
                                maxLength={input.maxLength}
                                minLength={input.minLength}
                                min={input.min}
                                max={input.max}
                                onChange={event => setFieldValue(modelField, event.target.value)}
                            />
                            {CustomError(errors[modelField], displayError, input.hideError)}
                        </Col>
                    </FormGroup>
                );
            }
        }

    }

    //Error messages
    const CustomError = (error, displayError, hideError) => {
        return hideError ? null : <p className="formik-error">{displayError ? error : ""}</p>;
    }

    //Renders form buttons
    const FormButtons = props.formButtons && props.formButtons.map((button, i) => {
        return (
            <Button type={button.submit ? "submit" : "button"} key={i} className={"submit-btn profile-template-button-" + button.type} variant={button.variant} size={button.size} onClick={button.onClick}>
                {button.label}
            </Button>
        );
    });

    //Maps form inputs
    const FormInputs = (values, setFieldValue, errors, touched) => props.formInputs.form && props.formInputs.form.map((input) => {
        return RenderInput(values, props.formInputs.model, input, values[props.formInputs.model][input.name], setFieldValue, errors, touched)
    });

    if ((!isNullOrUndefined(props.initialValues) && props.editMode) || !props.editMode) {
        return (
            <Formik
                enableReinitialize
                initialValues={initialValues}
                className={props.className}
                validate={values => {
                    props.handleHasUpdates();
                    return props.validate(values);
                }}
                onSubmit={(values) => {
                    props.handleSubmit(values)
                }}
            >
                {({ values, setFieldValue, errors, touched }) => (
                    <Form>
                        {FormInputs(values, setFieldValue, errors, touched)}
                        <ButtonToolbar className="justify-content-center">
                            {FormButtons}
                        </ButtonToolbar>
                    </Form>
                )}
            </Formik>
        );
    }else{
        return <p>Loading . . .</p>;
    }
}

export default ProfileFormTemplate;
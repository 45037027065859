import React, { useState, useLayoutEffect } from "react";
import CalendarIcon from "react-calendar-icon";
import { ThemeProvider } from "styled-components";
import { TouchableOpacity, Image } from "react-native";
import CalendarPlainIcon from "../../../includes/img/icons/calendar.svg";
import { appendUserRoute } from "../../../utils/common";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import {
    DatePicker,
    MuiPickersUtilsProvider,
  } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import CustomModal from "../../components/CustomModal";
import CloseIcon from "../../../includes/img/icons/close.svg";
import styles from './styles/styles.native';
import "./styles/calendar.css"; 
import format from "date-fns/format";
import jaLocale from "date-fns/locale/ja";

const theme = {
  calendarIcon: {
    textColor: "white", // text color of the header and footer
    primaryColor: "#0A182C", // background of the header and footer
    backgroundColor: "#fafafa",
  },
};


class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, "MMM d日 (EEEEE)", { locale: this.locale });
  }
}

const CalendarIconFilters = (props) => {

  const [showPicker, setShowPicker] = useState(false);
  const [calendarDate, setCalendarDate] = useState(new Date());

  useLayoutEffect(()=>{
    for (var i = 0; i < 4; i++) {
      let calendarElements = document.getElementsByClassName("sc-hKFyIo")[i];
      calendarElements.innerHTML = (calendarElements.innerHTML).replace('日', '');
    }
    
  })

  let arrRange = [];

  for (var i = 0; i < 4; i++) {
    arrRange.push(i);
  }

  const redirectFilterDate = (selectedDate) => {
    props.history.push(
      appendUserRoute(
          `/search/post/filter?date=${encodeURIComponent(format(selectedDate,"yyyy/MM/dd"))}`
      )
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="calendar-group">
        {/* Specific dates */}
        {arrRange.map((num, index) => {
          let currDate = new Date();
          if (0 !== index) {
            currDate.setDate(currDate.getDate() - index);
          }

          return (
            <TouchableOpacity 
                key={currDate}
                className="ml-2 mr-2 mb-2"
                activeOpacity={0.7}
                onPress={()=>redirectFilterDate(currDate)}
            >
              <CalendarIcon
                date={currDate}
                options={{
                  header: { weekday: "short" },
                  footer: { month: "short" },
                  value: { day: "2-digit" },
                  locale: 'ja'
                }}
              />
            </TouchableOpacity>
          );
        })}
        {/* Calendar Filter */}
        <TouchableOpacity 
            className="ml-2 mr-2"
            activeOpacity={0.7}
            onPress={()=>setShowPicker(true)}
        >
          <img src={CalendarPlainIcon} alt="calendar-plain-filter-icon" className="calendar-plain-filter"/>
        </TouchableOpacity>

        {/* Calendar Modal */}
        <CustomModal 
          showModal={showPicker}
          handleCloseModal={()=>setShowPicker(false)}
        >
            <MuiPickersUtilsProvider utils={LocalizedUtils} locale={jaLocale}>
                <DatePicker 
                    minDate={moment("2020-09-09", "YYYY-MM-DD")}
                    variant="static" 
                    label
                    value={calendarDate}
                    onChange={(date)=>{
                      if(format(date, "yyyy/MM/dd") !== format(calendarDate, "yyyy/MM/dd") && format(date, "MM/dd") === format(calendarDate, "MM/dd")){
                        setCalendarDate(date);
                      }else{
                        setCalendarDate(date);
                        setShowPicker(false);
                        redirectFilterDate(date);
                      }
                    }} 
                />
                <TouchableOpacity
                  style={styles.modalClose}
                  onPress={() => setShowPicker(false)}
                >
                  <Image source={CloseIcon} alt="close-icon" style={styles.closeIcon} />
                </TouchableOpacity>
            </MuiPickersUtilsProvider>
        </CustomModal>
      </div>
    </ThemeProvider>
  );
};

export default withRouter(CalendarIconFilters);

import {ADMIN_ROOT_API_URL} from "../../../../config/settings.js"

export const CREATE_RANDOM_MESSAGE = ADMIN_ROOT_API_URL +  "/create_random_message";

export const GET_RANDOM_MESSAGE = ADMIN_ROOT_API_URL +  "/get_random_message";

export const GET_ALL_RANDOM_MESSAGES = ADMIN_ROOT_API_URL +  "/get_all_random_messages";

export const GET_ALL_RECIPIENTS = ADMIN_ROOT_API_URL +  "/get_all_recipients";

export const UPDATE_RANDOM_MESSAGE = ADMIN_ROOT_API_URL +  "/update_random_message";

export const DELETE_RANDOM_MESSAGE = ADMIN_ROOT_API_URL +  "/delete_random_message";
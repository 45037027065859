import * as CommonSelectors from '../../../components/Validations/CommonSelectors';
import * as ErrorMessages from '../../../../utils/messages/admin/errorMessages';
import * as CommonValidation from '../../../../utils/common';
import moment from 'moment';

const validateRandomMessage = (values) => {
    let errors = {};

    /** 
     *  No delivery type is selected
    */

    if(CommonValidation.isStringNullEmptyOrUndefined(values.RandomMessageModel.type)){
        errors[`RandomMessageModel.topErrorMessage`] = ErrorMessages.MSG044_NO_DELIVERY_TYPE;
    }

    if(4 > values.RandomMessageModel.deliveryYear.length){
        errors[`RandomMessageModel.topErrorMessage`] = ErrorMessages.MSG062_INVALID_DELIVERY_DATE;
    }

    else if('1' === values.RandomMessageModel.type) {
        /**
         * Delivery date and time is required when delivery type is all members.
         */
        if(values.RandomMessageModel.hasOwnProperty('deliveryDate') 
            && CommonValidation.isStringNullEmptyOrUndefined(values.RandomMessageModel.deliveryDate)) {
                errors[`RandomMessageModel.topErrorMessage`] = ErrorMessages.MSG045_DELIVERY_DATE_AND_TIME_REQUIRED;
        }
        else if(CommonValidation.isStringNullEmptyOrUndefined(values.RandomMessageModel.deliveryYear)
            || CommonValidation.isStringNullEmptyOrUndefined(values.RandomMessageModel.deliveryMonth)
            || CommonValidation.isStringNullEmptyOrUndefined(values.RandomMessageModel.deliveryHour) 
            || CommonValidation.isStringNullEmptyOrUndefined(values.RandomMessageModel.deliveryMinute)) {
                errors[`RandomMessageModel.topErrorMessage`] = ErrorMessages.MSG045_DELIVERY_DATE_AND_TIME_REQUIRED;
            }
        else if(new Date(CommonValidation.formatDeliveryDate(values.RandomMessageModel)) < new Date()){
            /**
             * Delivery date should be in the future when delivery type is all members.
             */
            errors[`RandomMessageModel.topErrorMessage`] = ErrorMessages.MSG047_DELIVERY_DATE_SHOULD_BE_FUTURE;
        }

        else if((CommonSelectors.isThereNonDigit(values.RandomMessageModel.deliveryYear) 
                || CommonSelectors.isThereNonDigit(values.RandomMessageModel.deliveryMonth) 
                || CommonSelectors.isThereNonDigit(values.RandomMessageModel.deliveryDay) 
                || CommonSelectors.isThereNonDigit(values.RandomMessageModel.deliveryHour) 
                || CommonSelectors.isThereNonDigit(values.RandomMessageModel.deliveryMinute))) {
            /**
             * Delivery Date is invalid
             */
            errors[`RandomMessageModel.topErrorMessage`] = ErrorMessages.MSG062_INVALID_DELIVERY_DATE;
        }

        else if(!CommonValidation.isStringNullEmptyOrUndefined(values.RandomMessageModel.deliveryYear)
        && !CommonValidation.isStringNullEmptyOrUndefined(values.RandomMessageModel.deliveryMonth)
        && !CommonValidation.isStringNullEmptyOrUndefined(values.RandomMessageModel.deliveryDay)){
            const validate_date = new Date(values.RandomMessageModel.deliveryYear,
                                        Number(values.RandomMessageModel.deliveryMonth) - 1, 
                                        values.RandomMessageModel.deliveryDay
                                    );
    
            if(validate_date.getFullYear() !== Number(values.RandomMessageModel.deliveryYear)
                || validate_date.getMonth() + 1 !== Number(values.RandomMessageModel.deliveryMonth)
                || validate_date.getDate() !== Number(values.RandomMessageModel.deliveryDay)){
                errors[`RandomMessageModel.topErrorMessage`] = ErrorMessages.MSG062_INVALID_DELIVERY_DATE;
            }
        }
    } 
    
    else if('0' === values.RandomMessageModel.type){
        if(CommonValidation.isStringNullEmptyOrUndefined(values.RandomMessageModel.deliveryYear)){
            /**
             * Delivery year is required when delivery type is random members.
             */
            errors[`RandomMessageModel.topErrorMessage`] = ErrorMessages.MSG046_DELIVERY_YEAR_REQUIRED;
        } 
        else if('0' === values.RandomMessageModel.type 
            && parseInt(values.RandomMessageModel.deliveryYear) < new Date().getFullYear() 
            && CommonValidation.isStringNullEmptyOrUndefined(values.RandomMessageModel.deliveryMonth)){
        /**
         * Delivery year should be current or future when delivery type is random members. (When only year is entered)
         */
        errors[`RandomMessageModel.topErrorMessage`] = ErrorMessages.MSG048_DELIVERY_YEAR_SHOULD_BE_CURRENT_OR_FUTURE;
    } 
    
    else if('0' === values.RandomMessageModel.type 
            && !CommonValidation.isStringNullEmptyOrUndefined(values.RandomMessageModel.deliveryMonth)
            && moment(`${values.RandomMessageModel.deliveryYear}-${CommonValidation.prependZero(values.RandomMessageModel.deliveryMonth)}`).format("YYYY/MM") < moment().format("YYYY/MM")){
            /**
             * Delivery  year and month should be current or future when delivery type is random members. (When year and month is   entered)
             */
            errors[`RandomMessageModel.topErrorMessage`] = ErrorMessages.MSG049_DELIVERY_YEAR_AND_MONTH_SHOULD_BE_CURRENT_OR_FUTURE;
        }

        else if(!CommonValidation.isStringNullEmptyOrUndefined(values.RandomMessageModel.deliveryMonth)) {
                if(CommonSelectors.isThereNonDigit(values.RandomMessageModel.deliveryMonth)) {
                    /**
                     * Delivery Date is invalid
                     */
                    errors[`RandomMessageModel.topErrorMessage`] = ErrorMessages.MSG062_INVALID_DELIVERY_DATE;
                }
        }
        
        else if(CommonSelectors.isThereNonDigit(values.RandomMessageModel.deliveryYear)) {
            /**
             * Delivery Date is invalid
             */
            errors[`RandomMessageModel.topErrorMessage`] = ErrorMessages.MSG062_INVALID_DELIVERY_DATE;
        }
    }
        
    
    if(!CommonValidation.objKeyExist(errors, `RandomMessageModel.topErrorMessage`)){
        if(CommonValidation.isStringNullEmptyOrUndefined(values.RandomMessageModel.messageBody) 
        && CommonValidation.isStringNullEmptyOrUndefined(values.RandomMessageModel.messageImage)) {
        /**
         * Body or image is required.
         */
        errors[`RandomMessageModel.topErrorMessage`] = ErrorMessages.MSG050_EMPTY_BODY_OR_IMAGE;
    }

    else if(CommonValidation.isStringNullEmptyOrUndefined(values.RandomMessageModel.deliveryStatus)) {
        /**
         * No delivery status is selected
         */
        errors[`RandomMessageModel.topErrorMessage`] = ErrorMessages.MSG051_EMPTY_DELIVERY_STATUS;
    }

    else if(CommonSelectors.isThereDoubleByte(values.RandomMessageModel.deliveryYear)){
        /**
         * Delivery Year has double-byte numeric characters
         */
        errors[`RandomMessageModel.topErrorMessage`] = ErrorMessages.MSG052_DELIVERY_YEAR_HAS_DOUBLE_BYTE;
    }

    else if(CommonSelectors.isThereNonDigit(values.RandomMessageModel.deliveryYear)){
        /**
         * Delivery Year has invalid characters
         */
        errors[`RandomMessageModel.topErrorMessage`] = ErrorMessages.MSG057_INVALID_DELIVERY_YEAR;
    }

    else if(CommonSelectors.isThereDoubleByte(values.RandomMessageModel.deliveryMonth)){
        /**
         * Delivery Month has double-byte numeric characters
         */
        errors[`RandomMessageModel.topErrorMessage`] = ErrorMessages.MSG053_DELIVERY_MONTH_HAS_DOUBLE_BYTE;
    }

    else if(CommonSelectors.isThereNonDigit(values.RandomMessageModel.deliveryMonth) && '0' !== values.RandomMessageModel.type){
        /**
         * Delivery Month has invalid characters
         */
        errors[`RandomMessageModel.topErrorMessage`] = ErrorMessages.MSG058_INVALID_DELIVERY_MONTH;
    }

    else if(CommonSelectors.isThereDoubleByte(values.RandomMessageModel.deliveryDay)){
        /**
         * Delivey Day has double-byte numeric characters
         */
        errors[`RandomMessageModel.topErrorMessage`] = ErrorMessages.MSG054_DELIVERY_DAY_HAS_DOUBLE_BYTE;
    }

    else if(CommonSelectors.isThereNonDigit(values.RandomMessageModel.deliveryDay)&& '0' !== values.RandomMessageModel.type){
        /**
         * Delivery Day has invalid characters
         */
        errors[`RandomMessageModel.topErrorMessage`] = ErrorMessages.MSG059_INVALID_DELIVERY_DAY;
    }

    else if(CommonSelectors.isThereDoubleByte(values.RandomMessageModel.deliveryHour)){
        /**
         * Delivey Hour has double-byte numeric characters
         */
        errors[`RandomMessageModel.topErrorMessage`] = ErrorMessages.MSG055_DELIVERY_HOUR_HAS_DOUBLE_BYTE;
    }

    else if(CommonSelectors.isThereNonDigit(values.RandomMessageModel.deliveryHour) && '0' !== values.RandomMessageModel.type) {
        /**
         * Delivery Hour has invalid characters
         */
        errors[`RandomMessageModel.topErrorMessage`] = ErrorMessages.MSG060_INVALID_DELIVERY_HOUR;
    }

    else if(CommonSelectors.isThereDoubleByte(values.RandomMessageModel.deliveryMinute)){
        /**
         * Delivey Minute has double-byte numeric characters
         */
        errors[`RandomMessageModel.topErrorMessage`] = ErrorMessages.MSG056_DELIVERY_MINUTE_HAS_DOUBLE_BYTE;
    }

    else if(CommonSelectors.isThereNonDigit(values.RandomMessageModel.deliveryMinute) && '0' !== values.RandomMessageModel.type){
        /**
         * Delivery Minute has invalid characters
         */
        errors[`RandomMessageModel.topErrorMessage`] = ErrorMessages.MSG061_INVALID_DELIVERY_MINUTE;
    }

    else if(!CommonValidation.isStringNullEmptyOrUndefined(values.RandomMessageModel.messageImage)){
        /**Check if existing randommessage with valid path (skip validation) */
        if("string" === typeof values.RandomMessageModel.messageImage){
            if(0 > values.RandomMessageModel.messageImage.indexOf("RandomMessage/")){
                /**
                 * S3 file path is invalid
                 */
                errors[`RandomMessageModel.topErrorMessage`] = ErrorMessages.MSG063_INVALID_IMAGE;
            }
        }else if("object" === typeof values.RandomMessageModel.messageImage && !CommonSelectors.isCorrectImage(values.RandomMessageModel.messageImage.name)){
            /**
             * Image file is invalid
             */
            errors[`RandomMessageModel.topErrorMessage`] = ErrorMessages.MSG063_INVALID_IMAGE;
        }
        
    } 
    }

    

    return errors;
}

export default {
    validateRandomMessage,
}
import React, {useState} from 'react';
import AuthorHeader from '../../components/AuthorHeader';
import { Card } from 'react-bootstrap';
import { Media } from 'react-breakpoints'
import Linkify from "react-linkify";

const AdminNotice = (props) => {
    let defaultAuthor = {
        profileImageUrl: "",
        userId: null,
        isAdminNotice: true,
        handleName: "comu comu管理者"
    }

    const [showBody, setShowBody] = useState(false);

    return (
        <Media>
            {({ breakpoints, currentBreakpoint }) => {
                let mobileView = (breakpoints[currentBreakpoint] < breakpoints.mobileLandscape);
                return (
                    <Card className={mobileView ? "notice-container-item-mobile" : "notice-container-item"}>
                        <Card.Body style={{ padding: 0 }}>
                            <div className="post-item-container">
                                <AuthorHeader
                                    {...defaultAuthor}
                                    isPreview={true}
                                    disableImageLink={true}
                                    stringCreateDt={props.createViewDt}
                                />
                                <p className="cursor-pointer font-weight-bold mb-2" onClick={()=> setShowBody(!showBody)}>{props.title}</p>
                                {showBody && (
                                    <p className="font-weight-normal">
                                        <Linkify
                                            componentDecorator={(decoratedHref, decoratedText, key) => (
                                                <a target="blank" href={decoratedHref} key={key}>
                                                {decoratedText}
                                                </a>
                                            )}
                                        >
                                            {props.body}
                                        </Linkify>
                                    </p>
                                )}
                            </div>
                        </Card.Body>
                    </Card>
                )
            }}
        </Media>
    );
};

export default AdminNotice;
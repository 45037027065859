import React, {Component} from 'react';


class Admin404 extends Component {
    render(){
        return(
            <center><h4>Page not found</h4></center>
        );
    }
}

export default Admin404;
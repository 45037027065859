import MyPage from "./MyPage";
import MyPagePreview from "./MyPagePreview";

export const routes = [
    {
        path: "/mypage",
        component: MyPage,
        exact: true,
        showNav: true
    },
    {
        path: "/mypage/updated",
        component: MyPage,
        exact: true,
        showNav: true
    },
    {
        path: "/mypage-preview/",
        component: MyPagePreview,
        exact: false,
        showNav: true
    }
]
import React, { Component } from 'react';
import "./styles/styles.scss"
import { Media } from 'react-breakpoints'
import { FFMWEB_ROOT } from '../../../config/settings';
import { isMobile } from "react-device-detect";
import { defaultTopScroll } from '../../../utils/common';

class TermsOfService extends Component {

    state={
        isPortrait: true
    }

    componentDidMount(){
        defaultTopScroll();
        var that = this;

        // Find matches
        var mql = window.matchMedia("(orientation: portrait)");
        this.setState({isPortrait: mql.matches})

        // Add a media query change listener
        mql.addListener(function(m) {
            if(m.matches !== that.state.isPortrait){
                that.setState({isPortrait: m.matches})
            }
        });
    }
    
    componentWillUnmount() {
        document.body.style.overflowY = 'unset';
    }

    render() {
        return (
            <Media>
            {({ breakpoints, currentBreakpoint }) => {
                let mobileView = (breakpoints[currentBreakpoint] < breakpoints.mobileLandscape) || isMobile;
                
                if(mobileView && this.state.isPortrait){
                    document.body.style.overflowY = 'hidden'
                }else{
                    document.body.style.overflowY = 'auto'
                }

                return(
                    <div className="terms-main-container">
                        <div className="terms-header">
                            <span className="terms-header-text">利用規約</span>
                        </div>
                        <div className="terms-container">
                            <p>
                                <span className="term-date"> 最新更新日　2020年9月9日</span>
                                <section className="term-item">
                                １．利用規約の範囲<br/>
                                    ユーザーの皆様は、FF会員限定SNSサービス「comu comu」の利用に関して適用される、以下の利用規約を承認するものとします。この利用規約の他、comu comuからリンクされた他サイト、利用する個別サービスの利用規約（本利用規約では網羅できない、個別サービス特有の規約）が存在する場合は、その利用規約に従ってください。<br/>
                                    なお、個人情報の取り扱いについては株式会社エフエフエムおよび藤井フミヤオフィシャルサイト掲載のプライバシーポリシーに準ずるものとします。
                                </section>
                                <section className="term-item">
                                    ２．禁止行為 <br/>
                                        皆様にcomu comuを有益に楽しんでいただくため、comu comuでは以下の行為を禁止します。<br/><br/>
                            
                                        ・法令に違反する行為、および違法な行為を勧誘または助長する行為<br/>
                                        ・チケットの売買、譲渡を呼びかける行為<br/>
                                        ・アーティストの画像、アーティストの写真を素材とした印刷物等を撮影した画像、キャラクターのデータおよびロゴのデータの掲載や配布その他、肖像権・著作権等の権利侵害にあたる行為。<br/>
                                        ※上記「キャラクターのデータおよびロゴのデータ」とは、株式会社エフエフエムがホームページやアーティストグッズ用として公式に作成したオリジナルデータを指します。<br/>
                                        ※当項目の制限範囲はcomu comu内のみで適用するものであり、comu comu以外のサイトでは、ロゴマークおよびキャラクターのデータ以外の方法による使用も一切禁止します。<br/>
                                        ・ホームページに掲載されたデータの転載や加工、配布その他、肖像権・著作権等の権利侵害にあたる行為。<br/>
                                        ・テレビ番組等のダビング依頼・受注など肖像権・著作権等の権利侵害にあたる行為。<br/>
                                        ・他人の名誉、信用、プライバシー権、パブリシティ権、著作権、その他の権利を侵害する行為<br/>
                                        ・他のユーザーに対する中傷、脅迫、いやがらせ、その他経済的もしくは精神的損害または不利益を与える行為。特に、本人に許可を得ずにユーザーID、ニックネーム、氏名等を特定した上での攻撃的批判（反論の機会や手段が乏しい状況下で一方的に行われている場合等）にあたる投稿を掲載する行為。<br/>
                                        ・一般の方が不快に感ずる差別的、暴力的、性的なイメージ（画像）、言葉、その他の表現の掲載<br/>
                                        ・次に掲げる内容の情報を、comu comu内の書き込み可能な箇所に投稿し、またはメッセージで送信する行為。<br/>
                                        　　(1)商業用の広告、宣伝を目的とする情報。ただし株式会社エフエフエム公認のものを除く。<br/>
                                        　　(2)アフィリエイト（成果報酬型広告）のリンク、チェーンメール・マルチ商法・リードメール等他人を勧誘する内容、アダルトサイトへの誘導（単にリンクを張る行為を含む）、その他弊社が不適切と判断する情報。<br/>
                                        ・無差別にメッセージを送信し、無差別に友達の追加を依頼する行為。<br/>
                                        ・選挙の事前運動、選挙運動またはこれらに類似する行為、および公職選挙法に抵触する行為<br/>
                                        ・あらかじめ不特定者に転売する目的で購入した商品を高額で売買することは、迷惑防止条例に違反するため禁止します。非営利の売買、交換等を行う場合は、あくまで他人同士の取引であることを前提に自己責任のもと行ってください。<br/>
                                        ・日記やコミュニティなど複数のユーザーが閲覧可能な場所での、個人情報（住所、電話番号、金融機関口座番号、クレジットカード番号など）の記載<br/>
                                        ・自分以外の人物を名乗ったり、代表権や代理権がないにもかかわらず会社などの組織を名乗ったり、または他の人物や組織と提携、協力関係にあると偽ったりする行為。故意過失に基づき誤認した場合も含みます。<br/>
                                        ・comu comu内のサービスに関わる記載について、無断でそのコピー、複製、アップロード、掲示、伝送、配布等をする行為<br/>
                                        ・サーバーに負担をかける行為、および他のユーザーのアクセスまたは操作を妨害する行為、スパム行為<br/>
                                        ・comu comuの運営またはネットワーク・システムを妨害する行為<br/>
                                        ・他のユーザーの個人情報を収集・蓄積する行為<br/>
                                        ・一人のユーザーが複数のアカウントを持つ行為<br/>
                                        ・同じアカウントを複数人で利用する行為<br/>
                                        ・掲示板等でまったく面識のない方を無差別に招待する行為<br/>
                                        ・その他公序良俗、一般常識に反する行為<br/>
                                        ・上記に準ずる行為<br/><br/>
        
                                        <p>以上の行為が確認された場合、状況を判断した上で掲載情報の変更、登録削除を含めたしかるべき処置をとります。規約違反に関して連絡する場合は登録メールアドレス宛に送信させていただきます。ユーザー様のご事情により運営事務局からのメールを確認できなかった場合も、規約に則った対応をさせていただきますのであらかじめご了承ください。なお、削除結果に関する質問・苦情は一切受け付けておりません。判断の難しいケースに関しては、運営基準と照らし合わせてその都度判断させていただきます。</p>
                                </section>
                                <section className="term-item">
                                ３．推奨環境<br/>
                                推奨環境は<a href = {FFMWEB_ROOT + "/environment"} target="_blank" rel="noopener noreferrer">こちら</a>
                                </section>
                                <section className="term-item">
                                    ４．免責事項<br/>
                                    1.comu comuでは、基本的にユーザー同士の通信や活動に関与しません。ユーザーの皆様には、全て自己責任のもとでcomu comuをご利用いただきます。<br/>
                                    2.投稿等が法令違反や肖像権・著作権等の権利侵害にあたる場合には、損害賠償などの民事責任の他、刑事責任を問われることがあります。法律または利用規約条項のいずれかに従わない場合には、ユーザー様が当社に対して有する権利、主張、法的措置、訴訟、訴訟手続きの全てから当社を免除し、放免するものとします。<br/>
                                    3.当社はcomu comuのサービスの全部または一部を、いつでも任意の理由で変更・中断・停止または終了することができます。当社は、comu comu利用、または利用ができないことによって引き起こされた（直接的、間接的を問わず）損害について一切責任を負いません。<br/>
                                    4.当社はcomu comuの利用を終了したユーザー様（終了原因の如何を問いません）の、サーバーに保管される保存中の個人情報または利用データの一部または全部を、いつでも任意の理由で抹消できるものとします。<br/>
                                </section>
                                <section className="term-item">
                                    ５．皆様の同意、法的手続き<br/>
                                    1.ユーザーの皆様は、comu comuを利用することにより、この利用規約すべての記載内容について、同意されたものとみなされます。またこの利用規約は当社の判断で任意に変更されます。将来引き続きご利用になる場合は、その時点での内容に同意されているとみなされます。<br/>
                                    2.本利用規約の準拠法は日本法とします。また、comu comuまたはこの規約に関連して当社と皆様の間で生じた紛争については東京地方裁判所を第一審専属管轄裁判所とします。
                                </section>
                            </p>
                        </div>
                    </div>
                )
            }}
            </Media>
        );
    }
}

export default TermsOfService;
import * as CommonSelectors from '../../../components/Validations/CommonSelectors';
import * as ErrorMessages from '../../../../utils/messages/admin/errorMessages';
import { userManagementForm } from './templates';
import * as CommonValidation from '../../../../utils/common';

const validateUserManagement = (values) => {
    let errors = {};

    // Reset top error message to type 'top-error-message' from type 'values'
    userManagementForm.form[0].type = "top-error-message";
    userManagementForm.form[0].name = "topErrorMessage";

    /**
     * Validation applies to non-empty search-form fields 
     * */

    // ID has invalid characters
    if(!CommonValidation.isStringNullEmptyOrUndefined(values.UserManagementModel.userId) 
        && !CommonSelectors.isNumeric(values.UserManagementModel.userId))
    {
        errors[`UserManagementModel.topErrorMessage`] = ErrorMessages.MSG007_INVALID_ID;
    }

    // Email has invalid characters
    else if(!CommonValidation.isStringNullEmptyOrUndefined(values.UserManagementModel.email) 
        && !CommonSelectors.isCorrectEmail(values.UserManagementModel.email)) 
    {
        errors[`UserManagementModel.topErrorMessage`] = ErrorMessages.MSG002_INVALID_EMAIL;
    }

    return errors;
}

export default {
    validateUserManagement,
}
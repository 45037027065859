import reducer from "./reducers";
import { routes } from "../routes";
import * as profileOperations from "./operations"
import * as profileSelectors from "./selectors"

export {
    profileOperations,
    profileSelectors
}

export default {
    reducer,
    routes
};

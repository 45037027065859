import React, { PureComponent } from "react";
import { FlatList, View, TouchableOpacity, Image } from "react-native";
import { ListItem, Button } from "react-native-elements";
import NewChatIcon from "../../../includes/img/icons/edit.svg";
import DeleteIcon from "../../../includes/img/icons/delete.svg";
import MenuIcon from "../../../includes/img/icons/dot-menu.svg";
import MessageIcon from "../../../includes/img/icons/messages.svg";
import { PROFILE_THUMBNAIL_BUCKET_URL, THUMBNAIL_MEDIUM, CHAT_TYPE, WEBSOCKET_MESSAGES } from "../../../utils/constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { chatOperations } from "./state";
import DeleteMenuWindow from "./DeleteMenuWindow";
import RadioButton from "./RadioButton";
import styles from "./styles/chatlist.styles.native.js";
import ConfirmationModal from "../../components/ConfirmationModal/index";
import { isNullOrUndefined } from "util";
import MobileOnlyView from "../../components/MobileOnlyView"
import PCOnlyView from "../../components/PCOnlyView"
import { isMobile } from "react-device-detect";
import { Media } from 'react-breakpoints'
import { authOperations } from "../Authentication/state";
import ErrorModal from "../../components/ErrorModal";
import { SYSTEM_ERROR, USER_DELETED_MESSAGE, FRIEND_DELETED_MESSAGE, FRIEND_SENT_PHOTO, USER_SENT_PHOTO, NO_MESSAGES, DELETE_MESSAGE_TEXT } from "../../../utils/messages";
import { addDefaultSrc, isFumiyaOrFFStaffUserCheck, appendUserRoute, onApiSuccess, getObjKeyIdString, defaultTopScroll } from "../../../utils/common";
import ChatListSkeleton from '../../components/Skeletons/ChatListSkeleton';
import ProcessLoader from '../../components/ProcessLoader';

class ChatList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userId: props.userId,
      handleName: props.handleName,
      selectedThread: null,
      isModalShow: false,
      isDeleteMenuShow: false,
      isThreadDelete: false,
      currentPagination: 1,
      chatThreads: [],
      dataFetched: false,
      firstPage: true,
      isErrorModalShow: false,
      initialLoaded: false,
      isLastPage: false,
      isDeletingThread : false
    };
    this.ws = props.ws
  }

  initWSHandlers() {
    this.ws.onmessage = (data) => {
      let serverData = JSON.parse(data.data);
      let responseData = JSON.parse(serverData.data)

      if (WEBSOCKET_MESSAGES.onNotifications === responseData.action) {
        this.props.incrementNotifCount(responseData.referenceId)
      }else if (WEBSOCKET_MESSAGES.onChatNotification === responseData.action) {
        this.props.incrementChatThreadCount(responseData.senderId, responseData.threadId)
      } else if (WEBSOCKET_MESSAGES.onMessage === responseData.action || WEBSOCKET_MESSAGES.onMessageDelete === responseData.action
        || WEBSOCKET_MESSAGES.onImageRender === responseData.action) {
        this.props.getAllChatThread(this.state.userId, this.state.currentPagination)
          .then(response => {
            if (onApiSuccess(response)) {
              let data = JSON.parse(response.data.data);
              this.setState({
                chatThreads: data,
              })
            } else {
              this.setState({
                isErrorModalShow: true,
                errorMessage: SYSTEM_ERROR
              })
            }
          });
      }
    }
    this.ws.onclose = () => {
      this.ws.close()
      this.props.connect();
      setTimeout(() => {
        this.initWSHandlers()
      }, 1000);
    }
  }

  componentDidMount() {
    defaultTopScroll();

    this.props.getAllChatThread(this.state.userId, this.state.currentPagination)
      .then(response => {
        if (onApiSuccess(response)) {
          let data = JSON.parse(response.data.data);
          this.setState({
            chatThreads: data,
            dataFetched: true,
            initialLoaded: true
          })
          if(0 === data.length){
            this.setState({
              isLastPage : true
            })
          }
        } else {
          this.setState({
            isErrorModalShow: true,
            errorMessage: SYSTEM_ERROR,
            initialLoaded: true
          })
        }
      });
  }

  componentDidUpdate() {
    if (!isNullOrUndefined(this.ws)) {
      this.initWSHandlers()
    }
  }

  handleCreateMessage = () => {
    this.props.history.push({
      pathname: "/app/newchat"
    });
  };

  handleShowDeleteThread = () => {
    this.setState({
      isDeleteMenuShow: false,
      isThreadDelete: !this.state.isThreadDelete,
      selectedThread: null
    });
  };

  handleDeleteThreadConfirm = () => {
    this.setState({
      isModalShow: !this.state.isModalShow
    });
  };

  handleDeleteThread = () => {
    this.setState({
      isModalShow: false,
      isDeletingThread : true
    },()=>{
      this.props.deleteChatThread(this.state.userId, this.state.selectedThread).then(response => {
        if (onApiSuccess(response)) {
          let updatedThreads = Object.assign([], this.state.chatThreads); 
          let remainingThreads = updatedThreads.filter(i => getObjKeyIdString(i._id) !== getObjKeyIdString(this.state.selectedThread));
          this.setState({
            isThreadDelete: false,
            chatThreads: remainingThreads,
            isDeletingThread : false
          });
        } else {
          this.setState({
            isErrorModalShow: true,
            errorMessage: isNullOrUndefined(response.data.message) ? SYSTEM_ERROR : response.data.message,
            isDeletingThread : false
          })
        }
      });
    })
  };

  handleCancelDeleteThread = () => {
    this.setState({
      isModalShow: !this.state.isModalShow
    });
  };

  handleOpenMessage = item => {
    let conversationRoute = appendUserRoute(`/chatmessages/${item.recipientId}`)
    this.props.history.push({
      pathname: conversationRoute,
      state: {
        handleName: item.recipientHandleName
      }
    });
  };

  handleSelectThread = id => {
    this.setState({
      selectedThread: id
    });
  };

  handleToggleDeleteMenu = () => {
    this.setState({
      isDeleteMenuShow: !this.state.isDeleteMenuShow
    });
  };

  handlePagination = () => {
    if(!this.state.isLastPage){
      let currentPagination = this.state.currentPagination + 1
      this.setState({
        currentPagination: this.state.currentPagination + 1,
        dataFetched: false,
        firstPage: false
      });
  
      this.props
        .getAllChatThread(this.state.userId, currentPagination)
        .then(response => {
          if (onApiSuccess(response)) {
            let data = JSON.parse(response.data.data);
            this.setState(prevState => ({
              chatThreads: [...prevState.chatThreads, ...data],
              dataFetched: true
            }))
            if(0 === data.length){
              this.setState({
                isLastPage : true
              })
            }
          } else {
            this.setState({
              isErrorModalShow: true,
              errorMessage: SYSTEM_ERROR
            })
          }
        });
    }
  };

  handleCloseErrorModal = () => {
    this.setState({
      isErrorModalShow: false
    })
  }

  renderChatItem = (item) => {
    if(isNullOrUndefined(item.chat)){
      return " ";
    }
    return item.chat.isDeleted
      ? item.chat.senderId === this.state.userId
        ? USER_DELETED_MESSAGE
        : item.recipientHandleName + FRIEND_DELETED_MESSAGE
      : CHAT_TYPE.text === item.chat.type
        ? item.chat.chatBody
        : item.chat.senderId === this.state.userId
          ? USER_SENT_PHOTO
          : FRIEND_SENT_PHOTO;
  };

  renderItem = (item, index) => {
    const recipientImage = PROFILE_THUMBNAIL_BUCKET_URL + item.recipientId + "/" + item.recipientId + THUMBNAIL_MEDIUM
    return (
      <Media>
        {({ breakpoints, currentBreakpoint }) => {
          let isDeviceMobile = (breakpoints[currentBreakpoint] < breakpoints.mobileLandscape) || isMobile
          return (
            <View style={item.seen ? styles.seenChat : styles.unSeenChat}>
              <ListItem
                key={index}
                title={item.recipientHandleName}
                titleStyle={ isDeviceMobile ? styles.titleMobile : styles.titlePC}
                subtitle={this.renderChatItem(item)}
                subtitleStyle={isDeviceMobile ? styles.subtitleMobile : styles.subtitlePC}
                leftElement={
                  <img className="avatar" alt=" " src={recipientImage} onError={addDefaultSrc} />
                }
                rightElement={
                  <RadioButton
                    {...this.state}
                    id={item._id}
                    recipientId={item.recipientId}
                    handleSelectThread={this.handleSelectThread}
                  />
                }
                containerStyle={item.seen ? styles.seenChatItem : styles.unseenChatItem}
                onPress={
                  this.state.isThreadDelete ?  isFumiyaOrFFStaffUserCheck(item.recipientId) ? null : () => this.handleSelectThread(item._id) : () => this.handleOpenMessage(item)
                }
              />
            </View>
          )
        }}
      </Media>
    );
  };

  renderEmptyChatList = () => {
    return (
      <ListItem
        title={NO_MESSAGES}
        titleStyle={styles.emptyList}
      />
    );
  };

  renderActionIcons = () => {
    if (this.state.isThreadDelete) {
      return (
        <View style={styles.actionContainer}>
          {null !== this.state.selectedThread ? (
            <TouchableOpacity onPress={() => this.handleDeleteThreadConfirm()}>
              <Image
                alt="delete-icon"
                source={DeleteIcon}
                style={styles.deleteIcon}
              />
            </TouchableOpacity>
          ) : null}
          <PCOnlyView>
            <Button
              title="キャンセル"
              buttonStyle={styles.cancelBtnPC}
              titleStyle={styles.cancelBtnTitle}
              type="outline"
              onPress={() => this.handleShowDeleteThread()}
            />
          </PCOnlyView>
          <MobileOnlyView>
            <Button
              title="キャンセル"
              buttonStyle={styles.cancelBtn}
              titleStyle={styles.cancelBtnTitle}
              type="outline"
              onPress={() => this.handleShowDeleteThread()}
            />
          </MobileOnlyView>
        </View>
      );
    } else {
      return (
        <View style={styles.actionContainer}>
          <TouchableOpacity onPress={() => this.handleToggleDeleteMenu()}>
            <Image alt="pen-icon" source={MenuIcon} style={styles.menuIcon} />
          </TouchableOpacity>

          <TouchableOpacity onPress={() => this.handleCreateMessage()}>
            <Image
              alt="pen-icon"
              source={NewChatIcon}
              style={styles.newChatIcon}
            />
          </TouchableOpacity>
        </View>
      );
    }
  };

  isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }) => {
    const paddingToBottom = 20;
    return layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom;
  };

  render() {
    let chatListContent = (
      <div style={{ overflow: 'hidden' }}>
        <ChatListSkeleton />
      </div>
    );
    

    if (this.state.initialLoaded) {
      chatListContent = (
        <Media>
        {({ breakpoints, currentBreakpoint }) => {
          let isDeviceMobile = (breakpoints[currentBreakpoint] < breakpoints.mobileLandscape) || isMobile
          return(
  
            <View style={styles.flatlistContainer}>
              <FlatList
                showsVerticalScrollIndicator={!isDeviceMobile}
                style={isDeviceMobile ? styles.listContainerMobile : styles.listContainerPC}
                data={this.state.chatThreads}
                keyExtractor={(item) => { return item._id.$oid.toString() }}
                renderItem={({ item, index }) => this.renderItem(item, index)}
                ListEmptyComponent={this.renderEmptyChatList}
                extraData={this.state}
                bounces={false}
                onScroll={({ nativeEvent }) => {
                  if (this.isCloseToBottom(nativeEvent)) {
                    if(!this.state.isLastPage && this.state.dataFetched){
                      this.handlePagination();
                    }
                  }
                }}
              />
            </View>
       
          )
        }}
        </Media>
      )
    }


  return (
      <Media>
        {({ breakpoints, currentBreakpoint }) => {
          let isDeviceMobile = (breakpoints[currentBreakpoint] < breakpoints.mobileLandscape) || isMobile
          return (
            <div className="chat-list-container">
              <ProcessLoader isShown={this.state.isDeletingThread}/>
              <View style={styles.listItemContainer}>
                <ListItem
                  containerStyle={styles.listItem}
                  title={"メッセージ"}
                  titleStyle={isDeviceMobile ? styles.chatListTitleMobile : styles.chatListTitlePC}
                  leftElement={
                    <img className="header-avatar" alt="user-avatar" src={PROFILE_THUMBNAIL_BUCKET_URL + this.state.userId + "/" + this.state.userId + THUMBNAIL_MEDIUM + "?" + Date.now()} onError={addDefaultSrc} />
                  }
                  rightElement={this.renderActionIcons()}
                />
              </View>
              <View style={styles.horizontalBorder} />
              {this.state.isDeleteMenuShow ? (
                <View style={styles.menuContainer}>
                  <TouchableOpacity
                    style={styles.menuTouchable}
                    onPress={() => this.handleSelectThread()}
                  >
                    <Image
                      alt="pen-icon"
                      source={MessageIcon}
                      style={styles.messagesIcon}
                    />
                  </TouchableOpacity>
                </View>
              ) : null}

              <DeleteMenuWindow
                {...this.state}
                handleShowDeleteThread={this.handleShowDeleteThread}
              />

              {/** List Content */}
     
              {chatListContent}
          
              
              {this.state.isModalShow && (
                <ConfirmationModal
                  {...this.state}
                  handleConfirmAction={this.handleDeleteThread}
                  handleCloseModal={this.handleCancelDeleteThread}
                  customContent={null}
                  confirmationText={DELETE_MESSAGE_TEXT}
                  confirmTitle={"削除"}
                />
              )}


              {this.state.isErrorModalShow && (
                <ErrorModal
                  {...this.state}
                  handleCloseModal={this.handleCloseErrorModal}
                  customContent={null}
                  errorMessage={this.state.errorMessage}
                />
              )}

            </div>
          )
        }}
      </Media>
    );
  }
}

//Bind dispatch to action creator
//Call the websocket instance
const mapStateToProps = state => {
  return {
    ws: state.auth.websocket,
    userId: state.auth.credentials.userId,
    handleName: state.editprofile.profileDetails.handleName
  };
};
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllChatThread: chatOperations.getAllChatThread,
      deleteChatThread: chatOperations.deleteChatThread,
      connect: authOperations.connectWS,
      incrementNotifCount: authOperations.incrementNotifCount,
      incrementChatThreadCount: authOperations.incrementChatThreadCount
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ChatList);

import React from 'react';
import { objKeyExist, isStringNullEmptyOrUndefined } from '../../../../utils/common';

import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { FFMWEB_LOGIN_URL } from '../../../../config/settings';
import { profileOperations } from '../../EditProfile/state';
import { authOperations } from '../state';
import ProcessLoader from '../../../components/ProcessLoader';
import { APP_NEWSFEED, INT_NOT_FOUND } from '../../../../utils/constants';

const redirectFFMWeb = () => {
    window.location.replace(FFMWEB_LOGIN_URL);
}

const UserAuthentication = (props) => {
    const dispatch = useDispatch();
    let operations = bindActionCreators(
        {
            authenticateMember: authOperations.authenticateMember,
            getUserProfile: profileOperations.getUserProfile,
            checkMaintenance: authOperations.checkMaintenance
        },
        dispatch
    )
    // Authenticate FFMWeb Redirect (Pass params FFMWebAuth, email, password from FFMWeb route)
    let isFFMWebRedirect = props.location.state && (objKeyExist(props.location.state, "FFMWebAuth") && objKeyExist(props.location.state, "email") && objKeyExist(props.location.state, "password"));

    //Create user session Cognito using redirect states
    if (isFFMWebRedirect) {
        let email = props.location.state.email;
        let password = props.location.state.password;

        if (!isStringNullEmptyOrUndefined(email) && !isStringNullEmptyOrUndefined(password)) {
            //Cognito 
            operations.authenticateMember(email, password)
                .then((result) => {
                    if (INT_NOT_FOUND === result) {
                        //Invalid credentials side effects
                        redirectFFMWeb();
                    } else {
                        Promise.resolve(operations.checkMaintenance()).then(() => {
                            //Call API to get user details
                            operations.getUserProfile(result).then(() => {
                                props.history.replace(APP_NEWSFEED)
                            })
                        })

                    }
                });
        }
    }

    return (
        <ProcessLoader isShown={true} />
    );
};

export default UserAuthentication;
import React from "react";
import Modal from "react-modal";
import { View } from "react-native";
import "./styles.scss";

const CustomModal = props => {
  return (
    <View>
      <Modal
        ariaHideApp={false}
        isOpen={props.showModal}
        className="custom-modal"
        onRequestClose={()=>props.handleCloseModal()}
        overlayClassName="custom-modal-overlay"
      >
        {props.children}
      </Modal>
    </View>
  );
};

export default CustomModal;

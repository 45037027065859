import DiaryMemberSearch from "./DiaryMemberSearch";
import MainSearch from "./MainSearch";
import PostByDate from "./PostByDate";

export const routes = [
    {
        path: "/search",
        component: MainSearch,
        exact: true,
        showNav: true
    },
    {
        path: "/search/results",
        component: DiaryMemberSearch,
        exact: true,
        showNav: true
    },
    {
        path: "/search/post/filter",
        component: PostByDate,
        exact: true,
        showNav: true
    }
]
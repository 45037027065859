import * as Path from "./apiRoutes";
import ApiService from "../../../../utils/apiService";
import * as Actions from "./actions";
import { onApiSuccess } from "../../../../utils/common";

const getMaintenanceSettings = () => (dispatch) => {
    return ApiService.get(Path.GET_MAINTENANCE_SETTINGS)
        .then(response => {
            if(onApiSuccess(response)){
                let responseData = JSON.parse(response.data.data);
                let payload = {
                    maintenanceId: responseData[0]._id.$oid,
                    maintenanceType: parseInt(responseData[0].maintenanceType),
                }
                dispatch(Actions.setMaintenanceSettings(payload));
            }
            return response;
        })
        .catch((error) => {
            console.log(`System Error: ${error}`);
            return error;
        });
};

const updateMaintenanceSettings = (values) => (dispatch) => {
    return ApiService.patch(Path.UPDATE_MAINTENANCE_SETTINGS, values)
    .then(response => {
        if(onApiSuccess(response)){
            values.maintenanceType = parseInt(values);
            dispatch(Actions.setMaintenanceSettings(values));
        }
        return response;
    })
    .catch((error) => {
        console.log(`System Error: ${error}`);
        return error;
    });
};

export {
    getMaintenanceSettings,
    updateMaintenanceSettings
};
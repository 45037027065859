import React from "react";
import { Card } from "react-bootstrap";
import "./styles/containers.scss";
import {  isFumiyaOrFFStaffUserCheck } from "../../../utils/common";
import { MenuItem } from "../../components/MenuItem";
import { isMobile } from "react-device-detect";

const navList = (user) => [
  {
    navigate: "/newsfeed",
    label: "最新の更新",
    icon: require("../../../includes/img/user-menu/newsfeed-dark.svg"),
  },
  {
    navigate: "/mypage",
    label: "マイページ",
    icon: require("../../../includes/img/user-menu/mypage-dark.svg"),
  },
  {
    navigate: "/chat",
    label: "メッセージ",
    icon: require("../../../includes/img/user-menu/chat-dark.svg"),
    disabled: isFumiyaOrFFStaffUserCheck(user.userId)
  },
  {
    navigate: "/notifications",
    label: "新着情報",
    icon: require("../../../includes/img/user-menu/notification-dark.svg"),
    disabled: isFumiyaOrFFStaffUserCheck(user.userId)
  },
];

const snsList = [
  {
    navigate: "/twitter",
    label: "X",
    icon: require("../../../includes/img/user-menu/twitter-dark.svg"),
  },
  {
    navigate: "/instagram",
    label: "Instagram",
    icon: require("../../../includes/img/user-menu/instagram-dark.svg"),
  },
  {
    navigate: "/facebook",
    label: "Facebook",
    icon: require("../../../includes/img/user-menu/facebook-dark.svg"),
  },
];

export const PCSideNav = React.memo(({ user, profile }) => {

  if(window != window.parent){ //task#363 start
    return "";
  } else {  //task#363 end
  return (
    <div className={isMobile ? "user-main-sidenav-mobile" : "user-main-sidenav"}>
      <Card className="user-main-cardnav">
        {navList(user).map((item, i) => {
          return <MenuItem key={i} item={item} />;
        })}
      </Card>

      <div className="user-main-snsnav">
        {snsList.map((item, i) => {
          return <MenuItem key={i} item={item} />;
        })}
      </div>
    </div>
  );
  } //task#363
});

import React from 'react';
import Linkify from "react-linkify";

const LinkTransform = (props) => {
    return (
        <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="blank" 
                    href={decoratedHref} 
                    key={key} 
                    onClick={(e)=>{
                        e.stopPropagation();
                    }}
                >
                {decoratedText}
                </a>
            )}
        >
            {props.children}    
        </Linkify>
    )
};

export default LinkTransform;
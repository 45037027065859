import React, { PureComponent } from 'react';
import { Card } from 'react-bootstrap';
import { Media } from 'react-breakpoints'
import { isMobile } from "react-device-detect";
import { ActivityIndicator } from 'react-native';
import UserNotification from './UserNotification';
import { notificationOperations } from './state';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { onApiSuccess, getObjKeyIdString, isScrollRefetchReached, defaultTopScroll } from '../../../utils/common';
import ConfirmationModal from '../../components/ConfirmationModal';
import { isNullOrUndefined } from '../../../../node_modules/util';
import { WEBSOCKET_API_ERROR_STATUS, WEBSOCKET_MESSAGES } from '../../../utils/constants';
import { SYSTEM_ERROR, EMPTY_NOTIFICATION } from '../../../utils/messages';
import ErrorModal from '../../components/ErrorModal';
import { authOperations } from '../Authentication/state';
import ProcessLoader from '../../components/ProcessLoader';
import NotificationSkeleton from '../../components/Skeletons/NotificationSkeleton';
import "./styles/styles.scss"
import { isSessionExpired } from "../../../utils/common"; // Whiete Out fix
import ProcessLoaderForLoading from '../../components/ProcessLoaderForLoading'; // Whiete Out fix

/**For scroll direction check */
var scrollPos = 0;

class Notifications extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            currentPagination: 1,
            isFetching: false,
            showConfirmModal: false,
            confirmMsg: "",
            msgErrorModal: "",
            showErrorModal: false,
            isProcessing: false,
            initialLoaded: false,
            listEndReached: false,
            isProcessingForLoading: false, //White Out fix
            hash: Date.now()
        }
    }

    componentDidMount() {
        defaultTopScroll();

        this.handlePagination();

        //Scroll event listener
        document.addEventListener('scroll', this.onScrollCheck);

        document.addEventListener("visibilitychange", this.backGroundCare); //White Out fix

        /**Sockets */
        if (!isNullOrUndefined(this.props.websocket) && this.props.websocket.readyState === WebSocket.OPEN) {
            this.initWSHandlers()
        }
        /***/
    }

    onScrollCheck = () => {
        let isScrollDown = (document.body.getBoundingClientRect()).top < scrollPos;

        if (isScrollRefetchReached() && !this.state.isFetching && this.state.initialLoaded && isScrollDown && !this.state.listEndReached) {
            this.handlePagination();
        }

        scrollPos = (document.body.getBoundingClientRect()).top;
    }

    componentDidUpdate() {
        this.initWSHandlers()
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.onScrollCheck);
        document.removeEventListener("visibilitychange", this.backGroundCare); //White Out fix
    }

    handlePagination = () => {
        if (!this.state.isFetching) {
            this.setState({ isFetching: true }, () => {
                this.props.getNotifications(this.props.user.userId, this.state.currentPagination)
                    .then(res => {
                        if (onApiSuccess(res)) {
                            if (0 < JSON.parse(res.data.data).length) {
                                this.setState(prevState => ({
                                    currentPagination: prevState.currentPagination + 1,
                                    isFetching: false,
                                    initialLoaded: true
                                }));

                                /**Update last seen */
                                this.props.setLastSeen(this.props.user.userId);
                            } else {
                                this.setState({
                                    isFetching: false,
                                    initialLoaded: true,
                                    listEndReached: true
                                })
                            }
                        } else {
                            this.showSystemError();
                        }
                    }).catch(()=>this.showSystemError())
            });
        }
    }

    //Side effects based on received sockets
    /** Websockets Listeners*/
    initWSHandlers() {
        this.props.websocket.onmessage = (data) => {
            let serverData = JSON.parse(data.data);

            if (!isNullOrUndefined(serverData.message) && WEBSOCKET_API_ERROR_STATUS === serverData.message) {
                this.setState({
                    showErrorModal: true,
                    msgErrorModal: SYSTEM_ERROR,
                    isProcessing: false
                })
            }

            let responseData = JSON.parse(serverData.data)

            //Received notifications
            if (WEBSOCKET_MESSAGES.onNotifications === responseData.action) {
                //Add Notif to list
                this.props.addReceivedNotification(responseData)
                //Clear new notif count
                this.props.setLastSeen(this.props.user.userId);
            } if (WEBSOCKET_MESSAGES.onNewAcceptRequest === responseData.action) {
                //Success Accept Friend Request
                this.props.removeRequestNotification(responseData.notificationId);
                this.setState({
                    showConfirmModal: true,
                    confirmMsg: "友達リクエストを承認しました。",
                    isProcessing: false
                })
            } else if (WEBSOCKET_MESSAGES.onRemoveRequest === responseData.action) {
                //Success Remove Friend Request (Update existing notification)
                this.props.addReceivedNotification(responseData);
                this.setState({
                    showConfirmModal: true,
                    confirmMsg: "友達リクエストを削除しました。",
                    isProcessing: false
                })
            } else if(WEBSOCKET_MESSAGES.onNotificationError === responseData.action){
                this.setState({
                    showErrorModal: true,
                    msgErrorModal: serverData.message,
                    isProcessing: false
                })
            }
        }

        let isExistError = false; //added 20220720 White Out fix (caused by reconnection loop)
        this.props.websocket.onerror = () => { isExistError = true } //mod 20220617 White Out fix (caused by reconnection loop) Start.
        this.props.websocket.onclose = () => {
            let isScreenBackGround = localStorage.getItem("isScreenBackGround");
            if(isScreenBackGround == null){
                if(!isExistError) this.socketReconnect();
            } else { //If screen in smartPhone run to background, do not reconnect here as reconnection will be done with the exclusive method
                localStorage.setItem("isScreenBackGround", null);
            }
        } 
    }
     
    backGroundCare = () =>{
        if (document.visibilityState === "hidden"){ //When screen run to background
            localStorage.setItem("isScreenBackGround", 1);
            this.props.websocket.close(); //Forcibly close websocket
            localStorage.setItem("isWebsocktOpen", 0);
            this.setState({ isProcessingForLoading: true }); // Prepare to show Processing modal when screen returned from background
        }
        if (document.visibilityState === "visible"){ //When screen returned from background
            if (isSessionExpired()) this.props.logoutUser(); 
                this.props.connect();
                const interval = setInterval(() => {
                    if (localStorage.getItem("isWebsocktOpen") == 1) {
                        this.setState({ isProcessingForLoading: false }); // Stop Processing modal
                        clearInterval(interval);
                    }
                  }, 50);
        } 
    }//added 20220720 White Out fix End

    socketReconnect = () => {
        this.props.websocket.close()
        this.props.connect();
        setTimeout(() => {
            this.initWSHandlers()
        }, 1000);
    }
    /**END OF SOCKETS */


    handleAcceptRequest = (notificationId, referenceId) => {
        let params = {
            notificationId: getObjKeyIdString(notificationId),
            ownerId: this.props.user.userId,
            referenceId: referenceId
        }

        this.setState({ isProcessing: true }, this.props.acceptFriendRequest(params, this.props.websocket));
    }

    handleRemoveRequest = (notificationId, referenceId) => {
        let params = {
            notificationId: getObjKeyIdString(notificationId),
            ownerId: this.props.user.userId,
            referenceId: referenceId
        }

        this.setState({ isProcessing: true }, this.props.removeFriendRequest(params, this.props.websocket));
    }

    toggleConfirmModal = () => {
        this.setState(prevState => ({ showConfirmModal: !prevState.showConfirmModal }));
    }

    toggleErrorModal = () => {
        this.setState(prevState => ({ showErrorModal: !prevState.showErrorModal }))
    }

    notificationEmptyCheck = () => {
        return 0 === this.props.notificationsList.length;
    }

    showSystemError = () => {
        this.setState({
            isFetching: false,
            initialLoaded: true,
            msgErrorModal: SYSTEM_ERROR,
            showErrorModal: true
        })
    }


    render() {
        let { notificationsList } = this.props;
        
        return (
            <Media>
                {({ breakpoints, currentBreakpoint }) => {
                    let mobileView = (breakpoints[currentBreakpoint] < breakpoints.mobileLandscape) || isMobile;
                    return (
                        <React.Fragment>
                            <ProcessLoader
                                isShown={this.state.isProcessing}
                            />

                            <ProcessLoaderForLoading
                                isShown={this.state.isProcessingForLoading}
                            />

                            <Card className="feed-container-item mt-0 mb-0 pc-border" style={mobileView ? { border: 'none' } : {}}>
                                <span className="feed-card-header bg-white border-bottom-grey notification-header" style={mobileView ? {textAlign: 'center'} : {}}>
                                    新着情報
                                </span>

                                {this.state.initialLoaded || 0 < notificationsList.length ? (
                                    this.notificationEmptyCheck() ? (
                                        <p className="align-self-center mt-3">{EMPTY_NOTIFICATION}</p>
                                    ) : (
                                            notificationsList && notificationsList.map((item, index) => (
                                                <UserNotification
                                                    key={"notif" + index}
                                                    {...item}
                                                    acceptFriendRequest={this.handleAcceptRequest}
                                                    removeFriendRequest={this.handleRemoveRequest}
                                                    hash={this.state.hash}
                                                />
                                            ))
                                        )
                                ) : (
                                        <NotificationSkeleton />
                                    )}

                                {(this.state.isFetching && this.state.initialLoaded) && (
                                    <center>
                                        <ActivityIndicator style={{ padding: 30 }} />
                                    </center>
                                )}
                            </Card>


                            {/* Confirm */}
                            {this.state.showConfirmModal &&(
                                <ConfirmationModal
                                    isModalShow={true}
                                    confirmationText={this.state.confirmMsg}
                                    confirmTitle={"はい"}
                                    closeBtnStyle={{ display: "none" }}
                                    handleConfirmAction={this.toggleConfirmModal}
                                    handleCloseModal={this.toggleConfirmModal}
                                />
                            )}
                            
                            {this.state.showErrorModal && (
                                <ErrorModal
                                    errorMessage={this.state.msgErrorModal}
                                    isErrorModalShow={true}
                                    handleCloseModal={this.toggleErrorModal}
                                />
                            )}
                        </React.Fragment>
                    )
                }}
            </Media>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.credentials,
        notificationsList: state.notifications.notificationsList,
        websocket: state.auth.websocket,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getNotifications: notificationOperations.getNotifications,
        updateNotificationStatus: notificationOperations.updateNotificationStatus,
        acceptFriendRequest: notificationOperations.acceptFriendRequest,
        removeFriendRequest: notificationOperations.removeFriendRequest,
        removeRequestNotification: notificationOperations.removeRequestNotification,
        connect: authOperations.connectWS,
        addReceivedNotification: notificationOperations.addReceivedNotification,
        setLastSeen: notificationOperations.setLastSeen,
        logoutUser: authOperations.logoutUser //White out fix
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
import React from 'react';
import Modal from "react-modal";
import { View, Image, TouchableOpacity } from "react-native";
import { randomMessageCreateForm, randomMessageUpdateForm, recipientTable, radioInput } from './state/templates';
import { isNullOrUndefined, formatDeliveryDate } from "../../../utils/common";
import TableTemplate from '../../components/TableTemplate';
import PaginationTemplate from '../../components/PaginationTemplate';
import FormTemplate from '../../components/FormTemplate';
import CloseIcon from "../../../includes/img/icons/close.svg";
import "./styles/randomMessage.scss"
import styles from "../../components/ConfirmationModal/styles.native"
import { selectors } from './state';
import { DELIVERY_HISTORY_MAX_DISPLAY_ROWS } from '../../../utils/constants';

const RandomMessageModal = (props) => {
    let deliveryHistoryTotalPages = (!isNullOrUndefined(props.currRandomMsg) && 0 < props.currRandomMsg.length) || !isNullOrUndefined(props.currRandomMsg.deliveryHistory)? Math.ceil(props.currRandomMsg.deliveryHistory.length / DELIVERY_HISTORY_MAX_DISPLAY_ROWS) : 0;
  
    /**Set messageImage default */
    if(isNullOrUndefined(props.currRandomMsg.messageImage)){
        props.currRandomMsg.messageImage = props.currRandomMsg.image
    }

    /**
     *  Supply default values to form inputs
     */
    const setFormValues = (createForm) => {
        createForm.form.map(input => {
            if('種類' === input.label) {
                input.default = ('全員' === props.currRandomMsg.type || '1' === props.currRandomMsg.type) ? '1' : '0';
            }
            else if('送信日時' === input.label && "multiple-text" === input.type) {
                input.children.map(child => {
                    if('年' === child.label) {
                        child.default = props.currRandomMsg.deliveryYear;
                    }
    
                    else if('月' === child.label) {
                        if(!isNullOrUndefined(props.currRandomMsg)) {
                            child.default = props.currRandomMsg.deliveryMonth  || ""
                        }
                    }
    
                    else if('日' === child.label) {
                        child.default = props.currRandomMsg.deliveryDay || "";
                    }
    
                    else if('時' === child.label) {
                        if('全員' === props.currRandomMsg.type) {
                            child.default = props.currRandomMsg.deliveryHour || "0";
                        } else {
                            child.default = props.currRandomMsg.deliveryHour || "";
                        }
                    }
    
                    else if('分' === child.label) {
                        if('全員' === props.currRandomMsg.type) {
                            child.default = props.currRandomMsg.deliveryMinute || "0";
                        } else {
                            child.default = props.currRandomMsg.deliveryMinute || "";
                        }
                    }
                    return child;
                })
                input.default = formatDeliveryDate(props.currRandomMsg);
            } 
            else if('本文' === input.label) {
                input.default = props.currRandomMsg.messageBody ? props.currRandomMsg.messageBody : '';
            } 
            else if('写真' === input.label) {
                input.default = props.currRandomMsg.messageImage ? props.currRandomMsg.messageImage : '';
            }
            else if('配信' === input.label) {
                input.default = ('配信する' === props.currRandomMsg.deliveryStatus || '1' === props.currRandomMsg.deliveryStatus) ? '1' : '0';
            }
            return input;
        });

        return createForm;
    }

    const resetFormValues = (form) => {
        form.form.map(input => {
            if('radio' !== input.type) {
                input.default = '';
                if(input.hasOwnProperty('children')) {
                    input.children.map(child => {
                        child.default = '';
                        return child;
                    });
                }
            }
            return form;
        });
        return form;
    }

    const initUpdateForm = (updateForm) => {
        let index = updateForm.form.findIndex(input => '配信' === input.label);
        updateForm.form.find(formInput => formInput.name === 'recipientTable').label="配信履歴";
        updateForm.form[index].default = ('配信する' === props.currRandomMsg.deliveryStatus) ? '1' : '0';
        updateForm.form[index].type = "radio";
        return updateForm;
    }

    const handleShowEditForm = () => {
        props.handleShowEditModal();
    }

    const setFormProps = () => {
        switch(props.type) {
            case 'create': {
                return {
                    title: 'メッセージ作成・編集',
                    validate: selectors.validateRandomMessage,
                    formInputs: 'confirm' === props.prevModal ? setFormValues(randomMessageCreateForm) : resetFormValues(randomMessageCreateForm),
                    radioInput: radioInput,
                    handleSubmit: (row) => props.handleSubmit(row),
                    formButtons: [
                        { type: "primary", label: "確認する", submit: props.handleConfirm },
                        { type: "secondary", label: "戻る", onClick: props.handleCloseModal }
                    ]
                }
            }
            case 'update': {
                let customRandomMessageForm = Object.assign({}, randomMessageUpdateForm);
                customRandomMessageForm.form[0].name="";
                const updateModalTitle = props.currRandomMsg.hasSent ? "メッセージ【詳細】" : "メッセージ作成・編集";

                return{
                    title: updateModalTitle,
                    validate: props.currRandomMsg.hasSent ? () => {} : selectors.validateRandomMessage,
                    handleUpdateDeliveryStatus: props.handleUpdateDeliveryStatus,
                    formInputs: props.currRandomMsg.hasSent ? initUpdateForm(customRandomMessageForm) : setFormValues(randomMessageCreateForm),
                    radioInput: radioInput,
                    table: (
                        <TableTemplate
                            onClick={(row) => {props.redirectUser(row)}}
                            tableHeader={recipientTable.tableHeaders}
                            tableColumns={recipientTable.tableColumns}
                            tableList={props.recipientList ? props.recipientList : []}
                            style={{ width: "72%", marginLeft: "27%", float: "left" }}
                        />
                    ),
                    pagination: "全員" !== props.currRandomMsg.type && (
                        <PaginationTemplate
                            currentPage={props.currentPage}
                            totalPage={deliveryHistoryTotalPages}
                            handlePageChange={(page) => { props.handlePageChange(page) }}
                        />
                    ),
                    updatePreview: true,
                    handleSubmit: (row) => props.handleSubmit(row),
                    formButtons: [
                        { type: "primary", label: "確認する", submit: props.handleConfirm }, 
                        { type: "secondary", label: "戻る", onClick: props.handleCloseModal }
                    ]
                }
            }
            case 'confirmUpdate': {
                //Update form definition
                let customRandomMessageForm = Object.assign({}, randomMessageUpdateForm);
                customRandomMessageForm.form[0].name="";
                customRandomMessageForm.form.find(formInput => formInput.name === 'recipientTable').label="";
                customRandomMessageForm.form.find(formInput => formInput.name === 'deliveryStatus').type="values";

                return{
                    title: 'メッセージ作成・編集',
                    preview: true,
                    validate: selectors.validateRandomMessage,
                    formInputs: customRandomMessageForm,
                    radioInput: [],
                    formButtons: [
                        { type: "primary", label: "確定する", onClick: props.handleUpdate },
                        { type: "secondary", label: "戻る", onClick: handleShowEditForm }
                    ]
                }
            }
            case 'confirmDelete': {
                //Update form definition
                let customRandomMessageForm = Object.assign({}, randomMessageUpdateForm);
                customRandomMessageForm.form[0].name="confirmDeleteMsg";
                customRandomMessageForm.form.find(formInput => formInput.name === 'recipientTable').label="";
                customRandomMessageForm.form.find(formInput => formInput.name === 'deliveryStatus').type="values"

                return{
                    title: 'メッセージ削除',
                    preview: true,
                    validate: selectors.validateRandomMessage,
                    formInputs: customRandomMessageForm,
                    radioInput: [],
                    formButtons: [
                        { type: "primary", label: "削除する", onClick: (index) => { props.handleDbDelete(index) }},
                        { type: "secondary", label: "戻る", onClick: props.handleCloseModal }
                    ]
                }
            }
            case 'successUpdate': {
                //Update form definition
                let customRandomMessageForm = Object.assign({}, randomMessageUpdateForm);
                customRandomMessageForm.form[0].name="successUpdateMsg";

                return{
                    title: 'メッセージ作成・編集',
                    preview: true,
                    formInputs: customRandomMessageForm,
                    radioInput: [],
                    currRandomMsg: props.currRandomMsg,
                    formButtons: [
                        { type: "secondary", label: "閉じる", onClick: props.handleCloseModal}
                    ]
                }
            }
            case 'successDelete': {
                //Update form definition
                let customRandomMessageForm = Object.assign({}, randomMessageUpdateForm);
                customRandomMessageForm.form[0].name="successDeleteMsg";

                return{
                    title: 'メッセージ削除',
                    preview: true,
                    formInputs: customRandomMessageForm,
                    formButtons: [
                        { type: "secondary", label: "閉じる", onClick: props.handleCloseModal}
                    ]
                }
            }
            default: return{
                formInputs: randomMessageUpdateForm,
                radioInput: radioInput
            };
        }
    }

    return (
        <View>
            <Modal
                ariaHideApp={false}
                isOpen={props.isModalShow}
                className="randomMessage-modal"
                overlayClassName="randomMessage-modal-overlay"
            >
                <TouchableOpacity
                    style={styles.modalClose}
                    onPress={props.handleCloseModal}
                >
                    <Image source={CloseIcon} alt="close-icon" style={styles.closeIcon} />
                </TouchableOpacity>
                <View>

                {/**Render Form */}
                <FormTemplate
                    key={props.type}
                    validate={selectors.validateRandomMessage}
                    currRandomMsg={props.currRandomMsg}
                    {...setFormProps()}
                />
                </View>
            </Modal>
        </View>    
    );
};

export default RandomMessageModal;
import React, { Component } from 'react';
import { SNS_FACEBOOK_URL } from '../../../config/settings';
import FacebookIcon from "../../../includes/img/user-menu/facebook.svg";
import SNSHeader from './SNSHeader';
import "./styles/styles.scss"
import { onApiSuccess, isNullOrUndefined, defaultTopScroll, isScrollRefetchReached } from '../../../utils/common';
import { SYSTEM_ERROR, EXCEED_FACEBOOK_API_CALL_LIMIT } from '../../../utils/messages';
import { snsOperations } from "./state";
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import { API_LIMIT_REACHED_CODE, EMPTY_STRING, PAGE_API_LIMIT_REACHED_CODE_1,  PAGE_API_LIMIT_REACHED_CODE_2  } from '../../../utils/constants';
import ErrorModal from '../../components/ErrorModal';
import { ActivityIndicator } from 'react-native';
import FacebookPostItemTemplate from './FacebookPostItemTemplate';
import { isMobile } from "react-device-detect";

/**For scroll direction check (Landscape) */
var scrollPos = 0;

class Facebook extends Component {
    constructor(props){
        super(props)
        this.state = {
            posts: [],
            accessToken: null,
            nextURL: null,
            errorMessage: null,
            isErrorModalShow: false,
            isPostFetching: true,
            snsImageUrl: FacebookIcon,
            snsName: "Facebook",
            snsAccount: "@fumiyafujiiofficial",
            pageImageUrl: null,
            pageName: null,
            isLandscape: false
        }
    }

    handleRedirect = () => {
        window.open(SNS_FACEBOOK_URL)
    }

    componentDidMount(){
        defaultTopScroll();
        
        this.props.getFacebookAccessToken().then(response=>{
            if(onApiSuccess(response)){
                let data = JSON.parse(response.data.data)
                this.setState({
                    accessToken: data[0].token
                },()=>{
                  this.props.getFacebookPage(this.state.accessToken).then(response=>{
                        if(isNullOrUndefined(response.error)){
                            let pageInfo = response.data
                            this.setState({
                                pageImageUrl : pageInfo.picture.data.url,
                                pageName: pageInfo.name
                            }, ()=>{
                                this.props.getFacebookPosts(this.state.accessToken).then(response=>{
                                    if(isNullOrUndefined(response.error)){
                                        let posts = response.data.data;
                                        let nextURL = response.data.paging.next;
                                        this.setState({
                                            posts,
                                            nextURL,
                                            isPostFetching: false
                                        })
                                    }else if(!isNullOrUndefined(response.error) && (PAGE_API_LIMIT_REACHED_CODE_1 === response.error.code || PAGE_API_LIMIT_REACHED_CODE_2 === response.error.code || API_LIMIT_REACHED_CODE  === response.error.code)){
                                        this.setState({
                                            isErrorModalShow: true,
                                            errorMessage: EXCEED_FACEBOOK_API_CALL_LIMIT
                                        })
                                    }else{
                                        this.setState({
                                            isErrorModalShow: true,
                                            errorMessage: SYSTEM_ERROR
                                        })
                                    }
                                })
                            })
                        }else if(!isNullOrUndefined(response.error) && (PAGE_API_LIMIT_REACHED_CODE_1 === response.error.code || PAGE_API_LIMIT_REACHED_CODE_2 === response.error.code || API_LIMIT_REACHED_CODE  === response.error.code)){
                            this.setState({
                                isErrorModalShow: true,
                                errorMessage: EXCEED_FACEBOOK_API_CALL_LIMIT
                            })
                        }else{
                            this.setState({
                                isErrorModalShow: true,
                                errorMessage: SYSTEM_ERROR
                            })
                        }
                  })
                })
            }else{
                this.setState({
                    isErrorModalShow: true,
                    errorMessage: SYSTEM_ERROR
                })
            }
        })
        document.getElementById("scrollable").addEventListener('scroll', this.onScrollCheck);
        document.addEventListener('scroll', this.onBodyScrollCheck);

        //Listener for orientation
        var that = this;
        // Find matches
        var mql = window.matchMedia("(orientation: landscape)");
        this.setState({isLandscape: mql.matches})

        // Add a media query change listener
        mql.addListener(function(m) {
            if(m.matches !== that.state.isLandscape){
                that.setState({isLandscape: m.matches})
            }
        });
    }

    isFBScrollRefetchReached = () => {
        //Check if bottom is reached
        let rootElement = document.getElementById('scrollable');
        return rootElement.scrollTop >= (rootElement.scrollHeight - rootElement.offsetHeight);
    }

    onBodyScrollCheck = ()=>{
        if(isMobile && this.state.isLandscape){
            let isScrollDown = (document.body.getBoundingClientRect()).top < scrollPos;

            //Check if within scroll refetch, no concurrent post fetch, and limit not reached
            if (isScrollRefetchReached() && !this.state.isPostFetching && isScrollDown) {
                this.handlePagination();
            }
        }
        scrollPos = (document.body.getBoundingClientRect()).top;
    }

    onScrollCheck = () => {
        //Check if within scroll refetch, no concurrent post fetch, and limit not reached
        if (this.isFBScrollRefetchReached() && !this.state.isPostFetching) {
            this.handlePagination();
        }
    }

    handleCloseModal = () =>{
        this.setState({
            isErrorModalShow: false,
            errorMessage: EMPTY_STRING
        })
    }

    handlePagination = () => {
        if(!isNullOrUndefined(this.state.nextURL)){
            this.setState({
                isPostFetching: true
            }, ()=>{
                this.props.getPaginatedFacebookPosts(this.state.nextURL).then(response=>{
                    if(isNullOrUndefined(response.error)){
                        let posts = response.data.data;
                        let nextURL = response.data.paging.next;
                        this.setState({
                            posts : [...this.state.posts, ...posts],
                            nextURL,
                            isPostFetching: false
                        })
                    }else if(!isNullOrUndefined(response.error) &&  (PAGE_API_LIMIT_REACHED_CODE_1 === response.error.code || PAGE_API_LIMIT_REACHED_CODE_2 === response.error.code || API_LIMIT_REACHED_CODE  === response.error.code)){
                        this.setState({
                            isErrorModalShow: true,
                            errorMessage: EXCEED_FACEBOOK_API_CALL_LIMIT
                        })
                    }
                    else{
                        this.setState({
                            isErrorModalShow: true,
                            errorMessage: SYSTEM_ERROR
                        })
                    }
                })
            })
        }
    }

    componentWillUnmount() {
        document.getElementById("scrollable").removeEventListener('scroll', this.onScrollCheck);
        document.removeEventListener('scroll', this.onBodyScrollCheck);
    }
    

    render() {
            return(
                <div className="sns-container" id="sns-container">
                    <SNSHeader
                        snsUrl={SNS_FACEBOOK_URL}
                        snsName={this.state.snsName}
                        snsIcon={this.state.snsImageUrl}
                        snsAccount={this.state.snsAccount}
                    />
                    <div className="sns-facebook-container" id="scrollable">
                        <div className="feed-main">
                            {0 < this.state.posts.length ? (
                                this.state.posts.map((details, i) => {
                                  return(
                                    <div className="facebook-post-container" onClick={()=>this.handleRedirect()}>
                                        <FacebookPostItemTemplate 
                                            snsUrl={SNS_FACEBOOK_URL}
                                            snsName={this.state.snsName}
                                            snsIcon={this.state.snsImageUrl}
                                            snsAccount={this.state.snsAccount}
                                            pageName={this.state.pageName}
                                            pageImageUrl={this.state.pageImageUrl}
                                            key={details.id} 
                                            {...details} 
                                        />
                                        <div className="overlay"></div>
                                    </div>
                                  )
                                }
                              )
                            ) : (
                                    null
                                )}
                        </div>
                        <center>
                        <ActivityIndicator
                            style={{
                                height: 50,
                                display: this.state.isPostFetching ? "block" : "none"
                            }}
                        />
                    </center>
                    </div>
                    <ErrorModal
                        {...this.state}
                        handleCloseModal = {this.handleCloseModal}
                        customContent={null}
                        errorMessage={this.state.errorMessage}
                    />
            </div>
        )
    }
}

//Bind dispatch to action creator
  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        getFacebookAccessToken: snsOperations.getFacebookAccessToken,
        getFacebookPage: snsOperations.getFacebookPage,
        getFacebookPosts: snsOperations.getFacebookPosts,
        getPaginatedFacebookPosts: snsOperations.getPaginatedFacebookPosts
      },
      dispatch
    );
  
  export default connect(null, mapDispatchToProps)(Facebook);

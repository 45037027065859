import ViewPost from "./ViewPost";
import PostDetails from "./PostDetails";

export const routes = [
    {
        path: "/post/view/:id",
        component: ViewPost,
        exact: true,
        showNav: true
    },
    {
        path: "/post/view/past/:id",
        component: ViewPost,
        exact: true,
        showNav: true
    },
    {
        path: "/communities/:communityId/post/:id",
        component: ViewPost,
        exact: true,
        showNav: true
    },
    {
        path: "/post/create",
        component: PostDetails,
        exact: true,
        showNav: true
    },
    {
        path: "/post/edit/:postId",
        component: PostDetails,
        exact: true,
        showNav: true
    },
    {
        path: "/livestream",
        component: PostDetails,
        exact: true,
        showNav: true,
        
    },
]
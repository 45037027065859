import { StyleSheet } from "react-native";
import { Dimensions } from 'react-native';

const window = Dimensions.get("window");

const styles = StyleSheet.create({
  listItemContainer: {
    paddingLeft: 12,
    paddingRight: 12, 
    margin: 0,
    height: 52,
    display: 'flex',
    justifyContent: 'center'
  },
  listItemContainerPC: {
    paddingTop: 10,
    paddingBottom: 10
  },
  listItem: {
    padding: 0,
    paddingLeft: 2,
    margin: 0
  },
  listContainerPC: {
    height: "78vh"
  },
  listContainerMobile: {
    height: window.height - 160,
  },
  titleMobile: {
    marginLeft: 20,
    color: "#0A182C",
    fontFamily: "kozuka-gothic-pro, sans-serif",
    fontSize: 16,
    lineHeight: "inherit",
    fontVariant: ["proportional-width"],
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginRight: 10
  },
  titlePC: {
    marginLeft: 20,
    color: "#0A182C",
    fontFamily: "kozuka-gothic-pro, sans-serif",
    fontSize: 18,
    lineHeight: "inherit",
    fontVariant: ["proportional-width"],
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginRight: 10
  },
  chatListTitleMobile: {
    marginLeft: 16,
    color: "#0A182C",
    fontFamily: "kozuka-gothic-pro, sans-serif",
    fontSize: 16,
    maxWidth: 350
  },
  chatListTitlePC: {
    marginLeft: 16,
    color: "#0A182C",
    maxWidth: 350,
    fontFamily: "kozuka-gothic-pro, sans-serif",
    fontSize: 18,
  },
  subtitleMobile:{
    marginLeft: 20,
    marginRight: 20,
    color: "#0A182C",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "kozuka-gothic-pro, sans-serif",
    fontVariant: ["proportional-width"],
    fontSize: 12
  },
  subtitlePC:{
    fontVariant: ["proportional-width"],
    marginLeft: 20,
    marginRight: 20,
    color: "#0A182C",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "kozuka-gothic-pro, sans-serif",
    fontSize: 16
  },
  actionContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  newChatIcon: {
    height: 22,
    width: 22
  },
  menuIcon: {
    height: 7,
    width: 25,
    marginTop: 10,
    marginRight: 25,
    resizeMode: "stretch"
  },
  deleteIcon: {
    height: 24,
    width: 21,
    marginRight: 10
  },
  horizontalBorder: {
    borderBottomColor: "#EAEDED",
    borderBottomWidth: 1,
    width: "100%",
    alignSelf: "center"
  },
  avatar: {
    width: 70,
    height: 70
  },
  cancelBtn: {
    backgroundColor: "transparent",
    borderRadius: 10,
    width: 82,
    height: 25,
    borderColor: "#707070",
    padding: 5
  },
  cancelBtnPC: {
    backgroundColor: "transparent",
    borderRadius: 5,
    width: 85,
    height: 25,
    borderColor: "#707070",
    padding: 5
  },
  cancelBtnTitle: {
    fontSize: 12,
    color: "#606770"
  },
  emptyList: {
    textAlign: "center"
  },
  seenChat: {
    paddingTop: 10,
    paddingBottom : 10,
    paddingLeft: 10,
  },
  unSeenChat: {
    backgroundColor: "#F4F4F4",
    paddingTop: 10,
    paddingBottom : 10,
    paddingLeft: 10
  },
  seenChatPC: {
    backgroundColor: "#FFFFFF",
    paddingLeft: 0,
    paddingTop: 5,
    paddingBottom: 5
  },
  unSeenChatPC: {
    backgroundColor: "#F4F4F4",
    paddingLeft: 0,
    paddingTop: 10,
    paddingBottom: 10
  },
  seenChatItem:{
      backgroundColor: "#FFFFFF",
      padding: 0,
  },
  unseenChatItem: {
    backgroundColor: "#F4F4F4",
    padding: 0,
  },
  flatlistContainer : {
    flex: 1, 
    paddingBottom: 4
  }
});

export default styles;

const styles = {
    count:{
        color: "#fff", 
        fontSize: 36 
    },
    countOverlay: {
        backgroundColor: 'rgba(0,0,0,0.4)',
        width: 'calc(50% - 6px)',
        height: '33%',
        position: 'absolute',
        right: 0,
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center'
    },
    countOverlayMobile: {
        backgroundColor: 'rgba(0,0,0,0.4)',
        width: 'calc(50% - 4px)',
        height: '33%',
        position: 'absolute',
        right: 0,
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center'
    },
    halfGridVertical:{
        height: '49.6%', 
        width: '100%', 
        margin: 0
    },
    halfGridHorizontal:{
        // height: '100%', 
        maxHeight: 350,
        width: '49.6%', 
        margin: 0
    },
    halfGrid:{
        height: '49.6%', 
        width: '49.6%', 
        margin: 0
    },
    halfGridHeight:{
        maxHeight: 350,
        width: '49.6%', 
        margin: 0
    },
    fullGrid:{
        height: '100%', 
        width: '100%', 
        maxHeight: 700,
        margin: 0
    },
    trioGridVertical:{
        height: '32.8%', 
        width: '100%', 
        paddingLeft: 6
    },
    trioGridVerticalMobile:{
        height: '32.8%', 
        width: '100%', 
        paddingLeft: 4
    }
};

export default styles;
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { mypageOperations } from './state';
import { TextInput } from 'react-native';
import { connect } from 'react-redux';
import { Card, Image } from 'react-bootstrap';
import { appendUserRoute, onApiSuccess, getObjKeyIdString, defaultTopScroll, uploadToS3, objKeyExist, isStringNullEmptyOrUndefined, isScrollRefetchReached } from '../../../utils/common';
import { Link } from 'react-router-dom';
import GalleryIcon from '../../../includes/img/icons/gallery.svg';
import PCOnlyView from '../../components/PCOnlyView';
import PostItemTemplate from '../../components/PostItemTemplate';
import './styles/create.scss';
import './styles/info.scss';
import './styles/feed.scss';
import { Media } from 'react-breakpoints'
import { isMobile } from "react-device-detect";
import MyPageHeaderComponent from "./MyPageHeaderComponent"
import MyPageDetailComponent from "./MyPageDetailComponent"
import MyPageFriendsComponent from './MyPageFriendsComponent';
import ErrorModal from '../../components/ErrorModal';
import { EMPTY_STRING, IMAGE_TYPE, WEBSOCKET_MESSAGES, THUMBNAIL_SMALL, S3_STATUS_SUCCESS, ACCEPTED_IMAGE_TYPES, S3_PROFILE_THUMBNAIL_BUCKET_URL } from '../../../utils/constants';
import { SYSTEM_ERROR, MSG_NO_POST_YET } from '../../../utils/messages';
import { selectors } from "./state"
import {addDefaultSrc, fetchEmptyScroll} from "../../../utils/common"
import { ActivityIndicator } from 'react-native';
import MyFriendsSkeleton from '../../components/Skeletons/MyFriendsSkeleton';
import { isNullOrUndefined } from 'util';
import { authOperations } from '../Authentication/state';
import { profileOperations } from '../EditProfile/state';
import ProfileIcon from '../../../includes/img/placeholders/user.svg';
import ProcessLoader from '../../components/ProcessLoader';
import ConfirmationModal from '../../components/ConfirmationModal';
import {TouchableOpacity} from 'react-native';
import styles from './styles/mypage.styles.native';
import { isSessionExpired} from "../../../utils/common"; // Whiete Out fix
import ProcessLoaderForLoading from '../../components/ProcessLoaderForLoading'; // Whiete Out fix
import PostDetailModal from '../../components/PostDetailModal'; //task#363


const CreatePostArea = (props) => {
    let appendedRoute = appendUserRoute("/mypage");
    let createPostRoute = appendUserRoute("/post/create");
    let isProfileImageUrlValid = props.profileImageUrl && 0 <= props.profileImageUrl.indexOf("Profile/");
    let uploadImageRef = React.createRef();

    return (
        <Media>
            {({ breakpoints, currentBreakpoint }) => {
                let mobileView = (breakpoints[currentBreakpoint] < breakpoints.mobileLandscape) || isMobile;
                return (
                    <Card className="mypage-feed-container-item" style={mobileView ? { border: 'none' } : {}}>
                        <PCOnlyView>
                            <span className="feed-card-header">投稿を作成する</span>
                        </PCOnlyView>
                        <Card.Body className="feed-card-container">
                            <Link key={appendedRoute + "main"} href={appendedRoute} to={appendedRoute} className="user-main-profile mr-3">
                                <Image  src={isProfileImageUrlValid ? S3_PROFILE_THUMBNAIL_BUCKET_URL +  props.ownerId + "/" + props.ownerId + THUMBNAIL_SMALL + "?" + props.profileHash : ProfileIcon} onError={addDefaultSrc} className="mypage-profile-icon" roundedCircle alt="author-profile" />
                            </Link>
                            <Link key={createPostRoute + "input"} href={createPostRoute} to={createPostRoute} className="feed-card-create">
                                <TextInput
                                    name="createPost"
                                    placeholder={"日記を投稿しよう"}
                                    editable={false}
                                    style={styles.createInput}
                                />
                            </Link>
                            <TouchableOpacity activeOpacity={0.7} onPress={() => uploadImageRef.current.click()}>
                                <Image src={GalleryIcon} alt="camera-icon" className="mypage-create-camera" />
                            </TouchableOpacity>

                            <input
                                multiple
                                accept={ACCEPTED_IMAGE_TYPES.join(",")}
                                ref={uploadImageRef}
                                type="file"
                                id="uploadImageRef"
                                onChange={(event)=>{
                                    props.history.push({
                                        pathname: appendUserRoute("/post/create"),
                                        state: {
                                            preloadImages: event.target.files
                                        }
                                        
                                    })}
                                }
                                style={{ display: "none" }}
                            />
                        </Card.Body>
                    </Card>
                )
            }}
        </Media>
    )
}


class MyPage extends Component {
    constructor(props){
        super(props)
        this.state = {
            ownerId: props.userId,
            handleName: props.handleName,
            userModel: props.userInformation,
            randomFriends: [],
            currentPagination : 1,
            privacyType: null,
            postList: [],
            imageFile: null,
            isLoading: true,
            errorMessage: EMPTY_STRING,
            isErrorModalShow: false,
            errors : {},
            isOpenMenu: false,
            isCoverPhotoChange: false,
            isProfilePhotoChange: false,
            coverHash: Date.now(),
            profileHash : "",
            isPostFetching: true,
            isPreview: false,
            isPostDeletion: false,
            isLastPage: false,
            isDeletingPost: false,
            showDeleteModal: false,
            deletePostId: "",
            newPost: null,
            isProcessingForLoading: false, //White Out fix
            showPostDetailModal: false,//task#363
            postDetailPostId: "" //task#363
        }
    }

    componentDidMount(){
        defaultTopScroll();

        //Check if from Created Post
        let redirectState = this.props.location.state;
        if(redirectState && objKeyExist(redirectState, "newPost")){
            this.setState({newPost: redirectState.newPost._id})
        }
        
        this.props.getUserProfile(this.state.ownerId).then(response => {
            if(!onApiSuccess(response)) {
                this.setState({
                    isErrorModalShow: true,
                    errorMessage: SYSTEM_ERROR
                })
            }
        })

        this.props.getRandomFriends(this.state.ownerId).then(response => {
            if(onApiSuccess(response)){
                let data = JSON.parse(response.data.data)
                let friendsList= data.friends
                let friendsData = {
                    "count" : data.count,
                    "friends" : friendsList
                }
                this.setState({
                    randomFriends : friendsData
                })
            }else{
                this.setState({
                    isErrorModalShow : true,
                    errorMessage: SYSTEM_ERROR
                })
            }
        })
        
        this.props.getUserPosts(this.state.ownerId, this.state.privacyType, this.state.currentPagination)
            .then(response => {
                if(onApiSuccess(response)){
                    let postListData = JSON.parse(response.data.data)
                    this.setState({
                        postList : postListData,
                        isLoading: false,
                        isPostFetching: false,
                        currentPagination: this.state.currentPagination + 1
                    }, ()=>{
                        /**Assumed 2nd page after success fetch */
                         if(2 === this.state.currentPagination && !isStringNullEmptyOrUndefined(this.state.newPost)){
                             //Scroll to newly created post if set
                            
                             let newPostElement = document.getElementById(this.state.newPost);
                             if(newPostElement){
                                newPostElement.scrollIntoView({behavior: "smooth"});
                             }
                         }
                    
                    })
                 }else{
                    this.setState({
                        isErrorModalShow : true,
                        isPostFetching: false,
                        errorMessage: SYSTEM_ERROR
                    })
                }
            })
        document.addEventListener('scroll', this.onScrollCheck);

        document.addEventListener("visibilitychange", this.backGroundCare); //White Out fix
        
        /**Sockets */
        if (!isNullOrUndefined(this.props.websocket) && this.props.websocket.readyState === WebSocket.OPEN) {
            this.initWSHandlers()
        }
        /***/
    }

    componentDidUpdate(){
        this.initWSHandlers()
    }

    /** SOCKETS for thumbnails*/
    initWSHandlers() {
        this.props.websocket.onmessage = (data) => {
            let serverData = JSON.parse(data.data);    
            let responseData = JSON.parse(serverData.data)

            // check if required fields is included on the message
            if (WEBSOCKET_MESSAGES.onNotifications === responseData.action) {
                this.props.incrementNotifCount(responseData.referenceId)
            }else if (WEBSOCKET_MESSAGES.onChatNotification === responseData.action) {
                this.props.incrementChatThreadCount(responseData.senderId, responseData.threadId)
            }else if (WEBSOCKET_MESSAGES.onPostImageRender === responseData.action) {
                this.updatePostImageSocket(responseData.postId, responseData.imageUrl, responseData.imageStatus)
            }else if(WEBSOCKET_MESSAGES.onPostVideoRender === responseData.action){
                this.updatePostVideoSocket(responseData.postId, responseData.videoUrl, responseData.videoStatus)
            }else if(WEBSOCKET_MESSAGES.onProfileUpdate === responseData.action){
                //Used to update PCSideNav Profile Image Url
                this.props.updateProfileImage(this.state.ownerId)

                //Update profileHash first to initiate image display
                this.setState({profileHash: Date.now()}, ()=>{
                    setTimeout(()=>{
                        this.setState({isProfilePhotoChange: false})
                    }, 1000)
                })
            }else if(WEBSOCKET_MESSAGES.onCoverUpdate === responseData.action){
                //Update profileHash first to initiate image display
                this.setState({coverHash: Date.now()}, ()=>{
                    setTimeout(()=>{
                        this.setState({isCoverPhotoChange: false})
                    },1000)
                })
            }
    
            let isExistError = false; //added 20220720 White Out fix (caused by reconnection loop)
            this.props.websocket.onerror = () => { isExistError = true } //mod 20220617 White Out fix (caused by reconnection loop) Start.
            this.props.websocket.onclose = () => {
                let isScreenBackGround = localStorage.getItem("isScreenBackGround");
                if(isScreenBackGround == null){
                    if(!isExistError) this.socketReconnect();
                } else { //If screen in smartPhone run to background, do not reconnect here as reconnection will be done with the exclusive method
                    localStorage.setItem("isScreenBackGround", null);
                }
            } 
        }
    }

    backGroundCare = () =>{
        if (document.visibilityState === "hidden"){ //When screen run to background
            localStorage.setItem("isScreenBackGround", 1);
            this.props.websocket.close(); //Forcibly close websocket
            localStorage.setItem("isWebsocktOpen", 0);
            this.setState({ isProcessingForLoading: true }); // Prepare to show Processing modal when screen returned from background
        }
        if (document.visibilityState === "visible"){ //When screen returned from background
            if (isSessionExpired()) this.props.logoutUser(); 
                this.props.connect();
                const interval = setInterval(() => {
                    if (localStorage.getItem("isWebsocktOpen") == 1) {
                        this.setState({ isProcessingForLoading: false }); // Stop Processing modal
                        clearInterval(interval);
                    }
                  }, 50);
        } 
    }//added 20220720 White Out fix End

    /*Socket actions*/
    updatePostImageSocket = (postId, imageUrl, imageStatus) =>{
        let updatedPosts = Object.assign([], this.state.postList); 
        let postSocket = updatedPosts.filter(i => getObjKeyIdString(i._id) === getObjKeyIdString(postId));
        if(0 < postSocket.length){
            let selectedPost = postSocket[0];
            let updatePhoto = selectedPost.photos.filter(i => i.imageUrl === imageUrl)
            let photoDisplayIndex = selectedPost.photos.indexOf(updatePhoto[0]);
            //Rerender status on page only if within index 0-4 only
            if (0 < updatePhoto.length && 0 <= photoDisplayIndex && 4 >= photoDisplayIndex) {
                updatePhoto[0].imageStatus = imageStatus
                selectedPost.latestUpdate = new Date();
            }
        }
        setTimeout(()=>{
            this.setState({
                postList: updatedPosts
            })
        }, 2500)
        
        
    }

    updatePostVideoSocket = (postId, videoUrl, videoStatus) => {
        let updatedPosts = Object.assign([], this.state.postList); 
        let postSocket = updatedPosts.filter(i => getObjKeyIdString(i._id) === getObjKeyIdString(postId));
        if(0 < postSocket.length){
            let selectedPost = postSocket[0];
            selectedPost.videoUrl = videoUrl;
            selectedPost.videoStatus = videoStatus;
        }
        this.setState({
            postList: updatedPosts
        })
    }

    
    socketReconnect = () => {
        this.props.websocket.close()
        this.props.connect();
        setTimeout(() => {
            this.initWSHandlers()
        }, 1000);
    }
    /**END OF SOCKETS */

    componentWillUnmount(){
        document.removeEventListener('scroll', this.onScrollCheck);
        document.removeEventListener("visibilitychange", this.backGroundCare); //White Out fix
    }

    onScrollCheck = () =>{
        if(!this.state.isLoading && isScrollRefetchReached()){
            this.handlePaginatedPost();
        }
    }

    handleImageValidation = (file) => {
        let errors = selectors.validateImageUpdate(file)
        return errors
    }


    handleCoverImageChange = (event, type) => {
      
        if(event.target.files[0]){
            let errors = this.handleImageValidation(event.target.files[0])
            if(0 === Object.keys(errors).length){
                this.setState({
                    imageFile : event.target.files[0],
                    isCoverPhotoChange : true
                }, ()=>{
                    this.handleImageChangeSubmit(event,type)
                })
              
            }else{
                this.setState({
                    isErrorModalShow : true,
                    errorMessage: errors.image
                })
            }
        } 
        event.target.value = EMPTY_STRING
    }
    
    
    handleProfileImageChange = (event, type) => {
        if(event.target.files[0]){
            let errors = this.handleImageValidation(event.target.files[0])
            if(0 === Object.keys(errors).length){
                this.setState({
                    imageFile : event.target.files[0],
                    isProfilePhotoChange : true
                }, ()=>{
                    this.handleImageChangeSubmit(event,type)
                })
            }else{
                this.setState({
                    isErrorModalShow : true,
                    errorMessage: errors.image
                })
            }
        }
        event.target.value = EMPTY_STRING
    }

    handleImageChangeSubmit = (e, type) => {
        this.props.uploadImageChange(this.state.imageFile, this.state.ownerId, type).then(response=>{
            if(onApiSuccess(response)){
                let url = JSON.parse(response.data.data)
                Promise.resolve(uploadToS3(url[0], this.state.imageFile))
                .then(pRes => {
                    if(S3_STATUS_SUCCESS === pRes.status && isNullOrUndefined(pRes.statusError)){
                        if(IMAGE_TYPE.cover === type){
                            this.setState({
                                coverHash: Date.now(),
                            })
                        }else{
                            this.setState({
                                profileHash: Date.now(),
                            })
                        }
                    }else{
                        // e.target.file = EMPTY_STRING
                        this.setState({
                            isErrorModalShow : true,
                            errorMessage: SYSTEM_ERROR,
                            isCoverPhotoChange : false,
                            isProfilePhotoChange: false,
                        })
                    }
                })
            }else{
                this.setState({
                    isErrorModalShow : true,
                    errorMessage: SYSTEM_ERROR,
                    isCoverPhotoChange : false,
                    isProfilePhotoChange: false,
                })
            }
        })
    }


    handlePaginatedPost = () => {
        if (!this.state.isPostFetching && !this.state.isLastPage) {
            this.setState({ isPostFetching : true }, () => {
            this.props.getUserPosts(this.state.ownerId, this.state.privacyType, this.state.currentPagination)
                .then(res => {
                    if (onApiSuccess(res)) {
                        let postList = JSON.parse(res.data.data)
                        let updateFetch = () => {
                            this.setState(prevState => ({
                               postList: [
                                   ...prevState.postList,
                                   ...postList
                               ],
                               isPostFetching: false,
                               currentPagination : prevState.currentPagination + 1
                           }))
                       }
                       if (0 >= postList) {
                           this.setState({
                                isLastPage: true
                           })
                           fetchEmptyScroll();
                           setTimeout(() => { updateFetch() }, 500);
                       } else {
                           updateFetch();
                       }
                    } else {
                        this.setState({
                            isErrorModalShow : true,
                            errorMessage: SYSTEM_ERROR
                        })                   
                    }
                })
            });
        }
    }

    handleToggleMenu = () =>{
        this.setState({
            isOpenMenu : !this.state.isOpenMenu
        })
    }

    handleCloseErrorModal = () =>{
        this.setState({
            isErrorModalShow: false
        })
    }

    handleDeletePost = (postId) =>{
        this.setState({
            isDeletingPost : true
        })
        return new Promise (resolve=>{
            this.props.deletePost(postId).then(res =>{
                if (onApiSuccess(res)) {
                    this.setState(prevState => {
                        const postList = prevState.postList.filter(item=> getObjKeyIdString(item._id) !== postId)
                        return{
                             postList,
                             isDeletingPost : false,
                             showDeleteModal: false
                        }
                    })
                    resolve(true);
                } else {
                    this.setState({
                        isErrorModalShow : true,
                        errorMessage: isNullOrUndefined(res.data.message) ? SYSTEM_ERROR : res.data.message,
                        isPostFetching: false,
                        isDeletingPost : false,
                        showDeleteModal: false
                    })                   
                    resolve(false);
                }
            })
        })
    }

    setDeleteModal = (postId) =>{
        this.setState({showDeleteModal: true, deletePostId: postId})
    }

    closeDeleteModal = () =>{
        this.setState({showDeleteModal: false})
    }

    setPostDetailModal = (postId) =>{ //task#363 start
        this.setState({showPostDetailModal: true, postDetailPostId: postId})
    }

    closePostDetailModal = () =>{
        this.setState({showPostDetailModal: false})
    } //task#363 end

    render() {
            return (
                <React.Fragment>
                    <ProcessLoader isShown={this.state.isDeletingPost}/>
                    <ProcessLoaderForLoading isShown={this.state.isProcessingForLoading}/>
                    <Card className="mypage-info-container">
                        <MyPageHeaderComponent 
                            {...this.state}
                            handleToggleMenu={this.handleToggleMenu}
                            handleProfileImageChange={this.handleProfileImageChange}
                            handleCoverImageChange={this.handleCoverImageChange}
                         />
                        <div className="horizontal-border"></div>
                        <MyPageDetailComponent {...this.state}/>
                        <div className="horizontal-border"></div>
                        {
                           this.state.isLoading ?  <MyFriendsSkeleton/> :  <MyPageFriendsComponent {...this.state}/>
                        }
                    </Card>
                
                    <CreatePostArea {...this.state} {...this.props}/>
                    {!this.state.isLoading && !this.state.isPostDeletion ?
                     0 < this.state.postList.length ?
                      (
                        <div className="feed-main">
                             {this.state.postList.map((details, i) => {
                                return <PostItemTemplate 
                                    id={details._id} 
                                    key={"post" + i} 
                                    {...details} 
                                    setDeleteModal={this.setDeleteModal}
                                    isOpenMenu={this.state.isOpenMenu}
                                    profileHash={this.state.profileHash}
                                    isPreview={this.state.isPreview}
                                    latestUpdate={details.latestUpdate || ""}
                                    setPostDetailModal={this.setPostDetailModal}/**task#363**/
                                />
                             }) }
                        </div>
                     )
                     :
                     ( 
                        <center><p>{MSG_NO_POST_YET}</p></center> 
                     )
                    : null}

                    <center>
                        <ActivityIndicator
                            style={{
                                marginBottom: 20,
                                height: 50,
                                display: this.state.isPostFetching ? "block" : "none"
                            }}
                        />
                    </center>
                    <ErrorModal
                        {...this.state}
                        handleCloseModal={this.handleCloseErrorModal}
                        errorMessage={this.state.errorMessage}
                    />

                    <ConfirmationModal
                        isModalShow={this.state.showDeleteModal}
                        confirmationText={"日記を削除しますか？"}
                        handleConfirmAction={()=>this.handleDeletePost(this.state.deletePostId)}
                        confirmTitle={"削除"}
                        handleCloseModal={this.closeDeleteModal}
                    />

                    <PostDetailModal /**task#363 start**/
                        isModalShow={this.state.showPostDetailModal}
                        confirmationText={this.state.postDetailPostId}
                        confirmTitle={"MyPage"}
                        handleConfirmAction={()=>this.redirectViewPost(this.state.postDetailPostId)}
                        handleCloseModal={this.closePostDetailModal}
                /**task#363**/ /> 
                </React.Fragment>
            );
    }
}

const mapStateToProps = state => {
    return{
        userId: state.auth.credentials.userId,
        handleName: state.editprofile.profileDetails.handleName,
        profileImageUrl: state.auth.credentials.profileImageUrl,
        userInformation: state.editprofile.profileDetails,
        websocket: state.auth.websocket
    }
}

const mapDispatchToProps = dispatch => 
    bindActionCreators(
        {
            getUserBasicInformation: mypageOperations.getUserBasicInformation,
            getRandomFriends: mypageOperations.getRandomFriends,
            getUserPosts: mypageOperations.getUserPosts,
            uploadImageChange: mypageOperations.uploadImageChange,
            deletePost: mypageOperations.deletePost,
            incrementNotifCount: authOperations.incrementNotifCount,
            getUserProfile: profileOperations.getUserProfile,
            updateProfileImage: authOperations.updateProfileImage,
            connect: authOperations.connectWS,
            incrementChatThreadCount: authOperations.incrementChatThreadCount,
            logoutUser: authOperations.logoutUser //White out fix
        },
        dispatch
    )

export default connect(mapStateToProps, mapDispatchToProps)(MyPage);
import React from 'react';
import { Card } from 'react-bootstrap';
import AuthorHeader from '../AuthorHeader';
import { Dropdown } from 'react-bootstrap';
import { 
    WRAP_TEXT_CHARS, 
    POST_TYPES, 
    ATTACHMENT_TYPE, 
    UPLOAD_STATUS, 
    CLOUDFRONT_THUMBNAIL_PREFIX_URL,
    S3_DEFAULT_URL,
    DATE_FORMAT, 
    COMMUNITY_POST_TYPES,
    SOURCE,
} from '../../../utils/constants';
import PropTypes from 'prop-types';
import UploadingPlaceholder from '../../../includes/img/placeholders/uploading.png';
import FailedPlaceholder from '../../../includes/img/placeholders/failed.png';
import CommentIcon from '../../../includes/img/icons/comments.svg';
import MenuIcon from '../../../includes/img/icons/dot-menu.svg';
import { Media } from 'react-breakpoints'
import { Link, withRouter } from 'react-router-dom';
import { VIMEO_PLAYER_URL } from '../../../config/settings';
import { 
    appendUserRoute, 
    getObjKeyIdString, 
    getObjDateValue,
    isStringNullEmptyOrUndefined,
    customHTMLParse,
    maxTextLinePreviewTrim,
} from '../../../utils/common';
import { useSelector } from 'react-redux';
import { MenuItem } from '../MenuItem';
import './styles.scss';
import PhotoGrid from '../PhotoGrid';
import { View } from 'react-native';
import moment from "moment"
import { isNullOrUndefined } from 'util';
import { TouchableOpacity } from 'react-native';
import LinkTransform from "../LinkTransform";
import $ from 'jquery'; 

//Custom Component
const ErrorBody = ({ isOwner,isPreview, attachmentType, photos, videoStatus }) => {
  let hasFailed = false;
  let failedCount = 0;

  switch (attachmentType) {
    case ATTACHMENT_TYPE.image: {
      let counter = photos && photos.filter(
        (item) => UPLOAD_STATUS.failed === item.imageStatus
      ).length;
      hasFailed = 0 < counter;
      failedCount = counter;
      break;
    }
    case ATTACHMENT_TYPE.video: {
      hasFailed = UPLOAD_STATUS.failed === videoStatus;
      failedCount = hasFailed ? 1 : 0;
      break;
    }
    default:
      break;
  }

  return isOwner && !isPreview && hasFailed ? (
    <p
      className="error"
      style={{ cursor: "default" }}
    >{`写真${failedCount}点のアップロードに失敗しました。`}</p>
  ) : (
    ""
  );
};

const PostViewLink = ({ redirectRoute, redirectState, children }) => {
  return (
    <Link href={redirectRoute} to={{pathname: redirectRoute, state: redirectState}} className="post-item-body">
        {children}
    </Link>
    
  );
};

const PostContent = React.memo(
  ({
    postId,
    handleOpenPostModal,
    isPreview,
    poll,
    title,
    postBody,
    redirectRoute,
    redirectState,
    postType,//task#363
    onRedirect //task#363
  }) => {

      let trimmedText = postBody.substring(0, WRAP_TEXT_CHARS);
      let exceedChar = WRAP_TEXT_CHARS < postBody.length;

      if (!isNullOrUndefined(isPreview) && isPreview && handleOpenPostModal) {
        return (
          <TouchableOpacity
            onPress={() => onRedirect(postId)/**task#363**/}
          >
            <div className="post-item-body">
              <p className="post-title">{title}</p>
                <LinkTransform>
                  {maxTextLinePreviewTrim(customHTMLParse(trimmedText), exceedChar)}
                </LinkTransform>
            </div>
          </TouchableOpacity>
        );
      } else {
        return (
          <TouchableOpacity
            onPress={() => onRedirect(postId)/**task#363**/}
          >
          <div className="post-item-body">
            <p className="post-title">{title}</p>
            <LinkTransform>
                {maxTextLinePreviewTrim(customHTMLParse(trimmedText), exceedChar)}
            </LinkTransform>
            {!isNullOrUndefined(poll) &&
            0 < Object.keys(poll).length &&
            COMMUNITY_POST_TYPES.poll === postType
              ? RenderPollResult(poll)
              : null}
          </div>
         </TouchableOpacity>
        );
      }

  }
);

const CommentCount = React.memo(
  ({
    isPreview,
    handleOpenPostModal,
    postId,
    count,
    redirectRoute,
    redirectState,
    isPastPost,
    onRedirect //task#363
  }) => {
    return (
      <div className="post-item-container pt-0">
        <div
          className={
            redirectState.isCommunity || isPastPost
              ? 0 === count
                ? "post-item-community-no-comment"
                : "post-item-community-comment"
              : "post-item-comment"
          }
        >
          {!isNullOrUndefined(isPreview) && isPreview && handleOpenPostModal ? (
            <TouchableOpacity
              onPress={() => onRedirect(postId)/**task#363**/}
              style={{
                flex: 1,
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <span>
                <img
                  className="post-item-comment-icon"
                  src={CommentIcon}
                  alt="comment-icon"
                />
                <span className="post-item-comment-label">
                  {"コメントする"}
                </span>
              </span>
              {0 < count && (
                <div className="post-item-comment-label">{`コメント${count}件`}</div>
              )}
            </TouchableOpacity>
          ) : redirectState.isCommunity || isPastPost ? (
            <TouchableOpacity
              onPress={() => onRedirect(postId)/**task#363**/}
              style={{
                flex: 1,
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              {0 < count && (
                <div
                  className="post-item-comment-label"
                >{`コメント${count}件`}</div>
              )}
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              onPress={() => onRedirect(postId)/**task#363**/}
              style={{
                flex: 1,
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <div
                className="post-item-comment-label pl-0"
              >
                <span>
                  <img
                    className="post-item-comment-icon"
                    src={CommentIcon}
                    alt="comment-icon"
                  />
                  <span className="post-item-comment-label">
                    {"コメントする"}
                  </span>
                </span>
              </div>
              
              {0 < count && (
                <div
                  className="post-item-comment-label"
                >{`コメント${count}件`}</div>
              )}
            </TouchableOpacity>
          )}
        </div>
      </div>
    );
  }
);

//Functions
const RenderPollResult = (poll) => {
    return(
        <div className="post-item-poll-container">
            <p className="post-item-poll-deadline">回答締切⽇: {moment(getObjDateValue(poll.deadline)).format(DATE_FORMAT)}</p>
            <span className="post-item-poll-deadline-text">このアンケートは既に締め切りました。</span>
            <table className="post-item-poll-table">
                <tbody>
                  {
                        poll.choices.map((choice, index) => {
                           return (
                            <tr>
                                <td className="post-item-poll-table-td">{choice.choiceBody}</td>
                                <td>{choice.votes}</td>
                            </tr>
                           )
                        })
                  }
                </tbody>
            </table>
        </div>
    )
}


const RenderLivestream = React.memo(({ vimeoLivestreamId }) => {
  return (
    <div className="post-item-video-container">
      <iframe
        rel="preconnect"
        loading="lazy"
        title={vimeoLivestreamId}
        src={`${VIMEO_PLAYER_URL}/video/${vimeoLivestreamId}?title=0&byline=0&portrait=0&loop=1&autopause=1`}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
        className="post-item-video-content"
      ></iframe>
    </div>
  );
});

const RenderVideo = React.memo(
  ({ videoUrl, videoStatus, isOwner, onRedirect, postId }) => { /**task#363**/
    switch (videoStatus) {
      case UPLOAD_STATUS.uploading:
        return (
          <img
            alt="uploading"
            onClick={() => onRedirect(postId)/**task#363**/} 
            className="post-item-placeholder-video"
            src={UploadingPlaceholder}
          />
        );
      case UPLOAD_STATUS.failed:
        return isOwner ? (
          <img
            alt="failed attachment"
            onClick={() => onRedirect(postId)/**task#363**/} 
            className="post-item-placeholder-video"
            src={FailedPlaceholder}
          />
        ) : (
          ""
        );
      case UPLOAD_STATUS.success:
      default:
        return (
          <div className="post-item-video-container">
            <iframe
              rel="preconnect"
              loading="lazy"
              title={videoUrl}
              src={`${VIMEO_PLAYER_URL + videoUrl}?title=0&byline=0&portrait=0&loop=1&autopause=1`}
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
              className="post-item-video-content"
            ></iframe>
          </div>
        );
    }
  }
);

const RenderPhotos = React.memo(
  ({ postId, isPreview, handleOpenPostModal, photos, isOwner, onRedirect, source }) => {
    let previewPhotos = isOwner && !isPreview
      ? photos
      : photos.filter((item) => UPLOAD_STATUS.failed !== item.imageStatus);
    let mediaUri = previewPhotos.map((item) => {
      switch (item.imageStatus) {
        case UPLOAD_STATUS.uploading:
          return UploadingPlaceholder;
        case UPLOAD_STATUS.success:
          if(SOURCE.new === source){
            return (
              CLOUDFRONT_THUMBNAIL_PREFIX_URL +
              item.imageUrl +
              `${1 < previewPhotos.length ? "-medium?hr" : "-large?hr"}`
            );
          }else{
            return S3_DEFAULT_URL + item.imageUrl;
          }
        case UPLOAD_STATUS.failed:
          return FailedPlaceholder;
        default:
          return item.imageUrl;
      }
    });

    return (
      <View style={{ flex: 1 }}>
          <TouchableOpacity
            onPress={() => onRedirect(postId)/**task#363**/} 
            style={{ zIndex: 2 }}
          >
            <PhotoGrid source={mediaUri} />
            <div className="overlay"></div>
          </TouchableOpacity>
      </View>
    );
  }
);

const CustomToggle = React.forwardRef(({ onClick }, ref) => (
  <a
    href="_blank"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <img alt="menu-icon" src={MenuIcon} style={{ width: 24, height: 24 }} />
  </a>
));

const PostItemTemplate = React.memo((props) => {
    $(".parsedLink" ).on( "click", function(event) {
      event.stopPropagation();
    });

    let postId = getObjKeyIdString(props._id);
    let imageHash = !isNullOrUndefined(props.profileHash) ? props.profileHash : null
    let appendedPostViewRoute = SOURCE.new === props.source ? appendUserRoute(`/post/view/${postId}`) : appendUserRoute(`/post/view/past/${postId}`);
    let appendedCommunityPostViewRoute = appendUserRoute(`/communities/${props.communityId}/post/${postId}`);
    let redirectRoute = props.isCommunity ? appendedCommunityPostViewRoute : appendedPostViewRoute;
    let redirectState = {
        postId: postId,
        title: props.title,
        postBody: props.postBody,
        author: props.author,
        commentCount: props.commentCount,
        source: props.source,
        attachmentType: props.attachmentType,
        createDt: props.createDt,
        updateDt: props.updateDt,
        photos: props.photos,
        videoUrl: props.videoUrl,
        videoStatus: props.videoStatus,
        poll: props.poll,
        communityId: props.communityId,
        communityName: props.name,
        isCommunity: props.isCommunity,
        isPastPost: SOURCE.new !== props.source
    }

  //Hooks state

  //Redirect View Method
  const redirectViewPost = () =>
    props.history.push({ pathname: redirectRoute, state: redirectState });

  //Redirect Edit Method
  const redirectEditPost = () =>
    props.history.push({
      pathname: appendUserRoute(`/post/edit/${postId}`),
      state: { previousPath: props.history.location.pathname },
    });

    //Redux store data
    const userId = useSelector(state => state.auth.credentials.userId);

    //Conditions
    const isOwner = userId === props.author.userId;
    const displayLiveStream = POST_TYPES.livestream === props.type;
    const isPreview = props.isPreview
    const displayPhotos = (ATTACHMENT_TYPE.image === props.attachmentType && props.photos && (isOwner || 0 < props.photos.filter(item => UPLOAD_STATUS.failed !== item.imageStatus).length));
    const displayVideo = ATTACHMENT_TYPE.video === props.attachmentType;
    const isCommunity = props.isCommunity
    const isPastPost = props.isPastPost
    const postType = props.type

    //Menu Items
    const ownerMenu = [
        { item: { label: "日記を編集", icon: require('../../../includes/img/icons/edit-white.svg'), small: true }, onPress: redirectEditPost },
        { item: { label: "日記を削除", icon: require('../../../includes/img/icons/delete-white.svg'), small: true}, onPress: ()=> props.setDeleteModal(postId) }
    ]

    //Do not display if no success photo, video, livestream and text
    if(!displayLiveStream && !displayPhotos && !displayVideo && isStringNullEmptyOrUndefined(props.postBody)){
      return;
    }

    return (
        <Media>
            {({ breakpoints, currentBreakpoint }) => {
                let mobileView = (breakpoints[currentBreakpoint] < breakpoints.mobileLandscape);
                return (
                    <React.Fragment>
                        <Card id={props.id} className={mobileView ? "feed-container-item border-radius-none border-none" : "post-feed-item feed-container-item"}>
                            <Card.Body className={"p-0"}>
                                <div className={"post-item-container"}>
                                    <AuthorHeader
                                        {...props.author}
                                        hash={imageHash}
                                        createDt={getObjDateValue(props.createDt)}
                                        updateDt={getObjDateValue(props.updateDt)}
                                        isPreview={isPreview}
                                        handleOpenPostModal={props.handleOpenPostModal}
                                        userId={props.ownerId || props.author.userId}
                                        source={props.source}
                                    />
                                    {
                                        isOwner && !isPreview && !isCommunity && !isPastPost &&(
                                            <Dropdown className="post-item-menu">
                                                <Dropdown.Toggle as={CustomToggle} id="dropdown-post-menu" />
                                                <Dropdown.Menu flip={false} alignRight className="post-item-menu-dropdown">
                                                    {ownerMenu.map((menu, i) => (
                                                        <MenuItem
                                                            key={"user-menu" + i}
                                                            action={true}
                                                            item={menu.item}
                                                            onPress={menu.onPress}
                                                            titleStyle={{ color: "#fff", marginLeft: 12, fontSize: 14 }}
                                                        />
                                                    )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        )
                                    }

                                    <ErrorBody
                                        isOwner={isOwner}
                                        isPreview={isPreview}
                                        photos={props.photos}
                                        attachmentType={props.attachmentType}
                                        videoStatus={props.videoStatus}
                                    />
                                    <PostContent 
                                        postId={postId}
                                        handleOpenPostModal={props.handleOpenPostModal}
                                        isPreview={isPreview} 
                                        title={props.title} 
                                        postBody={props.postBody}
                                        poll={props.poll} 
                                        redirectRoute={redirectRoute} 
                                        redirectState={redirectState}
                                        postType={postType}
                                        onRedirect={props.setPostDetailModal}/**task#363**/
                                    />
                                </div>

                                {displayLiveStream && !isCommunity && (
                                    <RenderLivestream vimeoLivestreamId={props.vimeoLivestreamId} />
                                )}

                                {displayPhotos && (
                                    <RenderPhotos
                                        handleOpenPostModal={props.handleOpenPostModal}
                                        isPreview={isPreview} 
                                        photos={props.photos || []}
                                        isOwner={isOwner}
                                        onRedirect={props.setPostDetailModal}/**task#363**/
                                        redirectState={redirectState}
                                        postId={postId}
                                        latestUpdate={props.latestUpdate}
                                        source={props.source}
                                    />
                                )}

                                {displayVideo && (
                                    <RenderVideo
                                        videoUrl={props.videoUrl}
                                        videoStatus={props.videoStatus}
                                        isOwner={isOwner}
                                        onRedirect={props.setPostDetailModal}/**task#363**/
                                        postId={postId}/**task#363**/
                                    />
                                )}

                                <CommentCount 
                                    handleOpenPostModal={props.handleOpenPostModal}
                                    isPreview={isPreview} 
                                    isPastPost={isPastPost}
                                    postId={postId}
                                    count={props.commentCount} 
                                    redirectRoute={redirectRoute}
                                    redirectState={redirectState}
                                    onRedirect={props.setPostDetailModal}/**task#363**/
                                />
                            </Card.Body>
                        </Card>
                    </React.Fragment>
                )
            }}
        </Media>
    );
});

export default withRouter(PostItemTemplate);

PostItemTemplate.propTypes = {
  userId: PropTypes.number,
  setDeleteModal: PropTypes.func,
  setPostDetailModal: PropTypes.func, //task#363
  isCommunity: PropTypes.bool,
  communityId: PropTypes.string,
  communityName: PropTypes.string,
  _id: PropTypes.object,
  author: PropTypes.object,
  createDt: PropTypes.object,
  updateDt: PropTypes.object,
  title: PropTypes.string,
  postBody: PropTypes.string,
  type: PropTypes.number,
  attachmentType: PropTypes.number,
  photos: PropTypes.array,
  videoURL: PropTypes.string,
  videoStatus: PropTypes.number,
  commentCount: PropTypes.number,
  source: PropTypes.number,
  vimeoLivestreamId: PropTypes.string,
  startLivestreamDt: PropTypes.object,
  endLivestreamDt: PropTypes.object,
  isPoll: PropTypes.bool,
  poll: PropTypes.object,
};

import React, { Component } from 'react';
import { communityOperations } from './state';
import { bindActionCreators } from "redux";
import { EMPTY_STRING } from "../../../utils/constants";
import ErrorModal from "../../components/ErrorModal";
import { MSG_SYSTEM_ERROR, MSG_NO_POSTS } from "../../../utils/messages";
import { onApiSuccess, fetchEmptyScroll, isBottomScrollReached, defaultTopScroll } from '../../../utils/common';
import { connect } from "react-redux";
import "./styles/styles.scss"
import { Card } from 'react-bootstrap';
import PastCommunityHeader from './PastCommunityHeader';
import { ActivityIndicator } from 'react-native';
import CmtyItemTemplate from '../../components/CmtyItemTemplate';
import ProcessLoaderForLoading from '../../components/ProcessLoaderForLoading'; // Whiete Out fix
import PostDetailModal from '../../components/PostDetailModal'; //task#363

class PastCommunityDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
          communityId: props.match.params.communityId,
          currentPagination: 1,
          communityDetails: [],
          communityPosts: [],
          isErrorModalShow: false,
          errorMessage: EMPTY_STRING,
          isLoading: true,
          isPostFetching: true,
          isLastPage: false,
          isProcessingForLoading: false, //White Out fix
          showPostDetailModal: false,//task#363
          postDetailPostId: "" //task#363
        };
    }

    componentDidMount(){
        defaultTopScroll();

        this.props.getCommunity(this.state.communityId).then(response=>{
            if(onApiSuccess(response)){
                let data = JSON.parse(response.data.data)
                this.setState({
                    communityDetails : data,
                    isLoading: false
                })
            }else{
                this.setState({
                    isErrorModalShow: true,
                    errorMessage: MSG_SYSTEM_ERROR,
                    isLoading: true
                })
            }
        })
        this.props.getCommunityPosts(this.state.communityId, this.state.currentPagination).then(response=>{
            if(onApiSuccess(response)){
                let postData = JSON.parse(response.data.data)
                this.setState({
                    communityPosts : postData,
                    isPostFetching : false
                })
            }else{
                this.setState({
                    isErrorModalShow: true,
                    errorMessage: MSG_SYSTEM_ERROR,
                    isPostFetching : false
                })
            }
        })

        document.addEventListener('scroll', this.onScrollCheck);
    }

    componentWillUnmount(){
        document.removeEventListener('scroll', this.onScrollCheck);
        document.removeEventListener("visibilitychange", this.backGroundCare); //White Out fix
    }

    onScrollCheck = () =>{
        if(!this.state.isLoading && isBottomScrollReached()){
            this.handlePaginatedPost();
        }
    }

    handleCloseErrorModal = () => {
        this.setState({
          isErrorModalShow : false
        })
    }

    handlePaginatedPost = () => {
        if (!this.state.isPostFetching && !this.state.isLastPage) {
            this.setState({ currentPagination : this.state.currentPagination + 1, isPostFetching: true }, () => {
            this.props.getCommunityPosts(this.state.communityId, this.state.currentPagination)
                .then(res => {
                    if (onApiSuccess(res)) {
                        let postData = JSON.parse(res.data.data)
                        let updateFetch = () => {
                            this.setState(prevState => ({
                               communityPosts: [
                                   ...prevState.communityPosts,
                                   ...postData
                               ],
                               isPostFetching: false
                           }))
                       }
                       if (0 >= postData) {
                           this.setState({
                               isLastPage: true
                           })
                           fetchEmptyScroll()
                           setTimeout(() => { updateFetch() }, 500);
                       } else {
                           updateFetch();
                       }
                    } else {
                        this.setState({
                            isErrorModalShow : true,
                            errorMessage: MSG_SYSTEM_ERROR,
                            isPostFetching: false
                        })                   
                    }
                })
            });
        }
    }

    setPostDetailModal = (postId) =>{ //task#363 start
        this.setState({showPostDetailModal: true, postDetailPostId: postId})
    }

    closePostDetailModal = () =>{
        this.setState({showPostDetailModal: false})
    } //task#363 end

    render() {
        return (
            <React.Fragment>
                <ProcessLoaderForLoading isShown={this.state.isProcessingForLoading}/>
                <Card className="community-details-container">
                    <div className="community-header">
                        <p className="community-header-text">{!this.state.isLoading ? this.state.communityDetails[0].name : ""}</p>
                    </div>
                    <PastCommunityHeader {...this.state}/> 
                </Card>
                <div className="feed-main community-feed">
                    {0 < this.state.communityPosts.length && 0 < this.state.communityDetails.length ? (
                            this.state.communityPosts.map((details, i) => 
                            <CmtyItemTemplate 
                                key={"post" + i} 
                                {...details} 
                                communityId={this.state.communityId}
                                name={this.state.communityDetails[0].name} 
                                isCommunity={true}
                                setPostDetailModal={this.setPostDetailModal}/**task#363**/
                            />)
                        ) : this.state.isPostFetching ? null :  (
                            <center><p>{MSG_NO_POSTS}</p></center>
                        )
                    }
                </div>
                <center>
                        <ActivityIndicator
                            style={{
                                marginBottom: 20,
                                height: 30,
                                display: this.state.isPostFetching ? "block" : "none"
                            }}
                        />
                </center>
                <ErrorModal
                    {...this.state}
                    handleCloseModal={this.handleCloseErrorModal}
                    customContent={null}
                    errorMessage={this.state.errorMessage}
                 />

                <PostDetailModal /**task#363 start**/
                        isModalShow={this.state.showPostDetailModal}
                        confirmationText={this.state.postDetailPostId}
                        confirmTitle={"PastCommunity"}
                        handleConfirmAction={()=>this.redirectViewPost(this.state.postDetailPostId)}
                        handleCloseModal={this.closePostDetailModal}
                /**task#363**/ />
            </React.Fragment>
        );
    }
}


const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getCommunity: communityOperations.getCommunity,
            getCommunityPosts: communityOperations.getCommunityPosts,
        },
          dispatch
    );
      
export default connect(null, mapDispatchToProps)(PastCommunityDetails);
import * as Path from "./apiRoutes";
import * as Actions from "./actions";
import ApiService from "../../../../utils/apiService";
import { onApiSuccess } from "../../../../utils/common";

const searchUser = (values, isAppendList) => dispatch => {

    const payload = {
        "searchField": values.searchField,
        "searchValue": values.searchValue, 
        "currentPagination": values.currentPagination,
        "paginationToken": values.paginationToken
    }

    return ApiService.post(Path.SEARCH_USER, payload)
        .then(response => {
            if (onApiSuccess(response)) {
                
                const responseData = JSON.parse(response.data.data);

                let payload = responseData.pageList.map(user => {
                    if(user.hasOwnProperty("Attributes")) {
                        user.Attributes.map(attribute => {
                            switch(attribute.Name) {
                                case "preferred_username": {
                                    user.userId = parseInt(attribute.Value);
                                    break;
                                }
                                /**
                                * Ticket #: 21464
                                * Date: 20230714
                                * Updated By: Nezle Lindog
                                * Description: updated from 'name' attribute to 'comu_handlename' in cognito. This is due to not let comux2 update the name cognito field
                                 */
                                case "custom:comu_handlename": {
                                    user.handleName = attribute.Value;
                                    break;
                                }
                                case "email": {
                                    user.email = attribute.Value;
                                    break;
                                }
                                default: break;
                            }
                            return attribute;
                        });
                    } else {
                        user.userId = "-"
                        user.handleName = "-"
                        user.email = "-"
                    }
                    
                    return user
                })

                /**Sort by userId DESC */
                payload.sort((a,b) => b.userId - a.userId )

                if(isAppendList){
                    dispatch(Actions.appendUsersList(payload));
                }else{
                    dispatch(Actions.setUsersList(payload));
                }
                
            }
            return response;
        })
        .catch((error) => {
            console.log(`System Error: ${error}`);
            return error;
        });
};

const deleteUser = (row) => dispatch => {    
    let payload = {
        "usersToDelete": [row.userId]
    }
    
    return ApiService.post(Path.DELETE_USER, payload)
        .then(response => {
            if (onApiSuccess(response)) {
                payload.usersToDelete = payload.usersToDelete[0];
                dispatch(Actions.deleteUser(payload));
            }
            return response;
        })
        .catch((error) => {
            console.log(`System Error: ${error}`);
            return error;
        });
};

const getUserId = (row) => dispatch => {

    let payload = {
        'handleName': row.handleName
    }
    
    return ApiService.post(Path.GET_USER_ID, payload)
        .then(response => {
            if (onApiSuccess(response)) {
                const responseData = JSON.parse(response.data.data);
                payload.userId = responseData[0].userId;
                dispatch(Actions.updateUserId(payload));
            }
            return response;
        })
        .catch((error) => {
            console.log(`System Error: ${error}`);
            return error;
        });
};

export {
    searchUser,
    deleteUser,
    getUserId,
};
import React, { useImperativeHandle, useRef, forwardRef, createRef } from 'react';
import { Container } from 'react-bootstrap';
import { ActivityIndicator } from 'react-native';
import RenderCommentItem from './RenderCommentItem';
import './styles.scss';
import { getObjKeyIdString } from '../../../utils/common';
import CommentSkeleton from '../Skeletons/CommentSkeleton';

const RenderComments = (props, ref) => {

    let { isCommentLoaded, isCommentFetching, postId, source, comments, userId, postAuthorId } = props;
    
    let commentContent = <CommentSkeleton />;

    let commentItemRefs = useRef([]);
    
    if (commentItemRefs.current.length !== comments.length) {
        //Set dynamic ref for each comment
        commentItemRefs = Array(comments.length).fill().map((_, i) => { return {_id: getObjKeyIdString(comments[i]._id), current: createRef()}})
    }

    useImperativeHandle(ref, () => ({
        onSuccess: (strCommentId) => {
            //Check existing ref
            let createdComment = commentItemRefs.filter(c=>c._id === strCommentId);
            if(0 < createdComment.length){
                createdComment[0].current.onSuccess()
            }
        }
    }));   

    if (comments) {
        if (isCommentLoaded) {
            commentContent = (
                <React.Fragment>
                    {comments.map((item, i) => {
                        return (
                            <RenderCommentItem
                                {...item}
                                {...props}
                                hash={props.hash}
                                ref={commentItemRefs[i]} //Added reference to allow call for reply component (Set to comment index)
                                commentId={item._id}
                                key={getObjKeyIdString(item._id)}
                                createDt={item.createDt}
                                updateDt={item.updateDt}
                                author={item.author}
                                postId={postId}
                                source={source}
                                userId={userId}
                                postAuthorId={postAuthorId}
                            />
                        )
                    })}
                    {isCommentFetching && (
                        <ActivityIndicator style={{
                            height: 50,
                        }} />
                    )}
                </React.Fragment>
            )
        }
        return (
            <Container
                className="post-view-comment-area"
                style={0 < comments.length ? { paddingTop: 20, paddingBottom: 20 } : {}}
            >
                {commentContent}
            </Container>
        )
    } else {
        return '';
    }
}

export default forwardRef(RenderComments);

import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import './styles.scss';

class User404 extends Component {
    render() {
        return (
            <Card className="card-404">
                <Card.Title className="card-title-404">
                    エラー
                </Card.Title>
                <Card.Body>
                    <p>ページが見つかりません。</p>
                    <p>リンクに問題があるか、ページが削除された可能性があります。</p>
                </Card.Body>
            </Card>
        );
    }
}

export default User404;
const environment = process.env.REACT_APP_ENVIRONMENT;

let cognito = {};

//Test cognito only. Not Live
if(environment === "production"){
    //Insert live cognito details
    cognito = {
        Auth:{
            region: 'ap-northeast-1',
            userPoolId: 'ap-northeast-1_ANEnag9Si',
            userPoolWebClientId: '4kkndhbbgu2hsmnupto4pmn117',
            // authenticationFlowType: 'USER_PASSWORD_AUTH',
            oauth: {
                domain: 'comux2',
                scope: ['email', 'openid'],
                responseType: 'code'
            }
        }
    };


}else if(environment === "production-test"){

    /**UPDATE value based on testing */
    const testing = "system";

    if(testing === "performance"){
        cognito = {
            Auth:{
                region: 'ap-northeast-1',
                userPoolId: 'ap-northeast-1_MwclGM96f',
                userPoolWebClientId: '7rjpdh88d9h75rig5jf9s6kgu1',
                authenticationFlowType: 'USER_PASSWORD_AUTH',
                oauth: {
                    domain: 'comux2',
                    scope: ['email', 'openid'],
                    responseType: 'code'
                }
            }
        };
    }else{
        cognito = {
            Auth:{
                region: 'ap-northeast-1',
                userPoolId: 'ap-northeast-1_MwclGM96f',
                userPoolWebClientId: '75r362psi9c91ctadsdn4emape',
                // authenticationFlowType: 'USER_PASSWORD_AUTH',
                oauth: {
                    domain: 'comux2',
                    scope: ['email', 'openid'],
                    responseType: 'code'
                }
            }
        };
    }
}else{
    cognito = {
        Auth:{
            region: 'ap-northeast-1',
            userPoolId: 'ap-northeast-1_v9LFbV1bG',
            userPoolWebClientId: '7i1hrfdvkecckpsift51aogdbo',
            // authenticationFlowType: 'USER_PASSWORD_AUTH',
            oauth: {
                domain: 'comux2',
                scope: ['email', 'openid'],
                responseType: 'code'
            }
        }
    };
}

export default {
    cognito
}
import React from 'react';
import SearchAuthorHeaderTemplate from './SearchAuthorHeaderTemplate';
import { Image } from 'react-bootstrap';
import './styles/result.scss';
import { ATTACHMENT_TYPE, CLOUDFRONT_THUMBNAIL_PREFIX_URL, THUMBNAIL_SMALL, POST_TYPES, UPLOAD_STATUS, WRAP_TEXT_CHARS } from '../../../utils/constants';
import { Link } from 'react-router-dom';
import { getObjKeyIdString, appendUserRoute, getObjDateValue, isNullOrUndefined, customHTMLParse, maxTextLinePreviewTrim } from '../../../utils/common';
import { VIMEO_PLAYER_URL } from '../../../config/settings';
import UploadingPlaceholder from '../../../includes/img/placeholders/uploading.png';
import LinkTransform from "../../components/LinkTransform";
import { TouchableOpacity } from 'react-native'; //task#363

const RedirectLink = (props) =>{
    return(
        <Link href={props.href} to={{ pathname: props.href }} className={props.attachment ? "" : "search-body post-text"}>
            {props.children}
        </Link>
    )
}
const SearchPostItemTemplate = (props) => {
    let redirectPostRoute = appendUserRoute(`/post/view/${getObjKeyIdString(props._id)}`);

    let displayedText = <RedirectLink href={redirectPostRoute} >{props.postBody}</RedirectLink>
    let postId = getObjKeyIdString(props._id); //task#363
    if(!isNullOrUndefined(props.mobileView) && !props.mobileView){
        let exceedChar = WRAP_TEXT_CHARS < props.postBody.length; 
        let trimmedText = props.postBody.substring(0, WRAP_TEXT_CHARS);    
        displayedText = (
            <RedirectLink href={redirectPostRoute} >
                <LinkTransform>{maxTextLinePreviewTrim(customHTMLParse(trimmedText), exceedChar)}</LinkTransform>
            </RedirectLink>
        )
    }
    
    const PostContent = React.memo( //task#363 start
        ({
          postId,
          postBody,
          type,
          attachmentType,
          photos,
          vimeoUrl,
          vimeoLivestreamId,
          videoUrl,
          onRedirect
        }) => {
      
            let trimmedText = postBody.substring(0, WRAP_TEXT_CHARS);
            let exceedChar = WRAP_TEXT_CHARS < postBody.length;

            if(POST_TYPES.livestream === type){
                postBody = (
                    <div className="d-flex flex-row search-post-body">
                        {displayedText}

                            <div className="position-relative">
                                <div className="search-attachment-overlay"></div>
                                <iframe
                                    title={vimeoUrl}
                                    src={`${VIMEO_PLAYER_URL + "/video/" + vimeoLivestreamId}?controls=0`}
                                    className="search-attachment-video"
                                />
                            </div>

                    </div>
                )
            }else if (ATTACHMENT_TYPE.image === attachmentType) {
                let filteredImages = photos && photos.filter(i => i.imageStatus !== UPLOAD_STATUS.failed);
                let imageDisplayUrl = null;
                if(filteredImages && 0 < filteredImages.length){
                    imageDisplayUrl = (UPLOAD_STATUS.uploading === filteredImages[0].imageStatus) ? UploadingPlaceholder : `${CLOUDFRONT_THUMBNAIL_PREFIX_URL + props.photos[0].imageUrl + THUMBNAIL_SMALL + "?" + props.hash}`;
                }
        
                postBody = (
                    <div className="d-flex flex-row search-post-body">
                        {displayedText}

                            <Image src={imageDisplayUrl} alt="post-image" className="search-attachment" />
                        
                    </div>
                )
            } else if (ATTACHMENT_TYPE.video === attachmentType) {
                postBody = (
                    <div className="d-flex flex-row search-post-body">
                        {displayedText}
                        
                            <div className="position-relative">
                                <div className="search-attachment-overlay"></div>
                                <iframe
                                    title={vimeoUrl}
                                    src={`${VIMEO_PLAYER_URL + videoUrl}?controls=0`}
                                    className="search-attachment-video"
                                />
                            </div>

                    </div>
                )
            } else  { // case of text only
                postBody = (
                    <div className="d-flex flex-row search-post-body">
                        {displayedText}
                    </div>
                )
            }
      
              return (
                <TouchableOpacity
                  onPress={() => onRedirect(postId)}
                >
                    <LinkTransform>
                        {postBody}
                    </LinkTransform>
                    {0 < props.commentCount && (
                        <div className="search-comment-count">
                            {`コメント${props.commentCount}件`}
                        </div>
                    )}
                </TouchableOpacity>
              );
            
        }
      ); //task#363 end

    return (
        <div className="search-item-container">
            <SearchAuthorHeaderTemplate 
                {...props.author} 
                hash={props.hash}
                createDt={getObjDateValue(props.createDt)}
            />
            
            <PostContent 
                postId={postId}
                postBody={props.postBody}
                type={props.postType}
                attachmentType={props.attachmentType}
                photos={props.photos}
                vimeoUrl={props.vimeoUrl}
                vimeoLivestreamId={props.vimeoLivestreamId}
                videoUrl={props.videoUrl}
                onRedirect={props.setPostDetailModal}/**task#363**/
            />

        </div>
    );
};

export default SearchPostItemTemplate;
import * as Path from "./apiRoutes";
import ApiService from "../../../../utils/apiService";
import axios from 'axios';
import { WEBSOCKET_ACTIONS } from "../../../../utils/constants";
import { VIMEO_KEY } from "../../../../config/settings";

const getPost = (userId, postId, isPastPost) => () => {
    let getPostLink = isPastPost ? Path.GET_PAST_POST : Path.GET_POST;
    return ApiService.get(getPostLink + `/${userId}/${postId}`)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
};

const getMoreComments = (userId, postId, currentPagination, isPastPost, postType) => () => {
    let getCommentLink = isPastPost ? Path.GET_POST_PAGINATED_PAST_COMMENTS + `/${userId}/${postId}/${currentPagination}/${postType}` : Path.GET_POST_PAGINATED_COMMENTS + `/${userId}/${postId}/${currentPagination}`;

    return ApiService.get(getCommentLink)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
};

const getCommunityPost = (communityId, postId) => () => {
    return ApiService.get(Path.GET_COMMUNITY_POST + `/${communityId}/${postId}`)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
};

const getMoreCommunityComments = (postId, currentPagination) => () => {
    return ApiService.get(Path.GET_COMMUNITY_POST_PAGINATED_COMMENTS + `/${postId}/${currentPagination}`)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
};

const createPost = (params) => () => {
    return ApiService.post(Path.CREATE_POST, params)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}

const editPost = (params) => () => {
    return ApiService.patch(Path.EDIT_POST, params)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}

const deletePost = (postId, refId=null) => () => {
    let params={
        refId
    }

    return ApiService.post(Path.DELETE_POST + `/${postId}`, params)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}

const createComment = (params, ws) => () => {
    ws.send(
        JSON.stringify({
            action: WEBSOCKET_ACTIONS.onCreateComment,
            type: WEBSOCKET_ACTIONS.onCreateComment,
            data: {
                ...params
            },
        })
    );
}

const editComment = (params) => () => {
    return ApiService.patch(Path.EDIT_COMMENT, params)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}

const deleteComment = (commentId) => () => {
    return ApiService.delete(Path.DELETE_COMMENT + `/${commentId}`)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}


const createReply = (params, ws) => () => {
    ws.send(
        JSON.stringify({
            action: WEBSOCKET_ACTIONS.onCreateReply,
            type: WEBSOCKET_ACTIONS.onCreateReply,
            data: {
                ...params
            },
        })
    );
}

const editReply = (params) => () => {
    return ApiService.patch(Path.EDIT_REPLY, params)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}

const deleteReply = (commentId, replyId) => () => {
    return ApiService.delete(Path.DELETE_REPLY + `/${commentId}/${replyId}`)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}

const requestReupload = (params) => () => {
    return ApiService.patch(Path.REQUEST_REUPLOAD, params)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}

const createLivestream = (params) => () => {
    return ApiService.post(Path.CREATE_LIVESTREAM, params)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}

const editLivestream = (params) => () => {
    return ApiService.patch(Path.EDIT_LIVESTREAM, params)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}

const checkVimeoId = (vimeoId) => () =>{
    return axios.get(Path.VIMEO_CHECK + `/${vimeoId}`, {
        headers:{
            "Authorization": `Bearer ${VIMEO_KEY}`,
            "Content-Type": "application/json",
            "Accept": "application/vnd.vimeo.*+json;version=3.4"
        }
    })
}

export {
    getPost,
    getMoreComments,
    getCommunityPost,
    getMoreCommunityComments,
    createPost,
    editPost,
    deletePost,
    createComment,
    editComment,
    deleteComment,
    createReply,
    editReply,
    deleteReply,
    requestReupload,
    createLivestream,
    editLivestream,
    checkVimeoId
}
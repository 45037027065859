import * as types from "./types";
import { isNullOrUndefined } from "util";
import { EMPTY_STRING } from "../../../../utils/constants";

const defaultState = {
  message: []
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case types.SET_CHAT_MESSAGE:
      let isExisting = false
      let isDeleteMessage = EMPTY_STRING === action.payload.message  && !isNullOrUndefined(action.payload.recipientId)
      if(state.message.length > 0){
        let message = state.message.map((item)=>{
          if(action.payload.recipientId === item.recipientId){
            isExisting = true
            return{
              ...item,
              message: action.payload.message
            }
          }
          return item
        })
        if(isDeleteMessage){
          let message = state.message.filter(function(item) {
            return action.payload.recipientId !== item.recipientId 
           })
          return {
            ...state,
            message: message
          };
        }else if(isExisting){
          return{
            ...state,
            message
          }
        } else{
          return {
            ...state,
            message: [...state.message, action.payload]
          };
        }
      }else{
        return {
          ...state,
          message: [...state.message, action.payload]
        };
      }
    default:
      return state;
  }
}

import React from 'react';
import './styles.scss';

const RenderCommentCount = ({ count }) => {
    return 0 < count ? (
        <div className="post-view-container post-comment-no-padding pt-0">
            <div className="post-view-comment-count">
                {0 < count && <span className="post-view-comment-label">{`コメント${count}件`}</span>}
            </div>
        </div>
    ) : <div className="post-view-container post-comment-no-padding pt-0" />;

}

export default RenderCommentCount;
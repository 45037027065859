import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  chatListContainer: {
    backgroundColor: "#ffffff",
    zIndex: -9999,
    position: "relative"
  },
  listItemContainer: {
    padding: 10,
    zIndex: 2,
    backgroundColor: "#ffffff",
  },
  chatListItemContainer: {
    paddingHorizontal: 10,
    paddingTop: 10,
    paddingBottom: 2,
    zIndex: 2,
    backgroundColor: "#ffffff",
  },
  listItem: {
    padding: 0,
    margin: 0
  },
  listContainer: {
    height: 600
  },
  titleMobile: {
    marginLeft: 15,
    color: "#0A182C",
    fontSize: 14,
    fontFamily: "kozuka-gothic-pro, sans-serif",
  },
  titlePC: {
    marginLeft: 15,
    color: "#0A182C",
    fontSize: 16,
    fontFamily: "kozuka-gothic-pro, sans-serif",
  },
  horizontalBorder: {
    borderBottomColor: "#EAEDED",
    borderBottomWidth: 1,
    width: "100%",
    alignSelf: "center",
    padding: 5
  },
  inputContainer: {
    flex: 1,
    flexDirection: "row"
  },
  input: {
    width: "85%",
    height: 30,
    borderRadius: 20,
    borderColor: "gray",
    borderWidth: 1,
    backgroundColor: "white",
    marginTop: 10,
    marginBottom: 10,
    alignSelf: "center",
    paddingLeft: 12,
    paddingTop: 5
  },
  icon: {
    width: 25,
    height: 25,
    margin: 15,
    marginRight: 19,
    opacity: 1,
    resizeMode: 'contain'
  },
  disabledIcon: {
    width: 25,
    height: 25,
    margin: 15,
    marginRight: 19,
    opacity: .5,
    resizeMode: 'contain'
  },
  sendMessageContainer: {
    backgroundColor: "#F5F5F5"
  },
  userChatItemPC: {
    textAlign: "left",
    backgroundColor: "#F0F1F2",
    color: "black",
    borderRadius: 3,
    padding: 8,
    marginRight: 5,
    marginLeft: 5,
    marginTop: 5,
    marginBottom: 5,
    maxWidth: 300,
    fontFamily: "kozuka-gothic-pro, sans-serif",
    fontSize: 16,
    lineHeight: 26
  },
  userChatItem: {
    textAlign: "left",
    backgroundColor: "#F0F1F2",
    color: "black",
    borderRadius: 10,
    padding: 8,
    marginRight: 5,
    marginLeft: 5,
    marginTop: 5,
    marginBottom: 5,
    maxWidth: 220,
    fontFamily: "kozuka-gothic-pro, sans-serif",
    fontSize: 14,
    lineHeight: 26
  },
  userItemContainer: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "flex-end",
    flexDirection: "row"
  },
  friendChatItem: {
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: "#D7D8D9",
    borderRadius: 10,
    padding: 8,
    marginRight: 5,
    marginLeft: 5,
    marginTop: 5,
    marginBottom: 5,
    maxWidth: 220,
    fontFamily: "kozuka-gothic-pro, sans-serif",
    fontSize: 14,
    lineHeight: 26
  },
  friendChatItemPC: {
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: "#D7D8D9",
    borderRadius: 3,
    padding: 8,
    marginRight: 5,
    marginLeft: 10,
    marginTop: 5,
    marginBottom: 5,
    maxWidth: 300,
    fontFamily: "kozuka-gothic-pro, sans-serif",
    fontSize: 16,
    lineHeight: 26
  },
  friendItemContainer: {
    flex: 1,
    maxWidth: 400,
    alignItems: "flex-start",
    flexDirection: "row",
    justifyContent: "flex-start"
  },
  friendTimeStampContainer: {
    alignSelf: "flex-end"
  },
  friendTimeStamp: {
    textAlign: "right",
    color: "#AFAFB0",
    fontFamily: "kozuka-gothic-pro, sans-serif",
    fontSize: 12,
    textAlignVertical: "bottom"
  },
  friendTimeStampImage: {
    textAlign: "right",
    color: "#AFAFB0",
    fontFamily: "kozuka-gothic-pro, sans-serif",
    fontSize: 12,
    marginBottom: 10,
    position: "absolute",
    bottom: 0,
    paddingLeft: 5,
  },
  friendTimeStampImagePC: {
    textAlign: "right",
    color: "#AFAFB0",
    fontFamily: "kozuka-gothic-pro, sans-serif",
    fontSize: 14,
    position: "absolute",
    bottom: 0,
    paddingLeft: 5,
  },
  friendTimeStampPC: {
    fontFamily: "kozuka-gothic-pro, sans-serif",
    fontSize: 14,
    textAlign: "right",
    alignSelf: "flex-end",
    color: "#AFAFB0",
    textAlignVertical: "bottom"
  },
  userTimeStampImagePC: {
    textAlign: "right",
    fontFamily: "kozuka-gothic-pro, sans-serif",
    fontSize: 14,
    marginBottom: 10,
    color: "#AFAFB0"
  },
  userTimeStampImage: {
    textAlign: "right",
    fontFamily: "kozuka-gothic-pro, sans-serif",
    fontSize: 12,
    marginBottom: 10,
    color: "#AFAFB0"
  },
  userTimeStamp: {
    textAlign: "right",
    color: "#AFAFB0",
    fontFamily: "kozuka-gothic-pro, sans-serif",
    fontSize: 12,
  },
  userTimeStampPC: {
    textAlign: "right",
    color: "#AFAFB0",
    fontFamily: "kozuka-gothic-pro, sans-serif",
    fontSize: 14,
  },
  deletedMessage: {
    fontSize: 14,
    marginTop: 10,
    padding: 5,
    color: "gray",
    alignSelf: "center",
    fontFamily: "kozuka-gothic-pro, sans-serif",
    textAlign: "center"
  },
  deletedMessagePC: {
    fontSize: 14,
    marginTop: 10,
    padding: 5,
    color: "gray",
    alignSelf: "center",
    fontFamily: "kozuka-gothic-pro, sans-serif",
    textAlign: "center"
  },
  chatDeleteContainer: {
    position: "absolute",
    alignSelf: "flex-end",
    flex: 1,
    bottom: -18,
    marginRight: 5,
    backgroundColor: "#4A4A4D",
    borderRadius: 3,
    zIndex: 9999
  },
  chatDeleteContainerPC: {
    position: "absolute",
    alignSelf: "flex-end",
    flex: 1,
    bottom: -18,
    marginRight: 5,
    backgroundColor: "#4A4A4D",
    borderRadius: 3,
    zIndex: 9999
  },
  chatDeleteContainerImage: {
    position: "absolute",
    alignSelf: "flex-end",
    flex: 1,
    bottom: -18,
    marginRight: 5,
    backgroundColor: "#4A4A4D",
    borderRadius: 3,
    zIndex: 9999
  },
  chatDeleteContainerImagePC: {
    position: "absolute",
    alignSelf: "flex-end",
    flex: 1,
    bottom: -18,
    marginRight: 10,
    backgroundColor: "#4A4A4D",
    borderRadius: 3,
    zIndex: 9999
  },
  touchableDelete: {
    padding: 2,
    borderRadius: 2,
    flexDirection: "row",
    flexWrap: "nowrap",
    minWidth: 65
  },
  touchableDeletePC: {
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 5,
    paddingRight: 5,
    flexDirection: "row",
    flexWrap: "nowrap",
    minWidth: 65
  },
  dateTextMobile: {
    color: "#98A6B5",
    fontSize: 13,
    alignSelf: "center",
    margin: 10,
    zIndex: -999,
    position: "relative",
    fontFamily: "kozuka-gothic-pro, sans-serif",
  },
  dateTextPC: {
    color: "#98A6B5",
    fontSize: 14,
    alignSelf: "center",
    margin: 10,
    zIndex: -999,
    position: "relative",
    fontFamily: "kozuka-gothic-pro, sans-serif",
  },
  bottomContainer: {
    position: "relative",
    zIndex: 1
  },
  topContainer: {
    position: "relative",
    zIndex: 2
  },
  deleteIcon: {
    width: 13,
    height: 15,
    marginTop: 5,
    marginLeft: 8
  },
  deleteText: {
    fontSize: 12,
    padding: 5,
    color: "white"
  },
  deleteIconPC: {
    width: 13,
    height: 16,
    marginTop: 3,
    marginLeft: 5,
    marginRight: 2
  },
  deleteTextPC: {
    fontSize: 12,
    padding: 3,
    color: "white"
  },
  fileUploadContainer:{
    padding: 10,
    width: "100%",
    flex: 1,
    flexDirection: "column",
    height: 110
  },
  removeIcon: {
    height: 15, 
    width: 15, 
    margin: 0, 
    position: "absolute", 
    right: 10,
    top: 5
  },
  errorMessagePC: {
    position: "relative",
    bottom: "0px",
    top: "0px",
    left: "0px",
  },
  errorMessage: {
    position: "relative",
    bottom: "0px",
    top: "0px"
  },
  sendChatContainer:{
    flex:1,
    flexDirection: "column", 
    paddingTop: 5, 
    paddingBottom: 5
  },
  chatAvatar:{
    marginRight: 2,
    marginTop: 5
  },
  keyBoardAvoidingView: {
    zIndex: 2,
    position: "relative"
  }

});

export default styles;

import * as Path from "./apiRoutes";
import ApiService from "../../../../utils/apiService";
// import { onApiSuccess } from "../../../../utils/common";

const updatePassword = (values) => (dispatch) => {

    const payload = {
        'userId': values.userId,
        'username': values.username,
        'proposedPassword': values.newPassword,
    };

    return ApiService.patch(Path.UPDATE_PASSWORD, payload)
        .then((response) => {
            return response;
        })
};

export {
    updatePassword
};
import React, {useEffect} from "react";
import { Dropdown } from "react-bootstrap";
import { MenuItem } from "../MenuItem";
import { useSelector } from "react-redux";
import {
  CLOUDFRONT_THUMBNAIL_PREFIX_URL,
  THUMBNAIL_SMALL
} from "../../../utils/constants";
import MobileOnlyView from "../MobileOnlyView";
import PCOnlyView from "../PCOnlyView";
import './styles.css';
import { objKeyExist, defaultTopScroll, isFumiyaOrFFStaffUserCheck} from "../../../utils/common";
import { isMobile } from "react-device-detect";

export const navList = (mobileView, user, profile) => {
  let appendName = mobileView ? "" : "-dark";
  let handleName = objKeyExist(profile, "handleName") ? profile.handleName : user.handleName;
   
  return [
    {
      navigate: "/mypage",
      label: handleName,
      icon: CLOUDFRONT_THUMBNAIL_PREFIX_URL + user.profileImageUrl + THUMBNAIL_SMALL + `?${Date.now()}`,
      userIcon: true,
      containerStyle:{height: mobileView ? 52 : 47},
      titleStyle: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        display: "block",
        overflow: "hidden",
        color: "#0A182C",
        fontWeight: "500", 
        marginLeft: 16,
        fontVariant: "proportional-width"
      }
    },
    {
      navigate: "/livestream",
      label: "ライブ動画配信",
      icon: require(`../../../includes/img/user-menu/livestream${appendName}.svg`),
      special: true
    },
    {
      navigate: "/newsfeed",
      label: "最新の更新",
      icon: require(`../../../includes/img/user-menu/newsfeed${appendName}.svg`)
    },
    {
      navigate: "/chat",
      label: "メッセージ",
      icon: require(`../../../includes/img/user-menu/chat${appendName}.svg`),
      disabled: isFumiyaOrFFStaffUserCheck(user.userId)
    },
    {
      navigate: "/search",
      label: "検索",
      icon: require(`../../../includes/img/user-menu/search.svg`)
    },
    {
      navigate: "/notifications",
      label: "新着情報",
      icon: require(`../../../includes/img/user-menu/notification${appendName}.svg`),
      disabled: isFumiyaOrFFStaffUserCheck(user.userId)
    },
    {
      navigate: `/friends/${user.userId}`,
      label: "友達リスト",
      icon: require(`../../../includes/img/user-menu/friends${appendName}.svg`),
      small: true
    },
    {
      navigate: "/blocked",
      label: "アクセスブロック",
      icon: require(`../../../includes/img/user-menu/block${appendName}.svg`)
    },
    {
      navigate: "/help",
      label: "Q&A／ヘルプ",
      icon: require(`../../../includes/img/user-menu/help${appendName}.svg`)
    },
    {
      navigate: "/contact-us",
      label: "お問い合わせ",
      icon: require(`../../../includes/img/user-menu/contact${appendName}.svg`)
    },
    {
      navigate: "/communities",
      label: "過去のオフィシャルコミュニティ",
      icon: require(`../../../includes/img/user-menu/community${appendName}.svg`)
    },
    {
      navigate: "/privacy-policy",
      label: "プライバシーポリシー",
      icon: require(`../../../includes/img/user-menu/document${appendName}.svg`),
      small: true
    },
    {
      navigate: "/terms-of-service",
      label: "利用規約",
      icon: require(`../../../includes/img/user-menu/document${appendName}.svg`),
      small: true
    },
    {
      navigate: "/logout",
      label: "ログアウト",
      icon: require(`../../../includes/img/user-menu/logout${appendName}.svg`)
    },
    {
      navigate: "/twitter",
      label: "X",
      icon: require(`../../../includes/img/user-menu/twitter${appendName}.svg`)
    },
    {
      navigate: "/instagram",
      label: "Instagram",
      icon: require(`../../../includes/img/user-menu/instagram${appendName}.svg`)
    },
    {
      navigate: "/facebook",
      label: "Facebook",
      icon: require(`../../../includes/img/user-menu/facebook${appendName}.svg`)
    }
  ];
};

const HamburgerMenu = () => {
  const user = useSelector(state => state.auth.credentials);
  const profile = useSelector(state => state.editprofile.profileDetails);

  useEffect(()=>{
    defaultTopScroll();
  })

  return (
    <React.Fragment>
      <MobileOnlyView>
        <div style={{ backgroundColor: "#fff" }}>
          {navList(true, user, profile).map((item, i) => {
            return (
              <MenuItem
                key={i}
                item={item}
                mobileView={true}
                containerStyle={item.containerStyle || {height: 48}}
                leftAvatar={{ rounded: false, source: item.icon, size: 32 }}
                titleStyle={item.titleStyle || { fontWeight: "500", marginLeft: 16 }}
                bottomDivider={true}
              />
            );
          })}
        </div>
      </MobileOnlyView>

      <PCOnlyView>
        <Dropdown.Menu alignRight className={isMobile ? "hamburger-container-mobile" : "hamburger-container"}>
          {navList(false, user, profile).map((item, i) => {
            return <MenuItem containerStyle={item.containerStyle || {height: 47}} titleStyle={item.titleStyle} key={i} item={item} />;
          })}
        </Dropdown.Menu>
      </PCOnlyView>
    </React.Fragment>
  );
};
export default HamburgerMenu;

import React, { useState } from 'react';
import { Card, Image } from 'react-bootstrap';
import ConfirmationModal from "../../components/ConfirmationModal"
import { CLOUDFRONT_THUMBNAIL_PREFIX_URL, THUMBNAIL_MEDIUM} from '../../../utils/constants';
import { Button } from 'react-native-elements';
import styles from "./styles/mypage.styles.native"
import {addDefaultSrc, removeFileExtension} from "../../../utils/common"
import { appendUserRoute } from '../../../utils/common';
import { Link } from 'react-router-dom';
import PCOnlyView from '../../components/PCOnlyView';
import MobileOnlyView from '../../components/MobileOnlyView';
import { TouchableOpacity } from 'react-native';

const MyPageFriendsComponent = (props) => {
    const [profile, setProfile] = useState("");

    let friendListRoute = appendUserRoute(`/friends/${props.ownerId}`);
    if(0 < Object.keys(props.randomFriends).length){
        let data = props.randomFriends.friends
        let arrayOne = []
        let arrayTwo = []
        data.forEach((element,index) => {
            if(3 > index){
                arrayOne.push(element)
            }else{
                arrayTwo.push(element)
            }
        });
        return(
            <>
            <span className="friends-count">友達 {props.randomFriends.count} 人</span>
            <Card.Body className="mypage-friends-container">  
                <Card.Body className="friends-card-container">
                    {arrayOne.map((item,index) => {
                        let friendPageRoute = appendUserRoute(`/user/${item.userId}`);
                        return(
                            <div key={index} className="friend-container">
                                {!props.isPreview ?
                                <Link key={friendPageRoute} href={friendPageRoute} to={friendPageRoute} className="link-router">
                                    <Image src={CLOUDFRONT_THUMBNAIL_PREFIX_URL + removeFileExtension(item.profileImageUrl) + THUMBNAIL_MEDIUM + "?" + props.profileHash} onError={addDefaultSrc} alt="profile" className="friend-image"/>
                                    <span className="friend-handlename">{item.handleName}</span>
                                </Link>                               
                                :
                                <TouchableOpacity onPress={()=>{
                                    setProfile(item.userId)
                                    props.handleOpenModal()
                                }}>
                                    <Image src={CLOUDFRONT_THUMBNAIL_PREFIX_URL + removeFileExtension(item.profileImageUrl) + THUMBNAIL_MEDIUM + "?" + props.profileHash} onError={addDefaultSrc} alt="profile" className="friend-image"/>
                                    <span className="friend-handlename">{item.handleName}</span>
                                </TouchableOpacity>
                             }
                            </div>
                        )
                    })}
                </Card.Body>
                <Card.Body className="friends-card-container">
                    {arrayTwo.map((item, index) => {
                        let friendPageRoute = appendUserRoute(`/user/${item.userId}`);
                        return(
                            <div key={index} className="friend-container">
                                {!props.isPreview ?
                                <Link key={friendPageRoute} href={friendPageRoute} to={friendPageRoute} className="link-router">
                                    <Image src={CLOUDFRONT_THUMBNAIL_PREFIX_URL + removeFileExtension(item.profileImageUrl) + THUMBNAIL_MEDIUM} onError={addDefaultSrc} alt="profile" className="friend-image"/>
                                    <span className="friend-handlename">{item.handleName}</span>
                                </Link>                               
                                :
                                <TouchableOpacity onPress={()=>{
                                    setProfile(item.userId)
                                    props.handleOpenModal()
                                }}>
                                    <Image src={CLOUDFRONT_THUMBNAIL_PREFIX_URL + removeFileExtension(item.profileImageUrl)   + THUMBNAIL_MEDIUM} onError={addDefaultSrc} alt="profile" className="friend-image"/>
                                    <span className="friend-handlename">{item.handleName}</span>
                                </TouchableOpacity>
                             }
                            </div>                        
                        )
                    })}
                </Card.Body>
            </Card.Body>
            <PCOnlyView>
                {props.isPreview ? 
                    <Button disabled={props.isPreview ? true : false} title="全ての友達を見る" disabledTitleStyle={styles.friendsBtnTitleDisabledPC} disabledStyle={styles.displayFriendsBtnDisabledPC}/>
                :
                 <Link key={friendListRoute} href={friendListRoute} to={friendListRoute} className="link-router"> 
                     <Button title="全ての友達を見る" titleStyle={styles.friendsBtnTitlePC} buttonStyle={styles.displayFriendsBtnPC}/>
                 </Link>
                }
            </PCOnlyView>
            <MobileOnlyView>
                {props.isPreview ? 
                        <Button disabled={props.isPreview ? true : false} title="全ての友達を見る" disabledTitleStyle={styles.friendsBtnTitleDisabled} disabledStyle={styles.displayFriendsBtnDisabled}/>
                    :
                    <Link key={friendListRoute} href={friendListRoute} to={friendListRoute} className="link-router">
                        <Button title="全ての友達を見る" titleStyle={styles.friendsBtnTitle} buttonStyle={styles.displayFriendsBtn}/>
                    </Link>
                }
            </MobileOnlyView>

            <ConfirmationModal
                isModalShow={props.isFriendModalShow}
                confirmationText={"プレビューモードを終了しますか？"}
                handleConfirmAction={()=>props.handleRedirectProfile(profile)}
                handleCloseModal={props.handleCloseModal}
            />
            </>
        )
    }else{
        return null
    }
}

export default MyPageFriendsComponent
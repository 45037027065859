import * as Path from "./apiRoutes";
import * as Actions from "./actions";
import ApiService from "../../../../utils/apiService";
import {RANDOM_MSG_TYPES} from "../../../../utils/constants";
import { prependZero, strToInt, formatDeliveryDate, formatDeliveryType, formatDeliveryStatus, onApiSuccess, isStringNullEmptyOrUndefined, parseISOString } from "../../../../utils/common";

const createRandomMessage = (randomMessageModel) => dispatch => {

    let payload = {
        "type": strToInt(randomMessageModel.type),
        "deliveryYear": strToInt(randomMessageModel.deliveryYear),
        "deliveryMonth": strToInt(randomMessageModel.deliveryMonth),
        "deliveryDay": strToInt(randomMessageModel.deliveryDay),
        "deliveryHour": strToInt(randomMessageModel.deliveryHour),
        "deliveryMinute": strToInt(randomMessageModel.deliveryMinute),
        "messageBody": randomMessageModel.messageBody,
        "messageImage": randomMessageModel.messageImage.name,
        "deliveryStatus": strToInt(randomMessageModel.deliveryStatus),
        "deliveryHistory": []
    }

    return ApiService.post(Path.CREATE_RANDOM_MESSAGE, payload)
        .then(response => {
            if(onApiSuccess(response)){
                let updatePayload = {
                    _id: JSON.parse(response.data.data)._id.$oid,
                    deliveryDate: formatDeliveryDate(payload),
                    type: formatDeliveryType(payload),
                    deliveryStatus: formatDeliveryStatus(payload),
                    title: payload.messageBody,
                    messageImage: randomMessageModel.messageImage
                }

                payload = { ...payload, ...updatePayload };
                dispatch(Actions.insertRandomMessage(payload));
            }
            return response;
        })
        .catch((error) => {
            console.log(`System Error: ${error}`);
            return error;
        });
};

const getRandomMessage = (index) => dispatch => {
    return ApiService.get(Path.GET_RANDOM_MESSAGE + `/${index._id}`)
        .then(response => {
            if (onApiSuccess(response)) {
                const payload = JSON.parse(response.data.data)[0];
                payload._id = payload._id.$oid;
                payload.deliveryDate = formatDeliveryDate(payload);
                payload.type = formatDeliveryType(payload);
                payload.deliveryStatus = formatDeliveryStatus(payload);
                dispatch(Actions.getRandomMessage(payload));
            }
            return response;
        })
        .catch(error => {
            console.error(error);
        });
};

const getAllRandomMessages = (currentPagination) => dispatch => {
    return ApiService.get(Path.GET_ALL_RANDOM_MESSAGES + `/${currentPagination}`)
        .then(response => {
            if(onApiSuccess(response)){
                let responseData = JSON.parse(response.data.data);
                
                const payload = responseData.list.map(randomMessage => {
                    let isSendToAll = RANDOM_MSG_TYPES.all === randomMessage.type;
                    
                    randomMessage._id = randomMessage._id.$oid;
                    randomMessage.type = formatDeliveryType(randomMessage);
                    randomMessage.deliveryStatus = formatDeliveryStatus(randomMessage);
                    randomMessage.title = randomMessage.messageBody;
                    randomMessage.image = randomMessage.messageImage;

                    //Check type of random message for date render
                    let date = isSendToAll ? parseISOString(new Date(randomMessage.deliveryDate).toISOString()) : '';
                    const yearOnly = parseInt(randomMessage.deliveryDate.split('/')[0]);
                    const monthOnly = parseInt(randomMessage.deliveryDate.split('/')[1]);
                    randomMessage.deliveryYear = isSendToAll ? date.getFullYear() : yearOnly;
                    randomMessage.deliveryMonth = isSendToAll ? (isStringNullEmptyOrUndefined(date.getMonth().toString()) 
                                                    ? '' : date.getMonth() + 1 ) : monthOnly ;
                    randomMessage.deliveryDay = isSendToAll ? date.getDate() : '';
                    randomMessage.deliveryHour = isSendToAll ? date.getHours() : '';
                    randomMessage.deliveryMinute = isSendToAll ? date.getMinutes() : '';
                    
                    randomMessage.deliveryHistory = randomMessage.deliveryHistory.map(randomMessage => {
                                                        let formattedDate = new Date(randomMessage.timestamp.$date);
                                                        randomMessage.timestamp = `${formattedDate.getFullYear()}/${prependZero(formattedDate.getMonth() + 1)}/${prependZero(formattedDate.getDate())} 
                                                        ${prependZero(formattedDate.getHours())}:${prependZero(formattedDate.getMinutes())}`
                                                        
                                                        return randomMessage
                                                    })
                    randomMessage.deliveryHistory  =  randomMessage.deliveryHistory.sort(function(a,b){
                        return new Date(b.timestamp) - new Date(a.timestamp);
                    });                  
                                                    
                    return randomMessage;
                }); 
                dispatch(Actions.setRandomMessageList(payload));
            }
            return response;
        })
        .catch((error) => {
            console.log(`System Error: ${error}`);
            return error;
        });
};

const getAllRecipients = (index) => dispatch => {
    return ApiService.get(Path.GET_ALL_RECIPIENTS, {'randomMessageId' : `${index._id}`})
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
};

const updateRandomMessage = (randomMessageModel) => dispatch => {
    let payload = {};

    Object.keys(randomMessageModel).forEach((key) => {
        payload[key] = randomMessageModel[key];

        switch(key) {
            case 'type': {
                payload[key] = ('全員' === randomMessageModel.type) ? 1 : 0;
                break;
            }
            case 'deliveryYear': {
                payload[key] = strToInt(randomMessageModel.deliveryYear);
                break;
            }
            case  'deliveryMonth': {
                payload[key] = strToInt(randomMessageModel.deliveryMonth);
                break;
            }
            case 'deliveryDay': {
                payload[key] = strToInt(randomMessageModel.deliveryDay);
                break;
            }
            case 'deliveryHour': {
                payload[key] = strToInt(randomMessageModel.deliveryHour);
                break;
            }
            case 'deliveryMinute': {
                payload[key] = strToInt(randomMessageModel.deliveryMinute);
                break;
            }
            case 'messageBody': {
                payload[key] = randomMessageModel.messageBody;
                break;
            }
            case 'title': {
                payload[key] = randomMessageModel.messageBody;
                break;
            }
            case 'messageImage': {
                if("object" === typeof randomMessageModel.messageImage){
                    payload[key] = randomMessageModel.messageImage.name;
                }
                break;
            }
            case 'deliveryStatus': {
                payload[key] = ('配信する' === randomMessageModel.deliveryStatus) ? 1 : 0;
                break;
            }
            default: {
                break;
            }
        }
    });

    return ApiService.patch(Path.UPDATE_RANDOM_MESSAGE + `/${randomMessageModel._id}`, payload)
        .then(response => {
            if(onApiSuccess(response)) {
                
                let updatePayload = {
                    ...payload,
                    _id: randomMessageModel._id,
                    type: formatDeliveryType(payload),
                    deliveryYear: strToInt(payload.deliveryYear),
                    messageImage: isStringNullEmptyOrUndefined(randomMessageModel.image) ? "" : `RandomMessage/${randomMessageModel._id}/${randomMessageModel._id}`,
                    deliveryStatus: formatDeliveryStatus(payload)
                }
                
                payload = { ...updatePayload };
                dispatch(Actions.updateRandomMessage(payload));
            } else {
                return Promise.reject(new Error('Exception occurred during update.'));
            }

            return response;
        })
        .catch((error) => {
            console.error(error);
            return error;
        });
};

const deleteRandomMessage = (index) => dispatch => {
    return ApiService.post(Path.DELETE_RANDOM_MESSAGE, {'randomMessageId' : `${index._id}`})
        .then(response => {
            if (onApiSuccess(response)) {
                dispatch(Actions.deleteRandomMessage(`${index._id}`));
            }
            return response;
        })
        .catch(error => {
            console.error(error);
        });
};

export {
    createRandomMessage,
    getRandomMessage,
    getAllRandomMessages,
    getAllRecipients,
    updateRandomMessage,
    deleteRandomMessage
};
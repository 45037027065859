import Instagram from "./Instagram";
import Facebook from "./Facebook";
import Twitter from "./Twitter";

export const routes = [
  {
    path: "/instagram",
    component: Instagram,
    exact: true,
    showNav: true,
  },
  {
    path: "/facebook",
    component: Facebook,
    exact: true,
    showNav: true,
  },
  {
    path: "/twitter",
    component: Twitter,
    exact: true,
    showNav: true,
  },
];

import React, { Component } from "react";
import { View, TouchableOpacity, TextInput, Image, ActivityIndicator } from "react-native";
import img from "../../../includes/img/icons/search.svg";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { chatOperations } from "./state";
import { EMPTY_STRING, ENTER_KEY, SEARCH_KEY } from "../../../utils/constants";
import UserListItemComponent from "../../components/UserListItemComponent";
import styles from "./styles/newchat.styles.native.js";
import "./styles/chat.scss";
import ErrorModal from "../../components/ErrorModal";
import { SYSTEM_ERROR } from "../../../utils/messages";
import { appendUserRoute, onApiSuccess, isStringNullEmptyOrUndefined, defaultTopScroll } from "../../../utils/common";
import { FUMIYA_USER_ID, FF_STAFF_USER_ID } from "../../../config/settings";
import { Media } from 'react-breakpoints'
import { isMobile } from "react-device-detect";

class NewChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: props.userId,
      searchFieldInput: EMPTY_STRING,
      currentPagination: 1,
      scrollEnd: true,
      friendsList: [],
      isErrorModalShow: false,
      isUserFetching: true,
      isLastPage : false,
      isWhiteSpace : true,
      hash: Date.now()
    };
  }

  componentDidMount() {
    defaultTopScroll();
    //Fetch query string
    const query = new URLSearchParams(this.props.location.search);
    let searchFieldInput = query.get('search')
    if(!isStringNullEmptyOrUndefined(searchFieldInput)){
      this.setState({
        searchFieldInput 
      }, ()=>{
          //Call Search friend
          this.handleSearchFriend()
      })
    }else{
      this.props
      .getAllFriends(this.state.userId, this.state.currentPagination)
      .then(response => {
        if (onApiSuccess(response)) {
          let data = JSON.parse(response.data.data);
          let friends = data.filter(function(item) {
              return FUMIYA_USER_ID !== item.userId && FF_STAFF_USER_ID !== item.userId
           })
          this.setState({
            friendsList : friends,
            isUserFetching: false
          })
        }else{
          this.setState({
            isErrorModalShow: true,
            errorMessage: SYSTEM_ERROR
          })
        }
      });
    }
  }

  componentWillReceiveProps(props){
    const query = new URLSearchParams(props.location.search);
    let searchFieldInput = query.get('search')
    
    if(searchFieldInput !== this.state.searchFieldInput && !isStringNullEmptyOrUndefined(searchFieldInput)){
      this.setState({searchFieldInput},()=>this.handleSearchFriend(true))
    }
  
  }

  handleInputChange = event => {
   this.setState({
      searchFieldInput: event
    }, ()=>{
      this.checkInput()
    });
  };

  handleOnKeyPress = event => {
    if(!this.state.isWhiteSpace){
      if (ENTER_KEY === event.nativeEvent.key || SEARCH_KEY === event.nativeEvent.key) {
        this.handleSearchFriend();
      }
    }
  };

  handleSearchFriend = (backSearch) => {
    defaultTopScroll();
    
    let searchFieldInput = this.state.searchFieldInput.trim()

    if(!backSearch){
      //Update URL for retaining search input
      let encoded_string = encodeURIComponent(searchFieldInput)
      let searchRoute = appendUserRoute(`/newchat?search=${encoded_string}`)
      this.props.history.push(searchRoute)
    }
    

    this.setState({
      currentPagination : 1,
      isUserFetching: true
    }, ()=>{
      const searchModel = {
        userId: this.state.userId,
        handleName: searchFieldInput,
        currentPagination: this.state.currentPagination
      };
  
      this.props.searchFriend(searchModel).then(response => {
        if (onApiSuccess(response)) {
          let data = JSON.parse(response.data.data);
          let friends = data.filter(function(item) {
            return FUMIYA_USER_ID !== item.userId && FF_STAFF_USER_ID !== item.userId
          })
          this.setState({
            friendsList : friends,
            isUserFetching: false
          })
        }else{
          this.setState({
            isErrorModalShow: true,
            errorMessage: SYSTEM_ERROR
          })
        }
      });
    })
  };

  handlePagination = () => {
    if(!this.state.isLastPage){
      let searchFieldInput = this.state.searchFieldInput.trim();
      let currentPagination = this.state.currentPagination + 1;
      const searchModel = {
        userId: this.state.userId,
        handleName: searchFieldInput,
        currentPagination 
      };
        if(!this.state.isUserFetching){
          this.props.searchFriend(searchModel).then(response => {
            if (onApiSuccess(response)) {
              let data = JSON.parse(response.data.data);
              if(0 < data.length){
                let friends = data.filter(function(item) {
                  return FUMIYA_USER_ID !== item.userId && FF_STAFF_USER_ID !== item.userId
                })
                this.setState(prevState => ({
                  friendsList: [...prevState.friendsList, ...friends]
                }))
              }else{
                this.setState({
                  isLastPage : true
                })
              }
            }else{
              this.setState({
                isErrorModalShow: true,
                errorMessage: SYSTEM_ERROR
              })
            }
          });
        }
      }
      this.setState({
        currentPagination: this.state.currentPagination + 1
      });
  };

  handleSelectFriend = item => {
    let conversationRoute = appendUserRoute(`/chatmessages/${item.userId}`)
    this.props.history.push({
      pathname: conversationRoute,
      state: {
        handleName : item.handleName
      }
    });
  };

  handleCloseErrorModal = () => {
    this.setState({
      isErrorModalShow : false
    })
  }

  checkInput = () => {
    if (0 >= this.state.searchFieldInput.replace(/\s/g, '').length) {
      this.setState({
        isWhiteSpace : true
      })
    }else{
      this.setState({
        isWhiteSpace : false
      })
    }
  }
  
  render() {
    return(
      <Media>
      {({ breakpoints, currentBreakpoint }) => {
        let isMobileView = (breakpoints[currentBreakpoint] < breakpoints.mobileLandscape) || isMobile;
        return (
          <div className="newchat-container">
            <div className="newchat-header">
              <p className="new-chat-header-text">新規メッセージ</p>
            </div>
              <View style={styles.searchContainer}>
                <View style={styles.showSearchBtn}>
                  <TextInput
                    placeholder="宛先"
                    value={this.state.searchFieldInput}
                    onChangeText={this.handleInputChange}
                    onKeyPress={this.handleOnKeyPress}
                    style={isMobileView ? styles.inputMobile : styles.input}
                    returnKeyType="search"
                    className="search-input"
                    autoComplete="new-password"
                  />
                  <TouchableOpacity activeOpacity={0.7} disabled={this.state.isWhiteSpace} onPress={() => this.handleSearchFriend()}>
                    <Image
                      alt="search-icon"
                      source={img}
                      style={this.state.isWhiteSpace ? [styles.searchIcon, styles.halfOpacity] : styles.searchIcon}
                    />
                  </TouchableOpacity>
                </View>
                <View style={styles.horizontalBorder} />
              </View>
                {this.state.isUserFetching ?
                  (
                    <center className="w-100">
                      <ActivityIndicator
                          style={{
                            marginTop: 20,
                            height: 50,
                            display: this.state.isUserFetching ? "block" : "none"
                          }}
                      />
                    </center>   
                  )
                :
                <View style={styles.flatlistContainer}>
                    <UserListItemComponent
                      {...this.state}
                      hash={this.state.hash}
                      list={this.state.friendsList}
                      isCommunity={false}
                      isUserList={false}
                      handlePagination={this.handlePagination}
                      handleSelectItem={this.handleSelectFriend}
                    />
                  </View>
                }
     

            <ErrorModal
              {...this.state}
              handleCloseModal={this.handleCloseErrorModal}
              customContent={null}
              errorMessage={this.state.errorMessage}
            />
          </div>
        );
      }}
     </Media>
    )
  }
}

//Bind dispatch to action creators
const mapStateToProps = state => {
  return {
    userId: state.auth.credentials.userId
  };
};
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllFriends: chatOperations.getAllFriends,
      searchFriend: chatOperations.searchFriend
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(NewChat);

import React, { useState, useEffect, useImperativeHandle, useRef, forwardRef } from 'react';
import AuthorHeader from '../AuthorHeader';
import { Dropdown, Card } from 'react-bootstrap';
import { POST_TYPES, ATTACHMENT_TYPE, UPLOAD_STATUS, SOURCE, COMMUNITY_POST_TYPES } from '../../../utils/constants';
import PropTypes from 'prop-types';
import MenuIcon from '../../../includes/img/icons/dot-menu.svg';
import { Media } from 'react-breakpoints'
import { isMobile } from "react-device-detect";
import { useSelector } from 'react-redux';
import { appendUserRoute, getObjDateValue } from '../../../utils/common';
import { MenuItem } from '../MenuItem';
import './styles.scss';
import RenderCreateComment from './RenderCreateComment';
import RenderCommentCount from './RenderCommentCount';
import RenderLivestream from './RenderLivestream';
import RenderVideo from './RenderVideo';
import RenderPhotos from './RenderPhotos';
import PostContent from './PostContent';
import RenderComments from './RenderComments';
import ConfirmationModal from '../ConfirmationModal';
import User404 from '../../containers/Errors/User404';
import PostViewSkeleton from '../Skeletons/PostViewSkeleton';

//Custom Component
const ErrorBody = ({ userId, authorId, attachmentType, photos, videoStatus }) => {
    let hasFailed = false;
    let failedCount = 0;

    switch (attachmentType) {
        case ATTACHMENT_TYPE.image: {
            let counter = photos.filter(item => UPLOAD_STATUS.failed === item.imageStatus).length;
            hasFailed = 0 < counter;
            failedCount = counter;
            break;
        }
        case ATTACHMENT_TYPE.video: {
            hasFailed = UPLOAD_STATUS.failed === videoStatus;
            failedCount = hasFailed ? 1 : 0;
            break;
        }
        default: break;
    }


    return (userId === authorId && hasFailed) ? <p className="error" style={{ cursor: 'default' }}>{`写真${failedCount}点のアップロードに失敗しました。`}</p> : "";
}

const CustomToggle = React.forwardRef(({ onClick }, ref) => (
    <a
        href="_blank"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        <img alt="menu-icon" src={MenuIcon} style={{ width: 24, height: 24 }} />
    </a>
));

const PostViewTemplate = (props, ref) => {
    //Default top screen
    useEffect(() => { window.scrollTo(0, 0); }, [])

    //Image hash extension
    const hash = Date.now();

    //Redirect Edit Method
    const redirectEditPost = () => props.history.push({
        pathname: appendUserRoute(`/post/edit/${props.postId}`),
        state: { previousPath: props.history.location.pathname }
    });

    //Redux store data
    const userId = useSelector(state => state.auth.credentials.userId);

    //Hooks state
    const [showDelete, setShowDelete] = useState(false);

    //Conditions
    const isOwner = (userId === props.author.userId);
    const displayLiveStream = POST_TYPES.livestream === props.type;
    const displayPhotos = ((POST_TYPES.normal === props.type || (COMMUNITY_POST_TYPES.poll === props.type && props.isCommunity)) && ATTACHMENT_TYPE.image === props.attachmentType && (isOwner || 0 < props.photos.filter(item => UPLOAD_STATUS.failed !== item.imageStatus).length));
    const displayVideo = ATTACHMENT_TYPE.video === props.attachmentType;
    const displayCreateComment = (POST_TYPES.album !== props.type && SOURCE.new === props.source);
    const displayAlbum = POST_TYPES.album === props.type;
    const isCommunity = props.isCommunity
    const newSystemPost = SOURCE.new === props.source;
   
    //Menu Items
    const ownerMenu = [
        { item: { label: "日記を編集", icon: require('../../../includes/img/icons/edit-white.svg'), small: true }, onPress: redirectEditPost },
        { item: { label: "日記を削除", icon: require('../../../includes/img/icons/delete-white.svg'), small: true }, onPress: () => setShowDelete(true) }
    ]

    let mainContent = <PostViewSkeleton />

    /**REFERENCES FOR PARENT CALLS*/
    const postViewRef = useRef();
    useImperativeHandle(ref, () => ({
        /**CHILDREN SIDE EFFECTS ON RECEIVED SOCKET MESSAGE */
        onWebsocketCommentSuccess: () => {
            createCommentRef.current.onSuccess()
        },
        onWebsocketReplySuccess: (strCommentId) => {
            createReplyRef.current.onSuccess(strCommentId)
        }
    }));

    //Child Refs
    const createCommentRef = React.useRef();
    const createReplyRef = React.useRef();

    if (props.isContentLoaded) {
        mainContent = (
            <div className="post-view-container">
                <AuthorHeader
                    {...props.author}
                    createDt={getObjDateValue(props.createDt)}
                    updateDt={getObjDateValue(props.updateDt)}
                    source={props.source}
                    hash={hash}
                />


                {

                    (isOwner && !isCommunity && newSystemPost) && (
                        <Dropdown className="post-item-menu">
                            <Dropdown.Toggle as={CustomToggle} id="dropdown-post-menu" />
                            <Dropdown.Menu alignRight className="post-item-menu-dropdown" >
                                {ownerMenu.map((menu, i) => (
                                    <MenuItem
                                        key={"menu" + i}
                                        action={true}
                                        item={menu.item}
                                        onPress={menu.onPress}
                                        titleStyle={{ color: "#fff", marginLeft: 12, fontSize: 14 }}
                                    />
                                )
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    )
                }
                <ErrorBody
                    userId={userId}
                    authorId={props.author.userId}
                    photos={props.photos}
                    attachmentType={props.attachmentType}
                    videoStatus={props.videoStatus}
                />
                <PostContent title={props.title} postBody={props.postBody} poll={props.poll} postType={props.type} />
            </div>
        )
    }

    return (
        <Media ref={postViewRef}>
            {({ breakpoints, currentBreakpoint }) => {
                let mobileView = (breakpoints[currentBreakpoint] < breakpoints.mobileLandscape) || isMobile;
                if (props.isAccessInvalid) {
                    return (
                        <User404 />
                    )
                }
                return (
                    <React.Fragment>
                        <Card className={isCommunity ? "feed-community-container-view" : "feed-container-view"} style={mobileView ? { border: 'none' } : {}}>
                            {
                                isCommunity ?
                                    <div className="view-community-header">
                                        <p className="view-header-text">{props.communityName}</p>
                                    </div>
                                    : null
                            }
                            <Card.Body style={{ padding: 0 }}>
                                {mainContent}

                                {displayLiveStream && !isCommunity && (
                                    <RenderLivestream vimeoLivestreamId={props.vimeoLivestreamId} />
                                )}

                                {displayPhotos && (
                                    <div>
                                        <RenderPhotos
                                            {...props}
                                            userId={userId}
                                        />
                                    </div>
                                )}

                                {displayVideo && (
                                    <RenderVideo
                                        {...props}
                                        userId={userId}
                                    />
                                )}

                                <RenderCommentCount count={props.commentCount} />

                                {displayCreateComment && !isCommunity && (
                                    <RenderCreateComment
                                        hash={hash}
                                        ref={createCommentRef} //Ref to call child functions
                                        createComment={props.createComment}
                                        authorId={props.author.userId}
                                    />
                                )}

                                <RenderComments
                                    ref={createReplyRef} //Ref to call child functions
                                    {...props}
                                    postId={props.postId}
                                    postAuthorId={props.author.userId}
                                    comments={props.comments}
                                    userId={userId}
                                    hash={hash}
                                    isCommentFetching={props.isCommentFetching}
                                />

                                {displayAlbum && (
                                    <RenderPhotos
                                        {...props}
                                        isAlbum={true}
                                        userId={userId}
                                    />
                                )}
                            </Card.Body>
                        </Card>

                        {/* Delete PostConfirmation */}
                        <ConfirmationModal
                            isModalShow={showDelete}
                            confirmationText={"日記を削除しますか？"}
                            confirmTitle={"削除"}
                            handleConfirmAction={() => props.deletePost(props.postId).then(result=>setShowDelete(false))}
                            handleCloseModal={() => setShowDelete(false)}
                        />

                    </React.Fragment>
                )
            }}
        </Media>
    );
};

export default forwardRef(PostViewTemplate);

PostViewTemplate.propTypes = {
    userId: PropTypes.number,
    deletePost: PropTypes.func,
    isCommunity: PropTypes.bool,
    communityId: PropTypes.string,
    postId: PropTypes.string,
    author: PropTypes.object,
    createDt: PropTypes.object,
    updateDt: PropTypes.object,
    title: PropTypes.string,
    postBody: PropTypes.string,
    type: PropTypes.number,
    attachmentType: PropTypes.number,
    photos: PropTypes.array,
    videoURL: PropTypes.string,
    videoStatus: PropTypes.number,
    commentCount: PropTypes.number,
    source: PropTypes.number,
    vimeoLivestreamId: PropTypes.string,
    startLivestreamDt: PropTypes.string,
    endLivestreamDt: PropTypes.string,
    isPoll: PropTypes.bool,
    poll: PropTypes.object,
};
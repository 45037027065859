import { S3_URL_BUCKET, CLOUDFRONT_URL } from "../config/settings";

/*Define all constants*/
export const DATE_FORMAT = "YYYY/MM/DD";
export const DATETIME_FORMAT = "YYYY/MM/DD HH:mm"
export const API_SUCCESS_STATUS = "Success";
export const API_ERROR_STATUS = "Error";
export const API_NETWORK_ERROR = "Network Error";
export const STATUS_ERROR = "error";
export const EMPTY_STRING = ""
export const ENTER_KEY = "Enter"
export const ENTER_KEY_CODE = 13
export const SEARCH_KEY = "search"
export const ACCEPTED_IMAGE_TYPES = ['image/jpg', 'image/pjp', 'image/pjpeg', 'image/jpeg', 'image/png', 'image/jfif'];
export const WEBSOCKET_API_ERROR_STATUS = "Internal server error";
export const ACCEPTED_VIDEO_TYPES = ['video/mp4', 'video/quicktime'];
export const SINGLE_SPACE_CHAR =" "
export const USER_SESSION_EXISTING_FLAG = "user_session_exist";

/*Constant navigation*/
export const CHAT_NAVIGATION = "/chat"
export const NOTIFICATIONS_NAVIGATION = "/notifications"
export const APP_NEWSFEED = "/app/newsfeed"

/* Define Bucket URLs */
export const CHAT_THUMBNAIL_BUCKET_URL = CLOUDFRONT_URL + '/Thumbnail-Chat/'
export const RANDOM_MSG_THUMBNAIL_BUCKET_URL = CLOUDFRONT_URL + '/Thumbnail-RandomMessage/'
export const PROFILE_THUMBNAIL_BUCKET_URL = CLOUDFRONT_URL + '/Thumbnail-Profile/'
export const S3_THUMBNAIL_PREFIX_URL = S3_URL_BUCKET + "/Thumbnail-";
export const S3_PROFILE_THUMBNAIL_BUCKET_URL = S3_URL_BUCKET + '/Thumbnail-Profile/'

// /**Cloudfront CDN Url */
export const CLOUDFRONT_THUMBNAIL_PREFIX_URL = CLOUDFRONT_URL + "/Thumbnail-";
export const S3_DEFAULT_URL = S3_URL_BUCKET + "/";

/* Thumbnail sizes */
export const THUMBNAIL_THUMB = "-thumb"
export const THUMBNAIL_SMALL = "-small"
export const THUMBNAIL_MEDIUM = "-medium"
export const THUMBNAIL_LARGE = "-large"

/*Define all limits*/
export const MAX_TEXT_LINES = 10;
export const WRAP_TEXT_CHARS = 250;
export const POST_MAX_CHAR = 50000;
export const FUMIYA_COMMENTS_MAX_CHAR = 1000;
export const COMMENTS_MAX_CHAR = 10000;
export const REPLIES_MAX_CHAR = 10000;
export const CHAT_MAX_CHAR = 1000;
export const IMAGE_MAX_UPLOAD = 10;
export const THREAD_MAX_CHAR = 50;
export const POST_MAX_IMAGE = 30;
export const DELIVERY_HISTORY_MAX_DISPLAY_ROWS = 50;

/** Cognito Variables */
export const INT_NOT_FOUND = -1

/* Deleted user */
export const DELETED_USER_HANDLENAME = "退会ユーザ"


/**Admin constants */
export const NOT_AUTHORIZED = "NotAuthorizedException";
export const USER_NOT_FOUND = "UserNotFoundException";
export const RECIPIENTS_TBL_PAGE_SIZE = 30;
export const USER_MANAGEMENT_TBL_PAGE_SIZE = 50;
export const RANDOM_MESSAGE_BODY_MAX_LENGTH = 1000;
export const PASSWORD_CHANGE_MAX_LENGTH = 16;
export const USER_ID_MAX_LENGTH = 6;
export const HANDLE_NAME_MAX_LENGTH = 64;
export const EMAIL_MAX_LENGTH = 254;
export const NOTIFICATION_TITLE_MAX_LENGTH = 64;
export const IMAGE_MAX_SIZE = 10000000
export const ADMIN_LEVEL_ONE = "1";
export const ADMIN_LEVEL_TWO = "2";
export const PASSWORD_PLACEHOLDER = "•"
export const MAINTENANCE_TYPE = {
    open: 0,
    normal: 1,
    emergency: 2,
    temporary: 3,
}

export const MAINTENANCE_TEXT = {
    open: "Open",
    normal: "Normal",
    emergency: "Emergency",
    temporary: "Temporary",
}

/*Regex*/
export const ALPHA_NUMERIC = /[^a-zA-Z0-9 ]/g
export const REGEX_NUMERIC = /[^0-9]/
export const CUSTOM_ADDRESS_NOT_ALLOWED_REGEX = /([^",])$/

export const ATTACHMENT_TYPE = {
    none: 0,
    image: 1,
    video: 2
}

export const POST_TYPES = {
    normal: 0,
    livestream: 1,
    album: 2
}

export const UPLOAD_STATUS = {
    uploading: 0,
    success: 1,
    failed: 2
}

export const MODE = {
    add: "add",
    edit: "edit",
    confirm: "confirm"
}

export const SOURCE = {
    new: 0,
    oneGeneration: 1,
    twoGeneration: 2
}

export const RANDOM_MSG_TYPES = {
    random: 0,
    all: 1
}
export const CHAT_TYPE = {
    text: 0,
    image: 1
}
export const NOTIF_TYPE = {
    postRelated: 0,
    friendRequest: 1,
    acceptedRequest: 2,
    removedRequest: 3
}

export const RELATIONSHIPS = {
    isFriend: "is_friend",
    isBlocked: "blocked",
    isSent: "is_sent",
    isReceived: "is_received"
}

export const WEBSOCKET_ACTIONS = {
    onCreateConnection: "createConnection",
    onMessage: "onMessage",
    onImageUpload: "onImageUpload",
    onMessageDelete: "onMessageDelete",
    updateSeenStatus: "updateSeenStatus",
    pingServer: "onPing",
    onSendRequest: "onSendRequest",
    onCreateComment: "onCreateComment",
    onCreateReply: "onCreateReply",
    onAcceptRequest: "onAcceptRequest",
    onNewAcceptRequest: "onNewAcceptRequest",
    onRemoveRequest: "onRemoveRequest",
    onNewRemoveRequest: "onNewRemoveRequest",  
    onNotifications: "onNotifications"  
}

export const WEBSOCKET_MESSAGES = {
    onMessage: "message",
    onImageUpload: "imageUpload",
    onImageRender: "imageRender",
    onMessageDelete: "messageDelete",
    onError: "error",
    onAttachmentError: "attachmentError",
    onLimitError: "attachmentLimit",
    onPostImageRender: "postImageRender",
    onPostVideoRender: "postVideoRender",
    onSendRequest: "sendRequest",
    onCreateComment: "createComment",
    onCreateReply: "createReply",
    onAcceptRequest: "newAcceptRequest",
    onNewAcceptRequest: "newAcceptRequest",
    onRemoveRequest: "removeRequest", 
    onNewRemoveRequest: "newRemoveRequest", 
    onNotifications: "notifications",
    onCustomErrorMessage: "customErrorMessage",
    onProfileUpdate: "profileUpdate",
    onCoverUpdate: "coverUpdate",
    onChatNotification : "chatNotifications",
    onNotificationError : "notificationError",
    onCreateCommentError : "createCommentError",
    onCreateReplyError : "createReplyError",
    onBlockUser : "blockUser",
    onUnblockUser : "unblockUser",
    onNotFound: "notFound"
  };

  export const IMAGE_TYPE = {
      profile: "profile",
      cover: "cover"
  }

  export const PREVIEW_TYPE = {
    public: "public",
    friends: "friends"
  }

  export const PRIVACY = {
    public: 0,
    friends: 1,
    private: 2
  }

/*Instagram API Error Message*/
export const API_LIMIT_REACHED_CODE = 4

export const POST_PRIVACY = {
    public: false,
    friends: true
}

/*Facebook API Error Message*/
export const PAGE_API_LIMIT_REACHED_CODE_1 = 32
export const PAGE_API_LIMIT_REACHED_CODE_2 = 80001

export const FACEBOOK_ATTACHMENT_TYPES = {
    photo: "photo",
    video: "video",
    share: "share",
    album: "album",
    profile: "profile_media",
    link: "link",
    cover_photo: "cover_photo",
}


export const BREAKPOINTS = {
    mobile: 320,
    mobileLandscape: 638,
    tablet: 768,
    tabletLandscape: 1024,
    desktop: 1200,
    desktopLarge: 1500,
    desktopWide: 1920,
}

export const USER_TYPE = {
    admin: "admin",
    user: "user"
}

export const COMMUNITY_POST_TYPES = {
    topic: 0,
    poll: 1
}

export const COMMENT_TYPE = {
    text: 0,
    image: 1
}

export const S3_STATUS_SUCCESS = 204
import React from 'react';
import { Card } from 'react-bootstrap';
import '../../containers/Errors/User404';

const MaintenanceScreen = (props) => {
    return (
        <Card className="card-404">
            <Card.Title className="card-title-404">
            </Card.Title>
            <Card.Body>
                <center>
                    {props.isNormalMaintenance && (
                        <React.Fragment>
                            <p>只今システムのメンテナンス中です。</p>
                            <p>終了まで今しばらくお待ちください。</p>
                            <p>ご理解とご協力をお願い申し上げます。</p>
                            <br/>
                            <p>【メンテナンス情報（実施時間等）】</p>
                        </React.Fragment>
                    )}
                    {props.isEmergencyMaintenance && (
                        <React.Fragment>
                            <p>只今システムの緊急メンテナンス中です。</p>
                            <p>終了まで今しばらくお待ちください。</p>
                            <p>ご理解とご協力をお願い申し上げます。</p>
                            <br/>
                            <p>【メンテナンス情報（実施時間等）】</p>
                        </React.Fragment>
                    )}
                    {props.isTemporaryMaintenance && (
                        <React.Fragment>
                            <p>只今システムの臨時メンテナンス中です。</p>
                            <p>終了まで今しばらくお待ちください。</p>
                            <p>ご理解とご協力をお願い申し上げます。</p>
                            <br/>
                            <p>【メンテナンス情報（実施時間等）】</p>
                        </React.Fragment>
                    )}
                    <a href="https://www.fumiyafujii.net/fanclub" target="_blank" style={{display: 'inline'}}>Fanclubページ</a>
                    <p style={{display: 'inline'}}>にてご確認ください。</p>
                </center>
            </Card.Body>
        </Card>
    );
};

export default MaintenanceScreen;
import React from "react";
import { View, TouchableOpacity, Image, Text, StyleSheet } from "react-native";
import MessageIcon from "../../../includes/img/icons/messages.svg";
import { isMobile } from "react-device-detect";
import { Media } from 'react-breakpoints'

const DeleteMenuWindow = props => {
  if (props.isDeleteMenuShow) {
    return (
      <Media>
      {({ breakpoints, currentBreakpoint }) => {
        return(
          <View style={breakpoints[currentBreakpoint] < breakpoints.mobileLandscape || isMobile ?  styles.menuContainer : styles.menuContainerPC}>
            <View style={breakpoints[currentBreakpoint] < breakpoints.mobileLandscape || isMobile ?  styles.menuActionContainer :  styles.menuActionContainerPC }>
              <TouchableOpacity
                style={styles.menuTouchable}
                onPress={() => props.handleShowDeleteThread()}
              >
                <Image
                  alt="pen-icon"
                  source={MessageIcon}
                  style={styles.messagesIcon}
                />
                <Text style={breakpoints[currentBreakpoint] < breakpoints.mobileLandscape || isMobile ? styles.menuText : styles.menuTextPC}>メッセージを選択</Text>
              </TouchableOpacity>
            </View>
          </View>
        )
      }}
      </Media>
    );
  } else {
    return null;
  }
};

const styles = StyleSheet.create({
  menuText: {
    color: "white",
    padding: 5,
    paddingLeft: 0,
    fontSize: 12,
    fontFamily: "kozuka-gothic-pro, sans-serif",
  },
  menuTextPC: {
    color: "white",
    padding: 5,
    paddingLeft: 0,
    fontSize: 14,
    fontFamily: "kozuka-gothic-pro, sans-serif",
  },
  menuActionContainer: {
    padding: 7,
    paddingTop: 5,
    marginRight: 5,
    backgroundColor: "#000000",
    borderRadius: 3,
    opacity: 0.7,
    height: 40
  },
  menuActionContainerPC: {
    padding: 7,
    paddingTop: 2,
    marginRight: 5,
    backgroundColor: "#000000",
    borderRadius: 3,
    opacity: 0.7,
    height: 40
  },
  menuContainer: {
    position: "absolute",
    alignSelf: "flex-end",
    marginTop: 55,
    zIndex: 2,
    width: 150
  },
  menuContainerPC: {
    position: "absolute",
    alignSelf: "flex-end",
    marginTop: 55,
    zIndex: 2,
    width: 180
  },
  menuTouchable: {
    flex: 1,
    flexDirection: "row"
  },
  messagesIcon: {
    height: 20,
    width: 20,
    margin: 5,
    resizeMode: "stretch"
  }
});

export default DeleteMenuWindow;

import * as types from "./types";

const defaultState = {
    randomMessageList:[]
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.SET_RANDOM_MESSAGE_LIST:
            return {
                ...state,
                randomMessageList: action.payload
            };
        case types.INSERT_RANDOM_MESSAGE:
            return { 
                ...state,
                randomMessageList: [ ...state.randomMessageList, action.payload ]
                                    .sort((a,b) => { return new Date(b.deliveryDate) - new Date(a.deliveryDate); })
            };
        case types.UPDATE_RANDOM_MESSAGE: 
            return {
                ...state,
                randomMessageList: [ ...state.randomMessageList.map((randomMessage) => {
                    if(randomMessage._id === action.payload._id) {
                        Object.keys(action.payload).forEach((key) => {
                            randomMessage[key] = action.payload[key];
                        });
                    }
                    return randomMessage;
                }) ]
            };
        case types.DELETE_RANDOM_MESSAGE:
            return {
                ...state,
                randomMessageList: state.randomMessageList.filter(randomMessage => randomMessage._id !== action.payload)
            };
        default: return state;
    }
};
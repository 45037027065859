import React from 'react';
import { Image } from 'react-bootstrap';
import { UPLOAD_STATUS, CLOUDFRONT_THUMBNAIL_PREFIX_URL, SOURCE, S3_DEFAULT_URL } from '../../../utils/constants';
import UploadingPlaceholder from '../../../includes/img/placeholders/uploading.png';
import FailedPlaceholder from '../../../includes/img/placeholders/failed-grey.png';
import RenderCommentCount from './RenderCommentCount';
import './styles.scss';
import PostContent from './PostContent';
import RenderComments from './RenderComments';
import 'lazysizes';

const RenderPhotos = React.memo(({ photos, userId, author, source, isAlbum }) => {
    let isOwner = userId === author.userId;

    const renderItem = (uri, item, index) => {
        if (isAlbum) {
            return (
                <div key={"i" + index} className="post-view-album-image">
                    <div className="post-view-container">
                        <PostContent title={item.title || ''} postBody={item.body || ''} />
                    </div>
                    <Image src={uri} className="post-view-image lazyload" alt="post-image" style={{ marginBottom: -1 }} />
                    <RenderCommentCount count={item.comments ? item.comments.length : 0} />
                    <RenderComments
                        props={source}
                        comments={item.comments ? item.comments : []}
                        userId={userId}
                        isCommentLoaded={true}
                    />
                </div>
            )
        } else {
            return <Image key={"i" + index} src={uri} className="post-view-image lazyload" alt="post-image" />
        }
    }

    return photos.map((item, i) => {
        switch (item.imageStatus) {
            case UPLOAD_STATUS.uploading: return renderItem(UploadingPlaceholder, item, i);
            case UPLOAD_STATUS.success: {
                if(SOURCE.new === source){
                    return renderItem(CLOUDFRONT_THUMBNAIL_PREFIX_URL + item.imageUrl + "-large", item, i);
                }else{
                    //Absolute path for migrated readonly photos
                    return renderItem(S3_DEFAULT_URL + item.imageUrl, item, i);
                }
            }
            case UPLOAD_STATUS.failed: return isOwner ? renderItem(FailedPlaceholder, item, i) : null;
            default: return renderItem(item.imageUrl);
        }
    });
})


export default RenderPhotos;
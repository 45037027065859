import {ADMIN_ROOT_API_URL} from "../../../../config/settings.js"

export const CREATE_NOTIFICATION = ADMIN_ROOT_API_URL + "/create_notification";

export const GET_NOTIFICATION = ADMIN_ROOT_API_URL + "/get_notification";

export const GET_ALL_NOTIFICATIONS = ADMIN_ROOT_API_URL + "/get_all_notifications";

export const UPDATE_NOTIFICATION = ADMIN_ROOT_API_URL + "/update_notification";

export const DELETE_NOTIFICATION = ADMIN_ROOT_API_URL + "/delete_notification";
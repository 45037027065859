import * as Path from "./apiRoutes";
import ApiService from "../../../../utils/apiService";

const getAllPastCommunities = (currentPagination) => dispatch => {
  return ApiService.get( Path.GET_COMMUNITY_LIST + `/${currentPagination}`)
    .then(response => {
        return response
    })
    .catch(error => {
      console.error(error);
    });
};

const getCommunity = (communityId) => dispatch => {
    return ApiService.get( Path.GET_COMMUNITY_DETAILS + `/${communityId}`)
      .then(response => {
        return response
      })
      .catch(error => {
        console.error(error);
      });
  };

const getCommunityPosts = (communityId, currentPagination) => dispatch => {
  return ApiService.get(Path.GET_COMMUNITY_POSTS + `/${communityId}/${currentPagination}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error);
    });
};

  export {
    getAllPastCommunities,
    getCommunity,
    getCommunityPosts
  };
import React, { Component } from 'react';
import TableTemplate from '../../components/TableTemplate';
import { Button } from "react-bootstrap";
import { randomMessageTable } from './state/templates';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AdminCard from '../../components/AdminCard';
import PaginationTemplate from '../../components/PaginationTemplate';
import ErrorModal from '../../components/ErrorModal';
import { randMsgOperations } from './state';
import { isNullOrUndefined, formatDeliveryDate, onApiSuccess, isStringNullEmptyOrUndefined, getObjKeyIdString, adminUploadToS3, appendUserRoute, defaultTopScroll } from '../../../utils/common';
import { RECIPIENTS_TBL_PAGE_SIZE } from '../../../utils/constants';
import RandomMessageModal from './RandomMessageModal';
import './styles/randomMessage.scss';
import { MSG000_SYSTEM_ERROR, MSG009_SYS_ERR_SAVING_RECORD } from '../../../utils/messages/admin/errorMessages';
import ProcessLoader from '../../components/ProcessLoader';
import { profileOperations } from '../EditProfile/state';
import { authOperations } from '../Authentication/state';
import moment from "moment"
import ConfirmationModal from '../../components/ConfirmationModal';


class RandomMessageList extends Component {

    constructor(props) {
        super(props);
            this.state = {  
            currentPage: 1,
            currentRecipientsPage: 1,
            totalPage: 0,
            isModalShow: false,
            isCreate: false,
            currRandomMsg: {},
            prevRandomMsg: {},
            modalType: '',
            recipientList: [],
            imageFile: '',
            prevModal: '',
            isErrorModalShow: false,
            isTableShow: false,
            totalRecords: 0,
            isProcessing: false,
            isRedirect: false,
            userRoute: '',
            errorMessage: '',
            initialLoad: true,
            isRedirectModalShow: false
        }

        this.userRedirect = React.createRef();

        window.onbeforeunload = (e) => {
            defaultTopScroll();
        };
    }

    componentDidMount() {
        defaultTopScroll();
        this.handlePagination(1);
        this.setState({
            initialLoad: false
        })
    }

    handlePagination = (currentPagination) => {
        if(this.state.currentPage === currentPagination && !this.state.initialLoad){
            return
        }
        this.setState({ isProcessing: true }, ()=> {
            this.props.getAllRandomMessages(currentPagination)
            .then(res => {
                if (onApiSuccess(res)) {
                    let responseData = JSON.parse(res.data.data);
                    this.setState({
                        currentPage: currentPagination,
                        totalPage: responseData.totalPages,
                        totalRecords: responseData.totalRecords,
                        isProcessing: false
                    });
                } else {
                    this.toggleErrorModal(MSG000_SYSTEM_ERROR);
                }
            })
            .catch(() => this.toggleErrorModal(MSG000_SYSTEM_ERROR));
        })
    }

    handleRedirectUser = (row) => {
        if("全員" !== row.handleName){
            this.setState({ isProcessing: true }, () => {
                if (!row.hasOwnProperty('userId') || 'undefined' === typeof row.userId) {
                    this.props.getUserId(row)
                        .then(res => {
                            if (onApiSuccess(res)) {
                                const responseData = JSON.parse(res.data.data);
                                const userId = responseData[0].userId;
    
                                //Redirect
                                this.setBackdoorAuth(userId, row.handleName);
                            } else {
                                this.toggleErrorModal()
                                return
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            this.toggleErrorModal()
                            return
                        });
                } else {
                    //Redirect
                    this.setBackdoorAuth(row.userId, row.handleName);
                }
            })
        }
    }

    /**Handles updating store to allow admin access prior to redirect user */
    setBackdoorAuth = (userId, handleName) => {
        let params = { userId, handleName, authenticated: true };

        //Set user details
        this.props.getUserProfile(userId)
            .then(() => {
                //Set backdoor store details
                this.props.setAdminBackdoorCheck(params)
                    .then(() => {
                        this.setState({ isProcessing: false, isRedirectModalShow: true });
                    }).catch(() => this.setState({ isProcessing: false }))
            }).catch(() => this.setState({ isProcessing: false }))
    }

    processDeliveryMinute = (item) => {
        const timestamp = item.timestamp;
        const deliveryMinute = timestamp.split(':')[1];
        item.timestamp = ('0' === deliveryMinute) ? timestamp + '0':  timestamp;
        return item;
    }

    handleRecipientsTblPageChange = (page) => {
        let currRecipientsList = []

        if ("全員" === this.state.currRandomMsg.type) {
            currRecipientsList = [{
                "userId": "",
                "handleName": "全員",
                "timestamp": this.state.currRandomMsg.deliveryDate
            }]
        }
        else {
            this.state.currRandomMsg.deliveryHistory.map(item => {
                // pass deliveryHistory entry as parameter
                return this.processDeliveryMinute(item);
            })

            currRecipientsList = this.state.currRandomMsg.deliveryHistory.slice((page - 1) * RECIPIENTS_TBL_PAGE_SIZE, page * RECIPIENTS_TBL_PAGE_SIZE);
        }

        this.setState({ currentRecipientsPage: page, recipientList: currRecipientsList });
    }

    renderPagination = () => {
        return (
            <PaginationTemplate
                currentPage={this.state.currentPage}
                totalPage={this.state.totalPage}
                handlePageChange={(page) => { this.handlePagination(page) }}
            />
        )
    }

    handleCreateRandomMessage = () => {
        this.setState({ prevModal: '', isModalShow: true, modalType: 'create', isCreate: true });
    }

    handleShowEditModal = () => {
        if (this.state.isCreate) {
            this.setState({ prevModal: 'confirm', modalType: 'create', isCreate: true });
        }
        else {
            this.setState({ prevModal: 'confirm', modalType: 'update', isCreate: false });
        }
    }

    handleDeleteRandomMessage = (row) => {
        this.setState({ currRandomMsg: row, isModalShow: true, modalType: 'confirmDelete' });
    }

    handleDbDelete = () => {
        this.props.deleteRandomMessage(this.state.currRandomMsg)
            .then(res => {
                if (onApiSuccess(res)) {
                    this.setState({ modalType: 'successDelete', totalRecords: this.state.totalRecords - 1 }, () => {
                        this.handlePagination(this.state.currentPage);
                    })
                } else {
                    //Error side effects
                    this.toggleErrorModal(MSG009_SYS_ERR_SAVING_RECORD);
                }
            })
            .catch(() => {
                this.toggleErrorModal(MSG009_SYS_ERR_SAVING_RECORD);
            });
    }

    handleTitleClick = (row) => {
        this.setState(prevState => ({
            currRandomMsg: row,
            prevRandomMsg: prevState.currRandomMsg,
            isModalShow: true,
            isCreate: false,
            modalType: 'update',
            currentRecipientsPage: 1,
        }), () => { this.handleRecipientsTblPageChange(this.state.currentRecipientsPage); });
    }

    handleConfirmClick = (values) => {
        if (this.state.isCreate) {
            this.setState({ currRandomMsg: values.RandomMessageModel, modalType: 'confirmUpdate' });
        } else {
            this.handleUpdateFields(values);
            this.setState({ modalType: 'confirmUpdate' });
        }
    }

    handleShowSuccessModal = (_id) => {
        this.setState({ _id, modalType: 'successUpdate', isCreate: false, isProcessing: false });
    }

    handleUpdateFields = (value) => {
        let updatedRandomMsg = JSON.parse(JSON.stringify(this.state.currRandomMsg));

        if (value.hasOwnProperty('RandomMessageUpdateModel')) {
            updatedRandomMsg.deliveryStatus = parseInt(value.RandomMessageUpdateModel.deliveryStatus) ? '配信する' : '配信しない';
        } else {
            value.RandomMessageModel.deliveryDate = formatDeliveryDate(value.RandomMessageModel);
            updatedRandomMsg.deliveryDate = value.RandomMessageModel.deliveryDate;
            updatedRandomMsg.type = parseInt(value.RandomMessageModel.type) ? '全員' : 'ランダム';
            updatedRandomMsg.deliveryStatus = parseInt(value.RandomMessageModel.deliveryStatus) ? '配信する' : '配信しない';

            /**
             *  If type == 1
             */
            if (parseInt(value.RandomMessageModel.type)) {
                // initialize new Date object
                let parseDeliveryDate = new Date(moment(value.RandomMessageModel.deliveryDate).toISOString());

                updatedRandomMsg.deliveryDay = parseDeliveryDate.getDate();
                updatedRandomMsg.deliveryHour = parseDeliveryDate.getHours();
                updatedRandomMsg.deliveryMinute = parseDeliveryDate.getMinutes();
            } else {
                updatedRandomMsg.deliveryYear = parseInt(value.RandomMessageModel.deliveryDate.split('/')[0]);
                updatedRandomMsg.deliveryMonth = parseInt(value.RandomMessageModel.deliveryDate.split('/')[1]);
            }

            updatedRandomMsg.messageBody = value.RandomMessageModel.messageBody;
            updatedRandomMsg.messageImage = value.RandomMessageModel.messageImage;
        }

        this.setState({ currRandomMsg: updatedRandomMsg });

    }

    handleUpdateDeliveryStatus = (value) => {
        let updatedRandomMsg = JSON.parse(JSON.stringify(this.state.currRandomMsg));
        updatedRandomMsg.deliveryStatus = parseInt(value) ? '配信する' : '配信しない';
        this.setState({ currRandomMsg: updatedRandomMsg });
    }

    handleUpdateSuccess = () => {
        this.setState({ isProcessing: true }, () => {
            if (this.state.isCreate) {
                this.props.createRandomMessage(this.state.currRandomMsg)
                    .then(res => {
                        if (onApiSuccess(res)) {
                            //Increment result count after create
                            this.setState(prevState=>({totalRecords: prevState.totalRecords + 1}))

                            let responseData = JSON.parse(res.data.data);
                            let promiseGroup = [];

                            if (0 < responseData.presignedUrls.length) {
                                promiseGroup.push(
                                    adminUploadToS3(responseData.presignedUrls[0], this.state.currRandomMsg.messageImage)
                                )
                            }

                            Promise.all([...promiseGroup, 0])
                                .then(() => {
                                    this.handleShowSuccessModal(getObjKeyIdString(responseData._id));
                                })
                                .catch((error) => {
                                    this.setState({handleShowSuccessModal: false})
                                    console.log(error)
                                })
                        } else {
                            this.toggleErrorModal(MSG009_SYS_ERR_SAVING_RECORD);
                        }
                    }).catch(error => {
                        console.log(error)
                        this.toggleErrorModal(MSG009_SYS_ERR_SAVING_RECORD);
                    });

            } else {
                const fieldsToUpdate = this.getUpdatedFields();

                if (Object.keys(fieldsToUpdate).length) {
                    this.props.updateRandomMessage(fieldsToUpdate)
                        .then(res => {
                            if (onApiSuccess(res)) {
                                let responseData = JSON.parse(res.data.data);
                                let promiseGroup = [];

                                if (!isNullOrUndefined(responseData.presignedUrls) && 0 < responseData.presignedUrls.length) {
                                    promiseGroup.push(
                                        adminUploadToS3(responseData.presignedUrls[0], this.state.currRandomMsg.messageImage)
                                    )
                                }

                                Promise.all([...promiseGroup, 0])
                                    .then(() => {
                                        this.handleShowSuccessModal(fieldsToUpdate._id)
                                    })
                                    .catch((error) => {
                                        this.setState({handleShowSuccessModal: false})
                                        this.toggleErrorModal(MSG009_SYS_ERR_SAVING_RECORD);
                                        console.log(error)
                                    })
                            } else {
                                this.setState({handleShowSuccessModal: false})
                                this.toggleErrorModal(MSG009_SYS_ERR_SAVING_RECORD);
                            }
                        })
                        .catch(error => {
                            this.setState({handleShowSuccessModal: false})
                            this.toggleErrorModal(MSG009_SYS_ERR_SAVING_RECORD);
                            console.log(error)
                        });
                }
                else {
                    this.handleCloseModal();
                }
            }
        })
    }

    handleCloseModal = () => {
        this.setState(prevState => ({ isModalShow: !prevState.isModalShow, modalType: '', currRandomMsg: {}, isProcessing: false }));
    }

    getUpdatedFields = () => {
        let prev = JSON.parse(JSON.stringify(this.state.prevRandomMsg));
        let curr = JSON.parse(JSON.stringify(this.state.currRandomMsg));
        let updated = JSON.parse(JSON.stringify(this.state.currRandomMsg));

        if (prev.type === curr.type) {
            delete updated.type;
        }
        if (prev.deliveryYear === curr.deliveryYear) {
            delete updated.deliveryYear;
        }
        if (prev.deliveryMonth === curr.deliveryMonth) {
            delete updated.deliveryMonth;
        }
        if (prev.deliveryDay === curr.deliveryDay) {
            delete updated.deliveryDay;
        }
        if (prev.deliveryHour === curr.deliveryHour) {
            delete updated.deliveryHour;
        }
        if (prev.deliveryMinute === curr.deliveryMinute) {
            delete updated.deliveryMinute;
        }
        if (prev.messageBody === curr.messageBody) {
            delete updated.messageBody;
            delete updated.title;
        }
        if ("string" === typeof curr.messageImage) {
            delete updated.messageImage;
        } else {
            updated.messageImage = this.state.currRandomMsg.messageImage
        }
        if (prev.deliveryStatus === curr.deliveryStatus) {
            delete updated.deliveryStatus;
        }

        delete updated.topErrorMessage;

        return updated;
    }

    setModalProps = () => {
        switch (this.state.modalType) {
            case 'create': {
                return {
                    prevModal: this.state.prevModal,
                    handleCloseModal: this.handleCloseModal,
                    handleConfirm: this.handleConfirmClick,
                    handleSubmit: this.handleConfirmClick
                }
            }
            case 'update': {
                return {
                    handleConfirm: this.handleConfirmClick,
                    handleSubmit: this.handleConfirmClick,
                    handleCloseModal: this.handleCloseModal,
                    handleUpdateDeliveryStatus: this.handleUpdateDeliveryStatus,
                    recipientList: this.state.recipientList,
                    currentPage: this.state.currentRecipientsPage,
                    totalPage: this.state.totalPage,
                    handlePageChange: this.handleRecipientsTblPageChange,
                    redirectUser: this.handleRedirectUser,
                }
            }
            case 'confirmUpdate': {
                return {
                    handleUpdate: this.handleUpdateSuccess,
                    handleShowEditModal: this.handleShowEditModal
                }
            }
            case 'confirmDelete': {
                return {
                    handleDbDelete: this.handleDbDelete
                }
            }
            default: return {};
        }
    }

    toggleErrorModal = (errorMessage='') => {
        this.setState(prevState => ({ isProcessing: false, errorMessage: errorMessage, isErrorModalShow: !prevState.isErrorModalShow }))
    }

    toggleRedirectModal = () => {
        this.setState(prevState => ({ isRedirectModalShow: !prevState.isRedirectModalShow }));
    }

    render() {
        /**Modal scrollbar fix */
        if (this.state.isModalShow) {
            document.body.style.overflowY = "hidden"
        } else {
            document.body.style.overflowY = "auto"
        }

        /**Check if display table */
        let isTableShow = 0 < this.props.randMsgList.length;

        return (
            <React.Fragment>
                {/**Hidden Link for User new tab redirect*/}
                <a href={appendUserRoute("/mypage")} rel="noopener noreferrer" target="_blank"  ref={this.userRedirect}> </a>
                
                {/**Process Loading Indicator */}
                <ProcessLoader
                    isShown={this.state.isProcessing}
                />

                <p className="admin-title">フミヤさんからのランダムメッセージ</p>
                <AdminCard>
                    <p className="admin-subtitle">
                        メッセージ
                        <span className="admin-subtitle-colored">{this.state.totalRecords}</span>
                        件
                    </p>
                    <Button className="randomMessage-button" type="submit" onClick={() => { this.handleCreateRandomMessage() }}>新規作成</Button>

                    {isTableShow && (
                        <React.Fragment>
                            {this.renderPagination()}
                            <TableTemplate
                                tableName="randomMessage"
                                title={null}
                                onClick={(row) => { this.handleTitleClick(row) }}
                                tableHeader={randomMessageTable.tableHeaders}
                                tableColumns={randomMessageTable.tableColumns}
                                tableList={this.props.randMsgList}
                                rowButtons={[
                                    { label: "削除", variant: "link", onClick: (row) => { this.handleDeleteRandomMessage(row) } },
                                ]}
                            />
                            {this.renderPagination()}
                        </React.Fragment>
                    )}
                </AdminCard>

                {/** Random Message Details*/}
                {!isStringNullEmptyOrUndefined(this.state.modalType) && (
                    <RandomMessageModal
                        key={this.state.modalType}
                        type={this.state.modalType}
                        currRandomMsg={this.state.currRandomMsg}
                        isModalShow={this.state.isModalShow}
                        handleCloseModal={this.handleCloseModal}
                        {...this.setModalProps()}
                    />
                )}

                {/** Error Response Message*/}
                <ErrorModal
                    isErrorModalShow={this.state.isErrorModalShow}
                    errorMessage={this.state.errorMessage}
                    handleCloseModal={this.toggleErrorModal}
                />

                {/** Redirect Confirmation Message*/}
                <ConfirmationModal
                    isModalShow={this.state.isRedirectModalShow}
                    confirmationText={"このユーザでユーザ画面にログインしますか？"}
                    handleConfirmAction={()=>{
                        window.open(appendUserRoute("/mypage"), "_blank");
                        this.toggleRedirectModal();
                    }}
                    handleCloseModal={this.toggleRedirectModal}
                />

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        randMsgList: state.randommsg.randomMessageList,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        createRandomMessage: randMsgOperations.createRandomMessage,
        getRandomMessage: randMsgOperations.getRandomMessage,
        getAllRandomMessages: randMsgOperations.getAllRandomMessages,
        getAllRecipients: randMsgOperations.getAllRecipients,
        updateRandomMessage: randMsgOperations.updateRandomMessage,
        deleteRandomMessage: randMsgOperations.deleteRandomMessage,
        getUserProfile: profileOperations.getUserProfile,
        setAdminBackdoorCheck: authOperations.setAdminBackdoorCheck
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(RandomMessageList);
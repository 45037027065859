import React, {useState} from 'react';
import { ListItem, Button } from 'react-native-elements';
import { TouchableOpacity, View } from 'react-native';
import CommentIcon from '../../../includes/img/icons/comments.svg';
import ReplyIcon from '../../../includes/img/icons/reply.svg';
import { NOTIF_TYPE, CLOUDFRONT_THUMBNAIL_PREFIX_URL, DATETIME_FORMAT } from '../../../utils/constants';
import { addDefaultSrc, appendUserRoute, getObjDateValue, getObjKeyIdString, boldHandlenameRequest } from "../../../utils/common";
import styles from './styles/styles.native.';
import moment from 'moment';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux'
import { useDispatch } from 'react-redux';
import { notificationOperations } from './state';
import HTMLParse from 'html-react-parser';

const UserNotification = React.memo((props) => {
    let { 
        _id,
        title,
        type,
        referenceId,
        status,
        updateDt,
        hash
    } = props;

    const [showButtons, setShowButtons] = useState(false);

    let isFriendRelated = (type === NOTIF_TYPE.friendRequest || type === NOTIF_TYPE.acceptedRequest || type === NOTIF_TYPE.removedRequest);
    let leftIcon = null;
    let onNotifPress = () => {};


    //Bind dispatch to action creators
    const dispatch = useDispatch();
    const operations = bindActionCreators({updateNotificationStatus: notificationOperations.updateNotificationStatus},dispatch);

    //Call API to update seen of notification
    let updateSeenNotif = () => {
        if(0 === status){
            operations.updateNotificationStatus(getObjKeyIdString(_id))
        }
    }

    if (isFriendRelated) {
        switch(type){
            case NOTIF_TYPE.friendRequest: 
                title = boldHandlenameRequest(title, "さんから友達リクエストが届きました。"); 
                break;
            case NOTIF_TYPE.acceptedRequest:   
                title = boldHandlenameRequest(title, "さんが友達リクエストを承認しました。")
                break;
            case NOTIF_TYPE.removedRequest:   
                title = boldHandlenameRequest(title, "さんからの友達リクエストを削除しました。")
                break;
            default:
                break;
        }

        let redirectUser = () => {
            updateSeenNotif();
            props.history.push(appendUserRoute(`/user/${referenceId}`));
        }

        leftIcon = <img onClick={redirectUser} className="avatar-small" alt=" " src={CLOUDFRONT_THUMBNAIL_PREFIX_URL + `Profile/${referenceId}/${referenceId}-small?${hash}`} onError={addDefaultSrc} />;

        if(type === NOTIF_TYPE.friendRequest){
            onNotifPress = () => {
                setShowButtons(!showButtons);
                updateSeenNotif();
            }
        }else{
            onNotifPress = redirectUser;
        }
    } else {
        let isReply = "あなたのコメントに返信があります。" === title;

        leftIcon = <img src={isReply ? ReplyIcon : CommentIcon} alt="notif-icon" style={{ width: 36, objectFit: 'contain' }} />;
        onNotifPress = () => {
            updateSeenNotif();
            props.history.push(appendUserRoute(`/post/view/${referenceId}`));
        }
    }

    return (
        <TouchableOpacity activeOpacity={0.7} onPress={onNotifPress} key={_id} style={status ? styles.notifSeen : styles.notifUnseen}>
            <View style={styles.notifItem}>
                <ListItem
                    containerStyle={{ paddingVertical: 14, backgroundColor: 'none' }}
                    leftElement={leftIcon}
                    title={<div style={styles.notifTitle}>{HTMLParse(title)}</div>}
                    subtitle={moment(getObjDateValue(updateDt)).format(DATETIME_FORMAT)}
                    titleStyle={styles.notifTitle}
                    subtitleStyle={styles.notifSubtitle}
                />
                {(type === NOTIF_TYPE.friendRequest && showButtons) && (
                    <div className="d-flex justify-content-center align-items-center p-2">
                        <Button
                            onClick={()=>props.acceptFriendRequest(_id, referenceId)}
                            buttonStyle={styles.confirmBtn}
                            title={"承認"}
                            titleStyle={styles.whiteLabel}
                        />
                        <Button
                            onClick={()=>props.removeFriendRequest(_id, referenceId)}
                            buttonStyle={styles.closeBtn}
                            title={"削除"}
                            titleStyle={styles.blackLabel}
                        />
                    </div>
                )}
            </View>
        </TouchableOpacity>
    );
});

export default withRouter(UserNotification);
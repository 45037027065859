import { StyleSheet } from "react-native";
import { Dimensions } from 'react-native';

const windowHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
  listContainerPC: {
    height: "72vh"
  },
  listContainerMobile: {
    height: windowHeight - 211,
  },
  listContainerMobileChat: {
    height: windowHeight - 213,
  },
  communityListContainerMobile: {
    height: windowHeight - 145,
  },
  communityListContainerPC: {
    height:  "75vh" 
  },
  header: {
    backgroundColor: "#F5F5F5",
    borderBottomColor: "#dcdcdc",
    borderBottomWidth: 1,
    height: 60
  },
  listItemContainer: {
    paddingTop: 16,
    paddingBottom: 10,
    paddingRight: 12,
    paddingLeft: 12
  },
  listItem: {
    padding: 0,
    paddingLeft: 2,
    margin: 0
  },
  communityTitle:{
    fontFamily: "kozuka-gothic-pro, sans-serif",
    marginLeft: 20,
    fontSize: 18,
  },
  communityTitleMobile:{
    fontFamily: "kozuka-gothic-pro, sans-serif",
    marginLeft: 20,
    fontSize: 14,
    lineHeight: "22px"
  },
  title: {
    marginLeft: 20,
    fontSize: 18,
    fontVariant: ["proportional-width"],
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "kozuka-gothic-pro, sans-serif"
  },
  titleMobile: {
    marginLeft: 20,
    fontSize: 14,
    fontVariant: ["proportional-width"],
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "kozuka-gothic-pro, sans-serif"
  },
  emptyList: {
    textAlign: "center",
    marginLeft: 20,
    fontFamily: "kozuka-gothic-pro, sans-serif"
  },
  emptyListMobile: {
    textAlign: "center",
    marginLeft: 20,
    fontSize: 14,
    fontFamily: "kozuka-gothic-pro, sans-serif"
  },
  horizontalBorder: {
    borderBottomColor: "#EAEDED",
    borderBottomWidth: 1,
    width: "100%",
    alignSelf: "center",
    marginTop: 15
  },
  avatar: {
    width: 70,
    height: 70
  }
});

export default styles;

import reducer from "./reducers";
import { routes } from "../routes";

import * as topOperations from './operations';

export {
    topOperations
}

export default {
    reducer,
    routes
};

import PastCommunities from "./PastCommunities";
import PastCommunityDetails from "./PastCommunityDetails";

export const routes = [
    {
        path: "/communities",
        component: PastCommunities,
        exact: true,
        showNav: true
    },
    {
        path: "/communities/:communityId",
        component: PastCommunityDetails,
        exact: true,
        showNav: true
    }
]
import * as types from "./types";

const defaultState = {
    //Module state
    maintenanceId: '',
    maintenanceType: 0,
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.SET_MAINTENANCE_SETTINGS:
            return {
                ...state,
                maintenanceId: action.payload.maintenanceId,
                maintenanceType: action.payload.maintenanceType
            };
        default: return state;
    }
};
import { MSG_INVALID_IMAGE_SIZE } from "../../../../utils/messages"
import { ACCEPTED_IMAGE_TYPES, IMAGE_MAX_SIZE } from "../../../../utils/constants"

export const validateImageUpdate = (file) => {
    let errors = {}

    if(!file && "image" !== file["type"].split('/')[0]){
        errors.image = MSG_INVALID_IMAGE_SIZE
    }

    if(!ACCEPTED_IMAGE_TYPES.includes(file['type'])){
        errors.image = MSG_INVALID_IMAGE_SIZE
    }

    if(IMAGE_MAX_SIZE < file.size){
        errors.image = MSG_INVALID_IMAGE_SIZE
    }

    return errors
}
import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  header: {
    backgroundColor: "#F5F5F5",
    borderBottomColor: "#F3f3f3",
    borderBottomWidth: 2
  },
  showSearchBtn: {
    width: "70%",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: 20,
    borderColor: "#D6DBDF",
    borderWidth: 1,
    height: 40
  },
  input: {
    padding: 2,
    paddingLeft: 15,
    width: "100%",
    borderRadius: 20,
  },
  inputMobile: {
    padding: 2,
    paddingLeft: 15,
    width: "100%",
    borderRadius: 20,
    borderColor: "#606770"
  },
  searchIcon: {
    height: 25,
    width: 25,
    marginRight: 15,
    marginLeft: 5,
    marginTop: -2
  },
  halfOpacity:{
    opacity: 0.5
  },
  searchContainer: {
    flex: 1,
    alignItems: "center",
    padding: 10,
    paddingBottom: 0,
    backgroundColor: "#ffffff"
  },
  horizontalBorder: {
    borderBottomColor: "#EAEDED",
    borderBottomWidth: 1,
    marginTop: 12,
    width: "100%",
    alignSelf: "center"
  },
  flatlistContainer : {
    flex: 1
  }
});

export default styles;

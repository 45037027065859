import React, { useState } from "react";
import { Text, Image } from "react-native";
import { Container, Dropdown } from "react-bootstrap";
import { Header, Icon, Button } from "react-native-elements";
import { withRouter } from "react-router-dom";
import styles from "./styles.native.js";
import { Link } from "react-router-dom";
import { appendUserRoute } from "../../../utils/common.js";
import HamburgerMenu from "../HamburgerMenu";
import HamburgerIcon from "../../../includes/img/user-menu/burger-white.svg";
import { useSelector } from "react-redux";
import { isFumiyaOrFFStaffUserCheck } from "../../../utils/common";
import {
  CHAT_NAVIGATION,
  NOTIFICATIONS_NAVIGATION
} from "../../../utils/constants.js";
import NotificationBadge from "../NotificationBadge.js";
import "./styles.scss";
import MobileOnlyView from "../MobileOnlyView.js";
import PCOnlyView from "../PCOnlyView.js";
import ChatNotificationBadge from "../ChatNotificationBadge.js";
import img from "../../../includes/img/icons/search.svg";
import { TextInput } from 'react-native';

const navigation = [
  {
    icon: require("../../../includes/img/user-menu/newsfeed.svg"),
    navigate: "/newsfeed"
  },
  {
    icon: require("../../../includes/img/user-menu/mypage.svg"),
    navigate: "/mypage"
  },
  {
    icon: require("../../../includes/img/user-menu/chat.svg"),
    navigate: "/chat"
  },
  {
    icon: require("../../../includes/img/user-menu/search.svg"),
    navigate: "/search"
  },
  {
    icon: require("../../../includes/img/user-menu/notification.svg"),
    navigate: "/notifications"
  },
  {
    icon: require("../../../includes/img/user-menu/burger.svg"),
    navigate: "/menu"
  }
];

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="_blank"
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <img
      alt="hamburger-icon"
      src={HamburgerIcon}
      style={{ width: 24, height: 24 }}
    />
  </a>
));

const PCHeader = React.memo((props) => {
  const [search, setSearch] = useState("");

  const onSearch = event => {
    event.preventDefault();

    if (0 < search.trim().length) {
      let encoded_string = encodeURIComponent(search)
      window.location.href = appendUserRoute(`/search/results?keyword=${encoded_string}`)
    }
  };

  let topRoute = appendUserRoute(`/newsfeed`);

  if(window == window.parent) { //task#363

  return (
    <div className="fixedPCHeader">
      <Container className="flex-between-center" style={{ height: 50 }}>
      <Link key={topRoute} href={topRoute} to={topRoute} className="link-router">
        <span className="title">{"comu comu"}</span>
      </Link>

        <form onSubmit={event => onSearch(event)} autoComplete={"off"} className="d-flex align-items-center">
         <Image
              alt="search-icon"
              source={img}
              style={styles.searchIconLeft}
          />
          <TextInput
              className="input-radius center-placeholder search-input"
              placeholder={"comu comuを検索"}
              value={search}
              type="search"
              style={{ maxHeight: 32, width: 250,fontSize: 14, paddingLeft: ("" === search) ? 12 : 32, boxSizing: "border-box"}}
              onChange={e => setSearch(e.target.value)}
              autoComplete={"new-password"}
          />
        </form>

        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" />
          <HamburgerMenu />
        </Dropdown>
      </Container>
    </div>
  )      }else{ //task#363 start
    return (
      <div className="fixedMobileHeader">
        <Header
          placement="center"
          centerComponent={<Text style={styles.mbTitleIFrame}>{"comu comu"}</Text>}
          containerStyle={styles.mbHeaderIFrame}
        />      
      </div>
    );
  } //task#363 end;
})
;

const MobileHeader = React.memo((props) => {
  const userId = useSelector(state => state.auth.credentials.userId);

  let showBackButton =
    navigation.filter(
      item => appendUserRoute(item.navigate) === props.location.pathname
    ).length <= 0;

  if(window == window.parent) { //task#363

  return (
    <div className="fixedMobileHeader">
      <Header
        placement="center"
        leftComponent={
          showBackButton && (
            <Button
              key={"back"}
              type="clear"
              icon={
                <Icon
                  name="chevron-left"
                  size={30}
                  color="white"
                  type={"material"}
                />
              }
              style={{marginLeft: -10}}
              onPress={() => props.history.goBack()}
            />
          )
        }
        centerComponent={<Text style={styles.title}>{"comu comu"}</Text>}
        containerStyle={styles.mobileHeader}
      />

      <div className="navigation">
        {navigation.map((item, index) => {
          let appendedRoute = appendUserRoute(item.navigate);
          if (
            isFumiyaOrFFStaffUserCheck(userId) &&
            (CHAT_NAVIGATION === item.navigate ||
              NOTIFICATIONS_NAVIGATION === item.navigate)
          ) {
            return (
              <Button
                key={index}
                type="clear"
                icon={
                  <Image
                    source={item.icon}
                    style={styles.icon}
                    resizeMode={"contain"}
                  />
                }
                style={styles.icon}
                disabled={true}
                disabledStyle={{opacity: 0.6}}
                onPress={() => {}}
              />
            );
          } else {
            return (
              <Link key={appendedRoute} href={appendedRoute} to={appendedRoute}>
                <Button
                  key={index}
                  type="clear"
                  icon={
                    <Image
                      source={item.icon}
                      style={"/newsfeed" === item.navigate ? styles.cameraIcon : styles.icon}
                      resizeMode={"contain"}
                    />
                  }
                  style={styles.icon}
                  onPress={() => {}}
                />
                {"/notifications" === item.navigate && (
                  <NotificationBadge mobile={true} />
                )}
                {"/chat" === item.navigate && (
                  <ChatNotificationBadge mobile={true} />
                )}
              </Link>
            );
          }
        })}
      </div>
    </div>
  );
      }else{ //task#363 start
        return (
          <div className="fixedMobileHeader">
            <Header
              placement="center"
              centerComponent={<Text style={styles.mbTitleIFrame}>{"comu comu"}</Text>}
              containerStyle={styles.mbHeaderIFrame}
            />      
          </div>
        );
      } //task#363 end
});

const AppHeader = React.memo((props) => {
  return (
    <React.Fragment>
      <MobileOnlyView>
        <MobileHeader {...props} />
      </MobileOnlyView>

      <PCOnlyView>
        <PCHeader {...props} />
      </PCOnlyView>
    </React.Fragment>
  );
});

export default withRouter(AppHeader);

import React, {useRef} from 'react';
import { Card, Image } from 'react-bootstrap';
import { appendUserRoute, addDefaultSrc } from '../../../utils/common';
import { TextInput } from 'react-native';
import { Link } from 'react-router-dom';
import ProfileIcon from '../../../includes/img/placeholders/user.svg';
import GalleryIcon from '../../../includes/img/icons/gallery.svg';
import PCOnlyView from '../../components/PCOnlyView';
import { Media } from 'react-breakpoints'
import { CLOUDFRONT_THUMBNAIL_PREFIX_URL, ACCEPTED_IMAGE_TYPES } from '../../../utils/constants';
import {TouchableOpacity} from 'react-native';
import styles from './styles/create.native';

const CreatePostArea = React.memo(({ user, profileHash, history }) => {
    let appendedRoute = appendUserRoute("/mypage");
    let createPostRoute = appendUserRoute("/post/create");
    let isProfileImageUrlValid = user.profileImageUrl && 0 <= user.profileImageUrl.indexOf("Profile/");
    let uploadImageRef= useRef();

    return (
        <Media>
            {({ breakpoints, currentBreakpoint }) => {
                let mobileView = (breakpoints[currentBreakpoint] < breakpoints.mobileLandscape);

                return (
                    <Card className={mobileView ? "feed-container-item mt-0 border-radius-none border-none" : "feed-container-item mt-0"}>
                        <PCOnlyView>
                            <span className="feed-card-header">投稿を作成する</span>
                        </PCOnlyView>

                        <Card.Body className="feed-card-container">
                            <Link key={appendedRoute + "main"} href={appendedRoute} to={appendedRoute} className="user-main-profile mr-3">
                                <Image onError={addDefaultSrc} src={isProfileImageUrlValid ? `${CLOUDFRONT_THUMBNAIL_PREFIX_URL + user.profileImageUrl}-small?${profileHash}` : ProfileIcon} style={{ width: 36, height: 36, objectFit: 'cover' }} roundedCircle alt="author-profile" />
                            </Link>

                            <Link key={createPostRoute + "input"} href={createPostRoute} to={createPostRoute} className="feed-card-create">
                                <TextInput
                                    name="createPost"
                                    placeholder={"日記を投稿しよう"}
                                    editable={false}
                                    style={styles.createInput}
                                />
                            </Link>

                            <TouchableOpacity activeOpacity={0.7} onPress={() => uploadImageRef.current.click()}>
                                <Image src={GalleryIcon} alt="camera-icon" className="top-create-camera" />
                            </TouchableOpacity>

                            
                            <input
                                multiple
                                accept={ACCEPTED_IMAGE_TYPES.join(",")}
                                ref={uploadImageRef}
                                type="file"
                                id="uploadImageRef"
                                onChange={(event)=>{
                                    history.push({
                                        pathname: appendUserRoute("/post/create"),
                                        state: {
                                            preloadImages: event.target.files
                                        }
                                        
                                    })}
                                }
                                style={{ display: "none" }}
                            />

                        </Card.Body>
                    </Card>
                )
            }}
        </Media>
    )

});


export default CreatePostArea;
import React from 'react';
import { isStringNullEmptyOrUndefined } from '../../../utils/common';
import CryptoJS from 'crypto-js';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { FFMWEB_LOGIN_URL, NEW_COMU_AUTH_CHECK } from '../../../config/settings';
import { profileOperations } from '../EditProfile/state';
import { authOperations } from './state';
import ProcessLoader from '../../components/ProcessLoader';
import { APP_NEWSFEED, INT_NOT_FOUND } from '../../../utils/constants';

const redirectFFMWeb = () => {
    window.location.replace(FFMWEB_LOGIN_URL);
}

const UserAuthentication = (props) => {
    const query = new URLSearchParams(props.location.search);
    let encryptEmail = query.get('email');
    let encryptPassword = query.get('password');

    var email  = CryptoJS.AES.decrypt(encryptEmail, NEW_COMU_AUTH_CHECK).toString(CryptoJS.enc.Utf8);
    var password  = CryptoJS.AES.decrypt(encryptPassword, NEW_COMU_AUTH_CHECK).toString(CryptoJS.enc.Utf8);

    const dispatch = useDispatch();
    let operations = bindActionCreators(
        {
            authenticateMember: authOperations.authenticateMember,
            getUserProfile: profileOperations.getUserProfile,
            checkMaintenance: authOperations.checkMaintenance
        },
        dispatch
    )

    //Create user session Cognito using redirect states
    if (!isStringNullEmptyOrUndefined(email) && !isStringNullEmptyOrUndefined(password)) {
        //Cognito 
        operations.authenticateMember(email, password)
        .then((result) => {
            if (INT_NOT_FOUND === result || result.name) {
                //Invalid credentials side effects
                redirectFFMWeb();
            } else {
                Promise.resolve(operations.checkMaintenance()).then(() => {
                    //Call API to get user details
                    operations.getUserProfile(result).then(() => {
                        props.history.replace(APP_NEWSFEED)
                    })
                })

            }
        });
    }

    return (
        <ProcessLoader isShown={true} />
    );
};

export default UserAuthentication;
import React from 'react';
import { FormLabel, FormGroup, Button, ButtonToolbar, FormCheck, Image, Col, Row } from 'react-bootstrap';
import { TextInput } from "react-native";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { formatJpDate, formatYYYMMDD } from "../../../utils/common";
import { Formik, Form, Field, FieldArray } from 'formik';
import { DATE_FORMAT, PASSWORD_PLACEHOLDER } from '../../../utils/constants';
import { S3_URL_BUCKET } from '../../../config/settings'
import { isNullOrUndefined, isStringNullEmptyOrUndefined } from '../../../utils/common';
import { MSG008_NO_USER_RESULTS_FOUND } from '../../../utils/messages/admin/errorMessages';
import * as Messages from '../../../utils/messages/admin/messages';
import 'react-datepicker/dist/react-datepicker.css';
import './styles.scss';
import '../../containers/PasswordChange/styles/passwordChange.scss';
import '../../containers/Chat/styles/radio.scss';
import DropDownIcon from '../../../includes/img/icons/dropdown.svg';

let FormTemplate = (props) => {
    //Initial values
    let initialValues = {};
    let emptyInitialValues = isNullOrUndefined(props.initialValues);

    let modelValues = {};
    props.formInputs && props.formInputs.form.map((input) => {

        let propertyExist = !emptyInitialValues && props.initialValues[props.formInputs.model].hasOwnProperty(input.name);

        if("" !== input.name){
            if ("multiple-text" === input.type) {
                input.children.map(child => {
                    let childPropertyExist = !emptyInitialValues && props.initialValues[props.formInputs.model].hasOwnProperty(child.name);
                    if ("datepicker" === input.type) {
                        (modelValues[child.name] = childPropertyExist ? moment(props.initialValues[props.formInputs.model][child.name]).format(DATE_FORMAT) : child.default)
                    }
                    else {
                        let value = childPropertyExist ? props.initialValues[props.formInputs.model][child.name] : child.default;
                        modelValues[child.name] = value;
                    }
                    return null;
                })
            }
            else if ("datepicker" === input.type) {
                (modelValues[input.name] = propertyExist ? moment(props.initialValues[props.formInputs.model][input.name]).format(DATE_FORMAT) : input.default)
            }
            else {
                let value = propertyExist ? props.initialValues[props.formInputs.model][input.name] : input.default;
                modelValues[input.name] = value;
            }
        }
        return null;
    })

    initialValues[props.formInputs.model] = modelValues;
    
    let defaultClass = "form-input";

    function RenderInput(model, input, fieldValue, setFieldValue, errors, touched, values) {
        let modelField = `${model}.${input.name}`;
        let modelTouch = touched[model];
        let isTouched = undefined !== touched[model] && modelTouch[input.name];
        let isError = undefined !== errors[modelField];
        let displayError = isTouched && isError;
        let customDisabled = false;
        switch (input.type) {
            //renders file upload
            case "file": {
                let fileInfo = "";

                if(isStringNullEmptyOrUndefined(modelValues.messageImage)){
                    fileInfo = <span className="ml-3" style={{textOverflow: "ellipsis", overflow: "hidden"}}>{fieldValue.name}</span>;
                }else if(!isStringNullEmptyOrUndefined(modelValues["messageImage"]) ||  modelValues["messageImage"] instanceof File){
                    fileInfo = <span className="ml-3 pre-wrap-label">{"画像あり\n確認ボタンをクリックすると次画面でプレビュー可能"}</span>
                }

                return (
                    <FormGroup as={Row} key={modelField} className={defaultClass}>
                        <FormLabel column sm="3" className="form-template-label align-self-start">{input.label}</FormLabel>
                        <Col className="align-items-center text-control" style={{flexDirection: 'row', display: "flex", alignItems: 'center'}}>
                            <span className="align-items-center position-relative" style={{maxWidth: 100, }}>
                                <input className="form-template-file-upload" name={modelField} type="file" onChange={(event) => {
                                    setFieldValue(modelField, event.target.files[0]);
                                }} />
                                <img src={DropDownIcon} alt="dropdown" className="form-template-file-dropdown"/>
                            </span>
                            {fileInfo}
                        </Col>
                    </FormGroup>
                );
            }

            //renders radio button group field
            case "radio": {
                return (
                    <FormGroup as={Row} key={modelField} className={defaultClass}>
                        <FormLabel column sm="3" className="form-template-label">{input.label}</FormLabel>
                        {CustomError(errors[modelField], displayError)}

                        <Col sm="9">
                            {props.radioInput.map((radio, index) => {
                                if (radio.name === input.name) {
                                    return (
                                        <label className="label-container">
                                            <input
                                                className="radio-btn-form"
                                                id={input.name + index}
                                                type="radio"
                                                value={radio.value}
                                                key={index}
                                                name={modelField}
                                                inline={input.inline}
                                                onChange={event => setFieldValue(modelField, event.target.value)}
                                                checked={!isNullOrUndefined(fieldValue)  && (fieldValue.toString() === radio.value.toString())}
                                            />
                                            <span className="checkmark"></span>
                                            <span className="radio-label">{radio.label}</span>
                                        </label>
                                    );
                                }

                                return null;
                            })}
                        </Col>
                    </FormGroup>
                )
            }


            //renders single checkbox field
            case "checkbox": {
                return (
                    <FormGroup as={Row} key={modelField} className={defaultClass}>
                        <FormLabel column sm="3" className="form-template-label">{input.label}</FormLabel>
                        {CustomError(errors[modelField], displayError)}
                        <Col sm="9">
                            <FormCheck
                                id={input.name + input.value}
                                type="checkbox"
                                style={{ cursor: "pointer" }}
                                value={input.value}
                                name={modelField}
                                onChange={() => setFieldValue(modelField, !fieldValue)}
                                checked={fieldValue}
                                label={input.label}
                            />
                        </Col>
                    </FormGroup>
                )
            }

            //renders multiple checkbox field
            case "multi-checkbox": {
                return (
                    <FormGroup as={Row} key={modelField} className={defaultClass}>
                        <FormLabel column sm="3" className="form-template-label">{input.label}</FormLabel>
                        {CustomError(errors[modelField], displayError)}

                        <Col sm="9">
                            <FieldArray
                                name={modelField}
                                render={arrayHelpers => (
                                    <div>
                                        {props.checkboxInput.map((checkbox, index) => {
                                            if (input.name === checkbox.name) {
                                                return (
                                                    <FormCheck
                                                        id={input.name + index}
                                                        type="checkbox"
                                                        style={{ cursor: "pointer" }}
                                                        key={index}
                                                        name={modelField}
                                                        value={checkbox.value}
                                                        inline={input.inline}
                                                        checked={0 <= fieldValue.indexOf(checkbox.value)}
                                                        onChange={event => {
                                                            if (event.target.checked) {
                                                                arrayHelpers.push(checkbox.value)
                                                            } else {
                                                                let checkIndex = fieldValue.indexOf(checkbox.value);
                                                                arrayHelpers.remove(checkIndex);
                                                            }
                                                        }}
                                                        label={checkbox.label}
                                                    />
                                                )
                                            }

                                            return null;
                                        })}
                                    </div>
                                )}
                            />
                        </Col>
                    </FormGroup>
                )
            }

            //renders datepicker field
            case "datepicker": {
                let dateValue = moment(fieldValue, input.format).isValid() ? moment(fieldValue, input.format) : null;

                return (
                    <FormGroup as={Row} key={modelField} className={defaultClass}>
                        <FormLabel column sm="3" className="form-template-label">{input.label}</FormLabel>
                        {CustomError(errors[modelField], displayError)}

                        <Col sm="9">
                            <DatePicker
                                className={displayError ? "form-error form-control" : "form-control"}
                                name={modelField}
                                selected={dateValue.toDate()}
                                onChangeRaw={(event) => event.preventDefault()}
                                onChange={newValue => setFieldValue(modelField, moment(newValue).format(DATE_FORMAT))}
                                placeholderText={input.placeholder}
                            />
                        </Col>
                    </FormGroup>
                );
            }

            // renders select field
            case "select": {
                return (
                    <FormGroup as={Row} key={modelField} className={defaultClass}>
                        <FormLabel column sm="3" className="form-template-label">{input.label}</FormLabel>
                        {CustomError(errors[modelField], displayError)}

                        <Col sm="9">
                            <Field
                                className={displayError ? "form-control form-error" : "form-control"}
                                component="select"
                                name={modelField}
                            >
                                <option disabled value="">{input.placeholder}</option>
                                {props.selectInput && props.selectInput.filter((option) => option.name === input.name).map((item, i) => {
                                    return (
                                        <option key={i} value={item.value}> {item.label} </option>
                                    );
                                })}
                            </Field>
                        </Col>
                    </FormGroup>
                );
            }

            case "multiple-text": {
                return (
                    <FormGroup as={Row} key={modelField} className={defaultClass}>
                        <FormLabel column sm="3" className="form-template-label">{input.label}</FormLabel>
                        {CustomError(errors[modelField], displayError)}
                        <Col sm="9" className="multiple-text-column">
                            {input.children.map(child => {
                                return (RenderInput(model, child, values[model][child.name], setFieldValue, errors, touched, values))
                            })}
                        </Col>
                    </FormGroup>
                )
            }

            case "custom-text-date": {
                let disabled = "id" === input.name && props.isEditMode;

                if(0 === parseInt(values[model]["isDisplayed"]) && input.notifDisplayDependent){
                    disabled = true;
                    if(!isStringNullEmptyOrUndefined(fieldValue)){
                        setFieldValue(modelField, "");
                    } 
                }

                return (
                    <div key={modelField}>
                        <Col className="form-custom-text-date" style={{ width: input.width }}>
                            <Field
                                disabled={
                                    (("RandomMessageModel.deliveryDay"=== modelField || "RandomMessageModel.deliveryHour"=== modelField
                                    || "RandomMessageModel.deliveryMinute"===modelField) && 0 === parseInt(values.RandomMessageModel.type)) || disabled
                                }
                                className={displayError ? "form-control form-error" : "form-control"}
                                placeholder={input.placeholder}
                                component={input.component}
                                type={"number"}
                                name={modelField}
                                min={input.min}
                                max={input.max}
                                onChange={event => {
                                    if("" === event.target.value || (event.target.value >= input.min && event.target.value <= input.max)){
                                        setFieldValue(modelField, event.target.value);
                                    }
                                }}
                            />
                            <span className="form-custom-text-date-label">{input.label}</span>
                        </Col>
                    </div>
                )
            }

            case "textarea": {
                return(
                    <FormGroup as={Row} key={modelField} className={defaultClass}>
                        <FormLabel 
                            column sm="3" 
                            className="form-template-label" 
                            style={{alignSelf: input.startLabel ? "flex-start" : "center"}}
                        >
                            {input.label}
                        </FormLabel>
                        {CustomError(errors[modelField], displayError)}
                        <Col sm="9" style={{ display: 'flex', alignItems: 'center' }}>
                            <TextInput className={'form-control'} multiline={true} numberOfLines={parseInt(input.rows)} onChange={event => setFieldValue(modelField, event.target.value.trim())} defaultValue={input.default} maxLength={input.maxLength}></TextInput>
                        </Col>
                    </FormGroup>
                )
            }

            case "top-error-message": {
                return (
                    <div key={modelField} style={{textAlign: input.position ? input.position : "center", paddingLeft: input.paddingLeft ? input.paddingLeft.toString() + "px" : "0px"}} className={"form-template-top-error-message"}>{CustomError(errors[modelField], displayError)}</div>
                )
            }

            case "values": {
                
                let displayText = '';

                if(props.hasOwnProperty("currRandomMsg")) {
                    switch(input.label) {
                        case '種類': {
                            displayText = ('1' === props.currRandomMsg.type ? "全員" : '0' === props.currRandomMsg.type ? "ランダム" : props.currRandomMsg.type);
                            break;
                        }
                        case '送信日時': {
                            displayText = formatJpDate(props.currRandomMsg);
                            break;
                        }
                        case '本文': {
                            displayText = props.currRandomMsg.messageBody;
                            break;
                        }
                        case '写真': {
                            if(!isNullOrUndefined(props.currRandomMsg.messageImage)) {
                                let image = null;
    
                                try{
                                    if('string' === typeof(props.currRandomMsg.messageImage) && '' !== props.currRandomMsg.messageImage) {
                                        let imageSrc = props.currRandomMsg.messageImage;
                                        if(!imageSrc.includes(S3_URL_BUCKET)) {
                                            /** Updated to display medium, thumbnail*/
                                            imageSrc = `${S3_URL_BUCKET}/Thumbnail-${props.currRandomMsg.messageImage}-medium`;
                                        }
                                        
                                        image = <Image src={imageSrc} className={'form-template-thumbnail-image'} key={input.name} thumbnail alt={'message-image'} />
                                    } 
                                    else {
                                        image = <Image src={URL.createObjectURL(props.currRandomMsg.messageImage)} className={'form-template-thumbnail-image'} key={input.name} thumbnail alt={'message-image'} />
                                    }
                                    
                                    return(
                                        <FormGroup key={modelField} as={Row} className={defaultClass + props.updatePreview ? " " : " form-preview"}>
                                            <FormLabel 
                                                column sm="3" 
                                                className={props.updatePreview ? "form-template-label" : "form-template-label-left"}
                                                style={{alignSelf: input.startLabel ? "flex-start" : "center"}}
                                            >
                                                {input.label}
                                            </FormLabel>
                                            <Col style={{ display: 'flex', alignItems: 'center' }}>
                                                {image}
                                            </Col>
                                        </FormGroup>
                                    )
                                } 
                                catch {
                                    displayText = props.currRandomMsg.messageImage.name;
                                }
                            }
                            break;
                        }
                        case '配信': {
                            if(props.radioInput && props.radioInput.length) {
                                const deliveryStatusValue = ('配信する' === props.currRandomMsg.deliveryStatus) ? '1' : '0'
                                displayText = 
                                    <div key={input.name}>
                                        {props.radioInput.map((radio, index) => {
                                            if (radio.name === input.name) {
                                                return (
                                                    <FormCheck
                                                        id={input.name + index}
                                                        type="radio"
                                                        inline={input.inline}
                                                        key={index}
                                                        value={radio.value}
                                                        name={modelField}
                                                        onChange={event => {
                                                            setFieldValue(modelField, event.target.value);
                                                            props.handleUpdateDeliveryStatus(event.target.value);
                                                        }}
                                                        defaultChecked={deliveryStatusValue === radio.value}
                                                        label={radio.label}
                                                    />
                                                );
                                            }
                                            return null;
                                        })}
                                    </div>
                            } else {
                                displayText = ('1' === props.currRandomMsg.deliveryStatus) ? "配信する" : ('0' === props.currRandomMsg.deliveryStatus) ? "配信しない" : props.currRandomMsg.deliveryStatus;
                            }
                            
                            break;
                        }
                        default:
                            break;
                    }
                } else if(props.hasOwnProperty("currNotif")) {
                    const currNotif = props.currNotif;

                    switch(input.name) {
                        case 'createdDate': {
                            displayText = formatYYYMMDD(currNotif.createdYear, currNotif.createdMonth, currNotif.createdDay);
                            break;
                        }
                        case 'title': {
                            displayText = currNotif.title;
                            break;
                        }
                        case 'body': {
                            displayText = currNotif.body;
                            break;
                        }
                        case 'isDisplayed': {
                            if(props.radioInput && props.radioInput.length) {
                                const isDisplayed = ('表示' === currNotif.isDisplayed) ? '1' : '0';
                                displayText = 
                                    <div key={input.name}>
                                        {props.radioInput.map((radio, index) => {
                                            if (radio.name === input.name) {
                                                return (
                                                    <FormCheck
                                                        id={input.name + index}
                                                        type="radio"
                                                        inline={input.inline}
                                                        key={index}
                                                        value={radio.value}
                                                        name={modelField}
                                                        onChange={event => {
                                                            setFieldValue(modelField, event.target.value);
                                                            props.handleUpdateIsDisplayed(event.target.value);
                                                        }}
                                                        defaultChecked={isDisplayed === radio.value}
                                                        label={radio.label}
                                                    />
                                                );
                                            }
                                            return null;
                                        })}
                                    </div>
                            } else {
                                displayText = ('1' === currNotif.isDisplayed) ? "表示" : ('0' === currNotif.isDisplayed) ? "非表示" : currNotif.isDisplayed;
                            }
                            break;
                        }
                        case 'startDate': {
                            if(isStringNullEmptyOrUndefined(currNotif.startYear) 
                            && isStringNullEmptyOrUndefined(currNotif.startMonth)
                            && isStringNullEmptyOrUndefined(currNotif.startDay)) {
                                displayText = "-"
                            } else {
                                displayText = formatYYYMMDD(currNotif.startYear, currNotif.startMonth, currNotif.startDay);
                            }
                            break;
                        }
                        case 'endDate': {
                            if(isStringNullEmptyOrUndefined(currNotif.endYear) 
                            && isStringNullEmptyOrUndefined(currNotif.endMonth)
                            && isStringNullEmptyOrUndefined(currNotif.endDay)) {
                                displayText = "-"
                            } else {
                                displayText = formatYYYMMDD(currNotif.endYear, currNotif.endMonth, currNotif.endDay);
                            }
                            break;
                        }
                        default: break;
                    }
                } else if(props.hasOwnProperty("currPassword")) {
                    const currPassword = props.currPassword;

                    switch(input.name) {
                        case 'newPassword': {
                            displayText = PASSWORD_PLACEHOLDER.repeat(currPassword.newPassword.length);
                            break;
                        }
                        default: break;
                    }
                }

                return (
                    <div key={modelField}>
                        {'confirmDeleteMsg' === input.name ? (
                            <div className={'form-template-delete-prompt'}>{Messages.RANDOM_MESSAGE_DELETE_CONFIRM}</div>
                        ) : 
                        'successUpdateMsg' === input.name ? (
                            <div className={'form-template-delete-prompt'}>{Messages.RANDOM_MESSAGE_UPDATE_SUCCESS}</div>
                        ) : 
                        'successDeleteMsg' === input.name ? (
                            <div className={'form-template-delete-prompt'}>{Messages.RANDOM_MESSAGE_DELETE_SUCCESS}</div>
                        ) :
                        'adminNoticeConfirmCreateUpdateMsg' === input.name ? (
                            <div className={'form-template-delete-prompt'}>{Messages.ADMIN_NOTICE_CREATE_UPDATE_SUCCESS}</div>
                        ) : 
                        'adminNoticeConfirmDeleteMsg' === input.name ? (
                            <div className={'form-template-delete-prompt'}>{Messages.ADMIN_NOTICE_DELETE_CONFIRM}</div>
                        ) : 
                        'adminNoticeSuccessDeleteMsg' === input.name ? (
                            <div className={'form-template-delete-prompt'}>{Messages.ADMIN_NOTICE_DELETE_SUCCESS}</div>
                        ) : 
                        'adminSuccessPasswordChange' === input.name ? (
                            <div className={'form-template-delete-prompt'}>{Messages.PASSWORD_CHANGE_SUCCESS}</div>
                        ) : 
                        'noMatchingUsers' === input.name ? (
                            <div className={'form-template-error'}>{MSG008_NO_USER_RESULTS_FOUND}</div>
                        ) : 
                        displayText ? 
                        (
                            <FormGroup as={Row} className={defaultClass + props.updatePreview ? " " : " form-preview"}>
                                <FormLabel 
                                    column sm={input.divideCenter ? "6" : "3"} 
                                    className={
                                        (props.updatePreview ? "form-template-label" : "form-template-label-left")
                                        + (input.divideCenter ? " text-right pr-0" : "")
                                    }
                                    style={{alignSelf: input.startLabel ? "flex-start" : "center"}}
                                >
                                    {input.label}
                                </FormLabel>
                                <Col sm={input.divideCenter ? "6" : "9"} className={"form-column-display-text" + input.divideCenter ? " text-left pl-5" : ""}>
                                    <p className="form-column-display-text-p">
                                    {displayText}
                                    </p>
                                </Col>
                            </FormGroup>
                        ) : ''}
                    </div>
                );
            }

            case "table": {
                try {
                    if(props.currRandomMsg.hasSent) {
                        return (
                            <div key={modelField}>
                                <FormLabel column sm="3" className="form-template-label">{input.label}</FormLabel>
                                <div className="form-template-table">
                                    {props.table}
                                </div>
                            </div>
                        )
                    }
                } catch {
                    return;
                }
                break;
            }

            case "pagination": {
                try {
                    if(0 < props.currRandomMsg.deliveryHistory.length) {
                        return (
                            <div key={modelField} className="form-template-pagination">{props.pagination}</div>
                        )
                    }   
                } catch {
                    return;
                }
                break;
            }

            //renders text, textarea, email, password, numbers, default input
            case "text-user-search":{
                let withUserIdInput = !isStringNullEmptyOrUndefined(values[model]["userId"]);
                let withHandleNameInput = !isStringNullEmptyOrUndefined(values[model]["handleName"]);
                let withEmailInput = !isStringNullEmptyOrUndefined(values[model]["email"]);
                let hasSearchInput =  withUserIdInput || withHandleNameInput || withEmailInput;
                
                if(hasSearchInput){
                    if("userId" !== input.name && withUserIdInput){
                        customDisabled = true;
                    }else if ("handleName" !== input.name && withHandleNameInput){
                        customDisabled = true;
                    }else if("email" !== input.name && withEmailInput) {
                        customDisabled = true
                    }
                }
                //No Breaks
            }

            default: {
                let isDisabled = customDisabled || ("id" === input.name && props.isEditMode);
                return (
                    <FormGroup as={Row} key={modelField} className={defaultClass}>
                        <FormLabel column sm="3" className="form-template-label">{input.label}</FormLabel>
                        {CustomError(errors[modelField], displayError)}
                        <Col sm="9" className="form-template-default">
                            <Field
                                disabled={isDisabled}
                                className={displayError ? "form-control form-error" : "form-control"}
                                placeholder={input.placeholder}
                                component={input.component}
                                autoComplete={"off"}
                                type={input.type}
                                name={modelField}
                                maxLength={input.maxLength}
                                minLength={input.minLength}
                                min={input.min}
                                max={input.max}
                                onChange={event => {
                                    if(isDisabled){
                                        //Skip
                                    }else{
                                        let newValue = event.target.value;
                                        if(input.regex){
                                            if(!((input.regex).test(newValue)) || "" === newValue){
                                                setFieldValue(modelField, newValue)
                                            }
                                        }else {
                                            setFieldValue(modelField, newValue)
                                        }
                                    }
                                }}
                            />
                        </Col>
                    </FormGroup>
                );
            }
        }
    }

    //Error messages
    const CustomError = (error, displayError) => {
        return displayError ? <p className="formik-error">{error}</p> : null;
    }

    //Renders form buttons
    const FormButtons = (resetForm) => props.formButtons && props.formButtons.map((button, i) => {
        return (
            <Button type={button.submit ? "submit" : "button"} key={i} className={"form-template-button-" + button.type + ` ${button.className}`} size={button.size} onClick={()=>button.onClick ? button.onClick(resetForm) : {}}>
                {button.label}
            </Button>
        );
    });

    //Maps form inputs
    const FormInputs = (values, setFieldValue, errors, touched) => props.formInputs.form && props.formInputs.form.map((input) => {
        let fieldValue = "";
        if(values[props.formInputs.model].hasOwnProperty(input.name)){
            fieldValue = values[props.formInputs.model][input.name]
        }
 
        if("RandomMessageModel" === props.formInputs.model && 0 === parseInt(values.RandomMessageModel.type)){
            values.RandomMessageModel.deliveryDay = ""
            values.RandomMessageModel.deliveryHour= ""
            values.RandomMessageModel.deliveryMinute = ""
        }
        return RenderInput(props.formInputs.model, input, fieldValue, setFieldValue, errors, touched, values)
    });

    const FormTitle = ({ title }) => {
        return (
            <p className="form-template-title">{title}</p>
        )
    }

    if ((!isNullOrUndefined(props.initialValues) && props.editMode) || !props.editMode) {
        const { bindSubmitFormik } = props;
        return (
            <React.Fragment>
                {props.title && (
                    <FormTitle title={props.title} />
                )}
                <Formik
                    ref={node => (!isNullOrUndefined(props.updateFormRef) && props.updateFormRef(node))}
                    initialValues={initialValues}
                    className={props.className}
                    validate={values => {
                        return props.validate(values);
                    }}
                    onSubmit={(values) => {
                        props.handleSubmit(values)
                    }}
                >
                    {({ values, setFieldValue, errors, touched, submitForm, resetForm }) => (
                        <Form style={{color: "#3B4043"}} className={props.mainFormClass ? props.mainFormClass : ""}>
                            {!isNullOrUndefined(bindSubmitFormik) && bindSubmitFormik(submitForm, resetForm)}
                            <div className={(props.preview ? "pr-5 pl-5 " : "pr-5 ") + (props.customClass ? props.customClass : "")}>
                                {FormInputs(values, setFieldValue, errors, touched)}
                            </div>

                            <ButtonToolbar className="form-template-button-group">
                                {FormButtons(resetForm)}
                            </ButtonToolbar>
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        );
    } else {
        return <p>Loading . . .</p>;
    }
}

export default FormTemplate;
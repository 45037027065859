import React from 'react';
import { Image } from 'react-bootstrap';
import { CLOUDFRONT_THUMBNAIL_PREFIX_URL, THUMBNAIL_MEDIUM } from '../../../utils/constants';
import { appendUserRoute, addDefaultSrc } from '../../../utils/common';
import { useSelector } from 'react-redux';
import { TouchableOpacity } from 'react-native';
import { withRouter } from "react-router-dom";
import styles from './styles/styles.native';
import './styles/header.scss';

const SearchUserListTemplate = (props) => {
    //Redux store data
    const loggedUserId = useSelector(state => state.auth.credentials.userId);

    let redirectUserRoute = props.userId ? appendUserRoute(`/user/${props.userId}`) : "#";

    if (loggedUserId === props.userId) {
        redirectUserRoute = appendUserRoute("/mypage");
    }

    let profileImageUrl = `Profile/${props.userId}/${props.userId}`;

    return (
        <div 
            key={props.userId} 
            className={`userResult search-item-container ${!props.customStyle && "pt-3 pb-3"}`} 
            style={{...props.customStyle}}
            onClick={() => props.history.push(redirectUserRoute)}
        >
            <div className="search-user-profile-icon">
                <Image
                    onError={addDefaultSrc}
                    src={`${CLOUDFRONT_THUMBNAIL_PREFIX_URL + profileImageUrl + THUMBNAIL_MEDIUM}?` + props.hash}
                    className="search-user-profile-icon"
                    roundedCircle
                />
            </div>
                
            <div className="pl-3 user-handleName">{props.handleName}</div>
        </div>
    );
};

export default withRouter(SearchUserListTemplate);
import React, {useState} from 'react';
import { TouchableOpacity } from 'react-native';
import { ListItem } from 'react-native-elements';
import { Dropdown } from 'react-bootstrap';
import { appendUserRoute } from '../../../utils/common';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {isFumiyaOrFFStaffUserCheck, addDefaultSrc} from "../../../utils/common"
import { CHAT_NAVIGATION, NOTIFICATIONS_NAVIGATION } from "../../../utils/constants"
import NotificationBadge from '../NotificationBadge';
import ChatNotificationBadge from '../ChatNotificationBadge';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { authOperations } from '../../containers/Authentication/state';
import ConfirmationModal from '../../components/ConfirmationModal';
import ProcessLoader from '../../components/ProcessLoader';
import { FFMWEB_PRIVACY_POLICY_URL} from '../../../config/settings';

const MenuItem = React.memo(withRouter(({
    item, 
    action, 
    onPress, 
    containerStyle, 
    titleStyle,
    bottomDivider,
    history,
    mobileView
}) => {
    const userId = useSelector(state => state.auth.credentials.userId);
    const [showLogoutConfirm, setShowLogoutConfirm] = useState(false)
    const [showProcessLoader, setShowProcessLoader] = useState(false)

    const dispatch = useDispatch();
    const operations = bindActionCreators({logoutUser: authOperations.logoutUser},dispatch);
    const emptyAction = () => {};
    
    let onPressAction = onPress;
    let appendedRoute = action ? "" : appendUserRoute(item.navigate);

    if("/logout" === item.navigate){
        action = true;
        onPressAction = () => {
            setShowLogoutConfirm(true)
        };
    }

    if ("/privacy-policy" === item.navigate){
        action = true;
        onPressAction = () => {
            window.open(FFMWEB_PRIVACY_POLICY_URL, "_blank");
        };
    }

    if ((!mobileView && item.mobileOnly) || (item.special && !isFumiyaOrFFStaffUserCheck(userId))) {
        return "";
    }

    if(((NOTIFICATIONS_NAVIGATION === item.navigate || CHAT_NAVIGATION === item.navigate) && isFumiyaOrFFStaffUserCheck(userId)) || history.location.pathname === appendedRoute || item.disabled) {
        action = true;
        onPressAction = emptyAction
    }

    const initiateLogout = () =>{
        setShowProcessLoader(true);
        operations.logoutUser(false)
    }

    return (
        <React.Fragment>
            <Dropdown.Item className="custom-menu-item">
                <div onClick={action ? onPressAction : () => history.push(appendedRoute)}>
                    <ListItem
                        containerStyle={containerStyle || { paddingVertical: 10, backgroundColor: 'none' }}
                        leftElement={
                            <React.Fragment>
                                <img onError={addDefaultSrc} src={item.icon} alt="menu" style={item.userIcon ? { borderRadius: "50%", objectFit: 'cover', width: 32, height: 32 } : { marginRight: item.small ? 4 : 0 ,width: item.small ? 24 : 28, objectFit: 'contain' }} />
                            </React.Fragment>
                        }
                        style={item.disabled ? {opacity: 0.6} : {}}
                        activeOpacity={0.7}
                        delayPressIn={50000}
                        underlayColor={'none'}
                        title={
                            <div>
                                <div style={titleStyle || {fontWeight: '500', marginLeft: 16, fontSize: 16}}>{item.label}</div>
                                {("/chat" === item.navigate && !isFumiyaOrFFStaffUserCheck(userId)) && <ChatNotificationBadge/>}
                                {("/notifications" === item.navigate && !isFumiyaOrFFStaffUserCheck(userId)) && <NotificationBadge/>}
                            </div>
                        }
                        bottomDivider={bottomDivider}
                    />
                </div>
            </Dropdown.Item>

            {showLogoutConfirm &&(
                <ConfirmationModal
                    isModalShow={true}
                    confirmationText={"ログアウトしますか？"}
                    confirmTitle={"はい"}
                    handleConfirmAction={()=>initiateLogout()}
                    handleCloseModal={()=>setShowLogoutConfirm(false)}
                />
            )}

            {showProcessLoader && (<ProcessLoader isShown={true}/>)}

        </React.Fragment>
    );
}));

export { MenuItem };
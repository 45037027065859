import React from "react";
import { Card, Image } from 'react-bootstrap';
import MessagesIcon from '../../../includes/img/user-menu/chat.svg';
import FriendIcon from "../../../includes/img/user-menu/friends.svg"
import BlockIcon from "../../../includes/img/icons/block-white.svg"
import RemoveFriend from "../../../includes/img/icons/unfriend.svg"
import MenuBox from "../../../includes/img/icons/menu-box.svg"
import PastIcon from '../../../includes/img/icons/past-white.svg';
import DefaultIcon from '../../../includes/img/placeholders/user.svg';
import CoverIcon from '../../../includes/img/placeholders/cover.svg';
import { addDefaultSrc, addDefaultCoverSrc, customHTMLParse } from "../../../utils/common"
import { TouchableOpacity } from 'react-native';
import { appendUserRoute, isFumiyaOrFFStaffUserCheck } from '../../../utils/common';
import { Link } from 'react-router-dom';
import { CLOUDFRONT_THUMBNAIL_PREFIX_URL, THUMBNAIL_MEDIUM, THUMBNAIL_SMALL, THUMBNAIL_LARGE } from '../../../utils/constants';
import PCOnlyView from '../../components/PCOnlyView';
import MobileOnlyView from '../../components/MobileOnlyView';
import { isNullOrUndefined } from 'util';
import styles from './styles/mypage.styles.native';
import LinkTransform from "../../components/LinkTransform";

const FriendPageHeaderComponent = (props) => {
    let emptyInitialValues = isNullOrUndefined(props.userModel.user_model);
    const profileImageUrl = `Profile/${props.friendId}/${props.friendId}`
    const coverImageUrl = `Cover/${props.friendId}/${props.friendId}`
    const handleName = !emptyInitialValues ? props.userModel.user_model.handleName : ""
    const bio = !emptyInitialValues ? props.userModel.user_model.bio : ""
    return (
        <>
            <Card.Body className="mypage-cover-photo-container">
                <PCOnlyView>
                    <Image src={props.defaultProfile ? CoverIcon : CLOUDFRONT_THUMBNAIL_PREFIX_URL + coverImageUrl + THUMBNAIL_LARGE + "?" + props.coverHash} onError={addDefaultCoverSrc} alt="cover" className="profile-cover-image" />
                </PCOnlyView>
                <MobileOnlyView>
                    <Image src={props.defaultProfile ? CoverIcon : CLOUDFRONT_THUMBNAIL_PREFIX_URL + coverImageUrl + THUMBNAIL_LARGE + "?" + props.coverHash} onError={addDefaultCoverSrc} alt="cover" className="profile-cover-image" />
                </MobileOnlyView>
            </Card.Body>
            <Card.Body className="mypage-profile-container-mobile">
                <div className="mypage-profile-photo-container">
                    <PCOnlyView>
                        <Image src={props.defaultProfile ? DefaultIcon : CLOUDFRONT_THUMBNAIL_PREFIX_URL + profileImageUrl + THUMBNAIL_MEDIUM + "?" + props.profileHash} onError={addDefaultSrc} alt="profile" className="profile-image" />
                    </PCOnlyView>
                    <MobileOnlyView>
                        <Image src={props.defaultProfile ? DefaultIcon : CLOUDFRONT_THUMBNAIL_PREFIX_URL + profileImageUrl + THUMBNAIL_SMALL + "?" + props.profileHash} onError={addDefaultSrc} alt="profile" className="profile-image" />
                    </MobileOnlyView>
                </div>
            </Card.Body>
            <Card.Body className="mypage-bio-container">
                <span className="handle-name">{handleName}</span>
                {renderPreviewIcons(props, handleName)}
                <LinkTransform>
                    <span className="bio-text">{customHTMLParse(bio)}</span>
                </LinkTransform>
            </Card.Body>
        </>
    )
}

const renderPreviewIcons = (props, handleName) => {
    let pastPostRoute = appendUserRoute(`/past-posts/${props.friendId}/1`);
    let chatRoute = appendUserRoute(`/chatmessages/${props.friendId}`)
    let isFumiyaOrStaff = isFumiyaOrFFStaffUserCheck(parseInt(props.friendId)) || isFumiyaOrFFStaffUserCheck(props.userId);
    let isBlocked = 'blocked' === props.relationship
    let isFriend =  'is_friend' === props.relationship

    if(props.defaultProfile){
        return;
    }
    
    return (
        <>
            <Card.Body className="icons-card-container">
                <div className="icons-inner-container">
                    <TouchableOpacity activeOpacity={0.7} style={(isFumiyaOrStaff || isBlocked) ? styles.disabled : {}} disabled={isFumiyaOrStaff || isBlocked} onPress={() => props.handleFriendButtonClick()}>
                        <div className="icons-container">
                            <Image src={FriendIcon} alt="add" className="preview-icon" />
                            <p className="icon-name">{isFriend ? "友達" :  "友達になる" }</p>
                        </div>
                    </TouchableOpacity>
                    {props.isFriendMenuOpen ?
                        <div className="mypage-friend-menu-items friendpage-friend-menu-pos">
                            <TouchableOpacity
                                onPress={() => props.handleRemoveFriendClick()}
                                className="link-router"
                            >
                                <div className="menu-item">
                                    <Image src={RemoveFriend} className="header-icon" />
                                    <p>友達をやめる</p>
                                </div>
                            </TouchableOpacity>
                        </div>
                        : null}
                    <TouchableOpacity activeOpacity={0.7} style={(isFumiyaOrStaff || isBlocked) ? styles.disabled : {}} disabled={isFumiyaOrStaff || isBlocked} onPress={() => props.handleMessageButtonClick(chatRoute)}>
                        <div className="icons-container">
                            <Image src={MessagesIcon} alt="add" className="preview-icon" />
                            <p className="icon-name">メッセージ</p>
                        </div>
                    </TouchableOpacity>
                    <TouchableOpacity activeOpacity={0.7} onPress={() => props.handleProfileMenuToggle()}>
                        <div className="icons-container">
                            <Image src={MenuBox} alt="add" className="preview-icon" />
                            <p className="icon-name">その他</p>
                        </div>
                    </TouchableOpacity>
                    {props.isProfileMenuOpen ?
                        <div className="mypage-profile-menu-items friendpage-profile-menu-pos">
                            { isBlocked ? null : 
                                <Link key={pastPostRoute} href={pastPostRoute} to={pastPostRoute} className="link-router">
                                    <div className="menu-item">
                                        <Image src={PastIcon} className="header-icon" />
                                        <p>過去の投稿を見る</p>
                                    </div>
                                </Link>
                            }
                            {isFumiyaOrStaff ?
                                null 
                                :
                                <TouchableOpacity
                                    onPress={() => props.handleBlockUserClick()}
                                    className="link-router"
                                >
                                    <div className="menu-item">
                                        <Image src={BlockIcon} className="header-icon" />
                                        {
                                            isBlocked ?
                                                <p>ブロックを解除する</p>
                                                :
                                                <p>ブロックする</p>
                                        }
                                    </div>
                                </TouchableOpacity>}
                        </div>
                        : null}
                </div>
            </Card.Body>
        </>
    )
}

export default FriendPageHeaderComponent
import React, {useEffect} from "react";
import { Redirect, withRouter } from "react-router-dom";
import '../styles/login.scss';

const UserAuth = (props) => {

  useEffect(()=>{
    localStorage.clear()
  })

  let userId = parseInt(props.match.params.id);
  let email = `dummyUser${userId}@comux2test.com`;
  let password = "testtest";
  let notFound = false;

  switch(userId){
    // case 6152:{
    //   email = "zeppest@icloud.com";
    //   password = "123456"
    //   break;
    // }
    // case 550001:{
    //   email = "dgiango@csyoutsourcing.com";
    //   password = "123456"
    //   break;
    // }
    // case 494177:{
    //   email = "zepp_kk@yahoo.co.jp";
    //   password = "123456"
    //   break;
    // }
    case 488860:{
      email = "nlindog@asi-dev5.com";
      password = "asdf1234"
      break;
    }
    case 540316:{
      email = "davetest48@mailinator.com";
      password = "123456"
      break;
    }
    // case 549979:{
    //   email = "nlindog_test02@mailinator.com";
    //   password = "123456"
    //   break;
    // }
    // case 479249:{
    //   email = "zeppest@gmail.com";
    //   password = "123456"
    //   break;
    // }
    // case 549980:{
    //   email = "aihara_misaki@mailinator.com";
    //   password = "123456"
    //   break;
    // }
    // case 498564:{
    //   email = "nlindog@csyoutsourcing.com";
    //   password = "test123"
    //   break;
    // }
    // case 542919:{
    //   email = "hiromi_taguchi@mailinator.com";
    //   password = "123456"
    //   break;
    // }
    // case 549982:{
    //   email = "rena_saito@mailinator.com";
    //   password = "alliance"
    //   break;
    // }
    // case 487414:{
    //   email = "m_paulin@alliance.com.ph";
    //   password = "123456"
    //   break;
    // }
    // case 549983:{
    //   email = "keiko_misaki@mailinator.com";
    //   password = "123456"
    //   break;
    // }
    // case 549984:{
    //   email = "miyu_arakawa@mailinator.com";
    //   password = "alliance"
    //   break;
    // }
    // case 526532:{
    //   email = "dgiango@asi-dev1.com";
    //   password = "test123"
    //   break;
    // }
    // case 549985:{
    //   email = "nanase_aikawa@mailinator.com";
    //   password = "123456"
    //   break;
    // }
    // case 542921:{
    //   email = "uta_kajiwara@mailinator.com";
    //   password = "123456"
    //   break;
    // }
    // case 550002:{
    //   email = "megumi_aka@mailinator.com";
    //   password = "alliance"
    //   break;
    // }
    // case 318376:{
    //   email = "zeppest.peace@gmail.com";
    //   password = "123456"
    //   break;
    // }
    // case 550003:{
    //   email = "nakua_yatabe@mailinator.com";
    //   password = "123456"
    //   break;
    // }
    case 3644:{
      email = "test@comux2test.com";
      password = "testtest";
      break;
    }
    // case 5526:{
    //   email = "ff-sns@ffm.co.jp";
    //   password = "0021ff0021";
    //   break;
    // }
    default:{
      console.log("no user found")
      // notFound = true;
    }
  }

  console.log(email, password)

  if(notFound){
    return <center><h2>Test user not found</h2></center>
  }else{
    /**Redirect to Root with Route state to simulate FFMWeb redirect */
    return <Redirect to={{
      pathname: "/user/authenticate",
      state: {
        "FFMWebAuth": true,
        "email": email,
        "password": password
      }
    }} />
  }
}

export default withRouter(UserAuth);
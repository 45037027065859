import React from 'react';
import ContentLoader from 'react-content-loader'
import PCOnlyView from '../PCOnlyView';
import MobileOnlyView from '../MobileOnlyView';

const RowSkeleton = (
    <>
    <PCOnlyView>
        <ContentLoader
            speed={2}
            width={'100%'}
            height={200}
            viewBox=" 0 100% 60"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            className="svg-content-loader"
        >
            <rect x="0" y="5" width="33%" height="200" rx="3" />
            <rect x="34%" y="5" width="33%" height="200" rx="3" />
            <rect x="68%" y="5" width="33%" height="200" rx="3" />
        
         </ContentLoader>
    </PCOnlyView>
    
    <MobileOnlyView>
        <ContentLoader
            speed={2}
            width={'100%'}
            height={100}
            viewBox=" 0 100% 60"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <rect x="0" y="5" width="33%" height="100" rx="3" />
            <rect x="34%" y="5" width="33%" height="100" rx="3" />
             <rect x="68%" y="5" width="33%" height="100" rx="3" />
        </ContentLoader>
    </MobileOnlyView>
    </>
);

const InstagramSkeleton = () => {
    return (
        <div>
            {RowSkeleton}
            {RowSkeleton}
            {RowSkeleton}
            <MobileOnlyView>
                {RowSkeleton}
                {RowSkeleton}
                {RowSkeleton}
            </MobileOnlyView>
        </div>
    );
};

export default InstagramSkeleton;
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AdminCard from '../../components/AdminCard';
import TableTemplate from '../../components/TableTemplate';
import FormTemplate from '../../components/FormTemplate';
import { usersTable, userManagementForm } from './state/templates';
import UserPaginationTemplate from './UserPaginationTemplate';
import './styles/userManagement.scss';
import { userManagementOperations } from './state';
import { selectors } from './state';
import { onApiSuccess, appendUserRoute, defaultTopScroll, isStringNullEmptyOrUndefined } from "../../../utils/common";
import { PERMANENTLY_DELETE_USER} from "../../../utils/messages";
import ErrorModal from '../../components/ErrorModal';
import ConfirmationModal from '../../components/ConfirmationModal';
import { MSG000_SYSTEM_ERROR, MSG009_SYS_ERR_SAVING_RECORD } from '../../../utils/messages/admin/errorMessages';
import ProcessLoader from '../../components/ProcessLoader';
import { USER_MANAGEMENT_TBL_PAGE_SIZE, ADMIN_LEVEL_TWO, SINGLE_SPACE_CHAR } from '../../../utils/constants';
import { profileOperations } from '../EditProfile/state';
import { authOperations } from '../Authentication/state';

class UserSearch extends Component {

    constructor(props) {
        super(props);
        // On page refresh, scroll to top
        window.onbeforeunload = (e) => {
            defaultTopScroll();
        };
        this.state = {  
            currentPage: 1,
            currentPaginationToken: "",
            isDeleteModalShow: false,
            isErrorModalShow: false,
            isTblShow: false,
            isProcessing: false,
            userList : [],
            userRoute: '',
            isRedirect: false,
            errorMessage: '',
            userToDelete: null,
            searchField: '',
            searchValue: '',
            isRedirectModalShow: false
        }

        this.userRedirect = React.createRef();
    }

    componentWillMount() {
        this.setState({ isProcessing: true });
    }

    componentDidMount() {
        defaultTopScroll();
        this.setState({ isProcessing: false });
    }

    handleNoMatchingUsers = (responseData) => {
        const inputForm = userManagementForm.form;

        if(0 === responseData.pageList.length) {
            inputForm[0].type = "values";
            inputForm[0].name = "noMatchingUsers";
        } else {
            inputForm[0].type = "top-error-message";
            inputForm[0].name = "topErrorMessage";
        }
    }

    searchFunction = (page, isAppendList) =>{
        let values = { 
            paginationToken: this.state.currentPaginationToken || "", 
            searchField: this.state.searchField, 
            searchValue: this.state.searchValue, 
            currentPagination: page
        }

        this.props.searchUser(values, isAppendList)
        .then(res => {
            if (onApiSuccess(res)) {
                let responseData = JSON.parse(res.data.data);
                const showTable = 0 < responseData.pageList.length;

                // If zero matching records, show error message.
                this.handleNoMatchingUsers(responseData);
                
                this.setState({
                    isProcessing: false,
                    isTblShow: showTable,
                    currentPaginationToken: responseData.paginationToken,
                    currentPage: page,
                    totalPage: page
                },()=>{
                    this.handlePageChange(page)
                });
            } else {
                this.toggleErrorModal()
            }
        })
        .catch((err) => {
            console.log(err);
            this.toggleErrorModal()
        });
    }

    handleSubmit = (values) => {
        let searchField = "userId";
        let searchValue = "";
        let searchModel = values["UserManagementModel"];


        if(!isStringNullEmptyOrUndefined(searchModel.userId)){
            searchValue = searchModel.userId;
        }else if(!isStringNullEmptyOrUndefined(searchModel.handleName)){
            searchField = "handleName"
            searchValue = searchModel.handleName;
        }else if(!isStringNullEmptyOrUndefined(searchModel.email)){
            searchField = "email"
            searchValue = searchModel.email;
        }

        this.setState({ isProcessing: true, searchField, searchValue, currentPage: 1 }, () => {
            this.searchFunction(1, false)
        })
    }

    handleDelete = () => {
        this.setState({ isProcessing: true }, () => {
            this.props.deleteUser(this.state.userToDelete)
            .then(res => {
                if (onApiSuccess(res)) {
                    this.toggleDeleteConfirmModal();
                    this.handlePageChange(this.state.currentPage);
                    this.setState({ isProcessing: false });
                } else {
                    this.toggleErrorModal(MSG009_SYS_ERR_SAVING_RECORD)
                }
            })
            .catch(() => this.toggleErrorModal(MSG009_SYS_ERR_SAVING_RECORD));
        })
    }

    handleNextPage = (page) => {
        if(!isStringNullEmptyOrUndefined(this.state.currentPaginationToken)){
            this.setState({ isProcessing: true }, () => {
                this.searchFunction(page, true)
            });
        }
    }

    handlePageChange = (page) => {
        const currUsersList = this.props.userList.slice((page - 1) * USER_MANAGEMENT_TBL_PAGE_SIZE, page * USER_MANAGEMENT_TBL_PAGE_SIZE);
        this.setState({ currentPage: page, userList: currUsersList });
    }

    handleRedirectToProfile = (row) => {

        this.setState({ isProcessing: true }, () => {
            if (!row.hasOwnProperty('userId') || 'undefined' === typeof row.userId) {
                this.props.getUserId(row)
                    .then(res => {
                        if (onApiSuccess(res)) {
                            const responseData = JSON.parse(res.data.data);
                            const userId = responseData[0].userId;

                            //Redirect
                            this.setBackdoorAuth(userId, row.handleName);
                        } else {
                            this.toggleErrorModal()
                            return
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        this.toggleErrorModal()
                        return
                    });
            } else {
                //Redirect
                this.setBackdoorAuth(row.userId, row.handleName);
            }
        })
    }

    /**Handles updating store to allow admin access prior to redirect user */
    setBackdoorAuth = (userId, handleName) => {
        let params = { userId, handleName, authenticated: true };

        //Set user details
        this.props.getUserProfile(userId)
            .then(() => {
                //Set backdoor store details
                this.props.setAdminBackdoorCheck(params)
                    .then(() => {
                        //Redirect to user mypage
                        this.setState({ isProcessing: false, isRedirectModalShow: true });
                    }).catch(() => this.setState({ isProcessing: false }))
            }).catch(() => this.setState({ isProcessing: false }))
    }

    renderPagination = () => {
        return (
            <UserPaginationTemplate
                currentPage={this.state.currentPage}
                totalPage={this.state.totalPage}
                handlePageChange={(page) => { this.handlePageChange(page) }}
                handleNextPage={(page) => { this.handleNextPage(page) }}
                paginationToken={this.state.currentPaginationToken}
                fetchedSize={this.props.userList.length}
            />
        )
    }

    toggleErrorModal = (error=null) => {
        // Display default error message, else display system error when saving record
        if(null === error) {
            this.setState(prevState => ({ isProcessing: false, isDeleteModalShow: false, errorMessage: MSG000_SYSTEM_ERROR, isErrorModalShow: !prevState.isErrorModalShow }));
        } else {
            this.setState(prevState => ({ isProcessing: false, isDeleteModalShow: false, errorMessage: error, isErrorModalShow: !prevState.isErrorModalShow }));
        }
    }

    toggleDeleteConfirmModal = (row) => {
        // If isDeleteModalShow is updated to True, update userToDelete in state
        if(false === this.state.isDeleteModalShow) {
            this.setState(prevState => ({ userToDelete: row, isDeleteModalShow: !prevState.isDeleteModalShow }));
        } else {
            this.setState(prevState => ({ userToDelete: null, isDeleteModalShow: !prevState.isDeleteModalShow }));
        }
    }

    toggleRedirectModal = () => {
        this.setState(prevState => ({ isRedirectModalShow: !prevState.isRedirectModalShow }));
    }

    isShowDeleteHeader = () => {
        // If user level is !== 2, do not show delete column 
        if(ADMIN_LEVEL_TWO !== this.props.user.attributes["custom:level"]) {
            usersTable.tableHeaders = usersTable.tableHeaders.filter(header => SINGLE_SPACE_CHAR !== header);
        }
        return usersTable.tableHeaders;
    }

    isShowDeleteButton = () => {
        // If user level is !== 2, do not show delete button
        if(ADMIN_LEVEL_TWO === this.props.user.attributes["custom:level"]) {
            return [{ label: "削除", variant: "link", onClick: (row) => { this.toggleDeleteConfirmModal(row) } }];
        }
        return null;
    }

    clearSearch = (resetForm) =>{
        resetForm({
            UserManagementModel:{
                userId: "",
                handleName: "",
                email: "",
                topErrorMessage: ""
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                {/**Process Loading Indicator */}
                <ProcessLoader
                    isShown={this.state.isProcessing}
                />

                {/**User Management Form */}
                <p className="admin-title">ユーザ検索</p>
                <AdminCard className="userManagement-search-card">
                    <p className="admin-subtitle">ユーザ検索</p>
                    <FormTemplate
                        validate={selectors.validateUserManagement}
                        formInputs={userManagementForm}
                        handleSubmit={this.handleSubmit}
                        mainFormClass={"mainUserSearchContainer"}
                        customClass={"userManagement-form"}
                        formButtons={[
                            { type: "primary", label: "検索する", submit: true, className:"userManagement-submit-btn" },
                            { type: "secondary", label: "リセットする", className:"userManagement-submit-btn", onClick: this.clearSearch }
                        ]}
                    />
                </AdminCard>

                {/**Search Results Table */}
                {this.state.isTblShow && 
                    (<AdminCard>
                        <p className="admin-subtitle">検索結果 <span className="admin-subtitle-colored">{this.state.userList.length}</span> 件</p>

                        {this.renderPagination()}
                        <TableTemplate
                            tableName={"userSearch"}
                            onClick={(row) => { this.handleRedirectToProfile(row) }}
                            tableHeader={this.isShowDeleteHeader()}
                            tableColumns={usersTable.tableColumns}
                            tableList={this.state.userList}
                            rowButtons={this.isShowDeleteButton()}
                        />
                        {this.renderPagination()}
                        
                    </AdminCard>)
                }
                {/** Redirect Confirmation Message*/}
                <ConfirmationModal
                    isModalShow={this.state.isRedirectModalShow}
                    confirmationText={"このユーザでユーザ画面にログインしますか？"}
                    handleConfirmAction={()=>{
                        window.open(appendUserRoute("/mypage"), "_blank");
                        this.toggleRedirectModal();
                    }}
                    handleCloseModal={this.toggleRedirectModal}
                />
                {/** Delete Confirmation Message*/}
                <ConfirmationModal
                    isModalShow={this.state.isDeleteModalShow}
                    confirmationText={PERMANENTLY_DELETE_USER}
                    handleConfirmAction={this.handleDelete}
                    handleCloseModal={this.toggleDeleteConfirmModal}
                />
                {/** Error Response Message*/}
                <ErrorModal
                    isErrorModalShow={this.state.isErrorModalShow}
                    errorMessage={this.state.errorMessage}
                    handleCloseModal={this.toggleErrorModal}
                />

                {this.state.isRedirect && (
                    <Redirect to={this.state.userRoute} />
                )}

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userList: state.usermanagement.userList,
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.credentials
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        searchUser: userManagementOperations.searchUser,
        deleteUser: userManagementOperations.deleteUser,
        getUserId: userManagementOperations.getUserId,
        getUserProfile: profileOperations.getUserProfile,
        setAdminBackdoorCheck: authOperations.setAdminBackdoorCheck
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(UserSearch);
import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { logger } from 'redux-logger';
import throttle from 'lodash/throttle';
import { PERSISTED_STATE_KEY, BROWSER_ID } from './config/settings';
import uuid from 'react-uuid'
//Modules imports
import Authentication from './views/containers/Authentication/state';
import User404 from './views/containers/Errors/User404';
import Admin404 from './views/containers/Errors/Admin404';

//Users
import UserMain from './views/containers/UserMain/state';
import Top from './views/containers/Top/state';
import Post from './views/containers/Post/state';
import MyPage from './views/containers/MyPage/state';
import Chat from './views/containers/Chat/state';
import Community from './views/containers/Communities/state';
import Search from './views/containers/Search/state';
import Notifications from './views/containers/Notifications/state';
import SNS from './views/containers/SNS/state';
import EditProfile from './views/containers/EditProfile/state';
import TermsOfService from './views/containers/TermsOfService/state';
import PrivacyPolicy from './views/containers/PrivacyPolicy/state';
import BlockedUsers from './views/containers/BlockedUsers/state';
import Friends from './views/containers/Friends/state';
import FriendPage from './views/containers/FriendPage/state';
import PastPost from './views/containers/PastPost/state';

//Admin
import AdminMain from './views/containers/AdminMain/state';
import UserManagement from './views/containers/UserManagement/state';
import NotificationManagement from './views/containers/NotificationManagement/state';
import RandomMessage from './views/containers/RandomMessage/state';
import Maintenance from './views/containers/Maintenance/state';

//Store methods
const loadState = () => {
    try {
        const serializedState = localStorage.getItem(PERSISTED_STATE_KEY);
        return null === serializedState ? undefined : JSON.parse(serializedState);
    } catch (e) { return undefined; }
};

const saveState = (state) => {
    try {
        localStorage.setItem(PERSISTED_STATE_KEY, JSON.stringify(state));
    } catch (e) { }
};

const initBrowserId = () => {
    try {
        sessionStorage.setItem(BROWSER_ID, uuid());
    } catch (e) { }
};

//Module Reducers
const appReducer = combineReducers({
    usermain: UserMain.reducer,
    auth: Authentication.reducer,
    top: Top.reducer,
    mypage: MyPage.reducer,
    chat: Chat.reducer,
    community : Community.reducer,
    search: Search.reducer,
    notifications: Notifications.reducer,
    sns: SNS.reducer,
    randommsg: RandomMessage.reducer,
    usermanagement: UserManagement.reducer,
    notifmanagement: NotificationManagement.reducer,
    maintenance: Maintenance.reducer,
    editprofile: EditProfile.reducer,
    friendpage: FriendPage.reducer,
    pastpost: PastPost.reducer
});

const rootReducer = (state, action) => {

    if ("RESET_STORE" === action.type) {
        state = undefined
    }

    return appReducer(state, action);
}

//Root Routes
export const rootRoutes = [
    ...UserMain.routes,
    ...Authentication.routes,
    ...AdminMain.routes,
    { component: User404 }
]

//User Module Routes
export const userRoutes = [
    //Insert user module routes here
    ...Top.routes,
    ...Post.routes,
    ...MyPage.routes,
    ...Chat.routes,
    ...Community.routes,
    ...MyPage.routes,
    ...SNS.routes,
    ...Search.routes,
    ...Notifications.routes,
    ...TermsOfService.routes,
    ...PrivacyPolicy.routes,
    ...BlockedUsers.routes,
    ...Friends.routes,
    ...FriendPage.routes,
    ...PastPost.routes,
    //Removed EditProfile for public display
    //Removed ContactUs for public display
    { component: User404 }
]

//Fumiya and FF-Staff Module Routes
export const fumiyaRoutes = [
    //Insert user module routes here
    ...Top.routes,
    ...Post.routes,
    ...MyPage.routes,
    ...Community.routes,
    ...MyPage.routes,
    ...SNS.routes,
    ...TermsOfService.routes,
    ...PrivacyPolicy.routes,
    ...BlockedUsers.routes,
    ...Friends.routes,
    ...FriendPage.routes,
    ...PastPost.routes,
    ...Search.routes,
    //Removed EditProfile for public display
    //Removed ContactUs for public display
    { component: User404 }
]

//Admin Module Routes
export const adminRoutes = [
    //Insert admin module routes here
    ...UserManagement.routes,
    ...NotificationManagement.routes,
    ...RandomMessage.routes,
    ...Maintenance.routes,
    { component: Admin404 }
]

const persistedState = loadState();

const rootMiddlewares = [thunkMiddleware];

if (`development` === process.env.NODE_ENV) {
    rootMiddlewares.push(logger);
}
  
const middleware = applyMiddleware(...rootMiddlewares)


const store = createStore(
    rootReducer,
    persistedState,
    middleware,
);

initBrowserId();

export default function configureStore() {
    store.subscribe(throttle(() => {
        saveState(store.getState());
    }), 3000);

    return store;
};

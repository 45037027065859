import * as CommonSelectors from '../../../components/Validations/CommonSelectors';
import * as ErrorMessages from '../../../../utils/messages/admin/errorMessages';
import * as CommonValidation from '../../../../utils/common';

const validatePasswordChange = (values) => {
    let errors = {};
    
    if(CommonValidation.isStringNullEmptyOrUndefined(values.PasswordChangeModel.newPassword)){
        errors[`PasswordChangeModel.topErrorMessage`] = ErrorMessages.MSG064_EMPTY_NEW_PASSWORD;
    }

    else if(CommonValidation.isStringNullEmptyOrUndefined(values.PasswordChangeModel.confirmPassword)){
        errors[`PasswordChangeModel.topErrorMessage`] = ErrorMessages.MSG065_EMPTY_CONFIRM_PASSWORD;
    }

    else if(CommonSelectors.isThereDoubleByte(values.PasswordChangeModel.newPassword)){
        errors[`PasswordChangeModel.topErrorMessage`] = ErrorMessages.MSG066_PASSWORD_HAS_DOUBLE_BYTE;
    }

    else if(CommonSelectors.isThereMachineDependent(values.PasswordChangeModel.newPassword)){
        errors[`PasswordChangeModel.topErrorMessage`] = ErrorMessages.MSG067_PASSWORD_HAS_MACHINE_DEPENDENT;
    }

    else if(!CommonSelectors.isAlphanumeric(values.PasswordChangeModel.newPassword)){
        errors[`PasswordChangeModel.topErrorMessage`] = ErrorMessages.MSG068_INVALID_PASSWORD;
    }

    else if(values.PasswordChangeModel.newPassword.length < 8 || values.PasswordChangeModel.newPassword.length > 16){
        errors[`PasswordChangeModel.topErrorMessage`] = ErrorMessages.MSG069_INVALID_PASSWORD_LEN;
    }

    else if(values.PasswordChangeModel.newPassword !== values.PasswordChangeModel.confirmPassword){
        errors[`PasswordChangeModel.topErrorMessage`] = ErrorMessages.MSG070_PASSWORD_NOT_MATCH;
    }

    return errors;
}

export default {
    validatePasswordChange
}
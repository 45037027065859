import React from 'react';
import { Image,Media as BootstrapMedia } from 'react-bootstrap';
import DefaultIcon from '../../../includes/img/placeholders/user.svg';
import ResponseArea from './ResponseArea';
import { Link } from 'react-router-dom';
import { appendUserRoute, addDefaultSrc } from '../../../utils/common';
import './styles.scss';
import { CLOUDFRONT_THUMBNAIL_PREFIX_URL } from '../../../utils/constants';

const RenderReply = (props) => {
    let isOwner = props.userId === props.author.userId;

    //User Route
    let appendedRoute = appendUserRoute(`/user/${props.author.userId}`);

    let isProfileImageUrlValid = props.author.profileImageUrl && 0 <= props.author.profileImageUrl.indexOf("Profile/");
    
    if (isOwner) {
        appendedRoute = appendUserRoute("/mypage");
    }

    let isParent = (window == window.parent); //task#363

    return (
        <BootstrapMedia style={{ marginLeft: 12 }}>
            {props.author.isUserDeleted ? (
                <Image
                    onError={addDefaultSrc}
                    src={isProfileImageUrlValid ? `${CLOUDFRONT_THUMBNAIL_PREFIX_URL + props.author.profileImageUrl}-small?${props.hash}` : DefaultIcon}
                    className="post-view-avatar"
                    roundedCircle
                    alt={"user-img"}
                />
            ):(
                <Link /** task#363 start */
                    href={appendedRoute} 
                    to={appendedRoute}
                    target = {isParent ? "_self" : "_blank"} 
                    rel="noopener noreferrer"
                >{/** task#363 end*/}
                    <Image
                        onError={addDefaultSrc}
                        src={isProfileImageUrlValid ? `${CLOUDFRONT_THUMBNAIL_PREFIX_URL + props.author.profileImageUrl}-small?${props.hash}` : DefaultIcon}
                        className="post-view-avatar"
                        roundedCircle
                        alt={"user-img"}
                    />
                 </Link>
            )}
            <BootstrapMedia.Body>
                <ResponseArea
                    {...props}
                    commentId={props.commentId}
                    replyId={props.replyId}
                    key={props.replyId}
                    type={"reply"}
                    value={props.replyBody}
                    replyCount={0}
                    isOwner={isOwner}
                    postAuthorId={props.postAuthorId}
                />
            </BootstrapMedia.Body>
        </BootstrapMedia>
    )
}

export default RenderReply;
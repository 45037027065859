import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { authOperations } from '../containers/Authentication/state';
import { isSessionExpired } from '../../utils/common';

const SessionTimer = (props) => {

    const dispatch = useDispatch();
    let operations = bindActionCreators(
        {
            logoutUser: authOperations.logoutUser
        },
        dispatch
      );

    const handleOnIdle = event => {
        console.log('Session expired due to idle', event)
        operations.logoutUser(false);
      }
     
      const handleOnActive = event => {
        //Skip action
      }
     
      const handleOnAction = (e) => {
        if(isSessionExpired()){
          operations.logoutUser(false);
        }else{
          let newExpiry = new Date();
          newExpiry.setHours(newExpiry.getHours() + 2); //AWS wesocket timeout
          localStorage.setItem("session_expiry", newExpiry.getTime())
        }
      }
     
      useIdleTimer({
        timeout: 1000 * 60 * 120, //AWS wesocket timeout
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 850
      })
     
      return (
        <div>
          {props.children}
        </div>
      )
};

export default SessionTimer;
import reducer from "./reducers";
import { routes } from "../routes";
import * as maintenanceOperations from "./operations";

export {
    maintenanceOperations,
};

export default {
    reducer,
    routes
};

import React, { Component } from 'react';
import { maintenanceOperations } from './state';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { onApiSuccess } from '../../../utils/common';
import { MAINTENANCE_TYPE, MAINTENANCE_TEXT, ADMIN_LEVEL_TWO } from '../../../utils/constants';
import ErrorModal from '../../components/ErrorModal';
import { MSG000_SYSTEM_ERROR, MSG009_SYS_ERR_SAVING_RECORD } from '../../../utils/messages/admin/errorMessages';
import { Dropdown, DropdownButton, Button } from 'react-bootstrap';
import AdminCard from '../../components/AdminCard';
import ProcessLoader from '../../components/ProcessLoader';
import './styles/maintenance.scss';

class Maintenance extends Component {

    state = {
        maintenanceId: '',
        maintenanceType: null,
        updatedMaintenanceType: null,   // Actual value passed to API call
        maintenanceText: '',    
        isDataRetrieved: false,
        isErrorModalShow: false,
        isProcessing: false,
        errorMessage: '',
    }

    componentDidMount() {
        this.setState({ isProcessing: true }, () => {
            this.props.getMaintenanceSettings()
            .then(res => {
                if (onApiSuccess(res)) {
                    let responseData = JSON.parse(res.data.data);
                    const maintenanceType = parseInt(responseData[0].maintenanceType);
                    const maintenanceId = responseData[0]._id.$oid;
                    const maintenanceText = this.assertMaintenanceText(maintenanceType);
                    
                    this.setState({
                        maintenanceId: maintenanceId,
                        maintenanceType: maintenanceType,
                        updatedMaintenanceType: MAINTENANCE_TYPE.normal, // set initial option to Normal
                        maintenanceText: maintenanceText,
                        isDataRetrieved: true,
                        isProcessing: false
                    });
                } else {
                    this.toggleErrorModal(MSG000_SYSTEM_ERROR);
                }
            })
            .catch(() => this.toggleErrorModal(MSG000_SYSTEM_ERROR));
        });
    }

    toggleErrorModal = (errorMessage='') => {
        this.setState(prevState => ({ errorMessage: errorMessage, isErrorModalShow: !prevState.isErrorModalShow, isProcessing: false }));
    }

    assertMaintenanceText = (maintenanceType) => {
        switch(maintenanceType){

            case MAINTENANCE_TYPE.normal:
                return MAINTENANCE_TEXT.normal;

            case MAINTENANCE_TYPE.emergency:
                return MAINTENANCE_TEXT.emergency;

            case MAINTENANCE_TYPE.temporary:
                return MAINTENANCE_TEXT.temporary;

            default:
                return MAINTENANCE_TEXT.normal;
        }
    }

    handleUpdateMaintenanceType = (values) => {
        this.setState(
            { maintenanceType: values.maintenanceType }
        );
    }

    handleUpdateMaintenanceText = (e) => {
        this.setState({ maintenanceText: e.target.textContent });
    }

    handleMaintenanceSelect = (e) => {
        this.setState({ updatedMaintenanceType: e });
    }

    handleSubmit = () => {
        this.setState({ isProcessing: true }, () => {
            let payload= {
                maintenanceId: this.state.maintenanceId,
            }
            if(!this.state.maintenanceType) {
                payload.maintenanceType = this.state.updatedMaintenanceType;
            } 
            else {
                const maintenanceType = MAINTENANCE_TYPE.open;
                this.setState({ updatedMaintenanceType: maintenanceType }, 
                    () => {
                        payload.maintenanceType = maintenanceType;
                    }
                );
            }
            this.props.updateMaintenanceSettings(payload)
                .then(res => {
                    if (onApiSuccess(res)) {
                        const maintenanceType = this.state.updatedMaintenanceType;
                        const maintenanceText = this.assertMaintenanceText(maintenanceType);
                        this.setState({
                            maintenanceType: maintenanceType,
                            maintenanceText: maintenanceText,
                            updatedMaintenanceType: MAINTENANCE_TYPE.normal,
                            isProcessing: false
                        });
                    } else {
                        this.toggleErrorModal(MSG009_SYS_ERR_SAVING_RECORD);
                    }
                })
                .catch(() => this.toggleErrorModal(MSG009_SYS_ERR_SAVING_RECORD));
        });
    }

    render() {
        let isNotMaintenance = (MAINTENANCE_TYPE.open === this.state.maintenanceType);
        const isDataRetrieved = this.state.isDataRetrieved;
        const maintenanceTypeList = [
            MAINTENANCE_TEXT.normal, MAINTENANCE_TEXT.emergency,  MAINTENANCE_TEXT.temporary
        ]

        let adminLevel = null;
        let isAdminLevelTwo = true;

        if(this.props.credentials.hasOwnProperty('attributes')) {
            if(this.props.credentials.attributes.hasOwnProperty('custom:level')) {
                adminLevel = this.props.credentials.attributes['custom:level']
            }
        }

        if (ADMIN_LEVEL_TWO !== adminLevel) {
            const path = "/admin/user-search";
            this.props.history.push({
                pathname: path,
            });
            isAdminLevelTwo = false;
        }

        return (
            <React.Fragment>
                {!isAdminLevelTwo && (
                    <Redirect to={{
                        pathname: "/admin/user-search",
                        state: {
                            from: this.props.location
                        }
                    }} />
                )}
                <p className="admin-title">System Maintenance Setting</p>
                <AdminCard>

                {/**Process Loading Indicator */}
                <ProcessLoader
                    isShown={this.state.isProcessing}
                />

                <center>
                <React.Fragment>
                    {isDataRetrieved && (
                        <span><p style={{display: 'inline'}}>Site Status&emsp;</p>
                            {isNotMaintenance && (
                                <React.Fragment>
                                    <p style={{display: 'inline', paddingRight: '1em', 
                                        color: '#29BA9C', fontWeight: 'bold'}}>OPEN</p>
                                    <DropdownButton 
                                        variant={'secondary'}
                                        style={{display: 'inline', paddingRight: '1em'}} 
                                        title={this.state.maintenanceText}
                                        onSelect={(e)=>this.handleMaintenanceSelect(e)}
                                    >
                                        {maintenanceTypeList.map((item, index) => 
                                        {
                                            index += 1;
                                            return <Dropdown.Item key={index} eventKey={index.toString()} 
                                            onClick={(e)=>this.handleUpdateMaintenanceText(e)}>{item}</Dropdown.Item>
                                        })}
                                    </DropdownButton>
                                    <Button
                                        key={"Submit"}
                                        type={"submit"}
                                        variant={"primary"}
                                        className={"form-template-button"}
                                        onClick={()=>this.handleSubmit()}
                                        style={{backgroundColor: '#29BA9C', borderColor: '#29BA9C'}}
                                    >Maintenance Mode</Button>
                                </React.Fragment>
                            )}
                            {!isNotMaintenance && (
                                <React.Fragment>
                                <p style={{display: 'inline', paddingRight: '1em', 
                                    color: 'red', fontWeight: 'bold'}}>MAINTENANCE MODE</p>
                                <Button
                                    key={"Submit"}
                                    type={"submit"}
                                    variant={"primary"}
                                    className={"form-template-button"}
                                    onClick={()=>this.handleSubmit()}
                                    style={{backgroundColor: '#29BA9C', borderColor: '#29BA9C'}}
                                >Open Site</Button>
                                </React.Fragment>
                            )}
                        </span>
                    )}
                {/** Error Response Message*/}
                <ErrorModal
                    isErrorModalShow={this.state.isErrorModalShow}
                    errorMessage={this.state.errorMessage}
                    handleCloseModal={this.toggleErrorModal}
                />
                </React.Fragment>
                </center>
            </AdminCard>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        maintenanceId: state.maintenance.maintenanceId,
        maintenanceType: state.maintenance.maintenanceType,
        credentials: state.auth.credentials
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getMaintenanceSettings: maintenanceOperations.getMaintenanceSettings,
        updateMaintenanceSettings: maintenanceOperations.updateMaintenanceSettings,
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(Maintenance);
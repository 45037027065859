import React from 'react';
import ContentLoader from 'react-content-loader'

const RowSkeleton = (
    <ContentLoader
        speed={2}
        width={'100%'}
        height={100}
        
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >

        <rect x="90" y="20" width="140" height="10" rx="3" />
        <rect x="90" y="36" width="1000" height="8" rx="3" />
        <rect x="90" y="50" width="1000" height="8" rx="3" />
        <circle cx="36" cy="36" r="36" />
    </ContentLoader>
);

const ChatListSkeleton = () => {
    return (
        <div className="pt-3 pr-3 pl-1">
            {RowSkeleton}
            {RowSkeleton}
            {RowSkeleton}
            {RowSkeleton}
            {RowSkeleton}
        </div>
    );
};

export default ChatListSkeleton;
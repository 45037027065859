import * as Path from "./apiRoutes"
import ApiService from "../../../../utils/apiService"


const checkIfBlocked = (userId, friendId) => (dispatch) => {
    return ApiService.get(Path.CHECK_USER_BLOCKED + `/${userId}/${friendId}`)
    .then((response)=>{
        return response
    })
    .catch((error)=>{
        console.log(error)
    })
}

const getAllPastPosts = (ownerId, friendId, filterYear, filterMonth, source, currentPagination) => (dispatch) => {
    const params = {
        ownerId: ownerId,
        friendId: friendId,
        filterYear: filterYear ? filterYear : "",
        filterMonth: filterMonth ? filterMonth: "",
        source: source,
        current_pagination: currentPagination
    }
    return ApiService.post(Path.GET_PAST_POSTS, params)
    .then((response)=>{
        return response
    })
    .catch((error)=>{
        console.log(error)
    })
}

const getPostDates = (ownerId, friendId, source) => (dispatch) => {
    return ApiService.get(Path.GET_POST_DATES + `/${ownerId}/${friendId}/${source}`)
    .then((response)=>{
        return response
    })
    .catch((error)=>{
        console.log(error)
    })
}

export {
    checkIfBlocked,
    getAllPastPosts,
    getPostDates
}
import axios from 'axios';
import { REST_SERVICE_URL_ROOT } from '../config/settings';
import configureStore from '../app.modules';
import { bindActionCreators } from "redux";
import { authOperations } from '../views/containers/Authentication/state';
import { refreshTokensFromApi } from './common';
import { USER_TYPE } from './constants';

class ApiService {
  constructor() {

    axios.defaults.baseURL = REST_SERVICE_URL_ROOT;
    
    let service = axios.create();


    this.isRefreshing = false;
    this.refreshSubscribers = [];

    //Request Interceptor
    service.interceptors.request.use(function (config) {
      try{
        let token;
        if(configureStore().getState().auth.credentials.signInUserSession.idToken.jwtToken === undefined){
          token = "";
        } else {
          token = configureStore().getState().auth.credentials.signInUserSession.idToken.jwtToken;
        }
        
        //Change depending on your authorization token variable
        config.headers.common['Authorization'] = 'Bearer ' + token;
        config.headers.common['Content-Type'] = 'application/json';

      }catch{
        console.log("Unauthorized");
      }
      
      // Do something before request is sent
      return config;
    }, function (error) {
      // Do something with request error
      console.log(error);
      this.handleError(error)
      return Promise.reject(error);
    });

    // Response Interceptor
    service.interceptors.response.use(this.handleSuccess, this.handleError);

    this.service = service;
  }

  handleSuccess = (response) =>{
    return response;
  }

  //Update actions for error handling
  handleError = (error) => {
    let errorStatus = {};
    const originalRequest = error.config;

    try{
      if (!this.isRefreshing) {
        this.isRefreshing = true;
        this.refreshRequestToken()
        .then(newToken=>{
          this.isRefreshing = false;
          this.onTokenRefreshed(newToken);
        }).catch((err)=>{throw err})
      }
  
      const retryOrigReq = new Promise((resolve, reject) => {
        this.subscribeTokenRefresh(newToken => {
          // replace the expired token and retry
          originalRequest.headers['Authorization'] = 'Bearer ' + newToken;
          resolve(axios(originalRequest));
        });
      });

      return retryOrigReq;
    }catch(e){
      errorStatus.statusError = "error";
      return errorStatus;
    }
  }

  subscribeTokenRefresh(cb) {
    this.refreshSubscribers.push(cb);
  }
  
  onTokenRefreshed(token) {
    this.refreshSubscribers.map(cb => cb(token));
  }

  refreshRequestToken = () =>{
    let dispatch = configureStore().dispatch;
    let operations = bindActionCreators(
      {
        refreshTokens: authOperations.refreshTokens
      },
      dispatch
    );
    
    return refreshTokensFromApi(operations, USER_TYPE.user)
  }

  redirectTo = (document, path) => {
    document.location = path;
  }
  
  get(path) {
    return this.service.get(path);
  }
  
  patch(path, payload) {
    return this.service.request({
      method: 'PATCH',
      url: path,
      responseType: 'json',
      data: payload
    }).then((response) => {return response});
  }

  post(path, payload) {
    return this.service.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload
    }).then((response) => {return response});
  }

  delete(path, payload) {
    return this.service.request({
      method: 'DELETE',
      url: path,
      responseType: 'json',
      data: payload
    }).then((response) => {return response});
  }
}

export default new ApiService();
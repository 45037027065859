import * as Path from "./apiRoutes";
import ApiService from "../../../../utils/apiService";


const getBlockedUsers = (userId,currentPagination) => dispatch => {
  return ApiService.get( Path.GET_BLOCKED_USERS + `/${userId}/${currentPagination}`)
    .then(response => {
        return response
    })
    .catch(error => {
      console.error(error);
    });
};

const countBlockedUsers = (userId) => dispatch => {
    return ApiService.get( Path.GET_BLOCKED_USERS_COUNT + `/${userId}`)
      .then(response => {
        return response
      })
      .catch(error => {
        console.error(error);
      });
  };


  export {
    getBlockedUsers,
    countBlockedUsers
  };
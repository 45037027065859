import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  removeIcon: {
    width: 22,
    height: 22,
    position: 'absolute',
    top: 4,
    right: 16
  }
});

export default styles;

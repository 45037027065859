import { indexOf } from 'lodash';

const ALPHANUMERIC = /^[A-Za-z0-9]+$/
const NUMERIC = /^[0-9]*$/

export const isNumeric = (value) => {
    if (NUMERIC.test(value)) {
        return true;
    }
    return false;
}

export const isAlphanumeric = (value) => {
    if (ALPHANUMERIC.test(value)) {
        return true;
    }
    return false;
}

export const isCorrectEmail = (value) => {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        return false;
    }
    return true;
}

export const isThereDoubleByte = (value) => {

    for (var i = 0; i < value.length; i++) {
        var ch = value.charAt(i);
        var esc = escape(ch);
        if ((esc.length === 3 && esc.indexOf('%') !== -1 && ch !== '\\r' && ch !== '\\n') || esc.length === 1) {
            // let single_byte = 1;
        } else {
            return true;
        }
    }
    return false;
}

export const isThereNonDigit = (value) => {
    if (!/^\d+$/.test(value)) {
        return true;
    }
    return false;
}

export const isInvalidYear = (value) => {
    return 4 > value.length ? true : false;
}

export const isCorrectImage = (value) => {
    return /\.(jpeg|jpg|gif|png)$/.test(value);
}

export const isThereMachineDependent = (value) => {
    let MachineCodes = ['E28496','E38F8D','E284A1','E38AA4','E38AA5','E38AA6','E38AA7','E38AA8','E388B1','E388B2',
    'E388B9','E38DBE','E38DBD','E38DBC','E38DBB','E38D89','E38E9C','E38E9D','E38E9E','E38E8E','E38E8F','E38F84','E38C94',
    'E38CA2','E38D8D','E38C98','E38CA7','E38C83','E38CB6','E38D91','E38D97','E38C8D','E38CA6','E38CA3','E38CAB',
    'E38D8A','E38CBB','E291A0','E291A1','E291A2','E291A3','E291A4','E291A5','E291A6','E291A7','E291A8','E291A9',
    'E291AA','E291AB','E291AC','E291AD','E291AE','E291AF','E291B0','E291B1','E291B2','E291B3','E285A0','E285A1',
    'E285A2','E285A3','E285A4','E285A5','E285A6','E285A7','E285A8','E285A9','E289A1','E28891','E288AB','E288AE',
    'E2889A','E28AA5','E288A0','E2889F','E28ABF','E288B5','E288A9','E288AA','E7BA8A','E98D88','E8939C','E782BB',
    'E6A388','E5858A','E5A48B','E5A59B','E5A5A3','E5AFAC','EFA891','E5B582']
    let char = ""
    for (let i = 0; i < value.length; i++) {
        char = value.charAt(i)
        var encoded = rawurlencode(char)
        let code_value = ""
        let sChar = ""
        for (let i = 0; i < encoded.length; i++) {
            sChar = encoded.substr(i, 1).replace("%", "")
            code_value = code_value + sChar
        }
        code_value = code_value.trim()
        if(indexOf(MachineCodes,code_value) !== -1) {
            return true;
        }
        if ((code_value >= 'A9A0' && code_value <= 'AFFF') || (code_value >= 'F5A0' && code_value <= 'FEFF')) { 
            return true;
        }
    }
    return false
}

const rawurlencode = (str) => {

    str = (str + '')

    // Tilde should be allowed unescaped in future versions of PHP (as reflected below),
    // but if you want to reflect current
    // PHP behavior, you would need to add ".replace(/~/g, '%7E');" to the following.
    return encodeURIComponent(str)
        .replace(/!/g, '%21')
        .replace(/'/g, '%27')
        .replace(/\(/g, '%28')
        .replace(/\)/g, '%29')
        .replace(/\*/g, '%2A')

}
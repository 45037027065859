import React, { useState } from "react";
import { authOperations } from './state';
import { Button, FormGroup, FormControl, FormLabel, Container, Card } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { selectors } from './state';
import { bindActionCreators } from 'redux'
import { useSelector, useDispatch } from 'react-redux';
import './styles/login.scss';
import * as ErrorMessages from '../../../utils/messages/admin/errorMessages';
import { ADMIN_ROOT_ROUTE } from "../AdminMain/routes";
import ProcessLoader from '../../components/ProcessLoader';
import { NOT_AUTHORIZED, USER_NOT_FOUND } from "../../../utils/constants";

const LoginScreen = () => {
  //Hooks state
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  //Redux store data
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  //Bind dispatch to action creators
  const dispatch = useDispatch();
  const operations = bindActionCreators(
    {
      loginAdmin: authOperations.loginAdmin
    },
    dispatch
  )

  const disableForm = () => {
    return 0 >= username.length || 0 >= password.length;
  }


  const handleSubmit = (event) => {
    event.preventDefault();
    let isInvalid = selectors.validateLogin({username, password});
    if (isInvalid.email) {
      setErrorMessage(isInvalid.email);
    } 
    else if (isInvalid.password) {
      setErrorMessage(isInvalid.password);
    } 
    else {
      operations.loginAdmin(username, password)
      .then((result) => {
          switch(result.code){
            case USER_NOT_FOUND:
              setErrorMessage(ErrorMessages.MSG006_EMAIL_NOT_EXIST);
              break;
            case NOT_AUTHORIZED:
              setErrorMessage(ErrorMessages.MSG005_EMAIL_PASSWORD_INCORRECT);
              break;
            default:
              setErrorMessage(ErrorMessages.MSG000_SYSTEM_ERROR);
              break;
          }
      }).catch(() => {
        setErrorMessage(ErrorMessages.MSG000_SYSTEM_ERROR);
      });
      setIsProcessing(false);
    }
  }

  if (isAuthenticated) {
    return <Redirect to={ADMIN_ROOT_ROUTE + "/user-search"} />;
  }else{
    return (
      <Container className="login-container">
        <ProcessLoader isShown={isProcessing} />
        <Card className="login-card">
          <h5 className="login-title">comu comu 管理画面</h5>
          <h6 className="login-error">{errorMessage}</h6>
  
          <form className="login-form" onSubmit={(e)=>handleSubmit(e)}>
            <FormGroup className="login-input" controlId="email">
              <FormLabel>メールアドレス</FormLabel>
              <FormControl
                size="text"
                autoFocus
                autoComplete="off"
                value={username}
                onChange={(e) => setUsername(e.target.value.trim())}
              />
            </FormGroup>
  
            <FormGroup className="login-input" controlId="password">
              <FormLabel>パスワード</FormLabel>
              <FormControl
                size="text"
                autoComplete="off"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormGroup>
  
            <Button
              className="login-button"
              disabled={disableForm()}
              onClick={(e)=>handleSubmit(e)}
              type="submit"
            >
              ログインする
            </Button>
          </form>
        </Card>
      </Container>
    );
  }
}

export default LoginScreen;
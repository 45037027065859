import React from "react";
import Modal from "react-modal";
import { View, Text, Image, TouchableOpacity } from "react-native";
import "./styles.scss";
import CloseIcon from "../../../includes/img/icons/iframe_modal_close.svg";
import styles from "./styles.native.js";
import { Media } from 'react-breakpoints';

const PostDetailModal = props => {

  let url = "";
  let relativePass = "";

  switch(props.confirmTitle){
    case "Newsfeed":
      relativePass = "./post/view/";
      break;
    case "MyPage":
      relativePass = "./post/view/";
      break;
    case "FriendPage":
      relativePass = "../post/view/";
      break;
    case "PastPost":
      relativePass = "../../post/view/past/";
      break;
    case "Search":                              //task#363　start
      relativePass = "../post/view/";
      break;
    case "SearchByDate":                       
      relativePass = "../../post/view/";
      break;                                  //task#363　end
  }

  url = relativePass + props.confirmationText; //task#363

  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        return (
          <View>
            <Modal
              ariaHideApp={false}
              isOpen={props.isModalShow}
              className="iframe-modal"
              overlayClassName="delete-modal-overlay"
              id={props.id || "confirmation"}
              onRequestClose={() => props.handleCloseModal()/**task#363 start**/}
            >
              <TouchableOpacity
                style={styles.modalClose}
                onPress={() => props.handleCloseModal()}
              >{/**task#363 start**/}
                <br/>
                <Image source={CloseIcon} alt="close-icon" style={styles.closeIcon} />
              </TouchableOpacity>{/**task#363 end**/}

              <div className="btn-container">
                <iframe src={url} className='btn-container'></iframe>
              </div>
            </Modal>
          </View>
        )
      }}
    </Media>
  );
};

export default PostDetailModal;

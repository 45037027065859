import React from "react";
import { View, Image } from "react-native";
import styles from "./styles/styles.native";
import { Media } from 'react-breakpoints'
import { isMobile } from "react-device-detect";

const SNSHeader = (props) => {
  return (
    <Media>
    {({ breakpoints, currentBreakpoint }) => {
      return(
        <>
          <View style={breakpoints[currentBreakpoint] < breakpoints.mobileLandscape || isMobile ? styles.headerContainer : styles.headerContainerPC}>
            <View>
              <a
                className={breakpoints[currentBreakpoint] < breakpoints.mobileLandscape || isMobile ? "sns-header-mobile" : "sns-header"}
                href={props.snsUrl}
                rel="noopener noreferrer"
                target="_blank" 
              >
                <Image source={props.snsIcon} style={styles.icon} />
                <span className="sns-header-text">{props.snsName + " " + props.snsAccount}</span>
              </a>     
            </View>
          </View>
        </>
      )
    }}
    </Media>
  );
};

export default SNSHeader;

import React, { Component } from 'react';
import { blockedUsersOperations } from './state';
import { View } from "react-native";
import { bindActionCreators } from "redux";
import { EMPTY_STRING, THUMBNAIL_SMALL, PROFILE_THUMBNAIL_BUCKET_URL } from "../../../utils/constants";
import UserListItemComponent from "../../components/UserListItemComponent";
import ErrorModal from "../../components/ErrorModal";
import { MSG_SYSTEM_ERROR } from "../../../utils/messages";
import { onApiSuccess, appendUserRoute, addDefaultSrc, defaultTopScroll } from '../../../utils/common';
import { connect } from "react-redux";
import "./styles/styles.scss"
import styles from "./styles/styles.native"
import UserListSkeleton from "../../components/Skeletons/UserListSkeleton";
import { ListItem } from "react-native-elements";
import { Media } from 'react-breakpoints'
import { isMobile } from "react-device-detect";

class BlockedUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
          userId: props.userId,
          userHandleName:props.handleName,
          userImg: props.profileImageUrl,
          currentPagination: 1,
          blockedUserList: [],
          numberOfBlockedUser: 0,
          isErrorModalShow: false,
          errorMessage: EMPTY_STRING,
          isLoading: true,
          hash: Date.now(),
          isLastPage : false
        };
    }

    componentDidMount(){
        defaultTopScroll();

        this.props.getBlockedUsers(this.state.userId, this.state.currentPagination).then(response=>{
            if(onApiSuccess(response)){
                let blockedUserList = JSON.parse(response.data.data)
                this.setState({
                    blockedUserList 
                },()=>{
                    this.props.countBlockedUsers(this.state.userId).then(response=>{
                        if(onApiSuccess(response)){
                            let numberOfBlockedUser = response.data.data
                            this.setState({
                                numberOfBlockedUser,
                                isLoading: false
                            })
                        }else{
                            this.setState({
                                isErrorModalShow: true,
                                errorMessage: MSG_SYSTEM_ERROR
                            })
                        }
                    })
                })
            }else{
                this.setState({
                    isErrorModalShow: true,
                    errorMessage: MSG_SYSTEM_ERROR
                })
            }
        })
    }

    handleSelectUser = (item) => {
        let userRoute = appendUserRoute(`/user/${item.userId}`)
        this.props.history.push({
            pathname: userRoute
        });
    }

    handlePagination = () => {
       if(!this.state.isLastPage){
            this.setState({
                currentPagination: this.state.currentPagination + 1
            }, ()=>{
                this.props.getBlockedUsers(this.state.userId, this.state.currentPagination).then(response=>{
                    if(onApiSuccess(response)){
                        let blockedUserList = JSON.parse(response.data.data)
                        if(0 < blockedUserList.length){
                            this.setState({
                                blockedUserList : [...this.state.blockedUserList, ...blockedUserList]
                            })
                        }else{
                            this.setState({
                                isLastPage: true
                            })
                        }
                    }else{
                        this.setState({
                            isErrorModalShow: true,
                            errorMessage: MSG_SYSTEM_ERROR
                        })
                    }
                })
            })
       }
    }

    handleCloseErrorModal = () => {
        this.setState({
          isErrorModalShow : false
        })
    }
    
    render() {
        return (
            <Media>
                {({ breakpoints, currentBreakpoint }) => {
                    let isMobileView = (breakpoints[currentBreakpoint] < breakpoints.mobileLandscape) || isMobile;
                    return(
                        <div className="user-container">
                            <View style={styles.listItemContainer}>
                            <ListItem
                                containerStyle={[styles.listItem, {height: isMobileView ? 52 : 55}]}
                                title={this.state.userHandleName}
                                titleStyle={isMobileView ? styles.titleMobile : styles.title}
                                leftElement={
                                    <img className="header-avatar" alt="user-avatar" src={PROFILE_THUMBNAIL_BUCKET_URL + this.state.userId + "/" + this.state.userId + THUMBNAIL_SMALL + `?${this.state.hash}`} onError={addDefaultSrc} />
                                }
                            />
                                <View style={styles.horizontalBorder} />
                            </View>
                            <View style={styles.flatlistContainer}>
                                {
                                    !this.state.isLoading ? 
                                    <>
                                        <p className="blocked-number-count">アクセスブロックした人 <span className="blocked-users-count">{this.state.numberOfBlockedUser}人</span></p>
                                        <UserListItemComponent
                                            {...this.state}
                                            list={this.state.blockedUserList}
                                            isCommunity={false}
                                            isUserList={true}
                                            handlePagination={this.handlePagination}
                                            handleSelectItem={this.handleSelectUser}
                                            hash={this.state.hash}
                                        /> 
                                    </>
                                    : <UserListSkeleton/>
                                }
                            </View>
                    
                            <ErrorModal
                            {...this.state}
                            handleCloseModal={this.handleCloseErrorModal}
                            customContent={null}
                            errorMessage={this.state.errorMessage}
                            />
                    </div>
                    )
                }}
            </Media>
    );
}
}

//Bind dispatch to action creators
const mapStateToProps = state => {
    return {
        userId: state.auth.credentials.userId,
        handleName: state.editprofile.profileDetails.handleName,
        profileImageUrl: state.auth.credentials.profileImageUrl,
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getBlockedUsers: blockedUsersOperations.getBlockedUsers,
            countBlockedUsers: blockedUsersOperations.countBlockedUsers,
        },
          dispatch
    );
      
export default connect(mapStateToProps, mapDispatchToProps)(BlockedUsers);

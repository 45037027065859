import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
    authorTouchableHeader : {
        flex: 1, 
        flexDirection: 'row' , 
        alignItems: 'center'
    }
})

export default styles;

export const profileForm = {
    model: "ProfileModel",
    form: [
        {
            type: "text",
            label: "ハンドルネーム",
            name: "handleName",
            default: "",
            customClass: "mb-1",
            maxLength: 128
        },
        {
            type: "custom-row",
            label: "名前",
            name: "nameRow",
            children: [
                {
                    type: "child-row",
                    name: "nameChildRow",
                    hideError: true,
                    children:  [
                        {
                            type: "custom-text-inline",
                            label: "姓",
                            name: "lastname",
                            default: "",
                            labelWidth: 2,
                            fieldWidth: 10,
                            customClass: "profile-firstname-margin mb-0",
                            maxLength: 50,
                            groupWidth: 6,
                        },
                        {
                            type: "custom-text-inline",
                            label: "名",
                            name: "firstname",
                            default: "",
                            labelWidth: 2,
                            fieldWidth: 10,
                            customClass: "profile-lastname-margin mb-0", 
                            maxLength: 50,
                            groupWidth: 6,
                        }
                    ]
                },
                {
                    type: "privacy-select",
                    name: "namePrivacy",//rename this to field key
                    default: 0, 
                }
            ]
        },
        {
            type: "custom-row",
            label: "性別",
            name: "genderRow",
            children: [
                {
                    type: "radio",
                    inline: true,
                    label: "",
                    name: "gender", //rename this to field key
                    default: "女性"
                },
                {
                    type: "privacy-select",
                    name: "genderPrivacy", //rename this to field key
                    default: 0, 
                }
            ]
        },
        {
            type: "custom-row",
            label: "血液型",
            name: "bloodtypeRow",
            children: [
                {
                    type: "radio",
                    inline: true,
                    label: "",
                    name: "bloodtype", //rename this to field key
                    default: "不明"
                },
                {
                    type: "privacy-select",
                    name: "bloodtypePrivacy", //rename this to field key
                    default: 0, 
                }
            ]
        },
        {
            type: "custom-row",
            label: "誕生年",
            name: "birthYearRow",
            children: [
                {
                    type: "custom-select-year",
                    name: "birthYear", //rename this to field key
                    start: 1950,
                    end: 2020,
                    width: 100,
                    suffix: "年",
                    default: " "
                },
                {
                    type: "privacy-select",
                    name: "birthYearPrivacy", //rename this to field key
                    default: 0, 
                }
            ]
        },
        {
            type: "custom-row",
            label: "誕生日",
            name: "birthMonthDayRow",
            children: [
                {
                    type: "child-row",
                    name: "birthdate",
                    children: [
                        {
                            type: "custom-select-inline",
                            name: "birthMonth", //rename this to field key
                            start: 1,
                            end: 12,
                            suffix: "月",
                            default: " ",
                            customClass: "mb-0"
                        },
                        {
                            type: "custom-select-inline",
                            name: "birthDay", //rename this to field key
                            start: 1,
                            end: 31,
                            suffix: "日",
                            default: " ",
                            customClass: "mb-0"
                        },
                    ]
                },
                {
                    type: "privacy-select",
                    name: "birthMonthDayPrivacy", //rename this to field key
                    default: 0, 
                }
            ]
        },
        {
            type: "custom-row",
            label: "現住所",
            name: "addressRow",
            hideError: true,
            children: [
                {
                    type: "custom-select-address",
                    name: "address1",
                    default: 0,
                    width: 120,
                    hideError: true,
                    smallMargin: true,
                },
                {
                    type: "child-row",
                    name: "address2ChildRow",
                    hideError: true,
                    children: [
                        {
                            type: "custom-text",
                            label: "市区町村",
                            name: "address2",
                            default: "",
                            addressRegex: true,
                            labelWidth: 4,
                            smallWidth: 12,
                            customClass: "profile-text-flex mb-3",
                            maxLength: 100,
                            hideError: true,
                        },
                    ]
                },
                {
                    type: "privacy-select",
                    name: "addressPrivacy", //rename this to field key
                    default: 0, 
                }
            ]
        },
        {
            type: "custom-row",
            label: "出身地",
            name: "birthplaceRow",
            hideError: true,
            children: [
                {
                    type: "custom-text",
                    name: "birthplace",
                    labelWidth: 0,
                    fieldWidth: 12,
                    default: "",
                    maxLength: 100,
                    addressRegex: true,
                    hideError: true,
                    customClass: "mb-3"
                },
                {
                    type: "privacy-select",
                    name: "birthplacePrivacy", //rename this to field key
                    default: 0, 
                    hideError: true,
                }
            ]
        },
        {
            type: "custom-row",
            label: "趣味",
            name: "hobbyRow",
            hideError: true,
            children: [
                {
                    type: "custom-select-hobby",
                    name: "hobby1",
                    default: 1,
                    width: 400,
                    customClass: "profile-hobby-select"
                },
                {
                    type: "custom-select-hobby",
                    name: "hobby2",
                    default: 1,
                    width: 400,
                    customClass: "profile-hobby-select"
                },
                {
                    type: "custom-select-hobby",
                    name: "hobby3",
                    default: 1,
                    width: 400,
                    customClass: "profile-hobby-select"
                },
                {
                    type: "privacy-select",
                    name: "hobbyPrivacy",
                    default: 0, 
                }
            ]
        },
        {
            type: "custom-row",
            label: "職業",
            name: "occupationRow",
            hideError: true,
            children: [
                {
                    type: "custom-text",
                    name: "occupation",
                    labelWidth: 0,
                    fieldWidth: 12,
                    default: "",
                    maxLength: 50,
                    hideError: true,
                    customClass: "mb-3"
                },
                {
                    type: "privacy-select",
                    name: "occupationPrivacy", //rename this to field key
                    default: 0, 
                }
            ]
        },
        {
            type: "textarea",
            label: "自己紹介",
            name: "bio",
            rows: "10",
            default: "",
            hideError: true,
        },
    ]
}

export const radioInput = [
    {value: "女性", label: "女性", name: "gender" }, //should match name para mahibaw-an asa siya na input i render
    {value: "男性", label: "男性", name: "gender" },

    {value: "A", label: "A", name: "bloodtype" },
    {value: "B", label: "B", name: "bloodtype" },
    {value: "O", label: "O", name: "bloodtype" },
    {value: "AB", label: "AB", name: "bloodtype" },
    {value: "不明", label: "不明", name: "bloodtype" },
]

export const prefectureOptions = [
    {value: "0", label: "都道府県", name: "address1"},
    {value: "1", label: "北海道", name: "address1"},
    {value: "2", label: "青森県", name: "address1"},
    {value: "3", label: "岩手県", name: "address1"},
    {value: "4", label: "宮城県", name: "address1"},
    {value: "5", label: "秋田県", name: "address1"},
    {value: "6", label: "山形県", name: "address1"},
    {value: "7", label: "福島県", name: "address1"},
    {value: "8", label: "茨城県", name: "address1"},
    {value: "9", label: "栃木県", name: "address1"},
    {value: "10", label: "群馬県", name: "address1"},
    {value: "11", label: "埼玉県", name: "address1"},
    {value: "12", label: "千葉県", name: "address1"},
    {value: "13", label: "東京都", name: "address1"},
    {value: "14", label: "神奈川県", name: "address1"},
    {value: "15", label: "新潟県", name: "address1"},
    {value: "16", label: "富山県", name: "address1"},
    {value: "17", label: "石川県", name: "address1"},
    {value: "18", label: "福井県", name: "address1"},
    {value: "19", label: "山梨県", name: "address1"},
    {value: "20", label: "長野県", name: "address1"},
    {value: "21", label: "岐阜県", name: "address1"},
    {value: "22", label: "静岡県", name: "address1"},
    {value: "23", label: "愛知県", name: "address1"},
    {value: "24", label: "三重県", name: "address1"},
    {value: "25", label: "滋賀県", name: "address1"},
    {value: "26", label: "京都府", name: "address1"},
    {value: "27", label: "大阪府", name: "address1"},
    {value: "28", label: "兵庫県", name: "address1"},
    {value: "29", label: "奈良県", name: "address1"},
    {value: "30", label: "和歌山県", name: "address1"},
    {value: "31", label: "鳥取県", name: "address1"},
    {value: "32", label: "島根県", name: "address1"},
    {value: "33", label: "岡山県", name: "address1"},
    {value: "34", label: "広島県", name: "address1"},
    {value: "35", label: "山口県", name: "address1"},
    {value: "36", label: "徳島県", name: "address1"},
    {value: "37", label: "香川県", name: "address1"},
    {value: "38", label: "愛媛県", name: "address1"},
    {value: "39", label: "高知県", name: "address1"},
    {value: "40", label: "福岡県", name: "address1"},
    {value: "41", label: "佐賀県", name: "address1"},
    {value: "42", label: "長崎県", name: "address1"},
    {value: "43", label: "熊本県", name: "address1"},
    {value: "44", label: "大分県", name: "address1"},
    {value: "45", label: "宮崎県", name: "address1"},
    {value: "46", label: "鹿児島県", name: "address1"},
    {value: "47", label: "沖縄県", name: "address1"},
]

export const hobbyOptions = [
    {value: "2", label: "映画鑑賞", name: "hobby"},
    {value: "3", label: "音楽鑑賞", name: "hobby"},
    {value: "4", label: "スポーツ", name: "hobby"},
    {value: "5", label: "スポーツ観戦", name: "hobby"},
    {value: "6", label: "カラオケ", name: "hobby"},
    {value: "7", label: "バンド", name: "hobby"},
    {value: "8", label: "料理", name: "hobby"},
    {value: "9", label: "グルメ", name: "hobby"},
    {value: "10", label: "お酒", name: "hobby"},
    {value: "11", label: "ショッピング", name: "hobby"},
    {value: "12", label: "ファッション", name: "hobby"},
    {value: "13", label: "アウトドア", name: "hobby"},
    {value: "14", label: "ドライブ", name: "hobby"},
    {value: "15", label: "旅行", name: "hobby"},
    {value: "16", label: "アート", name: "hobby"},
    {value: "17", label: "習いごと", name: "hobby"},
    {value: "18", label: "語学", name: "hobby"},
    {value: "19", label: "読書", name: "hobby"},
    {value: "20", label: "マンガ", name: "hobby"},
    {value: "21", label: "テレビ", name: "hobby"},
    {value: "22", label: "ゲーム", name: "hobby"},
    {value: "23", label: "ギャンブル", name: "hobby"},
    {value: "24", label: "ペット", name: "hobby"},
    {value: "25", label: "美容", name: "hobby"},
    {value: "26", label: "ダイエット", name: "hobby"},
]
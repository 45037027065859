import React from 'react';
import ContentLoader from 'react-content-loader'
import { Card } from 'react-bootstrap';

const PostViewSkeleton = () => {
    return (
        <Card className="p-3 mb-4">
            <ContentLoader
                speed={2}
                width={'100%'}
                height={175}
                
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
            >

                <rect x="48" y="8" width="140" height="10" rx="3" />
                <rect x="48" y="24" width="120" height="8" rx="3" />
                <rect x="4" y="50" width="1000" height="10" rx="3" />
                <rect x="4" y="68" width="1000" height="10" rx="3" />
                <rect x="4" y="86" width="1000" height="10" rx="3" />
                <circle cx="20" cy="20" r="20" />
            </ContentLoader>
        </Card>
    );
};

export default PostViewSkeleton;
import React from 'react';
import { Media } from 'react-breakpoints'
import { isMobile } from "react-device-detect";

const MobileOnlyView = (props) => {
    return (
        <Media>
            {({ breakpoints, currentBreakpoint }) => {
                if (breakpoints[currentBreakpoint] < breakpoints.mobileLandscape || isMobile) {
                    return props.children;
                } else {
                    return "";
                }
            }}
        </Media>
    );
};

export default MobileOnlyView;
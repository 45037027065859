import MainContainer from "./MainContainer";

export const APP_ROOT_ROUTE = "/app";
export const ROOT = "/";

//Parent User Route
export const routes = [
    {
        path: ROOT,
        component: MainContainer,
        exact: true,
        showNav: true
    },
    {
        path: APP_ROOT_ROUTE,
        component: MainContainer,
        exact: false,
        showNav: true
    }
]
import * as types from "./types";

export const updateNotificationsList = data => ({
    type: types.UPDATE_NOTIFICATIONS_LIST,
    payload: data
});

export const appendNotificationsList = data => ({
    type: types.APPEND_NOTIFICATIONS_LIST,
    payload: data
});

export const removeRequestNotification = data => ({
    type: types.REMOVE_REQUEST_NOTIFICATION,
    payload: data
});

export const addReceivedNotification = data => ({
    type: types.ADD_RECEIVED_NOTIFICATION,
    payload: data
});

export const decrementNotification = () => ({
    type: types.DECREMENT_NOTIF_COUNT
});

export const setSeenNotification = notificationId => ({
    type: types.SET_SEEN_NOTIFICATION,
    payload: notificationId
});


import React from 'react';
import { VIMEO_PLAYER_URL } from '../../../config/settings';
import './styles.scss';

const RenderLivestream = ({ vimeoLivestreamId }) => {
    return (
        <div className="post-view-video-container">
            <iframe
                title={vimeoLivestreamId}
                src={`${VIMEO_PLAYER_URL}/video/${vimeoLivestreamId}?title=0&byline=0&portrait=0&loop=1&autopause=1`}
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
                className="post-view-video-content"
            >
            </iframe>
        </div>
    )
}

export default RenderLivestream;
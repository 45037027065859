import React, { Component } from "react";
import InstagramIcon from "../../../includes/img/user-menu/instagram.svg";
import { FlatList, View } from "react-native";
import SNSHeader from "./SNSHeader";
import styles from "./styles/styles.native";
import "./styles/styles.scss"
import { SNS_INSTAGRAM_URL } from "../../../config/settings";
import { snsOperations } from "./state";
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import {onApiSuccess, isNullOrUndefined, defaultTopScroll } from "../../../utils/common";
import ErrorModal from "../../components/ErrorModal";
import { EMPTY_STRING, API_LIMIT_REACHED_CODE } from "../../../utils/constants";
import { SYSTEM_ERROR, EXCEED_INSTAGRAM_API_CALL_LIMIT } from "../../../utils/messages";
import InstagramSkeleton from "../../components/Skeletons/InstagramSkeleton";
import { Media } from 'react-breakpoints'
import { isMobile } from "react-device-detect";

class Instagram extends Component {
    constructor(props){
        super(props)
        this.state = {
            feed: [],
            accessToken: null,
            nextURL: null,
            errorMessage: null,
            isErrorModalShow: false,
            isFeedFetching: true,
            snsImageUrl: InstagramIcon,
            snsName: "Instagram",
            snsAccount: "@fumiya_fujii_ff"
        }
    }

    componentDidMount(){
        defaultTopScroll();

        this.props.getInstagramAccessToken().then(response=>{
            if(onApiSuccess(response)){
                let data = JSON.parse(response.data.data)
                this.setState({
                    accessToken: data[0].token
                }, ()=>{
                    this.props.getInstagramFeed(this.state.accessToken).then(response=>{
                        if(isNullOrUndefined(response.error)){
                            let feed = response.data.data;
                            let nextURL = response.data.paging.next;
                            this.setState({
                                feed,
                                nextURL,
                                isFeedFetching: false
                            }, ()=>{
                                let feed = this.handleFormatData(this.state.feed)
                                this.setState({
                                  feed
                                })
                            })
                        }else if(!isNullOrUndefined(response.error) &&  API_LIMIT_REACHED_CODE  === response.error.code){
                            this.setState({
                                isErrorModalShow: true,
                                errorMessage: EXCEED_INSTAGRAM_API_CALL_LIMIT
                            })
                        }else{
                            this.setState({
                                isErrorModalShow: true,
                                errorMessage: SYSTEM_ERROR
                            })
                        }
                    })
               
                })
            }else{
                this.setState({
                    isErrorModalShow: true,
                    errorMessage: SYSTEM_ERROR
                })
            }
        })

    }

    handleCloseModal = () =>{
        this.setState({
            isErrorModalShow: false,
            errorMessage: EMPTY_STRING
        })
    }

    handleFormatData = (data) => {
        const numberOfFullRows = Math.floor(data.length / 3);
        let numberOfElementsLastRow = data.length - numberOfFullRows * 3;
        while (3 !== numberOfElementsLastRow  && 0 !== numberOfElementsLastRow) {
            data.push({ key: `blank-${numberOfElementsLastRow}`, empty: true });
            numberOfElementsLastRow++;
        }
        return data;
    };

    handlePagination = () => {
        if(!isNullOrUndefined(this.state.nextURL)){
            this.props.nextFeedImages(this.state.nextURL).then(response=>{
                if(isNullOrUndefined(response.error)){
                    let feed = response.data.data;
                    let prevFeed = this.state.feed.filter(item => !item.empty)
                    let nextURL = response.data.paging.next;
                    this.setState({
                        feed : [...prevFeed, ...feed],
                        nextURL,
                        isFeedFetching: false
                    }, ()=>{
                         let feed = this.handleFormatData(this.state.feed)
                          this.setState({
                            feed
                          })
                    })
                }else if(!isNullOrUndefined(response.error) &&  API_LIMIT_REACHED_CODE  === response.error.code){
                    this.setState({
                        isErrorModalShow: true,
                        errorMessage: EXCEED_INSTAGRAM_API_CALL_LIMIT
                    })
                }
                else{
                    this.setState({
                        isErrorModalShow: true,
                        errorMessage: SYSTEM_ERROR
                    })
                }
            })
        }
    }

    componentWillUnmount() {
        document.body.style.overflowY = 'unset';
    }
    

    render() {
        return (
            <Media>
            {({ breakpoints, currentBreakpoint }) => {
                let isDeviceMobile = (breakpoints[currentBreakpoint] < breakpoints.mobileLandscape) || isMobile
                return(
                    <div className="sns-container">
                    <SNSHeader
                        snsUrl={SNS_INSTAGRAM_URL}
                        snsName={this.state.snsName}
                        snsIcon={this.state.snsImageUrl}
                        snsAccount={this.state.snsAccount}
                    />
                    {
                        !this.state.isFeedFetching ? 
                        <View style={styles.flatlistContainer}>
                        <FlatList
                        data={this.state.feed}
                        style={isDeviceMobile ? styles.instagramContainerMobile :  styles.instagramContainerPC }
                        renderItem={({ item }) => (
                            <View style={styles.flatlistContainer}>
                                {!isNullOrUndefined(item.media_url) ?  
                                    <a
                                    className="sns-header"
                                    href={SNS_INSTAGRAM_URL}
                                    rel="noopener noreferrer"
                                    target="_blank">
                                        {(item.media_url.includes(".mp4")||item.media_url.includes(".mov")) ?
                                            <video
                                                alt={item.id}
                                                className={breakpoints[currentBreakpoint] < breakpoints.mobileLandscape || isMobile ? "ig-image-mobile" : "ig-image-pc"}
                                                src={item.media_url}
                                                autoPlay loop muted playsInline
                                            />
                                        :   
                                            <img
                                                alt={item.id}
                                                className={breakpoints[currentBreakpoint] < breakpoints.mobileLandscape || isMobile ? "ig-image-mobile" : "ig-image-pc"}
                                                src={item.media_url}
                                            />
                                        }
                                    </a>
                                : null
                                }
                            </View>
                        )}
                        numColumns={3}
                        onEndReachedThreshold={.5}
                        onEndReached={()=>this.handlePagination()}
                        keyExtractor={(item, index) => index.toString()}
                        />         
                        </View>          
                        : <InstagramSkeleton/>
                    }
                    
                    <ErrorModal
                        {...this.state}
                        handleCloseModal = {this.handleCloseModal}
                        customContent={null}
                        errorMessage={this.state.errorMessage}
                    />
    
                </div>
                )
            }}
            </Media>
        );
    }
    }

//Bind dispatch to action creator
  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        getInstagramAccessToken: snsOperations.getInstagramAccessToken,
        getInstagramFeed: snsOperations.getInstagramFeed,
        nextFeedImages : snsOperations.nextFeedImages
      },
      dispatch
    );
  
  export default connect(null, mapDispatchToProps)(Instagram);



import ChatList from "./ChatList";
import NewChat from "./NewChat";
import ChatConversation from "./ChatConversation";

export const routes = [
    {
        path: "/chat",
        component: ChatList,
        exact: true,
        showNav: true
    },
    {
        path: "/newchat",
        component: NewChat,
        exact: true,
        showNav: true
    },
    {
        path: "/chatmessages/:id",
        component: ChatConversation,
        exact: true,
        showNav: true
    } 
]
import React from 'react';
import { Image } from 'react-bootstrap';
import DefaultIcon from '../../../includes/img/placeholders/user.svg';
import moment from 'moment';
import './styles/header.scss';
import { DATETIME_FORMAT } from '../../../utils/constants';

const HandleName = (props) => {
    let {handleName } = props;
    return (
        <span className="author-name">{handleName}</span>
    )
}
const AuthorHeader = (props) => {

    let isValidCreateDt = moment(new Date(props.createDt)).isValid();
    let isProfileImageUrlValid = props.pageImageUrl;

    return (
        <div className="author-container">
            <React.Fragment>
                    <Image src={isProfileImageUrlValid ? props.pageImageUrl : DefaultIcon} style={{ width: 36, height: 36, objectFit: 'cover' }} roundedCircle />
                <div className="d-flex flex-column ml-2">
                    <HandleName handleName={props.pageName}/>
                    <span className="author-datetime">
                        {props.stringCreateDt ? props.stringCreateDt : (isValidCreateDt && moment(new Date(props.createDt)).format(DATETIME_FORMAT))}
                    </span>
                </div>
            </React.Fragment>
        </div>
    );


};

export default AuthorHeader;
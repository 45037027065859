import * as Path from "./apiRoutes";
import ApiService from "../../../../utils/apiService";
import * as Actions from "./actions";
import * as AuthActions from "../../Authentication/state/actions";
import { onApiSuccess } from "../../../../utils/common";
import { WEBSOCKET_ACTIONS } from "../../../../utils/constants";

const getNotifications = (userId, currentPagination) => dispatch =>{
    return ApiService.get(Path.GET_NOTIFICATIONS + `/${userId}/${currentPagination}`)
    .then(response => {
        if(onApiSuccess(response)){
            let data = JSON.parse(response.data.data);
            if(1 === currentPagination){
                dispatch(Actions.updateNotificationsList(data));
            }else{
                dispatch(Actions.appendNotificationsList(data));
            }
            
        }
      return response;
    })
    .catch(error => {
      console.error(error);
    });
}

const updateNotificationStatus = (notificationId) => (dispatch) => {
  ApiService.get(Path.UPDATE_NOTIFICATION_STATUS + `/${notificationId}`)
  dispatch(Actions.setSeenNotification(notificationId))
};

const acceptFriendRequest = (params, ws) => () => {
    ws.send(
        JSON.stringify({
            action: WEBSOCKET_ACTIONS.onAcceptRequest,
            type: WEBSOCKET_ACTIONS.onAcceptRequest,
            data: {
                ...params
            },
        })
    );
}

const removeFriendRequest = (params, ws) => () => {
    ws.send(
        JSON.stringify({
            action: WEBSOCKET_ACTIONS.onRemoveRequest,
            type: WEBSOCKET_ACTIONS.onRemoveRequest,
            data: {
                ...params
            },
        })
    );
}
  
const removeRequestNotification = (notificationId) => dispatch => {
    dispatch(Actions.removeRequestNotification(notificationId))
}

const decrementNotification = () => dispatch => {
    dispatch(Actions.decrementNotification())
}

const addReceivedNotification = (params) => dispatch => {
    dispatch(Actions.addReceivedNotification(params))
}

const setLastSeen = (userId) => dispatch => {
    ApiService.post(Path.SET_LAST_SEEN + `/${userId}`)
    //Clear new notif count
    dispatch(AuthActions.updateNotifCount(0))
    dispatch(AuthActions.clearNewNotifReferences())
}

export{
    getNotifications,
    updateNotificationStatus,
    acceptFriendRequest,
    removeFriendRequest,
    removeRequestNotification,
    addReceivedNotification,
    setLastSeen,
    decrementNotification
}
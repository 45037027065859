import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  createInput: {
    paddingHorizontal: 16,
    paddingVertical: 6.5,
    width: "100%",
    fontSize: 16,
    borderWidth: 1,
    borderColor: "#DCDCDC",
    borderRadius: 50
  }
});

export default styles;

import * as CommonSelectors from '../../../components/Validations/CommonSelectors';
import * as ErrorMessages from '../../../../utils/messages/admin/errorMessages';
import * as CommonValidation from '../../../../utils/common';

const validateNotification = (values) => {
    let errors = {};

    if(!CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.endYear)
    && !CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.endMonth)
    && !CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.endDay)){
        const validate_date = new Date(values.NotificationModel.endYear,
                                    Number(values.NotificationModel.endMonth) - 1, 
                                    values.NotificationModel.endDay
                                );

        if(validate_date.getFullYear() !== Number(values.NotificationModel.endYear)
            || validate_date.getMonth() + 1 !== Number(values.NotificationModel.endMonth)
            || validate_date.getDate() !== Number(values.NotificationModel.endDay)){
            errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG042_INVALID_DISPLAY_END_DATE;
        }
    }

    if(!CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.startYear)
    && !CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.startMonth)
    && !CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.startDay)){
        const validate_date = new Date(values.NotificationModel.startYear,
                                    Number(values.NotificationModel.startMonth) - 1, 
                                    values.NotificationModel.startDay
                                );
        
        if(validate_date.getFullYear() !== Number(values.NotificationModel.startYear)
            || validate_date.getMonth() + 1 !== Number(values.NotificationModel.startMonth)
            || validate_date.getDate() !== Number(values.NotificationModel.startDay)){
            errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG035_INVALID_DISPLAY_START_DATE;
        }
    }

    if(!CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.createdYear)
    && !CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.createdMonth)
    && !CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.createdDay)){
        const validate_date = new Date(values.NotificationModel.createdYear,
                                    Number(values.NotificationModel.createdMonth) - 1, 
                                    values.NotificationModel.createdDay
                                );

        if(validate_date.getFullYear() !== Number(values.NotificationModel.createdYear)
            || validate_date.getMonth() + 1 !== Number(values.NotificationModel.createdMonth)
            || validate_date.getDate() !== Number(values.NotificationModel.createdDay)){
            errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG028_INVALID_CREATED_DATE;
        }
    }

    else if(CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.createdYear)){
        // Created Year is empty
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG010_EMPTY_CREATED_YEAR;
    }

    else if(CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.createdMonth)){
        // Created Month is empty
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG011_EMPTY_CREATED_MONTH;
    }

    else if(CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.createdDay)){
        // Created Day is empty
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG012_EMPTY_CREATED_DAY;
    }

    else if(CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.title)){
        // Title is empty
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG013_EMPTY_ADMIN_NOTICE_TITLE;
    }

    else if(CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.body)){
        // Body is empty
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG014_EMPTY_ADMIN_NOTICE_BODY;
    }

    else if(CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.isDisplayed)){
        // No display type is selected
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG015_MISSING_DISPLAY_TYPE;
    }

    else if(CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.startYear)
    && (!CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.startMonth)
    || !CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.startDay))){
        // Display Start Year is empty
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG016_EMPTY_DISPLAY_START_YEAR;
    }

    else if(CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.startMonth) 
    && (!CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.startYear)
    || !CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.startDay))){
        // Display Start Month is empty
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG017_EMPTY_DISPLAY_START_MONTH;
    }

    else if(CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.startDay)
    && (!CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.startYear)
    || !CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.startMonth))){
        // Display Start Day is empty
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG018_EMPTY_DISPLAY_START_DAY;
    }

    else if(CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.endYear)
    && (!CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.endMonth)
    || !CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.endDay))){
        // Display End Year is empty
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG019_EMPTY_DISPLAY_END_YEAR;
    }

    else if(CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.endMonth)
    && (!CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.endYear)
    || !CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.endDay))){
        // Display End Month is empty
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG020_EMPTY_DISPLAY_END_MONTH;
    }

    else if(CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.endDay)
    && (!CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.endYear)
    || !CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.endMonth))){
        // Display End Day is empty
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG021_EMPTY_DISPLAY_END_DAY;
    }
    
    else if(CommonSelectors.isThereDoubleByte(values.NotificationModel.createdYear)){
        // Created Year has double-byte numeric characters
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG022_CREATED_YEAR_HAS_DOUBLE_BYTE;
    }

    else if(CommonSelectors.isThereDoubleByte(values.NotificationModel.createdMonth)){
        // Created Month has double-byte numeric characters
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG023_CREATED_MONTH_HAS_DOUBLE_BYTE;
    }

    else if(CommonSelectors.isThereDoubleByte(values.NotificationModel.createdDay)){
        // Created Day has double-byte numeric characters
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG024_CREATED_DAY_HAS_DOUBLE_BYTE;
    }
    
    else if(CommonSelectors.isThereNonDigit(values.NotificationModel.createdYear) 
    || (CommonSelectors.isInvalidYear(values.NotificationModel.createdYear))){
        // Created Year has invalid characters
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG025_INVALID_CREATED_YEAR;
    }

    else if(CommonSelectors.isThereNonDigit(values.NotificationModel.createdMonth)){
        // Created Month has invalid characters
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG026_INVALID_CREATED_MONTH;
    }

    else if(CommonSelectors.isThereNonDigit(values.NotificationModel.createdDay)){
        // Created Day has invalid characters
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG027_INVALID_CREATED_DAY;
    }

    else if(CommonSelectors.isThereNonDigit(values.NotificationModel.createdYear) || CommonSelectors.isThereNonDigit(values.NotificationModel.createdMonth) || CommonSelectors.isThereNonDigit(values.NotificationModel.createdDay)){
        // Created Date is invalid
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG028_INVALID_CREATED_DATE;
    }

    else if(CommonSelectors.isThereDoubleByte(values.NotificationModel.startYear)){
        // Display Start Year has double-byte numeric characters
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG029_DISPLAY_START_YEAR_HAS_DOUBLE_BYTE;
    }

    else if(CommonSelectors.isThereDoubleByte(values.NotificationModel.startMonth)){
        // Display Start Month has double-byte numeric characters
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG030_DISPLAY_START_MONTH_HAS_DOUBLE_BYTE;
    }

    else if(CommonSelectors.isThereDoubleByte(values.NotificationModel.startDay)){
        // Display Start Day has double-byte numeric characters
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG031_DISPLAY_START_DAY_HAS_DOUBLE_BYTE;
    }
    
    else if(CommonSelectors.isThereNonDigit(values.NotificationModel.startYear)
    && !CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.startYear)){
        // Display Start Year has invalid characters
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG032_INVALID_DISPLAY_START_YEAR;
    }

    else if(CommonSelectors.isThereNonDigit(values.NotificationModel.startMonth)
    && !CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.startMonth)){
        // Display Start Month has invalid characters
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG033_INVALID_DISPLAY_START_MONTH;
    }

    else if(CommonSelectors.isThereNonDigit(values.NotificationModel.startDay)
    && !CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.startDay)){
        // Display Start Day has invalid characters
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG034_INVALID_DISPLAY_START_DAY;
    }

    else if((!CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.startYear)
    && !CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.startMonth)
    && !CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.startDay)) 
    && (CommonSelectors.isThereNonDigit(values.NotificationModel.startYear) 
    || (CommonSelectors.isInvalidYear(values.NotificationModel.startYear))
    || CommonSelectors.isThereNonDigit(values.NotificationModel.startMonth) 
    || CommonSelectors.isThereNonDigit(values.NotificationModel.startDay))){
        // Display Start Date is invalid
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG035_INVALID_DISPLAY_START_DATE;
    }

    else if(CommonSelectors.isThereDoubleByte(values.NotificationModel.endYear)){
        // Display End Year has double-byte numeric characters
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG036_DISPLAY_END_YEAR_HAS_DOUBLE_BYTE;
    }

    else if(CommonSelectors.isThereDoubleByte(values.NotificationModel.endMonth)){
        // Display End Month has double-byte numeric characters
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG037_DISPLAY_END_MONTH_HAS_DOUBLE_BYTE;
    }

    else if(CommonSelectors.isThereDoubleByte(values.NotificationModel.endDay)){
        // Display End Day has double-byte numeric characters
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG038_DISPLAY_END_DAY_HAS_DOUBLE_BYTE;
    }
    
    else if(CommonSelectors.isThereNonDigit(values.NotificationModel.endYear)
    && !CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.endYear)){
        // Display End Year has invalid characters
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG039_INVALID_DISPLAY_END_YEAR;
    }

    else if(CommonSelectors.isThereNonDigit(values.NotificationModel.endMonth)
    && !CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.endMonth)){
        // Display End Month has invalid characters
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG040_INVALID_DISPLAY_END_MONTH;
    }

    else if(CommonSelectors.isThereNonDigit(values.NotificationModel.endDay)
    && !CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.endDay)){
        // Display End Day has invalid characters
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG041_INVALID_DISPLAY_END_DAY;
    }

    else if((!CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.endYear)
    && !CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.endMonth)
    && !CommonValidation.isStringNullEmptyOrUndefined(values.NotificationModel.endDay)) 
    && (CommonSelectors.isThereNonDigit(values.NotificationModel.endYear) 
    || (CommonSelectors.isInvalidYear(values.NotificationModel.endYear))
    || CommonSelectors.isThereNonDigit(values.NotificationModel.endMonth) 
    || CommonSelectors.isThereNonDigit(values.NotificationModel.endDay))){
        // Display End Date is invalid
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG042_INVALID_DISPLAY_END_DATE;
    }

    else if(new Date(`${values.NotificationModel.endYear}/${values.NotificationModel.endMonth}/${values.NotificationModel.endDay}`) < new Date(`${values.NotificationModel.startYear}/${values.NotificationModel.startMonth}/${values.NotificationModel.startDay}`))
    {
        // Display date duration is invalid (End date is earlier than start date)
        errors[`NotificationModel.topErrorMessage`] = ErrorMessages.MSG043_INVALID_DISPLAY_DATE_DURATION;
    }

    return errors;
}

export default {
    validateNotification,
}
import * as types from "./types"

const defaultState = {
    profileDetails: [],
};

export default function reducer(state = defaultState, action) {
    switch(action.type) {
        case types.SET_PROFILE_DETAILS:
            return {
                ...state,
                profileDetails: action.payload
            };
        default: return state;
    }
};
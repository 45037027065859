import React, { Component } from 'react'; 
import TableTemplate from '../../components/TableTemplate';
import { notificationsTable } from './state/templates';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AdminCard from '../../components/AdminCard';
import PaginationTemplate from '../../components/PaginationTemplate';
import { notifOperations } from './state';
import ErrorModal from '../../components/ErrorModal';
import { Button } from "react-bootstrap";
import './styles/notificationManagement.scss';
import { formatYYYMMDD, onApiSuccess, isStringNullEmptyOrUndefined, getObjKeyIdString, defaultTopScroll } from '../../../utils/common';
import { MSG000_SYSTEM_ERROR, MSG009_SYS_ERR_SAVING_RECORD } from '../../../utils/messages/admin/errorMessages';
import NotificationModal from './NotificationModal';
import ProcessLoader from '../../components/ProcessLoader';

class NotificationList extends Component {

    state = {
        currentPage: 1,
        totalPage: 0,
        isModalShow: false,
        isCreate: false,
        currNotif: {},
        prevNotif: {},
        modalType: '',
        prevModal: '',
        isErrorModalShow: false,
        totalRecords: 0,
        isProcessing: false,
        errorMessage: '',
        initialLoad: true
    }

    // On page refresh, scroll to top
    constructor(){
        super();
        window.onbeforeunload = (e) => {
            defaultTopScroll();
        };
    }

    componentDidMount() {
        defaultTopScroll();
        this.handlePagination(1);
        this.setState({
            initialLoad: false
        })
    }

    handlePagination = (currentPagination) => {
        if(this.state.currentPage === currentPagination && !this.state.initialLoad){
            return
        }
        this.setState({ isProcessing: true }, ()=> {
            this.props.getAllAdminNotice(currentPagination)
            .then(res => {
                if (onApiSuccess(res)) {
                    let responseData = JSON.parse(res.data.data);
                    this.setState({
                        currentPage: currentPagination,
                        totalPage: responseData.totalPages,
                        totalRecords: responseData.totalRecords,
                        isProcessing: false,
                    });
                } else {
                    this.toggleErrorModal(MSG000_SYSTEM_ERROR);
                }
            })
            .catch(() => this.toggleErrorModal(MSG000_SYSTEM_ERROR));
        });
    }

    toggleErrorModal = (errorMessage='') => {
        this.setState(prevState => ({ isProcessing: false , errorMessage: errorMessage, isErrorModalShow: !prevState.isErrorModalShow }));
    }

    handlePageChange = (page) => {
        //API Call
        //Update page
        this.handlePagination(page);
        this.setState({ currentPage: page })
    }

    handleCreateNotif = () => {
        this.setState({ prevModal: '', isModalShow: true, modalType: 'create', isCreate: true });
    }

    handleShowEditModal = () => {
        if (this.state.isCreate) {
            this.setState({ prevModal: 'confirm', modalType: 'create', isCreate: true });
        }
        else {
            this.setState({ prevModal: 'confirm', modalType: 'update', isCreate: false });
        }
    }

    handleConfirmClick = (values) => {
        if (this.state.isCreate) {
            this.setState({ currNotif: values.NotificationModel, modalType: 'confirmUpdate' });
        } else {
            this.handleUpdateFields(values);
            this.setState({ modalType: 'confirmUpdate' });
        }
    }

    handleUpdateFields = (value) => {
        let updatedCurrNotif = JSON.parse(JSON.stringify(this.state.currNotif));

        updatedCurrNotif.createdDate = formatYYYMMDD(value.NotificationModel.createdYear, value.NotificationModel.createdMonth, value.NotificationModel.createdDay);
        updatedCurrNotif.createViewDt = updatedCurrNotif.createdDate;
        updatedCurrNotif.createdYear = value.NotificationModel.createdYear;
        updatedCurrNotif.createdMonth = value.NotificationModel.createdMonth;
        updatedCurrNotif.createdDay = value.NotificationModel.createdDay;

        updatedCurrNotif.title = value.NotificationModel.title;
        updatedCurrNotif.body = value.NotificationModel.body;
        updatedCurrNotif.isDisplayed = parseInt(value.NotificationModel.isDisplayed) ? '表示' : '非表示';

        if(isStringNullEmptyOrUndefined(value.NotificationModel.startYear) 
            && isStringNullEmptyOrUndefined(value.NotificationModel.startMonth)
            && isStringNullEmptyOrUndefined(value.NotificationModel.startDay)) {
                updatedCurrNotif.startDate = ""
        } else {
            updatedCurrNotif.startDate = formatYYYMMDD(value.NotificationModel.startYear, value.NotificationModel.startMonth, value.NotificationModel.startDay);
        }
        updatedCurrNotif.startYear = value.NotificationModel.startYear;
        updatedCurrNotif.startMonth = value.NotificationModel.startMonth;
        updatedCurrNotif.startDay = value.NotificationModel.startDay;

        if(isStringNullEmptyOrUndefined(value.NotificationModel.endYear) 
            && isStringNullEmptyOrUndefined(value.NotificationModel.endMonth)
            && isStringNullEmptyOrUndefined(value.NotificationModel.endDay)) {
                updatedCurrNotif.endDate = ""
        } else {
            updatedCurrNotif.endDate = formatYYYMMDD(value.NotificationModel.endYear, value.NotificationModel.endMonth, value.NotificationModel.endDay);
        }

        updatedCurrNotif.endYear = value.NotificationModel.endYear;
        updatedCurrNotif.endMonth = value.NotificationModel.endMonth;
        updatedCurrNotif.endDay = value.NotificationModel.endDay;

        this.setState({ currNotif: updatedCurrNotif });
    }

    handleUpdateIsDisplayed = (value) => {
        let updatedNotif = JSON.parse(JSON.stringify(this.state.currNotif));
        updatedNotif.isDisplayed = parseInt(value) ? '表示' : '非表示';
        this.setState({ currNotif: updatedNotif });
    }

    handleUpdateSuccess = () => {
        this.setState({ isProcessing: true }, () => {
            if (this.state.isCreate) {
                this.props.createAdminNotice(this.state.currNotif)
                    .then(res => {
                        if (onApiSuccess(res)) {
                            this.setState(prevState => ({totalRecords: prevState.totalRecords + 1}))
                            let responseData = JSON.parse(res.data.data);
                            this.handleShowSuccessModal(getObjKeyIdString(responseData._id));
                        } else {
                            this.setState({handleShowSuccessModal: false});
                            this.toggleErrorModal(MSG009_SYS_ERR_SAVING_RECORD);
                        }
                    }).catch(error => {
                        console.log(error);
                        this.setState({handleShowSuccessModal: false});
                        this.toggleErrorModal(MSG009_SYS_ERR_SAVING_RECORD);
                    });
            } else {
                const fieldsToUpdate = this.getUpdatedFields();

                if (Object.keys(fieldsToUpdate).length) {
                    this.props.updateAdminNotice(fieldsToUpdate)
                        .then(res => {
                            if (onApiSuccess(res)) {
                                this.handleShowSuccessModal(fieldsToUpdate._id)
                            } else {
                                this.setState({handleShowSuccessModal: false});
                                this.toggleErrorModal(MSG009_SYS_ERR_SAVING_RECORD);
                            }
                        })
                        .catch(error => {
                            this.setState({handleShowSuccessModal: false});
                            this.toggleErrorModal(MSG009_SYS_ERR_SAVING_RECORD);
                            console.log(error);
                        });
                }
                else {
                    this.handleCloseModal();
                }
            }
        })
    }

    handleDeleteNotif = (row) => {
        this.setState({ currNotif: row, isModalShow: true, modalType: 'confirmDelete' });
    }

    handleDbDelete = () => {
        this.props.deleteAdminNotice(this.state.currNotif)
            .then(res => {
                if (onApiSuccess(res)) {
                    this.setState({ modalType: 'successDelete', totalRecords: this.state.totalRecords - 1 }, () => {
                        this.handlePagination(this.state.currentPage);
                    })
                } else {
                    //Error side effects
                    this.toggleErrorModal(MSG009_SYS_ERR_SAVING_RECORD);
                }
            })
            .catch(error => {
                console.log(error);
                this.toggleErrorModal(MSG009_SYS_ERR_SAVING_RECORD);
            });
    }

    handleTitleClick = (row) => {
        this.setState(prevState => ({
            currNotif: row,
            prevNotif: prevState.currNotif,
            isModalShow: true,
            isCreate: false,
            modalType: 'update',
        }));
    }

    handleShowSuccessModal = (_id) => {
        this.setState({ _id, modalType: 'successUpdate', isCreate: false, isProcessing: false });
    }

    handleCloseModal = () => {
        this.setState(prevState => ({ isModalShow: !prevState.isModalShow, modalType: '', currNotif: {}, isProcessing: false }));
    }

    renderPagination = () => {
        return (
            <PaginationTemplate
                currentPage={this.state.currentPage}
                totalPage={this.state.totalPage}
                handlePageChange={(page) => { this.handlePageChange(page) }}
            />
        )
    }

    getUpdatedFields = () => {
        let prev = JSON.parse(JSON.stringify(this.state.prevNotif));
        let curr = JSON.parse(JSON.stringify(this.state.currNotif));
        let updated = JSON.parse(JSON.stringify(this.state.currNotif));

        if (prev.createdYear === curr.createdYear) {
            delete updated.createdYear;
        }
        if (prev.createdMonth === curr.createdMonth) {
            delete updated.createdMonth;
        }
        if (prev.createdDay === curr.createdDay) {
            delete updated.createdDay;
        }
        if (prev.title === curr.title) {
            delete updated.title;
        }
        if (prev.body === curr.body) {
            delete updated.body;
        }
        if (prev.isDisplayed === curr.isDisplayed) {
            delete updated.isDisplayed;
        }
        if (prev.startYear === curr.startYear) {
            delete updated.startYear;
        }
        if (prev.startMonth === curr.startMonth) {
            delete updated.startMonth;
        }
        if (prev.startDay === curr.startDay) {
            delete updated.startDay;
        }
        if (prev.endYear === curr.endYear) {
            delete updated.endYear;
        }
        if (prev.endMonth === curr.endMonth) {
            delete updated.endMonth;
        }
        if (prev.endDay === curr.endDay) {
            delete updated.endDay;
        }

        delete updated.topErrorMessage;

        return updated;
    }

    setModalProps = () => {
        switch (this.state.modalType) {
            case 'create': {
                return {
                    prevModal: this.state.prevModal,
                    handleCloseModal: this.handleCloseModal,
                    handleConfirm: this.handleConfirmClick,
                    handleSubmit: this.handleConfirmClick
                }
            }
            case 'update': {
                return {
                    handleUpdateIsDisplayed: this.handleUpdateIsDisplayed,
                    handleConfirm: this.handleConfirmClick,
                    handleSubmit: this.handleConfirmClick,
                    handleCloseModal: this.handleCloseModal,
                    totalPage: this.state.totalPage,
                }
            }
            case 'confirmUpdate': {
                return {
                    handleUpdate: this.handleUpdateSuccess,
                    handleShowEditModal: this.handleShowEditModal
                }
            }
            case 'confirmDelete': {
                return {
                    handleDbDelete: this.handleDbDelete
                }
            }
            default: return {};
        }
    }

    render() {
        /**Modal scrollbar fix */
        if (this.state.isModalShow) {
            document.body.style.overflowY = "hidden"
        } else {
            document.body.style.overflowY = "auto"
        }

        /**Check if display table */
        let isTableShow = 0 < this.props.notifList.length;

        return (
            <React.Fragment>

                {/**Process Loading Indicator */}
                <ProcessLoader
                    isShown={this.state.isProcessing}
                />

                <p className="admin-title">お知らせ</p>
                <AdminCard>
                    <p className="admin-subtitle">お知らせ <span className="admin-subtitle-colored">{this.state.totalRecords}</span> 件</p>
                    <Button className="notificationManagement-button" type="submit" onClick={() => { this.handleCreateNotif() }}>新規作成</Button>

                    {isTableShow && (
                        <React.Fragment>
                            {this.renderPagination()}
                            <TableTemplate
                                onClick={(row) => { this.handleTitleClick(row) }}
                                tableHeader={notificationsTable.tableHeaders}
                                tableColumns={notificationsTable.tableColumns}
                                tableList={this.props.notifList}
                                rowButtons={[
                                    { label: "削除", variant: "link", onClick: (row) => { this.handleDeleteNotif(row) } },
                                ]}
                            />
                            {this.renderPagination()}
                        </React.Fragment>
                    )}
                    
                </AdminCard>

                {/** Notification Details*/}
                {!isStringNullEmptyOrUndefined(this.state.modalType) && (
                    <NotificationModal
                        key={this.state.modalType}
                        type={this.state.modalType}
                        currNotif={this.state.currNotif}
                        isModalShow={this.state.isModalShow}
                        handleCloseModal={this.handleCloseModal}
                        {...this.setModalProps()}
                    />
                )}


                {/** Error Response Message*/}
                <ErrorModal
                    isErrorModalShow={this.state.isErrorModalShow}
                    errorMessage={this.state.errorMessage}
                    handleCloseModal={this.toggleErrorModal}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        notifList: state.notifmanagement.notificationList
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        createAdminNotice: notifOperations.createAdminNotice,
        getAllAdminNotice: notifOperations.getAllAdminNotice,
        deleteAdminNotice: notifOperations.deleteAdminNotice,
        updateAdminNotice: notifOperations.updateAdminNotice,
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);
import React from 'react';
import {Card} from 'react-bootstrap';
import './styles.scss';

const AdminCard = (props) => {
    return (
        <Card className="admin-card-parent">
            <Card.Body>
                {props.children}
            </Card.Body>
        </Card>
    );
};

export default AdminCard;
import moment from 'moment'
import * as errMsg from "../../../../utils/messages"
import { isStringNullEmptyOrUndefined } from '../../../../utils/common'

export const checkEditProfileForm = (profileModel) => {
    profileModel = profileModel.ProfileModel

    let year = profileModel.birthYear.toString()
    let month = profileModel.birthMonth.toString()
    let day = profileModel.birthDay.toString()
    let birthdate = moment(year + "-" + month + "-" + day, "YYYY-M-D", true)
    let errArray = {}

    if('undefined' === typeof(profileModel.handleName) || isStringNullEmptyOrUndefined(profileModel.handleName)) {
        errArray["ProfileModel.handleName"] = errMsg.REQUIRED_HANDLENAME
    }

    if('undefined' === typeof(profileModel.lastname) || isStringNullEmptyOrUndefined(profileModel.lastname)) {
        errArray["ProfileModel.lastname"] = errMsg.REQUIRED_LASTNAME
    }

    if('undefined' === typeof(profileModel.firstname) || isStringNullEmptyOrUndefined(profileModel.firstname) ){
        errArray["ProfileModel.firstname"] = errMsg.REQUIRED_FIRSTNAME
    }

    if('undefined' === typeof(profileModel.gender) || isStringNullEmptyOrUndefined(profileModel.gender)) {
        errArray["ProfileModel.gender"] = errMsg.REQUIRED_GENDER
    }

    if('undefined' === typeof(profileModel.bloodtype) || isStringNullEmptyOrUndefined(profileModel.bloodtype)) {
        errArray["ProfileModel.bloodtype"] = errMsg.REQUIRED_BLOODTYPE
    }

    if('undefined' === typeof(profileModel.birthYear) || isStringNullEmptyOrUndefined(profileModel.birthYear)) {
        errArray["ProfileModel.birthYear"] = errMsg.REQUIRED_BIRTHYEAR
    }

    if(!isStringNullEmptyOrUndefined(year) && 
        (!isStringNullEmptyOrUndefined(month) || !isStringNullEmptyOrUndefined(day)) &&
        true !== birthdate.isValid()) {
        errArray["ProfileModel.birthdate"] = errMsg.INVALID_BIRTHDATE
    }

    return errArray
}
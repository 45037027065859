import reducer from "./reducers";
import { routes } from "../routes";
import * as notifOperations from "./operations";
import selectors from "./selectors";

export {
    notifOperations,
    selectors
};

export default {
    reducer,
    routes
};
import { CHAT_MAX_CHAR, ACCEPTED_IMAGE_TYPES, IMAGE_MAX_SIZE, IMAGE_MAX_UPLOAD } from '../../../../utils/constants';
import { MAX_CHAT_EXCEEDED, MAX_IMAGE_EXCEEDED, MSG_INVALID_IMAGE_SIZE } from '../../../../utils/messages';
import { isNullOrUndefined } from 'util';

export const validateChatMessage = (message) =>{
    let errors = {};

    if(!isNullOrUndefined(message)){
        if(CHAT_MAX_CHAR < message.length){
            errors.message = MAX_CHAT_EXCEEDED
        }
    }
    
    return errors;
}

export const validateChatImage = (files) =>{
    let errors = {};
    if(IMAGE_MAX_UPLOAD < files.length){
        errors.image = MAX_IMAGE_EXCEEDED
    }
    files.map(item => {
        if(!item && "image" !== item['type'].split('/')[0]){
            errors.image = MSG_INVALID_IMAGE_SIZE
        }

        if(IMAGE_MAX_SIZE < item.size){
            errors.image = MSG_INVALID_IMAGE_SIZE
        }
    
        if(!ACCEPTED_IMAGE_TYPES.includes(item['type'])){
            errors.image = MSG_INVALID_IMAGE_SIZE
        }
        return null
    })
    return errors;
}
import { POST_MAX_CHAR, COMMENTS_MAX_CHAR, REPLIES_MAX_CHAR, POST_MAX_IMAGE } from "./constants";


/*Error Messages*/
export const REQUIRED_FIELD = "テキストまたは画像入力してください。"
export const INVALID_IMAGE = "画像利用出来ない文字が含まれています。"
export const MAX_IMAGE_EXCEEDED = "一度に添付できる写真は10点までです。"
export const MAX_CHAT_EXCEEDED ="1000文字以内で入力してください。"
export const SYSTEM_ERROR = "システムエラーが発生しました。"

/*Chat Messages*/
export const USER_DELETED_MESSAGE = "メッセージを削除しました。"
export const FRIEND_DELETED_MESSAGE = "さんがメッセージを削除しました。"
export const USER_SENT_PHOTO = "写真を送信しました。"
export const FRIEND_SENT_PHOTO = "写真を受信しました。"
export const NO_MESSAGES = "まだメッセージがありません。"
export const DELETE_MESSAGE_TEXT = "メッセージを削除しますか？"

/*API Limit Messages*/
export const EXCEED_INSTAGRAM_API_CALL_LIMIT = "エラーが発生しました。上部の@fumiyart_official をクリックし、Instagramのページに直接アクセスください"
export const EXCEED_FACEBOOK_API_CALL_LIMIT = "エラーが発生しました。上部の@fumiyafujiiofficial をクリックし、Facebookのページに直接アクセスください"

/*PROFILE ERROR MESSAGES*/
export const REQUIRED_HANDLENAME = "ハンドルネームを入力してください。"
export const REQUIRED_LASTNAME = "姓を入力してください。"
export const REQUIRED_FIRSTNAME = "名を入力してください。"
export const REQUIRED_GENDER = "性別を入力してください。"
export const REQUIRED_BLOODTYPE = "血液型を入力してください。"
export const REQUIRED_BIRTHYEAR = "誕生年を入力してください。"
export const REQUIRED_BIRTHMONTHDAY = "誕生日を入力してください。"
export const INVALID_BIRTHDATE = "誕生日が無効です。"

/**POST Error Messages */
export const MSG_POST_REQUIRED = "テキストを入力、もしくは写真をアップロードしてください。";
export const MSG_POST_CHAR_EXCEEDED = POST_MAX_CHAR+"文字以内で入力してください。";
export const MSG_COMMENT_CHAR_EXCEEDED = COMMENTS_MAX_CHAR+"文字以内で入力してください。";
export const MSG_REPLY_CHAR_EXCEEDED = REPLIES_MAX_CHAR+"文字以内で入力してください。";
export const MSG_MAX_POST_PHOTO_EXCEEDED = "日記1件につき写真"+POST_MAX_IMAGE+"点までアップロード可能です。";
export const MSG_TOTAL_SIZE_REACHED = "データ容量が上限に達しました。不要な日記や写真を削除してください。";
export const MSG_INVALID_IMAGE_SIZE = "1ファイルあたり10MBまでのJPEG及びPNG形式にてアップロードしてください。";
export const MSG_INVALID_VIDEO = "MP4、もしくはMOV形式にてアップロードしてください。";

/** POST Livestream Error Messages */
export const MSG_INVALID_VIMEO_ID = "Vimeo IDが無効です。";
export const MSG_INVALID_DATE = "日時が無効です。";
export const MSG_FUTURE_DATE = "未来の日時のみ設定可能です。";
export const MSG_INVALID_DURATION = "開始日時は終了日時より前の日時でなければなりません。";
export const MSG_LIVESTREAM_OVERLAP = "別のライブ動画と表示時間が重複しています。";

/**COMMON Error Messages */
export const MSG_SYSTEM_ERROR = "システムエラーが発生しました。";

/**NEWSFEED Messages */
export const MSG_NO_POST_YET = "まだ投稿がありません。";

/**Past Post Messages */
export const MSG_NO_POSTS = "投稿がありません。";

/**User Management Messages */
export const PERMANENTLY_DELETE_USER = "Permanently delete this user?";

/**Notification Messages */
export const EMPTY_NOTIFICATION = "お知らせはまだありません。";

/**Search Messages */
export const NO_RESULTS_FOUND = "該当する結果はありません。";

/**Contact */
export const INQUIRY_NOT_SENT = "お問い合わせを送信できませんでした。";
export const MSG_INVALID_FFID = "会員番号6桁は半角で入力してください。";
export const MSG_EMPTY_EMAIL = "会員サイト登録メールアドレスを入力してください。";
export const MSG_DOUBLE_BYTE_EMAIL = "会員サイト登録メールアドレスは半角で入力してください。";
export const MSG_INVALID_EMAIL = "無効なEmailが入力されています。";
export const MSG_EMPTY_PASSWORD = "会員サイトパスワードを入力してください。";
export const MSG_INVALID_PASSWORD = "会員サイトパスワードは英数半角で入力してください。";
export const MSG_EMPTY_NAME = "氏名を入力してください。";
export const MSG_EMPTY_CONTENT = "お問い合わせ内容を入力してください。";

import * as Path from "./apiRoutes"
import ApiService from "../../../../utils/apiService"
import { isNullOrUndefined } from "util"
import { IMAGE_TYPE, POST_PRIVACY } from "../../../../utils/constants"

const getRandomFriends = (userId) => (dispatch) => {
    return ApiService.get(Path.GET_MYPAGE_FRIENDS + `${userId}`)
    .then((response)=>{
        return response
    })
    .catch((error)=>{
        console.log(error)
    })
}

const getUserPosts = (userId, type, currentPagination) => (dispatch) => {
    let privacy = type;

    if(isNullOrUndefined(type) || POST_PRIVACY.friends === type ){
        privacy = null
    }

    return ApiService.get(Path.GET_MYPAGE_POSTS + `${userId}/${privacy}/${currentPagination}`)
    .then((response)=>{
        return response
    })
    .catch((error)=>{
        console.log(error)
    })
}


const uploadImageChange = (imageFile, userId, type) => (dispatch) => {
    let fileExtension = "." + imageFile.type.split("/").pop()
    let keyName = IMAGE_TYPE.cover === type ? "Cover/" + userId + fileExtension : "Profile/" + userId + fileExtension
    let params = {
        keyName : keyName
    }
    return ApiService.post(Path.GET_PRESIGNED_URL, params)
    .then((response)=>{
        return response
    })
    .catch((error)=>{
        console.log(error)
    })
}

const deletePost = (postId, refId=null) => () => {
    let params={refId}

    return ApiService.post(Path.DELETE_POST + `${postId}`, params)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}



export{
    getRandomFriends,
    getUserPosts,
    uploadImageChange,
    deletePost  
}
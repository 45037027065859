import React from "react";
import { Card, Image } from 'react-bootstrap';
import { useSelector,  } from 'react-redux';
import CameraIcon from '../../../includes/img/icons/camera-white.svg';
//import MenuIcon from '../../../includes/img/icons/dot-menu-dark.svg'; 2023/10/18 Safari version issue.
import PreviewIcon from '../../../includes/img/icons/preview-white.svg';
import MessagesIcon from '../../../includes/img/user-menu/chat.svg';
import FriendIcon from "../../../includes/img/user-menu/friends.svg"
import MenuBox from "../../../includes/img/icons/menu-box.svg"
import PastIcon from '../../../includes/img/icons/past-white.svg';
import {addDefaultSrc, addDefaultCoverSrc, customHTMLParse} from "../../../utils/common"
import { TouchableOpacity } from 'react-native';
import { appendUserRoute } from '../../../utils/common';
import { Link } from 'react-router-dom';
import { IMAGE_TYPE, S3_THUMBNAIL_PREFIX_URL, THUMBNAIL_MEDIUM, THUMBNAIL_SMALL, THUMBNAIL_LARGE} from '../../../utils/constants';
import ProcessLoader from '../../components/ProcessLoader';
import PCOnlyView from '../../components/PCOnlyView';
import MobileOnlyView from '../../components/MobileOnlyView';
import { isNullOrUndefined } from 'util';
import LinkTransform from "../../components/LinkTransform";

const MyPageHeaderComponent = (props) => {
    const profileImageUrl = useSelector(state => state.auth.credentials.profileImageUrl);
    const coverImageUrl = useSelector(state => state.auth.credentials.coverImageUrl);

    let pastPostRoute = appendUserRoute(`/past-posts/${props.ownerId}/1`);
    let myPagePreviewRoute = appendUserRoute(`/mypage-preview/${props.ownerId}`);

        return(
            <>
                <Card.Body className="mypage-cover-photo-container">
                    <ProcessLoader isShown={props.isCoverPhotoChange}/>
                        <PCOnlyView>
                           <Image src={S3_THUMBNAIL_PREFIX_URL + coverImageUrl + THUMBNAIL_LARGE + "?" + props.coverHash}   onError={addDefaultCoverSrc} alt="cover" className="profile-cover-image"/>
                        </PCOnlyView>
                        <MobileOnlyView>
                           <Image src={S3_THUMBNAIL_PREFIX_URL +  coverImageUrl + THUMBNAIL_LARGE+ "?" + props.coverHash}    onError={addDefaultCoverSrc} alt="cover" className="profile-cover-image"/>
                        </MobileOnlyView>
                        {!props.isPreview ?
                            <div className="cover-camera">
                                <div className="cover-edit-upload">
                                    <label className="custom-file-upload">
                                        <Image src={CameraIcon} className="header-icon" alt="icon" />
                                        <p className="content">編集</p>
                                        <input type="file" accept="image/*" onChange={(e)=>props.handleCoverImageChange(e, IMAGE_TYPE.cover)} className="cover-upload"/>
                                    </label>
                                </div>
                            </div>                        
                        : null}
                </Card.Body>
                <Card.Body className="mypage-profile-container-mobile">
                    <div className="mypage-profile-photo-container">
                        <ProcessLoader isShown={props.isProfilePhotoChange}/>
                        <PCOnlyView>
                             <Image src={S3_THUMBNAIL_PREFIX_URL + profileImageUrl  + THUMBNAIL_MEDIUM + "?" + props.profileHash} onError={addDefaultSrc} alt="profile" className="profile-image"/>
                        </PCOnlyView>
                        <MobileOnlyView>
                             <Image src={S3_THUMBNAIL_PREFIX_URL + profileImageUrl + THUMBNAIL_SMALL + "?" + props.profileHash} onError={addDefaultSrc} alt="profile" className="profile-image"/>
                        </MobileOnlyView>
                        {!props.isPreview ?
                            <div className="profile-camera">
                                <label className="cover-edit-upload">
                                    <Image src={CameraIcon} alt="camera-icon" className="camera-icon" />
                                    <input type="file" accept="image/*" onChange={(e)=>props.handleProfileImageChange(e, IMAGE_TYPE.profile)} className="cover-upload"/>
                                </label>
                            </div>
                        : null}
                    </div>
                    {!props.isPreview ?
                    <div className="mypage-profile-menu">
                        <TouchableOpacity onPress={()=>props.handleToggleMenu()}>
                            <p><b>・・・</b></p>
                        </TouchableOpacity>
                    </div> : isNullOrUndefined}
                    {props.isOpenMenu ?
                      <div className="mypage-profile-menu-items">
                        <Link key={myPagePreviewRoute} href={myPagePreviewRoute} to={myPagePreviewRoute} className="link-router">
                            <div className="menu-item">
                                <Image src={PreviewIcon} alt="preview-icon" className="header-icon" />
                                <p>プレビュー</p>
                            </div>
                        </Link>
                        <Link key={pastPostRoute} href={pastPostRoute} to={pastPostRoute} className="link-router">
                            <div className="menu-item">
                                <Image src={PastIcon} alt="past-icon" className="header-icon" />
                                <p>過去の投稿を見る</p>
                            </div>
                        </Link>
                      </div>
                    : null}
                </Card.Body>
                <Card.Body className="mypage-bio-container">
                    <span className="handle-name">{props.userModel.handleName}</span>
                    {props.isPreview ? renderPreviewIcons() : null}    
                    <LinkTransform>
                        <span className="bio-text">{customHTMLParse(props.userModel.bio)}</span>
                    </LinkTransform>
                </Card.Body>
            </>
        )
}

const renderPreviewIcons = () => {
    return(
        <>
             <Card.Body className="icons-card-container">
                 <div className="icons-inner-container">
                    <div className="icons-container">
                        <Image src={FriendIcon}  alt="add" className="preview-icon"/>
                        <p className="icon-name">友達</p>
                    </div>
                    <div className="icons-container">
                        <Image src={MessagesIcon}  alt="messages" className="preview-icon"/>
                        <p className="icon-name">メッセージ</p>
                    </div>
                    <div className="icons-container">
                        <Image src={MenuBox}  alt="menu" className="preview-icon"/>
                        <p className="icon-name">その他</p>
                    </div>
                 </div>

            </Card.Body>
        </>
    )
}

export default MyPageHeaderComponent
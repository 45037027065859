import React from "react";
import Modal from "react-modal";
import { passwordChangeForm, passwordChangeUpdateForm } from './state/templates';
import { View, Image, TouchableOpacity } from "react-native";
import { selectors } from './state';
import "./styles/passwordChange.scss"
import CloseIcon from "../../../includes/img/icons/close.svg";
import styles from "../../components/ConfirmationModal/styles.native"
import FormTemplate from '../../components/FormTemplate';

const PasswordChangeModal = (props) => {

    const resetFormValues = (form) => {
        form.form.map(input => {
            input.default = '';
            return form;
        });
        return form;
    }

    const handleShowEditForm = () => {
        props.handleShowEditModal();
    }

    const setFormProps = () => {
        //Update form definition
        switch(props.type) {
            case 'update': {
                return {
                    formInputs: resetFormValues(passwordChangeForm),
                    handleSubmit: (row) => props.handleSubmit(row),
                    formButtons: [
                        { type: "primary", label: "確認する", submit: props.handleConfirm },
                        { type: "secondary", label: "戻る", onClick: props.handleCloseModal }
                    ]
                }
            }
            case 'confirm': {
                let customPasswordChangeForm = Object.assign({}, passwordChangeUpdateForm);
                customPasswordChangeForm.form[0].type="top-error-message";
                if('' === props.dataValidationError) {
                    customPasswordChangeForm.form[0].name="";
                } else {
                    customPasswordChangeForm.form[0].default = props.dataValidationError;
                }

                return{
                    formInputs: customPasswordChangeForm,
                    formButtons: [
                        { type: "primary", label: "変更する", onClick: props.handleUpdate },
                        { type: "secondary", label: "戻る", onClick: handleShowEditForm }
                    ]
                }
            }
            case 'success': {
                let customPasswordChangeForm = Object.assign({}, passwordChangeUpdateForm);
                customPasswordChangeForm.form[0].type="values";
                customPasswordChangeForm.form[0].name="adminSuccessPasswordChange";

                return{
                    formInputs: customPasswordChangeForm,
                    formButtons: [
                        { type: "secondary", label: "閉じる", onClick: props.handleCloseModal}
                    ]
                }
            }
            default: return{
                formInputs: passwordChangeForm,
            };
        }
    }

    return (
        <View>
            <Modal
                ariaHideApp={false}
                isOpen={props.isModalShow}
                className="passwordChange-modal"
                overlayClassName="passwordChange-modal-overlay"
            >
                <TouchableOpacity
                    style={styles.modalClose}
                    onPress={props.handleCloseModal}
                >
                    <Image source={CloseIcon} alt="close-icon" style={styles.closeIcon} />
                </TouchableOpacity>
                <View>

                {/**Render Form */}
                <FormTemplate
                    title={'パスワード変更'}
                    preview={true}
                    key={props.type}
                    validate={selectors.validatePasswordChange}
                    currPassword={props.currPassword}
                    {...setFormProps()}
                />
                </View>
            </Modal>
        </View>    
    );
}

export default PasswordChangeModal;
import React, { Component } from 'react';
import "./styles/styles.scss"
import { TouchableOpacity } from 'react-native';
import Caret from "../../../includes/img/icons/right-arrow.svg"
import HelpQuestion from "../../../includes/img/icons/help-question.svg"
import { Image } from 'react-bootstrap';
import Collapsible from 'react-collapsible';
import helpData from "./HelpData"
import { defaultTopScroll } from '../../../utils/common';
import debounce from 'lodash/debounce'
import HTMLParse from 'html-react-parser';

class Help extends Component {
    constructor(props){
        super(props);
        this.state = {
            hasOpen: false,
            items: helpData,
            hasLoaded: false       
        }
    }

    componentDidMount(){
        defaultTopScroll();
        this.closeAllCollapsible();
        setTimeout(() => {
            this.setState({
                hasLoaded: true
            })
        }, 100);
    }

    renderQuestion = (question) =>{
        return (
            <div className="help-question-container">
                <Image src={HelpQuestion} className="help-question-icon"/>
                <p className="help-question">{question}</p>
            </div>
        )
    }

    toggleCategoryOpen = (itemNo)=>{
      this.hasOpen()
      //Set category and questions to open
      let items = this.state.items.map(item=>{
          if(item.itemNo === itemNo){
            item.isCategoryOpen = true
            return item
          }
          return item
      })

      this.setState({
          items
      })
    }

    toggleCategoryClose = (itemNo)=>{
      this.hasOpen()
      //Set category and questions to close
      let items = this.state.items.map(item=>{
          if(item.itemNo === itemNo){
            item.isCategoryOpen = false
            return item
          }
          return item
      })
      // set all questions to close
      let updatedItems = items.map(item=>{
        if(item.itemNo === itemNo){
            item.questions.map(item=>{
                item.isQuestionOpen = false
                return item
            })
        }
        return item
      })

      this.setState({
          items : updatedItems
      })
    }

    toggleQuestionOpen = (itemNo, questionNo)=>{
      //Set question to open
      let items = this.state.items.map(item=>{
          if(item.itemNo === itemNo){
             item.questions.map(value=>{
                 if(value.questionNo === questionNo){
                     value.isQuestionOpen = true
                     return value
                 }
                return item
             })
          }
          return item
      })

      this.setState({
          items
      })
    }

    toggleQuestionClose = (itemNo, questionNo)=>{
      //Set question to open
      let items = this.state.items.map(item=>{
          if(item.itemNo === itemNo){
             item.questions.map(value=>{
                 if(value.questionNo === questionNo){
                     value.isQuestionOpen = false
                     return value
                 }
                return item
             })
          }
          return item
      })

      this.setState({
          items
      })
    }

    closeAllCollapsible = ()=>{
        let stateItems = Object.assign([], this.state.items);
        let items = stateItems.map(item=>{
                item.isCategoryOpen = false;
                item.questions.map(value=>{
                       value.isQuestionOpen = false
                       return value
                })
                return item
             })
        this.setState({
               items,
        })
    }

    openAllCollapsible = ()=>{
        let stateItems = Object.assign([], this.state.items);
        let items = stateItems.map(item=>{
                item.isCategoryOpen = true;
                item.questions.map(value=>{
                        value.isQuestionOpen = true
                        return value
                })
                return item
            })
        this.setState({
                items
        })
    }

    hasOpen = () => {
        this.setState({
            hasOpen : false
        })
        //Check if there is open category
        if (this.state.items.filter(e => e.isCategoryOpen === true).length > 0) {
            this.setState({
                hasOpen : true
            })
        }
    }

    closeWithDebounce = debounce(function() {
        this.closeAllCollapsible()
    }, 200);

    openWithDebounce = debounce(function() {
        this.openAllCollapsible()
    }, 200);


    render() {
        return (
            <div className="help-main-container">
                <div className="help-header">
                    <span className="help-header-text">Q&A／ヘルプ</span>
                </div>
                <div className="help-container">
                    <div className="help-button">
                        <TouchableOpacity onPress={this.state.hasOpen ? ()=> this.closeWithDebounce() : ()=> this.openWithDebounce()}>
                        <div className="help-button-item"> 
                            <span className="help-button-text"> {this.state.hasOpen ? "すべて閉じる" : "すべて開く" }</span>
                            <Image src={Caret} className="caret"/>
                        </div> 
                        </TouchableOpacity>
                    </div>
                    <div className="help-items-container">
                            {
                               this.state.hasLoaded && this.state.items.map((item,index)=>{
                                    return (
                                         <RenderCollapsible
                                            key={index}
                                            {...this.state}
                                            renderQuestion={this.renderQuestion}
                                            toggleCategoryClose={this.toggleCategoryClose}
                                            toggleCategoryOpen={this.toggleCategoryOpen}
                                            toggleQuestionClose={this.toggleQuestionClose}
                                            toggleQuestionOpen={this.toggleQuestionOpen}
                                            item={item}
                                        />
                                    )
                                })
                            }
                    </div>
                </div>
            </div>
        );
    }
}

const RenderCollapsible = (props) =>{
    return(
        <Collapsible transitionTime={150} key={props.item.itemNo} trigger={props.item.category} className="collapsible-header" open={props.item.isCategoryOpen}  onOpening={()=>props.toggleCategoryOpen(props.item.itemNo)} onClosing={()=>props.toggleCategoryClose(props.item.itemNo)}> 
            {props.item.questions.map(value=>{
                   return(
                        <Collapsible transitionTime={150}  key={value.questionNo}  open={value.isQuestionOpen}  trigger={props.renderQuestion(value.question)} className="question-trigger" openedClassName ={"question-trigger"} onOpening={()=>props.toggleQuestionOpen(props.item.itemNo, value.questionNo)} onClosing={()=>props.toggleQuestionClose(props.item.itemNo, value.questionNo)} >
                            <p className={"help-answer " + value.answerClassName}>
                                {value.answerInitial && HTMLParse(value.answerInitial)}
                                {value.disableParse ? value.answer : HTMLParse(value.answer)}
                            </p>
                       </Collapsible>
                   )
            })}
        </Collapsible>
    )
}

export default Help;
import * as types from "./types";

const defaultState = {
    //Module state
    userList:[]
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.SET_USERS_LIST:
            return {
                ...state,
                userList: action.payload
            };
        case types.APPEND_USERS_LIST:
            return {
                ...state,
                userList: [...state.userList, ...action.payload]
            };
        case types.DELETE_USER:
            return {
                ...state,
                userList: state.userList.filter(user => user.userId !== action.payload.usersToDelete)
            };
        case types.UPDATE_USER_ID:
            const updatedUser = {
                'userId': action.payload.userId
            }
            return {
                ...state,
                userList: state.userList.map(user => (user.handleName === action.payload.handleName) ? {...user, ...updatedUser} : user)
            }
        default: return state;
    }
};
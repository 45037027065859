import * as Path from "./apiRoutes";
import ApiService from "../../../../utils/apiService";


const getAllFriends = (userId,currentPagination) => dispatch => {
  return ApiService.get( Path.GET_ALL_FRIENDS + `/${userId}/${currentPagination}`)
    .then(response => {
        return response
    })
    .catch(error => {
      console.error(error);
    });
};

const countAllFriends = (userId) => dispatch => {
    return ApiService.get( Path.GET_FRIENDS_COUNT + `/${userId}`)
      .then(response => {
        return response
      })
      .catch(error => {
        console.error(error);
      });
  };

const isUserBlocked = (userId, friendId) => dispatch => {
    return ApiService.get(Path.IS_USER_BLOCKED + `/${userId}/${friendId}`)
      .then(response => {
        return response
      })
      .catch(error => {
        console.error(error);
      });
  };

const getUserHandleName = (userId) => dispatch => {
    return ApiService.get( Path.GET_USER_HANDLENAME + `/${userId}`)
      .then(response => {
        return response
      })
      .catch(error => {
        console.error(error);
      });
  };

  export {
    getAllFriends,
    countAllFriends,
    isUserBlocked,
    getUserHandleName
  };
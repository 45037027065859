import React from 'react';
import { Row, Col, Image, Container } from 'react-bootstrap';
import { TouchableOpacity } from 'react-native';
import { Media } from 'react-breakpoints'
import { isMobile } from "react-device-detect";
import styles from './styles.native.js';
import './styles.scss';
import 'lazysizes';

const maxPcContainerHeight = 700;
const maxMobileContainerHeight = 500;

export default React.memo(({ source, onPressImage }) => {
    let count = source.length || 0;
    let gridComponent = "";

    const ImagePress = React.memo(({ src, style }) => {
        return (
            <TouchableOpacity activeOpacity={0.7} onPress={onPressImage} style={style}>
                <Image loading={"lazy"} data-src={src} className="grid-image lazyload" alt="post-image"/>
            </TouchableOpacity>
        );
    })

    const OneImage = React.memo(({maxContainerHeight}) => {
        return <ImagePress src={source[0]} style={[styles.fullGrid, {maxHeight: maxContainerHeight}]} />
    })

    const TwoImage = React.memo(({maxContainerHeight}) => {
        return (
            <span className="d-flex justify-content-between w-100">
                {source.map((item, i) => <ImagePress key={"img" + i} src={item} style={[styles.halfGridHorizontal, {maxHeight: maxContainerHeight}]} />)}
            </span>
        )
    })

    const ThreeImage = React.memo(({ maxContainerHeight, mobileView }) => {
        let maxGridHeight = maxContainerHeight / 2;
        return (
            <React.Fragment>
                <div style={{ ...styles.halfGridVertical, paddingBottom: mobileView ? 3 : 5 }}><ImagePress src={source[0]} style={{ maxHeight: maxGridHeight }} /></div>

                <span className="d-flex justify-content-between three-image">
                    {source.map((item, i) => {
                        if (0 < i) {
                            return <ImagePress key={"img" + i} src={item} style={[styles.halfGridHeight, {maxHeight: maxGridHeight - 4}]} />
                        }
                        return '';
                    })}
                </span>
            </React.Fragment>
        )
    })

    const FourImage = React.memo(({maxContainerHeight}) => {
        let maxGridHeight = maxContainerHeight / 2;
        return (
            source.map((item, i) => {
                let customStyle = {};
                if(2 > i){
                    customStyle = {...customStyle, paddingBottom: 4}
                }
                if(1 === ((i+1) % 2)){
                    customStyle = {...customStyle}
                }
                return (
                    <ImagePress 
                        key={"img" + i} 
                        src={item} 
                        style={{...styles.halfGridHorizontal, ...customStyle, height: 'auto', maxHeight: (maxGridHeight - 4)}} 
                    />
                )
            })
        )
    })

    const MultipleImage = React.memo(({ maxContainerHeight, mobileView }) => {
        let rightImages = [];
        let leftImages = [];
        source.map((item, i) => i < 2 ? leftImages.push(item) : (i < 5 && rightImages.push(item)));

        return (
            <React.Fragment>
                <Col className="grid-multi" style={{ maxHeight: maxContainerHeight }}>
                    {leftImages.map((item, i) => <ImagePress key={"left" + i} src={item} style={styles.halfGridVertical} />)}
                </Col>
                <Col className="grid-multi" style={{ maxHeight: maxContainerHeight }}>
                    {rightImages.map((item, i) => <ImagePress key={"right" + i} src={item} style={mobileView ? styles.trioGridVerticalMobile : styles.trioGridVertical} />)}
                </Col>

                {5 < source.length && (
                    <TouchableOpacity activeOpacity={0.7} onPress={onPressImage} style={mobileView ? styles.countOverlayMobile : styles.countOverlay}>
                        <p style={styles.count}>+{source.length - 5}</p>
                    </TouchableOpacity>
                )}
            </React.Fragment>
        )
    })



    return (
        <Media>
            {({ breakpoints, currentBreakpoint }) => {
                let mobileView = breakpoints[currentBreakpoint] < breakpoints.mobileLandscape || isMobile;
                let maxContainerHeight = mobileView ? maxMobileContainerHeight : maxPcContainerHeight;
                maxContainerHeight = 3 === count ? maxContainerHeight + 175 : maxContainerHeight;

                switch (count) {
                    case 0: { gridComponent = ''; break; }
                    case 1: { gridComponent = <OneImage maxContainerHeight={maxContainerHeight}/>; break; }
                    case 2: { gridComponent = <TwoImage maxContainerHeight={maxContainerHeight}/>; break; }
                    case 3: { gridComponent = <ThreeImage maxContainerHeight={maxContainerHeight} mobileView={mobileView}/>; break; }
                    case 4: { gridComponent = <FourImage maxContainerHeight={maxContainerHeight} />; break; }
                    case 5:
                    default: { gridComponent = <MultipleImage maxContainerHeight={maxContainerHeight} mobileView={mobileView} />; break; }
                }

                return (

                    <Container style={{ padding: 0, margin: 0, maxHeight: maxContainerHeight, maxWidth: '100%' }}>
                        <Row 
                            className={4 === count ? "justify-content-between" : ""}  
                            style={{ margin: 0, maxHeight: maxContainerHeight }}
                        >
                            {gridComponent}
                        </Row>
                    </Container>
                )
            }}
        </Media>
    );
});
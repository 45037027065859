import React from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DefaultIcon from '../../../includes/img/placeholders/user.svg';
import moment from 'moment';
import { useSelector } from 'react-redux';
import './styles/header.scss';
import { DATETIME_FORMAT, CLOUDFRONT_THUMBNAIL_PREFIX_URL } from '../../../utils/constants';
import { appendUserRoute, addDefaultSrc } from '../../../utils/common';
import { withRouter } from "react-router-dom";

const HandleName = (props) => {
    let { appendedRoute, isUserDeleted, handleName } = props;
    if (isUserDeleted) {
        return (
            <span className="author-name">{"退会ユーザ"}</span>
        )
    } else {
        return (
            <Link href={appendedRoute} to={appendedRoute}>
                <span className="author-name">{handleName}</span>
            </Link>
        )
    }
}

const SearchAuthorHeaderTemplate = (props) => {
    //Redux store data
    const loggedUserId = useSelector(state => state.auth.credentials.userId);

    let isValidCreateDt = moment(new Date(props.createDt)).isValid();
    let isValidUpdateDt = moment(new Date(props.updateDt)).isValid();
    let isProfileImageUrlValid = props.profileImageUrl && 0 <= props.profileImageUrl.indexOf("Profile/");

    let redirectUserRoute = props.userId ? appendUserRoute(`/user/${props.userId}`) : "#";

    if (loggedUserId === props.userId) {
        redirectUserRoute = appendUserRoute("/mypage");
    }
    
    return (
        <div className="author-container">
            <Link href={redirectUserRoute} to={redirectUserRoute}>
                <Image alt="profile-icon" onError={addDefaultSrc} src={isProfileImageUrlValid ? `${CLOUDFRONT_THUMBNAIL_PREFIX_URL + props.profileImageUrl}-small?` + props.hash : DefaultIcon} className="search-profile-icon" roundedCircle />
            </Link>
            <div className="d-flex flex-column ml-2">
                <HandleName isUserDeleted={props.isUserDeleted} handleName={props.handleName} appendedRoute={redirectUserRoute} />
                <span className="author-datetime">
                    {isValidCreateDt && moment(new Date(props.createDt)).format(DATETIME_FORMAT)}
                    {isValidUpdateDt && " | 編集済"}
                </span>
            </div>
        </div>
    );


};

export default withRouter(SearchAuthorHeaderTemplate);
import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  modalClose: {
    position: "absolute",
    top: "-5px",
    right: "-5px",
    zIndex: 2
  },
  closeIcon: {
    width: 22,
    height: 22
  },
  modalText: {
    marginTop: "50px",
    marginBottom: "30px",
    textAlign: "center",
    fontSize: 16
  },
  confirmBtn: {
    backgroundColor: "#0a182c",
    maxWidth: 200,
    minWidth: 140,
    marginRight: 20,
    marginBottom: 10,
    borderRadius: 10,
    height: 40
  },
  closeBtn: {
    backgroundColor: "#dcdcdc",
    maxWidth: 200,
    minWidth: 140,
    borderRadius: 10,
    height: 40
  },
  titleConfirm: {
    color: "#ffffff",
    fontSize: 14
  },
  titleClose: {
    color: "#666666",
    fontSize: 14
  }
});

export default styles;

import * as Path from "./apiRoutes";
import * as Actions from "./actions";
import ApiService from "../../../../utils/apiService";
import { strToInt, onApiSuccess, formatYYYMMDD, timestampToYYYMMDD, isStringNullEmptyOrUndefined, isEmptyDateString } from "../../../../utils/common";

const createAdminNotice = (adminNoticeModel) => dispatch => {

    let payload = {
        "createdYear": strToInt(adminNoticeModel.createdYear),
        "createdMonth": strToInt(adminNoticeModel.createdMonth),
        "createdDay": strToInt(adminNoticeModel.createdDay),
        "title": adminNoticeModel.title,
        "body": adminNoticeModel.body,
        "isDisplayed": (strToInt(adminNoticeModel.isDisplayed) ? true : false),
        "startYear": strToInt(adminNoticeModel.startYear),
        "startMonth": strToInt(adminNoticeModel.startMonth),
        "startDay": strToInt(adminNoticeModel.startDay),
        "endYear": strToInt(adminNoticeModel.endYear),
        "endMonth": strToInt(adminNoticeModel.endMonth),
        "endDay": strToInt(adminNoticeModel.endDay)
    }

    return ApiService.post(Path.CREATE_NOTIFICATION, payload)
        .then(response => {
            if(onApiSuccess(response)){
                let responseData = JSON.parse(response.data.data);
                const startDateEmpty = isStringNullEmptyOrUndefined(payload.startYear) && isStringNullEmptyOrUndefined(payload.startMonth) && isStringNullEmptyOrUndefined(payload.startDay);
                const endDateEmpty = isStringNullEmptyOrUndefined(payload.endYear) && isStringNullEmptyOrUndefined(payload.endMonth) && isStringNullEmptyOrUndefined(payload.endDay);

                let updatePayload = {
                    _id: responseData._id.$oid,
                    createViewDt: formatYYYMMDD(payload.createdYear, payload.createdMonth, payload.createdDay),
                    startDate: !startDateEmpty ? formatYYYMMDD(payload.startYear, payload.startMonth, payload.startDay) : "-",
                    endDate: !endDateEmpty ? formatYYYMMDD(payload.endYear, payload.endMonth, payload.endDay) : "-",
                    isDisplayed: payload.isDisplayed ? "表示" : "非表示",
                }
                payload = { ...payload, ...updatePayload };
                dispatch(Actions.insertNotif(payload));
            }
            return response;
        })
        .catch((error) => {
            console.log(`System Error: ${error}`);
            return error;
        });
};

const getAllAdminNotice = (currentPagination) => dispatch => {
    return ApiService.get(Path.GET_ALL_NOTIFICATIONS + `/${currentPagination}`)
        .then(response => {
            if(onApiSuccess(response)){
                let responseData = JSON.parse(response.data.data);
                
                const payload = responseData.list.map(notif => {

                    notif._id = notif._id.$oid;
                    notif.isDisplayed = notif.isDisplayed ? '表示' : '非表示';
                    
                    let tempDate = new Date(notif.createViewDt);
                    
                    notif.createdYear = tempDate.getFullYear();
                    notif.createdMonth = isStringNullEmptyOrUndefined(tempDate.getMonth().toString()) ? '' : tempDate.getMonth() + 1;
                    notif.createdDay = tempDate.getDate();
                    
                    if(isStringNullEmptyOrUndefined(notif.startDate)) {
                        notif.startYear = "";
                        notif.startMonth = "";
                        notif.startDay = "";
                        notif.startDate = "-";
                    } else {
                        let startDate = new Date(timestampToYYYMMDD(notif.startDate.$date));
                        notif.startYear = startDate.getFullYear();
                        notif.startMonth = isStringNullEmptyOrUndefined(tempDate.getMonth().toString()) ? '' : startDate.getMonth() + 1;
                        notif.startDay = startDate.getDate();
                        notif.startDate = timestampToYYYMMDD(notif.startDate.$date);
                    }

                    if(isStringNullEmptyOrUndefined(notif.endDate)) {
                        notif.endYear = "";
                        notif.endMonth = "";
                        notif.endDay = "";
                        notif.endDate = "-";
                    } else {
                        let endDate = new Date(timestampToYYYMMDD(notif.endDate.$date));
                        notif.endYear = endDate.getFullYear();
                        notif.endMonth = isStringNullEmptyOrUndefined(tempDate.getMonth().toString()) ? '' : endDate.getMonth() + 1;
                        notif.endDay = endDate.getDate(); 
                        notif.endDate = timestampToYYYMMDD(notif.endDate.$date);
                    }

                    return notif;
                }); 
            
                dispatch(Actions.setNotifList(payload));
            }
            return response;
        })
        .catch((error) => {
            console.log(`System Error: ${error}`);
            return error;
        });
};

const updateAdminNotice = (adminNoticeModel) => dispatch => {
    let payload = {};

    Object.keys(adminNoticeModel).forEach((key) => {
        payload[key] = adminNoticeModel[key];

        switch(key) {
            case 'createdYear': {
                payload[key] = strToInt(adminNoticeModel.createdYear);
                break;
            }
            case 'createdMonth': {
                payload[key] = strToInt(adminNoticeModel.createdMonth);
                break;
            }
            case 'createdDay': {
                payload[key] = strToInt(adminNoticeModel.createdDay);
                break;
            }
            case 'title': {
                payload[key] = adminNoticeModel.title;
                break;
            }
            case 'body': {
                payload[key] = adminNoticeModel.body;
                break;
            }
            case 'isDisplayed': {
                payload[key] = ('表示' === adminNoticeModel.isDisplayed) ? true : false;
                break;
            }
            case 'startYear': {
                payload[key] = strToInt(adminNoticeModel.startYear);
                break;
            }
            case 'startMonth': {
                payload[key] = strToInt(adminNoticeModel.startMonth);
                break;
            }
            case 'startDay': {
                payload[key] = strToInt(adminNoticeModel.startDay);
                break;
            }
            case 'endYear': {
                payload[key] = strToInt(adminNoticeModel.endYear);
                break;
            }
            case 'endMonth': {
                payload[key] = strToInt(adminNoticeModel.endMonth);
                break;
            }
            case 'endDay': {
                payload[key] = strToInt(adminNoticeModel.endDay);
                break;
            }
            default: {
                break;
            }
        }
    });

    return ApiService.patch(Path.UPDATE_NOTIFICATION, payload)
        .then(response => {
            if(onApiSuccess(response)) {
                
                let updatePayload = {
                    ...payload,
                    _id: adminNoticeModel._id,
                    createViewDt: formatYYYMMDD(payload.createdYear, payload.createdMonth, payload.createdDay),
                    startDate: isEmptyDateString(payload.startYear, payload.startMonth, payload.startDay) 
                                ? "-" : formatYYYMMDD(payload.startYear, payload.startMonth, payload.startDay),
                    endDate: isEmptyDateString(payload.endYear, payload.endMonth, payload.endDay) 
                                ? "-" : formatYYYMMDD(payload.endYear, payload.endMonth, payload.endDay),
                    isDisplayed: payload.isDisplayed ? "表示" : "非表示",
                }

                payload = { ...updatePayload };
                dispatch(Actions.updateNotif(payload));
            } else {
                return Promise.reject(new Error('Exception occurred during update.'));
            }

            return response;
        })
        .catch((error) => {
            console.log(`there is an error: ${error}`);
            return error;
        });
};

const deleteAdminNotice = (index) => dispatch => {
    return ApiService.post(Path.DELETE_NOTIFICATION, {'notification_id' : `${index._id}`})
        .then(response => {
            if (onApiSuccess(response)) {
                dispatch(Actions.deleteNotif(`${index._id}`));
            }
            return response;
        })
        .catch(error => {
            console.error(error);
        });
};

export {
    createAdminNotice,
    getAllAdminNotice,
    deleteAdminNotice,
    updateAdminNotice,
};
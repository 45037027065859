import * as Path from "./apiRoutes";
import ApiService from "../../../../utils/apiService";
import * as Actions from "./actions";
import { WEBSOCKET_ACTIONS, EMPTY_STRING } from "../../../../utils/constants";
import { isNullOrUndefined } from "util";
import { BROWSER_ID } from "../../../../config/settings";



const getAllChatThread = (ownerId, currentPagination) => dispatch => {
  return ApiService.get(Path.GET_ALL_CHAT_THREAD + `/${ownerId}/${currentPagination}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      console.error(error);
    });
};

const deleteChatThread = (userId, threadId) => dispatch => {
  //Actual API Call
  let thread =  threadId.$oid.replace('"', "");
  let params = { userId: userId ,threadId: thread };
  return ApiService.post(Path.DELETE_CHAT_THREAD, params)
    .then(response => {
      return response;
    })
    .catch(error => {
      console.error(error);
    });
};

const getAllFriends = (userId, currentPagination) => dispatch => {
    return ApiService.get(Path.GET_USER_FRIENDS + `/${userId}/${currentPagination}`)
      .then(response => {
        return response;
      })
      .catch(error => {
        console.error(error);
      });
};

const searchFriend = searchModel => dispatch => {
  //Actual API Call
  let params = {
  "userId" : searchModel.userId,
  "handleName" : searchModel.handleName,
  "currentPagination" : searchModel.currentPagination
  }

  return ApiService.post(Path.SEARCH_FRIEND, params)
    .then(response => {
       return response;
    })
    .catch(error => {
      console.error(error);
    });
};

const getSpecificChatThread = (threadId, currentPagination, ownerId, recipientId) => dispatch => {
  let params = {
    ownerId: ownerId,
    recipientId: recipientId,
    currentPagination: currentPagination,
    threadId: isNullOrUndefined(threadId) ? null : threadId.$oid,
    browserId : sessionStorage.getItem(BROWSER_ID)
  };

  return ApiService.post(Path.GET_SPECIFIC_CHAT_THREAD, params)
  .then(response => {
      return response;
    })
    .catch(error => {
      console.error(error);
    });
};

const sendMessage = (messageModel, data, ws) => dispatch => {
    if (!navigator.onLine) {
      return Promise.resolve(false);
    }
    data.forEach(function (item, i) {
      setTimeout(function() {
      if (item instanceof File) {
            if (navigator.onLine) {
            let fileExtension = "." + item.type.split("/").pop()
            ws.send(
              JSON.stringify({
                action: WEBSOCKET_ACTIONS.onImageUpload,
                type: WEBSOCKET_ACTIONS.onImageUpload,
                data: {
                  chat: {
                    ownerId: messageModel.ownerId,
                    recipientId: messageModel.recipientId,
                    recipientHandleName: messageModel.recipientHandleName,
                    ownerHandleName: messageModel.ownerHandleName,
                    imageSize: item.size,
                    type: 1,
                    chatBody: null,
                    isSent: false,
                    isDeleted: false,
                    senderId: messageModel.ownerId,
                    fileExtension: fileExtension
                  },
                },
              })
            );
        }
    } else if(!isNullOrUndefined(item) && EMPTY_STRING !== item) {
      ws.send(
        JSON.stringify({
          action: WEBSOCKET_ACTIONS.onMessage,
          type: WEBSOCKET_ACTIONS.onMessage,
          data: {
            chat: {
              ownerId: messageModel.ownerId,
              recipientId:messageModel.recipientId,
              recipientHandleName: messageModel.recipientHandleName,
              ownerHandleName: messageModel.ownerHandleName,
              type: 0,
              isSent: true,
              isDeleted: false,
              chatBody: messageModel.chatBody,
              senderId: messageModel.ownerId,
            },
          },
        })
      );
    }
    }, 1000 * i);
   })
   return Promise.resolve(true);
}

const deleteMessage = (messageModel, ws) => dispatch => {
  ws.send(
    JSON.stringify({
      action: WEBSOCKET_ACTIONS.onMessageDelete,
      type: WEBSOCKET_ACTIONS.onMessageDelete,
      data: {
        chatId : messageModel.chatId,
        recipientId: messageModel.recipientId,
        ownerId: messageModel.ownerId,
        type: messageModel.type,
        fileExtension: messageModel.fileExtension
      },
    })
  );
};

const updateChangeSeenStatus = (ownerId, recipientId, ws) => dispatch => {
  ws.send(
    JSON.stringify({
      action: WEBSOCKET_ACTIONS.updateSeenStatus,
      type: WEBSOCKET_ACTIONS.updateSeenStatus,
      data: {
        ownerId: ownerId,
        recipientId: recipientId
      },
    })
  );
};

const storeMessage = (recipientId,message) => dispatch => {
  let data = {
    recipientId : recipientId,
    message: message
  }
  dispatch(Actions.setMessage(data))
};

const getFriendHandleName = (ownerId, recipientId) => dispatch => {
  return ApiService.get(Path.GET_FRIEND_HANDLENAME + `/${ownerId}/${recipientId}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error);
    });
};

const isRecipientBlocked = (userId, friendId) => dispatch => {
  return ApiService.get(Path.IS_RECIPIENT_BLOCKED + `/${userId}/${friendId}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error);
    });
};

const checkIfUserExist = (ownerId, friendId) => dispatch => {
  return ApiService.get(Path.VALIDATE_USER + `/${ownerId}/${friendId}`)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error(error);
    });
};

export {
  getAllFriends,
  searchFriend,
  getAllChatThread,
  deleteChatThread,
  getSpecificChatThread,
  sendMessage,
  deleteMessage,
  updateChangeSeenStatus,
  storeMessage,
  getFriendHandleName,
  isRecipientBlocked,
  checkIfUserExist
};

import React from 'react';
import CookieConsent from "react-cookie-consent";
import {Link} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { COMUX2_COOKIE_CONSENT, COMU2X_COOKIE_EXPIRATION } from '../../../config/settings';
import { setCookie, appendUserRoute, objKeyExist } from '../../../utils/common';

const CookiesPrompt = () => {
    const auth = useSelector(state => state.auth);
    let token = "";

    if(auth.userAuthenticated && objKeyExist(auth.credentials, "signInUserSession")){
        token = auth.credentials.signInUserSession.idToken.jwtToken;
    }

    return (
        <CookieConsent
            cookieName={COMUX2_COOKIE_CONSENT}
            cookieValue={token}
            expires={COMU2X_COOKIE_EXPIRATION}
            onAccept={()=>{setCookie(COMUX2_COOKIE_CONSENT, token)}}
            buttonText={"X"}
            buttonStyle={{ color: "white", fontSize: "1.2rem", background: "none", position: "absolute", right: 0, top: 0, marginRight: 10, margin: 0 }}
            style={{ background: "#2B373B", padding: '25px', fontSize: '1rem', opacity: 0.95 }}
          >
              <div style={{ textAlign: "center" }}>
                <div>このサイトは、機能実現・改善のためにCookieを利用します。サイトを利用することで、Cookieの使用に同意するものとします。</div>
                <div style={{ marginTop: "15px" }}><Link target="_blank" to={appendUserRoute("/privacy-policy")} style={{ color: "deepskyblue" }}>プライバシーポリシー</Link></div>
              </div>
          </CookieConsent>
    );
};

export default CookiesPrompt;
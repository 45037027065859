import * as Path from "./apiRoutes"
import ApiService from "../../../../utils/apiService"
import * as Actions from "./actions"
import * as AuthActions from "../../Authentication/state/actions";
import { onApiSuccess } from "../../../../utils/common"

const getUserProfile = (userId) => (dispatch) => {
    return ApiService.get(Path.GET_USER_PROFILE + "/" + userId)
    .then((response) => {
        if(onApiSuccess(response)) {
            let data = JSON.parse(response.data.data)
            let formattedData = {
                handleName: data.handleName ? data.handleName : undefined,
                firstname: data.name ? data.name.firstname :undefined,
                lastname: data.name ? data.name.lastname : undefined,
                namePrivacy: data.name ? data.name.privacy : undefined,
                gender: data.gender ? data.gender.gender : undefined,
                genderPrivacy: data.gender ? data.gender.privacy : undefined,
                bloodtype: data.bloodtype ? data.bloodtype.bloodtype : undefined,
                bloodtypePrivacy: data.bloodtype ? data.bloodtype.privacy : undefined,
                birthYear: data.birthdate ? data.birthdate.year : undefined,
                birthYearPrivacy: data.birthdate ? data.birthdate.yearPrivacy : undefined,
                birthMonth: data.birthdate ? data.birthdate.month : undefined,
                birthDay: data.birthdate ? data.birthdate.day : undefined,
                birthMonthDayPrivacy: data.birthdate ? data.birthdate.monthDayPrivacy : undefined,
                address1: data.address ? data.address.address1 : undefined,
                address2: data.address ? data.address.address2 : undefined,
                addressPrivacy: data.address ? data.address.privacy : undefined,
                birthplace: data.birthplace ? data.birthplace.birthplace : undefined,
                birthplacePrivacy: data.birthplace ? data.birthplace.privacy : undefined,
                occupation: data.occupation ? data.occupation.occupation : undefined,
                occupationPrivacy: data.occupation ? data.occupation.privacy : undefined,
                hobby1: data.hobbies ? data.hobbies.hobby1 : undefined,
                hobby2: data.hobbies ? data.hobbies.hobby2 : undefined,
                hobby3: data.hobbies ? data.hobbies.hobby3 : undefined,
                hobbyPrivacy: data.hobbies ? data.hobbies.privacy : undefined,
                bio: data.bio ? data.bio : undefined
            }
            dispatch(Actions.setProfileDetails(formattedData))
        }
        return response
    })
    .catch((error) => {
        console.log(error)
    })
}

const updateProfile = (profileModel, userId, username) => (dispatch) => {
    let updateData = {
        username: username,
        userId: userId,
        handleName: profileModel.handleName,
        name: {
            firstname: profileModel.firstname,
            lastname: profileModel.lastname,
            privacy: parseInt(profileModel.namePrivacy)
        },
        gender: {
            gender: profileModel.gender,
            privacy: parseInt(profileModel.genderPrivacy)
        },
        bloodtype: {
            bloodtype: profileModel.bloodtype,
            privacy: parseInt(profileModel.bloodtypePrivacy)
        },
        address: {
            address1: profileModel.address1,
            address2: profileModel.address2 ? profileModel.address2 : "",
            privacy: parseInt(profileModel.addressPrivacy)
        },
        birthdate: {
            year: profileModel.birthYear,
            yearPrivacy: parseInt(profileModel.birthYearPrivacy),
            month: profileModel.birthMonth,
            day: profileModel.birthDay,
            monthDayPrivacy: parseInt(profileModel.birthMonthDayPrivacy)
        },
        occupation: {
            occupation: profileModel.occupation ? profileModel.occupation : "",
            privacy: parseInt(profileModel.occupationPrivacy)
        },
        hobbies: {
            hobby1: profileModel.hobby1 ? profileModel.hobby1 : 1,
            hobby2: profileModel.hobby2 ? profileModel.hobby2 : 1,
            hobby3: profileModel.hobby3 ? profileModel.hobby3 : 1,
            privacy: parseInt(profileModel.hobbyPrivacy)
        },
        birthplace: {
            birthplace: profileModel.birthplace ? profileModel.birthplace : "",
            privacy: parseInt(profileModel.birthplacePrivacy)
        },
        bio: profileModel.bio ? profileModel.bio : ""
    }

    return ApiService.patch(Path.UPDATE_PROFILE, updateData)
    .then((response) => {
        if(onApiSuccess(response)) {
            dispatch(Actions.setProfileDetails(profileModel))
            dispatch(AuthActions.setHandleName(profileModel.handleName))
        }
        return response
    })
    .catch((error) => {
        console.log(error)
    })
}

export {
    getUserProfile,
    updateProfile
}
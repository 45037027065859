
import React from 'react';
import { Card } from 'react-bootstrap';
import { isNullOrUndefined } from 'util';
import { PRIVACY, RELATIONSHIPS } from '../../../utils/constants';
import { getPrefecture, getHobby, isStringNullEmptyOrUndefined } from '../../../utils/common';

const FriendPageDetailComponent = (props) => {
    let userInfo = props.userModel.user_model

    let hobbies = null
    if (!isNullOrUndefined(userInfo.hobbies)) {
        let hobby1 = !isNullOrUndefined(userInfo.hobbies.hobby1) ? userInfo.hobbies.hobby1 : 1
        let hobby2 = !isNullOrUndefined(userInfo.hobbies.hobby2) ? userInfo.hobbies.hobby2 : 1
        let hobby3 = !isNullOrUndefined(userInfo.hobbies.hobby3) ? userInfo.hobbies.hobby3 : 1
        hobbies = [hobby1, hobby2, hobby3]
    } 

    let is_friend = !isNullOrUndefined(props.userModel.relationship) && RELATIONSHIPS.isFriend === props.userModel.relationship ? true : false
    if (is_friend) {
        return renderMyPageFriendPreview(props, userInfo, hobbies)
    }
    else {
        return renderMyPagePublicPreview(props, userInfo, hobbies)
    }
}

const renderMyPageFriendPreview = (props, userInfo, hobbies) =>{
    let birthdate = userInfo.birthdate
    return(
        <>
            <Card.Body className="mypage-info-container">
                <div className="category-container">
                    <table>
                        <tbody>
                            <tr className={PRIVACY.private === userInfo.name.privacy ? "detail-hidden" : ""}>
                                <td className="profile-category">名前 </td>
                                <td className="profile-category-detail">
                                    {userInfo.name.lastname + " " + userInfo.name.firstname}
                                </td>
                            </tr>
                            <tr className={PRIVACY.private === userInfo.gender.privacy ? "detail-hidden" : ""}>
                                <td className="profile-category">性別</td> 
                                <td className="profile-category-detail">
                                    {userInfo.gender.gender}
                                </td>
                            </tr>
                            <tr className={PRIVACY.private === userInfo.bloodtype.privacy ? "detail-hidden" : ""}>
                                <td className="profile-category">血液型</td>
                                <td className="profile-category-detail">
                                    {userInfo.bloodtype.bloodtype}
                                </td>
                            </tr>
                            <tr className={PRIVACY.private === parseInt(userInfo.birthdate.yearPrivacy) ? "detail-hidden" : ""}>
                                <td className="profile-category">誕生日</td>
                                <td className="profile-category-detail">
                                    {PRIVACY.private === parseInt(userInfo.birthdate.yearPrivacy) ? "" : birthdate.year}
                                    {PRIVACY.private !== parseInt(userInfo.birthdate.yearPrivacy)
                                        && PRIVACY.private !== parseInt(userInfo.birthdate.monthDayPrivacy) && birthdate.month ?
                                        "/" : ""}
                                    {PRIVACY.private === parseInt(userInfo.birthdate.monthDayPrivacy) ? "" : birthdate.month}
                                    {PRIVACY.private !== parseInt(userInfo.birthdate.monthDayPrivacy)
                                        && PRIVACY.private !== parseInt(userInfo.birthdate.monthDayPrivacy) && birthdate.day ?
                                        "/" : ""}
                                    {PRIVACY.private === parseInt(userInfo.birthdate.monthDayPrivacy) ? "" : birthdate.day}
                                </td>
                            </tr>
                            {((!isStringNullEmptyOrUndefined(userInfo.address.address1) && 0 !== parseInt(userInfo.address.address1)) || !isStringNullEmptyOrUndefined(userInfo.address.address2))?
                                <tr className={PRIVACY.private === userInfo.address.privacy ? "detail-hidden" : ""}>
                                    <td className="profile-category">現住所</td>
                                    <td className="profile-category-detail">
                                        {getPrefecture(parseInt(userInfo.address.address1)) + "  " + userInfo.address.address2}
                                    </td>
                                </tr>
                                : null}
                            {!isStringNullEmptyOrUndefined(userInfo.birthplace.birthplace) ?
                                <tr className={PRIVACY.private === userInfo.birthplace.privacy ? "detail-hidden" : ""}>
                                    <td className="profile-category">出身地</td>
                                    <td className="profile-category-detail">
                                        {userInfo.birthplace.birthplace}
                                    </td>
                                </tr>
                                : null}
                            {!isStringNullEmptyOrUndefined(hobbies) && !isHobbyNone(hobbies) ?
                                <tr className={PRIVACY.private === userInfo.hobbies.privacy ? "detail-hidden" : ""}>
                                    <td className="profile-category">趣味</td>
                                    <td className="profile-category-detail">
                                        {renderHobbies(hobbies)}
                                    </td>
                                </tr>
                                : null}
                            {!isStringNullEmptyOrUndefined(userInfo.occupation.occupation) ?
                                <tr className={PRIVACY.private === userInfo.occupation.privacy ? "detail-hidden" : ""}>
                                    <td className="profile-category">職業</td>
                                    <td className="profile-category-detail">
                                        {userInfo.occupation.occupation}
                                    </td>
                                </tr>
                                : null}
                        </tbody>
                    </table>
                </div>
             </Card.Body>
        </>
    )
}

const renderMyPagePublicPreview = (props, userInfo, hobbies) =>{
    let birthdate = userInfo.birthdate
    return(
        <>
            <Card.Body className="mypage-info-container">
                <div className="category-container">
                    <table>
                    <tbody>
                            <tr className={PRIVACY.public !== userInfo.name.privacy ? "detail-hidden" : ""}>
                                <td className="profile-category">名前 </td>
                                <td className="profile-category-detail">
                                    {userInfo.name.lastname + " " + userInfo.name.firstname}
                                </td>
                            </tr>
                            <tr className={PRIVACY.public !== userInfo.gender.privacy ? "detail-hidden" : ""}>
                                <td className="profile-category">性別</td>
                                <td className="profile-category-detail">
                                    {userInfo.gender.gender}
                                </td>
                            </tr>
                            <tr className={PRIVACY.public !== userInfo.bloodtype.privacy ? "detail-hidden" : ""}>
                                <td className="profile-category">血液型</td>
                                <td className="profile-category-detail">
                                    {userInfo.bloodtype.bloodtype}
                                </td>
                            </tr>
                            <tr className={PRIVACY.public !== parseInt(userInfo.birthdate.yearPrivacy) ? "detail-hidden" : ""}>
                                <td className="profile-category">誕生日</td>
                                <td className="profile-category-detail">
                                    {PRIVACY.public !== parseInt(userInfo.birthdate.yearPrivacy) ? "" : birthdate.year}
                                    {PRIVACY.public === parseInt(userInfo.birthdate.yearPrivacy)
                                        && PRIVACY.public === parseInt(userInfo.birthdate.monthDayPrivacy) && birthdate.month ?
                                        "/" : ""}
                                    {PRIVACY.public !== parseInt(userInfo.birthdate.monthDayPrivacy) ? "" : birthdate.month}
                                    {PRIVACY.public === parseInt(userInfo.birthdate.monthDayPrivacy)
                                        && PRIVACY.public === parseInt(userInfo.birthdate.monthDayPrivacy) && birthdate.day ?
                                        "/" : ""}
                                    {PRIVACY.public !== parseInt(userInfo.birthdate.monthDayPrivacy) ? "" : birthdate.day}
                                </td>
                            </tr>
                            {((!isStringNullEmptyOrUndefined(userInfo.address.address1) && 0 !== parseInt(userInfo.address.address1)) || !isStringNullEmptyOrUndefined(userInfo.address.address2))?
                                <tr className={PRIVACY.public !== userInfo.address.privacy ? "detail-hidden" : ""}>
                                    <td className="profile-category"> 現住所</td>
                                    <td className="profile-category-detail">
                                    {getPrefecture(parseInt(userInfo.address.address1)) + "  " + userInfo.address.address2}
                                    </td>
                                </tr>
                                : null}
                            {!isStringNullEmptyOrUndefined(userInfo.birthplace.birthplace) ?
                                <tr className={PRIVACY.public !== userInfo.birthplace.privacy ? "detail-hidden" : ""}>
                                    <td className="profile-category">出身地</td>
                                    <td className="profile-category-detail">
                                        {userInfo.birthplace.birthplace}
                                    </td>
                                </tr>
                                : null}
                            {!isStringNullEmptyOrUndefined(hobbies) && !isHobbyNone(hobbies) ?
                                <tr className={PRIVACY.public !== userInfo.hobbies.privacy ? "detail-hidden" : ""}>
                                    <td className="profile-category">趣味</td>
                                    <td className="profile-category-detail">
                                        {renderHobbies(hobbies)}
                                    </td>
                                </tr>
                                : null}
                            {!isStringNullEmptyOrUndefined(userInfo.occupation.occupation) ?
                                <tr className={PRIVACY.public !== userInfo.occupation.privacy ? "detail-hidden" : ""}>
                                    <td className="profile-category">職業</td>
                                    <td className="profile-category-detail">
                                        {userInfo.occupation.occupation}
                                    </td>
                                </tr>
                                : null}
                        </tbody>
                    </table>
                </div>
             </Card.Body>
        </>
    )
}

const isHobbyNone = (hobbies) =>{
    //Check if all hobbies is equal to 1
    return hobbies.every( item => parseInt(item) === 1 )
}

const renderHobbies = (hobbies) =>{
    //Render values of hobbies
    var hobbyList = []
    hobbies.map(item=>{
        if(1 < parseInt(item)){
            let name = getHobby(parseInt(item))
            hobbyList.push(name)
        }
        return null
    })
    return hobbyList.join(",")
}


export default FriendPageDetailComponent

import reducer from "./reducers";
import { routes } from "../routes";
import * as communityOperations from "./operations"

export {communityOperations}

export default {
    reducer,
    routes
};

import reducer from "./reducers";
import { routes } from "../routes";
import * as searchOperations from './operations';

export {
    searchOperations
}

export default {
    reducer,
    routes
};

import React, { Component } from 'react';
import { TextInput, TouchableOpacity, ActivityIndicator } from 'react-native';
import PCOnlyView from '../../components/PCOnlyView';
import { Nav, Tab, Card } from 'react-bootstrap';
import './styles/search.scss';
import styles from './styles/styles.native';
import SearchIcon from "../../../includes/img/icons/search.svg";
import SearchPostItemTemplate from './SearchPostItemTemplate';
import SearchUserListTemplate from './SearchUserListTemplate';
import { Media } from 'react-breakpoints'
import { isMobile } from "react-device-detect";
import { Button, Icon } from 'react-native-elements';
import { NO_RESULTS_FOUND, SYSTEM_ERROR } from '../../../utils/messages';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { searchOperations } from './state';
import { onApiSuccess, getObjKeyIdString, isScrollRefetchReached, isStringNullEmptyOrUndefined, appendUserRoute, defaultTopScroll } from '../../../utils/common';
import ErrorModal from '../../components/ErrorModal';
import debounce from 'lodash/debounce'
import PostDetailModal from '../../components/PostDetailModal'; //task#363

/**For scroll direction check */
var scrollPos = 0;

class DiaryMemberSearch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchString: "",
            currentPaginationAll: 1,
            currentPaginationPosts: 1,
            currentPaginationUsers: 1,
            isSearching: false,
            msgErrorModal: "",
            showErrorModal: false,
            initialLoaded: false,
            allPosts: [],
            allUsers: [],
            usersList: [],
            postsList: [],
            activeTab: "all",
            searchInitiated: false,
            isFetching: false,
            allEndReached: false,
            userEndReached: false,
            postEndReached: false,
            hash: Date.now(),
            showPostDetailModal: false,//task#363
            postDetailPostId: "" //task#363
        }

        debounce(this.handleInputChange, 250)
    }

    componentDidMount() {
        defaultTopScroll();
        
        const query = new URLSearchParams(this.props.location.search);
        let searchFieldInput = query.get('keyword');

        if(!isStringNullEmptyOrUndefined(searchFieldInput)){
            this.setState({
                searchString: searchFieldInput
            }, ()=>{
                this.handleSearchSubmit("", true)
            })
        }

        document.addEventListener('scroll', this.onScrollCheck);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.onScrollCheck);
    }

    componentWillReceiveProps(props){
        const query = new URLSearchParams(props.location.search);
        let searchFieldInput = query.get('search');

        if(searchFieldInput !== this.state.searchString && !isStringNullEmptyOrUndefined(searchFieldInput)){
            this.setState({searchString: searchFieldInput},()=>this.handleSearchSubmit("", true))
        }
    }

    onScrollCheck = () => {
        let isScrollDown = (document.body.getBoundingClientRect()).top < scrollPos;
        let isEndReached = true;

        switch (this.state.activeTab) {
            case "all": { isEndReached = this.state.allEndReached; break; }
            case "posts": { isEndReached = this.state.postEndReached; break; }
            case "users": { isEndReached = this.state.userEndReached; break; }
            default: break;
        }
        

        if (isScrollRefetchReached() && !this.state.isFetching && !this.state.isSearching && isScrollDown && !isEndReached && this.state.searchInitiated) {
            this.handlePagination();
        }

        scrollPos = (document.body.getBoundingClientRect()).top;
    }

    handlePagination = () => {
        if(!this.state.isFetching){
            let currentPagination = null;
            switch (this.state.activeTab) {
                case "all": { currentPagination = this.state.currentPaginationAll; break; }
                case "posts": { currentPagination = this.state.currentPaginationPosts; break; }
                case "users": { currentPagination = this.state.currentPaginationUsers; break; }
                default: break;
            }

            let params = {
                userId: this.props.user.userId,
                searchString: this.state.searchString,
                currentPagination: currentPagination,
                searchType: this.state.activeTab
            }
            this.setState({ isFetching: true }, () => {
                this.props.executeSearch(params)
                    .then(res => {
                        if (onApiSuccess(res)) {
                            let resultData = JSON.parse(res.data.data);
                            switch (params.searchType) {
                                case "all": {
                                    let hasPosts = 0 < resultData.posts.length;

                                    this.setState(prevState => ({
                                        allPosts: [...prevState.allPosts, ...resultData.posts],
                                        currentPaginationAll: hasPosts ? prevState.currentPaginationAll + 1 : prevState.currentPaginationAll,
                                        isFetching: false,
                                        activeTab: params.searchType,
                                        allEndReached: !hasPosts
                                    }));
                                    break;
                                }
                                case "posts": {
                                    let hasPosts = 0 < resultData.posts.length;

                                    this.setState(prevState => ({
                                        postsList: [...prevState.postsList, ...resultData.posts],
                                        currentPaginationPosts: hasPosts ? prevState.currentPaginationPosts + 1 : prevState.currentPaginationPosts,
                                        isFetching: false,
                                        activeTab: params.searchType,
                                        postEndReached: !hasPosts
                                    }));
                                    break;
                                }
                                case "users": {
                                    let hasUsers = 0 < resultData.users.length;
                                    this.setState(prevState => ({
                                        usersList: [...prevState.usersList, ...resultData.users],
                                        currentPaginationUsers: hasUsers ? prevState.currentPaginationUsers + 1 : prevState.currentPaginationUsers,
                                        isFetching: false,
                                        activeTab: params.searchType,
                                        userEndReached: !hasUsers
                                    }));
                                    break;
                                }
                                default: break;
                            }
                        } else {
                            this.showSystemError();
                        }
                    }).catch(() => this.showSystemError())
            })
        }
    }

    handleSearchSubmit = (event, backSearch) => {
        if (event) {
            event.preventDefault()
        }

        if (!this.searchEmptyCheck()) {
            defaultTopScroll();

            let params = {
                userId: this.props.user.userId,
                searchString: this.state.searchString,
                currentPagination: 1,
                searchType: "all"
            }

            if(!backSearch){
                let encoded_string = encodeURIComponent(this.state.searchString.trim())
                let searchRoute = appendUserRoute(`/search/results?keyword=${encoded_string}`)
                this.props.history.push(searchRoute)
            }

            this.setState({ isSearching: true }, () => {
                this.props.executeSearch(params)
                    .then(res => {
                        if (onApiSuccess(res)) {
                            let resultData = JSON.parse(res.data.data);
                            let hasPosts = 0 < resultData.posts.length;
                            let hasUsers = 0 < resultData.users.length;
                            
                            //Set initial data to ALL, posts, users
                            this.setState(prevState => ({
                                allPosts: resultData.posts,
                                allUsers: resultData.users,
                                postsList: resultData.posts,
                                usersList: resultData.users,
                                currentPaginationAll: hasPosts ? 2 : 1,
                                currentPaginationPosts: hasPosts ? 2 : 1,
                                currentPaginationUsers: hasUsers ? 2 : 1,
                                searchInitiated: true,
                                isSearching: false,
                                searchString: prevState.searchString.trim(),
                                allEndReached: false,
                                userEndReached: false,
                                postEndReached: false
                            }))
                        } else {
                            this.showSystemError();
                        }
                    }).catch(() => this.showSystemError())
            })
        }
    }

    handlePostOnly = (mobileView) => {
        if (this.state.isSearching) {
            return <ActivityIndicator style={{ paddingTop: 20 }} />
        }

        if (0 === this.state.postsList.length) {
            return <p className="no-result">{NO_RESULTS_FOUND}</p>
        }

        return (
            this.state.postsList.map(item => {
                return <SearchPostItemTemplate hash={this.state.hash} key={getObjKeyIdString(item._id)} {...item} mobileView={mobileView}
                setPostDetailModal={this.setPostDetailModal}/**task#363**//>
            })
        )
    }

    handleUserOnly = (mobileView) => {
        if (this.state.isSearching) {
            return <ActivityIndicator style={{ paddingTop: 20 }} />
        }

        if (0 === this.state.usersList.length) {
            return <p className="no-result">{NO_RESULTS_FOUND}</p>
        }

        return this.state.usersList.map(item => {
            return <SearchUserListTemplate hash={this.state.hash} key={item.userId} {...item} />
        })
    }

    handleViewBoth = (mobileView) => {
        let userResult = "";
        let postResult = "";

        if (this.state.isSearching) {
            return <ActivityIndicator style={{ paddingTop: 20 }} />
        }

        if (0 < this.state.allUsers.length) {
            userResult = (
                <div className="search-item-container">
                    <p>メンバー</p>
                    {this.state.allUsers.slice(0, 3).map(item => {
                        return (
                            <SearchUserListTemplate
                                key={item.userId}
                                {...item}
                                hash={this.state.hash} 
                                customStyle={{
                                    borderWidth: 0,
                                    paddingLeft: 0,
                                    paddingBottom: 0,
                                    paddingTop: 0
                                }}
                            />
                        )
                    })}

                    <Button
                        onClick={() => this.setActiveTab("users")}
                        activeOpacity={0.7}
                        title="もっと友達を見る"
                        buttonStyle={mobileView ? styles.seeMoreBtnMobile : styles.seeMoreBtnPC}
                        titleStyle={styles.seeMoreTitle}
                    />
                </div>
            )
        }

        if (0 < this.state.allPosts.length) {
            postResult = this.state.allPosts.map(item => {
                return <SearchPostItemTemplate hash={this.state.hash} key={getObjKeyIdString(item._id)} {...item} mobileView={mobileView}
                setPostDetailModal={this.setPostDetailModal}/**task#363**//>
            })
        }

        if (0 === this.state.allPosts.length && 0 === this.state.allUsers.length) {
            return <p className="no-result">{NO_RESULTS_FOUND}</p>
        }

        return (
            <React.Fragment>
                {userResult}
                {postResult}
            </React.Fragment>
        )
    }


    handleInputChange = (value) => {
        this.setState({ searchString: value });
    }

    setActiveTab = (key) => {
        defaultTopScroll();
        this.setState({ activeTab: key })
    }

    showSystemError = () => {
        this.setState({
            isFetching: false,
            isSearching: false,
            msgErrorModal: SYSTEM_ERROR,
            showErrorModal: true,
            allPosts: [],
            allUsers: [],
            usersList: [],
            postsList: [],
        })
    }

    toggleErrorModal = () => {
        this.setState(prevState => ({ showErrorModal: !prevState.showErrorModal }))
    }

    searchEmptyCheck = () =>{
        return 0 === (this.state.searchString.trim()).length;
    }

    setPostDetailModal = (postId) =>{ //task#363 start
        this.setState({showPostDetailModal: true, postDetailPostId: postId})
    }

    closePostDetailModal = () =>{
        this.setState({showPostDetailModal: false})
    } //task#363 end

    render() {
        return (
            <Media>
                {({ breakpoints, currentBreakpoint }) => {
                    let mobileView = (breakpoints[currentBreakpoint] < breakpoints.mobileLandscape) || isMobile;
                    let tabStyle = mobileView ? { paddingTop: 16, marginLeft: 20, marginRight: 20 } : {};
                    return (
                        <div className={mobileView ? "tab-content-mobile" : "tab-content-web"}>
                            <Tab.Container
                                defaultActiveKey="all"
                                activeKey={this.state.activeTab}
                                style={{ backgroundColor: "red" }}
                            >
                                <div className={mobileView ? "search-bar-mobile col-sm-12 p-0" : "search-bar-web"}>
                                <div className={mobileView ? "search-mobile" : "search-web"}>
                                    {/**Mobile DiaryMemberSearch Component */}
                                    <PCOnlyView>
                                        <Card.Title className="search-title">
                                            <span>メンバー・日記検索</span>
                                            <Button
                                                key={"back"}
                                                type="clear"
                                                icon={
                                                    <Icon
                                                    name="chevron-left"
                                                    size={24}
                                                    color="black"
                                                    type={"material"}
                                                    />
                                                }
                                                title={"戻る"}
                                                titleStyle={{fontSize: 16, color: "#000"}}
                                                buttonStyle={{padding: 0, margin: 0}}
                                                onPress={() => this.props.history.push(appendUserRoute("/search"))}
                                            />

                                        </Card.Title>
                                    </PCOnlyView>
                                    <div className={`search-container-${mobileView ? "mobile" : "web"}`}>
                                        <div className="search-input-area" style={{ borderColor: mobileView ? "#606770" : "#DCDCDC" }}>
                                            <form autoComplete="off" className="w-100" onSubmit={this.handleSearchSubmit}>
                                                <TextInput
                                                    autoFocus={true}
                                                    autoComplete="off"
                                                    placeholder="comu comuを検索"
                                                    value={this.state.searchString}
                                                    onChangeText={this.handleInputChange}
                                                    onKeyPress={this.handleOnKeyPress}
                                                    style={mobileView ? styles.inputMobile : styles.inputWeb}
                                                    returnKeyType="search"
                                                    autoCorrect={"off"}
                                                    spellCheck={"off"}
                                                />
                                            </form>

                                            <TouchableOpacity disabled={this.searchEmptyCheck()} activeOpacity={0.5} onPress={this.handleSearchSubmit}>
                                                <img
                                                    alt="search-icon"
                                                    src={SearchIcon}
                                                    className={this.searchEmptyCheck() ? "search-icon half-opacity" : "search-icon"}
                                                />
                                            </TouchableOpacity>
                                        </div>
                                    </div>

                                    {/**TAB HEADERS**/}
                                    {this.state.searchInitiated && (
                                        <Nav variant="pills" className="flex-row result-tabs" style={{ backgroundColor: mobileView ? "#F5F5F5" : "#FFF" }}>
                                            <Nav.Item onClick={() => this.setActiveTab("all")}>
                                                <Nav.Link eventKey="all">すべて</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item onClick={() => this.setActiveTab("posts")}>
                                                <Nav.Link eventKey="posts">日記</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item onClick={() => this.setActiveTab("users")}>
                                                <Nav.Link eventKey="users">メンバー</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    )}
                                </div>
                                </div>

                                {this.state.searchInitiated ? (
                                    <Tab.Content style={{ paddingTop: mobileView ? 100 : 0 }}>
                                        <Tab.Pane eventKey="all" style={tabStyle}>
                                            {this.handleViewBoth(mobileView)}
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="posts" style={tabStyle}>
                                            {this.handlePostOnly(mobileView)}
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="users" style={tabStyle}>
                                            {this.handleUserOnly(mobileView)}
                                        </Tab.Pane>
                                    </Tab.Content>
                                ) : (
                                        <center>
                                            <ActivityIndicator
                                                style={{
                                                    paddingTop: 100,
                                                    display: this.state.isSearching ? "block" : "none"
                                                }}
                                            />
                                        </center>
                                    )}

                                <center>
                                    <ActivityIndicator
                                        style={{
                                            paddingTop: 10,
                                            paddingBottom: 24,
                                            display: this.state.isFetching ? "block" : "none"
                                        }}
                                    />
                                </center>
                            </Tab.Container>

                            <ErrorModal
                                errorMessage={this.state.msgErrorModal}
                                isErrorModalShow={this.state.showErrorModal}
                                handleCloseModal={this.toggleErrorModal}
                            />

                            <PostDetailModal /**task#363 start**/
                                isModalShow={this.state.showPostDetailModal}
                                confirmationText={this.state.postDetailPostId}
                                confirmTitle={"Search"}
                                handleConfirmAction={()=>this.redirectViewPost(this.state.postDetailPostId)}
                                handleCloseModal={this.closePostDetailModal}
                        /**task#363**/ /> 

                        </div>

                    )

                }}
            </Media>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.credentials
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        executeSearch: searchOperations.executeSearch,
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(DiaryMemberSearch);
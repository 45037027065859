import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  inputWeb: {
    padding: 12,
    paddingRight: 40,
    width: "100%",
    fontSize: 16
  },
  inputMobile: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    paddingRight: 40,
    width: "100%",
    fontSize: 16
  },
  seeMoreBtnPC: {
    backgroundColor: "#DCDCDC",
    width: 360,
    alignSelf: 'center',
    borderRadius: 8
  },
  seeMoreBtnMobile: {
    backgroundColor: "#DCDCDC",
    borderRadius: 16
  },
  seeMoreTitle:{
    color: "#666",
  },
  authorContainer: {
    flex: 1,
    flexDirection: 'row', 
    alignItems: 'center',
    width: 'auto'
  },
  modalClose: {
    zIndex: 2
  },
  closeIcon: {
    width: 22,
    height: 22,
    position: "absolute",
    right: -5,
    top: -5
  },
});

export default styles;

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { friendpageOperations } from './state';
import { connect } from 'react-redux';
import { Card } from 'react-bootstrap';
import { onApiSuccess, appendUserRoute, defaultTopScroll, isScrollRefetchReached } from '../../../utils/common';
import PostItemTemplate from '../../components/PostItemTemplate';
import ErrorModal from '../../components/ErrorModal';
import ConfirmationModal from "../../components/ConfirmationModal"
import { isNullOrUndefined } from 'util';
import { EMPTY_STRING, RELATIONSHIPS } from '../../../utils/constants';
import { WEBSOCKET_API_ERROR_STATUS, WEBSOCKET_MESSAGES } from '../../../utils/constants';
import { SYSTEM_ERROR, MSG_NO_POST_YET } from '../../../utils/messages';
import User404 from '../Errors/User404'
import FriendPageHeaderComponent from './FriendPageHeaderComponent';
import FriendPageDetailComponent from './FriendPageDetailComponent';
import './styles/info.scss';
import './styles/feed.scss';
import MyPageSkeleton from "../../components/Skeletons/MyPageSkeleton";
import { ActivityIndicator } from 'react-native';
import { getObjKeyIdString, fetchEmptyScroll} from "../../../utils/common";
import Snackbar from "@material-ui/core/Snackbar";
import { FUMIYA_USER_ID, FF_STAFF_USER_ID } from "../../../config/settings";
import { authOperations } from '../Authentication/state';
import ProcessLoader from '../../components/ProcessLoader';
import { notificationOperations } from '../Notifications/state';
import { isSessionExpired } from "../../../utils/common"; // Whiete Out fix
import ProcessLoaderForLoading from '../../components/ProcessLoaderForLoading'; // Whiete Out fix
import PostDetailModal from '../../components/PostDetailModal'; //task#363

class FriendPage extends Component {
    constructor(props){
        super(props)
        this.state = {
            ownerId: props.userId,
            handleName: props.handleName,
            friendHandleName: "",
            userModel: [],
            randomFriends: [],
            currentPagination : 1,
            type: null,
            postList: [],
            isLoading: true,
            errorMessage: EMPTY_STRING,
            isErrorModalShow: false,
            errors : {},
            isProfileMenuOpen: false,
            isFriendMenuOpen: false,
            isPostFetching: false,
            friendId: null,
            isAccessInvalid: false,
            isBlockFetching: true,
            relationship: "none",
            isAddfriendModalShow: false,
            isUnfriendModalShow: false,
            isBlockModalShow: false,
            isFriendRequestToastShow: false,
            isFriendAcceptToastShow: false,
            isFriendRequestSentAlreadyToastShow: false,
            isProcessing: false,
            isLastPage: false,
            coverHash: Date.now(),
            profileHash: Date.now(),
            isProcessingForLoading: false, //White Out fix
        }
    }

    componentWillMount() {
        const friendId = this.props.match.params.userId

        if(parseInt(friendId) === this.state.ownerId) {
            this.props.history.push(appendUserRoute("/mypage"))
        }

        this.setState({
            friendId: friendId
        })

        this.handleCheckIfBlocked(friendId)
    }

    componentDidMount() {
        defaultTopScroll();
        /**Sockets */
        if (!isNullOrUndefined(this.props.websocket) && this.props.websocket.readyState === WebSocket.OPEN) {
            this.initWSHandlers()
        }
        /***/

        this.handleFetchData()
    
        document.addEventListener('scroll', this.onScrollCheck);

        document.addEventListener("visibilitychange", this.backGroundCare); //White Out fix
    }

    componentDidUpdate(prevProps) {
        const friendId = this.props.match.params.userId

        if(parseInt(friendId) === this.state.ownerId) {
            this.props.history.push(appendUserRoute("/mypage"))
        }

        this.initWSHandlers()
        if (prevProps.match.params.userId !== friendId) {
            this.setState({
                userModel: [],
                randomFriends: [],
                currentPagination: 1,
                type: null,
                postList: [],
                isLoading: true,
                errorMessage: EMPTY_STRING,
                isErrorModalShow: false,
                errors: {},
                isProfileMenuOpen: false,
                isFriendMenuOpen: false,
                isPostFetching: false,
                friendId: friendId,
                isAccessInvalid: false,
                isBlockFetching: true,
                relationship: "none"
            }, () => {
                this.handleCheckIfBlocked(this.state.friendId) 
                
                this.handleFetchData()
            })
        }
    }

    componentWillUnmount(){
        document.removeEventListener('scroll', this.onScrollCheck);
        document.removeEventListener("visibilitychange", this.backGroundCare); //White Out fix
    }

    onScrollCheck = () =>{
        if(!this.state.isLoading && !this.state.isLastPage && isScrollRefetchReached()){
            this.handlePaginatedPost();
        }
    }

    handleCheckIfValidUserId(friendId) {
        if(isNaN(friendId)) {
            this.setState({
                isAccessInvalid: true,
                isBlockFetching: false
            })
            return true
        }
        return false
    }

    handleCheckIfBlocked(friendId) {
        if(this.handleCheckIfValidUserId(friendId)) {
            return
        }
        this.props.checkIfBlocked(this.state.ownerId, friendId).then(response => {
            if(onApiSuccess(response)) {
                const data = JSON.parse(response.data.data)
                const isAccessInvalid = data.is_blocked
                this.setState({
                    isAccessInvalid: isAccessInvalid,
                    isBlockFetching: false
                })
            }
            else {
                this.setState({
                    isErrorModalShow : true,
                    errorMessage: SYSTEM_ERROR
                })
            }
        }) 
    }

    handleFetchData() {
        this.setState({
            isPostFetching: true
        })

        this.props.getUserBasicInformation(this.state.ownerId, this.state.friendId).then(response => {
            if(onApiSuccess(response)) {
                let data = JSON.parse(response.data.data)
                let relationship = data.relationship

                if(!isNullOrUndefined(data.user_model)) {
                    if(!isNullOrUndefined(data.user_model.handleName)) {
                        this.setState({
                            userModel : data,
                            relationship: relationship,
                            friendHandleName: data.user_model.handleName
                        })
                
                        this.props.getUserPosts(this.state.ownerId, this.state.friendId, this.state.currentPagination)
                        .then(response => {
                            if(onApiSuccess(response)){
                                let data = JSON.parse(response.data.data)
                                this.setState({
                                    postList : data,
                                    isLoading: false,
                                    isPostFetching: false,
                                    isLastPage: data.length === 0 ? true : false
                                })
                            }else{
                                this.setState({
                                    isErrorModalShow : true,
                                    errorMessage: SYSTEM_ERROR
                                })
                            }
                        })
                    } else {
                        this.setState({
                            isAccessInvalid: true,
                            isLoading: false
                        })
                        return
                    }
                } else {
                    this.setState({
                        isAccessInvalid: true,
                        isLoading: false
                    })
                    return
                }
                
            }
            else {
                this.setState({
                    isErrorModalShow : true,
                    errorMessage: SYSTEM_ERROR
                })
            }
        })
    }

    //Side effects based on received sockets
    /** Websockets Listeners*/
    initWSHandlers() {
        this.props.websocket.onmessage = (data) => {
            let serverData = JSON.parse(data.data);
            let responseData = JSON.parse(serverData.data)

            if ((!isNullOrUndefined(serverData.message) 
                && WEBSOCKET_API_ERROR_STATUS === serverData.message) 
                || WEBSOCKET_MESSAGES.onError === responseData.action) {
                this.setState({
                    isErrorModalShow: true,
                    errorMessage: SYSTEM_ERROR,
                    isProcessing: false
                })
            }

            //Received notifications
            if (WEBSOCKET_MESSAGES.onNotifications === responseData.action) {
                this.props.incrementNotifCount(responseData.referenceId)
            }else if (WEBSOCKET_MESSAGES.onChatNotification === responseData.action) {
                this.props.incrementChatThreadCount(responseData.senderId, responseData.threadId)
            } else if (WEBSOCKET_MESSAGES.onSendRequest === responseData.action) {
                //Success Send Friend Request
                this.setState({
                    isFriendRequestToastShow: true,
                    relationship: RELATIONSHIPS.isSent,
                    isProcessing: false
                })
            } else if (WEBSOCKET_MESSAGES.onAcceptRequest === responseData.action) {
                //Success Accept Friend Request
                this.props.removeRequestNotification(responseData.notificationId.$oid);
                this.props.decrementNotification();

                this.setState({
                    isFriendAcceptToastShow: true,
                    relationship: RELATIONSHIPS.isFriend,
                    isProcessing: false
                })
            }
            
        }

        let isExistError = false; //added 20220720 White Out fix (caused by reconnection loop)
        this.props.websocket.onerror = () => { isExistError = true } //mod 20220617 White Out fix (caused by reconnection loop) Start.
        this.props.websocket.onclose = () => {
            let isScreenBackGround = localStorage.getItem("isScreenBackGround");
            if(isScreenBackGround == null){
                if(!isExistError) this.socketReconnect();
            } else { //If screen in smartPhone run to background, do not reconnect here as reconnection will be done with the exclusive method
                localStorage.setItem("isScreenBackGround", null);
            }
        } 
    }
     
    backGroundCare = () =>{
        if (document.visibilityState === "hidden"){ //When screen run to background
            localStorage.setItem("isScreenBackGround", 1);
            this.props.websocket.close(); //Forcibly close websocket
            localStorage.setItem("isWebsocktOpen", 0);
            this.setState({ isProcessingForLoading: true }); // Prepare to show Processing modal when screen returned from background
        }
        if (document.visibilityState === "visible"){ //When screen returned from background
            if (isSessionExpired()) this.props.logoutUser(); 
                this.props.connect();
                const interval = setInterval(() => {
                    if (localStorage.getItem("isWebsocktOpen") == 1) {
                        this.setState({ isProcessingForLoading: false }); // Stop Processing modal
                        clearInterval(interval);
                    }
                  }, 50);
        } 
    }
    //added 20220720 White Out fix End


    socketReconnect = () => {
        this.props.websocket.close()
        this.props.connect();
        setTimeout(() => {
            this.initWSHandlers()
        }, 1000);
    }
    /**END OF SOCKETS */

    handlePaginatedPost = () => {
        if (!this.state.isPostFetching) {
            this.setState({ currentPagination : this.state.currentPagination + 1, isPostFetching: true }, () => {
            this.props.getUserPosts(this.state.ownerId, this.state.friendId, this.state.currentPagination)
                .then(res => {
                    if (onApiSuccess(res)) {
                        let data = JSON.parse(res.data.data)
                        let updateFetch = () => {
                            this.setState(prevState => ({
                               postList: [
                                   ...prevState.postList,
                                   ...data
                               ],
                               isPostFetching: false,
                               isLastPage: data.length === 0 ? true : false
                           }))
                       }
                       if (0 >= data.length) {
                           fetchEmptyScroll();
                           setTimeout(() => { updateFetch() }, 500);
                       } else {
                           updateFetch();
                       }
                    } else {
                        this.setState({
                            isErrorModalShow : true,
                            errorMessage: SYSTEM_ERROR,
                            isPostFetching: false
                        })                   
                    }
                })
            });
        }
    }

    handleFriendButtonClick = () => {
        if(RELATIONSHIPS.isBlocked === this.state.relationship || 
            FUMIYA_USER_ID === parseInt(this.state.friendId) ||
            FF_STAFF_USER_ID === parseInt(this.state.friendId)) {
            return;
        }

        if(this.state.relationship === RELATIONSHIPS.isFriend) {
            this.setState({
                isFriendMenuOpen: !this.state.isFriendMenuOpen
            })
        }
        else if(this.state.relationship === RELATIONSHIPS.isReceived) {
            this.handleAcceptRequest(this.state.userModel.notification_id, this.state.friendId)
            this.handleFetchData()
        }
        else if(this.state.relationship === RELATIONSHIPS.isSent) {
            this.setState({
                isFriendRequestSentAlreadyToastShow: true
            })
        }
        else {
            this.setState({
                isAddfriendModalShow: true
            })
        }
    }

    handleMessageButtonClick = (chatRoute) => {
        if(RELATIONSHIPS.isBlocked === this.state.relationship ||
            FUMIYA_USER_ID === parseInt(this.state.friendId) ||
            FF_STAFF_USER_ID === parseInt(this.state.friendId)) {
            return;
        }

        this.props.history.push(chatRoute)
    }

    handleSendRequest = (friendId) => {
        let params = {
            ownerId: this.state.ownerId,
            referenceId: friendId
        }

        this.setState({isProcessing: true}, ()=>{
            this.props.sendFriendRequest(params, this.props.websocket)
        })
        
    }

    handleAcceptRequest = (notificationId, referenceId) => {
        let params = {
            notificationId: getObjKeyIdString(notificationId._id),
            ownerId: this.state.ownerId,
            referenceId: referenceId
        }

        this.setState({isProcessing: true}, ()=>{
            this.props.acceptFriendRequest(params, this.props.websocket)
        })
    }

    handleProfileMenuToggle = () => {
        this.setState({
            isProfileMenuOpen: !this.state.isProfileMenuOpen
        })
    }

    handleCloseAddfriendModal = () => {
        this.setState({
            isAddfriendModalShow: false
        })
    }

    handleCloseUnfriendModal = () => {
        this.setState({
            isUnfriendModalShow: false
        })
    }

    handleCloseBlockModal = () => {
        this.setState({
            isProfileMenuOpen: !this.state.isProfileMenuOpen,
            isBlockModalShow: false
        })
    }

    handleAddFriendClick = () => {
        this.setState({
            isAddfriendModalShow: false
        })
        this.handleSendRequest(this.state.friendId)
        this.handleFetchData()
    }

    handleRemoveFriendClick = () => {
        this.setState({
            isFriendMenuOpen: !this.state.isFriendMenuOpen,
            isUnfriendModalShow: true
        })
    }

    handleBlockUserClick = () => {
        this.setState({
            isBlockModalShow: true
        })
    }

    handleRemoveFriend = () => {
        this.setState({
            isUnfriendModalShow: false
        })
        this.props.removeFriend(this.state.ownerId, this.state.friendId)
        .then(response => {
            if(onApiSuccess(response)){
                window.location.reload()
            }
            else{
                this.setState({
                    isErrorModalShow : true,
                    errorMessage: SYSTEM_ERROR
                })
            }
        })
    }

    handleBlockUser = () => {
        this.setState({
            isBlockModalShow: false
        })
        if ('blocked' === this.state.relationship) {
            this.props.unblockUser(this.state.ownerId, this.state.friendId)
            .then(response => {
                if(onApiSuccess(response)){
                    window.location.reload()
                }
                else{
                    this.setState({
                        isErrorModalShow : true,
                        errorMessage: SYSTEM_ERROR
                    })
                }
            })
        }
        else {
            this.props.blockUser(this.state.ownerId, this.state.friendId)
            .then(response => {
                if(onApiSuccess(response)){
                    window.location.reload()
                }
                else{
                    this.setState({
                        isErrorModalShow : true,
                        errorMessage: SYSTEM_ERROR
                    })
                }
            })
        }
    }

    handleToastClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({
            isFriendAcceptToastShow: false,
            isFriendRequestToastShow: false,
            isFriendRequestSentAlreadyToastShow: false
        })
    }

    handleCloseErrorModal = () =>{
        this.setState({isErrorModalShow: false});
    }

    setPostDetailModal = (postId) =>{ //task#363 start
        this.setState({showPostDetailModal: true, postDetailPostId: postId})
    }

    closePostDetailModal = () =>{
        this.setState({showPostDetailModal: false})
    }//task#363 end

    render() {
        if(this.state.isAccessInvalid) {
            return (
                <User404/>
            )
        }
        else if(this.state.isBlockFetching) {
            return (
                <div className="bg-white">
                    <FriendPageHeaderComponent
                        defaultProfile={true}
                        {...this.state}
                        {...this.props}
                    />
                    <MyPageSkeleton isHeader={false} />
                    
                    <ErrorModal
                        {...this.state}
                        handleCloseModal={this.handleCloseErrorModal}
                        customContent={null}
                        errorMessage={this.state.errorMessage}
                    />
                </div>
            )
        }
        else {
            return (
                <React.Fragment>
                    <Card className={"friend-card"}>
                        <ProcessLoader isShown={this.state.isProcessing}/>
                        <ProcessLoaderForLoading isShown={this.state.isProcessingForLoading}/>
                        <FriendPageHeaderComponent
                            {...this.state}
                            {...this.props}
                            handleFriendButtonClick={this.handleFriendButtonClick}
                            handleProfileMenuToggle={this.handleProfileMenuToggle}
                            handleBlockUserClick={this.handleBlockUserClick}
                            handleRemoveFriendClick={this.handleRemoveFriendClick}
                            handleMessageButtonClick={this.handleMessageButtonClick}
                        />
                        <div className="horizontal-border"></div>
                        {
                           0 >= Object.keys(this.state.userModel).length  ?
                                <MyPageSkeleton isHeader={false} /> : <FriendPageDetailComponent {...this.state} />
                        }
                    </Card>
                    {'blocked' !== this.state.relationship ?
                        !this.state.isLoading ? 
                            0 < this.state.postList.length ?
                            (
                                <div className="feed-main">
                                    {
                                        this.state.postList.map((details, i) => 
                                            <PostItemTemplate 
                                                key={"post" + i} 
                                                {...details}  
                                                ownerId={this.state.friendId}
                                                isFriendPage={true}
                                                type={details.type}
                                                setPostDetailModal={this.setPostDetailModal/**task#363**/}
                                            />
                                        )
                                    }
                                </div>
                            )
                            :
                            (
                                <div className="feed-main">
                                    <center><p>{MSG_NO_POST_YET}</p></center> 
                                </div>
                            )
                        : null
                    : null}

                    <center>
                        <ActivityIndicator
                            style={{
                                marginBottom: 20,
                                height: 50,
                                display: this.state.isPostFetching ? "block" : "none"
                            }}
                        />
                    </center>
                    <ConfirmationModal
                        isModalShow={this.state.isAddfriendModalShow}
                        confirmationText={"友達リクエストを送信しますか？"}
                        handleConfirmAction={()=>this.handleAddFriendClick()}
                        handleCloseModal={this.handleCloseAddfriendModal}
                        confirmTitle={"追加"}
                        closeTitle={"戻る"}
                    />
                    <ConfirmationModal
                        isModalShow={this.state.isUnfriendModalShow}
                        confirmationText={"友達から削除しますか？"}
                        handleConfirmAction={()=>this.handleRemoveFriend()}
                        handleCloseModal={this.handleCloseUnfriendModal}
                        confirmTitle={"削除"}
                        closeTitle={"戻る"}
                    />
                    <ConfirmationModal
                        isModalShow={this.state.isBlockModalShow}
                        confirmationText={"blocked" !== this.state.relationship ? "ブロックしますか？" : "ブロックを解除しますか？"}
                        handleConfirmAction={()=>this.handleBlockUser()}
                        handleCloseModal={this.handleCloseBlockModal}
                        confirmTitle={"blocked" !== this.state.relationship ? "ブロック" : "解除"}
                        closeTitle={"戻る"} 
                    />
                    <PostDetailModal /**task#363 start**/
                        isModalShow={this.state.showPostDetailModal}
                        confirmationText={this.state.postDetailPostId}
                        confirmTitle={"FriendPage"}
                        handleConfirmAction={()=>this.redirectViewPost(this.state.postDetailPostId)}
                        handleCloseModal={this.closePostDetailModal}
                    /**task#363**/ /> 
                    <Snackbar 
                        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                        open={this.state.isFriendRequestToastShow}
                        message={`${this.state.friendHandleName}さんへ友達リクエストを送信しました。`}
                        autoHideDuration={3000}
                        onClose={this.handleToastClose}
                    />
                    <Snackbar 
                        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                        open={this.state.isFriendAcceptToastShow}
                        message={`${this.state.friendHandleName}さんからの友達リクエストを承認しました。`}
                        autoHideDuration={3000}
                        onClose={this.handleToastClose}
                    />
                    <Snackbar 
                        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                        open={this.state.isFriendRequestSentAlreadyToastShow}
                        message={`既に友達リクエストを送信済です。`}
                        autoHideDuration={3000}
                        onClose={this.handleToastClose}
                    />
                    <ErrorModal
                        {...this.state}
                        handleCloseModal={this.handleCloseErrorModal}
                        customContent={null}
                        errorMessage={this.state.errorMessage}
                    />
                </React.Fragment>
            );
        }
    }
}



const mapStateToProps = state => {
    return {
        userId: state.auth.credentials.userId,
        handleName: state.editprofile.profileDetails.handleName,
        websocket: state.auth.websocket,
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            checkIfBlocked: friendpageOperations.checkIfBlocked,
            getUserBasicInformation: friendpageOperations.getUserBasicInformation,
            getRandomFriends: friendpageOperations.getRandomFriends,
            getUserPosts: friendpageOperations.getUserPosts,
            blockUser: friendpageOperations.blockUser,
            unblockUser: friendpageOperations.unblockUser,
            removeFriend: friendpageOperations.removeFriend,
            sendFriendRequest: friendpageOperations.sendFriendRequest,
            acceptFriendRequest: friendpageOperations.acceptFriendRequest,
            connect: authOperations.connectWS,
            incrementNotifCount: authOperations.incrementNotifCount,
            incrementChatThreadCount: authOperations.incrementChatThreadCount,
            removeRequestNotification: notificationOperations.removeRequestNotification,
            decrementNotification: notificationOperations.decrementNotification,
            logoutUser: authOperations.logoutUser //White out fix
        },
        dispatch
    )

export default connect(mapStateToProps, mapDispatchToProps)(FriendPage);
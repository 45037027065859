import * as types from "./types";

export const insertRandomMessage = ( data ) => ({
    type: types.INSERT_RANDOM_MESSAGE,
    payload: data,
});

export const getRandomMessage = ( data ) => ({
    payload: data,
});

export const getAllRecipients = ( data ) => ({
    payload: data,
});

export const setRandomMessageList = ( data ) => ({
    type: types.SET_RANDOM_MESSAGE_LIST,
    payload: data,
});

export const updateRandomMessage = ( data ) => ({
    type: types.UPDATE_RANDOM_MESSAGE,
    payload: data,
});

export const deleteRandomMessage = ( index ) => ({
    type: types.DELETE_RANDOM_MESSAGE,
    payload: index,
});
import React, { Component } from 'react';
import './style/styles.scss';
import { FormLabel, FormGroup, Button, ButtonToolbar, Col } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import { contactFormInputs } from './state/templates';
import TextareaAutosize from 'react-textarea-autosize'
import { INQUIRY_NOT_SENT } from '../../../utils/messages';
import ErrorModal from '../../components/ErrorModal';
import ConfirmationModal from '../../components/ConfirmationModal';
import { contactSelectors, contactOperations } from './state';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { onApiSuccess, defaultTopScroll, appendUserRoute, isStringNullEmptyOrUndefined } from '../../../utils/common';
import ProcessLoader from '../../components/ProcessLoader';
import { Beforeunload } from 'react-beforeunload';
import DiscardChangesModal from '../../components/DiscardChangesModal';
import { Link } from 'react-router-dom';

class ContactUs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showConfirmModal: false,
            showErrorModal: false,
            submitInitiated: false,
            isProcessing: false,
            hasUpdates: false,
        }
    }

    componentDidMount(){
        defaultTopScroll();
    }
    
    handleSubmit = (values, resetForm) => {
        defaultTopScroll();
        
        this.setState({isProcessing: true}, ()=>{
            this.props.sendEmail(values)
            .then(response => {
                if (onApiSuccess(response)) {
                    //Display confirm
                    this.setState({ showConfirmModal: true, isProcessing: false, hasUpdates: false })

                    //Reset Form
                    resetForm({
                        ffid: "",
                        email: "",
                        password: "",
                        name: "",
                        content: ""
                    })
                } else {
                    this.setState({ showErrorModal: true, isProcessing: false })
                }
            }).catch(() => { this.setState({ showErrorModal: true, isProcessing: false }) })
        })
    }

    renderField = (input, value, setFieldValue, errors) => {
        let isError = undefined !== errors[input.name];
        let displayError = this.state.submitInitiated && isError;

        switch (input.type) {
            case "textarea": {
                return (
                    <FormGroup key={input.name} className={"ml-0 mr-0"}>
                        <FormLabel className="contact-label">{input.label}</FormLabel >

                        <Col className="pl-0 pr-0">
                            <TextareaAutosize
                                name={input.name}
                                className={"form-control contact-field"}
                                minRows={parseInt(input.rows)}
                                onChange={event => setFieldValue(input.name, event.target.value)}
                                value={value}
                                placeholder={input.placeholder}
                                autoComplete="off"
                            />
                            {displayError ? <p className="formik-error mt-2">{errors[input.name]}</p> : null}
                        </Col>
                    </FormGroup>
                )
            }

            default: {
                return (
                    <FormGroup key={input.name} className={"ml-0 mr-0"}>
                        <FormLabel className="contact-label">{input.label}</FormLabel >

                        <Col className="pl-0 pr-0">
                            <Field
                                autoComplete="off"
                                className={"form-control contact-field"}
                                placeholder={input.placeholder}
                                component={input.component}
                                type={input.type}
                                value={isStringNullEmptyOrUndefined(value) ? "" : value}
                                name={input.name}
                                maxLength={input.maxLength}
                                onChange={event => {
                                    let newValue = event.target.value;
                                    if(input.regex){
                                        if(!((input.regex).test(newValue)) || "" === newValue){
                                            setFieldValue(input.name, newValue)
                                        }
                                    }else {
                                        setFieldValue(input.name, newValue)
                                    }
                                }}
                            />
                            {displayError ? <p className="formik-error mt-2">{errors[input.name]}</p> : null}
                        </Col>
                    </FormGroup>
                )
            }
        }
    }

    toggleConfirmModal = () => {
        this.setState(prevState => ({ showConfirmModal: !prevState.showConfirmModal }));
    }

    toggleErrorModal = () => {
        this.setState(prevState => ({ showErrorModal: !prevState.showErrorModal }))
    }

    render() {
        return (
            /** On before page leave*/
            <Beforeunload onBeforeunload={(event) => this.state.hasUpdates ? event.preventDefault() : {}} >
                {/** On before route leave*/}
                <DiscardChangesModal active={this.state.hasUpdates}/>

                <ProcessLoader isShown={this.state.isProcessing}/>

                <div className="contact-parent">
                    <p className="contact-title">{"管理者へのお問い合わせ"}</p>
                    <div className="contact-container">
                        <p className="contact-description mb-4">
                            {`お問い合わせいただく前に、必ず「`}
                            <Link to={appendUserRoute("/help")}>Q&A／ヘルプ</Link>
                            {`」、「管理者からのお知らせ（お知らせがある場合のみトップページ上部に表⽰されます）」をご確認ください（既にご案内している件に関してはご回答差し上げない場合がございます）。それでも解決しない場合は、下記事項を必ずご⼊⼒の上お問い合わせください。`}
                        </p>
                        <p className="contact-description mb-4">{`お問い合わせの内容は、管理者とやりとりが続く場合でも必ずすべてその都度お書き添えください。`}</p>
                        <p className="contact-description mb-4">{`送信されたお問い合わせは、ご⾃⾝と管理者に同時に届くようになっております。`}</p>
                        <p className="contact-description mb-4">{`機能要望に関しては基本的に回答差し上げておりませんのでご了承ください。いただいたご意⾒・ご要望は今後サービスを改善していくために参考にさせていただきます。`}</p>
                        <p className="contact-description red-text">{`※必ず@ffm.co.jpからのメールを受信できるようにしておいてください。`}</p>

                        <div className="divider" />

                        <Formik
                            validate={(values)=>{
                                this.setState({hasUpdates: true});
                                return contactSelectors.checkContactForm(values)
                            }}
                            onSubmit={(values, { resetForm }) => {
                                this.handleSubmit(values, resetForm)
                            }}
                        >
                            {({ values, setFieldValue, errors }) => (
                                <Form>
                                    {contactFormInputs.map(item => {
                                        return this.renderField(item, values[item.name], setFieldValue, errors);
                                    })}
                                    <ButtonToolbar className="justify-content-center">
                                        <Button className="submit-btn" type="submit" onClick={() => this.setState({ submitInitiated: true })}>送信する</Button>
                                    </ButtonToolbar>
                                </Form>
                            )}
                        </Formik>

                        {/* Confirm */}
                        <ConfirmationModal
                            isModalShow={this.state.showConfirmModal}
                            confirmationText={"お問い合わせを送信しました。"}
                            confirmTitle={"はい"}
                            closeBtnStyle={{ display: "none" }}
                            handleConfirmAction={this.toggleConfirmModal}
                            handleCloseModal={this.toggleConfirmModal}
                        />

                        <ErrorModal
                            errorMessage={INQUIRY_NOT_SENT}
                            isErrorModalShow={this.state.showErrorModal}
                            handleCloseModal={this.toggleErrorModal}
                        />
                    </div>
                </div>
            </Beforeunload>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.credentials,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        sendEmail: contactOperations.sendEmail
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
import * as types from "./types";

export const setUsersList = ( data ) => ({
    type: types.SET_USERS_LIST,
    payload: data,
});

export const appendUsersList = ( data ) => ({
    type: types.APPEND_USERS_LIST,
    payload: data,
});

export const deleteUser = ( data ) => ({
    type: types.DELETE_USER,
    payload: data,
});

export const updateUserId = (data) => ({
    type: types.UPDATE_USER_ID,
    payload: data,
})
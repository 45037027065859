import { USER_ID_MAX_LENGTH, HANDLE_NAME_MAX_LENGTH, EMAIL_MAX_LENGTH, REGEX_NUMERIC } from '../../../../utils/constants';

export const usersTable = {
    tableHeaders: [
        "ID",
        "ハンドルネーム",
        "メールアドレス",
        " "
    ],
    tableColumns: [
        { key: "userId", center: true },
        { key: "handleName", colored: true, center: true, link: true },
        { key: "email", center: true }
    ]
}

export const userManagementForm = {
    model: "UserManagementModel",
    form: [
        {
            type: "top-error-message",
            label: "",
            name: "topErrorMessage",
            position: "center",
            default: ''
        },
        {
            type: "text-user-search",
            label: "ID",
            name: "userId",
            maxLength: USER_ID_MAX_LENGTH,
            default: '',
            regex: REGEX_NUMERIC
        },{
            type: "text-user-search",
            label: "ハンドルネーム",
            name: "handleName",
            maxLength: HANDLE_NAME_MAX_LENGTH,
            default: ''
        },{
            type: "text-user-search",
            label: "メールアドレス",
            name: "email",
            maxLength: EMAIL_MAX_LENGTH,
            default: ''
        }
    ]
}
//Add Support for IE Versions
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import configureStore, { rootRoutes } from './app.modules.js';
import {
    BrowserRouter,
    Switch
} from 'react-router-dom';
import PublicRoute from "./views/components/PublicRoute";
import ReactBreakpoints from 'react-breakpoints';
import './includes/bootstrap/css/bootstrap.min.css';
import './styles/css/index.css';
import './styles/css/feed.css';
import './styles/css/video.css';
import './styles/css/custom-bootstrap.css';
import moment from 'moment';
import 'moment-timezone';
import { BREAKPOINTS } from './utils/constants.js';
import 'fontfaceobserver'


/**Update moment to use JST timezone */
moment.tz.setDefault("Asia/Tokyo");

const Root = () => {
    return (
        <ReactBreakpoints 
            breakpoints={BREAKPOINTS}
            debounceResize={true}
            debounceDelay={250}
            key="breakpoint"
        >
            <style type="text/css">{`
                @font-face {
                    font-family: 'kozuka-gothic-pro';
                    font-style: normal;
                    src: local('kozuka-gothic-pro'), url(${require('../src/includes/fonts/KozGoPro-Regular.otf')}) format('opentype');
                    font-display: swap;
                } 
                @font-face {
                    font-family: 'kozuka-gothic-pro';
                    src: url(${require('../src/includes/fonts/KozGoPro-Regular.otf')}) format('opentype');
                    font-display: swap;
                }
                @font-face {
                    font-family: 'MaterialIcons';
                    src: url(${require('react-native-vector-icons/Fonts/MaterialIcons.ttf')}) format('truetype');
                }
                @font-face {
                    font-family: 'FontAwesome';
                    src: url(${require('react-native-vector-icons/Fonts/FontAwesome.ttf')}) format('truetype');
                }
            `}
            </style>
            <Provider store={configureStore()}>
                <BrowserRouter initialEntries={[]}>
                    <Switch>
                        {rootRoutes.map((route, i) => <PublicRoute key={i} {...route} />)}
                    </Switch>
                </BrowserRouter>
            </Provider>
        </ReactBreakpoints>
    )
}

ReactDOM.render(
    <Root />,
    document.getElementById('root')
);
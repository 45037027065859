import { RANDOM_MESSAGE_BODY_MAX_LENGTH } from '../../../../utils/constants';

export const randomMessageTable = {
    tableHeaders: [
        "配信日時",
        "種類",
        "タイトル",
        "配信",
        " "
    ],
    tableColumns: [
        { key: "deliveryDate", center: true },
        { key: "type", center: true},
        { key: "title", colored: true, link: true },
        { key: "deliveryStatus", center: true }
    ]
}

export const recipientTable = {
    tableHeaders: [
        "配信日時",
        "ID",
        "ハンドルネーム"
    ],
    tableColumns: [
        { key: "timestamp", center: true },
        { key: "userId", center: true },
        { key: "handleName", center: true, link: true, recipientTblLink: true }
    ]
}


export const randomMessageCreateForm = {
    model: "RandomMessageModel",
    form: [
        {
            type: "top-error-message",
            label: "",
            name: "topErrorMessage",
            default: ''
        },
        {
            type: "radio",
            label: "種類",
            name: "type",
            inline: true,
            default: ''
        },
        {
            type: "multiple-text",
            label: "送信日時",
            name: 'deliveryDate',
            children: [
                {
                    type: "custom-text-date",
                    label: "年",
                    name: "deliveryYear",
                    width: 115,
                    min: 1,
                    max: 9999,
                    default: ''
                },
                {
                    type: "custom-text-date",
                    label: "月",
                    name: "deliveryMonth",
                    width: 95,
                    min: 1,
                    max: 12,
                    default: ''
                },
                {
                    type: "custom-text-date",
                    label: "日",
                    name: "deliveryDay",
                    width: 95,
                    min: 1,
                    max: 31,
                    default: ''
                },
                {
                    type: "custom-text-date",
                    label: "時",
                    name: "deliveryHour",
                    width: 95,
                    min: 0,
                    max: 23,
                    default: ''
                },
                {
                    type: "custom-text-date",
                    label: "分",
                    name: "deliveryMinute",
                    width: 95,
                    min: 0,
                    max: 59,
                    default: ''
                },
            ]
        },
        {
            type: "textarea",
            label: "本文",
            name: "messageBody",
            rows: "10",
            maxLength: RANDOM_MESSAGE_BODY_MAX_LENGTH,
            default: '',
            startLabel: true,
        },
        {
            type: "file",
            label: "写真",
            name: "messageImage",
            default: ''
        },
        {
            type: "radio",
            label: "配信",
            name: "deliveryStatus",
            inline: true,
            default: ''
        },
    ]
}

export const randomMessageUpdateForm = {
    model: "RandomMessageUpdateModel",
    form: [
        {
            type: "values",
            label: "",
            name: "confirmDeleteMsg",
            default: ''
        },
        {
            type: "values",
            label: "種類",
            name: "type",
            default: ''
        },
        {
            type: "values",
            label: "送信日時",
            name: "deliveryDate",
            value: '',
            default: ''
        },
        {
            type: "values",
            label: "本文",
            name: "messageBody",
            default: '',
            startLabel: true,
        },
        {
            type: "values",
            label: "写真",
            name: "messageImage",
            default: '',
            startLabel: true,
        },
        {
            type: "radio",
            label: "配信",
            name: "deliveryStatus",
            inline: true,
            default: ''
        },
        {
            type: "table",
            label: "配信履歴",
            name: "recipientTable",
            default: ''
        },
        {
            type: "pagination",
            label: "",
            name: "pagination",
            default: ''
        }
    ]
}

export const radioInput = [
    { name: "type", value: 0, label: "ランダム" },
    { name: "type", value: 1, label: "全員" },
    { name: "deliveryStatus", value: 1, label: "配信する" },
    { name: "deliveryStatus", value: 0, label: "配信しない" },
];
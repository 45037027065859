import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
    fixedPCHeader:{
        display: 'flex',
        position: "fixed",
        zIndex: 9999999,
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        top: 0,
        backgroundColor: '#0A182C',
        height: 50
    },
    fixedMobileHeader:{
        display: "table",
        position: "fixed",
        zIndex: 999,
        width: '100%',
        top: 0
    },
    title:{
        color: 'white', 
        fontSize: 18,
        fontWeight: '500'
    },
    
    mobileHeader:{
        backgroundColor: '#0A182C',
        justifyContent: 'center'
    },

    mbHeaderIFrame:{
        backgroundColor: 'rgb(168,37,105)'
    },
    mbTitleIFrame:{
        color: 'white', 
        fontSize: 18,
        fontWeight: '500'
    },

    navigation:{
        flex: 1, 
        backgroundColor: "#fff",
        flexDirection: "row", 
        justifyContent: "space-around",
        paddingBottom: 19,
        marginTop: 0,
        borderBottomWidth: 1,
        borderBottomColor: '#dcdcdc'
    },
    icon:{
        width: 28, 
        height: 28,

    },
    cameraIcon:{
        width: 28, 
        height: 32,
        paddingTop: 12
    },
    searchIcon : {
        height: 18,
        width: 18,
        position: "absolute",
        top: 15,
        marginLeft: 75
    },
    searchIconLeft : {
        height: 18,
        width: 18,
        position: "absolute",
        marginLeft: 10
    }
})

export default styles;
import React from "react";
import Modal from "react-modal";
import { View, Text,  Image, TouchableOpacity} from "react-native";
import "./styles/styles.scss";
import CloseIcon from "../../../includes/img/icons/close.svg";
import styles from "./styles/styles.native.js";
import { SOURCE } from "../../../utils/constants";

const CalendarFilterComponent = props => {
  return (
    <View>
      <Modal
        ariaHideApp={false}
        isOpen={props.isShowFilter}
        className="calendar-modal"
        overlayClassName="calendar-modal-overlay"
      >
        <TouchableOpacity
          style={styles.modalClose}
          onPress={(e) => props.handleCloseFilter(e)}
        >
          <Image source={CloseIcon} alt="close-icon" style={styles.closeIcon} />
        </TouchableOpacity>
        <div className={SOURCE.oneGeneration === props.source ? "calendar-one-container" : "calendar-two-container"}>
            {
                SOURCE.oneGeneration === props.source ? 
                    <View style={styles.yearContainer}>
                        {props.genOneYears.map((item,index) => {
                            return (
                                <>
                                    <Text key={index} styles={styles.yearText}>{item}年</Text>
                                    {renderMonths(props,item)}
                                </>
                            )
                        })}
                    </View>
                :   
                    <View style={styles.yearContainer}>
                        {props.genTwoYears.map((item,index)=> {
                            return (
                                <>
                                    <Text key={index} styles={styles.yearText}>{item}年</Text>
                                    {renderMonths(props,item)}
                                </>
                            )
                        })}
                    </View>
            }
        </div>
        <div className="btn-container">
        <button onClick={SOURCE.oneGeneration === props.source ? ()=>props.handleDisplayTwoGen() : ()=>props.handleDisplayOneGen() }
         className="source-btn">{SOURCE.oneGeneration === props.source ? "もっと前の日記を見る" : "もっと新しい日記を見る"}</button>
        </div>
      </Modal>
    </View>
  );
};

const renderMonths = (props, currentYear) =>{
    //Generate months
    let months = getMonths()

    //Divide the months for row display
    let firstHalf = months.slice(0,6)
    let secondHalf = months.slice(6,12)
    return(
        <div className="months-row-container">
            <div className="first-half-item">
                {
                    firstHalf.map(month=>{
                        let filter = currentYear + "-" + month;
                         return(
                            <div key={month} className="months-item">
                                {isLink(props,filter) ? renderLinkItem(props, currentYear, month)  :  renderNonLinkItem(month) }
                            </div>
                        )
                     })
                }
            </div>
            <div className="second-half-item">
                {
                    secondHalf.map((month,index)=>{
                        let filter = currentYear + "-" + month;
                         if(2020 !== currentYear){
                            return(
                                <div key={month} className="months-item">
                                    {isLink(props,filter) ? renderLinkItem(props, currentYear, month)  :  renderNonLinkItem(month) }
                                 </div>
                            )
                         }else{
                             if(2 >= index){
                                return(
                                    <div key={month} className="months-item">
                                        {isLink(props,filter) ? renderLinkItem(props, currentYear, month)  :  renderNonLinkItem(month) }
                                    </div>
                                )
                             }else{
                                return null
                             }
                         }
                     })
                }
            </div>
        </div>
    )
}

const renderLinkItem = (props,currentYear,month) =>{
    return(
        <>
            <a href="# " onClick={(e)=>props.handleFilterPost(e,currentYear, month)} className="month month-link">
                <span>{month}</span>
                <span>月</span>
            </a> 
       </>
    )
}

const renderNonLinkItem = (month) => {
    return(
        <>
            <span className="month">{month}</span>
            <span>月</span>
        </>
    )
}


const getMonths = () => {
    //Get months and store in months variable
    const months = []
    let month = 1

    while (month <= 12){
        //Append 0 if month is less than 10
        if(10 > month){
            months.push("0" + month)
        }else{
            months.push(month.toString())
        }
        month++
    }
    return months
}

const isLink = (props, filter) => {
    //Values in props.filterDates must follow "YYYY-MM" format to match
    return props.filterDates.includes(filter.toString())
}

export default CalendarFilterComponent;
